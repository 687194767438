import { Injectable } from '@angular/core';

const ACTIVITY_API_URL = 'api/activityLogs';

@Injectable()
export class ActivityLogsService {

  constructor() { }

}
