export class InvoicesTable {
  public static invoices: any[] = [
    {
      id: 101,
      EmisorId: 1,
      Serie: 'A',
      Folio: 1000,
      Fecha: '2020-06-24T12:44:17.433Z',
      Total: 0,
      SubTotal: 0,
      Descuento: null,
      FormaPago: '14',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'P',
      MetodoPago: 'PUE',
      LugarExpedicion: '20120',
      Version: '3.3',
      Certificado: '',
      Sello: '',
      NoCertificado: '000001000001',
      Confirmacion: '',
      Observaciones: '',
      UUID: '',
      Status: 0,
      EsIngreso: true,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: null,
        invoiceId: null,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: null,
        invoiceId: null,
        Rfc: 'OMB202234YG5',
        Nombre: 'Kordula Stenet',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'I01'
      },
      Conceptos: [
        {
          id: null,
          invoiceId: null,
          ClaveProdServ: '84111506',
          NoIdentificacion: null,
          Cantidad: 1,
          ClaveUnidad: 'ACT',
          Unidad: null,
          Descripcion: 'Pago',
          ValorUnitario: 0,
          Importe: 0,
          Descuento: null,
          Impuestos: [],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [],
          Retenciones: [],
          TotalImpuestosTrasladados: 0,
          TotalImpuestosRetenidos: 0
        }
      ],
      Complementos: {
        ComplementoPagos: {
          Version: '1.0',
          Pagos: [
            {
              id: null,
              invoiceId: null,
              FechaPago: '2020-06-24T17:44:23.819Z',
              FormaDePagoP: '01',
              MonedaP: 'MXN',
              TipoCambioP: null,
              Monto: 694.75,
              NumOperacion: '',
              RfcEmisorCtaOrd: '',
              NomBancoOrdExt: '',
              CtaOrdenante: '',
              RfcEmisorCtaBen: '',
              CtaBeneficiario: '',
              TipoCadPago: '',
              CertPago: '',
              CadPago: '',
              SelloPago: '',
              DoctoRelacionados: [
                {
                  id: null,
                  invoicePagoId: null,
                  IdDocumento: 'd7bAD2dA-5bFc-e37b-AFa8-C3F3BDedbD4d',
                  Serie: 'C',
                  Folio: '1892',
                  MonedaDR: 'MXN',
                  TipoCambioDR: null,
                  MetodoDePagoDR: 'PPD',
                  NumParcialidad: 1,
                  ImpSaldoAnt: 1694.75,
                  ImpPagado: 694.75,
                  ImpSaldoInsoluto: 1000
                }
              ],
              Impuestos: []
            }
          ]
        },
        TimbreFiscalDigital: null,
        ImpuestosLocales: null,
        Donatarias: null,
        LeyendasFiscales: null,
        Detallista: null,
        INE: null,
        TuristaPasajeroExtranjero: null,
        Nomina: null
      },
      CfdiRelacionados: null,
      Addendas: {},
    },
    {
      id: 1002,
      emisorId: 1,
      Serie: 'A',
      Folio: '1000',
      Fecha: '2020-01-01T12:16:33.229Z',
      Total: 173.87,
      SubTotal: 155.61,
      Descuento: null,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20128',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      // UUID: 'efa167ab-EDf5-0e4b-DC4e-298fFf7b2b10',
      UUID: '',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 1,
        invoiceId: 1002,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 1,
        invoiceId: 1002,
        Rfc: 'RVT740418OP1',
        Nombre: 'Dania Meeking',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Conceptos: [
        {
          id: 1,
          invoiceId: 1002,
          ClaveProdServ: '10211904',
          NoIdentificacion: 'GUOG-5053',
          Cantidad: 1,
          ClaveUnidad: 'G12',
          Unidad: '',
          Descripcion: 'H. CLASSIC BURGER',
          ValorUnitario: 14.89,
          Importe: 14.89,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [],
              Retenciones: []
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        },
        {
          id: 2,
          invoiceId: 1002,
          ClaveProdServ: '10101504',
          NoIdentificacion: null,
          Cantidad: 1,
          ClaveUnidad: 'K40',
          Unidad: null,
          Descripcion: 'Comida para perros',
          ValorUnitario: 120,
          Importe: 120,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 2,
                  Base: 120,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.160000',
                  Importe: 19.2
                }
              ],
              Retenciones: []
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        },
        {
          id: 3,
          invoiceId: null,
          ClaveProdServ: '10215459',
          NoIdentificacion: 'FHHG-4348',
          Cantidad: 1,
          ClaveUnidad: 'XFW',
          Unidad: '',
          Descripcion: 'RESMA CARTA ECONOVO TIPO REVOL',
          ValorUnitario: 20.72,
          Importe: 20.72,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 3,
                  Base: 20.72,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.000000',
                  Importe: 0
                }
              ],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 3,
                  Base: 20.72,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.140000',
                  Importe: 2.9
                },
                {
                  id: 0,
                  invoiceConceptoId: 3,
                  Base: 20.72,
                  Impuesto: '001',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.180000',
                  Importe: 3.73
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.160000',
              Importe: 19.2
            },
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.000000',
              Importe: 0
            }
          ],
          Retenciones: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.140000',
              Importe: 2.9
            },
            {
              id: 0,
              Impuesto: '001',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.180000',
              Importe: 3.73
            }
          ],
          TotalImpuestosTrasladados: 19.2,
          TotalImpuestosRetenidos: 6.63
        }
      ],
      Complementos: {
        TimbreFiscalDigital: {
          invoiceId: 1002,
          Version: '1.1',
          UUID: 'efa167ab-EDf5-0e4b-DC4e-298fFf7b2b10',
          FechaTimbrado: '',
          RfcProvCertif: '',
          Leyenda: '',
          SelloCFD: '',
          NoCertificadoSAT: '',
          SelloSAT: '',
        }
      },
      CfdiRelacionados: [],
      Addendas: []
    },
    {
      id: 1003,
      emisorId: 1,
      Serie: 'A',
      Folio: '1002',
      Fecha: '2020-01-01T12:16:33.229Z',
      Total: 1113.94,
      SubTotal: 1128.68,
      Descuento: null,
      FormaPago: '25',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20128',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      // UUID: 'b1D7e2Ff-37fE-0f3f-cC5C-9F265De3f67B',
      UUID: '',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 2,
        invoiceId: 1003,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 2,
        invoiceId: 1003,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Conceptos: [
        {
          id: 4,
          invoiceId: 1003,
          ClaveProdServ: '10402874',
          NoIdentificacion: 'SYQR-3535',
          Cantidad: 1,
          ClaveUnidad: 'PTD',
          Unidad: '',
          Descripcion: 'FORMATO CARNET DE OPERADOR',
          ValorUnitario: 651.53,
          Importe: 651.53,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 4,
                  Base: 651.53,
                  Impuesto: '003',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.250000',
                  Importe: 162.88
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        },
        {
          id: 5,
          invoiceId: 1003,
          ClaveProdServ: '10313509',
          NoIdentificacion: 'BLTH-0899',
          Cantidad: 1,
          ClaveUnidad: 'M14',
          Unidad: '',
          Descripcion: 'TABLERO DE CONTROL ELECTRICO 5 HP, INCLUYE CONEXION A MOTOR',
          ValorUnitario: 140.27,
          Importe: 140.27,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 5,
                  Base: 140.27,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.160000',
                  Importe: 22.44
                }
              ],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 5,
                  Base: 140.27,
                  Impuesto: '003',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.060000',
                  Importe: 8.42
                },
                {
                  id: 0,
                  invoiceConceptoId: 5,
                  Base: 140.27,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.150000',
                  Importe: 21.04
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        },
        {
          id: 6,
          invoiceId: 1003,
          ClaveProdServ: '10302201',
          NoIdentificacion: 'TDIA-6768',
          Cantidad: 1,
          ClaveUnidad: '37',
          Unidad: '',
          Descripcion: 'CHALECO GETC DGVT165M HOODIE',
          ValorUnitario: 21.35,
          Importe: 21.35,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 6,
                  Base: 21.35,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.070000',
                  Importe: 1.49
                },
                {
                  id: 0,
                  invoiceConceptoId: 6,
                  Base: 21.35,
                  Impuesto: '001',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.070000',
                  Importe: 1.49
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        },
        {
          id: 7,
          invoiceId: 1003,
          ClaveProdServ: '10317101',
          NoIdentificacion: null,
          Cantidad: 1,
          ClaveUnidad: 'B4',
          Unidad: '',
          Descripcion: 'FLETE DE HARINA FAA-169433',
          ValorUnitario: 315.53,
          Importe: 315.53,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 7,
                  Base: 315.53,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.160000',
                  Importe: 50.48
                }
              ],
              Retenciones: []
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.160000',
              Importe: 72.92
            }
          ],
          Retenciones: [
            {
              id: 0,
              Impuesto: '003',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.250000',
              Importe: 162.88
            },
            {
              id: 0,
              Impuesto: '003',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.060000',
              Importe: 8.42
            },
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.150000',
              Importe: 21.04
            },
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.070000',
              Importe: 1.49
            },
            {
              id: 0,
              Impuesto: '001',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.070000',
              Importe: 1.49
            }
          ],
          TotalImpuestosTrasladados: 72.92,
          TotalImpuestosRetenidos: 195.32
        }
      ],
      Complementos: {
        TimbreFiscalDigital: {
          invoiceId: 1003,
          Version: '1.1',
          UUID: 'b1D7e2Ff-37fE-0f3f-cC5C-9F265De3f67B',
          FechaTimbrado: '',
          RfcProvCertif: '',
          Leyenda: '',
          SelloCFD: '',
          NoCertificadoSAT: '',
          SelloSAT: '',
        }
      },
      CfdiRelacionados: [],
      Addendas: []
    },
    {
      id: 1004,
      emisorId: 1,
      Serie: 'A',
      Folio: '1003',
      Fecha: '2020-01-01T12:16:33.229Z',
      Total: 323.47,
      SubTotal: 283.75,
      Descuento: null,
      FormaPago: '30',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20128',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      // UUID: 'cb2f0adf-F9e9-F04a-55b5-dbaAaCC447cb',
      UUID: '',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 3,
        invoiceId: 1004,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 3,
        invoiceId: 1004,
        Rfc: 'OMB202234YG5',
        Nombre: 'Kordula Stenet',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Conceptos: [
        {
          id: 8,
          invoiceId: 1004,
          ClaveProdServ: '10423003',
          NoIdentificacion: 'INOZ-8623',
          Cantidad: 1,
          ClaveUnidad: 'S4',
          Unidad: '',
          Descripcion: 'HONORARIOS MEDICOS',
          ValorUnitario: 283.75,
          Importe: 283.75,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 8,
                  Base: 283.75,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.160000',
                  Importe: 45.4
                }
              ],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 8,
                  Base: 283.75,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.020000',
                  Importe: 5.68
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.160000',
              Importe: 45.4
            }
          ],
          Retenciones: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.020000',
              Importe: 5.68
            }
          ],
          TotalImpuestosTrasladados: 45.4,
          TotalImpuestosRetenidos: 5.68
        }
      ],
      Complementos: {
        TimbreFiscalDigital: {
          invoiceId: 1004,
          Version: '1.1',
          UUID: 'cb2f0adf-F9e9-F04a-55b5-dbaAaCC447cb',
          FechaTimbrado: '',
          RfcProvCertif: '',
          Leyenda: '',
          SelloCFD: '',
          NoCertificadoSAT: '',
          SelloSAT: '',
        }
      },
      CfdiRelacionados: [],
      Addendas: []
    },
    {
      id: 1005,
      emisorId: 1,
      Serie: 'A',
      Folio: '1004',
      Fecha: '2020-01-01T12:16:33.229Z',
      Total: 1044.82,
      SubTotal: 967.43,
      Descuento: null,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20128',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      // UUID: '8593e0c0-aFbF-4c72-16ba-eefb01b37575',
      UUID: '',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 4,
        invoiceId: 1005,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 4,
        invoiceId: 1005,
        Rfc: 'BFY295234AX1',
        Nombre: 'Siward Franzonetti',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Conceptos: [
        {
          id: 9,
          invoiceId: 1005,
          ClaveProdServ: '10217915',
          NoIdentificacion: 'GSAT-9749',
          Cantidad: 1,
          ClaveUnidad: 'N2',
          Unidad: '',
          Descripcion: 'HONORARIOS PROFESIONALES DEL MES DE FEBRERO DE 2020, POR SERVICIOS DE CONTABILIDAD.',
          ValorUnitario: 967.43,
          Importe: 967.43,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 9,
                  Base: 967.43,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.160000',
                  Importe: 154.79
                }
              ],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 9,
                  Base: 967.43,
                  Impuesto: '003',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.080000',
                  Importe: 77.39
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.160000',
              Importe: 154.79
            }
          ],
          Retenciones: [
            {
              id: 0,
              Impuesto: '003',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.080000',
              Importe: 77.39
            }
          ],
          TotalImpuestosTrasladados: 154.79,
          TotalImpuestosRetenidos: 77.39
        }
      ],
      Complementos: {
        TimbreFiscalDigital: {
          invoiceId: 1005,
          Version: '1.1',
          UUID: '8593e0c0-aFbF-4c72-16ba-eefb01b37575',
          FechaTimbrado: '',
          RfcProvCertif: '',
          Leyenda: '',
          SelloCFD: '',
          NoCertificadoSAT: '',
          SelloSAT: '',
        }
      },
      CfdiRelacionados: [],
      Addendas: []
    },
    {
      id: 1006,
      emisorId: 1,
      Serie: 'C',
      Folio: 18,
      Fecha: '2020-01-01T12:16:33.462Z',
      Total: 805.68,
      SubTotal: 895.2,
      Descuento: null,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20128',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      // UUID: '67Af35CD-de1B-Ad6d-D9Ef-0B2abFDE83aD',
      UUID: '',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 1006,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 5,
        invoiceId: 1006,
        Rfc: 'DBC699926ZX5',
        Nombre: 'Lelah Crosby',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Conceptos: [
        {
          id: 10,
          invoiceId: 1006,
          ClaveProdServ: '12171608',
          NoIdentificacion: null,
          Cantidad: 1,
          ClaveUnidad: 'XBX',
          Unidad: '',
          Descripcion: 'JAMON MASTER CARVE POR KILO KIRKLAND SIGNATURE',
          ValorUnitario: 895.2,
          Importe: 895.2,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 10,
                  Base: 895.2,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.000000',
                  Importe: 0
                }
              ],
              Retenciones: [
                {
                  id: 0,
                  invoiceConceptoId: 10,
                  Base: 895.2,
                  Impuesto: '003',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.060000',
                  Importe: 53.71
                },
                {
                  id: 0,
                  invoiceConceptoId: 10,
                  Base: 895.2,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.040000',
                  Importe: 35.81
                },
                {
                  id: 0,
                  invoiceConceptoId: 10,
                  Base: 895.2,
                  Impuesto: '001',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.160000',
                  Importe: 143.23
                }
              ]
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.000000',
              Importe: 0
            }
          ],
          Retenciones: [
            {
              id: 0,
              Impuesto: '003',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.060000',
              Importe: 53.71
            },
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.040000',
              Importe: 35.81
            },
            {
              id: 0,
              Impuesto: '001',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.160000',
              Importe: 143.23
            }
          ],
          TotalImpuestosTrasladados: 0,
          TotalImpuestosRetenidos: 232.75
        }
      ],
      Complementos: {
        TimbreFiscalDigital: {
          invoiceId: 1006,
          Version: '1.1',
          UUID: '67Af35CD-de1B-Ad6d-D9Ef-0B2abFDE83aD',
          FechaTimbrado: '',
          RfcProvCertif: '',
          Leyenda: '',
          SelloCFD: '',
          NoCertificadoSAT: '',
          SelloSAT: '',
        }
      },
      CfdiRelacionados: [],
      Addendas: []
    },
    {
      id: 1007,
      emisorId: 1,
      Serie: 'C',
      Folio: '19',
      Fecha: '2020-01-01T12:16:33.462Z',
      Total: 295.31,
      SubTotal: 254.58,
      Descuento: null,
      FormaPago: '26',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20128',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      // UUID: 'e44ef6de-4ce2-73Ce-EacF-D3c0dDFCba70',
      UUID: '',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 1007,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 6,
        invoiceId: 1007,
        Rfc: 'OEZ512209FH9',
        Nombre: 'Jessie Goroni',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Conceptos: [
        {
          id: 11,
          invoiceId: 1007,
          ClaveProdServ: '10413405',
          NoIdentificacion: null,
          Cantidad: 1,
          ClaveUnidad: 'MBE',
          Unidad: '',
          Descripcion: 'XOLOPASS ZONA 1 (Nueva)',
          ValorUnitario: 254.58,
          Importe: 254.58,
          Descuento: null,
          Impuestos: [
            {
              Traslados: [
                {
                  id: 0,
                  invoiceConceptoId: 11,
                  Base: 254.58,
                  Impuesto: '002',
                  TipoFactor: 'Tasa',
                  TasaOCuota: '0.000000',
                  Importe: 0
                }
              ],
              Retenciones: []
            }
          ],
          CuentaPredial: [],
          InformacionAduanera: [],
          ComplementoConcepto: [],
          Parte: []
        }
      ],
      Impuestos: [
        {
          Traslados: [
            {
              id: 0,
              Impuesto: '002',
              TipoFactor: 'Tasa',
              TasaOCuota: '0.000000',
              Importe: 0
            }
          ],
          Retenciones: [],
          TotalImpuestosTrasladados: 0,
          TotalImpuestosRetenidos: 0
        }
      ],
      Complementos: {
        TimbreFiscalDigital: {
          invoiceId: 1007,
          Version: '1.1',
          UUID: 'e44ef6de-4ce2-73Ce-EacF-D3c0dDFCba70',
          FechaTimbrado: '',
          RfcProvCertif: '',
          Leyenda: '',
          SelloCFD: '',
          NoCertificadoSAT: '',
          SelloSAT: '',
        }
      },
      CfdiRelacionados: [],
      Addendas: []
    },
    {
      id: 1,
      emisorId: 1,
      Serie: 'B',
      Folio: '1864',
      Fecha: '2020-01-09T12:16:33.229Z',
      SubTotal: 6110.22,
      Descuento: null,
      Total: 6110.22,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PPD',
      LugarExpedicion: '20482',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Ba6eBEa9-c7e0-27F1-2913-a17ddcbA2Cfc',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 1,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 7,
        invoiceId: 1,
        Rfc: 'GBD806083LM6',
        Nombre: 'Tracie Opy',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 2,
      emisorId: 1,
      Serie: 'B',
      Folio: '1819',
      Fecha: '2020-03-31T12:16:33.229Z',
      SubTotal: 8388.81,
      Descuento: null,
      Total: 8388.81,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PPD',
      LugarExpedicion: '20816',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '8ef8d11A-d2Fe-2057-2fC0-deF1Bab3C6f6',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 2,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 8,
        invoiceId: 2,
        Rfc: 'OLZ586073SX0',
        Nombre: 'Julietta Lantiff',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 3,
      emisorId: 1,
      Serie: 'C',
      Folio: '1892',
      Fecha: '2020-04-04T12:16:33.229Z',
      SubTotal: 1694.75,
      Descuento: null,
      Total: 1694.75,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PPD',
      LugarExpedicion: '20657',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'd7bAD2dA-5bFc-e37b-AFa8-C3F3BDedbD4d',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 3,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 9,
        invoiceId: 3,
        Rfc: 'FIG317399TA7',
        Nombre: 'Malva Bortoli',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 4,
      emisorId: 1,
      Serie: 'C',
      Folio: '1118',
      Fecha: '2020-01-31T12:16:33.229Z',
      SubTotal: 6361.27,
      Descuento: null,
      Total: 6361.27,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PPD',
      LugarExpedicion: '20609',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '80Cff23f-45AE-B25F-153a-ab108853f96b',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 4,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 10,
        invoiceId: 4,
        Rfc: 'BTG953167EY6',
        Nombre: 'Moore Ludman',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 5,
      emisorId: 1,
      Serie: 'B',
      Folio: '1254',
      Fecha: '2020-03-11T12:16:33.229Z',
      SubTotal: 7509.15,
      Descuento: null,
      Total: 7509.15,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20126',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '97498df1-C5C0-fAFD-7a7c-Dbe1e2D4AAcD',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 5,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 11,
        invoiceId: 5,
        Rfc: 'QYN073049SA0',
        Nombre: 'Sanson Crotty',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 6,
      emisorId: 1,
      Serie: 'C',
      Folio: '1242',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 6763.99,
      Descuento: null,
      Total: 6763.99,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20701',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '1a8Abc8A-FBA5-Cdb4-AAbc-F7DCfcDEc610',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 6,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 12,
        invoiceId: 6,
        Rfc: 'PHT265369SX9',
        Nombre: 'Alla Terry',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 7,
      emisorId: 1,
      Serie: 'B',
      Folio: '1580',
      Fecha: '2020-02-17T12:16:33.229Z',
      SubTotal: 3939.82,
      Descuento: null,
      Total: 3939.82,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20568',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'C4Ee2Ea8-aaA0-cFda-f1aC-F36eeb1Ca3bE',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 7,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 13,
        invoiceId: 7,
        Rfc: 'LMH667499BQ7',
        Nombre: 'Pearline Deplacido',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 8,
      emisorId: 1,
      Serie: 'A',
      Folio: '1483',
      Fecha: '2020-04-03T12:16:33.229Z',
      SubTotal: 8021.98,
      Descuento: null,
      Total: 8021.98,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20537',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '2e955C1c-5Ac4-471E-8e5e-F662951008A4',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 8,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 14,
        invoiceId: 8,
        Rfc: 'YIP197260ZQ6',
        Nombre: 'Chandra Pearn',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 9,
      emisorId: 1,
      Serie: 'A',
      Folio: '1582',
      Fecha: '2020-04-08T12:16:33.229Z',
      SubTotal: 7039.03,
      Descuento: null,
      Total: 7039.03,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20880',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Df5fEC59-d2DD-3eAD-258F-f326ACd74eCE',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 9,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 15,
        invoiceId: 9,
        Rfc: 'DQI718920XN7',
        Nombre: 'Chicky Ruthen',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 10,
      emisorId: 1,
      Serie: 'B',
      Folio: '1984',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 8015.72,
      Descuento: null,
      Total: 8015.72,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20633',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '2C1C4FAA-ba73-0D58-Cf46-E6471Ea1b0c2',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 10,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 16,
        invoiceId: 10,
        Rfc: 'ITZ786522XW1',
        Nombre: 'Woodie Newson',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 11,
      emisorId: 1,
      Serie: 'C',
      Folio: '1458',
      Fecha: '2020-04-06T12:16:33.229Z',
      SubTotal: 5403.11,
      Descuento: null,
      Total: 5403.11,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20033',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '52D20b26-4DD1-Ef1D-EEE6-80afF449CFAF',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 11,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 17,
        invoiceId: 11,
        Rfc: 'VAT092530TE7',
        Nombre: 'Jameson Mayne',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 12,
      emisorId: 1,
      Serie: 'A',
      Folio: '1486',
      Fecha: '2020-02-08T12:16:33.229Z',
      SubTotal: 6702.91,
      Descuento: null,
      Total: 6702.91,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20192',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'f5eAFd20-6F35-92Da-8262-8194B4AB4F7C',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 12,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 18,
        invoiceId: 12,
        Rfc: 'PVE721978XC6',
        Nombre: 'Scarlett Hamley',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 13,
      emisorId: 1,
      Serie: 'C',
      Folio: '1307',
      Fecha: '2020-04-14T12:16:33.229Z',
      SubTotal: 2617.63,
      Descuento: null,
      Total: 2617.63,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20170',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '52BBC74a-7bc3-0AD0-5Bad-7d287a64d1A4',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 13,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 19,
        invoiceId: 13,
        Rfc: 'VYJ316745FM5',
        Nombre: 'Garvey Ekins',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 14,
      emisorId: 1,
      Serie: 'A',
      Folio: '1565',
      Fecha: '2020-04-05T12:16:33.229Z',
      SubTotal: 2916.92,
      Descuento: null,
      Total: 2916.92,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20461',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'aEd6Ba84-58Eb-6c13-e316-E9c7bEaB69A5',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 14,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 20,
        invoiceId: 14,
        Rfc: 'AMH798626CG7',
        Nombre: 'Cora Cretney',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 15,
      emisorId: 1,
      Serie: 'C',
      Folio: '1060',
      Fecha: '2020-02-08T12:16:33.229Z',
      SubTotal: 8210.07,
      Descuento: null,
      Total: 8210.07,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20164',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'a79b68b7-3CeA-618E-F47E-72e42F0c817e',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 15,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 21,
        invoiceId: 15,
        Rfc: 'LHY362740FC0',
        Nombre: 'Carolyn Raecroft',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 16,
      emisorId: 1,
      Serie: 'C',
      Folio: '1210',
      Fecha: '2020-04-12T12:16:33.229Z',
      SubTotal: 3275.79,
      Descuento: null,
      Total: 3275.79,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20069',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Ab59D1fA-2AFe-BA22-B3bE-eA89F50F8Dd8',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 16,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 22,
        invoiceId: 16,
        Rfc: 'CBP434650RV7',
        Nombre: 'Stefan Birtle',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 17,
      emisorId: 1,
      Serie: 'A',
      Folio: '1995',
      Fecha: '2020-01-06T12:16:33.229Z',
      SubTotal: 1750.89,
      Descuento: null,
      Total: 1750.89,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20597',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '756a29fe-9Eea-D8Ad-4F56-dc2e4ca9cad0',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 17,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 23,
        invoiceId: 17,
        Rfc: 'AGO184818LA6',
        Nombre: 'Patty McNish',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 18,
      emisorId: 1,
      Serie: 'A',
      Folio: '1394',
      Fecha: '2020-01-05T12:16:33.229Z',
      SubTotal: 3369.5,
      Descuento: null,
      Total: 3369.5,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20471',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'e684016E-7fc3-b57b-5a7f-ca40344B51Fc',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 18,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 24,
        invoiceId: 18,
        Rfc: 'MBZ385751VJ0',
        Nombre: 'Gav Kopke',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 19,
      emisorId: 1,
      Serie: 'C',
      Folio: '1143',
      Fecha: '2020-03-10T12:16:33.229Z',
      SubTotal: 3687.14,
      Descuento: null,
      Total: 3687.14,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20186',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'dAc70AF7-38A3-bD40-83a5-8bcf363a9Af6',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 19,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 25,
        invoiceId: 19,
        Rfc: 'GVR549981ED4',
        Nombre: 'Gabbi O\'Farrell',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 20,
      emisorId: 1,
      Serie: 'C',
      Folio: '1421',
      Fecha: '2020-03-12T12:16:33.229Z',
      SubTotal: 5848.67,
      Descuento: null,
      Total: 5848.67,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20352',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'f717d3Bf-65a1-fbbd-6ae6-0EBAa40e91DC',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 20,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 26,
        invoiceId: 20,
        Rfc: 'NYF978913PH9',
        Nombre: 'Kale Tomasicchio',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 21,
      emisorId: 1,
      Serie: 'C',
      Folio: '1106',
      Fecha: '2020-04-08T12:16:33.229Z',
      SubTotal: 3506.0,
      Descuento: null,
      Total: 3506.0,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20312',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '55126491-04aB-Bde9-F8D5-204c2b597C9F',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 21,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 27,
        invoiceId: 21,
        Rfc: 'EGS253709NF5',
        Nombre: 'Tierney Paul',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 22,
      emisorId: 1,
      Serie: 'C',
      Folio: '1917',
      Fecha: '2020-04-14T12:16:33.229Z',
      SubTotal: 3000.71,
      Descuento: null,
      Total: 3000.71,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20244',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'a8f62145-318B-e5BD-dfAd-e3eE7EbD7BBF',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 22,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 28,
        invoiceId: 22,
        Rfc: 'YGA330505SW3',
        Nombre: 'Andy Lideard',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 23,
      emisorId: 1,
      Serie: 'B',
      Folio: '1695',
      Fecha: '2020-04-10T12:16:33.229Z',
      SubTotal: 4325.9,
      Descuento: null,
      Total: 4325.9,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20816',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '6A0969Ef-3c46-b63D-d6BE-4Dbc3eAfD2ee',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 23,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 29,
        invoiceId: 23,
        Rfc: 'PKD328653HP8',
        Nombre: 'Winne Simmgen',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 24,
      emisorId: 1,
      Serie: 'C',
      Folio: '1262',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 5349.26,
      Descuento: null,
      Total: 5349.26,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20180',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'BfC16b7A-5F96-cc1f-2Fb0-f9Cc1ae92E1E',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 24,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 30,
        invoiceId: 24,
        Rfc: 'CWR845072AM8',
        Nombre: 'Casandra Frere',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 25,
      emisorId: 1,
      Serie: 'A',
      Folio: '1418',
      Fecha: '2020-04-02T12:16:33.229Z',
      SubTotal: 7721.18,
      Descuento: null,
      Total: 7721.18,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20742',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '5cf256D5-3396-a25C-c248-66b1Ea841eca',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 25,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 31,
        invoiceId: 25,
        Rfc: 'PMT823351GS3',
        Nombre: 'Juan Sillis',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 26,
      emisorId: 1,
      Serie: 'A',
      Folio: '1874',
      Fecha: '2020-03-10T12:16:33.229Z',
      SubTotal: 7528.19,
      Descuento: null,
      Total: 7528.19,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20865',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '25caeDf9-dacd-ea7a-e795-9aBcCf43d284',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 26,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 32,
        invoiceId: 26,
        Rfc: 'JGY545877WE5',
        Nombre: 'Freida Smethurst',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 27,
      emisorId: 1,
      Serie: 'C',
      Folio: '1035',
      Fecha: '2020-03-18T12:16:33.229Z',
      SubTotal: 6615.65,
      Descuento: null,
      Total: 6615.65,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20924',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'c20aEa61-fFbE-bAc9-BFb4-0A028e6898B6',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 27,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 33,
        invoiceId: 27,
        Rfc: 'ZRO289917AN2',
        Nombre: 'Saunder Bevington',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 28,
      emisorId: 1,
      Serie: 'C',
      Folio: '1870',
      Fecha: '2020-04-07T12:16:33.229Z',
      SubTotal: 9261.4,
      Descuento: null,
      Total: 9261.4,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20074',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'ce118ddB-ec6f-f7cE-dc89-3bD1B9A26579',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 28,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 34,
        invoiceId: 28,
        Rfc: 'DUP456334PX6',
        Nombre: 'Tana Le Gall',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 29,
      emisorId: 1,
      Serie: 'B',
      Folio: '1904',
      Fecha: '2020-04-15T12:16:33.229Z',
      SubTotal: 1379.33,
      Descuento: null,
      Total: 1379.33,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20412',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '2fBF35Ad-7442-Dcb1-0d32-C72363451ffA',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 29,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 35,
        invoiceId: 29,
        Rfc: 'RGD161788CJ2',
        Nombre: 'Elsworth Aspden',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 30,
      emisorId: 1,
      Serie: 'C',
      Folio: '1543',
      Fecha: '2020-04-17T12:16:33.229Z',
      SubTotal: 9882.81,
      Descuento: null,
      Total: 9882.81,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20422',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '3b5D0FE8-d06D-4c02-4fd7-Ea7BBF4dA81c',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 30,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 36,
        invoiceId: 30,
        Rfc: 'HJP766630BC4',
        Nombre: 'Stacy Calway',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 31,
      emisorId: 1,
      Serie: 'B',
      Folio: '1996',
      Fecha: '2020-04-19T12:16:33.229Z',
      SubTotal: 6902.21,
      Descuento: null,
      Total: 6902.21,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20626',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'e213C2e9-3Eb1-509E-8a7c-111e331Bd02D',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 31,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 37,
        invoiceId: 31,
        Rfc: 'RXH359567WA7',
        Nombre: 'Jehu Shreeve',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 32,
      emisorId: 1,
      Serie: 'C',
      Folio: '1102',
      Fecha: '2020-02-03T12:16:33.229Z',
      SubTotal: 9816.06,
      Descuento: null,
      Total: 9816.06,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20764',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '97F9a87E-D562-ca9D-BaE8-FF96DBae9f3d',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 32,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 38,
        invoiceId: 32,
        Rfc: 'EXA266456SC8',
        Nombre: 'Padraic Cordobes',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 33,
      emisorId: 1,
      Serie: 'B',
      Folio: '1617',
      Fecha: '2020-03-17T12:16:33.229Z',
      SubTotal: 1000.77,
      Descuento: null,
      Total: 1000.77,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20793',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '704d877d-f5Ba-dDcf-FAec-75FbD39b82D7',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 33,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 39,
        invoiceId: 33,
        Rfc: 'JLC478339VG7',
        Nombre: 'Valida Held',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 34,
      emisorId: 1,
      Serie: 'C',
      Folio: '1839',
      Fecha: '2020-04-04T12:16:33.229Z',
      SubTotal: 8279.14,
      Descuento: null,
      Total: 8279.14,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20230',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '29A5FAaa-cad0-37AA-c2e3-dadCD4d56D28',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 34,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 40,
        invoiceId: 34,
        Rfc: 'PAG668198EI0',
        Nombre: 'Winthrop Willoughby',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 35,
      emisorId: 1,
      Serie: 'B',
      Folio: '1135',
      Fecha: '2020-04-09T12:16:33.229Z',
      SubTotal: 5916.21,
      Descuento: null,
      Total: 5916.21,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20479',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '6d00B1dE-F266-80dc-D909-2EeE9eD56dE9',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 35,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 41,
        invoiceId: 35,
        Rfc: 'ENT932888FT0',
        Nombre: 'Shandie Blood',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 36,
      emisorId: 1,
      Serie: 'A',
      Folio: '1372',
      Fecha: '2020-03-13T12:16:33.229Z',
      SubTotal: 3250.01,
      Descuento: null,
      Total: 3250.01,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20160',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '7C0F1bbb-A15a-5a9b-cca9-78f9B85bcCeA',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 36,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 42,
        invoiceId: 36,
        Rfc: 'NGR239302GK4',
        Nombre: 'Robin Springthorpe',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 37,
      emisorId: 1,
      Serie: 'A',
      Folio: '1879',
      Fecha: '2020-04-19T12:16:33.229Z',
      SubTotal: 8996.06,
      Descuento: null,
      Total: 8996.06,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20096',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '40B7cD9f-eBc4-849b-C0CF-0F63Fa4Ac180',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 37,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 43,
        invoiceId: 37,
        Rfc: 'ZAR536931BW9',
        Nombre: 'Huey Keningley',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 38,
      emisorId: 1,
      Serie: 'C',
      Folio: '1676',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 2276.13,
      Descuento: null,
      Total: 2276.13,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20898',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'bbeF2e6b-5E9F-ff8f-AAFc-6dddF4237cE9',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 38,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 44,
        invoiceId: 38,
        Rfc: 'SQX610137LZ8',
        Nombre: 'Ernaline Ovey',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 39,
      emisorId: 1,
      Serie: 'C',
      Folio: '1653',
      Fecha: '2020-02-11T12:16:33.229Z',
      SubTotal: 6208.22,
      Descuento: null,
      Total: 6208.22,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20000',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '3dC3B9bb-2415-Aa51-5FC4-c9B6daff88d8',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 39,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 45,
        invoiceId: 39,
        Rfc: 'GRU005009YS7',
        Nombre: 'Othilia Lamboll',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 40,
      emisorId: 1,
      Serie: 'C',
      Folio: '1071',
      Fecha: '2020-04-06T12:16:33.229Z',
      SubTotal: 1748.68,
      Descuento: null,
      Total: 1748.68,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20171',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'B4a23816-E815-b12d-d17b-ACD0E8cABCA9',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 40,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 46,
        invoiceId: 40,
        Rfc: 'HFB269404JI1',
        Nombre: 'Aland Mathouse',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 41,
      emisorId: 1,
      Serie: 'C',
      Folio: '1789',
      Fecha: '2020-04-25T12:16:33.229Z',
      SubTotal: 1296.49,
      Descuento: null,
      Total: 1296.49,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20827',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'e597758A-8B5B-1a4e-EBef-5350cEF3532B',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 41,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 47,
        invoiceId: 41,
        Rfc: 'IOA282581MF7',
        Nombre: 'Darren Trodden',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 42,
      emisorId: 1,
      Serie: 'B',
      Folio: '1440',
      Fecha: '2020-01-12T12:16:33.229Z',
      SubTotal: 8403.25,
      Descuento: null,
      Total: 8403.25,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20454',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'eDC8d5c7-b03b-66Fa-92Dd-AD3e952fc9a1',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 42,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 48,
        invoiceId: 42,
        Rfc: 'IOL667154GK4',
        Nombre: 'Kingsley Solesbury',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 43,
      emisorId: 1,
      Serie: 'A',
      Folio: '1656',
      Fecha: '2020-02-14T12:16:33.229Z',
      SubTotal: 1977.11,
      Descuento: null,
      Total: 1977.11,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20590',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '3E5d9e5F-Fa67-653D-267c-4F74654dee1d',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 43,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 49,
        invoiceId: 43,
        Rfc: 'HFV581301GX6',
        Nombre: 'Deane McMurray',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 44,
      emisorId: 1,
      Serie: 'C',
      Folio: '1039',
      Fecha: '2020-03-04T12:16:33.229Z',
      SubTotal: 6775.4,
      Descuento: null,
      Total: 6775.4,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20549',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'B590AAf5-B4de-06EE-4f57-Ea5ABeBEEEaD',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 44,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 50,
        invoiceId: 44,
        Rfc: 'QBT605706YM4',
        Nombre: 'Slade McKomb',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 45,
      emisorId: 1,
      Serie: 'C',
      Folio: '1075',
      Fecha: '2020-02-06T12:16:33.229Z',
      SubTotal: 4100.04,
      Descuento: null,
      Total: 4100.04,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20774',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'eFC7E92d-CadA-E842-4B2F-18AFa35CeA56',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 45,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 51,
        invoiceId: 45,
        Rfc: 'KSG404556ZV3',
        Nombre: 'Vanni Trewinnard',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 46,
      emisorId: 1,
      Serie: 'C',
      Folio: '1815',
      Fecha: '2020-02-01T12:16:33.229Z',
      SubTotal: 4924.66,
      Descuento: null,
      Total: 4924.66,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20405',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'F929bd65-e8eF-93D4-6D7A-1DF5ECE6e025',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 46,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 52,
        invoiceId: 46,
        Rfc: 'OQH624238QG6',
        Nombre: 'Juliette Liddiard',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 47,
      emisorId: 1,
      Serie: 'C',
      Folio: '1725',
      Fecha: '2020-01-13T12:16:33.229Z',
      SubTotal: 5060.23,
      Descuento: null,
      Total: 5060.23,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20740',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '60E14ebD-0869-BfB5-9AAE-1cFae32798Ba',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 47,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 53,
        invoiceId: 47,
        Rfc: 'OFH437472KS3',
        Nombre: 'Ingmar Caine',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 48,
      emisorId: 1,
      Serie: 'C',
      Folio: '1299',
      Fecha: '2020-03-03T12:16:33.229Z',
      SubTotal: 1960.61,
      Descuento: null,
      Total: 1960.61,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20162',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'E021Ed36-eE73-3E79-9ACA-bF768768A3a0',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 48,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 54,
        invoiceId: 48,
        Rfc: 'ONL368568EM7',
        Nombre: 'Meredith Edington',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 49,
      emisorId: 1,
      Serie: 'A',
      Folio: '1639',
      Fecha: '2020-03-07T12:16:33.229Z',
      SubTotal: 1097.59,
      Descuento: null,
      Total: 1097.59,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20371',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'd35D6Ad4-BD5f-2808-D7d0-0Cdb199E0439',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 49,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 55,
        invoiceId: 49,
        Rfc: 'ADR657456ZS5',
        Nombre: 'Shelton Heelis',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 50,
      emisorId: 1,
      Serie: 'C',
      Folio: '1576',
      Fecha: '2020-04-09T12:16:33.229Z',
      SubTotal: 4724.87,
      Descuento: null,
      Total: 4724.87,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20646',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '4266c83F-B752-acDd-22d8-AB5DfD4BFc6c',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 5,
        invoiceId: 50,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 56,
        invoiceId: 50,
        Rfc: 'EBQ008771LP2',
        Nombre: 'Tobin Jacqueme',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 51,
      emisorId: 1,
      Serie: 'C',
      Folio: '1931',
      Fecha: '2020-03-13T12:16:33.229Z',
      SubTotal: 9258.98,
      Descuento: null,
      Total: 9258.98,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20643',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '28ADbfea-0B3D-e1c4-f5ab-cEB9B7CC9C2E',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 52,
      emisorId: 1,
      Serie: 'C',
      Folio: '1124',
      Fecha: '2020-04-05T12:16:33.229Z',
      SubTotal: 7766.55,
      Descuento: null,
      Total: 7766.55,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20275',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Ff47679b-C5Fa-cC6F-f2b2-99FeE713BD86',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 53,
      emisorId: 1,
      Serie: 'C',
      Folio: '1181',
      Fecha: '2020-02-15T12:16:33.229Z',
      SubTotal: 7442.94,
      Descuento: null,
      Total: 7442.94,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20816',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '8b0952de-cC19-bAA3-4cD4-08Bb30eaC77E',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 54,
      emisorId: 1,
      Serie: 'C',
      Folio: '1400',
      Fecha: '2020-02-02T12:16:33.229Z',
      SubTotal: 3659.59,
      Descuento: null,
      Total: 3659.59,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20416',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '64772aFf-f58E-cbFc-9506-920A5Dd9EbB2',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 55,
      emisorId: 1,
      Serie: 'A',
      Folio: '1327',
      Fecha: '2020-02-16T12:16:33.229Z',
      SubTotal: 8842.89,
      Descuento: null,
      Total: 8842.89,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20288',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '9D02F97a-aBb7-F13E-E4Bd-7fA2C5ADf387',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 56,
      emisorId: 1,
      Serie: 'A',
      Folio: '1639',
      Fecha: '2020-04-17T12:16:33.229Z',
      SubTotal: 5213.58,
      Descuento: null,
      Total: 5213.58,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20271',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'e8a47834-FF1f-0e4A-fB34-FF7B0fd0846A',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 57,
      emisorId: 1,
      Serie: 'C',
      Folio: '1035',
      Fecha: '2020-04-15T12:16:33.229Z',
      SubTotal: 9688.35,
      Descuento: null,
      Total: 9688.35,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20599',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Cbf08Eb7-fd57-245E-F1d1-21c26b60af1A',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 58,
      emisorId: 1,
      Serie: 'A',
      Folio: '1200',
      Fecha: '2020-04-01T12:16:33.229Z',
      SubTotal: 6343.0,
      Descuento: null,
      Total: 6343.0,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PPD',
      LugarExpedicion: '20851',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '8a8491f2-c72c-Bc6A-7EC7-6b285Ea57cE0',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 59,
      emisorId: 1,
      Serie: 'B',
      Folio: '1212',
      Fecha: '2020-04-12T12:16:33.229Z',
      SubTotal: 9931.39,
      Descuento: null,
      Total: 9931.39,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20163',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '695DEDfd-Cf26-6f29-ac5d-be5f02453bC9',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 60,
      emisorId: 1,
      Serie: 'A',
      Folio: '1724',
      Fecha: '2020-04-13T12:16:33.229Z',
      SubTotal: 3141.58,
      Descuento: null,
      Total: 3141.58,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20938',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'ff6ABc7A-D34f-C04f-aFAd-cAFe2403086A',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 61,
      emisorId: 1,
      Serie: 'C',
      Folio: '1190',
      Fecha: '2020-03-08T12:16:33.229Z',
      SubTotal: 5182.64,
      Descuento: null,
      Total: 5182.64,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20741',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'AfBFc31b-Adf1-7AaD-fCFF-f48Eac1C75EE',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 62,
      emisorId: 1,
      Serie: 'B',
      Folio: '1174',
      Fecha: '2020-01-06T12:16:33.229Z',
      SubTotal: 3133.92,
      Descuento: null,
      Total: 3133.92,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20586',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'F8FAd70F-669C-FfBe-bB85-deb48C41728d',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 63,
      emisorId: 1,
      Serie: 'C',
      Folio: '2000',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 2750.41,
      Descuento: null,
      Total: 2750.41,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20490',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'cdECbd61-df55-fEc7-6C9a-d54A0F95FBb0',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 64,
      emisorId: 1,
      Serie: 'A',
      Folio: '1776',
      Fecha: '2020-03-11T12:16:33.229Z',
      SubTotal: 9627.51,
      Descuento: null,
      Total: 9627.51,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20933',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '2446B94B-6DF4-b344-c092-CF8E1fc3A9d2',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 65,
      emisorId: 1,
      Serie: 'A',
      Folio: '1437',
      Fecha: '2020-03-13T12:16:33.229Z',
      SubTotal: 6936.84,
      Descuento: null,
      Total: 6936.84,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20300',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '3e1dd527-B47c-b619-f89A-1BC07dE2b78a',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 66,
      emisorId: 1,
      Serie: 'C',
      Folio: '1887',
      Fecha: '2020-04-05T12:16:33.229Z',
      SubTotal: 9522.47,
      Descuento: null,
      Total: 9522.47,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20623',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '58A146Bd-0110-f9AB-E8Db-9eEbC5A95fBd',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 67,
      emisorId: 1,
      Serie: 'A',
      Folio: '1808',
      Fecha: '2020-04-13T12:16:33.229Z',
      SubTotal: 4542.0,
      Descuento: null,
      Total: 4542.0,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20929',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '36DfEA87-B3b2-5fE4-cdd4-01aD00FA5Bfb',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 68,
      emisorId: 1,
      Serie: 'B',
      Folio: '1481',
      Fecha: '2020-01-06T12:16:33.229Z',
      SubTotal: 6565.95,
      Descuento: null,
      Total: 6565.95,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20248',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'cf9dC5c7-fD1d-1DB8-eB2b-Efd65DbEcbA5',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 69,
      emisorId: 1,
      Serie: 'C',
      Folio: '1347',
      Fecha: '2020-03-03T12:16:33.229Z',
      SubTotal: 1016.23,
      Descuento: null,
      Total: 1016.23,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20986',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'bBb6bE62-c220-AcDE-2519-eEfddfdA17eE',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 70,
      emisorId: 1,
      Serie: 'C',
      Folio: '1026',
      Fecha: '2020-04-01T12:16:33.229Z',
      SubTotal: 1265.94,
      Descuento: null,
      Total: 1265.94,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20156',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'bb643B86-a0D7-75aB-96B3-CE6BEf9CA460',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'IJO231544QF1',
        Nombre: 'Adriana Mixhel',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 71,
      emisorId: 1,
      Serie: 'B',
      Folio: '1821',
      Fecha: '2020-03-14T12:16:33.229Z',
      SubTotal: 1544.19,
      Descuento: null,
      Total: 1544.19,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20315',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '33Ea3f3c-9226-6Ff3-Ebd0-D545a31dDf66',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 72,
      emisorId: 1,
      Serie: 'C',
      Folio: '1967',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 5356.55,
      Descuento: null,
      Total: 5356.55,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20571',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'edBeEdB2-F385-4b6F-d8fd-B0E62Ae70Ba9',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 73,
      emisorId: 1,
      Serie: 'C',
      Folio: '1989',
      Fecha: '2020-04-07T12:16:33.229Z',
      SubTotal: 8376.35,
      Descuento: null,
      Total: 8376.35,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20976',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'BB0ea21f-C100-Ff2E-316C-936c77Ad45Be',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 74,
      emisorId: 1,
      Serie: 'C',
      Folio: '1069',
      Fecha: '2020-02-14T12:16:33.229Z',
      SubTotal: 6588.99,
      Descuento: null,
      Total: 6588.99,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20076',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'a198cFe7-CD49-a10F-5E83-14B50EEa2D53',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 75,
      emisorId: 1,
      Serie: 'B',
      Folio: '1174',
      Fecha: '2020-04-12T12:16:33.229Z',
      SubTotal: 8542.46,
      Descuento: null,
      Total: 8542.46,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20710',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '0E81f044-E17a-87dA-fCeb-6eeB9Ac3b74D',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 76,
      emisorId: 1,
      Serie: 'B',
      Folio: '1081',
      Fecha: '2020-04-07T12:16:33.229Z',
      SubTotal: 3448.9,
      Descuento: null,
      Total: 3448.9,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20786',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '30c9CCf1-175b-2A8b-4b4e-63297EadB0fd',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 77,
      emisorId: 1,
      Serie: 'B',
      Folio: '1900',
      Fecha: '2020-01-13T12:16:33.229Z',
      SubTotal: 1903.85,
      Descuento: null,
      Total: 1903.85,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20921',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '1AC0Ffda-11ca-93f1-CD0D-d7abCAfDAD1D',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 78,
      emisorId: 1,
      Serie: 'C',
      Folio: '1612',
      Fecha: '2020-01-18T12:16:33.229Z',
      SubTotal: 1048.55,
      Descuento: null,
      Total: 1048.55,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20182',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '7EDe53BC-24D2-2fB2-2D35-57E57a4EB77f',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 79,
      emisorId: 1,
      Serie: 'C',
      Folio: '1147',
      Fecha: '2020-01-31T12:16:33.229Z',
      SubTotal: 3153.88,
      Descuento: null,
      Total: 3153.88,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20554',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '4eA37A5d-2DaE-BB2f-a0EF-E4BBE5B306d6',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 80,
      emisorId: 1,
      Serie: 'C',
      Folio: '1639',
      Fecha: '2020-04-04T12:16:33.229Z',
      SubTotal: 2251.36,
      Descuento: null,
      Total: 2251.36,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20178',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'C2bD8be2-F547-3A4F-8FCe-C5d46e2cA4EC',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 81,
      emisorId: 1,
      Serie: 'A',
      Folio: '1752',
      Fecha: '2020-04-08T12:16:33.229Z',
      SubTotal: 1227.21,
      Descuento: null,
      Total: 1227.21,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20299',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'aADdDc5F-5DF0-65BC-e2B0-210c0fA82EeA',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 82,
      emisorId: 1,
      Serie: 'A',
      Folio: '1169',
      Fecha: '2020-04-14T12:16:33.229Z',
      SubTotal: 3057.76,
      Descuento: null,
      Total: 3057.76,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20286',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'f72645D1-F6A2-0A67-df78-8C758CA399c4',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 83,
      emisorId: 1,
      Serie: 'C',
      Folio: '1987',
      Fecha: '2020-04-18T12:16:33.229Z',
      SubTotal: 7098.25,
      Descuento: null,
      Total: 7098.25,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20582',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'bDad2C6c-9672-1Ca5-209f-0AB4Eb0931Fc',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 84,
      emisorId: 1,
      Serie: 'C',
      Folio: '1664',
      Fecha: '2020-03-10T12:16:33.229Z',
      SubTotal: 4074.24,
      Descuento: null,
      Total: 4074.24,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20989',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'a81E2ccC-ADba-E8Df-33AB-Cc99B3239e4a',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 85,
      emisorId: 1,
      Serie: 'A',
      Folio: '1776',
      Fecha: '2020-02-02T12:16:33.229Z',
      SubTotal: 1070.12,
      Descuento: null,
      Total: 1070.12,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20763',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'EC73aC04-dA6A-E30d-4E6C-C35A9dAae94F',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 86,
      emisorId: 1,
      Serie: 'B',
      Folio: '1632',
      Fecha: '2020-02-18T12:16:33.229Z',
      SubTotal: 4677.86,
      Descuento: null,
      Total: 4677.86,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20909',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'A40f6B3f-FDf5-bF15-DCeC-BbF82bE5c2fF',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 87,
      emisorId: 1,
      Serie: 'A',
      Folio: '1146',
      Fecha: '2020-03-06T12:16:33.229Z',
      SubTotal: 1387.07,
      Descuento: null,
      Total: 1387.07,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20608',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '7142EBaf-2E2C-EeDF-E00F-0da8c093F6Bf',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 88,
      emisorId: 1,
      Serie: 'B',
      Folio: '1704',
      Fecha: '2020-04-03T12:16:33.229Z',
      SubTotal: 3806.11,
      Descuento: null,
      Total: 3806.11,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20366',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '3FAC34EB-8058-9EeE-4beA-6a7D78BBECa1',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 89,
      emisorId: 1,
      Serie: 'C',
      Folio: '1864',
      Fecha: '2020-04-13T12:16:33.229Z',
      SubTotal: 1475.08,
      Descuento: null,
      Total: 1475.08,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20084',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'b572b5E8-6e17-3bB3-2853-beaF796BF76d',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 90,
      emisorId: 1,
      Serie: 'C',
      Folio: '1802',
      Fecha: '2020-01-09T12:16:33.229Z',
      SubTotal: 7218.73,
      Descuento: null,
      Total: 7218.73,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20347',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '85b62d15-5d1E-952E-4bEe-aa6BBA6b59dE',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 91,
      emisorId: 1,
      Serie: 'A',
      Folio: '1614',
      Fecha: '2020-04-16T12:16:33.229Z',
      SubTotal: 4734.88,
      Descuento: null,
      Total: 4734.88,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PPD',
      LugarExpedicion: '20577',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '2742EBAC-2509-5b9B-2f32-C4214083133A',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 6,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 92,
      emisorId: 1,
      Serie: 'A',
      Folio: '1591',
      Fecha: '2020-03-15T12:16:33.229Z',
      SubTotal: 6739.47,
      Descuento: null,
      Total: 6739.47,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20634',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '139BfDA9-Cfaf-c0Fa-5c84-EC017BB60B7F',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 7,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 93,
      emisorId: 1,
      Serie: 'C',
      Folio: '1165',
      Fecha: '2020-03-07T12:16:33.229Z',
      SubTotal: 1837.48,
      Descuento: null,
      Total: 1837.48,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20549',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'cdfF25F8-65D6-aD4b-dbe9-5E7b9e9aA667',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 8,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 94,
      emisorId: 1,
      Serie: 'C',
      Folio: '1575',
      Fecha: '2020-01-01T12:16:33.229Z',
      SubTotal: 4138.2,
      Descuento: null,
      Total: 4138.2,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20462',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Ef4CF7E8-7393-fDa1-acbC-dD5CE7b5cD4d',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 9,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 95,
      emisorId: 1,
      Serie: 'A',
      Folio: '1794',
      Fecha: '2020-04-06T12:16:33.229Z',
      SubTotal: 6419.04,
      Descuento: null,
      Total: 6419.04,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20319',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '58CBa7bf-cC3b-f695-16E7-bfFC76edFE6c',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 10,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 96,
      emisorId: 1,
      Serie: 'B',
      Folio: '1997',
      Fecha: '2020-04-07T12:16:33.229Z',
      SubTotal: 5444.6,
      Descuento: null,
      Total: 5444.6,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20688',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Db9C9a0C-B5dF-06A4-3Dd2-0c555D44b44b',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 11,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 97,
      emisorId: 1,
      Serie: 'A',
      Folio: '1663',
      Fecha: '2020-04-01T12:16:33.229Z',
      SubTotal: 5213.99,
      Descuento: null,
      Total: 5213.99,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20447',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'bcce80bf-FdaB-C87c-b9E1-8e0Aea45ac78',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 12,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 98,
      emisorId: 1,
      Serie: 'C',
      Folio: '1107',
      Fecha: '2020-04-19T12:16:33.229Z',
      SubTotal: 1116.46,
      Descuento: null,
      Total: 1116.46,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20118',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '0aDa0D58-fFaB-7cbf-13fC-Cdb8AfbBE45B',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 13,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 99,
      emisorId: 1,
      Serie: 'B',
      Folio: '1146',
      Fecha: '2020-04-11T12:16:33.229Z',
      SubTotal: 8611.3,
      Descuento: null,
      Total: 8611.3,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20995',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'ed3B56BC-6d55-5dAF-3A25-f70aA40Badcd',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 14,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 100,
      emisorId: 1,
      Serie: 'C',
      Folio: '1086',
      Fecha: '2020-03-14T12:16:33.229Z',
      SubTotal: 6185.73,
      Descuento: null,
      Total: 6185.73,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20042',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '189752cB-AccB-4fdF-4E33-1a05e7e6BbcE',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Deducible: false,
      Emisor: {
        id: 15,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    }
  ];
}
