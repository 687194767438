import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { DownloadFacturacionDialogComponent } from '../download-facturacion-dialog/download-facturacion-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@auth/services/authentication.service';

@Component({
  selector: 'kt-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.scss']
})
export class FacturacionComponent implements OnInit {

  origin: string ='Sitio_Facturacion';
  public blob: string | Blob;
  ngUnsubscribe = new Subject();
  @ViewChild('pdfViewer', { static: true }) public pdfViewer: PdfJsViewerComponent;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService
    ) {}

  ngOnInit(): void {
    this.downloadPDf()
  }

  redirectPage(page:string) {

    switch (page) {
      case 'api':
        this.router.navigate(['/api']);
        break;
      case 'contadores':
        this.router.navigate(['/contadores']);
        break;
      case 'white-label':
        this.router.navigate(['/white-label']);
        break;
      case 'facturacion':
        this.router.navigate(['/facturacion']);
        break;
      case 'visoor':
        this.router.navigate(['/porque-visoor']);
        break;
      case 'autofactura':
        this.router.navigate(['/autofactura']);
        break;
      case 'precios':
        this.router.navigate(['/precios']);
        break;
      default:
        this.router.navigate(['/home']);
        break;
    }

  }

  register() {
    const dialogRef = this.addDialogRegister();
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  addDialogRegister(){
    return this.dialog.open(RegisterDialogComponent, {
      width: '90vh',
      data: this.origin
    });
  }

  dudasDialog() {
    const dialogRef = this.addDudasDialog();
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  addDudasDialog(){
    return this.dialog.open(DownloadFacturacionDialogComponent, {
      width: '90vh',
    });
  }
  
  refreshHome() {
    this.router.navigate(['/home']);
  }
  
  redwhats() {
    window.open('https://wa.me/message/VXRD4APTH7TNL1', '_blank');
  }

  redDemo(){
    window.open('https://calendly.com/soporte_visoor/demo-visoor-2', '_blank');
  }

  redVisoorRegister(){
    window.location.href = 'https://app.visoor.mx/registrar';
  }

  redVisoorLogin(){
    window.location.href = 'https://app.visoor.mx/entrar';
  }

  downloadPDf(){
    
    this.authService.getPDFFacturacion().pipe(
      takeUntil(this.ngUnsubscribe),
      ).subscribe((response) => {
        this.blob = this.dataURItoBlob(response.PDF);
        this.pdfViewer.downloadFileName = 'Evoluciona a la Facturación 4.0';
        this.pdfViewer.pdfSrc = this.blob;
        this.pdfViewer.refresh();
    });
    
  }

  private dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

}
