export enum InvoiceCatMoneda {
  'Dirham de EAU' = 'AED',
  'Afghani' = 'AFN',
  'Lek' = 'ALL',
  'Dram armenio' = 'AMD',
  'Florín antillano neerlandés' = 'ANG',
  'Kwanza' = 'AOA',
  'Peso Argentino' = 'ARS',
  'Dólar Australiano' = 'AUD',
  'Aruba Florin' = 'AWG',
  'Azerbaijanian Manat' = 'AZN',
  'Convertibles marca' = 'BAM',
  'Dólar de Barbados' = 'BBD',
  'Taka' = 'BDT',
  'Lev búlgaro' = 'BGN',
  'Dinar de Bahrein' = 'BHD',
  'Burundi Franc' = 'BIF',
  'Dólar de Bermudas' = 'BMD',
  'Dólar de Brunei' = 'BND',
  'Boliviano' = 'BOB',
  'Mvdol' = 'BOV',
  'Real brasileño' = 'BRL',
  'Dólar de las Bahamas' = 'BSD',
  'Ngultrum' = 'BTN',
  'Pula' = 'BWP',
  'Rublo bielorruso' = 'BYR',
  'Dólar de Belice' = 'BZD',
  'Dolar Canadiense' = 'CAD',
  'Franco congoleño' = 'CDF',
  'WIR Euro' = 'CHE',
  'Franco Suizo' = 'CHF',
  'Franc WIR' = 'CHW',
  'Unidad de Fomento' = 'CLF',
  'Peso chileno' = 'CLP',
  'Yuan Renminbi' = 'CNY',
  'Peso Colombiano' = 'COP',
  'Unidad de Valor real' = 'COU',
  'Colón costarricense' = 'CRC',
  'Peso Convertible' = 'CUC',
  'Peso Cubano' = 'CUP',
  'Cabo Verde Escudo' = 'CVE',
  'Corona checa' = 'CZK',
  'Franco de Djibouti' = 'DJF',
  'Corona danesa' = 'DKK',
  'Peso Dominicano' = 'DOP',
  'Dinar argelino' = 'DZD',
  'Libra egipcia' = 'EGP',
  'Nakfa' = 'ERN',
  'Birr etíope' = 'ETB',
  'Euro' = 'EUR',
  'Dólar de Fiji' = 'FJD',
  'Libra malvinense' = 'FKP',
  'Libra Esterlina' = 'GBP',
  'Lari' = 'GEL',
  'Cedi de Ghana' = 'GHS',
  'Libra de Gibraltar' = 'GIP',
  'Dalasi' = 'GMD',
  'Franco guineano' = 'GNF',
  'Quetzal' = 'GTQ',
  'Dólar guyanés' = 'GYD',
  'Dolar De Hong Kong' = 'HKD',
  'Lempira' = 'HNL',
  'Kuna' = 'HRK',
  'Gourde' = 'HTG',
  'Florín' = 'HUF',
  'Rupia' = 'IDR',
  'Nuevo Shekel Israelí' = 'ILS',
  'Rupia india' = 'INR',
  'Dinar iraquí' = 'IQD',
  'Rial iraní' = 'IRR',
  'Corona islandesa' = 'ISK',
  'Dólar Jamaiquino' = 'JMD',
  'Dinar jordano' = 'JOD',
  'Yen' = 'JPY',
  'Chelín keniano' = 'KES',
  'Som' = 'KGS',
  'Riel' = 'KHR',
  'Franco Comoro' = 'KMF',
  'Corea del Norte ganó' = 'KPW',
  'Won' = 'KRW',
  'Dinar kuwaití' = 'KWD',
  'Dólar de las Islas Caimán' = 'KYD',
  'Tenge' = 'KZT',
  'Kip' = 'LAK',
  'Libra libanesa' = 'LBP',
  'Rupia de Sri Lanka' = 'LKR',
  'Dólar liberiano' = 'LRD',
  'Loti' = 'LSL',
  'Dinar libio' = 'LYD',
  'Dirham marroquí' = 'MAD',
  'Leu moldavo' = 'MDL',
  'Ariary malgache' = 'MGA',
  'Denar' = 'MKD',
  'Kyat' = 'MMK',
  'Tugrik' = 'MNT',
  'Pataca' = 'MOP',
  'Ouguiya' = 'MRO',
  'Rupia de Mauricio' = 'MUR',
  // 'Rupia' = 'MVR',
  'Kwacha' = 'MWK',
  'Peso Mexicano' = 'MXN',
  'México Unidad de Inversión (UDI)' = 'MXV',
  'Ringgit malayo' = 'MYR',
  'Mozambique Metical' = 'MZN',
  'Dólar de Namibia' = 'NAD',
  'Naira' = 'NGN',
  'Córdoba Oro' = 'NIO',
  'Corona noruega' = 'NOK',
  'Rupia nepalí' = 'NPR',
  'Dólar de Nueva Zelanda' = 'NZD',
  'Rial omaní' = 'OMR',
  'Balboa' = 'PAB',
  'Nuevo Sol' = 'PEN',
  'Kina' = 'PGK',
  'Peso filipino' = 'PHP',
  'Rupia de Pakistán' = 'PKR',
  'Zloty' = 'PLN',
  'Guaraní' = 'PYG',
  'Qatar Rial' = 'QAR',
  'Leu rumano' = 'RON',
  'Dinar serbio' = 'RSD',
  'Rublo ruso' = 'RUB',
  'Franco ruandés' = 'RWF',
  'Riyal saudí' = 'SAR',
  'Dólar de las Islas Salomón' = 'SBD',
  'Rupia de Seychelles' = 'SCR',
  'Libra sudanesa' = 'SDG',
  'Corona sueca' = 'SEK',
  'Dolar De Singapur' = 'SGD',
  'Libra de Santa Helena' = 'SHP',
  'Leona' = 'SLL',
  'Chelín somalí' = 'SOS',
  'Dólar de Suriname' = 'SRD',
  'Libra sudanesa Sur' = 'SSP',
  'Dobra' = 'STD',
  'Colon El Salvador' = 'SVC',
  'Libra Siria' = 'SYP',
  'Lilangeni' = 'SZL',
  'Baht' = 'THB',
  'Somoni' = 'TJS',
  'Turkmenistán nuevo manat' = 'TMT',
  'Dinar tunecino' = 'TND',
  'Pa\'anga' = 'TOP',
  'Lira turca' = 'TRY',
  'Dólar de Trinidad y Tobago' = 'TTD',
  'Nuevo dólar de Taiwán' = 'TWD',
  'Shilling tanzano' = 'TZS',
  'Hryvnia' = 'UAH',
  'Shilling de Uganda' = 'UGX',
  'Dolar americano' = 'USD',
  'Dólar estadounidense (día siguiente)' = 'USN',
  'Peso Uruguay en Unidades Indexadas (URUIURUI)' = 'UYI',
  'Peso Uruguayo' = 'UYU',
  'Uzbekistán Sum' = 'UZS',
  'Bolívar' = 'VEF',
  'Dong' = 'VND',
  'Vatu' = 'VUV',
  'Tala' = 'WST',
  'Franco CFA BEAC' = 'XAF',
  'Plata' = 'XAG',
  'Oro' = 'XAU',
  'Unidad de Mercados de Bonos Unidad Europea Composite (EURCO)' = 'XBA',
  'Unidad Monetaria de Bonos de Mercados Unidad Europea (UEM-6)' = 'XBB',
  'Mercados de Bonos Unidad Europea unidad de cuenta a 9 (UCE-9)' = 'XBC',
  'Mercados de Bonos Unidad Europea unidad de cuenta a 17 (UCE-17)' = 'XBD',
  'Dólar del Caribe Oriental' = 'XCD',
  'DEG (Derechos Especiales de Giro)' = 'XDR',
  'Franco CFA BCEAO' = 'XOF',
  'Paladio' = 'XPD',
  'Franco CFP' = 'XPF',
  'Platino' = 'XPT',
  'Sucre' = 'XSU',
  'Códigos reservados específicamente para propósitos de prueba' = 'XTS',
  'Unidad ADB de Cuenta' = 'XUA',
  'Los códigos asignados para las transacciones en que intervenga ninguna moneda' = 'XXX',
  'Rial yemení' = 'YER',
  'Rand' = 'ZAR',
  'Kwacha zambiano' = 'ZMW',
  'Zimbabwe Dólar' = 'ZWL',
}
