<div class="header">

    <div>
      <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    </div>
    <div class="header-menu">
      
        <div>
            <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
            <mat-menu #soluciones="matMenu">
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
            </mat-menu>
        </div>
  
        <div>            
            <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
        </div>
      
    </div>
    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
</div>

<div class="header--movil">
  <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  <div>
    <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
    <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
  </div>
  <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
</div>

  <mat-sidenav-container>
    <mat-sidenav #sidenav style="position: fixed;">
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
      </div>
      
      <div class="menu-opciones">
        <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
        <mat-menu #soluciones="matMenu">
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
        </mat-menu>
      </div>
  
      <div class="menu-opciones">            
          <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
      </div>
  
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
      </div>
  
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
      </div>
  
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
  

<div class="contenedor contenedor-principal fondo1">
    
    <div class="texto">
        <div>
            <h1 class="margen color-body-title center">Cumple con todas las obligaciones del CFDI 4.0</h1>
    
            <h4 class="margen color-body-text" style="font-weight: 700;"> Evita errores, multas y sanciones con nuestra versión del CFDI 4.0: más amigable e intuitiva. </h4>
    
            <div class="botones">
                <div>
                    <button mat-flat-button class="boton font-poppins" (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                            Regístrate
                        </span>
                    </button>
                </div>
                <div>
                    <button  (click)="redDemo()" mat-flat-button class="boton font-poppins">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                            Agenda una DEMO
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="imagen">
        <img src="../../../../assets/media/visoor/images/facturacion/laptop.png">
    </div>
    
</div>

<div class="contenedor fondo2">
    
    <div class="texto2">

        <h1 class="margen-titulo color-body-title center">¿Cómo funciona?</h1>
        
        <div class="opciones margen">
            <mat-icon class="color-body-subtitle">send</mat-icon>
            <h4> Obtén datos pre llenados guardando previamente a tus clientes </h4>
        </div>
        <div class="opciones margen">
            <mat-icon class="color-body-subtitle">send</mat-icon>
            <h4> Guarda como draft para poder obtener una pre factura y con esta vista previa confirmar datos, cifras y llenado correcto para evitar cancelaciones de CFDI </h4>
        </div>
        <div class="opciones margen">
            <mat-icon class="color-body-subtitle">send</mat-icon>
            <h4> ¿Haces la misma factura cada mes? programala como recurrente para que se timbre de forma automática </h4>
        </div>

        <div class="botones">
            <div>
                <button (click)="register()" mat-flat-button class="boton font-poppins">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Regístrate
                    </span>
                </button>
            </div>
            <div>
                <button (click)="redDemo()" mat-flat-button class="boton font-poppins">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Agenda una DEMO
                    </span>
                </button>
            </div>
        </div>
    </div>

    <div class="imagen2">
        <img src="../../../../assets/media/visoor/images/facturacion/factura.png"> 
    </div>
    
</div>

<div class="contenedor contenedor-final fondo3">
    <div class="texto3">

        <h1 class="margen color-body-title">¿Dudas sobre Facturación 4.0?</h1>

        <h4 class="margen color-body-subtitle" style="font-weight: 900;"> Conoce los principales cambios a considerar a partir del 2023 accediendo a nuestra presentación <a class="descarga cursor color-body-text" (click)="dudasDialog()">aquí</a> </h4>

    </div>

    <div class="imagen_final">
        <div>
          <embed src="../../../../assets/documents/Evoluciona.pdf#toolbar=0" type="application/pdf" width="90%" height="75%">
          <!-- <iframe src="../../../../assets/documents/Evoluciona.pdf" frameborder="0" width="90%" height="75%"></iframe> -->
        </div>
    </div>

    <div class="boton_final">
      <div>
        <button (click)="dudasDialog()" mat-flat-button class="boton font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
              Ver presentación
          </span>
        </button>
      </div>
    </div>
   
</div>

<!-- <div style="height: 100%; width: 100%;">
    <ng2-pdfjs-viewer pdf="../../../../assets/documents/Evoluciona a la Facturación 4.0 .pdf "
    downloadFileName="pdf"></ng2-pdfjs-viewer>
</div> -->

