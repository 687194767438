<div class="kt-portlet">

    <div  class="kt-portlet__head kt-portlet__head__custom portlet__head">
        <div class="kt-portlet__head-label portlet__head--label"> 
          <h3 class="kt-portlet__head-title portlet__head--title">Regístrate</h3>
          <span class="kt-portlet__head-icon kt-hide">
            <i class="la la-gear"></i>
          </span>
        </div>
    </div>
   
    <div class="kt-form" style="background-color: #00b1e5;">
      
        <div class="kt-portlet__body">
            <form [formGroup]="formContacto">

                <div class="form__input">
                    <span class="label_Form">Nombre</span>
                    <mat-form-field class="form__input" appearance="outline">
                      <!-- <mat-label>Nombre</mat-label> -->
                      <input formControlName="nombre" matInput autocomplete="off" required>
                      <mat-error>
                        El nombre es <strong>obligatorio</strong>
                      </mat-error>
                    </mat-form-field>
                </div>

                <div class="form__input">
                    <span class="label_Form">RFC</span>
                    <mat-form-field class="form__input" appearance="outline">
                        <!-- <mat-label>RFC</mat-label> -->
                        <input formControlName="Rfc" matInput autocomplete="off" oninput="this.value = this.value.toUpperCase()" maxlength="13" required>
                        <mat-error *ngIf="formContacto.get('Rfc').hasError('required')">
                            El RFC es <strong>obligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('Rfc').hasError('minlength') || formContacto.get('Rfc').hasError('maxlength')">
                            El RFC debe tener <strong>12 o 13</strong> caracteres
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('Rfc').hasError('pattern') && 
                            !formContacto.get('Rfc').hasError('minlength') && 
                            !formContacto.get('Rfc').hasError('maxlength')">
                            El RFC es <strong>no es válido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form__input">
                    <span class="label_Form">Correo</span>
                    <mat-form-field class="form__input" appearance="outline">
                        <!-- <mat-label>Correo</mat-label> -->
                        <input formControlName="correo" type="email" matInput autocomplete="off" required>
                        <mat-error *ngIf="formContacto.get('correo').hasError('email') && !formContacto.get('correo').hasError('required')">
                            El correo electrónico <strong>no es válido</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('correo').hasError('required')">
                            El correo electrónico es <strong>obligatorio</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form__input">
                    <span class="label_Form">Contraseña</span>
                    <mat-form-field class="form__input" appearance="outline">
                        <!-- <mat-label>Contraseña</mat-label> -->
                        <input  formControlName="password"  [type]="hidePass ? 'password' : 'text' " matInput autocomplete="off" (keyup)="validatePassword($event)" required>
                        <mat-icon matSuffix class="icons" (click)="hidePass = !hidePass">
                            {{hidePass ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                        <mat-hint>Algo indescifrable</mat-hint>
                        <mat-error *ngIf="formContacto.get('password').hasError('required')">
                            Este campo es <strong>obligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('password').hasError('minlength')">
                            La contraseña debe tener al menos <strong>8 caracteres</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('password').hasError('pattern') && !formContacto.get('password').hasError('minlength')">
                            La contraseña <strong>no es válida</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <span style="color: white; margin-left: 10px;" id="helperNum" class="form__helpers">Al menos un número</span>
                <span style="color: white; margin-left: 10px;" id="helperMayus" class="form__helpers">Al menos una letra mayúscula</span>
                <span style="color: white; margin-left: 10px;" id="helperMinus" class="form__helpers">Al menos una letra minúscula</span>
                <span style="color: white; margin-left: 10px;" id="helperSpecial" class="form__helpers">Al menos un caracter especial($, @, !, %, *, ?, &)</span>

                <div class="form__input">
                    <span class="label_Form" [ngStyle]="{'display': showConfirmPassword === true ? '' : 'none'}">Confirmar Contraseña</span>
                    <mat-form-field class="form__input" appearance="outline" [ngStyle]="{'display': showConfirmPassword === true ? '' : 'none'}">
                        <!-- <mat-label>Confirmar Contraseña</mat-label> -->
                        <input formControlName="confirmPassword" matInput [type]="hideConfirmPass ? 'password' : 'text'" autocomplete="off" required>
                        <mat-icon matSuffix class="icons" (click)="hideConfirmPass = !hideConfirmPass">
                            {{hideConfirmPass ? 'visibility_off' : 'visibility'}}
                        </mat-icon>
                        <mat-error *ngIf="formContacto.get('confirmPassword').hasError('required')">
                            Este campo es <strong>obligatorio</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('confirmPassword').hasError('mustMatch')">Las contraseñas no coinciden</mat-error>
                    </mat-form-field>
                </div>
                
                <div style="font-size: 15px; margin-top: 20px;">
                    <mat-checkbox style="color: white;" (change)="toggleCheckbox($event)">Acepto los <a style="color: black;"
                        href="https://legal.visoor.mx/terminosycondiciones" target="_blank">Términos y Condiciones</a>
                      y la
                      <a style="color: black;" href="https://legal.visoor.mx/avisodeprivacidad" target="_blank"> Política de Privacidad</a>
                    </mat-checkbox>
                    <mat-error *ngIf="!termsChecked && submited">
                      Sólo nos falta una palomita
                    </mat-error>
                </div>

                <div class="kt-margin-t-10 kt-margin-b-20">
                </div>
            </form>
        </div>


    
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <div>
                    <button id="ButtonFooter2" mat-flat-button
                            class="footer-boton font-poppins font-size-17 kt-margin-r-20"
                            (click)="contact()" [disabled]="send" style="min-width: 130px; min-height: 36px;">
                        <div *ngIf="!(send$ | async) && !(loading$ | async) && !send" class="kt-margin-r-20 kt-margin-l-20">Enviar
                        </div>
                        <div *ngIf="send" class="kt-margin-r-20 kt-margin-l-20">Enviado</div>
                        <span class="spinner-diff" *ngIf="(loading$ | async)">
                            <mat-spinner class="example-margin" style="margin:0 auto;" [diameter]="20"></mat-spinner>
                        </span>
                    </button>
                </div>
                <span *ngIf="error$ | async">¡Debes llenar todos los campos!</span>
            </div>
        </div>
    </div>
</div>
