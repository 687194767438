import { Component, OnInit, ViewChild, HostListener, Inject  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@auth/services/authentication.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';
import { Router } from '@angular/router';


export interface DialogData {
  DescFeature: string;
  UrlFeature: string;
  TitlFeature: string;
  infoExtra: string;
}

@Component({
  selector: 'kt-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public formContacto: FormGroup;
  error$ = new Subject<boolean>();
  send$ = new Subject<boolean>();
  loading$ = new Subject<boolean>();
  public send = false;
  images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);
  public logos = [];
  public logosMovil = [];
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  listPlan: string;
  Urlgif: string;
  Titlef: string;
  infoExtra: string;
  origin: string ='Sitio_principal';

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
   

    this.formContacto = this.fb.group({
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      necesidad: ['', Validators.required],
      whatsapp: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    });

    this.initTestimonios();
    this.initTestimoniosMovil();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let animado = document.querySelectorAll<HTMLElement>(".Kt-animationrightSm");
    var scrollTop = document.documentElement.scrollTop;
    for (var i = 0; i < animado.length; i++){
        var alturaAnimado = animado[i].offsetTop;
        if(alturaAnimado - 500 < scrollTop)
        {
          animado[i].classList.add("Kt-animationright");
        }
    }
    let animadoh1 = document.querySelectorAll<HTMLElement>(".animationH1SN");
    for (var i = 0; i < animadoh1.length; i++){
      var alturaAnimado1 = animadoh1[i].offsetTop;
      if(alturaAnimado1 - 600 < scrollTop)
      {
        animadoh1[i].classList.add("animationH1");
      }
    }
  }

  scroll(element: HTMLElement, id: string) {
    element.scrollIntoView();
  }

  initTestimonios(){
    
    let img1 = '../../../../assets/media/visoor/empresas/01.png';
    let img2 = '../../../../assets/media/visoor/empresas/02.png';
    let img3 = '../../../../assets/media/visoor/empresas/03.png';
    let img4  = '../../../../assets/media/visoor/empresas/04.png';
    let img5 = '../../../../assets/media/visoor/empresas/05.png';
    let img6 = '../../../../assets/media/visoor/empresas/06.png';
    let img7 = '../../../../assets/media/visoor/empresas/07.png';
    let img8 = '../../../../assets/media/visoor/empresas/08.png';
    let img9 = '../../../../assets/media/visoor/empresas/09.png';
    let img10 = '../../../../assets/media/visoor/empresas/10.png';
    let imagenes=[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
    let imagen1: string, imagen2: string, imagen3 : string, imagen4 : string, imagen5 : string;
    let size = imagenes.length; //10
    for (let i = 0; i < size-4; i++) {
      imagen1 = imagenes[i];
      imagen2 = imagenes[i+1];
      imagen3 = imagenes[i+2];
      imagen4 = imagenes[i+3];
      imagen5 = imagenes[i+4];
      this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    }
    imagen1 = imagenes[size-4];
    imagen2 = imagenes[size-3];
    imagen3 = imagenes[size-2];
    imagen4 = imagenes[size-1];
    imagen5 = imagenes[0];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[size-3];
    imagen2 = imagenes[size-2];
    imagen3 = imagenes[size-1];
    imagen4 = imagenes[0];
    imagen5 = imagenes[1];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[size-2];
    imagen2 = imagenes[size-1];
    imagen3 = imagenes[0];
    imagen4 = imagenes[1];
    imagen5 = imagenes[2];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[size-1];
    imagen2 = imagenes[0];
    imagen3 = imagenes[1];
    imagen4 = imagenes[2];
    imagen5 = imagenes[3];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});

  }

  initTestimoniosMovil(){
    
    let img1 = '../../../../assets/media/visoor/empresas/01.png';
    let img2 = '../../../../assets/media/visoor/empresas/02.png';
    let img3 = '../../../../assets/media/visoor/empresas/03.png';
    let img4  = '../../../../assets/media/visoor/empresas/04.png';
    let img5 = '../../../../assets/media/visoor/empresas/05.png';
    let img6 = '../../../../assets/media/visoor/empresas/06.png';
    let img7 = '../../../../assets/media/visoor/empresas/07.png';
    let img8 = '../../../../assets/media/visoor/empresas/08.png';
    let img9 = '../../../../assets/media/visoor/empresas/09.png';
    let img10 = '../../../../assets/media/visoor/empresas/10.png';
    let imagenes=[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
    let imagen1: string, imagen2: string, imagen3 : string;
    let size = imagenes.length; //10
    for (let i = 0; i < size-2; i++) {
      imagen1 = imagenes[i];
      imagen2 = imagenes[i+1];
      imagen3 = imagenes[i+2];
      this.logosMovil.push({imagen1, imagen2, imagen3});
    }
    imagen1 = imagenes[size-2];
    imagen2 = imagenes[size-1];
    imagen3 = imagenes[0];
    this.logosMovil.push({imagen1, imagen2, imagen3});
    imagen1 = imagenes[size-1];
    imagen2 = imagenes[0];
    imagen3 = imagenes[1];
    this.logosMovil.push({imagen1, imagen2, imagen3});

  }

  register2() {
    window.location.href = 'https://app.visoor.mx/registrar?utm_source=google&utm_medium=landing-page&utm_campaign=contadores';
  }

  register() {
    // const dialogRef = this.addDialogFilter();

    //const dialogRef = this.layoutUtilsService.emailElement(title);
    // dialogRef.afterClosed().subscribe((res: string[]) => {
    // });

    
    location.href = "https://app.visoor.mx/registrar";

  }

  addDialogFilter(){
    return this.dialog.open(RegisterDialogComponent, {
      width: '90vh',
      data: this.origin
    });
  }

  redwhats() {
    window.open('https://wa.me/message/VXRD4APTH7TNL1', '_blank');

   // window.location.href = 'https://wa.me/message/VXRD4APTH7TNL1';
  }
  
  refreshHome() {
    this.router.navigate(['/home']);
  }

  redDemo(){
    window.open('https://calendly.com/soporte_visoor/demo-visoor-2', '_blank');
  }

  redVisoorRegister(){
    window.location.href = 'https://app.visoor.mx/registrar';
  }

  redVisoorLogin(){
    window.location.href = 'https://app.visoor.mx/entrar';
  }

  CheckSelectAnual(select){
    let SelectAnual = document.querySelectorAll<HTMLElement>(".Anual");
    let SelectMes = document.querySelectorAll<HTMLElement>(".Mes");
    let DescAN = document.querySelectorAll<HTMLElement>(".marlefahoo");
    for (var i = 0; i < SelectAnual.length; i++){
      SelectAnual[i].classList.remove("hiddenpay");
      SelectAnual[i].classList.add("showpay");
    }
    for (var i = 0; i < SelectMes.length; i++){
      SelectMes[i].classList.remove("showpay");
      SelectMes[i].classList.add("hiddenpay");
    }
    for (var i = 0; i < DescAN.length; i++){
      DescAN[i].classList.remove("hiddenpay");
      DescAN[i].classList.add("showpay");
    }
  }

  CheckSelectMes(select){
    let SelectAnual = document.querySelectorAll<HTMLElement>(".Anual");
    let SelectMes = document.querySelectorAll<HTMLElement>(".Mes");
    let DescAN = document.querySelectorAll<HTMLElement>(".marlefahoo");
    for (var i = 0; i < SelectAnual.length; i++){
      SelectAnual[i].classList.remove("showpay");
      SelectAnual[i].classList.add("hiddenpay");
    }
    for (var i = 0; i < SelectMes.length; i++){
      SelectMes[i].classList.remove("hiddenpay");
      SelectMes[i].classList.add("showpay");
    }
    for (var i = 0; i < DescAN.length; i++){
      DescAN[i].classList.remove("showpay");
      DescAN[i].classList.add("hiddenpay");
    }
  }

  contact() {
    console.log(this.formContacto);
    this.formContacto.markAllAsTouched();
    if (this.formContacto.invalid) {
      this.error$.next(true);
      setTimeout(() => {
        this.error$.next(false);
      }, 10000);
    } else {
      this.loading$.next(true);
      const nombre = this.formContacto.get('nombre').value;
      const correo = this.formContacto.get('correo').value;
      const necesidad = this.formContacto.get('necesidad').value;
      const telefono = this.formContacto.get('whatsapp').value;
      const campania = "Landing Sitio";

      this.authService.sendLandingInfo(nombre, correo, necesidad, telefono, campania).pipe(first()).subscribe(() => {
        this.send = true;
        this.error$.next(false);
        this.send$.next(true);
        this.loading$.next(false);
        setTimeout(() => {
          this.send$.next(false);
        }, 10000);
      });
    }
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    console.log('Esta entrando');
    
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  openDialog($type) {
    this.infoExtra = ' ';
    if ($type == "DB") {
      this.listPlan = "Un resumen de tu cuenta que te ayudará a revisar tu actividad reciente, analizar tus ingresos y egresos, tener una vista previa de listas negras, verificar el status de tus facturas, entre otras funciones. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Dashboard.gif";
      this.Titlef = "Dashboard";
    }
    if ($type == "MV") {
      this.listPlan = "Monitorea en tiempo real todos tus egresos e ingresos, tus facturas, recibos de pago, nóminas y borradores. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Movimientos.gif";
      this.Titlef = "Movimientos";
    }
    if ($type == "VS") {
      this.listPlan = "Conecta tu cuenta directamente con el SAT y descarga todas tus facturas con un solo clic.  ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Visoorsat.gif";
      this.Titlef = "Visoor SAT";
    }
    if ($type == "FA") {
      this.listPlan = "Crea, personaliza y envía todas la facturas, recibos de nómina, pagos, créditos, y honorarios que necesites desde Visoor. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Facturacion.gif";
      this.Titlef = "Facturación";
    }
    if ($type == "LN") {
      this.listPlan = "Te alertamos sobre la situación fiscal de los RFC a los que les ha hecho o planeas realizarles una factura con el objetivo de que evites problemas ante el SAT. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Listasnegras.gif";
      this.Titlef = "Listas Negras";
    }
    if ($type == "NM") {
      this.listPlan = "Administra tu nómina fácilmente. Descarga nuestro template, transfiere tu información y súbela a la plataforma.";
      this.Urlgif = "../../../../assets/media/visoor/gif/Nomina.gif";
      this.Titlef = "Nomina";
    }
    if ($type == "MU") {
      this.listPlan = "Crea diferentes usuarios y asígnalos a tu equipo de trabajo en la plataforma. Tú decides qué permisos darles. Esto te permitirá llevar un mejor control en la asignación de tareas.";
      this.Urlgif = "../../../../assets/media/visoor/gif/Usuarios.gif";
      this.Titlef = "MultiUsuarios";
    }
    if ($type == "DT") {
      this.listPlan = "Genera desde Visoor tu Declaración bimestral Informativa de Operaciones con Terceros. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/DIOT.gif";
      this.Titlef = "Diot";
    }
    if ($type == "BN") {
      this.listPlan = "Conecta, relaciona y concilia tus movimientos bancarios con tus facturas. El proceso es completamente seguro, ya que tus datos estarán cifrados en todo momento.  ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Bancos.gif";
      this.Titlef = "Bancos";
    }
    if ($type == "DC") {
      this.listPlan = "Te permitirá tener a la mano documentación importante y actualizada como lo es 32D, CIF, declaraciones y estados de cuenta de tus bancos. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Documentos.gif";
      this.Titlef = "Documentos";
    }
    if ($type == "DA") {
      this.listPlan = "Descarga automáticamente tus facturas cada vez que lo desees. Tú decides con qué recurrencia programarlo. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Conexiones.gif";
      this.Titlef = "Descarga Automática";
    }
    if ($type == "RP") {
      this.listPlan = "Descarga un resumen de tus facturas timbradas, pagadas, cobradas,  canceladas y más. Tú decides los datos que necesites en cada reporte que generes. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Reportes.gif";
      this.Titlef = "Reportes Personalizados";
    }
    if ($type == "MR") {
      this.listPlan = "Registra los contribuyentes que desees en una sola cuenta. Configura los RFC’s que necesites a precios preferenciales de manera semestral y anual. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/multirfc.gif";
      this.Titlef = "Multi-RFCs";
      this.infoExtra = 'Costo por RFC: $600 MX Semestral / $800 MX Anual';
    }
    if ($type == "CA") {
      this.listPlan = "Agrega una lista de productos dentro de la plataforma creando un catálogo y así facilitar aún más el proceso de facturación";
      this.Urlgif = "../../../../assets/media/visoor/gif/Catalogo.gif";
      this.Titlef = "Catálogo";
    }
    
    this.dialog.open(DialogElementsExampleDialog, {
      width: '1000px',
      height: '370px',
      data: {
        DescFeature: this.listPlan,
        UrlFeature: this.Urlgif,
        TitlFeature:this.Titlef,
        infoExtra: this.infoExtra
      },
    });
  }

}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: './dialog-content-example-dialog.html',
})
export class DialogElementsExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
