<div class="header">

  <div>
    <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  </div>
  <div class="header-menu">
    
    <div>
      <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
      <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
      </mat-menu>
    </div>

    <div>            
        <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
    </div>
    
    <div>
      <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
    </div>
    
    <div>
      <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
    </div>
    
    <div>
      <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
    </div>
    
  </div>
  <div class="header-boton">
    <div class="onPress">
      <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
        <span class="kt-margin-r-5 kt-margin-l-5">
          ¡Prueba Visoor por 7 días!
        </span>
      </button>
    </div>
    <div class="onPress">
      <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
        <span class="kt-margin-r-5 kt-margin-l-5">
          Entrar
        </span>
      </button>
    </div>
  </div>

</div>

<div class="header--movil">
  <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  <div>
    <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
    <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
  </div>
  <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav style="position: fixed;">
    <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
    </div>
    
    <div class="menu-opciones">
      <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
      <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
      </mat-menu>
    </div>

    <div class="menu-opciones">            
        <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
    </div>
  </mat-sidenav>
</mat-sidenav-container>


  <div class="contenedor contenedor-principal fondo1">
    
    <div class="texto">

        <h1 class="margen color-body-title center">¿Por qué Visoor?</h1>
        <br>

        <h4 class="margen color-body-text" style="font-weight: 500;"> Visoor Soluciones Contables es una plataforma tecnológica que durante más de siete años ha revolucionado la facturación digital ofreciendo herramientas seguras, accesibles, fáciles de usar para las pequeñas y medianas empresas mexicanas</h4>
        <br>

        <div class="botones">
            <div>
                <button mat-flat-button class="boton font-poppins" (click)="register()">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Regístrate
                    </span>
                </button>
            </div>
            <div>
                <button  (click)="redDemo()" mat-flat-button class="boton font-poppins">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Agenda una DEMO
                    </span>
                </button>
            </div>
        </div>
    </div>

    <div class="imagen">
        <img src="../../../../assets/media/visoor/images/visoor/mapa.png">
    </div>
    
</div>
  
  <div class="segundaParte">
      <div class="center" >
          <h1 class="color-body-subtitle segundaParte-title">Visoor en Numeros</h1>
          <div class="numeros">
              <div >
              <h1>+ de</h1>
              <span class="numpr_first"></span><br>
              <span>facturas timbradas</span>
              </div>
              <div>
              <h1>+ de</h1>
              <span class="numpr_second"></span><br>
              <span>usuarios registrados</span>
              </div>
              <div>
              <h1>+ de</h1>
              <span class="numpr_third"></span> <br>
              <span>conexiones exitosas al sat</span>
              </div>
              <div>
              <h1>+ de</h1>
              <span class="numpr_fourth"></span> <br>
              <span>contribuyentes impactados por Visoor</span>
              </div>
          </div>
      </div>
  </div>

  <div class="terceraParte">
      <div>
          <h1 class="color-body-title terceraParte-title">Testimonios</h1>
          <div class="imgHover" style="text-align: center;">
            <img class="logoComentario"  src="../../../../assets/media/visoor/icono/azul/96x96-min.png">
          </div>

          <div class="terceraParte-contenedor">
            <ngb-carousel #carousel [interval]="8000" [pauseOnHover]="pauseOnHover" [showNavigationIndicators]="false"
              (slide)="onSlide($event)">
              <ng-template ngbSlide *ngFor="let testimonio of testimonios; index as i">
                <ng-container [ngTemplateOutlet]="opinions" [ngTemplateOutletContext]="{testimonio:testimonio}"></ng-container>
              </ng-template>
            </ngb-carousel>
          </div>
      </div>
      <div class="terceraParte-imagen">
          <img src="../../../../assets/media/visoor/images/visoor/telefono.png">
      </div>
  </div>

  <div class="carrusel">
      <h1 style="margin-bottom: 30px;" class="color-body-subtitle">Aliados</h1>
  
      <div>
  
        <ngb-carousel [interval]="4000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="true">
          <ng-template ngbSlide *ngFor="let logo of logos; index as i">
            <div class="logos">
              <div class="logos-indicadorIzquiero">
                <img src="../../../../assets/media/visoor/images/visoor/left.png">
              </div>
              <div class="logos-imagen">
                <img [src]="logo.imagen1">
              </div>
              <div class="logos-imagen">
                <img  [src]="logo.imagen2">
              </div>
              <div class="logos-imagen">
                <img [src]="logo.imagen3">
              </div>
              <div class="logos-imagen">
                <img [src]="logo.imagen4">
              </div>
              <div class="logos-imagen">
                <img [src]="logo.imagen5">
              </div>
              <div class="logos-indicadorDerecho">
                <img src="../../../../assets/media/visoor/images/visoor/right.png">
              </div>
            </div>
            
          </ng-template>
        </ngb-carousel>
        
      </div>

      <div class="imgHover carrusel-imagen">
        <img  src="../../../../assets/media/visoor/icono/blanco/96x96-min.png">
      </div>
  </div>
  <div class="carrusel-movil">
      <h1 style="margin-bottom: 15px;" class="color-body-subtitle">Aliados</h1>
  
      <div>
  
        <ngb-carousel [interval]="4000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="true">
          <ng-template ngbSlide *ngFor="let logo of logos; index as i">
            <div class="logos">
              <div class="logos-indicadorIzquiero">
                <img src="../../../../assets/media/visoor/images/visoor/left.png">
              </div>
              <div class="logos-imagen">
                <img [src]="logo.imagen1">
              </div>
              <div class="logos-indicadorDerecho">
                <img src="../../../../assets/media/visoor/images/visoor/right.png">
              </div>
            </div>
            
          </ng-template>
        </ngb-carousel>
        
      </div>

      <div class="imgHover carrusel-imagen">
        <img class="logoComentario" src="../../../../assets/media/visoor/icono/blanco/96x96-min.png">
      </div>
  </div>

  <ng-template #opinions let-testimonio="testimonio">
    <div class="font-poppins kt-margin-b-20">
      <p>
        {{testimonio.message}}
        <br>
        <br>
        {{testimonio.from}}
      </p>
    </div>
  </ng-template>


