<div class="header">

    <div>
      <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    </div>
    <div class="header-menu">
      
        <div>
            <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
            <mat-menu #soluciones="matMenu">
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
            </mat-menu>
        </div>

        <div>            
            <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
        </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
      </div>
      
    </div>
    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
</div>

<div class="header--movil">
  <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  <div>
    <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
    <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
  </div>
  <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav style="position: fixed;">
    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
    </div>
    
    <div class="menu-opciones">
      <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
      <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
      </mat-menu>
    </div>

    <div class="menu-opciones">            
        <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<div class="background1">
    <div class="contenedor">
        <div class="title">
            <div class="color-body-title center titles">
                <span class="title-bold">Integra a tu sitio </span>
                <span class="title-light">o plataforma soluciones </span>
                <span class="title-bold">administrativas y contables </span>
            </div>
            <br>
        </div>
        <div class="imagen">
          <div>
              <img src="../../../../../assets/media/visoor/images/api/API_web.png">
          </div>
        </div>
        <div class="imagen-movil">
          <div>
              <img src="../../../../../assets/media/visoor/images/api/API_movil.png">
          </div>
        </div>
        <div class="botones">
          <div>
              <button (click)="contactVentas()" mat-flat-button class="boton font-poppins">
                  <span class="kt-margin-r-5 kt-margin-l-5">
                      Hablar con ventas
                  </span>
              </button>
          </div>
        </div>
    </div>
</div>
