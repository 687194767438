import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';

@Component({
  selector: 'kt-visoor',
  templateUrl: './visoor.component.html',
  styleUrls: ['./visoor.component.scss']
})
export class VisoorComponent implements OnInit {

  public logos = [];
  public testimonios = [];
  unpauseOnArrow = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  pauseOnIndicator = false;
  paused = false;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  origin: string ='Sitio_Porque Visoor';

  constructor(
    private dialog: MatDialog,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.initLogos();
    this.initOpinions();
  }

  redirectPage(page:string) {

    switch (page) {
      case 'api':
        this.router.navigate(['/api']);
        break;
      case 'contadores':
        this.router.navigate(['/contadores']);
        break;
      case 'white-label':
        this.router.navigate(['/white-label']);
        break;
      case 'facturacion':
        this.router.navigate(['/facturacion']);
        break;
      case 'visoor':
        this.router.navigate(['/porque-visoor']);
        break;
      case 'autofactura':
        this.router.navigate(['/autofactura']);
        break;
      case 'precios':
        this.router.navigate(['/precios']);
        break;
      default:
        this.router.navigate(['/home']);
        break;
    }

  }

  initLogos(){
    
    let img1 = '../../../../assets/media/visoor/images/visoor/LOGO_1.png';
    let img2 = '../../../../assets/media/visoor/images/visoor/LOGO_2.png';
    let img3 = '../../../../assets/media/visoor/images/visoor/emite.png';
    let img4 = '../../../../assets/media/visoor/images/visoor/LOGO_4.png';
    let img5 = '../../../../assets/media/visoor/images/visoor/LOGO_5.png';
    let imagenes=[img1, img2, img3, img4, img5];
    let imagen1: string, imagen2: string, imagen3 : string, imagen4 : string, imagen5 : string;
    let size = imagenes.length; //10
    imagen1 = imagenes[0];
    imagen2 = imagenes[1];
    imagen3 = imagenes[2];
    imagen4 = imagenes[3];
    imagen5 = imagenes[4];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[1];
    imagen2 = imagenes[2];
    imagen3 = imagenes[3];
    imagen4 = imagenes[4];
    imagen5 = imagenes[0];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[2];
    imagen2 = imagenes[3];
    imagen3 = imagenes[4];
    imagen4 = imagenes[0];
    imagen5 = imagenes[1];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[3];
    imagen2 = imagenes[4];
    imagen3 = imagenes[0];
    imagen4 = imagenes[1];
    imagen5 = imagenes[2];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
    
    imagen1 = imagenes[4];
    imagen2 = imagenes[0];
    imagen3 = imagenes[1];
    imagen4 = imagenes[2];
    imagen5 = imagenes[3];
    this.logos.push({imagen1, imagen2, imagen3, imagen4, imagen5});
  }

  initOpinions(){
    let message = '"Visoor y su equipo de Soporte han sido clave para digitalizar mis servicios y administrar a varios usuarios con los que cuento. Siempre he tenido su apoyo."';
    let from = 'C.P. Higinio Pacheco';
    this.testimonios.push({ message, from });
    message = '"Con Visoor he optimizado mis procesos y mejorado mis tiempos. Me ha ayudado bastante."';
    from = 'Lic. Lorena Martinez';
    this.testimonios.push({ message, from });
    message = '"La atención y el servicio de la plataforma están muy bien por el acompañamiento de soporte que se tiene."';
    from = 'Auxiliar Administrativo Ma. Dolores R. ';
    this.testimonios.push({ message, from });
    message = '"La plataforma de Visoor es muy funcional, práctica y la atención del equipo  para la resolución de problemas es muy buena."';
    from = 'L.A.E. SUSANA MEJIA';
    this.testimonios.push({ message, from });
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  
  register() {
    const dialogRef = this.addDialogRegister();
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  addDialogRegister(){
    return this.dialog.open(RegisterDialogComponent, {
      width: '75vh',
      data: this.origin
    });
  }

  
  refreshHome() {
    this.router.navigate(['/home']);
  }
  
  redwhats() {
    window.open('https://wa.me/message/VXRD4APTH7TNL1', '_blank');
  }

  redDemo(){
    window.open('https://calendly.com/soporte_visoor/demo-visoor-2', '_blank');
  }

  redVisoorRegister(){
    window.location.href = 'https://app.visoor.mx/registrar';
  }

  redVisoorLogin(){
    window.location.href = 'https://app.visoor.mx/entrar';
  }

}
