import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegisterDialogComponent } from '../../register-dialog/register-dialog.component';
import { ContactDialogComponent } from '../../contact-dialog/contact-dialog.component';

@Component({
  selector: 'kt-white-label',
  templateUrl: './white-label.component.html',
  styleUrls: ['./white-label.component.scss']
})
export class WhiteLabelComponent implements OnInit {

  origin: string ='Sitio_Soluciones_WhiteLabel';

  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  redirectPage(page:string) {

    switch (page) {
      case 'api':
        this.router.navigate(['/api']);
        break;
      case 'contadores':
        this.router.navigate(['/contadores']);
        break;
      case 'white-label':
        this.router.navigate(['/white-label']);
        break;
      case 'facturacion':
        this.router.navigate(['/facturacion']);
        break;
      case 'visoor':
        this.router.navigate(['/porque-visoor']);
        break;
      case 'autofactura':
        this.router.navigate(['/autofactura']);
        break;
      case 'precios':
        this.router.navigate(['/precios']);
        break;
      default:
        this.router.navigate(['/home']);
        break;
    }

  }
  
  register() {
    const dialogRef = this.addDialogRegister();
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  contactVentas() {
    const dialogRef = this.addDialogContactVentas();
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  addDialogContactVentas(){
    return this.dialog.open(ContactDialogComponent, {
      width: '90vh',
      data: this.origin
    });
  }

  
  refreshHome() {
    this.router.navigate(['/home']);
  }

  addDialogRegister(){
    return this.dialog.open(RegisterDialogComponent, {
      width: '75vh',
      data: this.origin
    });
  }
  
  redwhats() {
    window.open('https://wa.me/message/VXRD4APTH7TNL1', '_blank');
  }

  redDemo(){
    window.open('https://calendly.com/soporte_visoor/demo-visoor-2', '_blank');
  }

  redVisoorRegister(){
    window.location.href = 'https://app.visoor.mx/registrar';
  }

  redVisoorLogin(){
    window.location.href = 'https://app.visoor.mx/entrar';
  }

}
