import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';

export interface DialogData {
  DescFeature: string;
  UrlFeature: string;
  TitlFeature: string;
  infoExtra: string;
}

@Component({
  selector: 'kt-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.scss']
})
export class PreciosComponent implements OnInit {

  
  infoExtra: string;
  listPlan: string;
  Urlgif: string;
  Titlef: string;
  origin: string ='Sitio_Precios';
  

  constructor(
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  redirectPage(page:string) {

    switch (page) {
      case 'api':
        this.router.navigate(['/api']);
        break;
      case 'contadores':
        this.router.navigate(['/contadores']);
        break;
      case 'white-label':
        this.router.navigate(['/white-label']);
        break;
      case 'facturacion':
        this.router.navigate(['/facturacion']);
        break;
      case 'visoor':
        this.router.navigate(['/porque-visoor']);
        break;
      case 'autofactura':
        this.router.navigate(['/autofactura']);
        break;
      case 'precios':
        this.router.navigate(['/precios']);
        break;
      default:
        this.router.navigate(['/home']);
        break;
    }

  }  

  refreshHome() {
    this.router.navigate(['/home']);
  }

  CheckSelectAnual(select){
    let SelectAnual = document.querySelectorAll<HTMLElement>(".Anual");
    let SelectMes = document.querySelectorAll<HTMLElement>(".Mes");
    let DescAN = document.querySelectorAll<HTMLElement>(".marlefahoo");
    for (var i = 0; i < SelectAnual.length; i++){
      SelectAnual[i].classList.remove("hiddenpay");
      SelectAnual[i].classList.add("showpay");
    }
    for (var i = 0; i < SelectMes.length; i++){
      SelectMes[i].classList.remove("showpay");
      SelectMes[i].classList.add("hiddenpay");
    }
    for (var i = 0; i < DescAN.length; i++){
      DescAN[i].classList.remove("hiddenpay");
      DescAN[i].classList.add("showpay");
    }
  }
  
  CheckSelectMes(select){
    let SelectAnual = document.querySelectorAll<HTMLElement>(".Anual");
    let SelectMes = document.querySelectorAll<HTMLElement>(".Mes");
    let DescAN = document.querySelectorAll<HTMLElement>(".marlefahoo");
    for (var i = 0; i < SelectAnual.length; i++){
      SelectAnual[i].classList.remove("showpay");
      SelectAnual[i].classList.add("hiddenpay");
    }
    for (var i = 0; i < SelectMes.length; i++){
      SelectMes[i].classList.remove("hiddenpay");
      SelectMes[i].classList.add("showpay");
    }
    for (var i = 0; i < DescAN.length; i++){
      DescAN[i].classList.remove("showpay");
      DescAN[i].classList.add("hiddenpay");
    }
  }

  register() {
    const dialogRef = this.addDialogRegister();
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  addDialogRegister(){
    return this.dialog.open(RegisterDialogComponent, {
      width: '75vh',
      data: this.origin
    });
  }
  
  redwhats() {
    window.open('https://wa.me/message/VXRD4APTH7TNL1', '_blank');
  }

  redDemo(){
    window.open('https://calendly.com/soporte_visoor/demo-visoor-2', '_blank');
  }

  redVisoorRegister(){
    window.location.href = 'https://app.visoor.mx/registrar';
  }

  redVisoorLogin(){
    window.location.href = 'https://app.visoor.mx/entrar';
  }

  openDialog($type) {
    this.infoExtra = ' ';
    if ($type == "DB") {
      this.listPlan = "Un resumen de tu información fiscal: ingresos y egresos, principales clientes y proveedores, así como tus facturas pendientes. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Dashboard_new.gif";
      this.Titlef = "Dashboard";
    }
    if ($type == "MV") {
      this.listPlan = "Monitorea tus egresos e ingresos por fecha, cliente y factura. Todos tus CFDI que quieras consultar o descargar en PDF y XML. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Movimientos_new.gif";
      this.Titlef = "Movimientos";
    }
    if ($type == "VS") {
      this.listPlan = "Descarga desde el SAT todas tus facturas emitidas y recibidas desde 2014 hasta la fecha. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Visoor SAT_new.gif";
      this.Titlef = "Visoor SAT";
    }
    if ($type == "FA") {
      this.listPlan = "Timbra y envía cualquier tipo de factura a tus clientes con un llenado semi automatizado. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Facturacion_new.gif";
      this.Titlef = "Facturación Electrónica";
    }
    if ($type == "LN") {
      this.listPlan = "Verifica la situación fiscal de los RFC a los que facturas con un buscador conectado a las listas 69 y 69B del SAT. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Listas Negras_new.gif";
      this.Titlef = "Listas Negras";
    }
    if ($type == "NM") {
      this.listPlan = "Timbra tus recibos de nómina mediante nuestro template y envíalos a tus trabajadores. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Nomina_new.gif";
      this.Titlef = "Nomina";
    }
    if ($type == "MU") {
      this.listPlan = "Crea y asigna roles a tu contador, auxiliar o cualquier persona que desees. Esto te permitirá llevar un mejor control en la asignación de tareas. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Multi_usuarios.gif";
      this.Titlef = "MultiUsuarios";
    }
    if ($type == "DT") {
      this.listPlan = "Genera desde Visoor tu Declaración bimestral Informativa de Operaciones con Terceros.  ";
      this.Urlgif = "../../../../assets/media/visoor/gif/DIOT_new.gif";
      this.Titlef = "Diot";
    }
    if ($type == "BN") {
      this.listPlan = "Consulta tus movimientos bancarios por mes y año en conexión cifrada y segura con Finerio. Te permitirá tener información financiera clave a la mano.  ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Bancos_new.gif";
      this.Titlef = "Bancos";
    }
    if ($type == "DC") {
      this.listPlan = "Actualiza y descarga tu CIF y 32D en segundos. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/CIF y 32D_new.gif";
      this.Titlef = "Documentos";
    }
    if ($type == "DA") {
      this.listPlan = "Descarga automáticamente tus facturas cada vez que lo desees. Tú decides con qué recurrencia programarlo. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Conexiones.gif";
      this.Titlef = "Descarga Automática";
    }
    if ($type == "RP") {
      this.listPlan = "Descarga un resumen de tus facturas timbradas, pagadas, cobradas,  canceladas y más. Tú decides los datos que necesites en cada reporte que generes.  ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Reportes personalizados_new.gif";
      this.Titlef = "Reportes Personalizados";
    }
    if ($type == "MR") {
      this.listPlan = "Registra las empresas que desees en una sola cuenta. Configura los RFC’s extras en la misma cuenta a precios preferenciales en esquemas semestral y anual. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Multi RFC_new.gif";
      this.Titlef = "Multi-RFCs";
      this.infoExtra = 'Costo por RFC: $600 MX Semestral / $800 MX Anual';
    }
    if ($type == "CA") {
      this.listPlan = "Da de alta a los datos de tus clientes y tus productos para llenar tus facturas más rápido. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Catalogo_new.gif";
      this.Titlef = "Catálogos";
    }
    if ($type == "AU") {
      this.listPlan = "Delega a tus clientes la facturación. Sólo sube tu venta a facturar, y proporciona un folio para que tu cliente se genere su factura y la reciba al instante. ";
      this.Urlgif = "../../../../assets/media/visoor/gif/Autofactura_new.gif";
      this.Titlef = "Autofactura";
    }
    
    this.dialog.open(DialogElementsExampleDialog, {
      width: '1000px',
      height: '370px',
      data: {
        DescFeature: this.listPlan,
        UrlFeature: this.Urlgif,
        TitlFeature:this.Titlef,
        infoExtra: this.infoExtra
      },
    });
  }

}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: './dialog-content-example-dialog.html',
})
export class DialogElementsExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
