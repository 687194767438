import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@auth/services/authentication.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'kt-download-facturacion-dialog',
  templateUrl: './download-facturacion-dialog.component.html',
  styleUrls: ['./download-facturacion-dialog.component.scss']
})
export class DownloadFacturacionDialogComponent implements OnInit {

  public formContacto: FormGroup;
  error$ = new Subject<boolean>();
  send$ = new Subject<boolean>();
  loading$ = new Subject<boolean>();
  public send = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<DownloadFacturacionDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public origin: string
    ) { }

  ngOnInit(): void {
    this.formContacto = this.fb.group({
  
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      whatsapp: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      
    });
  }
  
  contact() {
    console.log(this.formContacto);
    this.formContacto.markAllAsTouched();
    if (this.formContacto.invalid) {
      this.error$.next(true);
      setTimeout(() => {
        this.error$.next(false);
      }, 10000);
    } else {
      this.loading$.next(true);
      const nombre = this.formContacto.get('nombre').value;
      const correo = this.formContacto.get('correo').value;
      const telefono = this.formContacto.get('whatsapp').value;
      const pdf = true;
      const campania = 'Sitio_Facturacion_Dudas';
      const necesidad = '';

      this.authService.sendLandingInfoWhithPdf(nombre, correo, necesidad, telefono, campania, pdf).pipe(first()).subscribe(() => {
        this.send = true;
        this.error$.next(false);
        this.send$.next(true);
        this.loading$.next(false);
        setTimeout(() => {
          this.send$.next(false);
          this.dialogRef.close();
        }, 5000);
      });
    }
  }

}
