<div class="header">

    <div>
      <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    </div>
    <div class="header-menu">
      
      <div>
        <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
        <mat-menu #soluciones="matMenu">
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
        </mat-menu>
      </div>

      <div>            
          <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
      </div>
      
    </div>
    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
</div>

<div class="header--movil">
  <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  <div>
    <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
    <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
  </div>
  <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav style="position: fixed;">
    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
    </div>
    <div class="menu-opciones">
      <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
      <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
      </mat-menu>
    </div>

    <div class="menu-opciones">            
        <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
    </div>
  </mat-sidenav>
</mat-sidenav-container>


<div class="fondo">
    <div class="planes">
        <h1 class="white">Suscripción mensual o anual</h1>
        <div class="planes-title">
            <div class="toggle">
              <input type="radio" name="sizeBy" value="weight" id="sizeWeight" checked="checked" (click)="CheckSelectAnual($event)" />
              <label for="sizeWeight" class="fontrb" >Anual</label>
              <input type="radio" name="sizeBy" (click)="CheckSelectMes($event)" value="dimensions" id="sizeDimensions" />
              <label for="sizeDimensions" class="fontrb">Mensual</label>
            </div> 
        </div>
        <div class="planes2">
            <p class="white" style="font-size: 17px;">Ahorra más del 18%</p>
        </div>
        
        <div class="planes-items">
            <div class="planes-items-item">
              <div class="kt-align-center list">
                <div class="planes-items-imagen">
                  <img src="../../../../assets/media/visoor/images/precios/ICONO VISOOR 1.png">
                </div>
                <div class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-20">
                    Plan Facturación
                </div>
                <div class="font-oppensans kt-font-bolder">
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('DB')">Dashboard</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('MV')">Movimientos</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('VS')">Visoor-SAT</span><br>
                    <span class="kt-align-left">(10 consultas / mes)</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('FA')">Fact. Electrónica</span><br>
                    <span class="kt-align-left">(30 timbres / mes)</span>
                  </div>
                  
                </div>
                <div class="planes-items-item-footer">
                  <div class="kt-align-center Mes hiddenpay">
                    <span class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-10">
                      $145
                    </span>
                    <span class="font-oppensans kt-font-bolder color-dark">mensual</span>
                    <div class="kt-margin-t-15">
                      <button id="ButtonBody4" mat-flat-button class="button-prueba font-poppins color-body-base "
                      (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                          ¡Prueba Visoor por 7 días!
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="kt-align-center Anual">
                    <div class="planes-items-ahorro kt-align-center">
                      <span class="kt-margin-t-10 kt-font-bolder font-oppensans">Ahorra $290</span>
                    </div>
                    <span class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-10">
                      $120
                    </span>
                    <span class="font-oppensans kt-font-bolder color-dark">/mes</span><br>
                    <span class="planes-Facturado font-oppensans kt-font-bolder color-dark">Total: $1,450 (Facturado anualmente) </span>
                    <div class="kt-margin-t-15">
                      <button id="ButtonBody5" mat-flat-button class="button-prueba font-poppins color-body-base "
                      (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                          ¡Prueba Visoor por 7 días!
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            <div class="planes-items-Preferido">
              <div class="kt-align-center list">
                <h1 class="color-body-subtitle font-poppins font-size-20">
                  Recomendado
                </h1>
                <div class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-20">
                  Empresarial
                </div>
                <div class="font-oppensans kt-font-bolder">
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('DB')">Dashboard</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('MV')">Movimientos</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('VS')">Visoor-SAT</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('FA')">Fact. Electrónica</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('CA')">Catálogo</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('LN')">Listas Negras</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('MU')">Multiusuarios</span>
                  </div>
                  <div class="content Anual">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('MR')">Multi-rfcs *</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('BN')">Bancos</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('NM')">Nomina</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('DT')">Diot</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('DC')">32D</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbttes" (click)="openDialog('RP')">Reportes Personalizados</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbttes" (click)="openDialog('AU')">Autofactura</span>
                  </div>
                </div>
                <div class="planes-items-item-footer">
                  <div class="kt-align-center Mes hiddenpay">
                    <span class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-10">
                      $599
                    </span>
                    <span class="font-oppensans kt-font-bolder color-dark">mensual</span><br>
                    <div class="kt-margin-t-15">
                      <button id="ButtonBody6" mat-flat-button class="button-prueba font-poppins color-body-base "
                      (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                          ¡Prueba Visoor por 7 días!
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="kt-align-center Anual">
                    <div class="planes-items-ahorro kt-align-center">
                      <span class="kt-margin-t-10 kt-font-bolder font-oppensans">Ahorra $1,288</span>
                    </div>
                    <span class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-10">
                      $491
                    </span>
                    <span class="font-oppensans kt-font-bolder color-dark">/mes</span><br>
                    <span class="planes-Facturado font-oppensans kt-font-bolder color-dark">Total: $5,900 (Facturado anualmente)</span>
                    <div class="kt-margin-t-15">
                      <button id="ButtonBody7" mat-flat-button class="button-prueba font-poppins color-body-base "
                      (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                          ¡Prueba Visoor por 7 días!
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="kt-align-right  Anual">
                    <span class="planes-Facturado adicional font-oppensans kt-font-bolder">* Costo adicional</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="planes-items-item">
              <div class="kt-align-center list">
                <div class="planes-items-imagen">
                  <img src="../../../../assets/media/visoor/images/precios/ICONO VISOOR 1.png">
                </div>
                <div class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-20">
                  Ejecutivo
                </div>
                <div class="font-oppensans kt-font-bolder">
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('DB')">Dashboard</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('MV')">Movimientos</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('VS')">Visoor-SAT</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('FA')">Fact. Electrónica</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('CA')">Catálogo</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('LN')">Listas Negras</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbt" (click)="openDialog('MU')">Multiusuarios</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('BN')">Bancos</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('NM')">Nomina</span>
                  </div>
                  <div class="content">
                    <div class="kt-padding-r-5">
                      <img class="img-fluid kt-pull-right" src="../../../../assets/media/visoor/images/ICONO_VISOOR_2.png" alt="">
                    </div>
                    <span class="kt-align-left Linkbtdos" (click)="openDialog('DT')">Diot</span>
                  </div>
                </div>
                <div class="planes-items-item-footer">
                  <div class="kt-align-center Mes hiddenpay">
                    <span class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-10">
                      $399
                    </span>
                    <span class="font-oppensans kt-font-bolder color-dark">mensual</span>
                    <div class="kt-margin-t-15">
                      <button id="ButtonBody8" mat-flat-button class="button-prueba font-poppins color-body-base "
                      (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                          ¡Prueba Visoor por 7 días!
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="kt-align-center Anual">
                    <div class="planes-items-ahorro kt-align-center">
                      <span class="kt-margin-t-10 kt-font-bolder font-oppensans">Ahorra $888</span>
                    </div>
                    <span class="color-body-subtitle font-poppins font-size-26 kt-font-bolder kt-margin-b-10">
                      $325
                    </span>
                    <span class="font-oppensans kt-font-bolder color-dark">/mes</span><br>
                    <span class="planes-Facturado font-oppensans kt-font-bolder color-dark">Total: $3,900 (Facturado anualmente)</span>
                    <div class="kt-margin-t-15">
                      <button id="ButtonBody9"mat-flat-button class="button-prueba font-poppins color-body-base "
                      (click)="register()">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                          ¡Prueba Visoor por 7 días!
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <!-- <div class="contenedor">
        <div>
            <div class="Anual">
                <img class="imagen" src="../../../../assets/media/visoor/images/precios/PRECIOS 2.png">
            </div>
            <div class="Mes hiddenpay">
                <img class="imagen" src="../../../../assets/media/visoor/images/precios/PRECIOS 5.png">
            </div>
        </div>
        <div>
            <div class="Anual">
                <img class="imagen" src="../../../../assets/media/visoor/images/precios/PRECIOS 3.png">
            </div>
            <div class="Mes hiddenpay">
                <img class="imagen" src="../../../../assets/media/visoor/images/precios/PRECIOS 6.png">
            </div>
        </div>
        <div>
            <div class="Anual">
                <img class="imagen" src="../../../../assets/media/visoor/images/precios/PRECIOS 4.png">
            </div>
            <div class="Mes hiddenpay">
                <img class="imagen" src="../../../../assets/media/visoor/images/precios/PRECIOS 7.png">
            </div>
        </div>
    </div> -->
</div>