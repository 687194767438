// tslint:disable: max-line-length
export class InvoiceSenderProductsTable {
  // tslint:disable-next-line: variable-name
  public static invoice_sender_products: any[] = [
    {
      id: 1,
      invoiceSenderId: 1,
      Description: 'Diseño de logo',
      UnitCost: 10.84,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10211729',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'VLT',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 2,
      invoiceSenderId: 2,
      Description: 'MEMORIA USB KINGSTON 16GB DT10',
      UnitCost: 460.74,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202603',
      IdentificationNumber: 'HHFE-6096',
      InvoiceCatClaveUnidad: 'P96',
      Ieps: null,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 3,
      invoiceSenderId: 2,
      Description: 'CARTUCHO HP 954 AMARILLO XL',
      Unidad: 'Kilo',
      UnitCost: 487.21,
      Discount: 10,
      Vat: 0,
      InvoiceCatClaveProdServ: '10401920',
      IdentificationNumber: 1,
      InvoiceCatClaveUnidad: 'C43',
      Ieps: 0.265000,
      RetIeps: 0.265000,
      RetVat: 15,
      RetIsr: 31
    },
    {
      id: 4,
      invoiceSenderId: 2,
      Description: 'Diseño de logotipo',
      Unidad: 'Kilo',
      UnitCost: 634.08,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10421902',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M25',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 5,
      invoiceSenderId: 2,
      Description: 'Mensajes y comentarios',
      Unidad: 'Kilo',
      UnitCost: 655.94,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402404',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E07',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 6,
      invoiceSenderId: 3,
      Description: 'Admin. Redes Sociales',
      Unidad: 'Kilo',
      UnitCost: 483.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10431916',
      IdentificationNumber: 'VOIY-9913',
      InvoiceCatClaveUnidad: 'J91',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 7,
      invoiceSenderId: 3,
      Description: 'COLEGIATURA DEL MES DE OCTUBRE DE 2019',
      Unidad: 'Kilo',
      UnitCost: 553.76,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11172000',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E99',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 8,
      invoiceSenderId: 3,
      Description: 'SERVICIOS VARIOS  OBRA CIVIL 2019',
      Unidad: 'Kilo',
      UnitCost: 660.79,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10316322',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P36',
      Ieps: null,
      RetIeps: null,
      RetVat: 10, RetIsr: 26
    },
    {
      id: 9,
      invoiceSenderId: 4,
      Description: 'BONIFICACION DE COMISION COBRADA',
      UnitCost: 131.31,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12163701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A30',
      Ieps: null,
      RetIeps: null,
      RetVat: 2, RetIsr: 4
    },
    {
      id: 10,
      invoiceSenderId: 4,
      Description: 'MAKE UP BASE MEK',
      UnitCost: 164.22,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10417918',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P11',
      Ieps: null,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 11,
      invoiceSenderId: 4,
      Description: 'Pago',
      UnitCost: 412.14,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302407',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XPR',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 12,
      invoiceSenderId: 4,
      Description: 'Pago de nómina',
      UnitCost: 395.43,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122607',
      IdentificationNumber: 'QPDV-1387',
      InvoiceCatClaveUnidad: 'X4H',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: 7,
      RetIsr: 35
    },
    {
      id: 13,
      invoiceSenderId: 4,
      Description: 'SERVICIOS PROFESIONALES TECNICOS - MARZO 2020',
      UnitCost: 363.45,
      Discount: null,
      Iva: 0,
      InvoiceCatClaveProdServ: '10216701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XDJ',
      Ieps: null,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 14,
      invoiceSenderId: 4,
      Description: 'ADVANCED',
      UnitCost: 364.95,
      Discount: null,
      Iva: 0,
      InvoiceCatClaveProdServ: '10224600',
      IdentificationNumber: 'OBPR-8784',
      InvoiceCatClaveUnidad: 'A59',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 15,
      invoiceSenderId: 4,
      Description: 'ALAMBRE RECOCIDO',
      UnitCost: 889.36,
      Discount: null,
      Iva: 0,
      InvoiceCatClaveProdServ: '20122504',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A83',
      Ieps: null,
      RetIeps: null,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 16,
      invoiceSenderId: 4,
      Description: 'MORTERO APASCO',
      UnitCost: 901.76,
      Discount: null,
      Iva: 0,
      InvoiceCatClaveProdServ: '10231911',
      IdentificationNumber: 'LPAC-7690',
      InvoiceCatClaveUnidad: '2V',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 17,
      invoiceSenderId: 4,
      Description: 'CEMENTO GRIS APASCO',
      UnitCost: 719.65,
      Descuento: null,
      Iva: null,
      InvoiceCatClaveProdServ: '10415413',
      IdentificationNumber: 'HXCF-3684',
      InvoiceCatClaveUnidad: 'J15',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 18,
      invoiceSenderId: 1,
      Description: 'lavadero 60x60',
      UnitCost: 339.35,
      Descuento: null,
      Iva: null,
      InvoiceCatClaveProdServ: '10416215',
      IdentificationNumber: 'UICH-7723',
      InvoiceCatClaveUnidad: 'XIE',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 19,
      invoiceSenderId: 1,
      Description: 'VARILLA 3/8',
      UnitCost: 433.21,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10422701',
      IdentificationNumber: 'LRTW-1940',
      InvoiceCatClaveUnidad: 'C49',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 20,
      invoiceSenderId: 1,
      Description: 'servicio de entrega',
      UnitCost: 700.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402777',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Tu',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 21,
      invoiceSenderId: 1,
      Description: 'loseta de grano san luis 30x30',
      UnitCost: 429.44,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10311731',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XQG',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 22,
      invoiceSenderId: 1,
      Description: 'POR LOS SERVICIOS PRESTADOS REFERENTES A LA REVISION DEL MES DE MARZO 2020, EN MATERIA DE IMPUESTOS FEDERALES Y LOCALES',
      UnitCost: 556.57,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10213701',
      IdentificationNumber: 'DTRL-1883',
      InvoiceCatClaveUnidad: 'D88',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 23,
      invoiceSenderId: 1,
      Description: 'Tratamiento odontológico',
      UnitCost: 209.04,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10323502',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N49',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 24,
      invoiceSenderId: 1,
      Description: 'VIAJE EN FULL DE PLANTA NAVOJOA A PLANTA HERMOSILLO SEGUN BOLETA DE CARGA:283838',
      UnitCost: 465.6,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417900',
      IdentificationNumber: 'MDGE-5123',
      InvoiceCatClaveUnidad: 'M94',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 25,
      invoiceSenderId: 1,
      Description: 'VIAJE EN FULL DE PLANTA NAVOJOA A PLANTA HERMOSILLO SEGUN BOLETA DE CARGA:283837',
      UnitCost: 784.24,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218330',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'LUB',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 26,
      invoiceSenderId: 1,
      Description: 'Gas L.P',
      UnitCost: 779.39,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10252018',
      IdentificationNumber: 'EWOM-5616',
      InvoiceCatClaveUnidad: '1I',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 27,
      invoiceSenderId: 1,
      Description: 'Honorarios por ejecución y logistica del proyecto Mujeres Diversas Mujeres Iguales INE 2020 por el mes de febrero,marzo,abril',
      UnitCost: 833.75,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '14111529',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B19',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 28,
      invoiceSenderId: 1,
      Description: 'POR LOS SERVICIOS PRESTADOS REFERENTES A LA ELABORACION DE LA CONTABILIDAD DEL MES DE MARZO 2020',
      UnitCost: 80.39,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10315413',
      IdentificationNumber: 'KKWK-7022',
      InvoiceCatClaveUnidad: 'D1',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 29,
      invoiceSenderId: 1,
      Description: 'SERVICIOS PROFESIONALES DE ADMINISTRACION',
      UnitCost: 55.62,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122204',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D79',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 30,
      invoiceSenderId: 1,
      Description: 'POR LOS SERVICIOS PRESTADOS REFERENTES A LA REVISION EN IMPUESTOS DEL MES DE MARZO DE 2020, EN MATERIA DE IMPUESTOS FEDERALES Y LOCALES',
      UnitCost: 256.42,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10101604',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D70',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 31,
      invoiceSenderId: 1,
      Description: 'Gastos Locales',
      UnitCost: 963.86,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11162128',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Ts',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 33
    },
    {
      id: 32,
      invoiceSenderId: 1,
      Description: 'Flete Marítimo Internacional de Importacion',
      UnitCost: 981.44,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318339',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L15',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 33,
      invoiceSenderId: 1,
      Description: 'TABLERO CORCHO 150X120 MARCO ALUMINIO',
      UnitCost: 277.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402714',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MBR',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 34,
      invoiceSenderId: 1,
      Description: 'FABRICACION DE CARRITO PARA ALMACEN A BASE DE ESTRUCTURA DE ANGULO DE METAL, CUBIERTA DE LAMINA LISA DE METAL, 1 ENTREPAÑO FIJO, 1 ENTREPAÑO MOVIBLE CON RIEL, PINTURA ESMALTE EN COLOR GRIS, 4 LLANTAS DE 3, CON FRENO, 2 BASES PLEGABLES EN LOS EXTREMOS D...',
      UnitCost: 242.28,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302370',
      IdentificationNumber: 'MKDI-9659',
      InvoiceCatClaveUnidad: 'G57',
      Ieps: 0.060,
      RetIeps: 0.090,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 35,
      invoiceSenderId: 1,
      Description: 'ESCALERA EXT ALUMINIO T-III 16 GAN',
      UnitCost: 15.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10232125',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K68',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 36,
      invoiceSenderId: 1,
      Description: 'HIDROLAVADORA K3 FOLLOW ME',
      UnitCost: 116.13,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10321600',
      IdentificationNumber: 'IJVG-7662',
      InvoiceCatClaveUnidad: 'N12',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 37,
      invoiceSenderId: 1,
      Description: '2 FLECHAS EN AC, 1045 DE 1.750 DE DIAMETRO POR 30 DE LONGITUD, CON AJUSTES DE 1.378 DE DIAMETRO PARA BALERO, EN AMBOS EXTREMOS, LINEA I2 PACO',
      UnitCost: 215.32,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10151901',
      IdentificationNumber: 'BOKU-3479',
      InvoiceCatClaveUnidad: 'F02',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 38,
      invoiceSenderId: 1,
      Description: 'PLATA SR 40X100',
      UnitCost: 482.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202602',
      IdentificationNumber: 'GGDP-5257',
      InvoiceCatClaveUnidad: 'M79',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 39,
      invoiceSenderId: 1,
      Description: 'PLATA 6% 1ML SR 60X100',
      UnitCost: 863.69,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10452045',
      IdentificationNumber: 'MMVR-9269',
      InvoiceCatClaveUnidad: 'NIU',
      Ieps: null,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 40,
      invoiceSenderId: 1,
      Description: 'VENTA CLARO 10 Y 8MM PLANTILLAS Y 1 PZA TEMPLADO',
      UnitCost: 322.75,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10326040',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Jo',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 31
    },
    {
      id: 41,
      invoiceSenderId: 1,
      Description: 'MDF LIGERO 03 mm',
      UnitCost: 190.69,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417340',
      IdentificationNumber: 'JHWJ-5260',
      InvoiceCatClaveUnidad: 'MBF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 42,
      invoiceSenderId: 1,
      Description: 'TUBO DE 2" C-18 AC/INOX 304',
      UnitCost: 481.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10401915',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M47',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 43,
      invoiceSenderId: 1,
      Description: 'CONECTOR ROTULA TUBO TUBO',
      UnitCost: 947.52,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202133',
      IdentificationNumber: 'JUKC-7391',
      InvoiceCatClaveUnidad: 'XFW',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 44,
      invoiceSenderId: 1,
      Description: 'Cuotas RCV ',
      UnitCost: 206.41,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20141008',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B92',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 45,
      invoiceSenderId: 1,
      Description: 'Cuotas IMSS ',
      UnitCost: 990.88,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402804',
      IdentificationNumber: 'CVBN-4355',
      InvoiceCatClaveUnidad: 'KIC',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 46,
      invoiceSenderId: 1,
      Description: 'VINIL PENS. POR KILO',
      UnitCost: 429.52,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10423902',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G40',
      Ieps: null,
      RetIeps: null,
      RetVat: 2, RetIsr: 28
    },
    {
      id: 47,
      invoiceSenderId: 1,
      Description: 'CR.CLARO 6MM 1.80 X 2.60',
      UnitCost: 765.39,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12131504',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K50',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 48,
      invoiceSenderId: 1,
      Description: 'VENTA CLARO 10MM DE 1.26 X 1.30 TEMPLADO',
      UnitCost: 472.74,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10315444',
      IdentificationNumber: 'HKRJ-4991',
      InvoiceCatClaveUnidad: 'C68',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 49,
      invoiceSenderId: 1,
      Description: 'MAQUILA TEMPLADO 6mm FSOL',
      UnitCost: 725.3,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10201504',
      IdentificationNumber: 'DSGB-0818',
      InvoiceCatClaveUnidad: 'TSD',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 50,
      invoiceSenderId: 1,
      Description: 'CHAPA PHILLIPS 549 CILINDRO MARIPOSA NATURAL (MX3434)',
      UnitCost: 544.3,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10411723',
      IdentificationNumber: 'JRUB-6851',
      InvoiceCatClaveUnidad: 'H03',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 51,
      invoiceSenderId: 1,
      Description: 'AGUA CIEL CRISTAL NANA 600ML PET',
      UnitCost: 773.04,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402170',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F43',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 52,
      invoiceSenderId: 1,
      Description: 'CANDADO LAMINADO ECONOMICO 38MM 2PK',
      UnitCost: 433.7,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402618',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KMK',
      Ieps: null,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 53,
      invoiceSenderId: 1,
      Description: 'RESMA OFIXPRES AZUL CARTA 75G',
      UnitCost: 166.38,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10151510',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WH',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 54,
      invoiceSenderId: 1,
      Description: 'RESMA CARTA ECONOVO TIPO REVOL',
      UnitCost: 20.72,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10215459',
      IdentificationNumber: 'FHHG-4348',
      InvoiceCatClaveUnidad: 'XFW',
      Ieps: null,
      RetIeps: null,
      RetVat: 14, RetIsr: 18
    },
    {
      id: 55,
      invoiceSenderId: 1,
      Description: 'Amortizaciones',
      UnitCost: 928.26,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402714',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P20',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 56,
      invoiceSenderId: 1,
      Description: 'Aportacion c/Credito',
      UnitCost: 224.62,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217708',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K65',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 57,
      invoiceSenderId: 1,
      Description: 'Aportacion s/Credito',
      UnitCost: 459.56,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10426004',
      IdentificationNumber: 'YFYI-2543',
      InvoiceCatClaveUnidad: 'J53',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 58,
      invoiceSenderId: 1,
      Description: 'Mobil Extra',
      UnitCost: 371.11,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302479',
      IdentificationNumber: 'FMJL-5793',
      InvoiceCatClaveUnidad: 'FTK',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 59,
      invoiceSenderId: 1,
      Description: 'CARGO POR SERVICIO',
      UnitCost: 643.76,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10316807',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A93',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 60,
      invoiceSenderId: 1,
      Description: 'CHAPA DOBLE MANIJA CONALUM',
      UnitCost: 690.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10252004',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C37',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 61,
      invoiceSenderId: 1,
      Description: 'BATIENTE CON VENAS DE 2` ALTO',
      UnitCost: 292.63,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10351512',
      IdentificationNumber: 'HIEV-5490',
      InvoiceCatClaveUnidad: 'C99',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 62,
      invoiceSenderId: 1,
      Description: 'JUNQUILLO',
      UnitCost: 181.41,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '11111812',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XAV',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 63,
      invoiceSenderId: 1,
      Description: 'CERCO CHAPA 1 3/4" 6.10 MTS.',
      UnitCost: 734.03,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10252402',
      IdentificationNumber: 'FNWK-8528',
      InvoiceCatClaveUnidad: 'E32',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 24
    },
    {
      id: 64,
      invoiceSenderId: 1,
      Description: 'Junquillo Cuervo Hueco 10mm Blanco',
      UnitCost: 824.38,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10413110',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C98',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 65,
      invoiceSenderId: 1,
      Description: 'Fijo Tubular 3 Vias Blanco',
      UnitCost: 621.61,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101528',
      IdentificationNumber: 'LLCP-0784',
      InvoiceCatClaveUnidad: 'MTS',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 66,
      invoiceSenderId: 1,
      Description: 'BASCULA DIGI',
      UnitCost: 235.18,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12161605',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'RP',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 67,
      invoiceSenderId: 1,
      Description: 'ACTUALIZACION IMPUESTO SOBRE NOMINA',
      UnitCost: 62.1,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416105',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G48',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 68,
      invoiceSenderId: 1,
      Description: 'IMPUESTO SOBRE NOMINA',
      UnitCost: 86.88,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402614',
      IdentificationNumber: 'GYMA-3279',
      InvoiceCatClaveUnidad: 'AW',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 69,
      invoiceSenderId: 1,
      Description: 'RECARGOS IMPUESTO SOBRE NOMINA',
      UnitCost: 481.24,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10424301',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCR',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 70,
      invoiceSenderId: 1,
      Description: 'INTERES GRAVABLE AL 0%',
      UnitCost: 431.23,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416908',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B33',
      Ieps: null,
      RetIeps: null,
      RetVat: 7, RetIsr: 4
    },
    {
      id: 71,
      invoiceSenderId: 1,
      Description: 'INTERES GRAVABLE AL 16%',
      UnitCost: 450.56,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122312',
      IdentificationNumber: 'WLXB-6408',
      InvoiceCatClaveUnidad: 'LN',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 72,
      invoiceSenderId: 1,
      Description: 'COMISION COSTO ENVIO SMS',
      UnitCost: 680.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '13111078',
      IdentificationNumber: 'AMKQ-7921',
      InvoiceCatClaveUnidad: 'M11',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 73,
      invoiceSenderId: 1,
      Description: 'KIT DE CANCEL DE BAÑO TORINO 1.50 NAT BTE',
      UnitCost: 775.9,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20131007',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L76',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 14
    },
    {
      id: 74,
      invoiceSenderId: 1,
      Description: 'BROCA RECTA AV 21/64***',
      UnitCost: 874.07,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10301702',
      IdentificationNumber: 'IBNX-4181',
      InvoiceCatClaveUnidad: 'PB',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 75,
      invoiceSenderId: 1,
      Description: 'BROCA RECTA AV 5/16 OK',
      UnitCost: 434.57,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317705',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A49',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 76,
      invoiceSenderId: 1,
      Description: 'MACH MM AC 10X15 OK',
      UnitCost: 609.79,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302337',
      IdentificationNumber: 'DESW-8985',
      InvoiceCatClaveUnidad: 'KD',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 77,
      invoiceSenderId: 1,
      Description: 'BOLSA LISA 6.1  NEGRO',
      UnitCost: 775.91,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413807',
      IdentificationNumber: 'OYDF-1735',
      InvoiceCatClaveUnidad: 'L72',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 78,
      invoiceSenderId: 1,
      Description: 'TAPA BOLSA 6.1  NEGRO',
      UnitCost: 464.76,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10232030',
      IdentificationNumber: 'QIOH-3700',
      InvoiceCatClaveUnidad: 'B7',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 79,
      invoiceSenderId: 1,
      Description: 'DW03240         DISCO CARBURO 12 X 100 DTS ALUMINIO',
      UnitCost: 383.26,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218348',
      IdentificationNumber: 'RZEJ-9357',
      InvoiceCatClaveUnidad: 'J34',
      Ieps: null,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 80,
      invoiceSenderId: 1,
      Description: 'SIERRA P/MADERA 60 DIENTES/CARBURO 12X1',
      UnitCost: 241.71,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202153',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KMH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 81,
      invoiceSenderId: 1,
      Description: 'TAPON AUDITIVO TRES BARRERAS CON CORDON',
      UnitCost: 951.53,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302413',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MV',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 82,
      invoiceSenderId: 1,
      Description: 'LENTES DEPORTIVOS DE SEGURIDAD ESPEJO COLOR AZUL INTREPID',
      UnitCost: 299.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10311718',
      IdentificationNumber: 'SAKC-9262',
      InvoiceCatClaveUnidad: 'G47',
      Ieps: null,
      RetIeps: null,
      RetVat: 5, RetIsr: 12
    },
    {
      id: 83,
      invoiceSenderId: 1,
      Description: 'GUANTES PARA JARDIN, TALLA GRANDE',
      UnitCost: 580.6,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202002',
      IdentificationNumber: 'KRPH-4588',
      InvoiceCatClaveUnidad: 'XDX',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 84,
      invoiceSenderId: 1,
      Description: 'Hospedaje Personal Linux+Cpanel - solar-city.mx (19/03/2020 - 18/03/2021)',
      UnitCost: 406.83,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20121104',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C26',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 85,
      invoiceSenderId: 1,
      Description: 'Hoja Perimetral Blanco',
      UnitCost: 46.83,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10211501',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A32',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 18
    },
    {
      id: 86,
      invoiceSenderId: 1,
      Description: 'BLANCO TOTAL 1ML 60X100',
      UnitCost: 596.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10414911',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G26',
      Ieps: 0.090,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 87,
      invoiceSenderId: 1,
      Description: 'POLICARBONATO DP-UV GLANZE  6MM 3.05 X 2',
      UnitCost: 233.28,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402409',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G79',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 28
    },
    {
      id: 88,
      invoiceSenderId: 1,
      Description: 'MOSQUITEROS',
      UnitCost: 898.59,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10422301',
      IdentificationNumber: 'JQVW-3346',
      InvoiceCatClaveUnidad: 'C61',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 89,
      invoiceSenderId: 1,
      Description: 'TAPA BOLSA DE 1 1/2',
      UnitCost: 532.0,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10331506',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MV',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 25
    },
    {
      id: 90,
      invoiceSenderId: 1,
      Description: 'BOLSA DE 1 1/2`',
      UnitCost: 944.75,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10331908',
      IdentificationNumber: 'MPRM-4863',
      InvoiceCatClaveUnidad: 'WR',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 91,
      invoiceSenderId: 1,
      Description: 'TABLETA FIJA P/CELOCIA',
      UnitCost: 376.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '13111302',
      IdentificationNumber: 'IVPF-0486',
      InvoiceCatClaveUnidad: 'EB',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 92,
      invoiceSenderId: 1,
      Description: 'MOSQUITERO LIGERO 6.1  BLANCO',
      UnitCost: 125.15,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10411609',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F60',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 93,
      invoiceSenderId: 1,
      Description: '1.83 CRISTAL POLICARBONATO CELULAR 6MM 1 pedazo de 3.16 X 1.83, Cortar de pedazo de 4.13 X 1.83, Resta pedazo de 0.97',
      UnitCost: 712.71,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318324',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B78',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 94,
      invoiceSenderId: 1,
      Description: 'NA  SILICA GEL DE 0.5 A 0.9MM 1KG',
      UnitCost: 963.35,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10502301',
      IdentificationNumber: 'YTXB-1961',
      InvoiceCatClaveUnidad: '56',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 95,
      invoiceSenderId: 1,
      Description: 'BROCA 9/64 AV',
      UnitCost: 719.39,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10215305',
      IdentificationNumber: 'THGT-7511',
      InvoiceCatClaveUnidad: 'VLT',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 96,
      invoiceSenderId: 1,
      Description: 'PIJA HEX P/BROCA CON RONDANA 1/4X 1',
      UnitCost: 749.93,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231624',
      IdentificationNumber: 'DIKG-3720',
      InvoiceCatClaveUnidad: 'XYF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 97,
      invoiceSenderId: 1,
      Description: 'PIJA P/MADERA PHILLIPS 10X 4',
      UnitCost: 132.93,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416005',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'DRM',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 6
    },
    {
      id: 98,
      invoiceSenderId: 1,
      Description: 'TAQUETE PLASTICO 1/4',
      UnitCost: 392.09,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10217939',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E94',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 99,
      invoiceSenderId: 1,
      Description: 'BROCA P/CONCRETO 1/4X 4',
      UnitCost: 388.27,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302332',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J55',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 100,
      invoiceSenderId: 1,
      Description: 'BROCA 1/4 AV',
      UnitCost: 713.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417205',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XPA',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 101,
      invoiceSenderId: 1,
      Description: '9002-MO CARBONES-SERVICIO',
      UnitCost: 304.12,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122816',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '96',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 102,
      invoiceSenderId: 1,
      Description: '5375-MO CARBON-CABLE-PROTECTOR DE CABLE-SERVICIO',
      UnitCost: 159.04,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10216400',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K6',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 103,
      invoiceSenderId: 1,
      Description: 'LUNA DE 4MM 1.80 X 2.60',
      UnitCost: 747.78,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10311605',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K68',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 104,
      invoiceSenderId: 1,
      Description: 'BISAGRA HIDRAULICA DORTEC DT-2300 PARA 130 KGS.( 85531 )',
      UnitCost: 633.14,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202468',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XAT',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 7
    },
    {
      id: 105,
      invoiceSenderId: 1,
      Description: 'DESCUENTO LINEA CRISTAL',
      UnitCost: 674.87,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10212607',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MLT',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 106,
      invoiceSenderId: 1,
      Description: 'VENTA/MAQUILA CRISTAL TEMPLADO',
      UnitCost: 105.12,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10315443',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E39',
      Ieps: null,
      RetIeps: null,
      RetVat: 1, RetIsr: 10
    },
    {
      id: 107,
      invoiceSenderId: 1,
      Description: '107-111 MICROSWITCH HARTMANN',
      UnitCost: 770.64,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10431706',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F73',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 108,
      invoiceSenderId: 1,
      Description: 'CINTA MASKING 3/4"X50 MTS.',
      UnitCost: 503.01,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10241504',
      IdentificationNumber: 'DOXG-1423',
      InvoiceCatClaveUnidad: 'OT',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 109,
      invoiceSenderId: 1,
      Description: 'CN 1/8x1/2" VT9504 BLANCO 26 MTS',
      UnitCost: 105.55,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302032',
      IdentificationNumber: 'MDOV-8197',
      InvoiceCatClaveUnidad: 'K23',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 110,
      invoiceSenderId: 1,
      Description: 'VINIL 9 CUÑA NEGRO',
      UnitCost: 153.87,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10418320',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H43',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 12
    },
    {
      id: 111,
      invoiceSenderId: 1,
      Description: 'ESPATULA 4" AMARILLA C/ESQ. RED Y COSTILLA   NUEVO LAREDO 192435329000063 2019-04-30',
      UnitCost: 589.51,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121810',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XYW',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 112,
      invoiceSenderId: 1,
      Description: 'REP P/NAV 6 C/25 DOBLE FILO   LAREDO 192435329000136 2019-10-02',
      UnitCost: 350.88,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '14111824',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P50',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 32
    },
    {
      id: 113,
      invoiceSenderId: 1,
      Description: 'Magna',
      UnitCost: 563.01,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202108',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G33',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 114,
      invoiceSenderId: 1,
      Description: 'CR.CLARO 6MM. 3.60 X 2.60',
      UnitCost: 976.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11121806',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCB',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 33
    },
    {
      id: 115,
      invoiceSenderId: 1,
      Description: 'NA  ESQUINERO P/CRISTAL DOBLE 12.5MM',
      UnitCost: 265.54,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10213915',
      IdentificationNumber: 'LGGP-9225',
      InvoiceCatClaveUnidad: 'N42',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 116,
      invoiceSenderId: 1,
      Description: 'NA  SEPARADOR P/CRISTAL DOBLE 11.5MM X 5M',
      UnitCost: 857.05,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10171700',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J92',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 117,
      invoiceSenderId: 1,
      Description: 'CRISTAL CON CANTO PULIDO',
      UnitCost: 352.48,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11122003',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XEE',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 118,
      invoiceSenderId: 1,
      Description: 'DAP',
      UnitCost: 755.91,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12131806',
      IdentificationNumber: 'LAAF-3325',
      InvoiceCatClaveUnidad: 'K24',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 119,
      invoiceSenderId: 1,
      Description: 'Energia',
      UnitCost: 118.78,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10418333',
      IdentificationNumber: 'VUEE-5024',
      InvoiceCatClaveUnidad: 'D6',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 34
    },
    {
      id: 120,
      invoiceSenderId: 1,
      Description: 'JUNQUILLO 6.1  BLANCO',
      UnitCost: 115.51,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10411518',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N22',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 121,
      invoiceSenderId: 1,
      Description: 'CERCO CHAPA 6.1  BLANCO',
      UnitCost: 189.87,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317816',
      IdentificationNumber: 'VDYN-2840',
      InvoiceCatClaveUnidad: 'TA',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 122,
      invoiceSenderId: 1,
      Description: 'PASTA DIENTE',
      UnitCost: 893.5,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202102',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MIU',
      Ieps: null,
      RetIeps: null,
      RetVat: 16, RetIsr: 1
    },
    {
      id: 123,
      invoiceSenderId: 1,
      Description: 'DIADEMA',
      UnitCost: 118.34,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402860',
      IdentificationNumber: 'BDLD-6164',
      InvoiceCatClaveUnidad: 'J48',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 124,
      invoiceSenderId: 1,
      Description: 'MARCADORES',
      UnitCost: 901.52,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10226047',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'DZN',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 125,
      invoiceSenderId: 1,
      Description: 'PAPELERIA',
      UnitCost: 375.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10323401',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '5H',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 126,
      invoiceSenderId: 1,
      Description: 'BOT POP',
      UnitCost: 577.67,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10313708',
      IdentificationNumber: 'RZEX-0414',
      InvoiceCatClaveUnidad: 'Q31',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 127,
      invoiceSenderId: 1,
      Description: 'JALADERA "H" 50 COMB/PTS',
      UnitCost: 116.3,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10416803',
      IdentificationNumber: 'JGPG-5902',
      InvoiceCatClaveUnidad: 'K16',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 128,
      invoiceSenderId: 1,
      Description: 'CONECTOR GRAPA SIN RESAQUE BASE PLANA ZAMAC',
      UnitCost: 532.61,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402840',
      IdentificationNumber: 'YMCL-9472',
      InvoiceCatClaveUnidad: 'CDL',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 129,
      invoiceSenderId: 1,
      Description: 'CONECTOR GRAPA SIN RESAQUE BASE CURVA ZAMAC',
      UnitCost: 216.09,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '11161502',
      IdentificationNumber: 'TVRO-1997',
      InvoiceCatClaveUnidad: 'L18',
      Ieps: 0.030,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 4
    },
    {
      id: 130,
      invoiceSenderId: 1,
      Description: 'CRISTAL REFLEJANTE CLARO EXP 6.00 mm 2.5000 m 3.6000 mBRONCE, RC 396452',
      UnitCost: 274.15,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10326011',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H73',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 131,
      invoiceSenderId: 1,
      Description: 'BASE GIM O BRIDA SIMPLE P/2"',
      UnitCost: 703.4,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402736',
      IdentificationNumber: 'HGGG-2673',
      InvoiceCatClaveUnidad: 'KVR',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 132,
      invoiceSenderId: 1,
      Description: 'TAPA P/BRIDA DE 2" GIM',
      UnitCost: 570.53,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122201',
      IdentificationNumber: 'AQWI-8749',
      InvoiceCatClaveUnidad: 'XTD',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 133,
      invoiceSenderId: 1,
      Description: 'TAPON 3059 CON TUERCA P/2" TCRDAIS',
      UnitCost: 155.61,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10252302',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWY',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 134,
      invoiceSenderId: 1,
      Description: 'CONECTOR CAPBAIS 3042-3 ARTICULADO A PASAMANOS',
      UnitCost: 187.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202222',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '16',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 135,
      invoiceSenderId: 1,
      Description: 'KIT DE CANCEL DE BAÑO TORINO 2.00 NAT BTE',
      UnitCost: 763.86,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402370',
      IdentificationNumber: 'LGYO-7219',
      InvoiceCatClaveUnidad: 'P75',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 21
    },
    {
      id: 136,
      invoiceSenderId: 1,
      Description: 'Pieza 0738 REPUES SAB-10B P/MOD SAC BL C/',
      UnitCost: 782.81,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '21102101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L23',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 137,
      invoiceSenderId: 1,
      Description: 'Pieza 0569 REPUES P/CUCHILLA A44S 9MM C/1',
      UnitCost: 251.73,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10218315',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWR',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 138,
      invoiceSenderId: 1,
      Description: 'Pieza 0842 CUCH TH234 SILVER TIPO A METAL',
      UnitCost: 89.18,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10121603',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N69',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 139,
      invoiceSenderId: 1,
      Description: 'ULTRA VISION DS 50 72" X 100\' NEUTRAL',
      UnitCost: 970.23,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215423',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 140,
      invoiceSenderId: 1,
      Description: 'JALADERA 2226 1MT Y 90CM C/C SATIN',
      UnitCost: 614.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10313101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G78',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 141,
      invoiceSenderId: 1,
      Description: 'SEGURO 2045 PARA CADENA',
      UnitCost: 990.47,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20142406',
      IdentificationNumber: 'PLQD-9636',
      InvoiceCatClaveUnidad: 'M62',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 5, RetIsr: 1
    },
    {
      id: 142,
      invoiceSenderId: 1,
      Description: 'KIT BISAGRA HIDRAULICA Y HERRAJES P/80 KG',
      UnitCost: 393.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121602',
      IdentificationNumber: 'TFDL-2329',
      InvoiceCatClaveUnidad: 'A10',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 143,
      invoiceSenderId: 1,
      Description: 'VINIL CUÑA 11 NEGRO KGR',
      UnitCost: 259.69,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12142102',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'LA',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 34
    },
    {
      id: 144,
      invoiceSenderId: 1,
      Description: 'VINIL PATA 11 NEGRO KGR',
      UnitCost: 822.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301712',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P8',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 16
    },
    {
      id: 145,
      invoiceSenderId: 1,
      Description: 'JALADERA TIRADOR BLANCO',
      UnitCost: 987.55,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202379',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L63',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 146,
      invoiceSenderId: 1,
      Description: '1.20 X 1.80 CRISTAL LISO',
      UnitCost: 432.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10313607',
      IdentificationNumber: 'NBTJ-0485',
      InvoiceCatClaveUnidad: 'DRA',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 147,
      invoiceSenderId: 1,
      Description: 'BATIENTE 6.1  BLANCO',
      UnitCost: 646.6,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231626',
      IdentificationNumber: 'XGYO-7728',
      InvoiceCatClaveUnidad: 'F87',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 148,
      invoiceSenderId: 1,
      Description: 'Servicios de Telefonia Digital',
      UnitCost: 329.57,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10412216',
      IdentificationNumber: 'SKYL-3284',
      InvoiceCatClaveUnidad: 'P10',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 149,
      invoiceSenderId: 1,
      Description: 'Internet de Alta Velocidad',
      UnitCost: 37.18,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '21102100',
      IdentificationNumber: 'HOIJ-2626',
      InvoiceCatClaveUnidad: 'PZ',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 150,
      invoiceSenderId: 1,
      Description: 'Servicio Renta de Equipos',
      UnitCost: 503.24,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121127',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N22',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 151,
      invoiceSenderId: 1,
      Description: 'BOLSA DE HULE TRANSPARENTE 35X45CMS',
      UnitCost: 356.32,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10424802',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M71',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 152,
      invoiceSenderId: 1,
      Description: 'BOLSA DE HULE TRANSPARENTE 60X90CMS',
      UnitCost: 467.39,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10232051',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G46',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 153,
      invoiceSenderId: 1,
      Description: 'BOLSA DE HULE TRANSPARENTE 40X60CMS',
      UnitCost: 641.73,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11191602',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B9',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 154,
      invoiceSenderId: 1,
      Description: 'ESTRUCTURA DE ALUMINIO Y VIDRIO',
      UnitCost: 437.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10214613',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E78',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 27
    },
    {
      id: 155,
      invoiceSenderId: 1,
      Description: 'PELICULA CONTROL SOLAR, VIDRIO O ALUMINIO',
      UnitCost: 583.65,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202877',
      IdentificationNumber: 'DLOV-1636',
      InvoiceCatClaveUnidad: 'IU',
      Ieps: null,
      RetIeps: null,
      RetVat: 15, RetIsr: 19
    },
    {
      id: 156,
      invoiceSenderId: 1,
      Description: 'ROLLO DE PELICULA POLARIZADA',
      UnitCost: 674.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10323802',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A61',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 157,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO E INSTALACION DE PELICULA DE CONTROL SOLAR',
      UnitCost: 569.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202732',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P4',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 158,
      invoiceSenderId: 1,
      Description: 'REPARACION DE CANCELERIA DE ALUMINIO Y VIDRIO',
      UnitCost: 536.63,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10417351',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M93',
      Ieps: null,
      RetIeps: null,
      RetVat: 15, RetIsr: 12
    },
    {
      id: 159,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO E INSTALACION DE PELICULA DE SEGURIDAD',
      UnitCost: 353.89,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121515',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A68',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 160,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO E INTALACION DE VIDRIO CLARO',
      UnitCost: 727.42,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '13101706',
      IdentificationNumber: 'THZH-2103',
      InvoiceCatClaveUnidad: 'XNG',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 161,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO E INSTALACION DE PELICULA DECORATIVA',
      UnitCost: 886.11,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10341900',
      IdentificationNumber: 'BXGI-5923',
      InvoiceCatClaveUnidad: 'G88',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 162,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE CABEZAL, LIMPIEZA AL CABEZAL Y ARMADO, PULIDO',
      UnitCost: 188.67,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10362042',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TAN',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 163,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE CAFE',
      UnitCost: 195.62,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10314100',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D42',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 8
    },
    {
      id: 164,
      invoiceSenderId: 1,
      Description: 'Pago de Mensualidad Abril 2020 Visoor',
      UnitCost: 918.48,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10316411',
      IdentificationNumber: 'OXXJ-5466',
      InvoiceCatClaveUnidad: 'N12',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 165,
      invoiceSenderId: 1,
      Description: 'MONTAJE DE MALLA CICLONICA RECUPERADA DE 33X33MM, ALTURA DE 6M, INCLUYE TAQUETES DE EXPANSION',
      UnitCost: 193.07,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312653',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'FE',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null, RetIsr: 25
    },
    {
      id: 166,
      invoiceSenderId: 1,
      Description: 'DESMONTAJE CON RECUPERACION DE MALLA CICLONICA 33X33MM, ALTURA DE 6M',
      UnitCost: 108.96,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101512',
      IdentificationNumber: 'JSGZ-8989',
      InvoiceCatClaveUnidad: 'XTU',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 167,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO Y MONTAJE DE BRAZO HIDRAULICO CIERRA PUERTAS, FASE II, IV MARZO 2020',
      UnitCost: 91.01,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10362046',
      IdentificationNumber: 'KFFA-3096',
      InvoiceCatClaveUnidad: 'C23',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 11
    },
    {
      id: 168,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO Y MONTAJE DE BRAZO HIDRAULICO CIERRA PUERTAS',
      UnitCost: 541.02,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10222701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B14',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 11
    },
    {
      id: 169,
      invoiceSenderId: 1,
      Description: 'MANO DE ORA DEL SERVICIO DE REPARACIONES Y MANTENIMIENTO A MOTOCICLETA HONDA CGL125CC. PLACA S/PSAN7V. COLOR PLATA.',
      UnitCost: 519.9,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10362040',
      IdentificationNumber: 'UTMD-1544',
      InvoiceCatClaveUnidad: 'LD',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 170,
      invoiceSenderId: 1,
      Description: 'KIT DE TRANSMISION DE CADENA.',
      UnitCost: 564.81,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402888',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XZJ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 171,
      invoiceSenderId: 1,
      Description: 'BUJIA.',
      UnitCost: 272.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10431636',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '2G',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 27
    },
    {
      id: 172,
      invoiceSenderId: 1,
      Description: 'ACEITE PARA MOTOR 4 TIEMPOS.',
      UnitCost: 657.61,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10432067',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J84',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 173,
      invoiceSenderId: 1,
      Description: 'ESTIMACION #2, CALLE MARISCAL',
      UnitCost: 547.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10216105',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E74',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: 12, RetIsr: 8
    },
    {
      id: 174,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10803',
      UnitCost: 704.61,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10211804',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H53',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 17
    },
    {
      id: 175,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10812',
      UnitCost: 387.58,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218214',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A50',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 176,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10813',
      UnitCost: 448.51,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10431629',
      IdentificationNumber: 'CESV-8067',
      InvoiceCatClaveUnidad: 'CMQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 177,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10788',
      UnitCost: 178.99,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413112',
      IdentificationNumber: 'KSRP-6851',
      InvoiceCatClaveUnidad: 'HN',
      Ieps: 0.080,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 178,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10783',
      UnitCost: 263.56,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10315504',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XBK',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 17
    },
    {
      id: 179,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10741',
      UnitCost: 266.58,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11162108',
      IdentificationNumber: 'OCKN-2478',
      InvoiceCatClaveUnidad: 'L85',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 180,
      invoiceSenderId: 1,
      Description: 'Desarrollo campañas publicitarias - Abril',
      UnitCost: 798.95,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20102004',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L64',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 181,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10737',
      UnitCost: 672.85,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10412653',
      IdentificationNumber: 'USEW-1318',
      InvoiceCatClaveUnidad: 'XWF',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 182,
      invoiceSenderId: 1,
      Description: 'CONSUMO DE LUZ: OCTUBRE',
      UnitCost: 424.09,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '13111006',
      IdentificationNumber: 'KSGE-3857',
      InvoiceCatClaveUnidad: 'K51',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 183,
      invoiceSenderId: 1,
      Description: 'FRUTOS CIVILES MES MARZO 2020 LOCAL CALLE 20A # 315A FRACC. MONTEBELLO',
      UnitCost: 143.14,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10315407',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'DJ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 184,
      invoiceSenderId: 1,
      Description: 'FLETE REFERENCIA A-10738',
      UnitCost: 382.31,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202888',
      IdentificationNumber: 'JKAD-5741',
      InvoiceCatClaveUnidad: '2V',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 185,
      invoiceSenderId: 1,
      Description: 'venta al cliete',
      UnitCost: 911.05,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10326032',
      IdentificationNumber: 'LDLA-2127',
      InvoiceCatClaveUnidad: 'G79',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 32
    },
    {
      id: 186,
      invoiceSenderId: 1,
      Description: 'SOLERA 1 X 1/8',
      UnitCost: 981.15,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417938',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'CMQ',
      Ieps: null,
      RetIeps: null,
      RetVat: 6, RetIsr: 24
    },
    {
      id: 187,
      invoiceSenderId: 1,
      Description: 'CHAPA PHILLIPS #900 IZQUIERDA',
      UnitCost: 826.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202424',
      IdentificationNumber: 'KLVY-3363',
      InvoiceCatClaveUnidad: 'G40',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 20
    },
    {
      id: 188,
      invoiceSenderId: 1,
      Description: 'CARTUCHOS DE TINTA S2 NEGRO',
      UnitCost: 334.22,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20121502',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F91',
      Ieps: 0.060,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 189,
      invoiceSenderId: 1,
      Description: 'EQUIPO CODIFICADOR ANSER U2 PRO S. CON CONTROL REMOTO, SOPORTES PARA ANCLAJE, ELIMINADOR DE CORRIENTE Y TARJETA SD CADA EQUIPO CON NUMERO DE SERIES 3095615,3095616 Y 3104198.',
      UnitCost: 991.15,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11121900',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MND',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 190,
      invoiceSenderId: 1,
      Description: 'LLANTA 235/60R18 MICHELIN CON ALINEACION Y BALANCEO.',
      UnitCost: 910.49,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10301703',
      IdentificationNumber: 'YSQD-6164',
      InvoiceCatClaveUnidad: 'D55',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 191,
      invoiceSenderId: 1,
      Description: 'extinguidor nuevo de polvo químico seco de 6 kg con soporte',
      UnitCost: 986.27,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318329',
      IdentificationNumber: 'YKFF-4305',
      InvoiceCatClaveUnidad: 'H47',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 192,
      invoiceSenderId: 1,
      Description: 'Recarga de extinguidor de polvo quimico seco de 6 kg.',
      UnitCost: 64.85,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12164500',
      IdentificationNumber: 'EIII-4618',
      InvoiceCatClaveUnidad: 'WM',
      Ieps: 0.060,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 193,
      invoiceSenderId: 1,
      Description: 'SERVICIOS BANCARIOS',
      UnitCost: 79.34,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10201736',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H72',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 194,
      invoiceSenderId: 1,
      Description: 'ECO DE HOMBRO',
      UnitCost: 682.02,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10315456',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '5A',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 195,
      invoiceSenderId: 1,
      Description: 'HONORARIOS CONSULTA ATENCION:ALEJANDRO BATIZ SANCHEZ  25 MARZO 2020',
      UnitCost: 530.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10414910',
      IdentificationNumber: 'BNYU-8671',
      InvoiceCatClaveUnidad: '96',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 196,
      invoiceSenderId: 1,
      Description: 'Comisiones',
      UnitCost: 628.38,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10316206',
      IdentificationNumber: 'BKTQ-4849',
      InvoiceCatClaveUnidad: 'XPK',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 197,
      invoiceSenderId: 1,
      Description: 'FRIJOL PERUANO',
      UnitCost: 600.05,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10424202',
      IdentificationNumber: 'UGIV-3918',
      InvoiceCatClaveUnidad: 'ACT',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 198,
      invoiceSenderId: 1,
      Description: 'BP REGULAR 87 OCTANOS',
      UnitCost: 481.68,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10418319',
      IdentificationNumber: 'CGBL-8782',
      InvoiceCatClaveUnidad: 'XFE',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 199,
      invoiceSenderId: 1,
      Description: 'BOQUILLA DE RETORNO 1 1/2 X 3/4',
      UnitCost: 270.78,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10151536',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'IP',
      Ieps: null,
      RetIeps: 1.600,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 200,
      invoiceSenderId: 1,
      Description: 'COMISIONES MARZO AGENTE 15594',
      UnitCost: 158.13,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10218003',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'LI',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 201,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE TRANSPORTE DE PERSONAL',
      UnitCost: 773.42,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10415202',
      IdentificationNumber: 'OJGT-7135',
      InvoiceCatClaveUnidad: 'B55',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 6
    },
    {
      id: 202,
      invoiceSenderId: 1,
      Description: 'MANTENIMIENTO DE SOFTWARE',
      UnitCost: 637.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10218331',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'QH',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 203,
      invoiceSenderId: 1,
      Description: 'PÓLIZA PROTEC ANUAL',
      UnitCost: 929.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402867',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XPV',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 204,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE IMPLEMENTACION DE APLICACIONES',
      UnitCost: 696.34,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '14122200',
      IdentificationNumber: 'UNLU-9018',
      InvoiceCatClaveUnidad: 'DB',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 205,
      invoiceSenderId: 1,
      Description: 'HOSPEDAJE',
      UnitCost: 745.57,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202500',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q11',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 206,
      invoiceSenderId: 1,
      Description: 'CONSUMO DE ALIMENTOS',
      UnitCost: 328.23,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '15121525',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N23',
      Ieps: null,
      RetIeps: null,
      RetVat: 11, RetIsr: 7
    },
    {
      id: 207,
      invoiceSenderId: 1,
      Description: 'Servicios de Telecomunicaciones',
      UnitCost: 562.37,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10225103',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H12',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 208,
      invoiceSenderId: 1,
      Description: 'TUBO DE 20 GRS DE SOLDADURA ALEA 60/40 1',
      UnitCost: 578.27,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10222300',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L82',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 5, RetIsr: 31
    },
    {
      id: 209,
      invoiceSenderId: 1,
      Description: 'PASTA PARA SOLDAR 25 GR',
      UnitCost: 211.84,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218218',
      IdentificationNumber: 'OPBS-9574',
      InvoiceCatClaveUnidad: 'XCQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 210,
      invoiceSenderId: 1,
      Description: 'CUBIERTA P/CONECTOR TIPO DB-9 PLASTICO',
      UnitCost: 877.37,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10502925',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'NB',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 7, RetIsr: 26
    },
    {
      id: 211,
      invoiceSenderId: 1,
      Description: 'EXTENSION DB9 HEMBRA A MACHO 1.8M',
      UnitCost: 19.71,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10232104',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P32',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 212,
      invoiceSenderId: 1,
      Description: 'PILA PARA TERMINAL VX 680.',
      UnitCost: 312.13,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215426',
      IdentificationNumber: 'EKDW-4147',
      InvoiceCatClaveUnidad: 'DRA',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 10
    },
    {
      id: 213,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 570, IMPRESORA Y RODILLO.',
      UnitCost: 10.02,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302306',
      IdentificationNumber: 'FVMJ-5988',
      InvoiceCatClaveUnidad: 'AZ',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 26
    },
    {
      id: 214,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 510, RODILLO.',
      UnitCost: 786.11,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12164801',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G27',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 28
    },
    {
      id: 215,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 520, IMPRESORA Y RODILLO.',
      UnitCost: 461.62,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12164801',
      IdentificationNumber: 'UPEN-4094',
      InvoiceCatClaveUnidad: 'D82',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 5, RetIsr: 19
    },
    {
      id: 216,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 520, RODILLO.',
      UnitCost: 871.48,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10311717',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E39',
      Ieps: null,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 217,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 520, IMPRESORA, RODILLO Y TECLADO.',
      UnitCost: 946.06,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217703',
      IdentificationNumber: 'QPBA-1481',
      InvoiceCatClaveUnidad: 'J96',
      Ieps: null,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 218,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 520, CARGA DE DRIVERS Y COMPONENTES.',
      UnitCost: 177.14,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402323',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ATT',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 219,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 520, IMPRESORA.',
      UnitCost: 712.25,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202733',
      IdentificationNumber: 'LGXP-8520',
      InvoiceCatClaveUnidad: 'G38',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 220,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 520, TECLADO.',
      UnitCost: 156.64,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302769',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N34',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 221,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 510, IMPRESORA Y RODILLO.',
      UnitCost: 808.66,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402349',
      IdentificationNumber: 'NAPO-8541',
      InvoiceCatClaveUnidad: 'A22',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 222,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 680, PINES DE CARGA PILA EXTERNA.',
      UnitCost: 45.11,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121316',
      IdentificationNumber: 'BFOQ-2201',
      InvoiceCatClaveUnidad: 'J63',
      Ieps: 0.530,
      RetIeps: null,
      RetVat: 16, RetIsr: 30
    },
    {
      id: 223,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 680, TAPA DE IMPRESORA',
      UnitCost: 414.47,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10313510',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '72',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 224,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 680, IMPRESORA Y TAPA PRINTER.',
      UnitCost: 498.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '13111304',
      IdentificationNumber: 'CATP-6102',
      InvoiceCatClaveUnidad: 'K14',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 29
    },
    {
      id: 225,
      invoiceSenderId: 1,
      Description: 'REPARACION DE TERMINAL VX 680, PINES DE CARGA PILA EXTERNA Y TAPA DE IMPRESORA.',
      UnitCost: 984.71,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202235',
      IdentificationNumber: 'STIP-5433',
      InvoiceCatClaveUnidad: 'N93',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 24
    },
    {
      id: 226,
      invoiceSenderId: 1,
      Description: 'Cart. Rem de tinta p/hp 662 color XL',
      UnitCost: 161.25,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10361503',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M17',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 227,
      invoiceSenderId: 1,
      Description: 'Cart. Rem de tinta p/hp 662 negro XL',
      UnitCost: 873.65,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20111617',
      IdentificationNumber: 'RXBF-0185',
      InvoiceCatClaveUnidad: 'Q14',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 228,
      invoiceSenderId: 1,
      Description: 'COMISIONES MARZO 2020 AGENTE 7769',
      UnitCost: 33.64,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WR',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 229,
      invoiceSenderId: 1,
      Description: 'ZAPATERA ARMABLE 15 PARES NATURAL',
      UnitCost: 363.55,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10415452',
      IdentificationNumber: 'FXOT-1271',
      InvoiceCatClaveUnidad: 'L31',
      Ieps: 0.030,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 12
    },
    {
      id: 230,
      invoiceSenderId: 1,
      Description: 'COMISIONES FEBRERO 2020 AGENTE 80826',
      UnitCost: 780.2,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10315807',
      IdentificationNumber: 'KPUY-6389',
      InvoiceCatClaveUnidad: 'H92',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 18
    },
    {
      id: 231,
      invoiceSenderId: 1,
      Description: 'Recarga de extinguidor de polvo quimico seco de 4.5 kg',
      UnitCost: 175.61,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302870',
      IdentificationNumber: 'EEXA-0610',
      InvoiceCatClaveUnidad: 'B10',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 232,
      invoiceSenderId: 1,
      Description: 'Recarga de extinguidor de bióxido de carbono de 4.5 kg',
      UnitCost: 70.13,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10231710',
      IdentificationNumber: 'ZNGX-4208',
      InvoiceCatClaveUnidad: 'N94',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 233,
      invoiceSenderId: 1,
      Description: 'AFLAMMIT PCI 202 - 25 KG',
      UnitCost: 515.46,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12171610',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A71',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 234,
      invoiceSenderId: 1,
      Description: 'COFIA PLISADA 100 PIEZAS Información aduanera: Fecha: 2020-01-09 Num: 201117930000006 Aduana: ENSENADA, B.C.',
      UnitCost: 907.13,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12164101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E19',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 235,
      invoiceSenderId: 1,
      Description: 'GUANTE NITRILO TIPO SOLVEX TALLA 7',
      UnitCost: 777.07,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10216003',
      IdentificationNumber: 'MZFM-2488',
      InvoiceCatClaveUnidad: 'XTO',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 236,
      invoiceSenderId: 1,
      Description: '32011                MAGNA',
      UnitCost: 910.06,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '21101804',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '5P',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 237,
      invoiceSenderId: 1,
      Description: 'FILTRO',
      UnitCost: 388.28,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10412803',
      IdentificationNumber: 'QCOX-2593',
      InvoiceCatClaveUnidad: 'XZS',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 238,
      invoiceSenderId: 1,
      Description: 'ARANDELA',
      UnitCost: 874.46,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10414903',
      IdentificationNumber: 'NYHN-0792',
      InvoiceCatClaveUnidad: 'X5H',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 11
    },
    {
      id: 239,
      invoiceSenderId: 1,
      Description: 'EMPAQUE',
      UnitCost: 455.86,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10325100',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A73',
      Ieps: 0.060,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 240,
      invoiceSenderId: 1,
      Description: 'ACEITE',
      UnitCost: 728.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202173',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'U2',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 241,
      invoiceSenderId: 1,
      Description: 'ACEITE 5W30',
      UnitCost: 737.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202145',
      IdentificationNumber: 'GZMM-2185',
      InvoiceCatClaveUnidad: 'C26',
      Ieps: null,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 242,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE TRANSMISION',
      UnitCost: 302.64,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10502017',
      IdentificationNumber: 'CAYC-1563',
      InvoiceCatClaveUnidad: 'MQH',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 243,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE 10,000 KM    (D/C)',
      UnitCost: 404.23,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10301920',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XBS',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 244,
      invoiceSenderId: 1,
      Description: 'EXAMEN GENERAL DE ORINA',
      UnitCost: 833.65,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10421701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'PE',
      Ieps: 0.080,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 34
    },
    {
      id: 245,
      invoiceSenderId: 1,
      Description: 'QUÍMICA SANGUÍNEA CON COLESTEROL Y TRIGLICÉRIDOS',
      UnitCost: 67.48,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20122208',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A32',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 246,
      invoiceSenderId: 1,
      Description: 'BIOMETRÍA HEMÁTICA',
      UnitCost: 61.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402214',
      IdentificationNumber: 'QLPG-6992',
      InvoiceCatClaveUnidad: 'M35',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 247,
      invoiceSenderId: 1,
      Description: 'AC TOTAL DE LA HEPATITIS C (AC HCV )',
      UnitCost: 286.39,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10401913',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'HBX',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 248,
      invoiceSenderId: 1,
      Description: 'SODIO Y POTASIO',
      UnitCost: 921.73,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312643',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XYW',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 249,
      invoiceSenderId: 1,
      Description: 'ALFAFETOPROTEÍNA (AFP)',
      UnitCost: 299.18,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10415612',
      IdentificationNumber: 'JHBO-9821',
      InvoiceCatClaveUnidad: '93',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 250,
      invoiceSenderId: 1,
      Description: 'PRUEBAS DE FUNCIÓN HEPÁTICA COMPLETA',
      UnitCost: 961.31,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317928',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H35',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 251,
      invoiceSenderId: 1,
      Description: 'ANTÍGENO DE SUPERFICIE DE LA HEPATITIS B (HBSAG)',
      UnitCost: 759.56,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20121444',
      IdentificationNumber: 'WJRX-7448',
      InvoiceCatClaveUnidad: 'G56',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 252,
      invoiceSenderId: 1,
      Description: 'HUEVO BLANCO CAJA PLASTICO 360',
      UnitCost: 427.81,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '13111203',
      IdentificationNumber: 'IIKI-7409',
      InvoiceCatClaveUnidad: 'J76',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 253,
      invoiceSenderId: 1,
      Description: 'Servicios de Facturación',
      UnitCost: 280.9,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10232016',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KMK',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 254,
      invoiceSenderId: 1,
      Description: 'BOLSA 100 CINCHOS NEGROS 3.6X150MM',
      UnitCost: 821.74,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11172301',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MAW',
      Ieps: 0.080,
      RetIeps: null,
      RetVat: 13, RetIsr: 17
    },
    {
      id: 255,
      invoiceSenderId: 1,
      Description: 'CABLE TELEFONICO 4 VIAS MODULAR AWG 28 N',
      UnitCost: 892.32,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12161507',
      IdentificationNumber: 'XHFJ-3250',
      InvoiceCatClaveUnidad: '4W',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 256,
      invoiceSenderId: 1,
      Description: 'BANCOS RECEPCIÓN ALMACEN',
      UnitCost: 284.88,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10412200',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A77',
      Ieps: 0.090,
      RetIeps: null,
      RetVat: null, RetIsr: 3
    },
    {
      id: 257,
      invoiceSenderId: 1,
      Description: 'PLATOS DE COMIDA PLATOS DE DESAYUNO Y COMIDA PERSONAL BASE SEMANA 7',
      UnitCost: 55.32,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10311604',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'QR',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 258,
      invoiceSenderId: 1,
      Description: 'PLATOS DE COMIDA PLATOS DE COMIDA PERSONAL BASE SEMANA 7',
      UnitCost: 114.11,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11162105',
      IdentificationNumber: 'UMGE-6025',
      InvoiceCatClaveUnidad: 'A36',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 259,
      invoiceSenderId: 1,
      Description: 'PLATOS DE COMIDA PLATOS DE COMIDA PARA LOS TRABAJADORES SEMANA 7',
      UnitCost: 43.26,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10316500',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '17',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 260,
      invoiceSenderId: 1,
      Description: 'ZAPATERA ARMABLE 35 PARES NATURAL',
      UnitCost: 681.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10214201',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'R1',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 261,
      invoiceSenderId: 1,
      Description: 'TIEMPO EXTRAORDINARIO. PROYECTO LAS HUASTECAS FASE 4. TRABAJOS DE TOPOGRAFÍA. EN EL PERIODO DEL 23 DE MARZO AL 11 DE ABRIL DEL 2020.',
      UnitCost: 533.55,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402879',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XPO',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 262,
      invoiceSenderId: 1,
      Description: 'PROYECTO LAS HUASTECAS FASE 4. TRABAJOS DE TOPOGRAFÍA. TRABAJOS REALIZADOS EN TIEMPO ORDINARIO. EN EL PERIODO DEL 30 DE MARZO AL 11 DE ABRIL DEL 2020.',
      UnitCost: 373.76,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20122342',
      IdentificationNumber: 'CQML-7431',
      InvoiceCatClaveUnidad: 'E83',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 263,
      invoiceSenderId: 1,
      Description: 'Lona front impresa a color e instalaciòn en Patio Claveria',
      UnitCost: 836.0,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10314915',
      IdentificationNumber: 'UVWQ-1343',
      InvoiceCatClaveUnidad: 'PFL',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 24
    },
    {
      id: 264,
      invoiceSenderId: 1,
      Description: 'ALINEACION',
      UnitCost: 354.79,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10313101',
      IdentificationNumber: 'ALOL-3964',
      InvoiceCatClaveUnidad: 'UB',
      Ieps: null,
      RetIeps: null,
      RetVat: 6, RetIsr: 19
    },
    {
      id: 265,
      invoiceSenderId: 1,
      Description: 'BALANCEOS',
      UnitCost: 103.13,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317824',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '3B',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 266,
      invoiceSenderId: 1,
      Description: 'VIAJE DE PIPA PARA TRASPASO DE HARINA DE LA MONTAÑA IRAPUATO A HARINERA LOS PIRINEOS',
      UnitCost: 54.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121010',
      IdentificationNumber: 'KBIB-5169',
      InvoiceCatClaveUnidad: 'AI',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 267,
      invoiceSenderId: 1,
      Description: 'VIAJE EN PIPA PARA TRASPASO DE HARINA DE LA MONTAÑA IRAPUATO A HARINERA LOS PIRINEOS',
      UnitCost: 401.56,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12164900',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XDU',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 268,
      invoiceSenderId: 1,
      Description: 'FLETE DE HARINA FAA-169487',
      UnitCost: 512.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10326303',
      IdentificationNumber: 'SJXX-6004',
      InvoiceCatClaveUnidad: 'J55',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 269,
      invoiceSenderId: 1,
      Description: 'FLETE DE HARINA FAA-169433',
      UnitCost: 315.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B4',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 270,
      invoiceSenderId: 1,
      Description: 'FLETE DE HARINA FAA-169507',
      UnitCost: 210.27,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10318026',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XBW',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 271,
      invoiceSenderId: 1,
      Description: 'BOLSA PARA BASURA 90X120',
      UnitCost: 167.61,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '21101505',
      IdentificationNumber: 'YPMZ-2405',
      InvoiceCatClaveUnidad: 'N60',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 272,
      invoiceSenderId: 1,
      Description: 'Cubreboca Nariz Y Ojos De Plastico Lavable',
      UnitCost: 376.91,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10314815',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MIO',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 273,
      invoiceSenderId: 1,
      Description: 'DEPOSOTO DE AGUA DE LAVADO',
      UnitCost: 465.52,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10422000',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A55',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 274,
      invoiceSenderId: 1,
      Description: 'VARILLA ACOPLAMIENTO',
      UnitCost: 904.05,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202782',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P72',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 275,
      invoiceSenderId: 1,
      Description: 'RECEPCION GOMA',
      UnitCost: 684.95,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10432026',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A64',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 276,
      invoiceSenderId: 1,
      Description: 'REFRIGERANTE',
      UnitCost: 304.61,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402011',
      IdentificationNumber: 'TEIT-6082',
      InvoiceCatClaveUnidad: 'J46',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 277,
      invoiceSenderId: 1,
      Description: 'SOPORTE',
      UnitCost: 281.1,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10314301',
      IdentificationNumber: 'WRQH-6489',
      InvoiceCatClaveUnidad: 'XQL',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 278,
      invoiceSenderId: 1,
      Description: 'PLACA ANTISONORA',
      UnitCost: 61.51,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12162303',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E5',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 279,
      invoiceSenderId: 1,
      Description: 'TAPON',
      UnitCost: 549.63,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10301901',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D35',
      Ieps: null,
      RetIeps: null,
      RetVat: 3, RetIsr: 32
    },
    {
      id: 280,
      invoiceSenderId: 1,
      Description: 'ANILLO JUNTA',
      UnitCost: 13.98,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10213007',
      IdentificationNumber: 'JWSL-0769',
      InvoiceCatClaveUnidad: 'L55',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 1, RetIsr: 27
    },
    {
      id: 281,
      invoiceSenderId: 1,
      Description: 'AMORTIGUADORES',
      UnitCost: 88.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10151609',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KS',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 282,
      invoiceSenderId: 1,
      Description: 'MANGUERA',
      UnitCost: 606.31,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11162132',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KD',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 283,
      invoiceSenderId: 1,
      Description: '1 JUEGO PASTILLAS P. FRENOS DISCO',
      UnitCost: 435.7,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10214403',
      IdentificationNumber: 'FZJN-4544',
      InvoiceCatClaveUnidad: 'GK',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 14
    },
    {
      id: 284,
      invoiceSenderId: 1,
      Description: 'MATERIALES DIVERSOS  (9999)',
      UnitCost: 231.3,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402747',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M22',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 7
    },
    {
      id: 285,
      invoiceSenderId: 1,
      Description: 'AMORTIGUADORES DESM Y MONTAR  (1410200002)',
      UnitCost: 565.12,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121443',
      IdentificationNumber: 'SJDE-3922',
      InvoiceCatClaveUnidad: 'MTK',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 286,
      invoiceSenderId: 1,
      Description: 'ZAPATA DE FRENO DESM Y MONTAR  (1410200002)',
      UnitCost: 36.09,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10231637',
      IdentificationNumber: 'BSLU-4644',
      InvoiceCatClaveUnidad: 'XYX',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 287,
      invoiceSenderId: 1,
      Description: 'ALINEACION (1410200011)',
      UnitCost: 393.76,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417505',
      IdentificationNumber: 'JKFP-7548',
      InvoiceCatClaveUnidad: 'P36',
      Ieps: 0.060,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 14
    },
    {
      id: 288,
      invoiceSenderId: 1,
      Description: 'PASARRUEDA INT. DEL. DESM+MONTADO (1410200002)',
      UnitCost: 465.6,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218303',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J14',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null, RetIsr: 18
    },
    {
      id: 289,
      invoiceSenderId: 1,
      Description: 'LAVAR DEPOSITO CHISGUETEROS  (1410200002)',
      UnitCost: 864.47,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13101602',
      IdentificationNumber: 'JCME-8798',
      InvoiceCatClaveUnidad: 'HI',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 290,
      invoiceSenderId: 1,
      Description: 'TOPES GOMA EJE TRAS. DESM+MONTADO (1410200002)',
      UnitCost: 263.58,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10218000',
      IdentificationNumber: 'BNQZ-1039',
      InvoiceCatClaveUnidad: 'D53',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 20
    },
    {
      id: 291,
      invoiceSenderId: 1,
      Description: 'AMORTIGUADORES DESMONTADO Y MONTADO (1410200002)',
      UnitCost: 870.0,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10362017',
      IdentificationNumber: 'XJNO-9656',
      InvoiceCatClaveUnidad: '2M',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 292,
      invoiceSenderId: 1,
      Description: 'MUELLES HELICOIDALES DESM+MONTADO (1410200002)',
      UnitCost: 198.81,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217969',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H07',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 293,
      invoiceSenderId: 1,
      Description: 'PATAS TELESC-PICAS DESMONTADO Y (1410200002)  MONTADO',
      UnitCost: 907.31,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13101606',
      IdentificationNumber: 'WDFZ-9315',
      InvoiceCatClaveUnidad: 'P83',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 294,
      invoiceSenderId: 1,
      Description: 'VARILLAS ACOPL.B.ESTABI. (1410200002)  DESM+MONTADO',
      UnitCost: 533.57,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302447',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M25',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 295,
      invoiceSenderId: 1,
      Description: 'COJINETES B. ESTABILIZ. (1410200002)  DESM+MONTADO',
      UnitCost: 146.88,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302136',
      IdentificationNumber: 'RPXS-9713',
      InvoiceCatClaveUnidad: 'M66',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 25
    },
    {
      id: 296,
      invoiceSenderId: 1,
      Description: 'COJINETES BRAZO OSCIL. DESM+MONTADO (1410200002)',
      UnitCost: 651.32,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10362062',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'QTD',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 297,
      invoiceSenderId: 1,
      Description: 'BRAZOS OSCILANTES INFER. (1410200002)  DESM+MONTADO',
      UnitCost: 717.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302450',
      IdentificationNumber: 'FWPK-1047',
      InvoiceCatClaveUnidad: 'KHZ',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 298,
      invoiceSenderId: 1,
      Description: 'ZAPATAS FRENO TRAS. DESMONTADO Y (1410200002)  MONTADO',
      UnitCost: 933.69,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '15121529',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K51',
      Ieps: null,
      RetIeps: null,
      RetVat: 8, RetIsr: 10
    },
    {
      id: 299,
      invoiceSenderId: 1,
      Description: 'RUEDAS DESMONTADO Y MONTADO (1410200002)',
      UnitCost: 568.37,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '13111028',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'AJ',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 300,
      invoiceSenderId: 1,
      Description: 'DISCOS FRENO DEL. DESMONTADO Y (1410200002)  MONTADO',
      UnitCost: 454.15,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318100',
      IdentificationNumber: 'FIBD-9068',
      InvoiceCatClaveUnidad: 'MIU',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 301,
      invoiceSenderId: 1,
      Description: 'PAQ. BALANCEO',
      UnitCost: 675.88,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10211505',
      IdentificationNumber: 'HUOW-4744',
      InvoiceCatClaveUnidad: 'C16',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 302,
      invoiceSenderId: 1,
      Description: 'LUBRICACION DE SEMIEJES ARTICULADO  ',
      UnitCost: 790.7,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10301926',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L70',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 303,
      invoiceSenderId: 1,
      Description: 'consumo',
      UnitCost: 613.93,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10441605',
      IdentificationNumber: 'ZWZN-1164',
      InvoiceCatClaveUnidad: 'XJR',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 304,
      invoiceSenderId: 1,
      Description: 'CHIA',
      UnitCost: 505.6,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332021',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WI',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 305,
      invoiceSenderId: 1,
      Description: 'SEMILLA DE GIRASOL',
      UnitCost: 619.59,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10318303',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A20',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 15
    },
    {
      id: 306,
      invoiceSenderId: 1,
      Description: 'CACAHUATE HORNEADO',
      UnitCost: 161.98,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202882',
      IdentificationNumber: 'PCLW-4933',
      InvoiceCatClaveUnidad: 'K74',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 307,
      invoiceSenderId: 1,
      Description: 'AVENA',
      UnitCost: 176.13,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10316332',
      IdentificationNumber: 'BIMR-9953',
      InvoiceCatClaveUnidad: 'B30',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 33
    },
    {
      id: 308,
      invoiceSenderId: 1,
      Description: 'PEPITA VERDE',
      UnitCost: 656.82,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12131708',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A88',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 309,
      invoiceSenderId: 1,
      Description: 'REVOLTURA PARA PÁJAROS',
      UnitCost: 271.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12131503',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C36',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 20
    },
    {
      id: 310,
      invoiceSenderId: 1,
      Description: 'PASA JUMBO',
      UnitCost: 232.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10421602',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L63',
      Ieps: 0.090,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 311,
      invoiceSenderId: 1,
      Description: 'ARANDANO',
      UnitCost: 560.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11101611',
      IdentificationNumber: 'QPNP-5892',
      InvoiceCatClaveUnidad: 'C56',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 312,
      invoiceSenderId: 1,
      Description: 'PILONCILLO',
      UnitCost: 194.77,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10411512',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'GV',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 4
    },
    {
      id: 313,
      invoiceSenderId: 1,
      Description: 'GARBANZA',
      UnitCost: 782.55,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10214812',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ODE',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 22
    },
    {
      id: 314,
      invoiceSenderId: 1,
      Description: 'ALMENDRA FILETEADA C/C',
      UnitCost: 735.58,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10216504',
      IdentificationNumber: 'YDAZ-5335',
      InvoiceCatClaveUnidad: 'X1F',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 5
    },
    {
      id: 315,
      invoiceSenderId: 1,
      Description: 'NUEZ DE LA INDIA',
      UnitCost: 497.26,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10311724',
      IdentificationNumber: 'URIS-8544',
      InvoiceCatClaveUnidad: 'P22',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 316,
      invoiceSenderId: 1,
      Description: 'PISTACHE',
      UnitCost: 875.12,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121106',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C27',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 317,
      invoiceSenderId: 1,
      Description: 'PEPITA DE GIRASOL',
      UnitCost: 589.15,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10416702',
      IdentificationNumber: 'GSFN-7933',
      InvoiceCatClaveUnidad: 'J23',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 25
    },
    {
      id: 318,
      invoiceSenderId: 1,
      Description: 'ALPISTE',
      UnitCost: 379.87,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312402',
      IdentificationNumber: 'JTOJ-0729',
      InvoiceCatClaveUnidad: 'DZP',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 16, RetIsr: 7
    },
    {
      id: 319,
      invoiceSenderId: 1,
      Description: 'Gasolina Magna',
      UnitCost: 481.21,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402617',
      IdentificationNumber: 'FFDE-6819',
      InvoiceCatClaveUnidad: 'VLT',
      Ieps: null,
      RetIeps: null,
      RetVat: 2, RetIsr: 11
    },
    {
      id: 320,
      invoiceSenderId: 1,
      Description: 'COPIA-IMPR CARTA B&N 500-999',
      UnitCost: 406.71,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202740',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D88',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 321,
      invoiceSenderId: 1,
      Description: 'MANO DE OBRA DE (CAMBIO DE ROTULAS Y CAMBIO DE BRAZO AUXILIAR)',
      UnitCost: 922.23,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10131507',
      IdentificationNumber: 'SAWR-7319',
      InvoiceCatClaveUnidad: 'B7',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 322,
      invoiceSenderId: 1,
      Description: 'MANTENIMIENTO Y MONITOREO AL SISTEMA DE ALARMA, CORRESPONDIENTE AL MES DE ABRIL DE 2020.',
      UnitCost: 105.32,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402163',
      IdentificationNumber: 'TFQQ-6217',
      InvoiceCatClaveUnidad: 'E15',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 323,
      invoiceSenderId: 1,
      Description: 'MANO DE OBRA DE (DIAGNOSTICO DE FALLA DE VELOCIMETRO Y CAMBIO DE BALEROS DELANTEROS ,DOBLE,)',
      UnitCost: 270.68,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10211511',
      IdentificationNumber: 'ISJB-6449',
      InvoiceCatClaveUnidad: 'N93',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 28
    },
    {
      id: 324,
      invoiceSenderId: 1,
      Description: 'SURIMI MARINTES 1.000 KG',
      UnitCost: 865.91,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12164102',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E61',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 325,
      invoiceSenderId: 1,
      Description: 'CALAMAR AMERICANO',
      UnitCost: 283.74,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302120',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D60',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 326,
      invoiceSenderId: 1,
      Description: 'Numero fijo llamada entrante ilimitada (MPP)',
      UnitCost: 325.46,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217403',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'LEF',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 327,
      invoiceSenderId: 1,
      Description: 'ATT Consíguelo 5',
      UnitCost: 323.8,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302878',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'EQ',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 13, RetIsr: 27
    },
    {
      id: 328,
      invoiceSenderId: 1,
      Description: 'HIG.TWIN S/CENTRO 36/800 H.D.',
      UnitCost: 359.18,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402432',
      IdentificationNumber: 'YVDQ-6609',
      InvoiceCatClaveUnidad: 'QB',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 12
    },
    {
      id: 329,
      invoiceSenderId: 1,
      Description: 'Mattel Fast & Furious Elite Diecast Vehicles, 8-Pack',
      UnitCost: 954.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417105',
      IdentificationNumber: 'EMHD-5489',
      InvoiceCatClaveUnidad: 'MVA',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 330,
      invoiceSenderId: 1,
      Description: 'ENVIO DE PAQUETES A DOMICILIO',
      UnitCost: 543.85,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10241603',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H05',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 15
    },
    {
      id: 331,
      invoiceSenderId: 1,
      Description: 'G SUPER (Despacho 10864834-0)',
      UnitCost: 73.86,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10362105',
      IdentificationNumber: 'EUHA-1934',
      InvoiceCatClaveUnidad: 'TAN',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 332,
      invoiceSenderId: 1,
      Description: 'INTERES EXENTO',
      UnitCost: 395.37,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202761',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G91',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 333,
      invoiceSenderId: 1,
      Description: 'INTERES GRAVABLE',
      UnitCost: 322.36,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10252109',
      IdentificationNumber: 'JPRL-7171',
      InvoiceCatClaveUnidad: 'XWR',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 334,
      invoiceSenderId: 1,
      Description: 'Interés Devengado por el préstamo 39975',
      UnitCost: 673.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317925',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q18',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 335,
      invoiceSenderId: 1,
      Description: 'COMISION POR PAGO TARDIO',
      UnitCost: 25.7,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10212218',
      IdentificationNumber: 'BLFR-5556',
      InvoiceCatClaveUnidad: 'NA',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 336,
      invoiceSenderId: 1,
      Description: 'INTERES GRAVABLE COMPRA CUOTAS',
      UnitCost: 505.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302358',
      IdentificationNumber: 'QGKP-6231',
      InvoiceCatClaveUnidad: 'N38',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 33
    },
    {
      id: 337,
      invoiceSenderId: 1,
      Description: 'INTERES EXENTO COMPRA CUOTAS',
      UnitCost: 326.87,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312009',
      IdentificationNumber: 'VDNE-7652',
      InvoiceCatClaveUnidad: 'XPL',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 338,
      invoiceSenderId: 1,
      Description: 'INTERES GRAVABLE L EXPRESS',
      UnitCost: 368.91,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402319',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ND',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 339,
      invoiceSenderId: 1,
      Description: 'INTERES EXENTO LINEA EXPRESS',
      UnitCost: 497.22,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215411',
      IdentificationNumber: 'JLWZ-8792',
      InvoiceCatClaveUnidad: 'C55',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 340,
      invoiceSenderId: 1,
      Description: 'CONTRATO 1142395 MENSUALIDAD 57',
      UnitCost: 477.28,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10418318',
      IdentificationNumber: 'AEYJ-5444',
      InvoiceCatClaveUnidad: 'K47',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 30
    },
    {
      id: 341,
      invoiceSenderId: 1,
      Description: 'PIEZA TOALLA LIMPIA CLEAN MICROF AMARILLA',
      UnitCost: 653.58,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402435',
      IdentificationNumber: 'DQEU-9220',
      InvoiceCatClaveUnidad: 'E85',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 342,
      invoiceSenderId: 1,
      Description: 'GUANTE HULE ROJO ADEX 6 1/2 - 7',
      UnitCost: 421.37,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12141718',
      IdentificationNumber: 'TKHS-0438',
      InvoiceCatClaveUnidad: 'D12',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 343,
      invoiceSenderId: 1,
      Description: 'GUANTE HULE ROJO ADEX 8 1/2 - 9',
      UnitCost: 95.1,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '21102203',
      IdentificationNumber: 'YGNH-0221',
      InvoiceCatClaveUnidad: 'B55',
      Ieps: 0.060,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 1
    },
    {
      id: 344,
      invoiceSenderId: 1,
      Description: 'GUANTE HULE ROJO ADEX 7 1/2 - 8',
      UnitCost: 73.04,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10213602',
      IdentificationNumber: 'NLTJ-9357',
      InvoiceCatClaveUnidad: 'XPO',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 345,
      invoiceSenderId: 1,
      Description: 'DETERGENTE BIODEGRADABLE ROMA DE 20/500g',
      UnitCost: 280.6,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10431509',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M24',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 346,
      invoiceSenderId: 1,
      Description: 'TOALLA ROLLO 8910 EXELENCE 6/210m c/u ELITE',
      UnitCost: 600.34,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10414605',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H70',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 347,
      invoiceSenderId: 1,
      Description: 'HARPIC PASTILLA HIGIENICO MARINA 24/26gr',
      UnitCost: 149.82,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10502923',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N80',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 24
    },
    {
      id: 348,
      invoiceSenderId: 1,
      Description: 'Birtman BT-45 - Ventilador de Torre con Ionizador y Control Remoto, Negro, 45"',
      UnitCost: 286.3,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10215479',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MPA',
      Ieps: null,
      RetIeps: null,
      RetVat: 5, RetIsr: 28
    },
    {
      id: 349,
      invoiceSenderId: 1,
      Description: 'SERVICIO PRIVADO DE TRANSPORTE CON CHOFER \N FECHA VIAJE: 2020-03-27 15:42 \N DISTANCIA: 6.88 KM \N TIEMPO: 00:15 (HORAS:MINUTOS)',
      UnitCost: 959.17,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10341600',
      IdentificationNumber: 'LEGG-4409',
      InvoiceCatClaveUnidad: 'G58',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 23
    },
    {
      id: 350,
      invoiceSenderId: 1,
      Description: 'CUOTA DE SOLICITUD',
      UnitCost: 651.6,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10415416',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E14',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 351,
      invoiceSenderId: 1,
      Description: 'OTROS CARGOS2 TC',
      UnitCost: 266.1,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202402',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'PW',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 352,
      invoiceSenderId: 1,
      Description: 'TOALLA ROLLO ELITE BCA HS 6/180m 5971',
      UnitCost: 311.84,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202365',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'SQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 4
    },
    {
      id: 353,
      invoiceSenderId: 1,
      Description: 'REFRESCO EN LATA 24/235 ML COCA COLA SIN AZUCAR',
      UnitCost: 768.82,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10414600',
      IdentificationNumber: 'UAAE-1489',
      InvoiceCatClaveUnidad: 'J25',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 354,
      invoiceSenderId: 1,
      Description: 'STRUDEL DE MANZANA 8 PIEZAS KIRKLAND SIGNATURE',
      UnitCost: 517.51,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202407',
      IdentificationNumber: 'IWEY-4784',
      InvoiceCatClaveUnidad: 'KA',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 355,
      invoiceSenderId: 1,
      Description: 'ACEITE DE CANOLA 2/227 G PAM ORIGINAL',
      UnitCost: 206.96,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10314305',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N13',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 356,
      invoiceSenderId: 1,
      Description: 'PIÑA ENCHILADA 1 KG BALMORO',
      UnitCost: 988.9,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417901',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 357,
      invoiceSenderId: 1,
      Description: 'PLATANO EN TIRAS 350 G TUMBIS',
      UnitCost: 960.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10502104',
      IdentificationNumber: 'DQLJ-1376',
      InvoiceCatClaveUnidad: 'X4D',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 358,
      invoiceSenderId: 1,
      Description: 'QUESO MANCHEGO REBANADO 1KG PRESIDENT',
      UnitCost: 913.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10221800',
      IdentificationNumber: 'GZTM-4440',
      InvoiceCatClaveUnidad: 'YT',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 7
    },
    {
      id: 359,
      invoiceSenderId: 1,
      Description: 'AZUCAR MASCABADO 2 KG ZULKA',
      UnitCost: 226.71,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12164400',
      IdentificationNumber: 'EJVX-9545',
      InvoiceCatClaveUnidad: 'G66',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 360,
      invoiceSenderId: 1,
      Description: 'ENSALADA ESPINACA Y QUESO CABRA 630 GR KIRKLAND SIGNATURE',
      UnitCost: 226.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202122',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KVR',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 10, RetIsr: 16
    },
    {
      id: 361,
      invoiceSenderId: 1,
      Description: 'COFFEE, CAFÉ 100% ORGANICO 1 KG SAN FRANCISCO BAY',
      UnitCost: 460.59,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10213001',
      IdentificationNumber: 'HVNY-9330',
      InvoiceCatClaveUnidad: 'D14',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 362,
      invoiceSenderId: 1,
      Description: 'BLUEBERRY ORGANICO R',
      UnitCost: 819.44,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10231608',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M21',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 363,
      invoiceSenderId: 1,
      Description: 'CROISSANT MANTEQUILLA 12 PIEZAS / CONT.900GR KIRKLAND SIGNATURE',
      UnitCost: 297.13,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13101706',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K93',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 18
    },
    {
      id: 364,
      invoiceSenderId: 1,
      Description: 'SALCHICHAS AHUMADAS AJO ROSTIZADO 952G KIOLBASSA',
      UnitCost: 111.99,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10301946',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L14',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 15
    },
    {
      id: 365,
      invoiceSenderId: 1,
      Description: 'JAMON MASTER CARVE POR KILO KIRKLAND SIGNATURE',
      UnitCost: 895.2,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12171608',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XBX',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 4, RetIsr: 16
    },
    {
      id: 366,
      invoiceSenderId: 1,
      Description: 'QUICHE 2PZAS/650G LA TERRA FINA',
      UnitCost: 963.89,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10252040',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N71',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 367,
      invoiceSenderId: 1,
      Description: 'PLATANO ORGANICO 1.3 KG',
      UnitCost: 479.7,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10214000',
      IdentificationNumber: 'BOIB-8674',
      InvoiceCatClaveUnidad: 'XWV',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 368,
      invoiceSenderId: 1,
      Description: 'BOTANA DE GARBANZO 453G ORCHARD VALLEY',
      UnitCost: 962.44,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10212009',
      IdentificationNumber: 'QYLQ-2195',
      InvoiceCatClaveUnidad: 'XEI',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 13, RetIsr: 33
    },
    {
      id: 369,
      invoiceSenderId: 1,
      Description: 'SILLA PLEGABLE ACOJINADA MAXCHIEF',
      UnitCost: 156.46,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10452005',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J17',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 370,
      invoiceSenderId: 1,
      Description: 'ORGÁNICO DATILES DESHUESADOS 907G DESERT VALLEY DATES',
      UnitCost: 315.81,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10201509',
      IdentificationNumber: 'UQBA-0100',
      InvoiceCatClaveUnidad: 'AY',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 371,
      invoiceSenderId: 1,
      Description: 'BOTANA SURTIDA 1.36 KG KIRKLAND SIGNATURE',
      UnitCost: 413.71,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20111702',
      IdentificationNumber: 'OMQX-5488',
      InvoiceCatClaveUnidad: 'QTL',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 372,
      invoiceSenderId: 1,
      Description: 'MEZCLA FRAMBUESA, MORA Y ZARZAMORA, 1,81 KG. KIRKLAND SIGNATURE',
      UnitCost: 765.54,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302810',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '80',
      Ieps: 0.070,
      RetIeps: 0.060,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 373,
      invoiceSenderId: 1,
      Description: 'Pago Anualidad Visoor 2020',
      UnitCost: 611.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10415502',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H37',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 374,
      invoiceSenderId: 1,
      Description: 'CONVERTIDOR AUDIO/VIDEO HDMI A VGA P/PC',
      UnitCost: 61.86,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10401608',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KTN',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 375,
      invoiceSenderId: 1,
      Description: 'POLARIZADO SEGURIDAD',
      UnitCost: 246.91,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332019',
      IdentificationNumber: 'HANI-2374',
      InvoiceCatClaveUnidad: 'PT',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 376,
      invoiceSenderId: 1,
      Description: 'Piña Esmeralda',
      UnitCost: 180.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332063',
      IdentificationNumber: 'WUNI-3426',
      InvoiceCatClaveUnidad: 'JK',
      Ieps: 0.000,
      RetIeps: 0.090,
      RetVat: 9, RetIsr: 12
    },
    {
      id: 377,
      invoiceSenderId: 1,
      Description: 'CONTROL DE APERTURAS Y CIERRES',
      UnitCost: 912.44,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20142709',
      IdentificationNumber: 'MKSQ-1878',
      InvoiceCatClaveUnidad: '92',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 378,
      invoiceSenderId: 1,
      Description: 'POLARIZADO SEGURIDAD TIPO ORIGINAL',
      UnitCost: 388.72,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10418008',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F99',
      Ieps: null,
      RetIeps: null,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 379,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SACOS DE SAL EN GRANO',
      UnitCost: 506.65,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12162302',
      IdentificationNumber: 'UFHW-0582',
      InvoiceCatClaveUnidad: '59',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 18
    },
    {
      id: 380,
      invoiceSenderId: 1,
      Description: 'Piña miel',
      UnitCost: 913.98,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302334',
      IdentificationNumber: 'XNQQ-2656',
      InvoiceCatClaveUnidad: '2L',
      Ieps: null,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 381,
      invoiceSenderId: 1,
      Description: 'SESION FOTOGRAFICA WAVE II',
      UnitCost: 819.63,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231913',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K47',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 382,
      invoiceSenderId: 1,
      Description: 'Intereses garantía extendida',
      UnitCost: 959.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10501806',
      IdentificationNumber: 'BLBH-4852',
      InvoiceCatClaveUnidad: 'B17',
      Ieps: 0.070,
      RetIeps: 0.060,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 383,
      invoiceSenderId: 1,
      Description: 'Intereses fin. seguro auto',
      UnitCost: 365.39,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10424703',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J32',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 384,
      invoiceSenderId: 1,
      Description: 'Intereses de financiamiento',
      UnitCost: 928.98,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10323201',
      IdentificationNumber: 'GWCA-5144',
      InvoiceCatClaveUnidad: '2J',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 385,
      invoiceSenderId: 1,
      Description: 'SERVICIOS ADICIONALES',
      UnitCost: 520.93,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302160',
      IdentificationNumber: 'NHWX-7745',
      InvoiceCatClaveUnidad: 'M84',
      Ieps: null,
      RetIeps: null,
      RetVat: 7, RetIsr: 20
    },
    {
      id: 386,
      invoiceSenderId: 1,
      Description: 'SO-DIMM 4GB 1600MHZ KVR16LS11/4',
      UnitCost: 637.63,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10224901',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M9',
      Ieps: 0.030,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 387,
      invoiceSenderId: 1,
      Description: 'SSD ADATA 120GB ASU650SS-120GT-R',
      UnitCost: 670.17,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217605',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F14',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 388,
      invoiceSenderId: 1,
      Description: 'GAB EXTERNO SSD-HDD ADATA AED600U31-CBK',
      UnitCost: 206.1,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10217407',
      IdentificationNumber: 'IZGJ-6716',
      InvoiceCatClaveUnidad: 'F73',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 389,
      invoiceSenderId: 1,
      Description: 'OFFICE 365 BUSINESS PILOT SPP-00005',
      UnitCost: 315.67,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10412622',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M53',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 390,
      invoiceSenderId: 1,
      Description: 'INTERESES NO SUJETOS A IVA',
      UnitCost: 268.17,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417351',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'LUM',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 22
    },
    {
      id: 391,
      invoiceSenderId: 1,
      Description: 'INTERESES SUJETOS A IVA',
      UnitCost: 655.37,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10311902',
      IdentificationNumber: 'FJWH-8546',
      InvoiceCatClaveUnidad: 'P87',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 20
    },
    {
      id: 392,
      invoiceSenderId: 1,
      Description: 'Cocina Fabricada en Madera',
      UnitCost: 230.35,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217965',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ANN',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 393,
      invoiceSenderId: 1,
      Description: 'Mesa De Trabajo Area de Lavanderia',
      UnitCost: 523.1,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416102',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XHB',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 394,
      invoiceSenderId: 1,
      Description: 'Escitorio de Madera Forma U',
      UnitCost: 780.7,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332065',
      IdentificationNumber: 'OCNT-4175',
      InvoiceCatClaveUnidad: 'L73',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 31
    },
    {
      id: 395,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Gabinete de 2 Secciones',
      UnitCost: 893.58,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20101601',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M33',
      Ieps: null,
      RetIeps: 1.600,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 396,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Gabinetes Para Interiores',
      UnitCost: 217.72,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10232128',
      IdentificationNumber: 'SXCO-5480',
      InvoiceCatClaveUnidad: 'G90',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 397,
      invoiceSenderId: 1,
      Description: 'Mesa de Madera Gabinete',
      UnitCost: 964.83,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217968',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C41',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 398,
      invoiceSenderId: 1,
      Description: 'Gabinete de Madera Para Computadora',
      UnitCost: 302.06,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10361604',
      IdentificationNumber: 'VOYO-5976',
      InvoiceCatClaveUnidad: 'J97',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 399,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Gabinete',
      UnitCost: 900.86,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10216401',
      IdentificationNumber: 'GKUS-7517',
      InvoiceCatClaveUnidad: 'M71',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 400,
      invoiceSenderId: 1,
      Description: 'Escritorio de Madera',
      UnitCost: 35.98,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217939',
      IdentificationNumber: 'LKZQ-9969',
      InvoiceCatClaveUnidad: 'L44',
      Ieps: null,
      RetIeps: null,
      RetVat: 11, RetIsr: 9
    },
    {
      id: 401,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Seccion Estacion Trasera',
      UnitCost: 974.15,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10415604',
      IdentificationNumber: 'OFCV-1196',
      InvoiceCatClaveUnidad: 'H99',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 3, RetIsr: 27
    },
    {
      id: 402,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Seccion Estacion de Café',
      UnitCost: 33.01,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10231910',
      IdentificationNumber: 'MPAW-9929',
      InvoiceCatClaveUnidad: 'A21',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 403,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Seccion Barras',
      UnitCost: 117.15,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217947',
      IdentificationNumber: 'AOKH-7741',
      InvoiceCatClaveUnidad: 'XQR',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 7, RetIsr: 2
    },
    {
      id: 404,
      invoiceSenderId: 1,
      Description: 'Muebles de Madera Celosia',
      UnitCost: 307.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '15131503',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'SN',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 15, RetIsr: 5
    },
    {
      id: 405,
      invoiceSenderId: 1,
      Description: 'Capacitación',
      UnitCost: 770.61,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10224801',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F20',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 406,
      invoiceSenderId: 1,
      Description: 'LITROS GAS L.P.',
      UnitCost: 649.99,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10214709',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TMS',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null, RetIsr: 17
    },
    {
      id: 407,
      invoiceSenderId: 1,
      Description: 'AGUSTIN RANALLI',
      UnitCost: 173.91,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10211727',
      IdentificationNumber: 'UEEV-4655',
      InvoiceCatClaveUnidad: 'F24',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 408,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO Y COLOCACIÓN DE TECLADO DSC Y TRANSCEPTOR DEBIDO A UNA FALLA EN VOLTAJE EN LAB. OLAB DEL VALLE',
      UnitCost: 585.08,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10231713',
      IdentificationNumber: 'VEXX-9981',
      InvoiceCatClaveUnidad: 'Z5',
      Ieps: null,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 409,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO E INSTALACIÓN DE 2 CÁMARAS BULLET 1080HD Y TRANSCEPTORES EN LAB. JENNER VIADUCTO',
      UnitCost: 440.44,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10452003',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B23',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 410,
      invoiceSenderId: 1,
      Description: 'maria del carmen',
      UnitCost: 908.59,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317200',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B14',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 411,
      invoiceSenderId: 1,
      Description: 'RECARGA AL CHIP GSM/GPRS, CORRESPONDIENTE A LOS MESES DE MARZO Y ABRI DE 2020',
      UnitCost: 496.27,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202015',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E65',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 22
    },
    {
      id: 412,
      invoiceSenderId: 1,
      Description: 'RENTA DEL MES DE ABRIL DE 2020. DEL DEPTO. NO.1 DEL INMUEBLE UBICADO EN PETEN NO. 1038, COL. LETRAN VALLE, CIUDAD DE MEXICO. / CUENTA PREDIAL: 025352340001.',
      UnitCost: 79.55,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10315807',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'AB',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 413,
      invoiceSenderId: 1,
      Description: 'TABLERO DE CONTROL ELECTRICO 5 HP, INCLUYE CONEXION A MOTOR',
      UnitCost: 140.27,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10313509',
      IdentificationNumber: 'BLTH-0899',
      InvoiceCatClaveUnidad: 'M14',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 414,
      invoiceSenderId: 1,
      Description: 'DUCTERIA DE ACERO GALVANIZADO SEGUN DOCUMENTO OV PPG SJR (EXCEPTUANDO EL CALIBRE)',
      UnitCost: 478.98,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10311615',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C68',
      Ieps: 0.060,
      RetIeps: 0.060,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 415,
      invoiceSenderId: 1,
      Description: 'HOJA TAMAÑO CARTA C/SUAJE A 1/2 CARTA',
      UnitCost: 435.51,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20101804',
      IdentificationNumber: 'NMGT-8479',
      InvoiceCatClaveUnidad: 'XAL',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 416,
      invoiceSenderId: 1,
      Description: 'CONFIRMEMO',
      UnitCost: 451.94,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301954',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'DAD',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 5
    },
    {
      id: 417,
      invoiceSenderId: 1,
      Description: 'CINTILLO DE IDENTIFICACION',
      UnitCost: 758.39,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12162304',
      IdentificationNumber: 'RESV-6989',
      InvoiceCatClaveUnidad: 'A96',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 9, RetIsr: 35
    },
    {
      id: 418,
      invoiceSenderId: 1,
      Description: 'ASISTENCIA POR FALLA DE 3 CÁMARAS Y CÁMARA ANÁLITICA EN LAB. JENNER MARINA NACIONAL',
      UnitCost: 464.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12162003',
      IdentificationNumber: 'YRYS-1151',
      InvoiceCatClaveUnidad: 'NI',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 419,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE SORTEO DE MATERIAL EN PARKER, DEL 20 AL 21 DE FEBRERO 2020 SOPORTE: CLAUDIA CAMPOS (CHECK VALVE)',
      UnitCost: 289.63,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10412634',
      IdentificationNumber: 'WNNA-6916',
      InvoiceCatClaveUnidad: 'E71',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 420,
      invoiceSenderId: 1,
      Description: 'FORMATO DE INCIDENCIAS',
      UnitCost: 59.11,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10231902',
      IdentificationNumber: 'GING-8285',
      InvoiceCatClaveUnidad: 'N42',
      Ieps: 0.030,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 421,
      invoiceSenderId: 1,
      Description: 'SERVICIOS CONTABLES DEL MES DE MARZO DEL 2020',
      UnitCost: 832.02,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12162212',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C82',
      Ieps: null,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 422,
      invoiceSenderId: 1,
      Description: 'COBRO DE COMISION',
      UnitCost: 35.0,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10226016',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'SR',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 33
    },
    {
      id: 423,
      invoiceSenderId: 1,
      Description: 'APOYO A NUEVOS PROYECTOS DEL MES DE JUNIO 2019',
      UnitCost: 913.33,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10315103',
      IdentificationNumber: 'EZDJ-8320',
      InvoiceCatClaveUnidad: 'F82',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 10
    },
    {
      id: 424,
      invoiceSenderId: 1,
      Description: 'APOYO A NUEVOS PROYECTOS DEL MES DE MAYO 2019',
      UnitCost: 410.63,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215611',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M63',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 425,
      invoiceSenderId: 1,
      Description: 'Cargo por Transporte Express',
      UnitCost: 281.78,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217402',
      IdentificationNumber: 'OZNN-7013',
      InvoiceCatClaveUnidad: 'M77',
      Ieps: 0.090,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 426,
      invoiceSenderId: 1,
      Description: 'Transporte',
      UnitCost: 512.7,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10151518',
      IdentificationNumber: 'XLJR-8259',
      InvoiceCatClaveUnidad: 'P1',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 427,
      invoiceSenderId: 1,
      Description: 'HIGO',
      UnitCost: 567.94,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10331611',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G51',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 428,
      invoiceSenderId: 1,
      Description: 'APOYO A NUEVOS PROYECTOS DEL MES DE MARZO 2019',
      UnitCost: 53.38,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302135',
      IdentificationNumber: 'QBEU-8439',
      InvoiceCatClaveUnidad: 'XCA',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 429,
      invoiceSenderId: 1,
      Description: 'ARMAZON SP',
      UnitCost: 282.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301500',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E37',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 1
    },
    {
      id: 430,
      invoiceSenderId: 1,
      Description: 'FELPA MICROFIBRA',
      UnitCost: 180.15,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10231908',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XHC',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 431,
      invoiceSenderId: 1,
      Description: 'VINIMEX TOTAL  REGALON',
      UnitCost: 606.43,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302810',
      IdentificationNumber: 'WFKA-2489',
      InvoiceCatClaveUnidad: 'MLD',
      Ieps: null,
      RetIeps: null,
      RetVat: 10, RetIsr: 34
    },
    {
      id: 432,
      invoiceSenderId: 1,
      Description: 'VINIMEX TOTAL',
      UnitCost: 857.42,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10415456',
      IdentificationNumber: 'VWZC-4695',
      InvoiceCatClaveUnidad: 'P21',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 10
    },
    {
      id: 433,
      invoiceSenderId: 1,
      Description: 'GAS LICUADO DE PETROLEO',
      UnitCost: 761.04,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317108',
      IdentificationNumber: 'HUQC-2580',
      InvoiceCatClaveUnidad: 'M72',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 434,
      invoiceSenderId: 1,
      Description: 'Envio Nal Frontera',
      UnitCost: 316.43,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10502913',
      IdentificationNumber: 'APSZ-6511',
      InvoiceCatClaveUnidad: 'L70',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 435,
      invoiceSenderId: 1,
      Description: 'JUAN CARLOS MACIAS',
      UnitCost: 570.41,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10171600',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 25
    },
    {
      id: 436,
      invoiceSenderId: 1,
      Description: 'MANTENIMIENTO DE PERSIANAS',
      UnitCost: 837.38,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317500',
      IdentificationNumber: 'CPEG-9219',
      InvoiceCatClaveUnidad: 'APZ',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 437,
      invoiceSenderId: 1,
      Description: 'INTERMEDIACIÓN EN SERVICIOS TURÍSTICOS',
      UnitCost: 148.37,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402122',
      IdentificationNumber: 'GTRV-6381',
      InvoiceCatClaveUnidad: 'B85',
      Ieps: null,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 438,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE INTERMEDIACIÓN POR PRODUCTOS TURÍSTICOS A CUENTA Y ORDEN DE RFC-AME880912I89',
      UnitCost: 409.72,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10401915',
      IdentificationNumber: 'VBPS-1816',
      InvoiceCatClaveUnidad: 'N16',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 13, RetIsr: 20
    },
    {
      id: 439,
      invoiceSenderId: 1,
      Description: 'ASISTENCIA POR FALLA DE SENSOR EN LAB. JENNER CULHUACAN',
      UnitCost: 266.06,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10432065',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G7',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 16, RetIsr: 7
    },
    {
      id: 440,
      invoiceSenderId: 1,
      Description: 'CARGOS AEROPORTUARIOS',
      UnitCost: 291.46,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10318328',
      IdentificationNumber: 'ZPQL-1743',
      InvoiceCatClaveUnidad: 'E71',
      Ieps: null,
      RetIeps: null,
      RetVat: 4, RetIsr: 18
    },
    {
      id: 441,
      invoiceSenderId: 1,
      Description: 'TARIFA AEREA PNR: NYV82Q',
      UnitCost: 351.37,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10415304',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'BB',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 7, RetIsr: 23
    },
    {
      id: 442,
      invoiceSenderId: 1,
      Description: 'YR',
      UnitCost: 812.53,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202761',
      IdentificationNumber: 'AYNX-4314',
      InvoiceCatClaveUnidad: 'N14',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 443,
      invoiceSenderId: 1,
      Description: 'TUA',
      UnitCost: 977.12,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10312401',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E91',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 444,
      invoiceSenderId: 1,
      Description: 'TRANSPORTACION AEREA',
      UnitCost: 34.58,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402885',
      IdentificationNumber: 'OTOA-2408',
      InvoiceCatClaveUnidad: 'XVL',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 445,
      invoiceSenderId: 1,
      Description: 'Hyatt Regency Dulles (DBL QN) 18-MAR-20 21-MAR-20',
      UnitCost: 202.72,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10224601',
      IdentificationNumber: 'YSMV-9415',
      InvoiceCatClaveUnidad: 'J61',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 446,
      invoiceSenderId: 1,
      Description: 'Premium',
      UnitCost: 567.02,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10401744',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XZU',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 447,
      invoiceSenderId: 1,
      Description: 'SERVICIO PRIVADO DE TRANSPORTE CON CHOFER \N FECHA VIAJE: 2020-03-12 10:24 \N DISTANCIA: 0.0 KM \N TIEMPO: 00:00 (HORAS:MINUTOS)',
      UnitCost: 437.98,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10214609',
      IdentificationNumber: 'NRAZ-5797',
      InvoiceCatClaveUnidad: 'J19',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null, RetIsr: 35
    },
    {
      id: 448,
      invoiceSenderId: 1,
      Description: 'Holiday Inn Express Mexico Aeropuerto (DBL KG-NM) 22-MAR-20 23-MAR-20',
      UnitCost: 471.35,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101611',
      IdentificationNumber: 'FBVI-4400',
      InvoiceCatClaveUnidad: 'XID',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 449,
      invoiceSenderId: 1,
      Description: 'HORMONA ESTIMULANTE DE TIROIDES TSH',
      UnitCost: 537.05,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302206',
      IdentificationNumber: 'ODYD-0403',
      InvoiceCatClaveUnidad: 'K36',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 450,
      invoiceSenderId: 1,
      Description: 'CHECK UP HORMONAL FEMENINO 36',
      UnitCost: 765.72,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402608',
      IdentificationNumber: 'YRBM-0553',
      InvoiceCatClaveUnidad: 'XJC',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 9
    },
    {
      id: 451,
      invoiceSenderId: 1,
      Description: 'HONORARIOS PROFESIONALES DEL MES DE FEBRERO DE 2020, POR SERVICIOS DE CONTABILIDAD.',
      UnitCost: 967.43,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10217915',
      IdentificationNumber: 'GSAT-9749',
      InvoiceCatClaveUnidad: 'N2',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 452,
      invoiceSenderId: 1,
      Description: 'TARIFA USO DE AEROPUERTO (TUA)',
      UnitCost: 492.95,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10502117',
      IdentificationNumber: 'YKQH-1369',
      InvoiceCatClaveUnidad: 'ASU',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 453,
      invoiceSenderId: 1,
      Description: 'Revision Equipaje (YR)',
      UnitCost: 425.23,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122819',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J57',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 454,
      invoiceSenderId: 1,
      Description: 'TARIFA DEL BOLETO 9423327379963 DEL PASAJERO PACHECO SANTIAGO/RAUL FERNAND COMPRADO EL 03/03/2020',
      UnitCost: 202.54,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '14121902',
      IdentificationNumber: 'VZYN-1684',
      InvoiceCatClaveUnidad: 'K60',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 455,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE INTERMEDIACIÓN POR PRODUCTOS TURÍSTICOS A CUENTA Y ORDEN DE RFC-ANA050518RL1',
      UnitCost: 655.37,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10426032',
      IdentificationNumber: 'XDOZ-6480',
      InvoiceCatClaveUnidad: 'N75',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 456,
      invoiceSenderId: 1,
      Description: 'TARIFA DEL BOLETO 9423328901757 DEL PASAJERO LASTRA ZALETA/DIDIER COMPRADO EL 02/03/2020',
      UnitCost: 802.81,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12141725',
      IdentificationNumber: 'JHVN-2448',
      InvoiceCatClaveUnidad: 'MIN',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 14
    },
    {
      id: 457,
      invoiceSenderId: 1,
      Description: 'TARIFA AEREA PNR: H3ZY5Y',
      UnitCost: 209.39,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10301717',
      IdentificationNumber: 'SUWM-4018',
      InvoiceCatClaveUnidad: 'G18',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 458,
      invoiceSenderId: 1,
      Description: 'TARIFA AEREA PNR: A2LZFL',
      UnitCost: 389.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302342',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCE',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 459,
      invoiceSenderId: 1,
      Description: 'TARIFA AEREA PNR: U81C5M',
      UnitCost: 630.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10413808',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M90',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 460,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE INTERMEDIACIÓN POR PRODUCTOS TURÍSTICOS A CUENTA Y ORDEN DE RFC-TAE870129F98',
      UnitCost: 225.63,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10342001',
      IdentificationNumber: 'JADR-3475',
      InvoiceCatClaveUnidad: 'G33',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 461,
      invoiceSenderId: 1,
      Description: 'TARIFA AEREA PNR: V4WLNY',
      UnitCost: 630.64,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10314608',
      IdentificationNumber: 'HNOR-4119',
      InvoiceCatClaveUnidad: 'L83',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 462,
      invoiceSenderId: 1,
      Description: 'Servicios de Facturaci?n',
      UnitCost: 389.31,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10425902',
      IdentificationNumber: 'EACF-7069',
      InvoiceCatClaveUnidad: 'HEA',
      Ieps: 0.250,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 463,
      invoiceSenderId: 1,
      Description: 'TOTAL COMISIONES',
      UnitCost: 795.06,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101516',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'FOT',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 464,
      invoiceSenderId: 1,
      Description: 'HOJA DE CORTE Y ARQUEO DE CAJA',
      UnitCost: 213.33,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317416',
      IdentificationNumber: 'ILAH-9151',
      InvoiceCatClaveUnidad: 'H71',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 465,
      invoiceSenderId: 1,
      Description: 'FLETE',
      UnitCost: 260.47,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10232107',
      IdentificationNumber: 'TRNJ-5796',
      InvoiceCatClaveUnidad: 'K69',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 28
    },
    {
      id: 466,
      invoiceSenderId: 1,
      Description: 'LIBROS PROTOCOLOS BLANCOS',
      UnitCost: 907.33,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202744',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P30',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 467,
      invoiceSenderId: 1,
      Description: 'Derechos, Productos y Aprovechamientos, Número de operación: 3588 fecha - hora de presentación: 2019-01-24 17:15 Clave de referencia del Pago: 357001857',
      UnitCost: 703.26,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10441509',
      IdentificationNumber: 'KHUR-4046',
      InvoiceCatClaveUnidad: 'GZ',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 468,
      invoiceSenderId: 1,
      Description: 'DAMPOL 9908 - GEL ANTIBACTERIAL PARA MANOS ENVASE DE 1 LITRO',
      UnitCost: 820.62,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402167',
      IdentificationNumber: 'AZUL-5906',
      InvoiceCatClaveUnidad: 'C10',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 469,
      invoiceSenderId: 1,
      Description: 'Servicios Especiales Limpieza profunda de casa.',
      UnitCost: 575.06,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302703',
      IdentificationNumber: 'DDYN-3788',
      InvoiceCatClaveUnidad: 'F82',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 470,
      invoiceSenderId: 1,
      Description: 'Servicio de Limpieza Mensual a lámparas en área de recepción.',
      UnitCost: 156.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10212503',
      IdentificationNumber: 'AVXJ-6247',
      InvoiceCatClaveUnidad: 'A83',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 471,
      invoiceSenderId: 1,
      Description: 'Servicio de Limpieza Normal con Frecuencia semanal.',
      UnitCost: 112.49,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11101623',
      IdentificationNumber: 'XMTK-6945',
      InvoiceCatClaveUnidad: 'J33',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 31
    },
    {
      id: 472,
      invoiceSenderId: 1,
      Description: 'SERVICIO PRIVADO DE TRANSPORTE CON CHOFER \N FECHA VIAJE: 2020-03-06 11:21 \N DISTANCIA: 14.66 KM \N TIEMPO: 00:34 (HORAS:MINUTOS)',
      UnitCost: 483.66,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302378',
      IdentificationNumber: 'RUZH-6191',
      InvoiceCatClaveUnidad: 'A67',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 473,
      invoiceSenderId: 1,
      Description: 'AJUSTE POR PRECIO',
      UnitCost: 285.76,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10318343',
      IdentificationNumber: 'EHFY-9812',
      InvoiceCatClaveUnidad: 'E72',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 19
    },
    {
      id: 474,
      invoiceSenderId: 1,
      Description: 'ENVIO DE PAQUETE A DOMICILIO',
      UnitCost: 467.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202182',
      IdentificationNumber: 'JMJB-2296',
      InvoiceCatClaveUnidad: 'J55',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 475,
      invoiceSenderId: 1,
      Description: 'PARTE DE NOVEDADES',
      UnitCost: 833.8,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301809',
      IdentificationNumber: 'KEPY-3725',
      InvoiceCatClaveUnidad: 'B15',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 24
    },
    {
      id: 476,
      invoiceSenderId: 1,
      Description: 'RECARGA AL CHIP GSM/GPRS, CORRESPONDIENTE A LOS MESES DE MARZO Y ABRIL DE 2020',
      UnitCost: 718.29,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10201716',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N71',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 477,
      invoiceSenderId: 1,
      Description: 'SELLOS DE 1- 1/4',
      UnitCost: 887.11,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302155',
      IdentificationNumber: 'GNHE-5361',
      InvoiceCatClaveUnidad: 'B90',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 478,
      invoiceSenderId: 1,
      Description: 'MANO DE OBRA',
      UnitCost: 637.22,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121517',
      IdentificationNumber: 'HYSM-3691',
      InvoiceCatClaveUnidad: 'F71',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 479,
      invoiceSenderId: 1,
      Description: 'SELLOS MONEDA # 06 Y # 08',
      UnitCost: 446.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10122003',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q16',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 480,
      invoiceSenderId: 1,
      Description: 'CARGA DE GAS REFRIGERANTE DE A/C',
      UnitCost: 287.41,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121420',
      IdentificationNumber: 'SAJP-6248',
      InvoiceCatClaveUnidad: 'MAR',
      Ieps: null,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 481,
      invoiceSenderId: 1,
      Description: 'REPARACION DE A/C A UNIDAD 114 M2, PLACAS 717 AK 3. CARGA DE ACEITE A COMPRESOR',
      UnitCost: 317.8,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10214912',
      IdentificationNumber: 'LNGO-4977',
      InvoiceCatClaveUnidad: 'N69',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 482,
      invoiceSenderId: 1,
      Description: 'FORMATO PERMISO DE SALIDA',
      UnitCost: 670.97,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402468',
      IdentificationNumber: 'AQUC-2314',
      InvoiceCatClaveUnidad: 'K35',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 483,
      invoiceSenderId: 1,
      Description: 'SOLICITUD DE REGISTRO NUEVOS SOCIOS TIENDA',
      UnitCost: 695.97,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13111065',
      IdentificationNumber: 'UHVF-0404',
      InvoiceCatClaveUnidad: 'C94',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 484,
      invoiceSenderId: 1,
      Description: 'SOLICITUD DE REGISTRO NUEVOS SOCIOS AF EXT',
      UnitCost: 648.19,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202740',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'X1D',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 485,
      invoiceSenderId: 1,
      Description: 'RECARGA AL CHIP GSM/GPRS, CORRESPONDIENTE A LOS MESES DE MARZO Y ABRIL DE 2019.',
      UnitCost: 981.48,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10215451',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '97',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 486,
      invoiceSenderId: 1,
      Description: 'ROLLOS DE POLIBURBUJA',
      UnitCost: 638.62,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202503',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XKG',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 487,
      invoiceSenderId: 1,
      Description: 'ROLLOS DE FLEJE VERDE LISO',
      UnitCost: 53.12,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10226002',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'CEN',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 488,
      invoiceSenderId: 1,
      Description: 'PLACAS DE UNICEL',
      UnitCost: 180.91,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215483',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N94',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 489,
      invoiceSenderId: 1,
      Description: 'TARIMAS PLASTICAS',
      UnitCost: 531.3,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317343',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '1C',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 23
    },
    {
      id: 490,
      invoiceSenderId: 1,
      Description: 'ESQUINEROS DE CARTON DE 1.30 MTS/ALAS 4,',
      UnitCost: 318.41,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11151712',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'QB',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 491,
      invoiceSenderId: 1,
      Description: 'Guante Nitrilo no esteril mediano Supreno SE Microflex Lote 1700206900232',
      UnitCost: 690.0,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202473',
      IdentificationNumber: 'VBEZ-9083',
      InvoiceCatClaveUnidad: 'G37',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 30
    },
    {
      id: 492,
      invoiceSenderId: 1,
      Description: 'Guante Nitrilo no esteril chico Ambiderm Lote EH390I119',
      UnitCost: 674.57,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10361505',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KX',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 493,
      invoiceSenderId: 1,
      Description: 'Sangre de carnero desfibrinada Hemoproveedores Lote S-1106 Cad 24 Abr 2020',
      UnitCost: 329.79,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121506',
      IdentificationNumber: 'VCXU-0545',
      InvoiceCatClaveUnidad: 'XDK',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 26
    },
    {
      id: 494,
      invoiceSenderId: 1,
      Description: 'KILOS DE CINTURON PORTAGEL (6 PAQUETES)',
      UnitCost: 496.71,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10341801',
      IdentificationNumber: 'WNUJ-9339',
      InvoiceCatClaveUnidad: 'XOF',
      Ieps: 0.030,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 9
    },
    {
      id: 495,
      invoiceSenderId: 1,
      Description: 'BOLSAS DE GEL REFRIGERANTE (80 CAJAS)',
      UnitCost: 319.41,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10451901',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B22',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 496,
      invoiceSenderId: 1,
      Description: 'BISTEC DE RES',
      UnitCost: 246.9,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12142102',
      IdentificationNumber: 'XLYB-0844',
      InvoiceCatClaveUnidad: 'GQ',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 497,
      invoiceSenderId: 1,
      Description: 'MOLIDA DE RES',
      UnitCost: 617.7,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10413807',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H80',
      Ieps: null,
      RetIeps: null,
      RetVat: 3, RetIsr: 6
    },
    {
      id: 498,
      invoiceSenderId: 1,
      Description: 'MOLIDA DE CERDO',
      UnitCost: 586.4,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11191607',
      IdentificationNumber: 'FOEB-7217',
      InvoiceCatClaveUnidad: 'M37',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 23
    },
    {
      id: 499,
      invoiceSenderId: 1,
      Description: 'ROSBIF DE RES',
      UnitCost: 535.89,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10315700',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XDK',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 500,
      invoiceSenderId: 1,
      Description: 'ESPINAZO DE CERDO',
      UnitCost: 175.37,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '21101606',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H30',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 501,
      invoiceSenderId: 1,
      Description: 'FALDA DE RES',
      UnitCost: 718.58,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122109',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 502,
      invoiceSenderId: 1,
      Description: 'MANTECA DE CERDO',
      UnitCost: 130.6,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10431600',
      IdentificationNumber: 'WYRI-0934',
      InvoiceCatClaveUnidad: 'GV',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 503,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE COORDINACIÓN LOGíSTICA PARA MOVER UNIDAD DEDICADA CAJA DE 53´ Y 2 DE 48´, DE MEDIMETRICS A NEGRA MODELO REF SERVICIO DE TRANSPORTE **PAYNAL GROUP SERVICES SA DE CV PRESTA UN SERVICIO DE INTERMEDIACIÓN POR LO QUE NO ES SUJETO DE RETENCIÓ...',
      UnitCost: 10.24,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12171615',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H52',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 8, RetIsr: 20
    },
    {
      id: 504,
      invoiceSenderId: 1,
      Description: 'ROLLOS DE PELICULA PLASTICA',
      UnitCost: 444.12,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302173',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'BB',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 505,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 16 AL 31 DE MARZO DEL 2020, RECOLECTOR',
      UnitCost: 36.88,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20122826',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C90',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 506,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 16 AL 31 DE MARZO DEL 2020, AUXILIAR ADMINISTRATIVO',
      UnitCost: 729.13,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12171612',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P77',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 507,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 16 AL 31 DE MARZO DEL 2020, CONSULTOR',
      UnitCost: 768.8,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10226069',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'T7',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 508,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 16 AL 31 DE MARZO DEL 2020, DIRECTOR GENERAL',
      UnitCost: 450.62,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413603',
      IdentificationNumber: 'NAAR-9585',
      InvoiceCatClaveUnidad: 'L50',
      Ieps: null,
      RetIeps: null,
      RetVat: 10, RetIsr: 29
    },
    {
      id: 509,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 16 AL 31 DE MARZO DEL 2020, GERENTE DE OPERACIONES',
      UnitCost: 39.63,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '13101607',
      IdentificationNumber: 'CNYD-8016',
      InvoiceCatClaveUnidad: 'D23',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 510,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 16 AL 31 DE MARZO DEL 2020, CONSULTOR JUNIOR',
      UnitCost: 356.48,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10226019',
      IdentificationNumber: 'UCRU-0535',
      InvoiceCatClaveUnidad: 'SW',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 23
    },
    {
      id: 511,
      invoiceSenderId: 1,
      Description: 'ESQUINEROS PLASTICOS 1.40 MTS',
      UnitCost: 252.66,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413004',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L31',
      Ieps: 0.080,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 512,
      invoiceSenderId: 1,
      Description: 'ESQUINEROS PLASTICOS 1.70 MTS',
      UnitCost: 59.34,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10252004',
      IdentificationNumber: 'UFAR-8507',
      InvoiceCatClaveUnidad: 'XFT',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 513,
      invoiceSenderId: 1,
      Description: 'ESQUINEROS PLASTICOS 2.10 MTS',
      UnitCost: 293.47,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402388',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G14',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 514,
      invoiceSenderId: 1,
      Description: 'Suministro e instalación de alimentación eléctrica',
      UnitCost: 496.99,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10315808',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P22',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 8
    },
    {
      id: 515,
      invoiceSenderId: 1,
      Description: 'mantenimiento general',
      UnitCost: 407.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12161605',
      IdentificationNumber: 'TYSZ-0193',
      InvoiceCatClaveUnidad: 'H43',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 34
    },
    {
      id: 516,
      invoiceSenderId: 1,
      Description: 'MARBETE PARA RACK SEMANAL',
      UnitCost: 313.94,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20131000',
      IdentificationNumber: 'XYON-2870',
      InvoiceCatClaveUnidad: '1J',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 517,
      invoiceSenderId: 1,
      Description: 'MARBETE COLLARIN',
      UnitCost: 674.32,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217508',
      IdentificationNumber: 'HCDP-4825',
      InvoiceCatClaveUnidad: 'H60',
      Ieps: null,
      RetIeps: 0.304,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 518,
      invoiceSenderId: 1,
      Description: 'VALE DE SALIDA DE MATERIAL',
      UnitCost: 289.54,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10452104',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K16',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 519,
      invoiceSenderId: 1,
      Description: 'FORMATO VALE DE SALIDA DE ALMACEN Y HERRAMIENTAS',
      UnitCost: 199.96,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10211804',
      IdentificationNumber: 'KMVK-0140',
      InvoiceCatClaveUnidad: 'LTR',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 520,
      invoiceSenderId: 1,
      Description: 'FORMATO NOTA DE VENTA',
      UnitCost: 904.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202425',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '4K',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 521,
      invoiceSenderId: 1,
      Description: 'FORMATO DE INTERCOS',
      UnitCost: 171.54,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317104',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCP',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 522,
      invoiceSenderId: 1,
      Description: 'FORMATO ENTREGA DE EFECTIVO (CAJA TABULADORES)',
      UnitCost: 614.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10331907',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P84',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 12
    },
    {
      id: 523,
      invoiceSenderId: 1,
      Description: 'ETIQUETA DE LIBERADO VERDE',
      UnitCost: 535.88,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302730',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P75',
      Ieps: null,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 524,
      invoiceSenderId: 1,
      Description: 'FORMATO CONTRATO DE COMODATO',
      UnitCost: 201.72,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121440',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C72',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 20
    },
    {
      id: 525,
      invoiceSenderId: 1,
      Description: 'FORMATO COMPROBANTE DE EPURA',
      UnitCost: 480.8,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402801',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F65',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 14
    },
    {
      id: 526,
      invoiceSenderId: 1,
      Description: 'FORMATO CARNET DE OPERADOR',
      UnitCost: 651.53,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402874',
      IdentificationNumber: 'SYQR-3535',
      InvoiceCatClaveUnidad: 'PTD',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 527,
      invoiceSenderId: 1,
      Description: 'paquete de cubrebocas desechable 2 capas',
      UnitCost: 696.16,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10312649',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A70',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 528,
      invoiceSenderId: 1,
      Description: 'PICHANCHA 3 PULGADAS',
      UnitCost: 62.94,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13111038',
      IdentificationNumber: 'TEFA-9894',
      InvoiceCatClaveUnidad: 'XHN',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 529,
      invoiceSenderId: 1,
      Description: 'SERVICIOS CONTABLES DEL MES DE MARZO 2020',
      UnitCost: 547.78,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10314919',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCG',
      Ieps: 0.030,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 530,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,MARKETING',
      UnitCost: 278.78,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417911',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 531,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,VENTAS',
      UnitCost: 677.52,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '15101610',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N51',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 532,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,ALMACENISTA',
      UnitCost: 682.59,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10326059',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P56',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 533,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,GERENTE DE VENTAS',
      UnitCost: 646.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301911',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C29',
      Ieps: null,
      RetIeps: null,
      RetVat: 9, RetIsr: 9
    },
    {
      id: 534,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,AYUDANTE GENERAL',
      UnitCost: 706.1,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202185',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XDK',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 535,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,CHOFER',
      UnitCost: 699.92,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10161505',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XYP',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 536,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,CUENTAS POR COBRAR',
      UnitCost: 738.41,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402183',
      IdentificationNumber: 'NZHU-5991',
      InvoiceCatClaveUnidad: 'Q11',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 537,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,CONTADOR',
      UnitCost: 986.75,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121104',
      IdentificationNumber: 'PCVF-1235',
      InvoiceCatClaveUnidad: 'XZR',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 538,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,AUXILIAR ADMINISTRATIVO',
      UnitCost: 232.79,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202725',
      IdentificationNumber: 'JORC-9697',
      InvoiceCatClaveUnidad: 'J73',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 539,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,INTENDENTE',
      UnitCost: 205.2,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402210',
      IdentificationNumber: 'EGDW-7633',
      InvoiceCatClaveUnidad: 'G53',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 2, RetIsr: 14
    },
    {
      id: 540,
      invoiceSenderId: 1,
      Description: 'mano de obra servicio unidad #5226',
      UnitCost: 119.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10414112',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N45',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 541,
      invoiceSenderId: 1,
      Description: 'aceite para motor',
      UnitCost: 973.87,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402004',
      IdentificationNumber: 'LFOA-0564',
      InvoiceCatClaveUnidad: 'XIB',
      Ieps: 0.060,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 542,
      invoiceSenderId: 1,
      Description: 'filtro para diesel',
      UnitCost: 818.92,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202613',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'QD',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 543,
      invoiceSenderId: 1,
      Description: 'filtro para aceite de motor',
      UnitCost: 638.8,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416100',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B69',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 544,
      invoiceSenderId: 1,
      Description: 'filtro para aire de motor',
      UnitCost: 21.92,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10418343',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P8',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 545,
      invoiceSenderId: 1,
      Description: 'ESTUDIOS DE GAMAGRAFIA',
      UnitCost: 800.4,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402847',
      IdentificationNumber: 'PKFS-2153',
      InvoiceCatClaveUnidad: 'K96',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 546,
      invoiceSenderId: 1,
      Description: 'FLETE (TESTIM.C/M Y CARATULAS)',
      UnitCost: 277.64,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20121702',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XSD',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null, RetIsr: 9
    },
    {
      id: 547,
      invoiceSenderId: 1,
      Description: 'FLETE (TESTI. RAYADO Y SOBRES KRAFT)',
      UnitCost: 135.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402431',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'T6',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 548,
      invoiceSenderId: 1,
      Description: 'CARATULAS EN .CART.TELA IMPORT',
      UnitCost: 51.75,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231610',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G63',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 549,
      invoiceSenderId: 1,
      Description: 'MILLAR DE PAPEL TESTIMONIO C/ MEMBRETE',
      UnitCost: 405.08,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121130',
      IdentificationNumber: 'PYMU-2796',
      InvoiceCatClaveUnidad: 'N51',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 550,
      invoiceSenderId: 1,
      Description: 'CANDADO DE VIGA FIJO MOD. CV',
      UnitCost: 594.47,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302302',
      IdentificationNumber: 'RXYQ-6929',
      InvoiceCatClaveUnidad: 'A96',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 551,
      invoiceSenderId: 1,
      Description: 'ABRAZADERA FIJA CON 2 TORNILLOS',
      UnitCost: 473.9,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12161809',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E75',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 552,
      invoiceSenderId: 1,
      Description: 'cinta teflon',
      UnitCost: 36.75,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10122100',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q41',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 553,
      invoiceSenderId: 1,
      Description: 'tapon 25',
      UnitCost: 227.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302414',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XZY',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 23
    },
    {
      id: 554,
      invoiceSenderId: 1,
      Description: 'reduccion 25-20',
      UnitCost: 723.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416401',
      IdentificationNumber: 'DQGA-5066',
      InvoiceCatClaveUnidad: 'G29',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 555,
      invoiceSenderId: 1,
      Description: 'conector macho 20',
      UnitCost: 735.61,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402034',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XBA',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 35
    },
    {
      id: 556,
      invoiceSenderId: 1,
      Description: 'dado 20',
      UnitCost: 152.33,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301928',
      IdentificationNumber: 'XVJM-9814',
      InvoiceCatClaveUnidad: 'MQ',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 557,
      invoiceSenderId: 1,
      Description: 'dado 25',
      UnitCost: 928.68,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10318018',
      IdentificationNumber: 'ACYU-3722',
      InvoiceCatClaveUnidad: 'B56',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 558,
      invoiceSenderId: 1,
      Description: 'termo',
      UnitCost: 333.48,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302882',
      IdentificationNumber: 'XAHJ-3126',
      InvoiceCatClaveUnidad: 'N41',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 559,
      invoiceSenderId: 1,
      Description: 'tee 25',
      UnitCost: 839.73,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317704',
      IdentificationNumber: 'ASGP-6783',
      InvoiceCatClaveUnidad: 'Q21',
      Ieps: null,
      RetIeps: null,
      RetVat: 13, RetIsr: 35
    },
    {
      id: 560,
      invoiceSenderId: 1,
      Description: 'coples 20',
      UnitCost: 175.38,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202839',
      IdentificationNumber: 'OXPZ-3306',
      InvoiceCatClaveUnidad: 'GRM',
      Ieps: null,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 561,
      invoiceSenderId: 1,
      Description: 'codos20x90',
      UnitCost: 708.94,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10332015',
      IdentificationNumber: 'UJJE-2994',
      InvoiceCatClaveUnidad: 'XWA',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 562,
      invoiceSenderId: 1,
      Description: 'codos 20x45',
      UnitCost: 405.98,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10151539',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F41',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 563,
      invoiceSenderId: 1,
      Description: 'codos 25x45',
      UnitCost: 816.56,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '15121524',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'GQ',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 564,
      invoiceSenderId: 1,
      Description: 'codo 25x90',
      UnitCost: 600.18,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10314706',
      IdentificationNumber: 'TSXH-2554',
      InvoiceCatClaveUnidad: 'DJ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 565,
      invoiceSenderId: 1,
      Description: 'coples 25',
      UnitCost: 579.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20111611',
      IdentificationNumber: 'BNVM-3538',
      InvoiceCatClaveUnidad: 'HEA',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 566,
      invoiceSenderId: 1,
      Description: 'tubo 25',
      UnitCost: 689.71,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20121317',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MIL',
      Ieps: null,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 567,
      invoiceSenderId: 1,
      Description: 'tubo 20',
      UnitCost: 274.24,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10413602',
      IdentificationNumber: 'OZLR-2095',
      InvoiceCatClaveUnidad: 'MWH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 568,
      invoiceSenderId: 1,
      Description: 'cambio de terminales y bieletas',
      UnitCost: 934.34,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402392',
      IdentificationNumber: 'CCYZ-6398',
      InvoiceCatClaveUnidad: 'G57',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 569,
      invoiceSenderId: 1,
      Description: 'cambio de rotulas y bujes de orquillas',
      UnitCost: 286.93,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302802',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L57',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 570,
      invoiceSenderId: 1,
      Description: 'buje de orquilla grande economico',
      UnitCost: 382.34,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10432111',
      IdentificationNumber: 'FUST-8819',
      InvoiceCatClaveUnidad: 'P95',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 571,
      invoiceSenderId: 1,
      Description: 'buje de orquilla chico economico',
      UnitCost: 184.4,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10212503',
      IdentificationNumber: 'KHYW-1609',
      InvoiceCatClaveUnidad: 'IV',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 572,
      invoiceSenderId: 1,
      Description: 'rotula delantera inferior econimica',
      UnitCost: 452.01,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12161507',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A37',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 12
    },
    {
      id: 573,
      invoiceSenderId: 1,
      Description: 'terminal de direccion economica',
      UnitCost: 259.68,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312303',
      IdentificationNumber: 'GVBG-8077',
      InvoiceCatClaveUnidad: 'NAR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 574,
      invoiceSenderId: 1,
      Description: 'bieleta de dirección económica',
      UnitCost: 700.89,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12352112',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H15',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 575,
      invoiceSenderId: 1,
      Description: 'Sello de 12mm hamb',
      UnitCost: 576.75,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10311719',
      IdentificationNumber: 'IDOY-8429',
      InvoiceCatClaveUnidad: 'K94',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 576,
      invoiceSenderId: 1,
      Description: 'sandia',
      UnitCost: 994.4,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302165',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A75',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 577,
      invoiceSenderId: 1,
      Description: 'SHOTPEEN W5-P-1527',
      UnitCost: 220.36,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312611',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TP',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 578,
      invoiceSenderId: 1,
      Description: 'berelinte bco ost',
      UnitCost: 672.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10502802',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XKI',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 579,
      invoiceSenderId: 1,
      Description: 'berelex green',
      UnitCost: 146.84,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217354',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'X4B',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 580,
      invoiceSenderId: 1,
      Description: 'papel',
      UnitCost: 390.93,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332016',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XDI',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 581,
      invoiceSenderId: 1,
      Description: 'cinta',
      UnitCost: 804.18,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202611',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K46',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 582,
      invoiceSenderId: 1,
      Description: 'cuñas',
      UnitCost: 263.23,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122310',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TIP',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 583,
      invoiceSenderId: 1,
      Description: 'plasticos',
      UnitCost: 773.67,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10416101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M49',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 584,
      invoiceSenderId: 1,
      Description: 'yeso',
      UnitCost: 538.77,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417948',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J43',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 14, RetIsr: 3
    },
    {
      id: 585,
      invoiceSenderId: 1,
      Description: 'sellador vin',
      UnitCost: 105.87,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302758',
      IdentificationNumber: 'JKRN-6781',
      InvoiceCatClaveUnidad: 'B26',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 586,
      invoiceSenderId: 1,
      Description: 'Presentación perspectiva económica, AMEX',
      UnitCost: 308.82,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10314917',
      IdentificationNumber: 'JJMN-0359',
      InvoiceCatClaveUnidad: 'G63',
      Ieps: null,
      RetIeps: null,
      RetVat: 13, RetIsr: 16
    },
    {
      id: 587,
      invoiceSenderId: 1,
      Description: 'Participación información y análisis 3er.trimestre 2020',
      UnitCost: 275.25,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10452059',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'PE',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 12, RetIsr: 11
    },
    {
      id: 588,
      invoiceSenderId: 1,
      Description: 'Renta de Abril de 2020, de la finca ubicado en Alvaro Obregon # 596 colonia San Juan de Dios en Guadalajara, Jalisco.',
      UnitCost: 771.12,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215433',
      IdentificationNumber: 'SMEM-4700',
      InvoiceCatClaveUnidad: 'TTS',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 589,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 03-04-2020',
      UnitCost: 721.3,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10426074',
      IdentificationNumber: 'VYVM-6967',
      InvoiceCatClaveUnidad: '30',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 590,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 05-04-2020',
      UnitCost: 21.89,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10241506',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B46',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 591,
      invoiceSenderId: 1,
      Description: 'INTERES',
      UnitCost: 56.76,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10413608',
      IdentificationNumber: 'IYQI-2330',
      InvoiceCatClaveUnidad: 'XNT',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 592,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 02-04-2020',
      UnitCost: 683.81,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202754',
      IdentificationNumber: 'ZBYO-1255',
      InvoiceCatClaveUnidad: 'XBS',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 11
    },
    {
      id: 593,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE MONITOREO TRIMESTRAL',
      UnitCost: 332.56,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10211714',
      IdentificationNumber: 'CVHI-3565',
      InvoiceCatClaveUnidad: 'XKI',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 594,
      invoiceSenderId: 1,
      Description: 'Suscripción',
      UnitCost: 520.52,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10226062',
      IdentificationNumber: 'KGRI-1063',
      InvoiceCatClaveUnidad: 'XCB',
      Ieps: null,
      RetIeps: null,
      RetVat: 11, RetIsr: 13
    },
    {
      id: 595,
      invoiceSenderId: 1,
      Description: 'Tarifa por referencia',
      UnitCost: 590.72,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10423102',
      IdentificationNumber: 'TOQE-6666',
      InvoiceCatClaveUnidad: 'B38',
      Ieps: null,
      RetIeps: null,
      RetVat: 6, RetIsr: 16
    },
    {
      id: 596,
      invoiceSenderId: 1,
      Description: 'Tarifa variable por venta de artículo',
      UnitCost: 724.0,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10315804',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H23',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 597,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 31-03-2020',
      UnitCost: 33.51,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402216',
      IdentificationNumber: 'LAVQ-0081',
      InvoiceCatClaveUnidad: 'C63',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 33
    },
    {
      id: 598,
      invoiceSenderId: 1,
      Description: 'COBRO COMIS TAR DEBIT A NOM EVOPAYMX',
      UnitCost: 692.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231809',
      IdentificationNumber: 'BUCV-3017',
      InvoiceCatClaveUnidad: 'A80',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 599,
      invoiceSenderId: 1,
      Description: 'Servicios de mensajeria y paquetería para MSS',
      UnitCost: 848.37,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10415609',
      IdentificationNumber: 'GNBF-8331',
      InvoiceCatClaveUnidad: 'D42',
      Ieps: null,
      RetIeps: null,
      RetVat: 7, RetIsr: 10
    },
    {
      id: 600,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 01-04-2020',
      UnitCost: 826.78,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12352102',
      IdentificationNumber: 'ISMK-8850',
      InvoiceCatClaveUnidad: 'K30',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 35
    },
    {
      id: 601,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 25-03-2020',
      UnitCost: 207.96,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20142905',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWZ',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 602,
      invoiceSenderId: 1,
      Description: 'Uso del servicio 18-03-2020',
      UnitCost: 705.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10212652',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TAB',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 22
    },
    {
      id: 603,
      invoiceSenderId: 1,
      Description: 'VIAS DE EMBARQUE TERRESTRE - ENVIO POR APP',
      UnitCost: 365.04,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302381',
      IdentificationNumber: 'RUCF-8721',
      InvoiceCatClaveUnidad: 'H46',
      Ieps: null,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 604,
      invoiceSenderId: 1,
      Description: 'SWITCH 8 PTOS POE+ 802.3AT EXTEND C/2 COMBO GIGA TP/SFP C/LCD P/MON POE 120W',
      UnitCost: 100.63,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10311719',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XPY',
      Ieps: null,
      RetIeps: null,
      RetVat: 2, RetIsr: 15
    },
    {
      id: 605,
      invoiceSenderId: 1,
      Description: 'CONECTOR PVC CONDUIT PESADO 1/2',
      UnitCost: 705.59,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12141506',
      IdentificationNumber: 'VLSB-3828',
      InvoiceCatClaveUnidad: 'XCJ',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 606,
      invoiceSenderId: 1,
      Description: 'TRAMO TUBO PVC CONDUIT PESADO : 1/2',
      UnitCost: 199.54,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10401702',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B75',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 11
    },
    {
      id: 607,
      invoiceSenderId: 1,
      Description: 'CAJA DE PVC CONDUIT 5X5 CON TAPA',
      UnitCost: 557.04,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10161902',
      IdentificationNumber: 'OAJX-2018',
      InvoiceCatClaveUnidad: 'KUR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 608,
      invoiceSenderId: 1,
      Description: 'VIAS DE EMBARQUE PRODUCTOS CON VOLUMEN TERRESTRE - ENVIO POR APP',
      UnitCost: 849.12,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10315427',
      IdentificationNumber: 'TBKY-3992',
      InvoiceCatClaveUnidad: 'G10',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 609,
      invoiceSenderId: 1,
      Description: 'CMRA DOMO IP 1MP 2.8MM IP67 IK10 POE IR 30M',
      UnitCost: 412.87,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10217340',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '5K',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 610,
      invoiceSenderId: 1,
      Description: 'NVR 4MP / 8 CANALES IP / 8 PUERTOS POE+ / 1 HDD / H.265+ / SALIDA EN FULL HD',
      UnitCost: 29.8,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11162108',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'U1',
      Ieps: 0.060,
      RetIeps: 0.060,
      RetVat: 9, RetIsr: 27
    },
    {
      id: 611,
      invoiceSenderId: 1,
      Description: 'GAB P/PARED PUERTA C/CRISTAL CUERPO FIJO RACK 19IN 6 UNIDADES',
      UnitCost: 40.91,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317001',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N77',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 612,
      invoiceSenderId: 1,
      Description: 'JACK UTP CAT5E D/IMPACTO 110 NEGRO',
      UnitCost: 970.39,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302202',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCW',
      Ieps: 0.030,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 25
    },
    {
      id: 613,
      invoiceSenderId: 1,
      Description: 'PANEL D/PARCHEO MODULAR 24 PTOS  1U',
      UnitCost: 947.79,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12162502',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G16',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 614,
      invoiceSenderId: 1,
      Description: 'REGULAR',
      UnitCost: 134.65,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10213611',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K30',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 615,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,MECANICO',
      UnitCost: 985.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10211720',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H91',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 32
    },
    {
      id: 616,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,OPERADOR DE TRACTOCAMION',
      UnitCost: 216.55,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20122513',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B67',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 617,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,ASISTENTE DE LOGISTICA',
      UnitCost: 437.73,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13111055',
      IdentificationNumber: 'YWRS-3690',
      InvoiceCatClaveUnidad: 'J94',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 1
    },
    {
      id: 618,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020,OPERADOR FORANEO',
      UnitCost: 922.1,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10413609',
      IdentificationNumber: 'TJLQ-0111',
      InvoiceCatClaveUnidad: 'F91',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 619,
      invoiceSenderId: 1,
      Description: 'cinta de teflon',
      UnitCost: 461.24,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122109',
      IdentificationNumber: 'WUNR-9814',
      InvoiceCatClaveUnidad: 'G59',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 620,
      invoiceSenderId: 1,
      Description: 'conector macho20',
      UnitCost: 866.23,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10401956',
      IdentificationNumber: 'WGLE-4786',
      InvoiceCatClaveUnidad: 'M81',
      Ieps: 0.080,
      RetIeps: null,
      RetVat: null, RetIsr: 19
    },
    {
      id: 621,
      invoiceSenderId: 1,
      Description: 'dados 20',
      UnitCost: 97.1,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202235',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N27',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 622,
      invoiceSenderId: 1,
      Description: 'dados 25',
      UnitCost: 435.36,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10232036',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P37',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 623,
      invoiceSenderId: 1,
      Description: 'codos 20x90',
      UnitCost: 587.76,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302315',
      IdentificationNumber: 'PAUJ-8797',
      InvoiceCatClaveUnidad: 'J25',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 624,
      invoiceSenderId: 1,
      Description: 'codos 25x90',
      UnitCost: 720.86,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202019',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XNG',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 625,
      invoiceSenderId: 1,
      Description: 'tubo25',
      UnitCost: 557.73,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10252212',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '4U',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 626,
      invoiceSenderId: 1,
      Description: 'RENTA DEL MES DE ABRIL 2020',
      UnitCost: 684.96,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10211501',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J61',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 627,
      invoiceSenderId: 1,
      Description: 'CHILE DE ÁRBOL C/C YAHUALICA',
      UnitCost: 318.58,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402236',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G70',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 628,
      invoiceSenderId: 1,
      Description: 'CHILE PASILLA',
      UnitCost: 684.0,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10201945',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G82',
      Ieps: null,
      RetIeps: null,
      RetVat: 13, RetIsr: 33
    },
    {
      id: 629,
      invoiceSenderId: 1,
      Description: 'CHILE ANCHO',
      UnitCost: 677.88,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10231629',
      IdentificationNumber: 'MKYV-2846',
      InvoiceCatClaveUnidad: 'G49',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 630,
      invoiceSenderId: 1,
      Description: 'FRIJOL PINTO',
      UnitCost: 357.26,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10423200',
      IdentificationNumber: 'QEWF-5856',
      InvoiceCatClaveUnidad: 'A64',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 631,
      invoiceSenderId: 1,
      Description: 'CHILE CHILACA',
      UnitCost: 368.08,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122703',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WA',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 632,
      invoiceSenderId: 1,
      Description: 'CLUTCH P/TRANSMISION AUTOMATICA VOLVO',
      UnitCost: 217.91,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12163902',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ODE',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 633,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ASESORIA EN ALMACENAJE',
      UnitCost: 311.74,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10215406',
      IdentificationNumber: 'VEGQ-0910',
      InvoiceCatClaveUnidad: 'G87',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 4, RetIsr: 25
    },
    {
      id: 634,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ASESORIA EN ALMACENAJE Y DISTRIBUCION',
      UnitCost: 399.04,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10331711',
      IdentificationNumber: 'BREH-9003',
      InvoiceCatClaveUnidad: 'GRO',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 635,
      invoiceSenderId: 1,
      Description: 'KIT PISTOLA PINTAR 2 BOQUILLAS',
      UnitCost: 288.98,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10215805',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MAM',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null, RetIsr: 35
    },
    {
      id: 636,
      invoiceSenderId: 1,
      Description: 'HONORARIOS MEDICOS',
      UnitCost: 283.75,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10423003',
      IdentificationNumber: 'INOZ-8623',
      InvoiceCatClaveUnidad: 'S4',
      Ieps: null,
      RetIeps: null,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 637,
      invoiceSenderId: 1,
      Description: 'ALQUILER DE CAMION ABRIL 2020',
      UnitCost: 272.61,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10332033',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'X7A',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 638,
      invoiceSenderId: 1,
      Description: 'ENVIO INTERNACIONALES',
      UnitCost: 516.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10401937',
      IdentificationNumber: 'XLVK-7176',
      InvoiceCatClaveUnidad: 'YDK',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 10, RetIsr: 30
    },
    {
      id: 639,
      invoiceSenderId: 1,
      Description: 'ENVIO INTERNACIONALES ',
      UnitCost: 832.21,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10218201',
      IdentificationNumber: 'WSJN-5275',
      InvoiceCatClaveUnidad: 'TIP',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 640,
      invoiceSenderId: 1,
      Description: ' APLICA A FACTURA SEG-38914',
      UnitCost: 982.39,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317302',
      IdentificationNumber: 'UTBR-0529',
      InvoiceCatClaveUnidad: 'Q3',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 641,
      invoiceSenderId: 1,
      Description: 'Recargos',
      UnitCost: 768.89,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10415104',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '4O',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 642,
      invoiceSenderId: 1,
      Description: 'Balero 6206',
      UnitCost: 976.16,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10441507',
      IdentificationNumber: 'NMLM-4009',
      InvoiceCatClaveUnidad: 'XDG',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 643,
      invoiceSenderId: 1,
      Description: 'Balero 6205',
      UnitCost: 64.55,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202174',
      IdentificationNumber: 'KUCI-3444',
      InvoiceCatClaveUnidad: 'XYQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 644,
      invoiceSenderId: 1,
      Description: 'Sello 1 pulgada largo',
      UnitCost: 566.69,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10412002',
      IdentificationNumber: 'VUPX-3344',
      InvoiceCatClaveUnidad: 'H43',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 645,
      invoiceSenderId: 1,
      Description: 'REPARACION A REFRIGERADOR SAMSUNG CARGA DE GAS',
      UnitCost: 228.58,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10362056',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E42',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 24
    },
    {
      id: 646,
      invoiceSenderId: 1,
      Description: 'CONSULTA MEDICA',
      UnitCost: 702.32,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302743',
      IdentificationNumber: 'OCJR-5658',
      InvoiceCatClaveUnidad: 'XWR',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 647,
      invoiceSenderId: 1,
      Description: 'Recargos de cuotas RCV ',
      UnitCost: 814.22,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402364',
      IdentificationNumber: 'JAAG-2039',
      InvoiceCatClaveUnidad: 'L67',
      Ieps: null,
      RetIeps: null,
      RetVat: 3, RetIsr: 29
    },
    {
      id: 648,
      invoiceSenderId: 1,
      Description: 'Recargos de Cuotas IMSS ',
      UnitCost: 192.7,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '11191503',
      IdentificationNumber: 'ZROP-4169',
      InvoiceCatClaveUnidad: 'J46',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 649,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020, AYUDANTE GENERAL',
      UnitCost: 680.03,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10252104',
      IdentificationNumber: 'SFAX-6405',
      InvoiceCatClaveUnidad: 'N26',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 650,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020, OFICIAL CARPINTERO',
      UnitCost: 84.69,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402325',
      IdentificationNumber: 'EGGA-8164',
      InvoiceCatClaveUnidad: 'N40',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 651,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020, AYUDANTE DE ALBAÑILERIA',
      UnitCost: 678.47,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10232111',
      IdentificationNumber: 'IHXC-0579',
      InvoiceCatClaveUnidad: 'IT',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 652,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020, OFICIAL FIERRERO',
      UnitCost: 565.4,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10413909',
      IdentificationNumber: 'RSPY-2059',
      InvoiceCatClaveUnidad: '2B',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 653,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 30 DE MARZO AL 05 DE ABRIL DEL 2020, OFICIAL ALBAÑIL',
      UnitCost: 417.49,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402227',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XZF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 654,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, ASESOR DE VENTAS',
      UnitCost: 758.55,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10412403',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWG',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 655,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, MENSAJERO',
      UnitCost: 355.7,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10201942',
      IdentificationNumber: 'BBXN-1331',
      InvoiceCatClaveUnidad: 'X5H',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 34
    },
    {
      id: 656,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, DISEÑADOR GRAFICO',
      UnitCost: 202.69,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10431702',
      IdentificationNumber: 'ETKN-9389',
      InvoiceCatClaveUnidad: 'D91',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 657,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, VENDEDOR',
      UnitCost: 739.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20121122',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J10',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 20
    },
    {
      id: 658,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, AUXILIAR CREDITO Y COBRANZA',
      UnitCost: 812.3,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122514',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '3B',
      Ieps: null,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 659,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, ALMACENISTA',
      UnitCost: 406.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10213110',
      IdentificationNumber: 'PFKJ-0210',
      InvoiceCatClaveUnidad: 'D95',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 660,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, AYUDANTE DE ALMACEN',
      UnitCost: 952.01,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10216702',
      IdentificationNumber: 'NMUZ-7615',
      InvoiceCatClaveUnidad: 'E52',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 12
    },
    {
      id: 661,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, INGENIERO',
      UnitCost: 791.44,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12161900',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XBB',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 662,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 DE ABRIL DEL 2020, AUXILIAR EN PRODUCCION',
      UnitCost: 298.47,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11191502',
      IdentificationNumber: 'NWTL-2121',
      InvoiceCatClaveUnidad: 'GWH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 663,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE COORDINACIÓN LOGíSTICA PARA MOVER UNIDAD DEDICADA CAMIONETA 1.5 TONS, DE PRIDA TX A FABRICA DE MERMELADA REF 31J0000060. **PAYNAL GROUP SERVICES SA DE CV PRESTA UN SERVICIO DE INTERMEDIACIÓN POR LO QUE NO ES SUJETO DE RETENCIÓN DE IVA** O...',
      UnitCost: 131.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10362021',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ROM',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 664,
      invoiceSenderId: 1,
      Description: 'Servicios de auditoria 2020 3/3',
      UnitCost: 48.44,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10321802',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Jo',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 665,
      invoiceSenderId: 1,
      Description: 'brocha',
      UnitCost: 80.85,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402032',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F32',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 14
    },
    {
      id: 666,
      invoiceSenderId: 1,
      Description: 'removedor de pintua',
      UnitCost: 683.68,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10425003',
      IdentificationNumber: 'DDVS-1943',
      InvoiceCatClaveUnidad: 'XPO',
      Ieps: null,
      RetIeps: null,
      RetVat: 14, RetIsr: 13
    },
    {
      id: 667,
      invoiceSenderId: 1,
      Description: 'lijas de esmeril',
      UnitCost: 609.78,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302031',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XHA',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 9
    },
    {
      id: 668,
      invoiceSenderId: 1,
      Description: 'estopa',
      UnitCost: 343.13,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402173',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B13',
      Ieps: null,
      RetIeps: null,
      RetVat: 7, RetIsr: 26
    },
    {
      id: 669,
      invoiceSenderId: 1,
      Description: 'pistola',
      UnitCost: 93.77,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20111609',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'FE',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 670,
      invoiceSenderId: 1,
      Description: 'thinner americano',
      UnitCost: 401.98,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10151501',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L70',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 671,
      invoiceSenderId: 1,
      Description: 'esmalte gris',
      UnitCost: 642.7,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10326065',
      IdentificationNumber: 'SCGD-5703',
      InvoiceCatClaveUnidad: 'DPC',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 10
    },
    {
      id: 672,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 ABRIL DEL 2020, VENDEDORA',
      UnitCost: 786.44,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10326058',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'CTN',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 673,
      invoiceSenderId: 1,
      Description: 'SUMINISTRO DE SERVICIO DE PERSONAL CORRESPONDIENTE AL PERIODO DEL 21 DE MARZO AL 05 ABRIL DEL 2020, VENDEDOR',
      UnitCost: 303.08,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413301',
      IdentificationNumber: 'NDDJ-0509',
      InvoiceCatClaveUnidad: 'N69',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 7, RetIsr: 25
    },
    {
      id: 674,
      invoiceSenderId: 1,
      Description: 'SHOCK CORRECTIVO CUBETA DE 18 KG',
      UnitCost: 817.4,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10213010',
      IdentificationNumber: 'UFFO-8411',
      InvoiceCatClaveUnidad: 'QTR',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 675,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE COORDINACIÓN LOGíSTICA PARA MOVER CONSOLIDADO 1 PALLET 63 KG, DE THERMON INC SAN MARCOS A PRIDA TX REF 31J0000060. **PAYNAL GROUP SERVICES SA DE CV PRESTA UN SERVICIO DE INTERMEDIACIÓN POR LO QUE NO ES SUJETO DE RETENCIÓN DE IVA** ORIGEN:...',
      UnitCost: 848.95,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10401514',
      IdentificationNumber: 'LZJG-4202',
      InvoiceCatClaveUnidad: 'H23',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 2
    },
    {
      id: 676,
      invoiceSenderId: 1,
      Description: 'Combustible Pemex Diesel',
      UnitCost: 573.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10413703',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J90',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 35
    },
    {
      id: 677,
      invoiceSenderId: 1,
      Description: 'SOPHIA ISABEL ROMERO MARTINEZ',
      UnitCost: 952.56,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402028',
      IdentificationNumber: 'AVBX-3225',
      InvoiceCatClaveUnidad: 'KAT',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 678,
      invoiceSenderId: 1,
      Description: 'NITRIC ACID 55%',
      UnitCost: 425.25,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '15121904',
      IdentificationNumber: 'ZQWC-7921',
      InvoiceCatClaveUnidad: 'XCO',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 21
    },
    {
      id: 679,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE SEGURIDAD PRIVADA DEL PERIODO DEL 1 DE ABRIL AL 07 DE ABRIL DEL 2020 EN PRODUCTOS REMO',
      UnitCost: 591.28,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10412624',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F10',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 680,
      invoiceSenderId: 1,
      Description: 'Lote de luminarias y accesorios eléctricos',
      UnitCost: 176.6,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10191703',
      IdentificationNumber: 'QRGB-2557',
      InvoiceCatClaveUnidad: '5A',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 681,
      invoiceSenderId: 1,
      Description: '10 LONAS DE 1X1M CON TERMOSELLADO Y OJILLOS ,CERRADO,',
      UnitCost: 550.77,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20141702',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'SX',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 682,
      invoiceSenderId: 1,
      Description: 'UN VEHICULO NUEVO, IMPORTADO QX 60 LUXURY',
      UnitCost: 527.6,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413909',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '40',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 683,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE PAQUETERIA',
      UnitCost: 209.22,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202510',
      IdentificationNumber: 'IRXW-5922',
      InvoiceCatClaveUnidad: 'L24',
      Ieps: 0.070,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 684,
      invoiceSenderId: 1,
      Description: 'SERENADE ASO 1 LT',
      UnitCost: 316.77,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10424100',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'GK',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 15
    },
    {
      id: 685,
      invoiceSenderId: 1,
      Description: 'KUMULUS DF 25 KG',
      UnitCost: 744.3,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10423800',
      IdentificationNumber: 'UOIT-7600',
      InvoiceCatClaveUnidad: 'P25',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 21
    },
    {
      id: 686,
      invoiceSenderId: 1,
      Description: 'CABRIO C 800 GRS',
      UnitCost: 827.98,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202350',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q17',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 687,
      invoiceSenderId: 1,
      Description: 'KNACK 970 ML',
      UnitCost: 598.48,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402175',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TSH',
      Ieps: null,
      RetIeps: null,
      RetVat: 13, RetIsr: 19
    },
    {
      id: 688,
      invoiceSenderId: 1,
      Description: 'PURE DE FRESA CHUNKY',
      UnitCost: 851.62,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332062',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J40',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 20
    },
    {
      id: 689,
      invoiceSenderId: 1,
      Description: 'Servicio Topicflower - Nota de crédito por A 504',
      UnitCost: 293.49,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122500',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P69',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 690,
      invoiceSenderId: 1,
      Description: 'H. FAMOUS STAR',
      UnitCost: 92.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10131601',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q34',
      Ieps: null,
      RetIeps: null,
      RetVat: 2, RetIsr: 27
    },
    {
      id: 691,
      invoiceSenderId: 1,
      Description: 'PAPAS CHICAS',
      UnitCost: 142.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101700',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XYH',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 692,
      invoiceSenderId: 1,
      Description: 'REFRESCO CHICO',
      UnitCost: 664.08,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202780',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'LTR',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: null, RetIsr: 26
    },
    {
      id: 693,
      invoiceSenderId: 1,
      Description: 'PEMEX PREMIUM',
      UnitCost: 194.58,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12164900',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'T5',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 694,
      invoiceSenderId: 1,
      Description: 'G PREMIUM-32012',
      UnitCost: 700.57,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317510',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '5P',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 695,
      invoiceSenderId: 1,
      Description: 'ACEITE PARA MOTOR TAB',
      UnitCost: 975.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312216',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'X4C',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 8
    },
    {
      id: 696,
      invoiceSenderId: 1,
      Description: 'FILTRO DE ACEITE RE-MBE8Z-6731-AB',
      UnitCost: 509.49,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10318005',
      IdentificationNumber: 'RSQH-3323',
      InvoiceCatClaveUnidad: 'L92',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 697,
      invoiceSenderId: 1,
      Description: 'ELEMENTO FILTRANTE PURIFICADOR',
      UnitCost: 413.29,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10401744',
      IdentificationNumber: 'NHHH-9116',
      InvoiceCatClaveUnidad: 'PTN',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 698,
      invoiceSenderId: 1,
      Description: 'MATERIALES DIVERSOS',
      UnitCost: 280.27,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317334',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C29',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 699,
      invoiceSenderId: 1,
      Description: 'SERVICIO DE 140,000 KM',
      UnitCost: 359.26,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10502805',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'NC',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 700,
      invoiceSenderId: 1,
      Description: 'iDesign Una Waste, Trash Can for Bathroom, Kitchen, Bedroom-Set of 2, 12" Inch - Set of 2, 12" Inch - Set of 2',
      UnitCost: 883.5,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10311613',
      IdentificationNumber: 'FYBC-3558',
      InvoiceCatClaveUnidad: 'DAY',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 701,
      invoiceSenderId: 1,
      Description: 'TONYMOLY Panda\'s Dream - Saco de dormir, color blanco',
      UnitCost: 469.62,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12141912',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M60',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 702,
      invoiceSenderId: 1,
      Description: 'Las Cuatro Nobles Verdades',
      UnitCost: 561.76,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202212',
      IdentificationNumber: 'TPDU-3570',
      InvoiceCatClaveUnidad: 'DEC',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 29
    },
    {
      id: 703,
      invoiceSenderId: 1,
      Description: 'Membresía mensual Amazon Prime',
      UnitCost: 609.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10418111',
      IdentificationNumber: 'TVVU-8487',
      InvoiceCatClaveUnidad: 'SYR',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 704,
      invoiceSenderId: 1,
      Description: 'REFRESCO MEDIANO',
      UnitCost: 393.75,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417332',
      IdentificationNumber: 'IUKH-6800',
      InvoiceCatClaveUnidad: 'L15',
      Ieps: null,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 705,
      invoiceSenderId: 1,
      Description: 'BIG ORIGINAL',
      UnitCost: 748.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302746',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A25',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 706,
      invoiceSenderId: 1,
      Description: 'PAPAS MEDIANAS',
      UnitCost: 758.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10416402',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'SCR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 707,
      invoiceSenderId: 1,
      Description: 'H. SMOKE BURGER',
      UnitCost: 634.35,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12162903',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H77',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 2, RetIsr: 21
    },
    {
      id: 708,
      invoiceSenderId: 1,
      Description: 'H. CLASSIC BURGER',
      UnitCost: 14.89,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10211904',
      IdentificationNumber: 'GUOG-5053',
      InvoiceCatClaveUnidad: 'G12',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 709,
      invoiceSenderId: 1,
      Description: 'H. WESTERN',
      UnitCost: 299.78,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202110',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E68',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 710,
      invoiceSenderId: 1,
      Description: 'GPREMIUM-32012',
      UnitCost: 75.75,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10251600',
      IdentificationNumber: 'UHCK-9425',
      InvoiceCatClaveUnidad: 'GW',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 711,
      invoiceSenderId: 1,
      Description: 'TP-Link ROUTPL890 Router Inalámbrico Dual Band Simultanea Ac1200-867 Mbit/S, 2.4 GHz / 5 GHz, Externo, 4',
      UnitCost: 624.91,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10212622',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XSD',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 712,
      invoiceSenderId: 1,
      Description: 'Telefonia Telefonía Res Ilim Plus Mensualidad Princ',
      UnitCost: 722.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301728',
      IdentificationNumber: 'OXUN-4048',
      InvoiceCatClaveUnidad: 'E25',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 713,
      invoiceSenderId: 1,
      Description: 'Internet Internet Resid. 20 Mbps Mensualidad Princ',
      UnitCost: 176.59,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417923',
      IdentificationNumber: 'QYLW-6630',
      InvoiceCatClaveUnidad: 'E36',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 714,
      invoiceSenderId: 1,
      Description: 'SUBSIDIO TENENCIA',
      UnitCost: 285.45,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10301717',
      IdentificationNumber: 'QQSC-1721',
      InvoiceCatClaveUnidad: 'XAI',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 715,
      invoiceSenderId: 1,
      Description: 'DERECHOS',
      UnitCost: 160.67,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12142101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L94',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 716,
      invoiceSenderId: 1,
      Description: 'TENENCIA',
      UnitCost: 800.46,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217803',
      IdentificationNumber: 'LMKC-1398',
      InvoiceCatClaveUnidad: 'F16',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 717,
      invoiceSenderId: 1,
      Description: 'Cargo de equipo 18 de 24',
      UnitCost: 729.02,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302348',
      IdentificationNumber: 'ULBN-6842',
      InvoiceCatClaveUnidad: 'B32',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 6
    },
    {
      id: 718,
      invoiceSenderId: 1,
      Description: 'N',
      UnitCost: 797.72,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417800',
      IdentificationNumber: 'QHLP-8262',
      InvoiceCatClaveUnidad: 'XFO',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 719,
      invoiceSenderId: 1,
      Description: 'Reembolso de membresía anual Amazon Prime',
      UnitCost: 493.88,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317002',
      IdentificationNumber: 'QNXU-8302',
      InvoiceCatClaveUnidad: 'M67',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 720,
      invoiceSenderId: 1,
      Description: 'Membresía anual Amazon Prime',
      UnitCost: 444.17,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231505',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A62',
      Ieps: null,
      RetIeps: null,
      RetVat: 11, RetIsr: 17
    },
    {
      id: 721,
      invoiceSenderId: 1,
      Description: 'TIZAYUCA',
      UnitCost: 641.4,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10151534',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F77',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 722,
      invoiceSenderId: 1,
      Description: 'SUPER VIA',
      UnitCost: 246.44,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10313702',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '13',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 6
    },
    {
      id: 723,
      invoiceSenderId: 1,
      Description: 'SEGUNDO PISO',
      UnitCost: 446.72,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10222101',
      IdentificationNumber: 'UWYD-4871',
      InvoiceCatClaveUnidad: 'N93',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 35
    },
    {
      id: 724,
      invoiceSenderId: 1,
      Description: 'MARQUESA VIAPASS',
      UnitCost: 952.78,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202234',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XQF',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 725,
      invoiceSenderId: 1,
      Description: 'SILLA ARMABLE RUNDA CON FUNDA',
      UnitCost: 413.68,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12142201',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '40',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 15
    },
    {
      id: 726,
      invoiceSenderId: 1,
      Description: 'ESCRITORIO EN L VELVET',
      UnitCost: 653.54,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10318333',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A34',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 727,
      invoiceSenderId: 1,
      Description: 'LIBRERO CON BASE DE BAMBOO',
      UnitCost: 496.72,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10318013',
      IdentificationNumber: 'KMFP-6870',
      InvoiceCatClaveUnidad: 'B50',
      Ieps: null,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 728,
      invoiceSenderId: 1,
      Description: 'SILLA RECEPCION DOME AZ-BCO',
      UnitCost: 160.93,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10431701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'FB',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 23
    },
    {
      id: 729,
      invoiceSenderId: 1,
      Description: 'Pago de Mensualidad Marzo 2020 Visoor',
      UnitCost: 722.46,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317404',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J62',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 13
    },
    {
      id: 730,
      invoiceSenderId: 1,
      Description: 'SALA MODULAR JAZZ VINIL, NEGRO',
      UnitCost: 519.63,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10213505',
      IdentificationNumber: 'DIFP-4596',
      InvoiceCatClaveUnidad: 'TE',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 731,
      invoiceSenderId: 1,
      Description: 'REGULADOR PARA REFRI KOBLENZ RI 1502',
      UnitCost: 607.81,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10431807',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCY',
      Ieps: null,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 732,
      invoiceSenderId: 1,
      Description: 'CONSUMO RESTAURANTE',
      UnitCost: 796.57,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10215484',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M66',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 733,
      invoiceSenderId: 1,
      Description: 'Internet Internet Resid. 80 Mbps Mensualidad Princ',
      UnitCost: 251.51,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10331707',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L32',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 5
    },
    {
      id: 734,
      invoiceSenderId: 1,
      Description: 'Cargo de equipo 19 de 24',
      UnitCost: 635.27,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10413928',
      IdentificationNumber: 'OMLM-8717',
      InvoiceCatClaveUnidad: 'MMT',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 735,
      invoiceSenderId: 1,
      Description: 'PEAJE EN EFECTIVO CORRESPONDIENTE AL CRUCE DE NRU: 01509100010244480B2E8677A124EAEA FECHA: 29/02/0020 HORA: 10:13:48 CASETA: ZINAPECUARO CLASE: A',
      UnitCost: 234.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10313106',
      IdentificationNumber: 'QJZN-4700',
      InvoiceCatClaveUnidad: 'C6',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 736,
      invoiceSenderId: 1,
      Description: 'PEAJE EN EFECTIVO CORRESPONDIENTE AL CRUCE DE NRU: 0150720001074509C439BDF1C5FA6436 FECHA: 01/03/0020 HORA: 15:43:39 CASETA: ZINAPECUARO CLASE: A',
      UnitCost: 924.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20101702',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N92',
      Ieps: null,
      RetIeps: null,
      RetVat: 8, RetIsr: 32
    },
    {
      id: 737,
      invoiceSenderId: 1,
      Description: 'PEAJE AUTOMOVIL',
      UnitCost: 156.06,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10431910',
      IdentificationNumber: 'IISW-0511',
      InvoiceCatClaveUnidad: 'E52',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 738,
      invoiceSenderId: 1,
      Description: 'G PREMIUM',
      UnitCost: 734.12,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10212810',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M11',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 739,
      invoiceSenderId: 1,
      Description: 'REEMP 1 A O ELECTRICOS',
      UnitCost: 333.45,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12141759',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'OZI',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 740,
      invoiceSenderId: 1,
      Description: 'Cafetera Programable Savoy 12 Tazas',
      UnitCost: 937.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402458',
      IdentificationNumber: 'HNIQ-7839',
      InvoiceCatClaveUnidad: 'K31',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 23
    },
    {
      id: 741,
      invoiceSenderId: 1,
      Description: 'S.REF.14.BS.RT38K5930BS EM TM TWC NoIM',
      UnitCost: 157.86,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10232005',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MIN',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 742,
      invoiceSenderId: 1,
      Description: 'S.LAV.17.AI.NGO.WA17J6730LV.CS',
      UnitCost: 303.76,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10417920',
      IdentificationNumber: 'XFQI-9428',
      InvoiceCatClaveUnidad: 'M65',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 27
    },
    {
      id: 743,
      invoiceSenderId: 1,
      Description: 'ZACAPALCO TAXCO',
      UnitCost: 835.42,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10342100',
      IdentificationNumber: 'VEWW-1757',
      InvoiceCatClaveUnidad: 'NV',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 744,
      invoiceSenderId: 1,
      Description: 'VILLA GUERRERO',
      UnitCost: 384.08,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10318115',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'DAA',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 13
    },
    {
      id: 745,
      invoiceSenderId: 1,
      Description: 'TENANGO',
      UnitCost: 125.21,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10322703',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'INH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 746,
      invoiceSenderId: 1,
      Description: 'RAYON',
      UnitCost: 348.51,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10414111',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H82',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 747,
      invoiceSenderId: 1,
      Description: 'LA VENTA',
      UnitCost: 230.4,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '14111523',
      IdentificationNumber: 'PJRY-9934',
      InvoiceCatClaveUnidad: 'NEW',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 7, RetIsr: 26
    },
    {
      id: 748,
      invoiceSenderId: 1,
      Description: 'LA FINCA',
      UnitCost: 784.73,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12352311',
      IdentificationNumber: 'YVHQ-7406',
      InvoiceCatClaveUnidad: 'XXC',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null, RetIsr: 21
    },
    {
      id: 749,
      invoiceSenderId: 1,
      Description: 'EL DORADO',
      UnitCost: 923.92,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10315701',
      IdentificationNumber: 'SQTU-4915',
      InvoiceCatClaveUnidad: 'GFI',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 750,
      invoiceSenderId: 1,
      Description: 'CONTEPEC',
      UnitCost: 637.39,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402760',
      IdentificationNumber: 'HZJZ-9949',
      InvoiceCatClaveUnidad: 'DQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 5
    },
    {
      id: 751,
      invoiceSenderId: 1,
      Description: 'CHAPULTEPEC',
      UnitCost: 628.9,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10412200',
      IdentificationNumber: 'SMAH-9359',
      InvoiceCatClaveUnidad: 'KEL',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 752,
      invoiceSenderId: 1,
      Description: 'CHAMAPA INBURSA',
      UnitCost: 935.92,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12161805',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'HO',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 753,
      invoiceSenderId: 1,
      Description: 'ATLACOMULCO-BANOBRAS',
      UnitCost: 566.1,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10221802',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F17',
      Ieps: null,
      RetIeps: null,
      RetVat: 9, RetIsr: 31
    },
    {
      id: 754,
      invoiceSenderId: 1,
      Description: 'ATLACOMULCO SUR',
      UnitCost: 273.36,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10311910',
      IdentificationNumber: 'AQDN-2832',
      InvoiceCatClaveUnidad: 'B84',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 755,
      invoiceSenderId: 1,
      Description: 'Consumo del día 25/01/2020',
      UnitCost: 246.89,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10212206',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TI',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 756,
      invoiceSenderId: 1,
      Description: 'SERVICIO PRIVADO DE TRANSPORTE CON CHOFER \N FECHA VIAJE: 2020-01-20 05:20 \N DISTANCIA: 20.33 KM \N TIEMPO: 00:24 (HORAS:MINUTOS)',
      UnitCost: 256.09,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10411507',
      IdentificationNumber: 'FHWA-9483',
      InvoiceCatClaveUnidad: 'XPR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 757,
      invoiceSenderId: 1,
      Description: 'CONTRIBUCIÓN GUBERNAMENTAL',
      UnitCost: 818.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416000',
      IdentificationNumber: 'DHVC-9933',
      InvoiceCatClaveUnidad: 'PO',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 758,
      invoiceSenderId: 1,
      Description: 'RESTAURANTES AL 16%',
      UnitCost: 904.11,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10214903',
      IdentificationNumber: 'LFYP-3393',
      InvoiceCatClaveUnidad: 'E72',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 759,
      invoiceSenderId: 1,
      Description: 'DOBLE WESTERN',
      UnitCost: 46.22,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10414918',
      IdentificationNumber: 'XEPI-6107',
      InvoiceCatClaveUnidad: 'K70',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 24
    },
    {
      id: 760,
      invoiceSenderId: 1,
      Description: 'Cargo de equipo 17 de 24',
      UnitCost: 378.66,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10201961',
      IdentificationNumber: 'ICJS-0727',
      InvoiceCatClaveUnidad: 'XCO',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 761,
      invoiceSenderId: 1,
      Description: 'PS4 DS4 Midnight Blue',
      UnitCost: 647.59,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10211900',
      IdentificationNumber: 'CHGD-6821',
      InvoiceCatClaveUnidad: 'K92',
      Ieps: null,
      RetIeps: null,
      RetVat: 2, RetIsr: 14
    },
    {
      id: 762,
      invoiceSenderId: 1,
      Description: 'REVISION DE A/C A UNIDAD 374, PLACAS 856 ES 9. CARGA DE GAS REFRIGERANTE DE A/C',
      UnitCost: 655.52,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10421901',
      IdentificationNumber: 'XYIB-3712',
      InvoiceCatClaveUnidad: 'N73',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 763,
      invoiceSenderId: 1,
      Description: 'MEDIACIÓN MERCANTIL',
      UnitCost: 880.63,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10324703',
      IdentificationNumber: 'ANGN-6601',
      InvoiceCatClaveUnidad: 'J75',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 764,
      invoiceSenderId: 1,
      Description: 'ZINAPECUARO',
      UnitCost: 36.08,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11141607',
      IdentificationNumber: 'CMJJ-0096',
      InvoiceCatClaveUnidad: 'A51',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 765,
      invoiceSenderId: 1,
      Description: 'XONACATLAN',
      UnitCost: 379.82,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10232031',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XYH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 766,
      invoiceSenderId: 1,
      Description: 'MENDOZA',
      UnitCost: 373.04,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10213002',
      IdentificationNumber: 'IBFD-7087',
      InvoiceCatClaveUnidad: 'G04',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 767,
      invoiceSenderId: 1,
      Description: 'CERRO GORDO',
      UnitCost: 988.57,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10414825',
      IdentificationNumber: 'PATK-4834',
      InvoiceCatClaveUnidad: '45',
      Ieps: null,
      RetIeps: null,
      RetVat: 10,
      RetIsr: null
    },
    {
      id: 768,
      invoiceSenderId: 1,
      Description: 'APASEO EL GRANDE',
      UnitCost: 842.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202327',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 769,
      invoiceSenderId: 1,
      Description: 'Consultoría en tecnologías de la información',
      UnitCost: 495.41,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10326056',
      IdentificationNumber: 'UXOU-1692',
      InvoiceCatClaveUnidad: 'FOT',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 770,
      invoiceSenderId: 1,
      Description: 'FUSIBLE',
      UnitCost: 996.77,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417937',
      IdentificationNumber: 'TPTL-7432',
      InvoiceCatClaveUnidad: 'E80',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 771,
      invoiceSenderId: 1,
      Description: 'REPARACION DE FALSOS CONTACTOS',
      UnitCost: 956.68,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10422701',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'HKM',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 772,
      invoiceSenderId: 1,
      Description: 'REVISION DE EQUIPO DE REFRIGERACION TAC-32 SB330, PLACAS 4MC 54 67. LAVADO EXTREMO DE PANAL DE CONDENSADOR',
      UnitCost: 651.03,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317935',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XGI',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 773,
      invoiceSenderId: 1,
      Description: 'LAVADO DE SISTEMA 141-B',
      UnitCost: 54.34,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10212636',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E33',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 12
    },
    {
      id: 774,
      invoiceSenderId: 1,
      Description: 'COMPRESOR INTERNATIONAL',
      UnitCost: 211.85,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12352001',
      IdentificationNumber: 'TFHG-4513',
      InvoiceCatClaveUnidad: 'F25',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 775,
      invoiceSenderId: 1,
      Description: 'VALVULA DE EXPANSION DE A/C',
      UnitCost: 914.78,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10316705',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N88',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 776,
      invoiceSenderId: 1,
      Description: 'REPARACION DE A/C A UNIDAD 259 INTERNATIONAL. PLACAS 942 EW 8. FILTRO DESHIDRATADOR DE A/C INTERNATIONAL',
      UnitCost: 968.54,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10361904',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'T4',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 777,
      invoiceSenderId: 1,
      Description: 'Servicio Topicflower - Nota de crédito por A 485',
      UnitCost: 633.24,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10151807',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B27',
      Ieps: 0.060,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 778,
      invoiceSenderId: 1,
      Description: 'PLANET FGSD-1022VHP Switch PoE+ / distancia 250 metros / 8 puertos + 2 combo TP/SFP gigabit y pantalla LCD para monitoreo',
      UnitCost: 992.14,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302326',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q39',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 779,
      invoiceSenderId: 1,
      Description: 'Conector Jack Estilo 110 (de Impacto), Tipo Keystone, Categoría 6, de 8 posiciones y 8 cables, Color Negro',
      UnitCost: 712.91,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '15121528',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F55',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 780,
      invoiceSenderId: 1,
      Description: 'Panel de Parcheo Modular Keystone (Sin Conectores), de 24 Puertos, Identificación con Etiqueta Adhesiva, 1UR',
      UnitCost: 824.39,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10417704',
      IdentificationNumber: 'YRTF-1667',
      InvoiceCatClaveUnidad: 'D2',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 781,
      invoiceSenderId: 1,
      Description: 'Cable de parcheo UTP Categoría 6, con plug modular en cada extremo - 1 m. - Negro',
      UnitCost: 775.6,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10414702',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KUR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 782,
      invoiceSenderId: 1,
      Description: 'Instalación cámara. Incluye ductería, cableado, mano de obra, conectores y configuración.(Polígono Torre F)',
      UnitCost: 485.91,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11181501',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '3G',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 783,
      invoiceSenderId: 1,
      Description: 'Instalación y configuración de equipos de red Incluye montaje y gabinete. (Polígono Torre F)',
      UnitCost: 876.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10314903',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCL',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 784,
      invoiceSenderId: 1,
      Description: 'Instalación y configuración de NVR Incluye accesorios. (Polígono Torre F)',
      UnitCost: 600.65,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '11172000',
      IdentificationNumber: 'WQLL-7538',
      InvoiceCatClaveUnidad: 'XAT',
      Ieps: null,
      RetIeps: null,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 785,
      invoiceSenderId: 1,
      Description: 'Gabinete para Montaje en Pared, Puerta de Cristal, Cuerpo Fijo con Rack , de 6 Unidades',
      UnitCost: 397.07,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10411705',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XAA',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 786,
      invoiceSenderId: 1,
      Description: 'Disco duro WD de 1TB / 5400RPM / Optimizado para Videovigilancia',
      UnitCost: 827.74,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202718',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWB',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 4, RetIsr: 1
    },
    {
      id: 787,
      invoiceSenderId: 1,
      Description: 'Domo IP 1 Megapixel / 30 mts IR Inteligente / dWDR / IP67 / IK10 / Hik- Connect P2P / PoE / ONVIF',
      UnitCost: 904.2,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10214503',
      IdentificationNumber: 'DFNB-6163',
      InvoiceCatClaveUnidad: 'XCV',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 788,
      invoiceSenderId: 1,
      Description: 'NVR 4 Megapixel / 8 Canales IP / 8 Puertos PoE+ / 1 Bahía de Disco Duro / Salida en Full HD',
      UnitCost: 618.6,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121407',
      IdentificationNumber: 'ZDHH-3222',
      InvoiceCatClaveUnidad: 'H03',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 789,
      invoiceSenderId: 1,
      Description: '14497 WHITENER POWDER 500G LOTE: MD0A02-013 O.C. 50041783',
      UnitCost: 281.12,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402164',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C93',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 790,
      invoiceSenderId: 1,
      Description: 'NRU 7EUJ5M4R813U',
      UnitCost: 769.49,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10441512',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B61',
      Ieps: null,
      RetIeps: null,
      RetVat: 6, RetIsr: 21
    },
    {
      id: 791,
      invoiceSenderId: 1,
      Description: 'NRU BBACM64R827A',
      UnitCost: 620.99,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10226201',
      IdentificationNumber: 'DNEK-8878',
      InvoiceCatClaveUnidad: 'ON',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 792,
      invoiceSenderId: 1,
      Description: 'NRU PVN9LKIR8217',
      UnitCost: 441.92,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10418343',
      IdentificationNumber: 'MMMV-2213',
      InvoiceCatClaveUnidad: 'G70',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 793,
      invoiceSenderId: 1,
      Description: 'NRU U55LI94A81N1',
      UnitCost: 249.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10201912',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E84',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 794,
      invoiceSenderId: 1,
      Description: 'NRU LQAN2TTA81JM',
      UnitCost: 994.48,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10362060',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D38',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: 6,
      RetIsr: null
    },
    {
      id: 795,
      invoiceSenderId: 1,
      Description: 'NRU TL749WC2827L',
      UnitCost: 253.96,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '13101503',
      IdentificationNumber: 'MPDO-5645',
      InvoiceCatClaveUnidad: 'F35',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 25
    },
    {
      id: 796,
      invoiceSenderId: 1,
      Description: 'NRU SF6BM4T2827N',
      UnitCost: 472.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402838',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P17',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 797,
      invoiceSenderId: 1,
      Description: 'Cable de parcheo UTP Categoría 6, con plug modular en cada extremo - 1. - Negro',
      UnitCost: 857.99,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12164605',
      IdentificationNumber: 'RSQU-0651',
      InvoiceCatClaveUnidad: 'K39',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 13
    },
    {
      id: 798,
      invoiceSenderId: 1,
      Description: 'Instalación cámara. Incluye ductería, cableado, mano de obra, conectores y configuración.(Polígono Torre D)',
      UnitCost: 608.07,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402734',
      IdentificationNumber: 'CVRH-5686',
      InvoiceCatClaveUnidad: 'P51',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 3
    },
    {
      id: 799,
      invoiceSenderId: 1,
      Description: 'Instalación y configuración de equipos de red. Incluye montaje y gabinete. (Polígono Torre D)',
      UnitCost: 247.87,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10422201',
      IdentificationNumber: 'USSP-4426',
      InvoiceCatClaveUnidad: 'Z5',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 800,
      invoiceSenderId: 1,
      Description: 'Instalación y configuración de NVR. Incluye accesorios. (Polígono Torre D)',
      UnitCost: 244.04,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10218342',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L38',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 801,
      invoiceSenderId: 1,
      Description: 'Servicio Topicflower - Nota de crédito por A 467',
      UnitCost: 267.49,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10301739',
      IdentificationNumber: 'MWSO-1664',
      InvoiceCatClaveUnidad: 'SX',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 19
    },
    {
      id: 802,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:0129910002864112590FD38201CCAA08',
      UnitCost: 821.63,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11121605',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'M75',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 803,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:01303200023607801AFADB450624CF1B',
      UnitCost: 962.08,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12162502',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G67',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 804,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:012991000288268340B2B2E53D3F6257',
      UnitCost: 783.35,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10414101',
      IdentificationNumber: 'HEYF-9813',
      InvoiceCatClaveUnidad: 'K11',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 805,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:0130420002570913C07117B2BF94D7BE',
      UnitCost: 826.42,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302358',
      IdentificationNumber: 'TPAZ-1421',
      InvoiceCatClaveUnidad: 'XBE',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 806,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:0129910002607080C4889EE8FF243548',
      UnitCost: 618.44,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10211706',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D72',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 807,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:0130320002150292A958F67056E158EF',
      UnitCost: 978.66,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10325302',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '1F',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 808,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:013001000236373335A3D5B06D65E0ED',
      UnitCost: 434.64,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318207',
      IdentificationNumber: 'FSBM-9550',
      InvoiceCatClaveUnidad: 'E69',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 809,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:0130420002613727D810D9375ED18FDB',
      UnitCost: 800.99,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10322500',
      IdentificationNumber: 'WHYP-2866',
      InvoiceCatClaveUnidad: 'S5',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 12
    },
    {
      id: 810,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:0129910002705978224F166140600588',
      UnitCost: 995.97,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10316700',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q13',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 811,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:01299100027201404BAE4FB811A45329',
      UnitCost: 15.12,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302127',
      IdentificationNumber: 'GDOL-6531',
      InvoiceCatClaveUnidad: 'F61',
      Ieps: null,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 812,
      invoiceSenderId: 1,
      Description: 'PEAJES LIB. LA PIEDAD NRU:01303200021918648D5BCED3F350ABED',
      UnitCost: 695.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416216',
      IdentificationNumber: 'UTUE-9641',
      InvoiceCatClaveUnidad: 'K74',
      Ieps: null,
      RetIeps: null,
      RetVat: 8, RetIsr: 29
    },
    {
      id: 813,
      invoiceSenderId: 1,
      Description: 'MENSUALIDAD BECADOS',
      UnitCost: 981.07,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10322401',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A76',
      Ieps: null,
      RetIeps: null,
      RetVat: 9, RetIsr: 32
    },
    {
      id: 814,
      invoiceSenderId: 1,
      Description: 'MENSUALIDAD COMPLETA',
      UnitCost: 852.73,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10215506',
      IdentificationNumber: 'GUBM-6683',
      InvoiceCatClaveUnidad: 'M83',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 815,
      invoiceSenderId: 1,
      Description: 'INSCRIPCIONES COMPLETAS',
      UnitCost: 87.92,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202882',
      IdentificationNumber: 'PQVE-3155',
      InvoiceCatClaveUnidad: 'P14',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 19
    },
    {
      id: 816,
      invoiceSenderId: 1,
      Description: 'ESTACIONAMIENTO FUT 7',
      UnitCost: 397.54,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301707',
      IdentificationNumber: 'IISZ-2534',
      InvoiceCatClaveUnidad: 'K20',
      Ieps: null,
      RetIeps: null,
      RetVat: 9, RetIsr: 3
    },
    {
      id: 817,
      invoiceSenderId: 1,
      Description: 'RENTA DE CANCHA 3',
      UnitCost: 759.82,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10311709',
      IdentificationNumber: 'ZGBK-9731',
      InvoiceCatClaveUnidad: 'G46',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 818,
      invoiceSenderId: 1,
      Description: 'RENTA DE CANCHA 2',
      UnitCost: 979.45,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10221502',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'T8',
      Ieps: null,
      RetIeps: 1.600,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 819,
      invoiceSenderId: 1,
      Description: 'RENTA DE CANCHA',
      UnitCost: 941.66,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10212401',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C16',
      Ieps: 0.030,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 820,
      invoiceSenderId: 1,
      Description: 'QUINTUPACK UNIFORME CIX JOVEN XXL',
      UnitCost: 763.53,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10252056',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B99',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 821,
      invoiceSenderId: 1,
      Description: 'PALCO',
      UnitCost: 154.41,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12161600',
      IdentificationNumber: 'FQDM-8647',
      InvoiceCatClaveUnidad: '4C',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 7, RetIsr: 27
    },
    {
      id: 822,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY CL20-AP20 STAR WARS',
      UnitCost: 209.15,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317337',
      IdentificationNumber: 'PAOG-7330',
      InvoiceCatClaveUnidad: 'C67',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 823,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20',
      UnitCost: 146.24,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301744',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'ASM',
      Ieps: null,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 824,
      invoiceSenderId: 1,
      Description: 'QUINTUPACK UNIFORME CIX JOVEN XL',
      UnitCost: 857.48,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10401725',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCA',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 825,
      invoiceSenderId: 1,
      Description: 'MENSUALIDAD CON RECARGO',
      UnitCost: 74.09,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10452046',
      IdentificationNumber: 'VGAB-6910',
      InvoiceCatClaveUnidad: 'XKG',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 826,
      invoiceSenderId: 1,
      Description: 'ABONADOS SEMESTRALES CORNER PONIENTE BAJO',
      UnitCost: 581.05,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10426045',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XPR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 827,
      invoiceSenderId: 1,
      Description: 'BOLETOS ZONA CORNER PONIENTE ALTO JORNADA 5',
      UnitCost: 308.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402313',
      IdentificationNumber: 'UJKT-8902',
      InvoiceCatClaveUnidad: 'BB',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 828,
      invoiceSenderId: 1,
      Description: 'CHAMARRA GETC DGTJ161M DGTJ161B CAMO',
      UnitCost: 365.12,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317827',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H64',
      Ieps: null,
      RetIeps: null,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 829,
      invoiceSenderId: 1,
      Description: 'CHAMARRA CHARLY 5000174 WINTER',
      UnitCost: 465.67,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13111301',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F15',
      Ieps: null,
      RetIeps: null,
      RetVat: 16, RetIsr: 6
    },
    {
      id: 830,
      invoiceSenderId: 1,
      Description: 'PLAYERA GETC MESH DG NEGRA HOMBRE S',
      UnitCost: 258.95,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10314803',
      IdentificationNumber: 'TGVY-9116',
      InvoiceCatClaveUnidad: 'B17',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 9
    },
    {
      id: 831,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO HOMBRE XL',
      UnitCost: 814.57,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '20122203',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'RH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 832,
      invoiceSenderId: 1,
      Description: 'LLAVERO PLA EROS LOGO ROJO',
      UnitCost: 73.25,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10412607',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MTR',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 833,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO HOMBRE S',
      UnitCost: 140.6,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10431504',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'L26',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 834,
      invoiceSenderId: 1,
      Description: 'GORRA NEW ERA 5950 CIUDAD',
      UnitCost: 223.31,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122001',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P53',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 835,
      invoiceSenderId: 1,
      Description: 'PLAYERA CHARLY 5009465 ENTRENAMIENTO',
      UnitCost: 975.5,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20122109',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C41',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 836,
      invoiceSenderId: 1,
      Description: 'POLO GETC MESH MXTMP485 NEGRO MUJER L',
      UnitCost: 482.52,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10402744',
      IdentificationNumber: 'ZIYH-1357',
      InvoiceCatClaveUnidad: 'A21',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 837,
      invoiceSenderId: 1,
      Description: 'PLATEA',
      UnitCost: 534.03,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202365',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G11',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 838,
      invoiceSenderId: 1,
      Description: 'PINTACARAS',
      UnitCost: 915.16,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10426050',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N68',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 839,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP18-CL19',
      UnitCost: 187.47,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10426026',
      IdentificationNumber: 'XRSY-4172',
      InvoiceCatClaveUnidad: 'XYD',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 840,
      invoiceSenderId: 1,
      Description: 'CONJUNTO PANTS GETC DGST177M',
      UnitCost: 405.44,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10326081',
      IdentificationNumber: 'KMYK-9453',
      InvoiceCatClaveUnidad: 'KBA',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 25
    },
    {
      id: 841,
      invoiceSenderId: 1,
      Description: 'POLO CHARLY 5007774 PIQUE',
      UnitCost: 873.55,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302377',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F60',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 2
    },
    {
      id: 842,
      invoiceSenderId: 1,
      Description: 'GORRA NE 950 XOLOS 17 LOGO CALACA CAMO',
      UnitCost: 982.08,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '11181500',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WSD',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 843,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO MUJER L',
      UnitCost: 841.87,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202438',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'UB',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 844,
      invoiceSenderId: 1,
      Description: 'CHAMARRA CHARLY 5000175 HOODIE',
      UnitCost: 60.14,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11162110',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q31',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 845,
      invoiceSenderId: 1,
      Description: 'VIP',
      UnitCost: 689.68,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101507',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B83',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 846,
      invoiceSenderId: 1,
      Description: 'PATROCINIO',
      UnitCost: 865.59,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10425300',
      IdentificationNumber: 'TYMK-4462',
      InvoiceCatClaveUnidad: 'M89',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 847,
      invoiceSenderId: 1,
      Description: 'MENSUALIDAD',
      UnitCost: 440.05,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '12164801',
      IdentificationNumber: 'TFYG-5255',
      InvoiceCatClaveUnidad: 'H74',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 848,
      invoiceSenderId: 1,
      Description: 'QUINTUPACK UNIFORME CIX JOVEN L',
      UnitCost: 94.63,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10215430',
      IdentificationNumber: 'PVHJ-0193',
      InvoiceCatClaveUnidad: 'K28',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 849,
      invoiceSenderId: 1,
      Description: 'QUINTUPACK UNIFORME CIX CHICO',
      UnitCost: 702.47,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10314903',
      IdentificationNumber: 'MIYG-3279',
      InvoiceCatClaveUnidad: 'CMT',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 850,
      invoiceSenderId: 1,
      Description: 'PAGO DE ARBITRAJE',
      UnitCost: 114.88,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301517',
      IdentificationNumber: 'TZIU-1104',
      InvoiceCatClaveUnidad: 'XTU',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 851,
      invoiceSenderId: 1,
      Description: 'REINSCRIPCION',
      UnitCost: 879.17,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10314004',
      IdentificationNumber: 'RXER-0432',
      InvoiceCatClaveUnidad: 'N43',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 852,
      invoiceSenderId: 1,
      Description: 'CONCESION ESTADIO CALIENTE',
      UnitCost: 464.51,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218009',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'DAY',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 853,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 2 (Renovacion)',
      UnitCost: 650.57,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402456',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'P65',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: null, RetIsr: 29
    },
    {
      id: 854,
      invoiceSenderId: 1,
      Description: 'PERSONALIZACION 79',
      UnitCost: 831.85,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10317329',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B56',
      Ieps: 0.000,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 855,
      invoiceSenderId: 1,
      Description: 'AUTOMOVIL VERSA SENSE CVT COLOR AZUL MODELO 2020 MARCA NISSAN VIN 3N1CN8AE2LL855171',
      UnitCost: 602.66,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10326007',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'UF',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 856,
      invoiceSenderId: 1,
      Description: 'ABONADO TRIBUNA VIP',
      UnitCost: 543.24,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202028',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D81',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 857,
      invoiceSenderId: 1,
      Description: 'ABONADOS TRIBUNA VIP',
      UnitCost: 458.26,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '14122107',
      IdentificationNumber: 'WYML-1919',
      InvoiceCatClaveUnidad: 'MAW',
      Ieps: 0.070,
      RetIeps: 0.070,
      RetVat: null, RetIsr: 23
    },
    {
      id: 858,
      invoiceSenderId: 1,
      Description: 'Reposicion XoloPass',
      UnitCost: 700.38,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12142003',
      IdentificationNumber: 'JNOR-8395',
      InvoiceCatClaveUnidad: 'K41',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 859,
      invoiceSenderId: 1,
      Description: 'CIEL',
      UnitCost: 84.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10231925',
      IdentificationNumber: 'IKMR-2334',
      InvoiceCatClaveUnidad: 'Q16',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 860,
      invoiceSenderId: 1,
      Description: 'FANTA',
      UnitCost: 147.04,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10411515',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Q13',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 861,
      invoiceSenderId: 1,
      Description: 'MANZANA',
      UnitCost: 364.94,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10311512',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K80',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 862,
      invoiceSenderId: 1,
      Description: 'COCA- COLA',
      UnitCost: 826.77,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318105',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G28',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 35
    },
    {
      id: 863,
      invoiceSenderId: 1,
      Description: 'CERVEZA BUDWEISER',
      UnitCost: 593.49,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10415101',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KNS',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 34
    },
    {
      id: 864,
      invoiceSenderId: 1,
      Description: 'CERVEZA BUDLIGHT',
      UnitCost: 265.84,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10416908',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '2V',
      Ieps: 0.030,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 3
    },
    {
      id: 865,
      invoiceSenderId: 1,
      Description: 'IMPERMEABLE PLA LUCCA LOGO ROJO',
      UnitCost: 900.76,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10431620',
      IdentificationNumber: 'EGLV-2980',
      InvoiceCatClaveUnidad: 'J10',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 866,
      invoiceSenderId: 1,
      Description: 'MOCHILA CHARLY 8066949 LOGO NEGRO',
      UnitCost: 964.7,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10252103',
      IdentificationNumber: 'AWMD-1444',
      InvoiceCatClaveUnidad: 'P81',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 867,
      invoiceSenderId: 1,
      Description: 'CHALECO GETC DGVT084M',
      UnitCost: 817.23,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10214803',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '54',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 868,
      invoiceSenderId: 1,
      Description: 'BALON SMH XTSB066S4 LOGO 4',
      UnitCost: 759.8,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10414403',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N53',
      Ieps: null,
      RetIeps: 0.250,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 869,
      invoiceSenderId: 1,
      Description: 'CONJUNTO PANTS CHARLY 5055726 CONCENTRA',
      UnitCost: 149.46,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10212619',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N47',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 870,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 CAMO LOGO',
      UnitCost: 468.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10431917',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XCP',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 34
    },
    {
      id: 871,
      invoiceSenderId: 1,
      Description: 'BALON SMH XTSB065S5 LOGO 5',
      UnitCost: 301.07,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402620',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'WG',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 872,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 BASICA LOGO NEGRO 7 3/8',
      UnitCost: 474.09,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10211512',
      IdentificationNumber: 'RJHB-9678',
      InvoiceCatClaveUnidad: 'H84',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 873,
      invoiceSenderId: 1,
      Description: 'SHORT CHARLY 5027877 CON LYCRA',
      UnitCost: 142.55,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10452404',
      IdentificationNumber: 'STTS-0171',
      InvoiceCatClaveUnidad: 'E93',
      Ieps: null,
      RetIeps: null,
      RetVat: 13, RetIsr: 34
    },
    {
      id: 874,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO HOMBRE XXL',
      UnitCost: 985.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417304',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J26',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 875,
      invoiceSenderId: 1,
      Description: 'POLO CHARLY 5007775 PIQUE',
      UnitCost: 602.76,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10216200',
      IdentificationNumber: 'CEYY-6983',
      InvoiceCatClaveUnidad: 'X4H',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 12
    },
    {
      id: 876,
      invoiceSenderId: 1,
      Description: 'GORRA NE 950 CAMO LOGO BLACK ON BLACK',
      UnitCost: 102.44,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10431917',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D46',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 877,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 RETRO NEGRA X ROJA S/M',
      UnitCost: 488.34,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10201937',
      IdentificationNumber: 'TCHM-4490',
      InvoiceCatClaveUnidad: 'STI',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 878,
      invoiceSenderId: 1,
      Description: 'GORRA NE 940 LOGO NEGRO NINO',
      UnitCost: 364.68,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20122006',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N83',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 879,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO HOMBRE M',
      UnitCost: 430.15,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10452028',
      IdentificationNumber: 'EGQE-2488',
      InvoiceCatClaveUnidad: 'P95',
      Ieps: null,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 880,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO MUJER S',
      UnitCost: 701.29,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10312611',
      IdentificationNumber: 'IPJX-7242',
      InvoiceCatClaveUnidad: 'KIP',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 881,
      invoiceSenderId: 1,
      Description: 'GORRA NEW ERA 5950 XOLOITZCUINTLES',
      UnitCost: 660.95,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '14122202',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B32',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 882,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 BASICA LOGO BLACK ON BLACK',
      UnitCost: 40.92,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10431810',
      IdentificationNumber: 'DWID-0580',
      InvoiceCatClaveUnidad: 'C35',
      Ieps: 0.000,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 883,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO HOMBRE L',
      UnitCost: 414.18,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302449',
      IdentificationNumber: 'XUCR-3934',
      InvoiceCatClaveUnidad: 'F57',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 24
    },
    {
      id: 884,
      invoiceSenderId: 1,
      Description: 'PIN LOGO XOLOS CHICO',
      UnitCost: 618.42,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218114',
      IdentificationNumber: 'CGNQ-6640',
      InvoiceCatClaveUnidad: 'P63',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 22
    },
    {
      id: 885,
      invoiceSenderId: 1,
      Description: 'LLAVERO PLA BALISTIC ROJO',
      UnitCost: 744.65,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10302847',
      IdentificationNumber: 'YNQF-5588',
      InvoiceCatClaveUnidad: 'DMQ',
      Ieps: null,
      RetIeps: null,
      RetVat: 14, RetIsr: 18
    },
    {
      id: 886,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 LOGO SCRL ROJINEGRO S',
      UnitCost: 709.96,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10217821',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D03',
      Ieps: null,
      RetIeps: null,
      RetVat: 11, RetIsr: 2
    },
    {
      id: 887,
      invoiceSenderId: 1,
      Description: 'PULSERA TIJUANA X PLASTICO',
      UnitCost: 119.34,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10214818',
      IdentificationNumber: 'HLOA-1503',
      InvoiceCatClaveUnidad: 'K49',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 888,
      invoiceSenderId: 1,
      Description: 'TENIS CHARLY 1029606 TURF XOLOS',
      UnitCost: 514.36,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202458',
      IdentificationNumber: 'UEOV-0374',
      InvoiceCatClaveUnidad: 'J55',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null, RetIsr: 20
    },
    {
      id: 889,
      invoiceSenderId: 1,
      Description: 'GORRA NE 950 XOLOS 15 LOGO CALACA',
      UnitCost: 201.2,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10301940',
      IdentificationNumber: 'NFWJ-7358',
      InvoiceCatClaveUnidad: 'LA',
      Ieps: 0.030,
      RetIeps: 0.250,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 890,
      invoiceSenderId: 1,
      Description: 'CHAMARRA CHARLY 5016046 FLEECE',
      UnitCost: 941.21,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10413912',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XSK',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 891,
      invoiceSenderId: 1,
      Description: 'CHAMARRA CHARLY 5016049 ROMPEVIENTOS',
      UnitCost: 304.72,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10502007',
      IdentificationNumber: 'EWMS-6421',
      InvoiceCatClaveUnidad: 'MTS',
      Ieps: 0.030,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 892,
      invoiceSenderId: 1,
      Description: 'PANALERO GETC BXTPN495 ROJO BEBE 24M',
      UnitCost: 858.36,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10317951',
      IdentificationNumber: 'QGEV-1267',
      InvoiceCatClaveUnidad: 'XDY',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 893,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO MUJER M',
      UnitCost: 542.11,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10452011',
      IdentificationNumber: 'ERPY-5168',
      InvoiceCatClaveUnidad: 'H62',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 894,
      invoiceSenderId: 1,
      Description: 'CONJUNTO PANTS CHARLY 5055727 ENTRENA',
      UnitCost: 933.86,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10218116',
      IdentificationNumber: 'XUWW-7994',
      InvoiceCatClaveUnidad: 'XKI',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 18
    },
    {
      id: 895,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 RETRO LOGO NEGRO 7 1/8',
      UnitCost: 704.89,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10218117',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'UF',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 6
    },
    {
      id: 896,
      invoiceSenderId: 1,
      Description: 'CALZADO CHARLY 1029606 TURF XOLO',
      UnitCost: 260.09,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202857',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '93',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 897,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 CAMO LOGO',
      UnitCost: 929.66,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10313806',
      IdentificationNumber: 'DEJA-0538',
      InvoiceCatClaveUnidad: 'XPE',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 898,
      invoiceSenderId: 1,
      Description: 'GORRA NE 920 BASICA LOGO BLACK ON BLACK',
      UnitCost: 490.67,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302328',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K84',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 899,
      invoiceSenderId: 1,
      Description: 'PERSONALIZACION ROJA 79',
      UnitCost: 622.57,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '11101625',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K5',
      Ieps: null,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 900,
      invoiceSenderId: 1,
      Description: 'PLAYERA CHARLY CONCENTRACION LINEA ROJA',
      UnitCost: 261.98,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10301809',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MIU',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 901,
      invoiceSenderId: 1,
      Description: 'CHALECO GETC DGVT165M HOODIE',
      UnitCost: 21.35,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302201',
      IdentificationNumber: 'TDIA-6768',
      InvoiceCatClaveUnidad: '37',
      Ieps: null,
      RetIeps: null,
      RetVat: 7, RetIsr: 7
    },
    {
      id: 902,
      invoiceSenderId: 1,
      Description: 'CHAMARRA GETC DGPJ168M DGPJ168B HOODIE',
      UnitCost: 388.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10232036',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A3',
      Ieps: null,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 903,
      invoiceSenderId: 1,
      Description: 'GORRA NE 950 XOLOS 13 LOGO CALACA',
      UnitCost: 665.9,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10226010',
      IdentificationNumber: 'TZUQ-4005',
      InvoiceCatClaveUnidad: 'KJ',
      Ieps: null,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 904,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 RETRO LOGO NEGRO 7 3/8',
      UnitCost: 90.26,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217347',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D61',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 905,
      invoiceSenderId: 1,
      Description: 'SHORT CHARLY 5027878 ENTRENAMIENTO',
      UnitCost: 447.46,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302144',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K53',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 7,
      RetIsr: null
    },
    {
      id: 906,
      invoiceSenderId: 1,
      Description: 'CHAMARRA GETC DGPJ169M DGPJ169B',
      UnitCost: 58.53,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10216104',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'X1G',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 907,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 LOGO ROJO HEXTECH',
      UnitCost: 361.3,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10213802',
      IdentificationNumber: 'DJTO-6837',
      InvoiceCatClaveUnidad: 'M21',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 908,
      invoiceSenderId: 1,
      Description: 'CHALECO GETC DGVT090L',
      UnitCost: 687.97,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11131501',
      IdentificationNumber: 'MPXH-6980',
      InvoiceCatClaveUnidad: 'A55',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 909,
      invoiceSenderId: 1,
      Description: 'BUFANDA SMH XTSC067A FUERZA TIJUAS',
      UnitCost: 572.54,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10417920',
      IdentificationNumber: 'XKCF-8783',
      InvoiceCatClaveUnidad: 'J23',
      Ieps: null,
      RetIeps: null,
      RetVat: 3, RetIsr: 31
    },
    {
      id: 910,
      invoiceSenderId: 1,
      Description: 'GORRA NE 940 RETRO FELT X BLANCO',
      UnitCost: 272.65,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10224502',
      IdentificationNumber: 'SNBZ-8383',
      InvoiceCatClaveUnidad: 'XDB',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 911,
      invoiceSenderId: 1,
      Description: 'CHAMARRA GETC DGPJ170M DGPJ170B',
      UnitCost: 220.46,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '21101522',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'D14',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 24
    },
    {
      id: 912,
      invoiceSenderId: 1,
      Description: 'CORREA TIJUANA X CLUB TIJUANA CABEZAS',
      UnitCost: 722.8,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10401943',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E79',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 8
    },
    {
      id: 913,
      invoiceSenderId: 1,
      Description: 'BUFANDA FANATIKER XOLOS 90',
      UnitCost: 884.39,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10313201',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E34',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 914,
      invoiceSenderId: 1,
      Description: 'EXHIBICION ANUNCIOS PRO ONE',
      UnitCost: 615.93,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '15121903',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B79',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 915,
      invoiceSenderId: 1,
      Description: 'MOCHILA CHARLY 8066959 LOGO ROJO',
      UnitCost: 696.15,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10316413',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XZM',
      Ieps: null,
      RetIeps: null,
      RetVat: 3, RetIsr: 23
    },
    {
      id: 916,
      invoiceSenderId: 1,
      Description: 'PELOTA PLA ANTIESTRES LOGO',
      UnitCost: 578.95,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10226080',
      IdentificationNumber: 'EAVU-9549',
      InvoiceCatClaveUnidad: 'E65',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 917,
      invoiceSenderId: 1,
      Description: 'TERMO PLA PRESTON LOGO ROJO 480 ML',
      UnitCost: 868.33,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10431804',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XMW',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 918,
      invoiceSenderId: 1,
      Description: 'VASO PLA BACHELOR POPOTE LOGO ROJO',
      UnitCost: 989.08,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10324402',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '4K',
      Ieps: null,
      RetIeps: null,
      RetVat: 8, RetIsr: 32
    },
    {
      id: 919,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 LOGO SCRL VICERA ROJA S',
      UnitCost: 611.26,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417700',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N76',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 920,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 BASICA LOGO NEGRO 7 3/4',
      UnitCost: 112.94,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10213915',
      IdentificationNumber: 'ZXFM-4696',
      InvoiceCatClaveUnidad: 'XZM',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 921,
      invoiceSenderId: 1,
      Description: 'CHALECO SMH XTVT047M,XTVT047B,XTVT056L',
      UnitCost: 573.77,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10452101',
      IdentificationNumber: 'FWIM-8081',
      InvoiceCatClaveUnidad: 'A78',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 922,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 BASICA LOGO L/XL',
      UnitCost: 859.86,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302447',
      IdentificationNumber: 'AHKQ-4551',
      InvoiceCatClaveUnidad: 'H82',
      Ieps: 0.080,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 923,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 BASICA LOGO BLACK ON BLACK',
      UnitCost: 188.18,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10312646',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'QTR',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 11
    },
    {
      id: 924,
      invoiceSenderId: 1,
      Description: 'CHALECO GETC DGVT087M CAMO',
      UnitCost: 567.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10401915',
      IdentificationNumber: 'INDJ-1123',
      InvoiceCatClaveUnidad: 'X44',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 16
    },
    {
      id: 925,
      invoiceSenderId: 1,
      Description: 'XOLOMONQUI SUPER XOLO MAYOR',
      UnitCost: 518.97,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202472',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G43',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 926,
      invoiceSenderId: 1,
      Description: 'GORRA NE 5950 BASICA LOGO NEGRO 7 1/4',
      UnitCost: 298.95,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12352201',
      IdentificationNumber: 'SQXR-2343',
      InvoiceCatClaveUnidad: 'G62',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 33
    },
    {
      id: 927,
      invoiceSenderId: 1,
      Description: 'LENTES BIGBOSS LA JAURIA',
      UnitCost: 119.3,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10152202',
      IdentificationNumber: 'NDLN-4067',
      InvoiceCatClaveUnidad: 'J55',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 928,
      invoiceSenderId: 1,
      Description: 'CHAMARRA CHARLY 5000176 PULL OVER',
      UnitCost: 886.97,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402765',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KPO',
      Ieps: null,
      RetIeps: null,
      RetVat: 14,
      RetIsr: null
    },
    {
      id: 929,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO MUJER XL',
      UnitCost: 547.82,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '14111705',
      IdentificationNumber: 'NDYA-8552',
      InvoiceCatClaveUnidad: '1H',
      Ieps: null,
      RetIeps: null,
      RetVat: 13,
      RetIsr: null
    },
    {
      id: 930,
      invoiceSenderId: 1,
      Description: 'GORRA NE 920 RETRO ROJA X NEGRO',
      UnitCost: 45.94,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121116',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E66',
      Ieps: 0.090,
      RetIeps: null,
      RetVat: 5,
      RetIsr: null
    },
    {
      id: 931,
      invoiceSenderId: 1,
      Description: 'GORRA NE 950 XOLOS 5 XOLOS TIJUAS',
      UnitCost: 482.77,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10216603',
      IdentificationNumber: 'QPHH-0592',
      InvoiceCatClaveUnidad: 'F20',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 932,
      invoiceSenderId: 1,
      Description: 'TERMO TIJUANA X LOGO FUERZA TIJUAS',
      UnitCost: 641.86,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12352128',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N18',
      Ieps: null,
      RetIeps: null,
      RetVat: 12,
      RetIsr: null
    },
    {
      id: 933,
      invoiceSenderId: 1,
      Description: 'TARRO SPI GEL 1/2 LITRO ROJO LOGO NEGRO',
      UnitCost: 344.69,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10226081',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E16',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 934,
      invoiceSenderId: 1,
      Description: 'XOLOPASS CABECERA NORTE (Nueva)',
      UnitCost: 837.37,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20122369',
      IdentificationNumber: 'GKQJ-8314',
      InvoiceCatClaveUnidad: 'C98',
      Ieps: null,
      RetIeps: null,
      RetVat: 2,
      RetIsr: null
    },
    {
      id: 935,
      invoiceSenderId: 1,
      Description: 'XOLOPASS NORTE NUMERADO (Nueva)',
      UnitCost: 961.5,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20121413',
      IdentificationNumber: 'KPQE-1554',
      InvoiceCatClaveUnidad: 'X1G',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 25
    },
    {
      id: 936,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 2 CORNER (Nueva)',
      UnitCost: 14.82,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10452042',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XTS',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 937,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 2 (Nueva)',
      UnitCost: 993.79,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302031',
      IdentificationNumber: 'BBDA-0356',
      InvoiceCatClaveUnidad: '95',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 938,
      invoiceSenderId: 1,
      Description: 'XOLOPASS PLATEA (Nueva)',
      UnitCost: 863.38,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10315702',
      IdentificationNumber: 'ZMEQ-7454',
      InvoiceCatClaveUnidad: 'N84',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 939,
      invoiceSenderId: 1,
      Description: 'SUR NUMERADO',
      UnitCost: 719.09,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10401733',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H15',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 12, RetIsr: 11
    },
    {
      id: 940,
      invoiceSenderId: 1,
      Description: 'CABECERA NORTE',
      UnitCost: 889.95,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10201522',
      IdentificationNumber: 'JKYK-6046',
      InvoiceCatClaveUnidad: 'XBY',
      Ieps: null,
      RetIeps: null,
      RetVat: 1,
      RetIsr: null
    },
    {
      id: 941,
      invoiceSenderId: 1,
      Description: 'SUR NUMERADO CORNER',
      UnitCost: 903.53,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10202410',
      IdentificationNumber: 'HAXU-0212',
      InvoiceCatClaveUnidad: 'VA',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 942,
      invoiceSenderId: 1,
      Description: 'NORTE NUMERADO CORNER',
      UnitCost: 261.79,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10217338',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'HH',
      Ieps: 0.060,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 943,
      invoiceSenderId: 1,
      Description: 'NORTE NUMERADO',
      UnitCost: 870.78,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10332117',
      IdentificationNumber: 'PORG-6483',
      InvoiceCatClaveUnidad: 'A68',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 14, RetIsr: 17
    },
    {
      id: 944,
      invoiceSenderId: 1,
      Description: 'ZONA 2',
      UnitCost: 234.0,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10331507',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G89',
      Ieps: null,
      RetIeps: 0.080,
      RetVat: null, RetIsr: 29
    },
    {
      id: 945,
      invoiceSenderId: 1,
      Description: 'ZONA 1 CORNER',
      UnitCost: 868.38,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10316204',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XNU',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 3
    },
    {
      id: 946,
      invoiceSenderId: 1,
      Description: 'ZONA 1',
      UnitCost: 288.45,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10401744',
      IdentificationNumber: 'QTSG-4183',
      InvoiceCatClaveUnidad: 'K45',
      Ieps: 0.070,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 947,
      invoiceSenderId: 1,
      Description: 'PLATEA CORNER',
      UnitCost: 315.93,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10226201',
      IdentificationNumber: 'CLJN-5069',
      InvoiceCatClaveUnidad: 'Q34',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 948,
      invoiceSenderId: 1,
      Description: 'CONJUNTO PANTS CHARLY 5055693',
      UnitCost: 117.35,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302600',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A59',
      Ieps: 0.000,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 949,
      invoiceSenderId: 1,
      Description: 'XOLOPASS VIP (Nueva)',
      UnitCost: 978.16,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10432104',
      IdentificationNumber: 'CKFP-9842',
      InvoiceCatClaveUnidad: 'MTS',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 950,
      invoiceSenderId: 1,
      Description: 'GORRA NE 920 CAMO LOGO',
      UnitCost: 186.57,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10326703',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'N2',
      Ieps: null,
      RetIeps: null,
      RetVat: 4, RetIsr: 12
    },
    {
      id: 951,
      invoiceSenderId: 1,
      Description: 'TERMO PLA ISHTAR LOGO ROJO 500 ML',
      UnitCost: 914.92,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10401505',
      IdentificationNumber: 'QPDV-6516',
      InvoiceCatClaveUnidad: 'D81',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 952,
      invoiceSenderId: 1,
      Description: 'GORRA NE 950 XOLOS 7 MESH LOGO PIEL',
      UnitCost: 887.96,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10231804',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XYR',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 953,
      invoiceSenderId: 1,
      Description: 'XOLOPASS NORTE NUMERADO CORNER (Nueva)',
      UnitCost: 814.79,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10251806',
      IdentificationNumber: 'MTXO-5589',
      InvoiceCatClaveUnidad: 'XAL',
      Ieps: 0.060,
      RetIeps: 0.080,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 954,
      invoiceSenderId: 1,
      Description: 'XOLOPASS SUR NUMERADO CORNER (Nueva)',
      UnitCost: 500.59,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10432046',
      IdentificationNumber: 'LGZU-2593',
      InvoiceCatClaveUnidad: 'K83',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 955,
      invoiceSenderId: 1,
      Description: 'GORRA NE 940 BASICA LOGO',
      UnitCost: 416.47,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10312635',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'G21',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 956,
      invoiceSenderId: 1,
      Description: 'CHALECO GETC PADDED DG ROJO NI�O T 12-14',
      UnitCost: 684.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10362058',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'NM3',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 8,
      RetIsr: null
    },
    {
      id: 957,
      invoiceSenderId: 1,
      Description: 'CILINDRO PLA BOWLING LOGO NEGRO',
      UnitCost: 810.3,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10214109',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: '1E',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 958,
      invoiceSenderId: 1,
      Description: 'PLUMA PLA MAVI EN BOLSA ROJO',
      UnitCost: 903.62,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10501805',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K41',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 5
    },
    {
      id: 959,
      invoiceSenderId: 1,
      Description: 'XOLOMONQUI COLGANTE XOLO MAYOR',
      UnitCost: 442.15,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10226051',
      IdentificationNumber: 'UULW-7724',
      InvoiceCatClaveUnidad: 'E53',
      Ieps: null,
      RetIeps: null,
      RetVat: null, RetIsr: 30
    },
    {
      id: 960,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 BASICA LOGO S/M',
      UnitCost: 272.28,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10214919',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KO',
      Ieps: null,
      RetIeps: null,
      RetVat: 6, RetIsr: 7
    },
    {
      id: 961,
      invoiceSenderId: 1,
      Description: 'XOLOMONQUI LLAVERO XOLO MAYOR',
      UnitCost: 101.83,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10302374',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C65',
      Ieps: null,
      RetIeps: 0.090,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 962,
      invoiceSenderId: 1,
      Description: 'XOLOPASS NORTE NUMERADO CRN (Renovacion)',
      UnitCost: 676.72,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10201512',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MMK',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null, RetIsr: 21
    },
    {
      id: 963,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 2 CORNER (Renovacion)',
      UnitCost: 389.17,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10151536',
      IdentificationNumber: 'PLPK-9449',
      InvoiceCatClaveUnidad: 'K17',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 964,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 1 (Nueva)',
      UnitCost: 254.58,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10413405',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'MBE',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 965,
      invoiceSenderId: 1,
      Description: 'XOLOPASS SUR NUMERADO (Renovacion)',
      UnitCost: 87.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '12164000',
      IdentificationNumber: 'DXLI-3593',
      InvoiceCatClaveUnidad: 'XSY',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 966,
      invoiceSenderId: 1,
      Description: 'XOLOPASS CABECERA NORTE (Renovacion)',
      UnitCost: 284.15,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10202213',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'TQ',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 967,
      invoiceSenderId: 1,
      Description: 'XOLOPASS NORTE NUMERADO (Renovacion)',
      UnitCost: 788.92,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10213001',
      IdentificationNumber: 'ETFA-6084',
      InvoiceCatClaveUnidad: 'N36',
      Ieps: null,
      RetIeps: null,
      RetVat: 3, RetIsr: 26
    },
    {
      id: 968,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 1 CORNER (Renovacion)',
      UnitCost: 675.14,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202229',
      IdentificationNumber: 'GCPO-1990',
      InvoiceCatClaveUnidad: 'XEG',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 969,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 1 (Renovacion)',
      UnitCost: 836.97,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10218018',
      IdentificationNumber: 'CIMA-6016',
      InvoiceCatClaveUnidad: 'H62',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 970,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 PORTERO',
      UnitCost: 538.58,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10252017',
      IdentificationNumber: 'CUPS-2538',
      InvoiceCatClaveUnidad: 'H45',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 971,
      invoiceSenderId: 1,
      Description: 'GORRA NE 3930 XOLOS 10 X NEGRO',
      UnitCost: 204.84,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10362059',
      IdentificationNumber: 'YHCQ-5942',
      InvoiceCatClaveUnidad: 'E56',
      Ieps: 0.080,
      RetIeps: null,
      RetVat: 11,
      RetIsr: null
    },
    {
      id: 972,
      invoiceSenderId: 1,
      Description: 'LLAVERO TIJUANA X LOGO DESTAPADOR',
      UnitCost: 817.38,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10402831',
      IdentificationNumber: 'NXJA-1297',
      InvoiceCatClaveUnidad: 'XZF',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 973,
      invoiceSenderId: 1,
      Description: 'LLAVERO PLA ESCUDO LOGO PLATA',
      UnitCost: 668.67,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10402375',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XTZ',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 974,
      invoiceSenderId: 1,
      Description: 'JERSEY CHARLY AP19-CL20 NEGRO NINO M',
      UnitCost: 504.7,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10217420',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K55',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 975,
      invoiceSenderId: 1,
      Description: 'PLAYERA CAMI CUELLO V HERMOSO',
      UnitCost: 366.31,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10111303',
      IdentificationNumber: 'UBUD-1125',
      InvoiceCatClaveUnidad: '32',
      Ieps: null,
      RetIeps: null,
      RetVat: 14, RetIsr: 19
    },
    {
      id: 976,
      invoiceSenderId: 1,
      Description: 'PLAYERA CAMI HERMOSO',
      UnitCost: 251.34,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '21101802',
      IdentificationNumber: 'MXAT-1539',
      InvoiceCatClaveUnidad: 'QK',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 977,
      invoiceSenderId: 1,
      Description: 'XOLOPASS SUR NUMERADO (Nueva)',
      UnitCost: 69.97,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10312813',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'T6',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: 4,
      RetIsr: null
    },
    {
      id: 978,
      invoiceSenderId: 1,
      Description: 'CERVEZA CUCAPAH',
      UnitCost: 720.71,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10318321',
      IdentificationNumber: 'XNPJ-1746',
      InvoiceCatClaveUnidad: 'NPT',
      Ieps: null,
      RetIeps: 1.600,
      RetVat: 16,
      RetIsr: null
    },
    {
      id: 979,
      invoiceSenderId: 1,
      Description: 'CERVEZA STELLA ARTOIS',
      UnitCost: 402.19,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '13102008',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'Z1',
      Ieps: 0.060,
      RetIeps: 0.090,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 980,
      invoiceSenderId: 1,
      Description: 'CERVEZA MICHELOB ULTRA',
      UnitCost: 75.22,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '11101506',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'E74',
      Ieps: 0.030,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 981,
      invoiceSenderId: 1,
      Description: 'ZONA 2 CORNER',
      UnitCost: 152.95,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10416602',
      IdentificationNumber: 'JFFR-8350',
      InvoiceCatClaveUnidad: 'D38',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 982,
      invoiceSenderId: 1,
      Description: 'XOLOPASS ZONA 1 CORNER (Nueva)',
      UnitCost: 783.76,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10426016',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'H41',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 983,
      invoiceSenderId: 1,
      Description: 'XOLOPASS PLATEA CORNER (Nueva)',
      UnitCost: 260.39,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317949',
      IdentificationNumber: 'QAWE-5121',
      InvoiceCatClaveUnidad: 'D96',
      Ieps: 0.060,
      RetIeps: null,
      RetVat: 5, RetIsr: 18
    },
    {
      id: 984,
      invoiceSenderId: 1,
      Description: 'PLUMA PLA ROLLER EN BOLSA PLATA',
      UnitCost: 832.79,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '11151715',
      IdentificationNumber: 'FJEJ-2836',
      InvoiceCatClaveUnidad: 'MQH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 985,
      invoiceSenderId: 1,
      Description: 'TAZA LIM CERAMICA CHICA LOGO',
      UnitCost: 523.19,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10332129',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'C70',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 986,
      invoiceSenderId: 1,
      Description: 'TAZA TIJUANA X LOGO XOLO ART',
      UnitCost: 254.28,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10452043',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'SIE',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 987,
      invoiceSenderId: 1,
      Description: 'CHALECO GETC PADDED DG ROJO NI�O T 8-10',
      UnitCost: 211.19,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10161903',
      IdentificationNumber: 'WFFG-3559',
      InvoiceCatClaveUnidad: 'KWH',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 988,
      invoiceSenderId: 1,
      Description: 'PUBLICIDAD',
      UnitCost: 752.02,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202874',
      IdentificationNumber: 'HLIL-1003',
      InvoiceCatClaveUnidad: 'XFO',
      Ieps: null,
      RetIeps: null,
      RetVat: 9,
      RetIsr: null
    },
    {
      id: 989,
      invoiceSenderId: 1,
      Description: 'XOLOPASS SUR NUMERADO CRN (Renovacion)',
      UnitCost: 707.5,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10316909',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'A93',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 990,
      invoiceSenderId: 1,
      Description: 'XOLOPASS PLATEA (Renovacion)',
      UnitCost: 127.8,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '20102000',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'K22',
      Ieps: null,
      RetIeps: 0.070,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 991,
      invoiceSenderId: 1,
      Description: 'REPARTO DE AGUA DE PIPAS PARA LAS DIFERENTES COLONIAS DE LA ZONA PONIENTE DEL 02 AL 31 DE MARZO DEL 2020.',
      UnitCost: 98.82,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '20143005',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'F58',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: 15,
      RetIsr: null
    },
    {
      id: 992,
      invoiceSenderId: 1,
      Description: 'Servicio Topicflower - Nota de crédito por A 458',
      UnitCost: 750.07,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10202731',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'KCC',
      Ieps: null,
      RetIeps: null,
      RetVat: 3,
      RetIsr: null
    },
    {
      id: 993,
      invoiceSenderId: 1,
      Description: 'Transporte de pasajeros aérea',
      UnitCost: 944.61,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10302440',
      IdentificationNumber: 'GSWQ-4804',
      InvoiceCatClaveUnidad: 'E32',
      Ieps: null,
      RetIeps: null,
      RetVat: 15, RetIsr: 17
    },
    {
      id: 994,
      invoiceSenderId: 1,
      Description: 'Venta de veh?culo por salvamento en las condiciones y da?os en que se encuentra, derivados del siniestro 0099028/20. Marca: BMW, Tipo: BMW X3 SDRIVE 20IA BUSINESS , Modelo: 2016, Serie: WBAWY9109G0N55736, Motor: A2081170',
      UnitCost: 117.2,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10332028',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XSA',
      Ieps: null,
      RetIeps: null,
      RetVat: 16, RetIsr: 29
    },
    {
      id: 995,
      invoiceSenderId: 1,
      Description: 'CRÉDITO PREPAGO NACIONAL',
      UnitCost: 591.48,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10241500',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'J44',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 996,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ENVÍO. ENVÍO: 232516.',
      UnitCost: 777.35,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10412640',
      IdentificationNumber: 'AZIF-9203',
      InvoiceCatClaveUnidad: 'C56',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 997,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ENVÍO. ENVÍO: 232399.',
      UnitCost: 807.27,
      Discount: null,
      Vat: 0,
      InvoiceCatClaveProdServ: '10217510',
      IdentificationNumber: 'HKSP-1149',
      InvoiceCatClaveUnidad: 'K30',
      Ieps: null,
      RetIeps: 0.060,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 998,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ENVÍO. ENVÍO: 232398.',
      UnitCost: 134.45,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '10417344',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'XWY',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    },
    {
      id: 999,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ENVÍO. ENVÍO: 232397.',
      UnitCost: 990.69,
      Discount: null,
      Vat: null,
      InvoiceCatClaveProdServ: '10317931',
      IdentificationNumber: 'GFDD-1737',
      InvoiceCatClaveUnidad: 'C89',
      Ieps: 0.000,
      RetIeps: null,
      RetVat: null, RetIsr: 6
    },
    {
      id: 1000,
      invoiceSenderId: 1,
      Description: 'SERVICIOS DE ENVÍO. ENVÍO: 232396.',
      UnitCost: 921.69,
      Discount: null,
      Vat: 0.16,
      InvoiceCatClaveProdServ: '12141617',
      IdentificationNumber: null,
      InvoiceCatClaveUnidad: 'B20',
      Ieps: null,
      RetIeps: null,
      RetVat: null,
      RetIsr: null
    }
  ];
}
