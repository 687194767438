import { BaseModel } from '../_base/crud';

export class RegisterModel extends BaseModel {
  name: string;
  rfc: string;
  password: string;
  email: string;
  origin: string;
  collectedAt: Date;

  clear() {
    this.name = '',
    this.rfc = '',
    this.password = '',
    this.email = '',
    this.origin = '';
    this.collectedAt = new Date();
  }
}
