<div class="header">

    <div>
      <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    </div>
    <div class="header-menu">
      
      <div>
        <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
        <mat-menu #soluciones="matMenu">
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
        </mat-menu>
      </div>

      <div>            
          <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
      </div>
      
      <div>
        <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
      </div>
      
    </div>
    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
</div>

<div class="header--movil">
  <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  <div>
    <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
    <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
  </div>
  <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav style="position: fixed;">
    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
    </div>
    <div class="menu-opciones">
      <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
      <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
      </mat-menu>
    </div>

    <div class="menu-opciones">            
        <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<div class="background">
    <div class="contenedor">
        
        <div class="texto">
            <div class="color-body-title center titles principal">
              <span class="title-light">Te damos el producto y </span>
              <span class="title-bold">tú le pones tu marca</span>
            </div>
            <br>
            <h2 class="margen color-body-text center">Personaliza tu propia plataforma y agrega valor a tu empresa.</h2>
            <br>
            <p class="margen justify" style="font-size: 17px;">
                Obtén Visoor en nuestro esquema White Label con las herramientas que necesites. Otorga accesos, folios y todo lo que requieras a tus clientes y proveedores.
            </p>
            <div class="opciones">
                <div class="botones">
                    <button (click)="contactVentas()" mat-flat-button class="boton font-poppins">
                        <span class="kt-margin-r-5 kt-margin-l-5">
                            Hablar con Ventas
                        </span>
                    </button>
                </div>
            </div>
            <br>
            <h2 class="margen color-body-subtitle">¿Qué incluye?</h2>
            <br>
            <div class="primerSeccion-opciones color-body-text">
                <div class="primerSeccion-opciones-item">
                    <mat-icon class="icono_send">send</mat-icon>
                  <span> Diseño y Desarrollo </span>
                </div>
                <div class="primerSeccion-opciones-item">
                    <mat-icon class="icono_send">send</mat-icon>
                    <span> Implementación </span>
                </div>
                <div class="primerSeccion-opciones-item">
                    <mat-icon class="icono_send">send</mat-icon>
                  <span> Asesoría y puesta en marcha de plataforma </span>
                </div>
                <div class="primerSeccion-opciones-item">
                    <mat-icon class="icono_send">send</mat-icon>
                  <span> Precio Preferencial de Folios </span>
                </div>
                <div class="primerSeccion-opciones-item">
                    <mat-icon class="icono_send">send</mat-icon>
                  <span> Soporte Técnico </span>
                </div>
        
              </div>
        </div>
    
        <div  class="texto">
            <div class="imagen">
                <img src="../../../../../assets/media/visoor/images/white_label/WhiteLabel_5.png">
            </div>
        </div>
    
    </div>
</div>

