
<div  mat-dialog-content >
  <div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">{{data.TitlFeature}}</h3>
      </div>
    </div>
    <div class="kt-portlet__body">
      <div class="row">
        <div class="col-lg-6 martp" style="margin-top: 80px  !important">
          <span class="kt-margin-r-15 kt-margin-l-15 tpsize" style="font-size: 1.2rem !important;">
            {{data.DescFeature}}
          </span><br>
          <span class="kt-margin-r-15 kt-margin-l-15 tpsize" style="font-size: 1.2rem !important;">
            {{data.infoExtra}}
          </span>
        </div>
        <div class="col-lg-6">

          <img class="img-fluid kt-pull-right" src={{data.UrlFeature}} alt="">
          
        </div>
      </div>
    </div>
  </div>


</div>

