import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterDialogComponent } from '../register-dialog/register-dialog.component';

@Component({
  selector: 'kt-dudas',
  templateUrl: './dudas.component.html',
  styleUrls: ['./dudas.component.scss']
})
export class DudasComponent implements OnInit {

  origin: string ='sitio_FacturacionDudas';

  constructor(
    private dialog: MatDialog
    ) { }

  ngOnInit(): void {
  }
  
  register() {
    const dialogRef = this.addDialogFilter();

    //const dialogRef = this.layoutUtilsService.emailElement(title);
    dialogRef.afterClosed().subscribe((res: string[]) => {
    });

  }

  addDialogFilter(){
    return this.dialog.open(RegisterDialogComponent, {
      width: '75vh',
      data: this.origin
    });
  }

}
