export enum InvoiceCatUsoCfdi {
  'Honorarios médicos, dentales y gastos hospitalarios.' = 'D01',
  'Gastos médicos por incapacidad o discapacidad' = 'D02',
  'Gastos funerales.' = 'D03',
  'Donativos.' = 'D04',
  'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' = 'D05',
  'Aportaciones voluntarias al SAR.' = 'D06',
  'Primas por seguros de gastos médicos.' = 'D07',
  'Gastos de transportación escolar obligatoria.' = 'D08',
  'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' = 'D09',
  'Pagos por servicios educativos (colegiaturas)' = 'D10',
  'Adquisición de mercancias' = 'G01',
  'Devoluciones, descuentos o bonificaciones' = 'G02',
  'Gastos en general' = 'G03',
  'Construcciones' = 'I01',
  'Mobilario y equipo de oficina por inversiones' = 'I02',
  'Equipo de transporte' = 'I03',
  'Equipo de computo y accesorios' = 'I04',
  'Dados, troqueles, moldes, matrices y herramental' = 'I05',
  'Comunicaciones telefónicas' = 'I06',
  'Comunicaciones satelitales' = 'I07',
  'Otra maquinaria y equipo' = 'I08',
  'Por definir' = 'P01',
}
