<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P4KQ5PB" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->
<div class="contenedor color-background-base">
    
  <img (click)="redwhats()" class="botonF1" src="../../../../assets/media/visoor/images/whats.png">  

  <div class="header">

    <div>
      <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    </div>

    <div class="header-menu">

      <div>
        <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
        <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item routerLink="./contadores">Contadores</button>
          <button class="header-matMenu" mat-menu-item routerLink="./api">API's</button>
          <button class="header-matMenu" mat-menu-item routerLink="./white-label">White label</button>
        </mat-menu>
      </div>

      <div>
        <button class="header-boton" mat-button routerLink="./facturacion">Facturacion 4.0</button>
      </div>

      <div>
        <button class="header-boton" mat-button routerLink="./porque-visoor">¿Por qué Visoor?</button>
      </div>

      <div>
        <button class="header-boton" mat-button routerLink="./autofactura">Autofactura</button>
      </div>

      <div>
        <button class="header-boton" mat-button routerLink="./precios">Precios</button>
      </div>
      
    </div>

    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
  </div>

  <div class="header--movil">
    <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    <div>
      <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
      <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
    </div>
    <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
  </div>
  <mat-sidenav-container>
    <mat-sidenav #sidenav style="position: fixed;">
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
      </div>
      <div class="menu-opciones">
        <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
        <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item routerLink="./contadores">Contadores</button>
          <button class="header-matMenu" mat-menu-item routerLink="./api">API's</button>
          <button class="header-matMenu" mat-menu-item routerLink="./white-label">White label</button>
        </mat-menu>
      </div>

      <div class="menu-opciones">
        <button class="header-boton" mat-button routerLink="./facturacion">Facturacion 4.0</button>
      </div>

      <div class="menu-opciones">
        <button class="header-boton" mat-button routerLink="./porque-visoor">¿Por qué Visoor?</button>
      </div>

      <div class="menu-opciones">
        <button class="header-boton" mat-button routerLink="./autofactura">Autofactura</button>
      </div>

      <div class="menu-opciones">
        <button class="header-boton" mat-button routerLink="./precios">Precios</button>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>

  <div class="background1">

    <div class="primerSeccion">
  
      <div>
        <img class="primerSeccion-img" src="../../../../assets/media/visoor/images/1.png">
      </div>
      
      <div class="primerSeccion-text">

        <div class="color-body-title center">
          <span class="title-light"> Factura a la velocidad que</span>
        </div>
        <div class="color-body-title center title-space">
          <span class="title-bold"> tu negocio necesita. </span>
        </div>

        <br>
        <h2 class="color-body-text center primerSeccion-h2">
          Realiza en cuestión de minutos:
        </h2>
  
        <div class="primerSeccion-opciones color-body-text">
          <div class="primerSeccion-opciones-item">
            <img class="primerSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_black.png">
            <span> CFDI 4.0 y Complementos 2.0 </span>
          </div>
          <div class="primerSeccion-opciones-item">
            <img class="primerSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_black.png">
            <span> Factura Programada y Prefacturas </span>
          </div>
          <div class="primerSeccion-opciones-item">
            <img class="primerSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_black.png">
            <span> Descargas (XML y PDF) </span>
          </div>
          <div class="primerSeccion-opciones-item">
            <img class="primerSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_black.png">
            <span> Conexión a Bancos </span>
          </div>
          <div class="primerSeccion-opciones-item">
            <img class="primerSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_black.png">
            <span> Multiusuario y MultiRFC </span>
          </div>
          <div></div>
          <div class="primerSeccion-opciones-item">
            <img class="primerSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_black.png">
            <span> Reportes </span>
          </div>
        </div>
  
        <div class="primerSeccion-botones">
          <button (click)="register()" id="ButtonHeader1" mat-flat-button class="primerSeccion-botones-boton font-poppins">
            <span class="kt-margin-r-5 kt-margin-l-5">
              Regístrate
            </span>
          </button>
          <button (click)="redDemo()" id="ButtonHeader2" mat-flat-button class="primerSeccion-botones-boton font-poppins">
            <span class="kt-margin-r-5 kt-margin-l-5">
              Agenda una DEMO
            </span>
          </button>
        </div>
  
      </div>
  
    </div>

  </div>
  
  <div class="background2">

    <div class="segundaSeccion">
      
      <div class="segundaSeccion-text">

        <div class="color-body-base center titles">
          <span class="title-light"> Timbra tu Factura</span><span class="title-bold"> en menos de 15 segundos. </span>
        </div>

        <p class="color-body-base center segundaSeccion-opciones">
          ¡Reduce hasta 300% del tiempo que destinas al Facturar! Con las herramientas de Visoor podrás:
        </p>
  
        <div class="segundaSeccion-opciones color-body-base">
          <div class="segundaSeccion-opciones-item">
            <img class="segundaSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_white.png">
            <span>Llenar en automático datos de tus clientes y productos <br> </span>
          </div>
          <div class="segundaSeccion-opciones-item">
            <img class="segundaSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_white.png">
            <span>Generar Pre-Facturas para evitar errores <br> </span>
          </div>
          <div class="segundaSeccion-opciones-item">
            <img class="segundaSeccion-opciones-icono" src="../../../../assets/media/visoor/images/icono_paloma_white.png">
            <span>Programar Facturas en la fecha y hora que desees <br> </span> 
          </div>

        </div>
  
      </div>
  
      <div>
        <img class="segundaSeccion-img" src="../../../../assets/media/visoor/images/2.png">
      </div>
  
    </div>

  </div>

  <div class="background3">
  
    <div class="terceraSeccion">
  
      <div>
        <img class="terceraSeccion-img" src="../../../../assets/media/visoor/images/3.png">
      </div>
      
      <div class="terceraSeccion-text">
  
        <div class="color-body-title center titles">
          <span class="title-light">¡</span>
          <span class="title-bold">Descarga facturas </span>
          <span class="title-light"> de cualquier mes y año!</span>
        </div>
        <br>
  
        <div class="terceraSeccion-opciones color-body-text justify">
          <p>
            En conexión directa con el SAT, descarga desde Visoor tus ingresos y egresos desde 2014 a la fecha en PDF y XML.
          </p>
        </div>
  
        <div class="terceraSeccion-botones">
          <button (click)="register()" id="ButtonBody1" mat-flat-button class="terceraSeccion-botones-boton font-poppins">
            <span class="kt-margin-r-5 kt-margin-l-5">
              Regístrate
            </span>
          </button>
        </div>
  
      </div>
  
    </div>

  </div>

  <div class="background4">

    <div class="cuartaSeccion">
      
      <div class="cuartaSeccion-text">
  
        <div class="color-body-base center titles">
          <span class="title-light">Genera y Envía Tus </span>
          <span class="title-bold">Recibos de Nómina </span>
        </div>
        <br>
  
        <div class="cuartaSeccion-opciones color-body-base justify">
          <p>
            Carga la información de tus trabajadores en nuestra plantilla y timbra en simultáneo los comprobantes que requieras.
          </p>
        </div>
  
      </div>
  
      <div>
        <img class="cuartaSeccion-img" src="../../../../assets/media/visoor/images/4.png">
      </div>
  
    </div>

  </div>

  <div class="background5">
    
    <div class="quintaSeccion">
  
      <div>
        <img class="quintaSeccion-img" src="../../../../assets/media/visoor/images/5.png">
      </div>
      
      <div class="quintaSeccion-text">

        <div class="color-body-title center titles">
          <span class="title-light">Asigna </span>
          <span class="title-bold">roles a tu equipo </span>
          <span class="title-light">de trabajo </span>
        </div>
        <br>
  
        <div class="quintaSeccion-opciones color-body-text justify">
          <p>
            ¡Tú decides qué información mostrar! Brinda los accesos que desees a tu contador, auxiliar administrativo, o cualquier persona clave en tu contabilidad.
          </p>
        </div>
  
        <div class="quintaSeccion-botones">
          <button (click)="register()" id="ButtonBody2" mat-flat-button class="quintaSeccion-botones-boton font-poppins">
            <span class="kt-margin-r-5 kt-margin-l-5">
              Regístrate
            </span>
          </button>
        </div>
  
      </div>
  
    </div>

  </div>

  <div class="background6">

    <div class="sextaSeccion">
      
      <div class="sextaSeccion-text">
        <div class="color-body-base center titles">
          <span class="title-light">¡Evita </span>
          <span class="title-bold">Multas </span>
          <span class="title-light">con el </span>
          <span class="title-bold">SAT!</span>
        </div>
        <br>
  
        <div class="sextaSeccion-opciones color-body-base justify">
          <p>
            Verifica el estatus de tus clientes y proveedores ante el SAT con nuestro buscador de Listas Negras.
          </p>
        </div>
  
        <div class="sextaSeccion-botones">
          <button (click)="redDemo()" id="ButtonBody3" mat-flat-button class="sextaSeccion-botones-boton font-poppins">
            <span class="kt-margin-r-5 kt-margin-l-5">
              Agenda una DEMO
            </span>
          </button>
        </div>
  
      </div>
      
      <div>
        <img class="sextaSeccion-img" src="../../../../assets/media/visoor/images/6.png">
      </div>
  
    </div>

  </div>

  <div class="background7">
  
    <div class="septimaSeccion">
  
      <div>
        <img class="septimaSeccion-img" src="../../../../assets/media/visoor/images/7.png">
      </div>
  
      <div class="septimaSeccion-text">
  
        <div class="color-body-title center titles">
          <span class="title-light">¡Consulta y descarga tu </span>
          <span class="title-bold">CIF y 32D </span>
          <span class="title-light">actualizadas!</span>
        </div>
        <br>
  
        <div class="septimaSeccion-opciones color-body-text justify">
          <p>
            Consulta desde nuestra plataforma tu Cédula de Identificación Fiscal y la Opinión de Cumplimiento sin necesidad de entrar al portal del SAT o asistir a alguna de sus oficinas
          </p>
        </div>
  
      </div>
    </div>

  </div>

  <div class="background8">

    <div class="octavaSeccion">
      
      <div class="octavaSeccion-text">
  
        <div class="color-body-base center titles">
          <span class="title-light">Gestiona tus </span>
          <span class="title-bold">bancos</span>
        </div>
        <br>
        
        <div class="octavaSeccion-opciones color-body-base justify">
          <p>
            Accede a tus bancos desde Visoor para monitorear el comportamiento de tu cuenta bancaria y de tus facturas en el mismo lugar.
          </p>
        </div>
      </div>
  
      <div>
        <img class="octavaSeccion-img" src="../../../../assets/media/visoor/images/8.png">
      </div>
  
    </div>

  </div>

  <div class="background9">

    <div class="novenaSeccion">
  
      <div>
        <img class="novenaSeccion-img" src="../../../../assets/media/visoor/images/9.png">
      </div>
      
      <div class="novenaSeccion-text">
  
        <div class="color-body-title center titles">
          <span class="title-light">Administra </span>
          <span class="title-bold">Múltiples RFCs</span>
        </div>
        <br>
  
        <div class="novenaSeccion-opciones color-body-text justify">
          <p>
            ¿Administras más de una empresa? Gestiona múltiples RFCs desde la misma cuenta de Visoor.
          </p>
        </div>
  
        <div class="novenaSeccion-botones">
          <button (click)="register()" id="ButtonBody1" mat-flat-button class="novenaSeccion-botones-boton font-poppins">
            <span class="kt-margin-r-5 kt-margin-l-5">
              ¡Prueba Visoor por 7 días!
            </span>
          </button>
        </div>
  
      </div>
  
    </div>

  </div>

  <div class="carrusel">
    <h1 class="color-body-text" style="margin-bottom: 30px;">Empresas que digitalizaron su administración con <span class="color-body-subtitle">Visoor</span> 
    </h1>

    <div>

      <ngb-carousel [interval]="4000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="true">
        <ng-template ngbSlide *ngFor="let logo of logos; index as i">
          <div class="logos">
            <div class="logos-indicadorIzquiero">
              <img src="../../../../assets/media/visoor/images/flecha_left.png">
            </div>
            <div class="logos-imagen">
              <img [src]="logo.imagen1">
            </div>
            <div class="logos-imagen">
              <img  [src]="logo.imagen2">
            </div>
            <div class="logos-imagen">
              <img [src]="logo.imagen3">
            </div>
            <div class="logos-imagen">
              <img [src]="logo.imagen4">
            </div>
            <div class="logos-imagen">
              <img [src]="logo.imagen5">
            </div>
            <div class="logos-indicadorDerecho">
              <img src="../../../../assets/media/visoor/images/flecha_right.png">
            </div>
          </div>
          
        </ng-template>
      </ngb-carousel>
      
    </div>
  </div>

  <div class="carrusel-movil">
    <h1 class="color-body-text" style="margin-bottom: 15px;">Empresas que digitalizaron su administración con <span class="color-body-subtitle">Visoor</span> 
    </h1>

    <div>

      <ngb-carousel [interval]="4000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="true">
        <ng-template ngbSlide *ngFor="let logo of logosMovil; index as i">
          <div class="logos">
            <div class="logos-indicadorIzquiero">
              <img src="../../../../assets/media/visoor/images/flecha_left.png">
            </div>
            <div class="logos-imagen">
              <img [src]="logo.imagen1">
            </div>
            <div class="logos-indicadorDerecho">
              <img src="../../../../assets/media/visoor/images/flecha_right.png">
            </div>
          </div>

        </ng-template>
      </ngb-carousel>
      
    </div>
  </div>

  <div #formulario class="footer">
    <div></div>

    <div class="footer-img" >
      <img class="footer-logo" src="../../../../assets/media/visoor/images/logo_visoor_white.png" alt="">
    </div>

    <div class="kt-margin-l-20 font-poppins footer-text">
      <p class="kt-margin-b-8">Av. Lic. Miguel de la Madrid Hurtado 1961-Interior 10, Desarrollo Especial Seminario Siervos de María C.P. 20118, Aguascalientes, Ags..</p>
      <p class="kt-margin-b-8">Tel: (449) 4755998</p>
      <p class="kt-margin-b-8">Email: soporte@visoor.mx</p>
      <p class="kt-margin-b-8">Lunes - Viernes: 9:00 a.m - 6:00 p.m</p>
    </div>

    <div class="text kt-pl80 kt-pr80">
      <div class="font-size-29 kt-font-bolder kt-margin-b-15 font-poppins">Conversa con nosotros
      </div>
      <div class="font-oppensans max footer-descripcion">
        Si tienes dudas de cómo dar el primer paso para digitalizar tu administración, estás en el lugar correcto.
        Regístrate y un especialista te compartirá algunos tips:
      </div>
      <div>
        <form [formGroup]="formContacto">
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre">
          </mat-form-field>
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Correo</mat-label>
            <input matInput type="email" formControlName="correo">
          </mat-form-field>
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Necesidad</mat-label>
            <textarea matInput formControlName="necesidad" placeholder="Pregúntanos algo..."></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>WhatsApp</mat-label>
            <input matInput type="tel" formControlName="whatsapp" maxlength="10">
          </mat-form-field>
          <div class="kt-margin-t-10 kt-margin-b-20">
            <button id="ButtonFooter2" mat-flat-button
              class="footer-boton font-poppins font-poppins font-size-17 kt-margin-r-20"
              (click)="contact()" [disabled]="send" style="min-width: 130px; min-height: 36px;">
              <div *ngIf="!(send$ | async) && !(loading$ | async) && !send" class="kt-margin-r-20 kt-margin-l-20">Enviar
              </div>
              <div *ngIf="send" class="kt-margin-r-20 kt-margin-l-20">Enviado</div>
              <span class="spinner-diff" *ngIf="(loading$ | async)">
                <mat-spinner class="example-margin" style="margin:0 auto;" [diameter]="20"></mat-spinner>
              </span>
            </button>
            <span *ngIf="error$ | async">¡Debes llenar todos los campos!</span>
            <span *ngIf="send$ | async">¡Gracias por contactarnos!</span>
          </div>
        </form>
      </div>
    </div>

    <div class="footer-text2">
      <img src="../../../../assets/media/visoor/images/LOGO BLANCO VISOOR.png">
      <p>
        Visoor te ayuda a llevar un mejor control de tu administración con herramientas digitales que hacen más fácil tu proceso. ¡Pruébalo y disfruta de nuestras soluciones!
      </p>
    </div>

    <div></div>

    <div class="footer-legal">
      <p>
        ©Visoor 2022. Todos los derechos reservados.  
        <a class="color-body-subtitle" target="_blank" href="https://legal.visoor.mx/avisodeprivacidad">Aviso de Privacidad</a>
        |
        <a class="color-body-subtitle" target="_blank" href="https://legal.visoor.mx/terminosycondiciones">Términos y Condiciones</a>
      </p>
    </div>

    <div class="footer-icons">
      <a target="_blank" href="https://www.facebook.com/visoor" >
        <i class="fab fa-facebook-f kt-margin-l-20 kt-margin-r-20 font-size-40"></i>
      </a>
      <a target="_blank" href="https://www.instagram.com/visoor_mx/">
        <i class="fab fa-instagram kt-margin-r-20 font-size-40"></i>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/visoor/">
        <i class="fab fa-linkedin-in kt-margin-r-20 font-size-40"></i>
      </a>
      <a target="_blank" href="https://twitter.com/visoormx?fbclid=IwAR3z6iZ2fl2HifKiBto7LjOafVijeJuf78vB9wz_akYJV1oetC2X9RxWbVo">
        <i class="fab fa-twitter kt-margin-r-20 font-size-40"></i>
      </a>
    </div>


  </div>

</div>

