export class DiotRecordsTable {
  public static records: any[] = [
    {
      Month: 0,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 129.00,
      Vat: 21.00,
      Amount: 150.00,
    },
    {
      Month: 1,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 1029.00,
      Vat: 21.00,
      Amount: 1050.00,
    },
    {
      Month: 2,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 229.00,
      Vat: 21.00,
      Amount: 250.00,
    },
    {
      Month: 3,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 329.00,
      Vat: 21.00,
      Amount: 350.00,
    },
    {
      Month: 4,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 129.00,
      Vat: 51.00,
      Amount: 200.00,
    },
    {
      Month: 5,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 169.00,
      Vat: 21.00,
      Amount: 190.00,
    },
    {
      Month: 5,
      Year: 2020,
      Rfc: 'IJO231544QF1',
      Subtotal: 1209.00,
      Vat: 21.00,
      Amount: 1230.00,
    },
    {
      Month: 4,
      Year: 2020,
      Rfc: 'IJO231544QF1',
      Subtotal: 129.00,
      Vat: 21.00,
      Amount: 150.00,
    },
    {
      Month: 3,
      Year: 2020,
      Rfc: 'IJO231544QF1',
      Subtotal: 629.00,
      Vat: 21.00,
      Amount: 650.00,
    },
    {
      Month: 6,
      Year: 2020,
      Rfc: 'IJO231544QF1',
      Subtotal: 10000.00,
      Vat: 1600.00,
      Amount: 11600.00,
    },
    {
      Month: 6,
      Year: 2020,
      Rfc: 'TZS316456HY5',
      Subtotal: 6290.00,
      Vat: 210.00,
      Amount: 6500.00,
    },
  ];
}
