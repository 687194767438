// Angular
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// Pages
import { CoreModule } from '../../core/core.module';
import { PartialsModule } from '../partials/partials.module';
import { ContadoresComponent } from './soluciones/contadores/contadores.component';
import { ApiComponent } from './soluciones/api/api.component';
import { WhiteLabelComponent } from './soluciones/white-label/white-label.component';
import { FacturacionComponent } from './facturacion/facturacion.component';
import { VisoorComponent } from './visoor/visoor.component';
import { AutofacturaComponent } from './autofactura/autofactura.component';
import { PreciosComponent } from './precios/precios.component';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RegisterDialogComponent } from './register-dialog/register-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DudasComponent } from './dudas/dudas.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DownloadFacturacionDialogComponent } from './download-facturacion-dialog/download-facturacion-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [    
  ContadoresComponent,
  ApiComponent,
  WhiteLabelComponent,
  FacturacionComponent,
  VisoorComponent,
  AutofacturaComponent,
  PreciosComponent,
  RegisterDialogComponent,
  DudasComponent,
  ContactDialogComponent,
  DownloadFacturacionDialogComponent
],
  imports: [
    CoreModule,
    FormsModule,
    CommonModule,
    PartialsModule,
    HttpClientModule,
    MatIconModule,
    MatGridListModule,
    NgbCarouselModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatMenuModule,
    PdfJsViewerModule,
    MatSidenavModule,
    MatListModule
  ],
  providers: []
})
export class PagesModule {
}
