<div class="header">

    <div>
        <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
      </div>
      <div class="header-menu">
        
        <div>
            <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
            <mat-menu #soluciones="matMenu">
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
            </mat-menu>
        </div>
  
        <div>            
            <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
        </div>
      
    </div>
    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
</div>

<div class="header--movil">
    <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    <div>
      <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
      <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
    </div>
    <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
  </div>
  
  <mat-sidenav-container>
    <mat-sidenav #sidenav style="position: fixed;">
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
      </div>
      
      <div class="menu-opciones">
        <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
        <mat-menu #soluciones="matMenu">
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
            <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
        </mat-menu>
      </div>
  
      <div class="menu-opciones">            
          <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
      </div>
  
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
      </div>
  
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
      </div>
  
      <div class="menu-opciones">
        <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
  

<div class="background">
    <div class="contenedor">
        <div class="texto">
            <div class="color-body-title center titles principal">
                <span class="title-light">¿Manejas una </span>
                <span class="title-bold">cartera de clientes </span>
                <span class="title-light">amplia?</span>
            </div>
            <br>
            <p class="margen justify" style="font-size: 17px;">
                En Visoor sabemos que llevar la contabilidad de muchos clientes a la vez puede ser una tarea complicada si no cuentas con las herramientas necesarias, por ello, tenemos dos soluciones para optimizar tus procesos de trabajo.
            </p>
            <br>
            <br>
            <h4 class="margen30 color-body-text ">Multi RFC Masivo, registra hasta <strong>100 RFC</strong> en plan empresarial dentro de Visoor</h4>
            <div class="carrusel">
              <div>
                <ngb-carousel [interval]="4000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="true">
                  <ng-template ngbSlide *ngFor="let opt of options; index as i">
                    <div class="logos">
                      <div class="logos-indicadorIzquiero">
                        <img src="../../../../../assets/media/visoor/images/flecha_left.png">
                      </div>

                      <div class="logos-texto">
                        <span (click)="openDialog(opt.direction)">{{opt.name}}</span>
                      </div>
                      
                      <div class="logos-indicadorDerecho">
                        <img src="../../../../../assets/media/visoor/images/flecha_right.png">
                      </div>
                    </div>
                  </ng-template>
                </ngb-carousel>
                
              </div>
            </div>
            <div class="color-body-title center titles precio">
                <span class="title-light-m">Desde</span><br>
                <span class="title-bold-m">$3,900 </span>
                <span class="title-light-m">anuales</span>
            </div>
            <div class="botones">
                <button (click)="contactVentas()" mat-flat-button class="boton font-poppins">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Hablar con ventas
                    </span>
                </button>
            </div>
        </div>
        <div  class="texto">
            <div class="imagen hoverNot">
                <img src="../../../../../assets/media/visoor/images/contadores/mujer.png"> 
            </div>
        </div>
    </div>
    <div class="contenedor2">
        <div  class="texto2">
            <div class="imagen2 hoverNot">
                <img src="../../../../../assets/media/visoor/images/contadores/pantalla.png">
            </div>
        </div>
        <div  class="texto2_right">
            <div class="color-body-title center titles">
              <span class="title-light">Genera Ingresos con </span>
                <span class="title-bold">Distribuidor Visoor</span>
            </div>
            <br>
            <p class="margen justify" style="font-size: 17px;">
                Recomienda Visoor y da un valor agregado a tu servicio contable. <br> 
                Recibe hasta el <strong>30%</strong> por cada suscripción anual.
            </p>
            <div class="botones2">
                <button (click)="contactVentas()" mat-flat-button class="boton font-poppins">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Hablar con ventas
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
