export class BlackListRecordsTable {
  public static records: any[] = [
    {
      Rfc: 'GAGF500215TWA',
      Company: 'FRANCISCA GARCIA GUTIERREZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/11/2014'),
    },
    {
      Rfc: 'GAGF530705UN0',
      Company: 'FERMIN CARMELO GALINDO GOMEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGF590911B53',
      Company: 'FEDERICO GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/02/2014'),
    },
    {
      Rfc: 'GAGF6707302E3',
      Company: 'FRANCISCO DANIEL GARCIA GAMBOA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2015'),
    },
    {
      Rfc: 'GAGF6810043U3',
      Company: 'FRANCISCA GALARZA GUERRERO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/05/2014'),
    },
    {
      Rfc: 'GAGF710219L9A',
      Company: 'FERNANDO JAVIER GARZA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2017'),
    },
    {
      Rfc: 'GAGF810203427',
      Company: 'FERNANDO GALLO GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/09/2015'),
    },
    {
      Rfc: 'GAGF810328A28',
      Company: 'FERNANDO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/01/2015'),
    },
    {
      Rfc: 'GAGF810618TB5',
      Company: 'FERNANDO OCTAVIO GARCIA GOMEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2015'),
    },
    {
      Rfc: 'GAGF840202KM9',
      Company: 'FRANCISCO JAVIER GAYTAN GARCÍA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/05/2018'),
    },
    {
      Rfc: 'GAGG420725SN3',
      Company: 'GUILLERMO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2014'),
    },
    {
      Rfc: 'GAGG580113Q46',
      Company: 'GUMERCINDA GARCIA GERONIMO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/12/2017'),
    },
    {
      Rfc: 'GAGG590819KZA',
      Company: 'GABRIEL GARCIA GIL',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2015'),
    },
    {
      Rfc: 'GAGG590907N97',
      Company: 'GONZALO LEONCIO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/06/2016'),
    },
    {
      Rfc: 'GAGG6206293W0',
      Company: 'GRISELDA ESTHER GASCA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/09/2015'),
    },
    {
      Rfc: 'GAGG630409SJ8',
      Company: 'MA GUADALUPE GARCIA GUZMAN',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/05/2016'),
    },
    {
      Rfc: 'GAGG680121CE3',
      Company: 'MA GUADALUPE GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/04/2018'),
    },
    {
      Rfc: 'GAGG6804257XA',
      Company: 'GUADALUPE GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/07/2018'),
    },
    {
      Rfc: 'GAGG721108CB1',
      Company: 'GILBERTO ROLANDO GARCIA GRANADOS',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/04/2017'),
    },
    {
      Rfc: 'GAGG7301151T2',
      Company: 'GABRIELA GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/11/2014'),
    },
    {
      Rfc: 'GAGG740517275',
      Company: 'GAMALIEL GARFIAS GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2015'),
    },
    {
      Rfc: 'GAGG7409193R5',
      Company: 'GENARO GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/05/2014'),
    },
    {
      Rfc: 'GAGG741010DS1',
      Company: 'GABRIELA DEL CARMEN GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/07/2015'),
    },
    {
      Rfc: 'GAGG7410235D7',
      Company: 'GAMALIEL GARZA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2017'),
    },
    {
      Rfc: 'GAGG7505293S3',
      Company: 'GABRIELA KARINA GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2016'),
    },
    {
      Rfc: 'GAGG7912223IA',
      Company: 'GUSTAVO GARCIA GARDEA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2014'),
    },
    {
      Rfc: 'GAGG801222SC7',
      Company: 'GUADALUPE GARCIA GARRIDO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGG810922R53',
      Company: 'JOSE GUADALUPE GRAJEDA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2014'),
    },
    {
      Rfc: 'GAGG820410H8A',
      Company: 'GLORIA DEL CARMEN GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/06/2018'),
    },
    {
      Rfc: 'GAGG821116F32',
      Company: 'JOSE GUSTAVO GASTELUM GERARDO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/08/2014'),
    },
    {
      Rfc: 'GAGG830814FZ0',
      Company: 'GABRIELA GARCIA GALAVIZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/10/2015'),
    },
    {
      Rfc: 'GAGG850924193',
      Company: 'GERARDO GRANADOS GALVEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/02/2018'),
    },
    {
      Rfc: 'GAGG860323PX9',
      Company: 'GUADALUPE ARANZAZU GAYOSSO GALLEGO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/01/2017'),
    },
    {
      Rfc: 'GAGG8701079Y1',
      Company: 'GUADALUPE GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2015'),
    },
    {
      Rfc: 'GAGH200504K21',
      Company: 'HUMBERTO MARCOS DE LA GARZA GOMEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/11/2015'),
    },
    {
      Rfc: 'GAGH571111MG7',
      Company: 'HECTOR MARTIN GARZA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/02/2014'),
    },
    {
      Rfc: 'GAGH6704044P3',
      Company: 'HERNAN EDGARDO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGH700915270',
      Company: 'HECTOR RUBEN GARZA GUTIERREZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/02/2017'),
    },
    {
      Rfc: 'GAGH8508059Q0',
      Company: 'HONORE VLADIMIR GARCIA GARDUÑO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/04/2018'),
    },
    {
      Rfc: 'GAGI2602013E0',
      Company: 'IGNACIO GARZA GUTIERREZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/08/2014'),
    },
    {
      Rfc: 'GAGI500513TU7',
      Company: 'IRMA GALLARZO GUERRERO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/12/2014'),
    },
    {
      Rfc: 'GAGI550630FH3',
      Company: 'IGNACIO GARCIA GOMEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/02/2014'),
    },
    {
      Rfc: 'GAGI7307039L8',
      Company: 'ISRAEL GARNICA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/11/2015'),
    },
    {
      Rfc: 'GAGI760719HKA',
      Company: 'MARIA ISABEL GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/05/2018'),
    },
    {
      Rfc: 'GAGI9205158S6',
      Company: 'ISIDRO CECILIO GAITAN GRIMALDO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/01/2018'),
    },
    {
      Rfc: 'GAGJ2912189V4',
      Company: 'JOSE DE JESUS GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2015'),
    },
    {
      Rfc: 'GAGJ310718650',
      Company: 'JUSTINO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2017'),
    },
    {
      Rfc: 'GAGJ321111GJ2',
      Company: 'JAIME FERNANDO GARZA GARZA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2015'),
    },
    {
      Rfc: 'GAGJ341112K24',
      Company: 'JABIER GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2018'),
    },
    {
      Rfc: 'GAGJ341202AP7',
      Company: 'J. GUADALUPE GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/02/2014'),
    },
    {
      Rfc: 'GAGJ430507R81',
      Company: 'JORGE GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/06/2018'),
    },
    {
      Rfc: 'GAGJ4906242Q0',
      Company: 'JUANA GALEANA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/10/2017'),
    },
    {
      Rfc: 'GAGJ550806DNA',
      Company: 'JAIME ELIUD GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/11/2015'),
    },
    {
      Rfc: 'GAGJ5604309I2',
      Company: 'JAIME CARLOS GARRIDO GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/01/2018'),
    },
    {
      Rfc: 'GAGJ5701104C5',
      Company: 'JAIME GALICIA GUTIÉRREZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/05/2018'),
    },
    {
      Rfc: 'GAGJ570422NW7',
      Company: 'JORGE HUGO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/02/2014'),
    },
    {
      Rfc: 'GAGJ571216GS8',
      Company: 'JAIME GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/07/2017'),
    },
    {
      Rfc: 'GAGJ5801114S3',
      Company: 'JUAN MANUEL GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGJ620912NSA',
      Company: 'JAIME GERARDO GALARZA GRACIANO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/02/2017'),
    },
    {
      Rfc: 'GAGJ691013TV3',
      Company: 'JAVIER OCTAVIO GARDUÑO GUTIERREZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/04/2015'),
    },
    {
      Rfc: 'GAGJ6910159XA',
      Company: 'JESUS GARRIDO GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/08/2014'),
    },
    {
      Rfc: 'GAGJ711218KT5',
      Company: 'JORGE MANUEL GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2014'),
    },
    {
      Rfc: 'GAGJ730310PU2',
      Company: 'JESUS DIONICIO GARCIA GERARDO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGJ731129P14',
      Company: 'JOSEFINA GARCIA GUTIERREZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGJ741117SP5',
      Company: 'JOEL GALAVIZ GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/03/2017'),
    },
    {
      Rfc: 'GAGJ751214LE7',
      Company: 'JUAN ANTONIO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/10/2014'),
    },
    {
      Rfc: 'GAGJ770112810',
      Company: 'JORGE ANTONIO GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/05/2014'),
    },
    {
      Rfc: 'GAGJ770507742',
      Company: 'JAVIER GARCIA GUERRERO',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/02/2017'),
    },
    {
      Rfc: 'GAGJ8105076T3',
      Company: 'JUAN MANUEL GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/07/2015'),
    },
    {
      Rfc: 'GAGJ840128U31',
      Company: 'JORGE MIGUEL GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGJ870510ER2',
      Company: 'JAIME GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/11/2016'),
    },
    {
      Rfc: 'GAGK800319141',
      Company: 'KARINA GAYTAN GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2017'),
    },
    {
      Rfc: 'GAGL3209072E7',
      Company: 'LUIS LAURO GARZA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/03/2015'),
    },
    {
      Rfc: 'GAGL510509LR5',
      Company: 'LUIS GARCIA GARCIA',
      Status: 'CANCELADOS',
      UpdateDate: new Date('01/11/2014'),
    },
    {
      Rfc: 'GAGL621225U76',
      Company: 'LUIS ALFONSO GARCIA GONZALEZ',
      Status: 'CANCELADOS',
      UpdateDate: new Date('16/10/2015'),
    },
    {
      Rfc: 'CMK060106Q73',
      Company: 'COMERCIALIZADORA Y MAQUILADORA KISA S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('10/01/2014'),
    },
    {
      Rfc: 'CML020402SV0',
      Company: 'CONSTRUCTORA MENDOZA LOPEZ, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('02/09/2019'),
    },
    {
      Rfc: 'CML041208QJ8',
      Company: 'CONSORCIO ML, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/11/2016'),
    },
    {
      Rfc: 'CML110929D50',
      Company: 'COMERCIALIZADORA DE MATERIALES LY, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2017'),
    },
    {
      Rfc: 'CML131223CB1',
      Company: 'CENTRO MERCANTIL LOGROS, S.A. DE C.V.',
      Status: 'Desvirtuado',
      UpdateDate: new Date('01/12/2016'),
    },
    {
      Rfc: 'CML1410233MA',
      Company: 'COMERCIALIZADORA Y MARKETING LEGER, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('31/10/2017'),
    },
    {
      Rfc: 'CMM1007094SA',
      Company: 'CSM MEMBRANES DE MEXICO, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/12/2016'),
    },
    {
      Rfc: 'CMM101104R1A',
      Company: 'CONSTRUCCIONES Y MATERIALES MAP, S.A. DE C.V. ',
      Status: 'Definitivo',
      UpdateDate: new Date('01/10/2016'),
    },
    {
      Rfc: 'CMM101111ST0',
      Company: 'COMERCIALIZADORA MEXICANA MAFERVI, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/08/2017'),
    },
    {
      Rfc: 'CMM111027E75',
      Company: 'COMERCIALIZADORA Y MARKETING MAJA S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2015'),
    },
    {
      Rfc: 'CMM120416SR1',
      Company: 'CORPORATIVO DE MATERIALES Y MAQUINARIA, S.A DE C.V.',
      Status: 'Presunto',
      UpdateDate: new Date('02/08/2018'),
    },
    {
      Rfc: 'CMM1209121H5',
      Company: 'COMERCIALIZADORA MERCANTIL LA MARINA, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2018'),
    },
    {
      Rfc: 'CMM1305157U7',
      Company: 'COMERCIALIZADORA DE MEXICO MULTINIVEL LATAM, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('02/09/2019'),
    },
    {
      Rfc: 'CMM130724FU3',
      Company: 'CEO MARNE MÉXICO, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('18/09/2017'),
    },
    {
      Rfc: 'CMM140714LQ1',
      Company: 'CONSTRUCCIONES, MAQUINARIA Y MATERIALES GRACO, S.A. DE C.V.',
      Status: 'Presunto',
      UpdateDate: new Date('15/05/2020'),
    },
    {
      Rfc: 'CMM1408055K3',
      Company: 'CONSTRUCCIONES Y MATERIALES MORIT, S.A DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/11/2016'),
    },
    {
      Rfc: 'CMM970520J85',
      Company: 'CONSTRUCCIONES Y MATERIALES MARISCAL, S.A. DE C.V.	Sentencia',
      Status: 'Favorable',
      UpdateDate: new Date('01/02/2017'),
    },
    {
      Rfc: 'CMN1509091JA',
      Company: 'CONSTRUCTORA Y MINERÍA DEL NORTE, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/07/2017'),
    },
    {
      Rfc: 'CMO0603188Q0',
      Company: 'COMERCIALIZADORA LA MOJARRA S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/09/2015'),
    },
    {
      Rfc: 'CMO061212G92',
      Company: 'CORPORATIVO MORYNA, S.C.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/12/2016'),
    },
    {
      Rfc: 'CMO070226460',
      Company: 'CONSTRUCTORA MORAYMA, S.A. DE C.V.  ',
      Status: 'Definitivo',
      UpdateDate: new Date('01/11/2016'),
    },
    {
      Rfc: 'CMO0706268S6',
      Company: 'CYS MOVILES, S.A. DE C.V.    ',
      Status: 'Definitivo',
      UpdateDate: new Date('01/03/2017'),
    },
    {
      Rfc: 'CMO070907SW0',
      Company: 'CONSTRUCTORA MONTESCLAROS, S.A. DE C.V.',
      Status: 'Desvirtuado',
      UpdateDate: new Date('18/09/2017'),
    },
    {
      Rfc: 'CMO110604MQ3',
      Company: 'COMERCIALIZADORA MÚLTIPLE DEL ORIENTE, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/05/2017'),
    },
    {
      Rfc: 'CMO1109082T1',
      Company: '3C MAQUINARIA Y OBRA, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/11/2019'),
    },
    {
      Rfc: 'CMO111003IDA',
      Company: 'COMERCIALIZADORA METALURGICA DE OCCIDENTE S.C. DE R.L. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/12/2014'),
    },
    {
      Rfc: 'CMO120320B31',
      Company: 'COMERCIALIZADORA MONMAR, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2017'),
    },
    {
      Rfc: 'CMO1206121J0',
      Company: 'COMERCIALIZADORA MOPAD S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/08/2014'),
    },
    {
      Rfc: 'CMO121127LR8',
      Company: 'CONSTRUCCION Y MANTENIMIENTO OCC, S. DE R.L. DE C.V.	Sentencia',
      Status: 'Favorable',
      UpdateDate: new Date('01/12/2016'),
    },
    {
      Rfc: 'CMO130726F80',
      Company: 'COMERCIALIZADORA MOURAH, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/05/2018'),
    },
    {
      Rfc: 'CMO1403217U3',
      Company: 'COMERCIALIZADORA MORIREX, S.C.',
      Status: 'Presunto',
      UpdateDate: new Date('01/06/2017'),
    },
    {
      Rfc: 'CMO1503092J1',
      Company: 'SOCIEDAD COMERCIAL MONKSWELL, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/05/2017'),
    },
    {
      Rfc: 'CMP110803QZ7',
      Company: 'COMERCIALIZADORA DE MATERIALES PLATINO CADENA, S.A. DE C.V. ',
      Status: 'Definitivo',
      UpdateDate: new Date('02/07/2018'),
    },
    {
      Rfc: 'CMP120810M21',
      Company: 'CONSTRUCTORA MSR PROYECTO Y DISEÑO, S. DE R.L. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('05/02/2019'),
    },
    {
      Rfc: 'CMP1209052M0',
      Company: 'COMERCIALIZADORA Y MAQUILADORA PREMIUM SERVICES S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/04/2014'),
    },
    {
      Rfc: 'CMP131217CD2',
      Company: 'CONSTRUCCIONES Y MATERIALES DEL PACÍFICO ZIMA, S. DE R.L. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/09/2017'),
    },
    {
      Rfc: 'CMP140404IG4',
      Company: 'COMERCIALIZADORA MODERNIDAD DEL PACÍFICO, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/08/2017'),
    },
    {
      Rfc: 'CMR100325H69',
      Company: 'Constructora Montecillos  Ramírez, S.A. de C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/09/2015'),
    },
    {
      Rfc: 'CMR110204M82',
      Company: 'CONCENTRADORA DE MATERIALES RECICLADOS, S.A. DE C.V.	Sentencia',
      Status: 'Favorable',
      UpdateDate: new Date('01/07/2017'),
    },
    {
      Rfc: 'CMR120724UL0',
      Company: 'COMERCIALIZADORA MAYORISTA DEL RINCÓN, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('31/10/2017'),
    },
    {
      Rfc: 'CMR131129PS0',
      Company: 'COMERCIALIZADORA MRD, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('31/10/2017'),
    },
    {
      Rfc: 'CMR1410024E0',
      Company: 'COMERCIALIZADORA MRV, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/08/2017'),
    },
    {
      Rfc: 'CMR1412027FA',
      Company: 'CONSTRUCCIONES Y MATERIALES ROSIT, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('02/09/2019'),
    },
    {
      Rfc: 'CMS090210PX5',
      Company: 'CONSTRUCCIONES, MAQUILAS Y SUMINISTROS INDUSTRIALES OREON, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/01/2018'),
    },
    {
      Rfc: 'CMS120801BU2',
      Company: 'COMERCIALIZADORA MEXICANA DE SERVICIOS DE CALL CENTER, S.A. DE C.V. ',
      Status: 'Definitivo',
      UpdateDate: new Date('02/08/2018'),
    },
    {
      Rfc: 'CMS130306MC9',
      Company: 'CONSTRUCCIONES MARES DEL SUR, SA DE CV',
      Status: 'Definitivo',
      UpdateDate: new Date('01/01/2017'),
    },
    {
      Rfc: 'CMS130424LL8',
      Company: 'CONSTRUCCION MANTENIMIENTO SUPERVISION GRAN, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/03/2017'),
    },
    {
      Rfc: 'CMS150311Q30',
      Company: 'COMERCIALIZADORA MAR SUREÑO, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2018'),
    },
    {
      Rfc: 'CMS151001BN2',
      Company: 'COMERCIALIZADORA DE MATERIALES Y SERVICIOS PERAMA, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('02/10/2018'),
    },
    {
      Rfc: 'CMT071204IT3',
      Company: 'CONSTRUCCION Y MONTAJES DE TUXPAN, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/03/2017'),
    },
    {
      Rfc: 'CMT1009241W6',
      Company: 'COORDINADORA MTP, S.A. DE C.V. ',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2018'),
    },
    {
      Rfc: 'CMT1112128Z8',
      Company: 'COMERCIALIZADORA 1363, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/01/2017'),
    },
    {
      Rfc: 'CMT120124911',
      Company: 'COPPER METALS TRADER, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/12/2017'),
    },
    {
      Rfc: 'CMT120227I10',
      Company: 'CONSTRUCCIONES MATERIALES Y TUBOS PIEDRA ROJA, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/09/2016'),
    },
    {
      Rfc: 'CMT121001NH3',
      Company: 'CONSTRUCCIÓN Y MULTISERVICIOS TORRES, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/03/2018'),
    },
    {
      Rfc: 'CMT130604B61',
      Company: 'COMERCIAL DE METALES TRIUNFO, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/10/2019'),
    },
    {
      Rfc: 'CMU081003M56',
      Company: 'CSI MULTISERVICIOS, S.A. DE C.V. ',
      Status: 'Definitivo',
      UpdateDate: new Date('01/12/2016'),
    },
    {
      Rfc: 'CMU1003034W4',
      Company: 'COMERCIALIZADORA MUNDITECNO, S.A. DE C.V.	Sentencia',
      Status: 'Favorable',
      UpdateDate: new Date('01/05/2018'),
    },
    {
      Rfc: 'CMU1103251GA',
      Company: 'CONSULTORÍA MUUK, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/01/2018'),
    },
    {
      Rfc: 'CMU110727LX3',
      Company: 'CONSTRUCCIONES Y MODIFICACIONES ULBATRAK, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/04/2015'),
    },
    {
      Rfc: 'CMU111216P27',
      Company: 'CALZADO MUÑECA, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/06/2018'),
    },
    {
      Rfc: 'CMU130808EK3',
      Company: 'COMERCIALIZADORA MURSI, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/12/2017'),
    },
    {
      Rfc: 'CMU1310169W3',
      Company: 'COMERCIALIZADORA MUDLER, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('02/08/2018'),
    },
    {
      Rfc: 'CMU1510303X6',
      Company: 'CONSTRUCTORA MURAKOTE S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/02/2017'),
    },
    {
      Rfc: 'CMV1304125U8',
      Company: 'COMERCIALIZADORA Y MAQUILADORA VALZUR S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/05/2014'),
    },
    {
      Rfc: 'CMX110601MXA',
      Company: 'CONSULTORIA MX, S.C.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/10/2016'),
    },
    {
      Rfc: 'CMZ1209132E7',
      Company: 'CONSTRUEDIFICACIONES Y MANTENIMIENTO ZAMORA & RAMIREZ, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('05/02/2019'),
    },
    {
      Rfc: 'CMZ121108UA9',
      Company: 'COMERCIALIZADORA Y MAQUILADORA EL ZARCO S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/05/2014'),
    },
    {
      Rfc: 'CNA050421QFA',
      Company: 'CONSTRUCCIONES NAZARO S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/09/2014'),
    },
    {
      Rfc: 'CNA0901291R5',
      Company: 'CONSTRUCTORA NAKBE, S.A. DE C.V.',
      Status: 'Definitivo',
      UpdateDate: new Date('01/03/2018'),
    },
    {
      Rfc: 'CNA090625KL3',
      Company: 'COUNTRA NACIONAL, S.A. DE C.V.',
      Status: 'Desvirtuado',
      UpdateDate: new Date('01/11/2015'),
    },
  ];
}
