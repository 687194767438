export class VSInvoicesTable {
  public static invoices: any[] = [
    {
      id: 1101,
      emisorId: 1,
      Serie: 'X',
      Folio: '1864',
      Fecha: '2020-05-09T12:16:33.229Z',
      SubTotal: 6110.22,
      Descuento: null,
      Total: 6110.22,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20482',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Ba6eBEa9-c7e0-27F1-2913-a17ddcbA2Cfc',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 1,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 7,
        invoiceId: 1,
        Rfc: 'WXM648314LC6',
        Nombre: 'Anna-diana Palffrey',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 1102,
      emisorId: 1,
      Serie: 'X',
      Folio: '1819',
      Fecha: '2020-05-29T12:16:33.229Z',
      SubTotal: 8388.81,
      Descuento: null,
      Total: 8388.81,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20816',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '8ef8d11A-d2Fe-2057-2fC0-deF1Bab3C6f6',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 2,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 8,
        invoiceId: 2,
        Rfc: 'WNY581872LE4',
        Nombre: 'Xena Nagle',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 1103,
      emisorId: 1,
      Serie: 'X',
      Folio: '1892',
      Fecha: '2020-05-04T12:16:33.229Z',
      SubTotal: 1694.75,
      Descuento: null,
      Total: 1694.75,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20657',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'd7bAD2dA-5bFc-e37b-AFa8-C3F3BDedbD4d',
      Status: 0,
      EsIngreso: 1,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 3,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 9,
        invoiceId: 3,
        Rfc: 'GWR055341SJ2',
        Nombre: 'Rickie Brasseur',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 1104,
      emisorId: 1,
      Serie: 'X',
      Folio: '1118',
      Fecha: '2020-05-29T12:16:33.229Z',
      SubTotal: 6361.27,
      Descuento: null,
      Total: 6361.27,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20609',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '80Cff23f-45AE-B25F-153a-ab108853f96b',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 4,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 10,
        invoiceId: 4,
        Rfc: 'TMW099869DE5',
        Nombre: 'Evelina Ritson',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 1105,
      emisorId: 1,
      Serie: 'X',
      Folio: '1254',
      Fecha: '2020-05-11T12:16:33.229Z',
      SubTotal: 7509.15,
      Descuento: null,
      Total: 7509.15,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20126',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '97498df1-C5C0-fAFD-7a7c-Dbe1e2D4AAcD',
      Status: 1,
      EsIngreso: 1,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 5,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 11,
        invoiceId: 5,
        Rfc: 'YOD950278ZF3',
        Nombre: 'Joscelin O\'Gready',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
    },
    {
      id: 1196,
      emisorId: 1,
      Serie: 'X',
      Folio: '1997',
      Fecha: '2020-05-07T12:16:33.229Z',
      SubTotal: 5444.6,
      Descuento: null,
      Total: 5444.6,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20688',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Db9C9a0C-B5dF-06A4-3Dd2-0c555D44b44b',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 11,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 1197,
      emisorId: 1,
      Serie: 'X',
      Folio: '1663',
      Fecha: '2020-05-01T12:16:33.229Z',
      SubTotal: 5213.99,
      Descuento: null,
      Total: 5213.99,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20447',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'bcce80bf-FdaB-C87c-b9E1-8e0Aea45ac78',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 12,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 1198,
      emisorId: 1,
      Serie: 'X',
      Folio: '1107',
      Fecha: '2020-05-19T12:16:33.229Z',
      SubTotal: 1116.46,
      Descuento: null,
      Total: 1116.46,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'E',
      MetodoPago: 'PUE',
      LugarExpedicion: '20118',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '0aDa0D58-fFaB-7cbf-13fC-Cdb8AfbBE45B',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 13,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 1199,
      emisorId: 1,
      Serie: 'X',
      Folio: '1146',
      Fecha: '2020-06-11T12:16:33.229Z',
      SubTotal: 8611.3,
      Descuento: null,
      Total: 8611.3,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20995',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'ed3B56BC-6d55-5dAF-3A25-f70aA40Badcd',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 14,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    },
    {
      id: 1100,
      emisorId: 1,
      Serie: 'X',
      Folio: '1086',
      Fecha: '2020-06-14T12:16:33.229Z',
      SubTotal: 6185.73,
      Descuento: null,
      Total: 6185.73,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'I',
      MetodoPago: 'PUE',
      LugarExpedicion: '20042',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '189752cB-AccB-4fdF-4E33-1a05e7e6BbcE',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 15,
        invoiceId: 100,
        Rfc: 'TZS316456HY5',
        Nombre: 'Tori Roskruge',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Receptor: {
        id: 97,
        invoiceId: 100,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      }
    }
  ];
}
