<div class="kt-portlet">

    <div  class="kt-portlet__head kt-portlet__head__custom portlet__head">
        <div class="kt-portlet__head-label portlet__head--label"> 
          <h3 class="kt-portlet__head-title portlet__head--title">Contáctanos</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
   
    <div class="kt-form" style="background-color: #00b1e5;">
      
        <div class="kt-portlet__body">
            <form [formGroup]="formContacto">

                <div class="form__input">
                    <span class="label_Form">Nombre</span>
                    <mat-form-field class="form__input" appearance="outline">
                      <input formControlName="nombre" matInput autocomplete="off" required>
                      <mat-error>
                        El nombre es <strong>obligatorio</strong>
                      </mat-error>
                    </mat-form-field>
                </div>

                <div class="form__input">
                    <span class="label_Form">Correo</span>
                    <mat-form-field class="form__input" appearance="outline">
                        <input formControlName="correo" type="email" matInput autocomplete="off" required>
                        <mat-error *ngIf="formContacto.get('correo').hasError('email') && !formContacto.get('correo').hasError('required')">
                            El correo electrónico <strong>no es válido</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('correo').hasError('required')">
                            El correo electrónico es <strong>obligatorio</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form__input">
                    <span class="label_Form">WhatsApp</span>
                    <mat-form-field class="form__input" appearance="outline">
                        <input formControlName="whatsapp" type="tel" matInput maxlength="10" required>
                        <mat-error *ngIf="formContacto.get('whatsapp').hasError('minlength') && formContacto.get('whatsapp').hasError('maxLength')">
                            La número debe tener al menos <strong>8 caracteres</strong>
                        </mat-error>
                        <mat-error *ngIf="formContacto.get('whatsapp').hasError('required')">
                            El número es <strong>obligatorio</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <div class="form__input">
                    <span class="label_Form">Cuentanos tu Necesidad</span>
                    <mat-form-field class="form__input" appearance="outline">
                      <input formControlName="necesidad" matInput autocomplete="off" required>
                      <mat-error *ngIf="formContacto.get('necesidad').hasError('required')">
                        Este campo es <strong>obligatorio</strong>
                    </mat-error>
                    </mat-form-field>
                </div>

                <div class="kt-margin-t-10 kt-margin-b-20">
                </div>
            </form>
        </div>


    
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <button id="ButtonFooter2" mat-flat-button
                        class="footer-boton font-poppins font-size-17 kt-margin-r-20"
                        (click)="contact()" [disabled]="send" style="min-width: 130px; min-height: 36px;">
                    <div *ngIf="!(send$ | async) && !(loading$ | async) && !send" class="kt-margin-r-20 kt-margin-l-20">Enviar
                    </div>
                    <div *ngIf="send" class="kt-margin-r-20 kt-margin-l-20">Enviado</div>
                    <span class="spinner-diff" *ngIf="(loading$ | async)">
                        <mat-spinner class="example-margin" style="margin:0 auto;" [diameter]="20"></mat-spinner>
                    </span>
                </button>
                <span *ngIf="error$ | async">¡Debes llenar todos los campos!</span>
            </div>
        </div>
    </div>
</div>
