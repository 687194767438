<div class="contenedor contenedor-division">
    <div class="texto">

        <h1 class="margen color-body-title">¿Dudas sobre Facturación 4.0?</h1>

        <h4 class="margen color-body-subtitle" style="font-weight: 900;"> Conoce los principales cambios a considerar a partir del 2023 accediendo a nuestra presentación <a class="cursor color-body-text" (click)="register()">aquí</a> </h4>

    </div>

    <div class="imagen">
        <img src="../../../../assets/media/visoor/images/dudas/DUDAS FACTURACIÓN.png">
    </div>
   
</div>


