export class ActivityLogsTable {
  public static logs: any[] = [
    {
      id: 1,
      invoiceSenderId: 2,
      Description: 'Se inició sesión',
      Date: new Date(),
    },
    {
      id: 2,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 3,
      invoiceSenderId: 2,
      Description: 'Se autogeneró la factura del pago mensual',
      Date: new Date(new Date().setDate(new Date().getDate() - 2)),
    },
    {
      id: 4,
      invoiceSenderId: 2,
      Description: 'Se realizaon 10 facturas',
      Date: new Date(new Date().setDate(new Date().getDate() - 3)),
    },
    {
      id: 5,
      invoiceSenderId: 2,
      Description: 'Se presentó el 32D',
      Date: new Date(new Date().setDate(new Date().getDate() - 4)),
    },
    {
      id: 6,
      invoiceSenderId: 2,
      Description: 'Se creó el usuario David',
      Date: new Date(new Date().setDate(new Date().getDate() - 5)),
    },
    {
      id: 7,
      invoiceSenderId: 2,
      Description: 'Se agregaron 3 RFC adicionales',
      Date: new Date(new Date().setDate(new Date().getDate() - 6)),
    },
    {
      id: 8,
      invoiceSenderId: 2,
      Description: 'Se realizó el pago mensual por $149.00',
      Date: new Date(new Date().setDate(new Date().getDate() - 7)),
    },
    {
      id: 9,
      invoiceSenderId: 2,
      Description: 'Se han descargado 100 Facturas de Ingresos',
      Date: new Date(new Date().setDate(new Date().getDate() - 8)),
    },
    {
      id: 10,
      invoiceSenderId: 2,
      Description: 'Se creó la cuenta',
      Date: new Date(new Date().setDate(new Date().getDate() - 9)),
    },
    {
      id: 11,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 12,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 13,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 14,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 15,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 16,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 17,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 18,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 19,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 20,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 21,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 22,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      id: 23,
      invoiceSenderId: 2,
      Description: 'Se descargarón listas negras',
      Date: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
  ];
}
