export class PayrollInvoicesTable {
  public static invoices: any[] = [
    {
      emisorId: 1,
      Serie: 'N',
      Folio: '100',
      Fecha: new Date(),
      SubTotal: 1956.50,
      Descuento: 46.70,
      Total: 1909.8,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20482',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'Ba6eBEa9-c7e0-27F1-3913-a17ddcbA2Cfc',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 1,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 7,
        invoiceId: 1,
        Rfc: 'WXM648314LC6',
        Nombre: 'Anna-diana Palffrey',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Complementos: {
        Nomina: {
          Version: '1.2',
          TipoNomina: 'O',
          FechaPago: '2020-05-15',
          FechaInicialPago: '2020-05-01',
          FechaFinalPago: '2020-05-15',
          NumDiasPagados: 15,
          TotalPercepciones: 1875.00,
          TotalDeducciones: 46.70,
          TotalOtrosPagos: 81.50,
        }
      }
    },
    {
      emisorId: 1,
      Serie: 'N',
      Folio: '101',
      Fecha: new Date(),
      SubTotal: 120.00,
      Descuento: null,
      Total: 120.00,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20816',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: '8ef8d21A-d2Fe-2057-2fC0-deF1Bab3C6f6',
      Status: 1,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 2,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 8,
        invoiceId: 2,
        Rfc: 'WNY581872LE4',
        Nombre: 'Xena Nagle',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Complementos: {
        Nomina: {
          Version: '1.2',
          FechaFinalPago: '2017-01-14',
          FechaInicialPago: '2017-01-14',
          FechaPago: '2017-01-14',
          NumDiasPagados: 15,
          TipoNomina: 'O',
          TotalPercepciones: 120.00,
        }
      }
    },
    {
      emisorId: 1,
      Serie: 'N',
      Folio: '102',
      Fecha: new Date(),
      SubTotal: 2694.75,
      Descuento: null,
      Total: 2694.75,
      FormaPago: '99',
      CondicionesDePago: '',
      Moneda: 'MXN',
      TipoCambio: null,
      TipoDeComprobante: 'N',
      MetodoPago: 'PUE',
      LugarExpedicion: '20657',
      Version: '',
      Certificado: '',
      Sello: '',
      NoCertificado: '',
      Confirmacion: '',
      UUID: 'd7cAD2dA-5bFc-e37b-AFa8-C3F3BDedbD4d',
      Status: 0,
      EsIngreso: 0,
      FechaStatus: null,
      Emisor: {
        id: 5,
        invoiceId: 3,
        Rfc: 'TCM9706253B1',
        Nombre: 'Prueba Visoor 2.0 SA de CV',
        RegimenFiscal: '612'
      },
      Receptor: {
        id: 9,
        invoiceId: 3,
        Rfc: 'GWR055341SJ2',
        Nombre: 'Rickie Brasseur',
        ResidenciaFiscal: null,
        NumRegIdTrib: null,
        UsoCFDI: 'P01'
      },
      Complementos: {
        Nomina: {
          Version: '1.2',
          TipoNomina: 'O',
          FechaPago: '2020-05-15',
          FechaInicialPago: '2020-05-01',
          FechaFinalPago: '2020-05-15',
          NumDiasPagados: 15,
          TotalPercepciones: 2875.00,
          TotalDeducciones: 46.70,
          TotalOtrosPagos: 81.50,
        }
      }
    },
  ];
}
