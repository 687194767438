export class InvoiceCatClaveProdServTable {
  // tslint:disable-next-line: variable-name
  public static invoice_cat_clave_prod_serv: any[] = [
    { Clave: '01010101', Descripcion: 'No existe en el catálogo' },
    { Clave: '10101500', Descripcion: 'Animales vivos de granja' },
    { Clave: '10101501', Descripcion: 'Gatos vivos' },
    { Clave: '10101502', Descripcion: 'Perros' },
    { Clave: '10101504', Descripcion: 'Visón' },
    { Clave: '10101505', Descripcion: 'Ratas' },
    { Clave: '10101506', Descripcion: 'Caballos' },
    { Clave: '10101507', Descripcion: 'Ovejas' },
    { Clave: '10101508', Descripcion: 'Cabras' },
    { Clave: '10101509', Descripcion: 'Asnos' },
    { Clave: '10101510', Descripcion: 'Ratones' },
    { Clave: '10101511', Descripcion: 'Cerdos' },
    { Clave: '10101512', Descripcion: 'Conejos' },
    { Clave: '10101513', Descripcion: 'Cobayas o conejillos de indias' },
    { Clave: '10101514', Descripcion: 'Primates' },
    { Clave: '10101515', Descripcion: 'Armadillos' },
    { Clave: '10101516', Descripcion: 'Ganado vacuno' },
    { Clave: '10101517', Descripcion: 'Camellos' },
    { Clave: '10101600', Descripcion: 'Pájaros y aves de corral vivos' },
    { Clave: '10101601', Descripcion: 'Pollos vivos' },
    { Clave: '10101602', Descripcion: 'Patos vivos' },
    { Clave: '10101603', Descripcion: 'Pavos vivos' },
    { Clave: '10101604', Descripcion: 'Gansos vivos' },
    { Clave: '10101605', Descripcion: 'Faisanes vivos' },
    { Clave: '10101700', Descripcion: 'Peces vivos' },
    { Clave: '10101701', Descripcion: 'Salmones vivos' },
    { Clave: '10101702', Descripcion: 'Trucha viva' },
    { Clave: '10101703', Descripcion: 'Tilapia viva' },
    { Clave: '10101704', Descripcion: 'Carpa viva' },
    { Clave: '10101705', Descripcion: 'Anguilas vivas' },
    { Clave: '10101800', Descripcion: 'Mariscos e invertebrados acuáticos vivos' },
    { Clave: '10101801', Descripcion: 'Camarón vivo' },
    { Clave: '10101802', Descripcion: 'Almejas vivas' },
    { Clave: '10101803', Descripcion: 'Mejillones vivos' },
    { Clave: '10101804', Descripcion: 'Ostras vivas' },
    { Clave: '10101805', Descripcion: 'Cangrejos vivos' },
    { Clave: '10101806', Descripcion: 'Abulones vivos' },
    { Clave: '10101807', Descripcion: 'Pulpo vivo' },
    { Clave: '10101808', Descripcion: 'Calamar vivo' },
    { Clave: '10101809', Descripcion: 'Sanguijuelas' },
    { Clave: '10101900', Descripcion: 'Insectos vivos' },
    { Clave: '10101901', Descripcion: 'Mariposas' },
    { Clave: '10101902', Descripcion: 'Escarabajos' },
    { Clave: '10101903', Descripcion: 'Abejas' },
    { Clave: '10101904', Descripcion: 'Gusanos de seda' },
    { Clave: '10102000', Descripcion: 'Animales salvajes vivos' },
    { Clave: '10102001', Descripcion: 'Elefantes' },
    { Clave: '10102002', Descripcion: 'Zorros vivos' },
    { Clave: '10102100', Descripcion: 'Huevos de aves para incubar' },
    { Clave: '10111300', Descripcion: 'Accesorios, equipo y tratamientos para los animales domésticos' },
    { Clave: '10111301', Descripcion: 'Juguetes para mascotas' },
    { Clave: '10111302', Descripcion: 'Productos para el aseo y cuidado de mascotas' },
    { Clave: '10111303', Descripcion: 'Equipo para el manejo de desperdicios de las mascotas' },
    { Clave: '10111304', Descripcion: 'Tazones o equipo para alimentación de mascotas' },
    { Clave: '10111305', Descripcion: 'Tratamientos medicados para mascotas' },
    { Clave: '10111306', Descripcion: 'Kits para el entrenamiento de mascotas domésticas' },
    { Clave: '10111307', Descripcion: 'Cobijas para mascotas' },
    { Clave: '10121500', Descripcion: 'Pienso para ganado' },
    { Clave: '10121501', Descripcion: 'Salvado de trigo puro' },
    { Clave: '10121502', Descripcion: 'Avena para forraje' },
    { Clave: '10121503', Descripcion: 'Maíz para forraje' },
    { Clave: '10121504', Descripcion: 'Sorgo para forraje' },
    { Clave: '10121505', Descripcion: 'Heno' },
    { Clave: '10121506', Descripcion: 'Tortas oleaginosas' },
    { Clave: '10121507', Descripcion: 'Forraje compuesto' },
    { Clave: '10121600', Descripcion: 'Alimento para pájaros y aves de corral' },
    { Clave: '10121601', Descripcion: 'Alimento vivo para aves' },
    { Clave: '10121602', Descripcion: 'Alpiste' },
    { Clave: '10121603', Descripcion: 'Botanas o comida recreacional para aves' },
    { Clave: '10121604', Descripcion: 'Alimento avícola' },
    { Clave: '10121700', Descripcion: 'Alimento para peces' },
    { Clave: '10121701', Descripcion: 'Salmuera fresca o congelada' },
    { Clave: '10121702', Descripcion: 'Alimento granulado para peces' },
    { Clave: '10121703', Descripcion: 'Alimento en hojuelas para peces' },
    { Clave: '10121800', Descripcion: 'Alimento para perros y gatos' },
    { Clave: '10121801', Descripcion: 'Comida seca para perros' },
    { Clave: '10121802', Descripcion: 'Comida húmeda para perros' },
    { Clave: '10121803', Descripcion: 'Leche para perros o gatos' },
    { Clave: '10121804', Descripcion: 'Comida seca para gatos' },
    { Clave: '10121805', Descripcion: 'Comida húmeda para gatos' },
    { Clave: '10121806', Descripcion: 'Botanas o comida recreacional para gatos o perros' },
    { Clave: '10121900', Descripcion: 'Alimento para roedores' },
    { Clave: '10121901', Descripcion: 'Comida granulada para roedores' },
    { Clave: '10122000', Descripcion: 'Alimento para reptiles' },
    { Clave: '10122001', Descripcion: 'Comida granulada para reptiles' },
    { Clave: '10122002', Descripcion: 'Comida húmeda para reptiles' },
    { Clave: '10122003', Descripcion: 'Comida viva para reptiles' },
    { Clave: '10122100', Descripcion: 'Comida para animales variados' },
    { Clave: '10122101', Descripcion: 'Comida para cerdos' },
    { Clave: '10122102', Descripcion: 'Comida para visones' },
    { Clave: '10122103', Descripcion: 'Comida para monos' },
    { Clave: '10122104', Descripcion: 'Comida para conejos' },
    { Clave: '10131500', Descripcion: 'Cobertizos para animales' },
    { Clave: '10131506', Descripcion: 'Establos para ganado' },
    { Clave: '10131507', Descripcion: 'Casas para mascotas domesticadas' },
    { Clave: '10131508', Descripcion: 'Camas para mascotas' },
    { Clave: '10131600', Descripcion: 'Recipientes para animales' },
    { Clave: '10131601', Descripcion: 'Jaulas o sus accesorios' },
    { Clave: '10131602', Descripcion: 'Perreras' },
    { Clave: '10131603', Descripcion: 'Equipaje para el transporte de animales' },
    { Clave: '10131604', Descripcion: 'Correas para perros' },
    { Clave: '10131700', Descripcion: 'Hábitat para animales' },
    { Clave: '10131701', Descripcion: 'Terrarios' },
    { Clave: '10131702', Descripcion: 'Acuarios' },
    { Clave: '10141500', Descripcion: 'Talabartería' },
    { Clave: '10141501', Descripcion: 'Sillas de montar' },
    { Clave: '10141502', Descripcion: 'Fustas y látigos' },
    { Clave: '10141503', Descripcion: 'Herraduras para caballo' },
    { Clave: '10141504', Descripcion: 'Herraduras para mula' },
    { Clave: '10141505', Descripcion: 'Sillín o pelero' },
    { Clave: '10141600', Descripcion: 'Arneses' },
    { Clave: '10141601', Descripcion: 'Bridas' },
    { Clave: '10141602', Descripcion: 'Yugos' },
    { Clave: '10141603', Descripcion: 'Bocados para caballos' },
    { Clave: '10141604', Descripcion: 'Riendas' },
    { Clave: '10141605', Descripcion: 'Estribos' },
    { Clave: '10141606', Descripcion: 'Correas o traíllas' },
    { Clave: '10141607', Descripcion: 'Arneses de cuello para animales' },
    { Clave: '10141608', Descripcion: 'Arneses o sus accesorios' },
    { Clave: '10141609', Descripcion: 'Sujetadores' },
    { Clave: '10141610', Descripcion: 'Bozales' },
    { Clave: '10141611', Descripcion: 'Soportes para correas' },
    { Clave: '10151500', Descripcion: 'Semillas y plántulas vegetales' },
    { Clave: '10151501', Descripcion: 'Semillas o plántulas de fríjol' },
    { Clave: '10151502', Descripcion: 'Semillas o plántulas de zanahoria' },
    { Clave: '10151503', Descripcion: 'Semillas o plántulas de apio' },
    { Clave: '10151504', Descripcion: 'Semillas o plántulas de chiles' },
    { Clave: '10151505', Descripcion: 'Semillas o plántulas de calabacín' },
    { Clave: '10151506', Descripcion: 'Semillas o plántulas de alverja' },
    { Clave: '10151507', Descripcion: 'Semillas o plántulas de pepino cohombro' },
    { Clave: '10151508', Descripcion: 'Semillas o plántulas de berenjena' },
    { Clave: '10151509', Descripcion: 'Semillas o plántulas de endivias' },
    { Clave: '10151510', Descripcion: 'Semillas o plántulas de ajo' },
    { Clave: '10151511', Descripcion: 'Semillas o plántulas de puerro' },
    { Clave: '10151512', Descripcion: 'Semillas o plántulas de lechuga' },
    { Clave: '10151513', Descripcion: 'Semillas o plántulas de maíz' },
    { Clave: '10151514', Descripcion: 'Semillas o plántulas de melón' },
    { Clave: '10151515', Descripcion: 'Semillas o plántulas cebolla' },
    { Clave: '10151516', Descripcion: 'Semillas o plántulas de soya' },
    { Clave: '10151517', Descripcion: 'Semillas o plántulas de espinaca' },
    { Clave: '10151518', Descripcion: 'Semillas o plántulas de tomate' },
    { Clave: '10151519', Descripcion: 'Semillas o plántulas de nabo' },
    { Clave: '10151520', Descripcion: 'Semillas o plántulas de acelga' },
    { Clave: '10151521', Descripcion: 'Semillas o plántulas de pimiento morrón' },
    { Clave: '10151522', Descripcion: 'Semillas o plántulas de remolacha' },
    { Clave: '10151523', Descripcion: 'Semillas o plántulas de coliflor' },
    { Clave: '10151524', Descripcion: 'Semillas o plántulas de perejil' },
    { Clave: '10151525', Descripcion: 'Semillas o plántulas de brócoli' },
    { Clave: '10151526', Descripcion: 'Semillas o plántulas de repollo' },
    { Clave: '10151527', Descripcion: 'Semillas o plántulas de papa' },
    { Clave: '10151528', Descripcion: 'Semillas o plántulas de batata' },
    { Clave: '10151529', Descripcion: 'Semillas o plántulas de calabaza' },
    { Clave: '10151530', Descripcion: 'Semillas o plántulas de rábano' },
    { Clave: '10151531', Descripcion: 'Semillas o plántulas de repollitos de bruselas' },
    { Clave: '10151532', Descripcion: 'Semillas o plántulas de ahuyama' },
    { Clave: '10151533', Descripcion: 'Semillas o plántulas de okra' },
    { Clave: '10151534', Descripcion: 'Semillas o plántulas de melón cantalupo' },
    { Clave: '10151535', Descripcion: 'Semillas o plántulas de maní' },
    { Clave: '10151536', Descripcion: 'Semillas o plántulas de caigua' },
    { Clave: '10151537', Descripcion: 'Semillas o plántulas de espárrago' },
    { Clave: '10151538', Descripcion: 'Semillas o plántulas de garbanzo' },
    { Clave: '10151539', Descripcion: 'Semillas o plántulas de haba' },
    { Clave: '10151600', Descripcion: 'Semillas de cereales' },
    { Clave: '10151601', Descripcion: 'Semillas de trigo' },
    { Clave: '10151602', Descripcion: 'Semillas de canola' },
    { Clave: '10151603', Descripcion: 'Semillas de cebada' },
    { Clave: '10151604', Descripcion: 'Semillas de mijo' },
    { Clave: '10151605', Descripcion: 'Semillas de avena' },
    { Clave: '10151606', Descripcion: 'Semillas de ajonjolí' },
    { Clave: '10151607', Descripcion: 'Semillas de linaza' },
    { Clave: '10151608', Descripcion: 'Semillas de aceite de ricino' },
    { Clave: '10151609', Descripcion: 'Semillas de maíz' },
    { Clave: '10151610', Descripcion: 'Semillas de centeno' },
    { Clave: '10151611', Descripcion: 'Semillas de sorgo' },
    { Clave: '10151612', Descripcion: 'Semillas o plántulas de kiwicha' },
    { Clave: '10151613', Descripcion: 'Semillas o plántulas de quínoa' },
    { Clave: '10151700', Descripcion: 'Semillas y plántulas de hierba y forraje' },
    { Clave: '10151701', Descripcion: 'Semillas o plántulas de arroz' },
    { Clave: '10151702', Descripcion: 'Semillas o plántulas de trébol' },
    { Clave: '10151703', Descripcion: 'Semillas o plántulas de alfalfa' },
    { Clave: '10151704', Descripcion: 'Semillas o plántulas de pasto' },
    { Clave: '10151705', Descripcion: 'Semillas o plántulas de veza (gachas / guija)' },
    { Clave: '10151706', Descripcion: 'Semillas o plántulas de guar' },
    { Clave: '10151800', Descripcion: 'Semillas y plántulas de especias' },
    { Clave: '10151801', Descripcion: 'Semillas o plántulas de pimienta' },
    { Clave: '10151802', Descripcion: 'Semillas o plántulas de vainilla' },
    { Clave: '10151803', Descripcion: 'Semillas o plántulas de canela' },
    { Clave: '10151804', Descripcion: 'Semillas o plántulas de clavo de olor' },
    { Clave: '10151805', Descripcion: 'Semillas o plántulas de cilantro' },
    { Clave: '10151806', Descripcion: 'Semillas o plántulas de jengibre' },
    { Clave: '10151807', Descripcion: 'Semillas o plántulas de azafrán' },
    { Clave: '10151808', Descripcion: 'Semillas o plántulas de tomillo' },
    { Clave: '10151809', Descripcion: 'Semillas o plántulas de curry' },
    { Clave: '10151810', Descripcion: 'Semillas o plántulas de mostaza' },
    { Clave: '10151811', Descripcion: 'Semillas o plántulas de ginseng' },
    { Clave: '10151812', Descripcion: 'Semillas o plántulas de champiñones' },
    { Clave: '10151813', Descripcion: 'Semillas o plántulas de sacha inchi' },
    { Clave: '10151814', Descripcion: 'Semillas o plántulas de achiote' },
    { Clave: '10151815', Descripcion: 'Semillas o plántulas de kudzu' },
    { Clave: '10151816', Descripcion: 'Semillas o plántulas de albahaca' },
    { Clave: '10151817', Descripcion: 'Semillas o plántulas de anís' },
    { Clave: '10151900', Descripcion: 'Semillas, bulbos, plántulas y esquejes de flores' },
    { Clave: '10151901', Descripcion: 'Semillas, bulbos, plántulas o esquejes de tulipán' },
    { Clave: '10151902', Descripcion: 'Semillas, plántulas o esquejes de rosa' },
    { Clave: '10151903', Descripcion: 'Semillas, bulbos, plántulas o esquejes de narciso' },
    { Clave: '10151904', Descripcion: 'Semillas de girasol' },
    { Clave: '10151905', Descripcion: 'Bulbos o tallos de jacinto' },
    { Clave: '10151906', Descripcion: 'Bulbos de lirio' },
    { Clave: '10151907', Descripcion: 'Semillas o plántulas de veza' },
    { Clave: '10152000', Descripcion: 'Semillas y esquejes de árboles y arbustos' },
    { Clave: '10152001', Descripcion: 'Semillas o esquejes de árboles frutales' },
    { Clave: '10152002', Descripcion: 'Semillas o esquejes de coníferas' },
    { Clave: '10152003', Descripcion: 'Semillas o esquejes de árboles de frutos secos' },
    { Clave: '10152004', Descripcion: 'Plántulas de latifoliados' },
    { Clave: '10152005', Descripcion: 'Plántulas de coníferas' },
    { Clave: '10152006', Descripcion: 'Semillas o yemas de pino' },
    { Clave: '10152007', Descripcion: 'Semillas o yemas de algarrobo' },
    { Clave: '10152100', Descripcion: 'Residuos que no sean de piensos' },
    { Clave: '10152101', Descripcion: 'Extracción de los residuos de semilla de babool' },
    { Clave: '10152102', Descripcion: 'Residuos de semilla de colza' },
    { Clave: '10152103', Descripcion: 'Residuo de semillas de linaza' },
    { Clave: '10152104', Descripcion: 'Torta de neem' },
    { Clave: '10152200', Descripcion: 'Semillas de cultivos fibrosos y semilleros' },
    { Clave: '10152201', Descripcion: 'Semillas o plántulas de algodón' },
    { Clave: '10152202', Descripcion: 'Semillas o plántulas de lino' },
    { Clave: '10152300', Descripcion: 'Plántulas y semillas de leguminosas' },
    { Clave: '10152400', Descripcion: 'Plántulas y semillas de tubérculos' },
    { Clave: '10161500', Descripcion: 'Árboles y arbustos' },
    { Clave: '10161501', Descripcion: 'Aceitunos' },
    { Clave: '10161502', Descripcion: 'Cafetos' },
    { Clave: '10161503', Descripcion: 'Cacaoteros' },
    { Clave: '10161504', Descripcion: 'Manzanos' },
    { Clave: '10161505', Descripcion: 'Peros' },
    { Clave: '10161506', Descripcion: 'Naranjos' },
    { Clave: '10161507', Descripcion: 'Rododendros' },
    { Clave: '10161508', Descripcion: 'Plantas de te' },
    { Clave: '10161509', Descripcion: 'Coníferas' },
    { Clave: '10161510', Descripcion: 'Abetos rojos (píceas)' },
    { Clave: '10161511', Descripcion: 'Pinos' },
    { Clave: '10161512', Descripcion: 'Abetos' },
    { Clave: '10161513', Descripcion: 'Palmeras' },
    { Clave: '10161514', Descripcion: 'Casuarina' },
    { Clave: '10161515', Descripcion: 'Ciprés' },
    { Clave: '10161516', Descripcion: 'Eucalipto' },
    { Clave: '10161517', Descripcion: 'Árbol de quinua' },
    { Clave: '10161518', Descripcion: 'Magnolio' },
    { Clave: '10161519', Descripcion: 'Mioporo' },
    { Clave: '10161520', Descripcion: 'Acalifa' },
    { Clave: '10161521', Descripcion: 'Tecomaria capensis o madreselva del cabo' },
    { Clave: '10161522', Descripcion: 'Arbusto croton bolaina' },
    { Clave: '10161523', Descripcion: 'Abutillón' },
    { Clave: '10161524', Descripcion: 'Ficus' },
    { Clave: '10161525', Descripcion: 'Lúcumo' },
    { Clave: '10161526', Descripcion: 'Aguacatal' },
    { Clave: '10161527', Descripcion: 'Guanábano' },
    { Clave: '10161528', Descripcion: 'Carambolo' },
    { Clave: '10161529', Descripcion: 'Ciruelo' },
    { Clave: '10161530', Descripcion: 'Árbol de quince' },
    { Clave: '10161600', Descripcion: 'Plantas florales' },
    { Clave: '10161602', Descripcion: 'Poinsettias' },
    { Clave: '10161604', Descripcion: 'Azaleas' },
    { Clave: '10161605', Descripcion: 'Cactos' },
    { Clave: '10161606', Descripcion: 'Ageratum púrpura' },
    { Clave: '10161800', Descripcion: 'Plantas sin flor' },
    { Clave: '10161801', Descripcion: 'Helechos' },
    { Clave: '10161802', Descripcion: 'Hiedras' },
    { Clave: '10161803', Descripcion: 'Filodendros' },
    { Clave: '10161804', Descripcion: 'Líquenes' },
    { Clave: '10161805', Descripcion: 'Vides' },
    { Clave: '10161900', Descripcion: 'Productos florales secos' },
    { Clave: '10161901', Descripcion: 'Vainas secas' },
    { Clave: '10161902', Descripcion: 'Follaje seco' },
    { Clave: '10161903', Descripcion: 'Helechos secos' },
    { Clave: '10161905', Descripcion: 'Ramas y tallos secos' },
    { Clave: '10161906', Descripcion: 'Penachos de gramíneas secos' },
    { Clave: '10161907', Descripcion: 'Flores secas prensadas' },
    { Clave: '10161908', Descripcion: 'Pétalos secos' },
    { Clave: '10171500', Descripcion: 'Abonos orgánicos y nutrientes para plantas' },
    { Clave: '10171501', Descripcion: 'Estiércol o guano' },
    { Clave: '10171502', Descripcion: 'Hormonas para plantas' },
    { Clave: '10171503', Descripcion: 'Harina de pescado' },
    { Clave: '10171504', Descripcion: 'Abono' },
    { Clave: '10171505', Descripcion: 'Nutriente foliar' },
    { Clave: '10171506', Descripcion: 'Humus' },
    { Clave: '10171600', Descripcion: 'Abonos químicos y nutrientes para plantas' },
    { Clave: '10171601', Descripcion: 'Fertilizante nitrogenado' },
    { Clave: '10171602', Descripcion: 'Fertilizante de potasio' },
    { Clave: '10171603', Descripcion: 'Fertilizante de fósforo' },
    { Clave: '10171604', Descripcion: 'Fertilizante de sulfuro' },
    { Clave: '10171605', Descripcion: 'Mezclas de nitrógeno ? fósforo ? potasio ? npk' },
    { Clave: '10171606', Descripcion: 'Fertilizarte de sílice puro' },
    { Clave: '10171607', Descripcion: 'Fertilizante con magnesio' },
    { Clave: '10171608', Descripcion: 'Fertilizante micro elemento' },
    { Clave: '10171609', Descripcion: 'Fertilizante fosfato de sílice' },
    { Clave: '10171610', Descripcion: 'Fertilizante potasio de sílice' },
    { Clave: '10171611', Descripcion: 'Fertilizante de calcio' },
    { Clave: '10171700', Descripcion: 'Herbicidas' },
    { Clave: '10171701', Descripcion: 'Matamalezas' },
    { Clave: '10171702', Descripcion: 'Fungicidas' },
    { Clave: '10171800', Descripcion: 'Acondicionadores de suelos' },
    { Clave: '10171801', Descripcion: 'Acondicionador orgánico de suelos' },
    { Clave: '10171802', Descripcion: 'Acondicionador inorgánico de suelos' },
    { Clave: '10191500', Descripcion: 'Pesticidas o repelentes de plagas' },
    { Clave: '10191506', Descripcion: 'Mata ? roedores' },
    { Clave: '10191507', Descripcion: 'Repelentes de aves' },
    { Clave: '10191508', Descripcion: 'Protectores contra termitas' },
    { Clave: '10191509', Descripcion: 'Insecticidas' },
    { Clave: '10191510', Descripcion: 'Abamectina' },
    { Clave: '10191511', Descripcion: 'Fipronil' },
    { Clave: '10191700', Descripcion: 'Dispositivos para control de plagas' },
    { Clave: '10191701', Descripcion: 'Trampas para control animal' },
    { Clave: '10191703', Descripcion: 'Trampas para el control de insectos voladores' },
    { Clave: '10191704', Descripcion: 'Matamoscas' },
    { Clave: '10191705', Descripcion: 'Lazos' },
    { Clave: '10191706', Descripcion: 'Cepos' },
    { Clave: '10191707', Descripcion: 'Repelente ultrasónico de pestes' },
    { Clave: '10201500', Descripcion: 'Rosales vivos azules o lavanda o púrpura' },
    { Clave: '10201501', Descripcion: 'Rosal vivo allure o sterling 95' },
    { Clave: '10201502', Descripcion: 'Rosal vivo amnesia' },
    { Clave: '10201503', Descripcion: 'Rosal vivo augusta louise' },
    { Clave: '10201504', Descripcion: 'Rosal vivo avant garde' },
    { Clave: '10201505', Descripcion: 'Rosal vivo blue bird' },
    { Clave: '10201506', Descripcion: 'Rosal vivo curiosa' },
    { Clave: '10201507', Descripcion: 'Rosal vivo cool water' },
    { Clave: '10201508', Descripcion: 'Rosal vivo delilah' },
    { Clave: '10201509', Descripcion: 'Rosal vivo double party' },
    { Clave: '10201510', Descripcion: 'Rosal vivo faith' },
    { Clave: '10201511', Descripcion: 'Rosal vivo mami blue o mammy blue' },
    { Clave: '10201512', Descripcion: 'Rosal vivo marítimo' },
    { Clave: '10201513', Descripcion: 'Rosal vivo milano' },
    { Clave: '10201514', Descripcion: 'Rosal vivo mistery' },
    { Clave: '10201515', Descripcion: 'Rosal vivo ocean song o boyfriend' },
    { Clave: '10201516', Descripcion: 'Rosal vivo cezanne púrpura' },
    { Clave: '10201517', Descripcion: 'Rosal vivo purple fragrance' },
    { Clave: '10201518', Descripcion: 'Rosal vivo sanaa' },
    { Clave: '10201519', Descripcion: 'Rosal vivo silverstone' },
    { Clave: '10201520', Descripcion: 'Rosal vivo soulmate' },
    { Clave: '10201521', Descripcion: 'Rosal vivo stranger' },
    { Clave: '10201522', Descripcion: 'Rosal vivo tinted blue' },
    { Clave: '10201523', Descripcion: 'Rosal vivo two faces' },
    { Clave: '10201600', Descripcion: 'Rosales vivos chocolate o marrón' },
    { Clave: '10201601', Descripcion: 'Rosal vivo black lava' },
    { Clave: '10201602', Descripcion: 'Rosal vivo cimarron' },
    { Clave: '10201603', Descripcion: 'Rosal vivo coffee break' },
    { Clave: '10201604', Descripcion: 'Rosal vivo estelle' },
    { Clave: '10201605', Descripcion: 'Rosal vivo gypsy leonidas' },
    { Clave: '10201606', Descripcion: 'Rosal vivo leonidas' },
    { Clave: '10201607', Descripcion: 'Rosal vivo matilda' },
    { Clave: '10201608', Descripcion: 'Rosal vivo sunny leonidas' },
    { Clave: '10201609', Descripcion: 'Rosal vivo terra nostra' },
    { Clave: '10201610', Descripcion: 'Rosal vivo terracotta' },
    { Clave: '10201700', Descripcion: 'Rosales vivos crema' },
    { Clave: '10201701', Descripcion: 'Rosal vivo advenire' },
    { Clave: '10201702', Descripcion: 'Rosal vivo alex' },
    { Clave: '10201703', Descripcion: 'Rosal vivo antique brass' },
    { Clave: '10201704', Descripcion: 'Rosal vivo aubade' },
    { Clave: '10201705', Descripcion: 'Rosal vivo beach' },
    { Clave: '10201706', Descripcion: 'Rosal vivo belle pearl' },
    { Clave: '10201707', Descripcion: 'Rosal vivo blush de los andes' },
    { Clave: '10201708', Descripcion: 'Rosal vivo camel' },
    { Clave: '10201709', Descripcion: 'Rosal vivo caramel antike o caramel antique' },
    { Clave: '10201710', Descripcion: 'Rosal vivo champagne' },
    { Clave: '10201711', Descripcion: 'Rosal vivo clear ocean' },
    { Clave: '10201712', Descripcion: 'Rosal vivo combo' },
    { Clave: '10201713', Descripcion: 'Rosal vivo creme de la creme' },
    { Clave: '10201714', Descripcion: 'Rosal vivo emanuella' },
    { Clave: '10201715', Descripcion: 'Rosal vivo evolution' },
    { Clave: '10201716', Descripcion: 'Rosal vivo fedora' },
    { Clave: '10201717', Descripcion: 'Rosal vivo fenice' },
    { Clave: '10201718', Descripcion: 'Rosal vivo french vanilla' },
    { Clave: '10201719', Descripcion: 'Rosal vivo hollywood' },
    { Clave: '10201720', Descripcion: 'Rosal vivo ilios' },
    { Clave: '10201721', Descripcion: 'Rosal vivo jelena' },
    { Clave: '10201722', Descripcion: 'Rosal vivo kameleon' },
    { Clave: '10201723', Descripcion: 'Rosal vivo kentucky' },
    { Clave: '10201724', Descripcion: 'Rosal vivo kings pride' },
    { Clave: '10201725', Descripcion: 'Rosal vivo latin fusion' },
    { Clave: '10201726', Descripcion: 'Rosal vivo lemon dream' },
    { Clave: '10201727', Descripcion: 'Rosal vivo magic moka' },
    { Clave: '10201728', Descripcion: 'Rosal vivo mamamia' },
    { Clave: '10201729', Descripcion: 'Rosal vivo message' },
    { Clave: '10201730', Descripcion: 'Rosal vivo muneca o munieca' },
    { Clave: '10201731', Descripcion: 'Rosal vivo parfum de rosas' },
    { Clave: '10201732', Descripcion: 'Rosal vivo porcelina' },
    { Clave: '10201733', Descripcion: 'Rosal vivo privilege' },
    { Clave: '10201734', Descripcion: 'Rosal vivo quicksand' },
    { Clave: '10201735', Descripcion: 'Rosal vivo rollercoaster' },
    { Clave: '10201736', Descripcion: 'Rosal vivo romantic curiosa' },
    { Clave: '10201737', Descripcion: 'Rosal vivo safari' },
    { Clave: '10201738', Descripcion: 'Rosal vivo sahara' },
    { Clave: '10201739', Descripcion: 'Rosal vivo sandy femma' },
    { Clave: '10201740', Descripcion: 'Rosal vivo talea' },
    { Clave: '10201741', Descripcion: 'Rosal vivo timeless' },
    { Clave: '10201742', Descripcion: 'Rosal vivo transition' },
    { Clave: '10201743', Descripcion: 'Rosal vivo trump' },
    { Clave: '10201744', Descripcion: 'Rosal vivo twin' },
    { Clave: '10201745', Descripcion: 'Rosal vivo vendela' },
    { Clave: '10201746', Descripcion: 'Rosal vivo virginia' },
    { Clave: '10201800', Descripcion: 'Rosales vivos verdes o lima' },
    { Clave: '10201801', Descripcion: 'Rosal vivo amandine' },
    { Clave: '10201802', Descripcion: 'Rosal vivo caipirinha' },
    { Clave: '10201803', Descripcion: 'Rosal vivo green fashion' },
    { Clave: '10201804', Descripcion: 'Rosal vivo green tea' },
    { Clave: '10201805', Descripcion: 'Rosal vivo jade' },
    { Clave: '10201806', Descripcion: 'Rosal vivo limbo' },
    { Clave: '10201807', Descripcion: 'Rosal vivo limena o limenia' },
    { Clave: '10201808', Descripcion: 'Rosal vivo limona' },
    { Clave: '10201809', Descripcion: 'Rosal vivo old dutch' },
    { Clave: '10201810', Descripcion: 'Rosal vivo super green' },
    { Clave: '10201811', Descripcion: 'Rosal vivo sweet green' },
    { Clave: '10201812', Descripcion: 'Rosal vivo viva' },
    { Clave: '10201813', Descripcion: 'Rosal vivo zazu' },
    { Clave: '10201900', Descripcion: 'Rosales vivos rosado claro' },
    { Clave: '10201901', Descripcion: 'Rosal vivo anna' },
    { Clave: '10201902', Descripcion: 'Rosal vivo bella vita' },
    { Clave: '10201903', Descripcion: 'Rosal vivo bridal dream' },
    { Clave: '10201904', Descripcion: 'Rosal vivo candy bianca' },
    { Clave: '10201905', Descripcion: 'Rosal vivo caress' },
    { Clave: '10201906', Descripcion: 'Rosal vivo carolina' },
    { Clave: '10201907', Descripcion: 'Rosal vivo climax' },
    { Clave: '10201908', Descripcion: 'Rosal vivo danny' },
    { Clave: '10201909', Descripcion: 'Rosal vivo dolce vita' },
    { Clave: '10201910', Descripcion: 'Rosal vivo elite' },
    { Clave: '10201911', Descripcion: 'Rosal vivo emma' },
    { Clave: '10201912', Descripcion: 'Rosal vivo engagement' },
    { Clave: '10201913', Descripcion: 'Rosal vivo esther' },
    { Clave: '10201914', Descripcion: 'Rosal vivo excalibur' },
    { Clave: '10201915', Descripcion: 'Rosal vivo exciting' },
    { Clave: '10201916', Descripcion: 'Rosal vivo first lady' },
    { Clave: '10201917', Descripcion: 'Rosal vivo geraldine' },
    { Clave: '10201918', Descripcion: 'Rosal vivo gotcha' },
    { Clave: '10201919', Descripcion: 'Rosal vivo harmonie' },
    { Clave: '10201920', Descripcion: 'Rosal vivo heaven' },
    { Clave: '10201921', Descripcion: 'Rosal vivo high and elegant' },
    { Clave: '10201922', Descripcion: 'Rosal vivo katherine' },
    { Clave: '10201923', Descripcion: 'Rosal vivo king kong' },
    { Clave: '10201924', Descripcion: 'Rosal vivo livia' },
    { Clave: '10201925', Descripcion: 'Rosal vivo lorena' },
    { Clave: '10201926', Descripcion: 'Rosal vivo lovely' },
    { Clave: '10201927', Descripcion: 'Rosal vivo maaike' },
    { Clave: '10201928', Descripcion: 'Rosal vivo marilyn' },
    { Clave: '10201929', Descripcion: 'Rosal vivo marlise' },
    { Clave: '10201930', Descripcion: 'Rosal vivo miranda o ausimmon' },
    { Clave: '10201931', Descripcion: 'Rosal vivo mona lisa' },
    { Clave: '10201932', Descripcion: 'Rosal vivo nirvana' },
    { Clave: '10201933', Descripcion: 'Rosal vivo o hara' },
    { Clave: '10201934', Descripcion: 'Rosal vivo ole' },
    { Clave: '10201935', Descripcion: 'Rosal vivo olga' },
    { Clave: '10201936', Descripcion: 'Rosal vivo pacífica' },
    { Clave: '10201937', Descripcion: 'Rosal vivo party mix' },
    { Clave: '10201938', Descripcion: 'Rosal vivo peckoubo o pekcoubo' },
    { Clave: '10201939', Descripcion: 'Rosal vivo phoebe o ausnotice' },
    { Clave: '10201940', Descripcion: 'Rosal vivo pink farfalla' },
    { Clave: '10201941', Descripcion: 'Rosal vivo pink finess' },
    { Clave: '10201942', Descripcion: 'Rosal vivo pink magic' },
    { Clave: '10201943', Descripcion: 'Rosal vivo pink osiana' },
    { Clave: '10201944', Descripcion: 'Rosal vivo pretty woman' },
    { Clave: '10201945', Descripcion: 'Rosal vivo romance' },
    { Clave: '10201946', Descripcion: 'Rosal vivo romantic antike o antique' },
    { Clave: '10201947', Descripcion: 'Rosal vivo rosalind o astew' },
    { Clave: '10201948', Descripcion: 'Rosal vivo rosita vendela' },
    { Clave: '10201949', Descripcion: 'Rosal vivo secret garden' },
    { Clave: '10201950', Descripcion: 'Rosal vivo solaire' },
    { Clave: '10201951', Descripcion: 'Rosal vivo sophie' },
    { Clave: '10201952', Descripcion: 'Rosal vivo sweet akito' },
    { Clave: '10201953', Descripcion: 'Rosal vivo sweet avalanche' },
    { Clave: '10201954', Descripcion: 'Rosal vivo sweet elegance' },
    { Clave: '10201955', Descripcion: 'Rosal vivo sweet pink' },
    { Clave: '10201956', Descripcion: 'Rosal vivo titanic' },
    { Clave: '10201957', Descripcion: 'Rosal vivo toscanini' },
    { Clave: '10201958', Descripcion: 'Rosal vivo vania' },
    { Clave: '10201959', Descripcion: 'Rosal vivo vanity' },
    { Clave: '10201960', Descripcion: 'Rosal vivo vision' },
    { Clave: '10201961', Descripcion: 'Rosal vivo vivaldi' },
    { Clave: '10201962', Descripcion: 'Rosal vivo whisper' },
    { Clave: '10202000', Descripcion: 'Rosales vivos multicolor rosados' },
    { Clave: '10202001', Descripcion: 'Rosal vivo attracta' },
    { Clave: '10202002', Descripcion: 'Rosal vivo boheme' },
    { Clave: '10202003', Descripcion: 'Rosal vivo carousel' },
    { Clave: '10202004', Descripcion: 'Rosal vivo cezanne' },
    { Clave: '10202005', Descripcion: 'Rosal vivo crazy one' },
    { Clave: '10202006', Descripcion: 'Rosal vivo dance valley' },
    { Clave: '10202007', Descripcion: 'Rosal vivo duett' },
    { Clave: '10202008', Descripcion: 'Rosal vivo esperance' },
    { Clave: '10202009', Descripcion: 'Rosal vivo fiesta' },
    { Clave: '10202010', Descripcion: 'Rosal vivo halloween' },
    { Clave: '10202011', Descripcion: 'Rosal vivo highlander' },
    { Clave: '10202012', Descripcion: 'Rosal vivo hot ambiance' },
    { Clave: '10202013', Descripcion: 'Rosal vivo la belle' },
    { Clave: '10202014', Descripcion: 'Rosal vivo laguna' },
    { Clave: '10202015', Descripcion: 'Rosal vivo latin ambiance' },
    { Clave: '10202016', Descripcion: 'Rosal vivo latin breeze' },
    { Clave: '10202017', Descripcion: 'Rosal vivo long arifa' },
    { Clave: '10202018', Descripcion: 'Rosal vivo murano' },
    { Clave: '10202019', Descripcion: 'Rosal vivo n-joy rose' },
    { Clave: '10202020', Descripcion: 'Rosal vivo panama' },
    { Clave: '10202021', Descripcion: 'Rosal vivo peppermint' },
    { Clave: '10202022', Descripcion: 'Rosal vivo pijama party' },
    { Clave: '10202023', Descripcion: 'Rosal vivo portofino' },
    { Clave: '10202024', Descripcion: 'Rosal vivo priceless' },
    { Clave: '10202025', Descripcion: 'Rosal vivo queen amazon' },
    { Clave: '10202026', Descripcion: 'Rosal vivo ranuncula' },
    { Clave: '10202027', Descripcion: 'Rosal vivo rossini' },
    { Clave: '10202028', Descripcion: 'Rosal vivo sabina o sabrina' },
    { Clave: '10202029', Descripcion: 'Rosal vivo scandal' },
    { Clave: '10202030', Descripcion: 'Rosal vivo silvery pink' },
    { Clave: '10202031', Descripcion: 'Rosal vivo something else' },
    { Clave: '10202032', Descripcion: 'Rosal vivo soutine' },
    { Clave: '10202033', Descripcion: 'Rosal vivo sovereign' },
    { Clave: '10202034', Descripcion: 'Rosal vivo super disco' },
    { Clave: '10202035', Descripcion: 'Rosal vivo ts 1968' },
    { Clave: '10202036', Descripcion: 'Rosal vivo variance' },
    { Clave: '10202037', Descripcion: 'Rosal vivo verdi' },
    { Clave: '10202100', Descripcion: 'Rosales vivos anaranjados' },
    { Clave: '10202101', Descripcion: 'Rosal vivo alhambra' },
    { Clave: '10202102', Descripcion: 'Rosal vivo aloha' },
    { Clave: '10202103', Descripcion: 'Rosal vivo amber' },
    { Clave: '10202104', Descripcion: 'Rosal vivo apache' },
    { Clave: '10202105', Descripcion: 'Rosal vivo arabia' },
    { Clave: '10202106', Descripcion: 'Rosal vivo bengala' },
    { Clave: '10202107', Descripcion: 'Rosal vivo bibi' },
    { Clave: '10202108', Descripcion: 'Rosal vivo caramba' },
    { Clave: '10202109', Descripcion: 'Rosal vivo caramella' },
    { Clave: '10202110', Descripcion: 'Rosal vivo carla' },
    { Clave: '10202111', Descripcion: 'Rosal vivo cartagena' },
    { Clave: '10202112', Descripcion: 'Rosal vivo chanson' },
    { Clave: '10202113', Descripcion: 'Rosal vivo charmer' },
    { Clave: '10202114', Descripcion: 'Rosal vivo cherry brandy' },
    { Clave: '10202115', Descripcion: 'Rosal vivo chilis' },
    { Clave: '10202116', Descripcion: 'Rosal vivo cinnamon' },
    { Clave: '10202117', Descripcion: 'Rosal vivo colandro' },
    { Clave: '10202118', Descripcion: 'Rosal vivo coral sea' },
    { Clave: '10202119', Descripcion: 'Rosal vivo corvette o red corvette' },
    { Clave: '10202120', Descripcion: 'Rosal vivo dark milva' },
    { Clave: '10202121', Descripcion: 'Rosal vivo donna' },
    { Clave: '10202122', Descripcion: 'Rosal vivo dreamer' },
    { Clave: '10202123', Descripcion: 'Rosal vivo el dorado' },
    { Clave: '10202124', Descripcion: 'Rosal vivo el toro' },
    { Clave: '10202125', Descripcion: 'Rosal vivo elena' },
    { Clave: '10202126', Descripcion: 'Rosal vivo ensueño' },
    { Clave: '10202127', Descripcion: 'Rosal vivo euforia' },
    { Clave: '10202128', Descripcion: 'Rosal vivo exótica' },
    { Clave: '10202129', Descripcion: 'Rosal vivo fancy amazon' },
    { Clave: '10202130', Descripcion: 'Rosal vivo fiction' },
    { Clave: '10202131', Descripcion: 'Rosal vivo finess' },
    { Clave: '10202132', Descripcion: 'Rosal vivo flameco' },
    { Clave: '10202133', Descripcion: 'Rosal vivo free spirit' },
    { Clave: '10202134', Descripcion: 'Rosal vivo gelato' },
    { Clave: '10202135', Descripcion: 'Rosal vivo gypsy curiosa' },
    { Clave: '10202136', Descripcion: 'Rosal vivo high and magic' },
    { Clave: '10202137', Descripcion: 'Rosal vivo high and orange magic' },
    { Clave: '10202138', Descripcion: 'Rosal vivo iguana o alegra' },
    { Clave: '10202139', Descripcion: 'Rosal vivo impulse' },
    { Clave: '10202140', Descripcion: 'Rosal vivo indian femma' },
    { Clave: '10202141', Descripcion: 'Rosal vivo indian sunset' },
    { Clave: '10202142', Descripcion: 'Rosal vivo karusso' },
    { Clave: '10202143', Descripcion: 'Rosal vivo kerio' },
    { Clave: '10202144', Descripcion: 'Rosal vivo kiki' },
    { Clave: '10202145', Descripcion: 'Rosal vivo latin circus' },
    { Clave: '10202146', Descripcion: 'Rosal vivo leonisa' },
    { Clave: '10202147', Descripcion: 'Rosal vivo lipstick' },
    { Clave: '10202148', Descripcion: 'Rosal vivo lobita' },
    { Clave: '10202149', Descripcion: 'Rosal vivo luca' },
    { Clave: '10202150', Descripcion: 'Rosal vivo manitou' },
    { Clave: '10202151', Descripcion: 'Rosal vivo mariana' },
    { Clave: '10202152', Descripcion: 'Rosal vivo marjan o pk sensation' },
    { Clave: '10202153', Descripcion: 'Rosal vivo milonga' },
    { Clave: '10202154', Descripcion: 'Rosal vivo milva' },
    { Clave: '10202155', Descripcion: 'Rosal vivo miracle' },
    { Clave: '10202156', Descripcion: 'Rosal vivo mirage' },
    { Clave: '10202157', Descripcion: 'Rosal vivo monte carlo' },
    { Clave: '10202158', Descripcion: 'Rosal vivo movie star' },
    { Clave: '10202159', Descripcion: 'Rosal vivo nikita' },
    { Clave: '10202160', Descripcion: 'Rosal vivo orange flame' },
    { Clave: '10202161', Descripcion: 'Rosal vivo orange france' },
    { Clave: '10202162', Descripcion: 'Rosal vivo orange intuition' },
    { Clave: '10202163', Descripcion: 'Rosal vivo orange unique' },
    { Clave: '10202164', Descripcion: 'Rosal vivo orangine u orangina' },
    { Clave: '10202165', Descripcion: 'Rosal vivo papaya' },
    { Clave: '10202166', Descripcion: 'Rosal vivo pareo' },
    { Clave: '10202167', Descripcion: 'Rosal vivo peach sherbet' },
    { Clave: '10202168', Descripcion: 'Rosal vivo queensday' },
    { Clave: '10202169', Descripcion: 'Rosal vivo rosselle' },
    { Clave: '10202170', Descripcion: 'Rosal vivo royal circus' },
    { Clave: '10202171', Descripcion: 'Rosal vivo sari' },
    { Clave: '10202172', Descripcion: 'Rosal vivo sensual' },
    { Clave: '10202173', Descripcion: 'Rosal vivo soap' },
    { Clave: '10202174', Descripcion: 'Rosal vivo sombrero' },
    { Clave: '10202175', Descripcion: 'Rosal vivo spicy' },
    { Clave: '10202176', Descripcion: 'Rosal vivo star 2000' },
    { Clave: '10202177', Descripcion: 'Rosal vivo summer versilia' },
    { Clave: '10202178', Descripcion: 'Rosal vivo trixx' },
    { Clave: '10202179', Descripcion: 'Rosal vivo tropical amazon' },
    { Clave: '10202180', Descripcion: 'Rosal vivo utopia' },
    { Clave: '10202181', Descripcion: 'Rosal vivo valentine' },
    { Clave: '10202182', Descripcion: 'Rosal vivo verano' },
    { Clave: '10202183', Descripcion: 'Rosal vivo versilia' },
    { Clave: '10202184', Descripcion: 'Rosal vivo voodoo' },
    { Clave: '10202185', Descripcion: 'Rosal vivo wow' },
    { Clave: '10202186', Descripcion: 'Rosal vivo yabadabadoo' },
    { Clave: '10202200', Descripcion: 'Rosales vivos durazno' },
    { Clave: '10202201', Descripcion: 'Rosal vivo alejandra' },
    { Clave: '10202202', Descripcion: 'Rosal vivo azafran' },
    { Clave: '10202203', Descripcion: 'Rosal vivo big fun' },
    { Clave: '10202204', Descripcion: 'Rosal vivo cabaret' },
    { Clave: '10202205', Descripcion: 'Rosal vivo capuccino' },
    { Clave: '10202206', Descripcion: 'Rosal vivo carpe diem' },
    { Clave: '10202207', Descripcion: 'Rosal vivo cosima' },
    { Clave: '10202208', Descripcion: 'Rosal vivo cumbia' },
    { Clave: '10202209', Descripcion: 'Rosal vivo dream' },
    { Clave: '10202210', Descripcion: 'Rosal vivo epoca' },
    { Clave: '10202211', Descripcion: 'Rosal vivo fado' },
    { Clave: '10202212', Descripcion: 'Rosal vivo femma' },
    { Clave: '10202213', Descripcion: 'Rosal vivo guajira' },
    { Clave: '10202214', Descripcion: 'Rosal vivo high and arena' },
    { Clave: '10202215', Descripcion: 'Rosal vivo high and dandy' },
    { Clave: '10202216', Descripcion: 'Rosal vivo high and lucky' },
    { Clave: '10202217', Descripcion: 'Rosal vivo high and peach' },
    { Clave: '10202218', Descripcion: 'Rosal vivo imagination' },
    { Clave: '10202219', Descripcion: 'Rosal vivo isis' },
    { Clave: '10202220', Descripcion: 'Rosal vivo joy o light versilia' },
    { Clave: '10202221', Descripcion: 'Rosal vivo juliet ausjameson' },
    { Clave: '10202222', Descripcion: 'Rosal vivo la parisienne' },
    { Clave: '10202223', Descripcion: 'Rosal vivo la perla' },
    { Clave: '10202224', Descripcion: 'Rosal vivo lovita sunblaze' },
    { Clave: '10202225', Descripcion: 'Rosal vivo malilena o marilena' },
    { Clave: '10202226', Descripcion: 'Rosal vivo monyna' },
    { Clave: '10202227', Descripcion: 'Rosal vivo nectarine' },
    { Clave: '10202228', Descripcion: 'Rosal vivo oriental curiosa' },
    { Clave: '10202229', Descripcion: 'Rosal vivo osiana' },
    { Clave: '10202230', Descripcion: 'Rosal vivo peach avalanche' },
    { Clave: '10202231', Descripcion: 'Rosal vivo peach deja vu' },
    { Clave: '10202232', Descripcion: 'Rosal vivo picanto' },
    { Clave: '10202233', Descripcion: 'Rosal vivo prima donna' },
    { Clave: '10202234', Descripcion: 'Rosal vivo sheril' },
    { Clave: '10202235', Descripcion: 'Rosal vivo sirocco' },
    { Clave: '10202236', Descripcion: 'Rosal vivo tamara' },
    { Clave: '10202237', Descripcion: 'Rosal vivo taxo' },
    { Clave: '10202238', Descripcion: 'Rosal vivo trust' },
    { Clave: '10202239', Descripcion: 'Rosal vivo valencia' },
    { Clave: '10202240', Descripcion: 'Rosal vivo vinci' },
    { Clave: '10202241', Descripcion: 'Rosal vivo wanda' },
    { Clave: '10202300', Descripcion: 'Rosales vivos rosados' },
    { Clave: '10202301', Descripcion: 'Rosal vivo aerobic' },
    { Clave: '10202302', Descripcion: 'Rosal vivo after party' },
    { Clave: '10202303', Descripcion: 'Rosal vivo amsterdam' },
    { Clave: '10202304', Descripcion: 'Rosal vivo aqua rose' },
    { Clave: '10202305', Descripcion: 'Rosal vivo attache' },
    { Clave: '10202306', Descripcion: 'Rosal vivo attitude' },
    { Clave: '10202307', Descripcion: 'Rosal vivo ballet' },
    { Clave: '10202308', Descripcion: 'Rosal vivo belami' },
    { Clave: '10202309', Descripcion: 'Rosal vivo bella voo o belle vue' },
    { Clave: '10202310', Descripcion: 'Rosal vivo bling bling' },
    { Clave: '10202311', Descripcion: 'Rosal vivo blushing akito' },
    { Clave: '10202312', Descripcion: 'Rosal vivo brooke' },
    { Clave: '10202313', Descripcion: 'Rosal vivo bugatti' },
    { Clave: '10202314', Descripcion: 'Rosal vivo cadillac' },
    { Clave: '10202315', Descripcion: 'Rosal vivo carnaval' },
    { Clave: '10202316', Descripcion: 'Rosal vivo cereza' },
    { Clave: '10202317', Descripcion: 'Rosal vivo charming unique' },
    { Clave: '10202318', Descripcion: 'Rosal vivo cherry o' },
    { Clave: '10202319', Descripcion: 'Rosal vivo ciciolina' },
    { Clave: '10202320', Descripcion: 'Rosal vivo classic cezanne' },
    { Clave: '10202321', Descripcion: 'Rosal vivo clasic duett' },
    { Clave: '10202322', Descripcion: 'Rosal vivo cosmiq' },
    { Clave: '10202323', Descripcion: 'Rosal vivo dark engagement' },
    { Clave: '10202324', Descripcion: 'Rosal vivo daytona' },
    { Clave: '10202325', Descripcion: 'Rosal vivo dekora' },
    { Clave: '10202326', Descripcion: 'Rosal vivo dolores' },
    { Clave: '10202327', Descripcion: 'Rosal vivo eliza' },
    { Clave: '10202328', Descripcion: 'Rosal vivo flash baccara' },
    { Clave: '10202329', Descripcion: 'Rosal vivo full house' },
    { Clave: '10202330', Descripcion: 'Rosal vivo funky' },
    { Clave: '10202331', Descripcion: 'Rosal vivo giliane' },
    { Clave: '10202332', Descripcion: 'Rosal vivo gran europe' },
    { Clave: '10202333', Descripcion: 'Rosal vivo habari' },
    { Clave: '10202334', Descripcion: 'Rosal vivo hanseat' },
    { Clave: '10202335', Descripcion: 'Rosal vivo high and amazing' },
    { Clave: '10202336', Descripcion: 'Rosal vivo high and bonita' },
    { Clave: '10202337', Descripcion: 'Rosal vivo high and booming' },
    { Clave: '10202338', Descripcion: 'Rosal vivo high and fantasy' },
    { Clave: '10202339', Descripcion: 'Rosal vivo high and rich' },
    { Clave: '10202340', Descripcion: 'Rosal vivo hot lady' },
    { Clave: '10202341', Descripcion: 'Rosal vivo hot princess' },
    { Clave: '10202342', Descripcion: 'Rosal vivo inspiration' },
    { Clave: '10202343', Descripcion: 'Rosal vivo jeimy' },
    { Clave: '10202344', Descripcion: 'Rosal vivo kachita' },
    { Clave: '10202345', Descripcion: 'Rosal vivo karen' },
    { Clave: '10202346', Descripcion: 'Rosal vivo kenji' },
    { Clave: '10202347', Descripcion: 'Rosal vivo kiko' },
    { Clave: '10202348', Descripcion: 'Rosal vivo laser' },
    { Clave: '10202349', Descripcion: 'Rosal vivo latin duett' },
    { Clave: '10202350', Descripcion: 'Rosal vivo latin fever' },
    { Clave: '10202351', Descripcion: 'Rosal vivo lifestyle' },
    { Clave: '10202352', Descripcion: 'Rosal vivo light orlando' },
    { Clave: '10202353', Descripcion: 'Rosal vivo lovely dreams' },
    { Clave: '10202354', Descripcion: 'Rosal vivo loyalty' },
    { Clave: '10202355', Descripcion: 'Rosal vivo malibu' },
    { Clave: '10202356', Descripcion: 'Rosal vivo mata-hari' },
    { Clave: '10202357', Descripcion: 'Rosal vivo memphis' },
    { Clave: '10202358', Descripcion: 'Rosal vivo mi amor' },
    { Clave: '10202359', Descripcion: 'Rosal vivo miami' },
    { Clave: '10202360', Descripcion: 'Rosal vivo michelle' },
    { Clave: '10202361', Descripcion: 'Rosal vivo mikaela' },
    { Clave: '10202362', Descripcion: 'Rosal vivo orchestra' },
    { Clave: '10202363', Descripcion: 'Rosal vivo orlando' },
    { Clave: '10202364', Descripcion: 'Rosal vivo osadia' },
    { Clave: '10202365', Descripcion: 'Rosal vivo paeonia freelander' },
    { Clave: '10202366', Descripcion: 'Rosal vivo paula' },
    { Clave: '10202367', Descripcion: 'Rosal vivo pavarotti' },
    { Clave: '10202368', Descripcion: 'Rosal vivo pink intuition' },
    { Clave: '10202369', Descripcion: 'Rosal vivo poison' },
    { Clave: '10202370', Descripcion: 'Rosal vivo princess' },
    { Clave: '10202371', Descripcion: 'Rosal vivo queen mary' },
    { Clave: '10202372', Descripcion: 'Rosal vivo raphaela' },
    { Clave: '10202373', Descripcion: 'Rosal vivo raspberry ice' },
    { Clave: '10202374', Descripcion: 'Rosal vivo ravel' },
    { Clave: '10202375', Descripcion: 'Rosal vivo riviera' },
    { Clave: '10202376', Descripcion: 'Rosal vivo sade' },
    { Clave: '10202377', Descripcion: 'Rosal vivo sashimi' },
    { Clave: '10202378', Descripcion: 'Rosal vivo shanya' },
    { Clave: '10202379', Descripcion: 'Rosal vivo shocking versilia' },
    { Clave: '10202380', Descripcion: 'Rosal vivo solitaire' },
    { Clave: '10202381', Descripcion: 'Rosal vivo something different' },
    { Clave: '10202382', Descripcion: 'Rosal vivo splendid renate' },
    { Clave: '10202383', Descripcion: 'Rosal vivo star' },
    { Clave: '10202384', Descripcion: 'Rosal vivo sweet candia' },
    { Clave: '10202385', Descripcion: 'Rosal vivo sweet moments' },
    { Clave: '10202386', Descripcion: 'Rosal vivo sweet unique' },
    { Clave: '10202387', Descripcion: 'Rosal vivo taboo' },
    { Clave: '10202388', Descripcion: 'Rosal vivo timona' },
    { Clave: '10202389', Descripcion: 'Rosal vivo topaz' },
    { Clave: '10202390', Descripcion: 'Rosal vivo vogue' },
    { Clave: '10202391', Descripcion: 'Rosal vivo voila' },
    { Clave: '10202392', Descripcion: 'Rosal vivo wild one' },
    { Clave: '10202393', Descripcion: 'Rosal vivo yves piaget' },
    { Clave: '10202400', Descripcion: 'Rosales vivos rojos o burgundy' },
    { Clave: '10202401', Descripcion: 'Rosal vivo african dawn' },
    { Clave: '10202402', Descripcion: 'Rosal vivo amada' },
    { Clave: '10202403', Descripcion: 'Rosal vivo black baccara' },
    { Clave: '10202404', Descripcion: 'Rosal vivo black beauty' },
    { Clave: '10202405', Descripcion: 'Rosal vivo black finess o black magic' },
    { Clave: '10202406', Descripcion: 'Rosal vivo black magic' },
    { Clave: '10202407', Descripcion: 'Rosal vivo bohemian o pasarela' },
    { Clave: '10202408', Descripcion: 'Rosal vivo breathless' },
    { Clave: '10202409', Descripcion: 'Rosal vivo caballero' },
    { Clave: '10202410', Descripcion: 'Rosal vivo carrera' },
    { Clave: '10202411', Descripcion: 'Rosal vivo charlene' },
    { Clave: '10202412', Descripcion: 'Rosal vivo charlotte' },
    { Clave: '10202413', Descripcion: 'Rosal vivo cherry lady' },
    { Clave: '10202414', Descripcion: 'Rosal vivo cherry love' },
    { Clave: '10202415', Descripcion: 'Rosal vivo classy rose' },
    { Clave: '10202416', Descripcion: 'Rosal vivo colorado velvet' },
    { Clave: '10202417', Descripcion: 'Rosal vivo corazon' },
    { Clave: '10202418', Descripcion: 'Rosal vivo corrida' },
    { Clave: '10202419', Descripcion: 'Rosal vivo dynamite' },
    { Clave: '10202420', Descripcion: 'Rosal vivo eurored' },
    { Clave: '10202421', Descripcion: 'Rosal vivo fashion' },
    { Clave: '10202422', Descripcion: 'Rosal vivo fire and ice' },
    { Clave: '10202423', Descripcion: 'Rosal vivo first red' },
    { Clave: '10202424', Descripcion: 'Rosal vivo forever young' },
    { Clave: '10202425', Descripcion: 'Rosal vivo freedom' },
    { Clave: '10202426', Descripcion: 'Rosal vivo freestyle' },
    { Clave: '10202427', Descripcion: 'Rosal vivo friendship' },
    { Clave: '10202428', Descripcion: 'Rosal vivo gospel' },
    { Clave: '10202429', Descripcion: 'Rosal vivo graffity' },
    { Clave: '10202430', Descripcion: 'Rosal vivo grand gala' },
    { Clave: '10202431', Descripcion: 'Rosal vivo grand prix' },
    { Clave: '10202432', Descripcion: 'Rosal vivo grand classe' },
    { Clave: '10202433', Descripcion: 'Rosal vivo hearts' },
    { Clave: '10202434', Descripcion: 'Rosal vivo heat' },
    { Clave: '10202435', Descripcion: 'Rosal vivo hocus pocus' },
    { Clave: '10202436', Descripcion: 'Rosal vivo lady in red' },
    { Clave: '10202437', Descripcion: 'Rosal vivo latin lady' },
    { Clave: '10202438', Descripcion: 'Rosal vivo legend' },
    { Clave: '10202439', Descripcion: 'Rosal vivo lulu' },
    { Clave: '10202440', Descripcion: 'Rosal vivo luna rossa' },
    { Clave: '10202441', Descripcion: 'Rosal vivo luxor' },
    { Clave: '10202442', Descripcion: 'Rosal vivo madame delbard o carola' },
    { Clave: '10202443', Descripcion: 'Rosal vivo miss paris' },
    { Clave: '10202444', Descripcion: 'Rosal vivo nicole' },
    { Clave: '10202445', Descripcion: 'Rosal vivo night fever' },
    { Clave: '10202446', Descripcion: 'Rosal vivo obsession' },
    { Clave: '10202447', Descripcion: 'Rosal vivo opium' },
    { Clave: '10202448', Descripcion: 'Rosal vivo paz' },
    { Clave: '10202449', Descripcion: 'Rosal vivo preference' },
    { Clave: '10202450', Descripcion: 'Rosal vivo red berlin' },
    { Clave: '10202451', Descripcion: 'Rosal vivo red bull' },
    { Clave: '10202452', Descripcion: 'Rosal vivo red calypso' },
    { Clave: '10202453', Descripcion: 'Rosal vivo red diamond' },
    { Clave: '10202454', Descripcion: 'Rosal vivo red fantasy' },
    { Clave: '10202455', Descripcion: 'Rosal vivo red france' },
    { Clave: '10202456', Descripcion: 'Rosal vivo red intuition' },
    { Clave: '10202457', Descripcion: 'Rosal vivo red jewel' },
    { Clave: '10202458', Descripcion: 'Rosal vivo red magic' },
    { Clave: '10202459', Descripcion: 'Rosal vivo red one' },
    { Clave: '10202460', Descripcion: 'Rosal vivo red paris' },
    { Clave: '10202461', Descripcion: 'Rosal vivo red princess' },
    { Clave: '10202462', Descripcion: 'Rosal vivo red sensation o colorad' },
    { Clave: '10202463', Descripcion: 'Rosal vivo red unique' },
    { Clave: '10202464', Descripcion: 'Rosal vivo rockefeller' },
    { Clave: '10202465', Descripcion: 'Rosal vivo romeo' },
    { Clave: '10202466', Descripcion: 'Rosal vivo rouge baiser' },
    { Clave: '10202467', Descripcion: 'Rosal vivo roulette' },
    { Clave: '10202468', Descripcion: 'Rosal vivo royal massai' },
    { Clave: '10202469', Descripcion: 'Rosal vivo royal red' },
    { Clave: '10202470', Descripcion: 'Rosal vivo samurai' },
    { Clave: '10202471', Descripcion: 'Rosal vivo sexy red' },
    { Clave: '10202472', Descripcion: 'Rosal vivo starfire' },
    { Clave: '10202473', Descripcion: 'Rosal vivo tango' },
    { Clave: '10202474', Descripcion: 'Rosal vivo tiger tail' },
    { Clave: '10202475', Descripcion: 'Rosal vivo tinto' },
    { Clave: '10202476', Descripcion: 'Rosal vivo top secret' },
    { Clave: '10202477', Descripcion: 'Rosal vivo vital' },
    { Clave: '10202478', Descripcion: 'Rosal vivo wisdom' },
    { Clave: '10202479', Descripcion: 'Rosal vivo xantia' },
    { Clave: '10202480', Descripcion: 'Rosal vivo xcite' },
    { Clave: '10202500', Descripcion: 'Rosales vivos sweetheart' },
    { Clave: '10202501', Descripcion: 'Rosal vivo burgundy sweetheart' },
    { Clave: '10202502', Descripcion: 'Rosal vivo cream sweetheart' },
    { Clave: '10202503', Descripcion: 'Rosal vivo hot pink sweetheart' },
    { Clave: '10202504', Descripcion: 'Rosal vivo lavender sweetheart' },
    { Clave: '10202505', Descripcion: 'Rosal vivo light pink sweetheart' },
    { Clave: '10202506', Descripcion: 'Rosal vivo orange sweetheart' },
    { Clave: '10202507', Descripcion: 'Rosal vivo peach sweetheart' },
    { Clave: '10202508', Descripcion: 'Rosal vivo red sweetheart' },
    { Clave: '10202509', Descripcion: 'Rosal vivo white sweetheart' },
    { Clave: '10202510', Descripcion: 'Rosal vivo yelllow sweetheart' },
    { Clave: '10202600', Descripcion: 'Rosales vivos blancos' },
    { Clave: '10202601', Descripcion: 'Rosal vivo absolut' },
    { Clave: '10202602', Descripcion: 'Rosal vivo aida' },
    { Clave: '10202603', Descripcion: 'Rosal vivo akito' },
    { Clave: '10202604', Descripcion: 'Rosal vivo amelia' },
    { Clave: '10202605', Descripcion: 'Rosal vivo anastasia' },
    { Clave: '10202606', Descripcion: 'Rosal vivo andean crystal' },
    { Clave: '10202607', Descripcion: 'Rosal vivo angel' },
    { Clave: '10202608', Descripcion: 'Rosal vivo annemarie' },
    { Clave: '10202609', Descripcion: 'Rosal vivo avalanche' },
    { Clave: '10202610', Descripcion: 'Rosal vivo bianca' },
    { Clave: '10202611', Descripcion: 'Rosal vivo blizzard' },
    { Clave: '10202612', Descripcion: 'Rosal vivo bridal akito' },
    { Clave: '10202613', Descripcion: 'Rosal vivo domenica' },
    { Clave: '10202614', Descripcion: 'Rosal vivo escimo' },
    { Clave: '10202615', Descripcion: 'Rosal vivo farfalla' },
    { Clave: '10202616', Descripcion: 'Rosal vivo high and peace' },
    { Clave: '10202617', Descripcion: 'Rosal vivo high and pure' },
    { Clave: '10202618', Descripcion: 'Rosal vivo inocencia o innocenti' },
    { Clave: '10202619', Descripcion: 'Rosal vivo ivory' },
    { Clave: '10202620', Descripcion: 'Rosal vivo mondial' },
    { Clave: '10202621', Descripcion: 'Rosal vivo mount everest' },
    { Clave: '10202622', Descripcion: 'Rosal vivo nova zembla' },
    { Clave: '10202623', Descripcion: 'Rosal vivo patience o auspator' },
    { Clave: '10202624', Descripcion: 'Rosal vivo polar star' },
    { Clave: '10202625', Descripcion: 'Rosal vivo polo' },
    { Clave: '10202626', Descripcion: 'Rosal vivo proud' },
    { Clave: '10202627', Descripcion: 'Rosal vivo snowy jewel' },
    { Clave: '10202628', Descripcion: 'Rosal vivo tibet' },
    { Clave: '10202629', Descripcion: 'Rosal vivo tineke' },
    { Clave: '10202630', Descripcion: 'Rosal vivo vitality' },
    { Clave: '10202631', Descripcion: 'Rosal vivo white cadillac' },
    { Clave: '10202632', Descripcion: 'Rosal vivo white dove' },
    { Clave: '10202700', Descripcion: 'Rosales vivos amarillos' },
    { Clave: '10202701', Descripcion: 'Rosal vivo aalsmeer gold' },
    { Clave: '10202702', Descripcion: 'Rosal vivo alina' },
    { Clave: '10202703', Descripcion: 'Rosal vivo ambiance' },
    { Clave: '10202704', Descripcion: 'Rosal vivo aquarel' },
    { Clave: '10202705', Descripcion: 'Rosal vivo autumn' },
    { Clave: '10202706', Descripcion: 'Rosal vivo brasil' },
    { Clave: '10202707', Descripcion: 'Rosal vivo candle light' },
    { Clave: '10202708', Descripcion: 'Rosal vivo cantata o cantate' },
    { Clave: '10202709', Descripcion: 'Rosal vivo capriccio' },
    { Clave: '10202710', Descripcion: 'Rosal vivo caribbean' },
    { Clave: '10202711', Descripcion: 'Rosal vivo circus' },
    { Clave: '10202712', Descripcion: 'Rosal vivo citran' },
    { Clave: '10202713', Descripcion: 'Rosal vivo concorde' },
    { Clave: '10202714', Descripcion: 'Rosal vivo conga' },
    { Clave: '10202715', Descripcion: 'Rosal vivo deja vu' },
    { Clave: '10202716', Descripcion: 'Rosal vivo desire' },
    { Clave: '10202717', Descripcion: 'Rosal vivo donia sol' },
    { Clave: '10202718', Descripcion: 'Rosal vivo dueto' },
    { Clave: '10202719', Descripcion: 'Rosal vivo erin' },
    { Clave: '10202720', Descripcion: 'Rosal vivo exotic curiosa' },
    { Clave: '10202721', Descripcion: 'Rosal vivo feria' },
    { Clave: '10202722', Descripcion: 'Rosal vivo fire bird' },
    { Clave: '10202723', Descripcion: 'Rosal vivo florida' },
    { Clave: '10202724', Descripcion: 'Rosal vivo friendly' },
    { Clave: '10202725', Descripcion: 'Rosal vivo gallinda' },
    { Clave: '10202726', Descripcion: 'Rosal vivo geisha' },
    { Clave: '10202727', Descripcion: 'Rosal vivo gelbe' },
    { Clave: '10202728', Descripcion: 'Rosal vivo gelosia o yellow flame' },
    { Clave: '10202729', Descripcion: 'Rosal vivo gold rush' },
    { Clave: '10202730', Descripcion: 'Rosal vivo gold star' },
    { Clave: '10202731', Descripcion: 'Rosal vivo gold strike' },
    { Clave: '10202732', Descripcion: 'Rosal vivo golda' },
    { Clave: '10202733', Descripcion: 'Rosal vivo golden fashion' },
    { Clave: '10202734', Descripcion: 'Rosal vivo golden gate' },
    { Clave: '10202735', Descripcion: 'Rosal vivo gran dorado' },
    { Clave: '10202736', Descripcion: 'Rosal vivo helio' },
    { Clave: '10202737', Descripcion: 'Rosal vivo high and exotic' },
    { Clave: '10202738', Descripcion: 'Rosal vivo high and yellow flame' },
    { Clave: '10202739', Descripcion: 'Rosal vivo high and yellow magic' },
    { Clave: '10202740', Descripcion: 'Rosal vivo high society' },
    { Clave: '10202741', Descripcion: 'Rosal vivo hummer' },
    { Clave: '10202742', Descripcion: 'Rosal vivo idole o elle' },
    { Clave: '10202743', Descripcion: 'Rosal vivo inti' },
    { Clave: '10202744', Descripcion: 'Rosal vivo jet set' },
    { Clave: '10202745', Descripcion: 'Rosal vivo judy' },
    { Clave: '10202746', Descripcion: 'Rosal vivo jupiter' },
    { Clave: '10202747', Descripcion: 'Rosal vivo konfetti' },
    { Clave: '10202748', Descripcion: 'Rosal vivo kyara o kira' },
    { Clave: '10202749', Descripcion: 'Rosal vivo latin beauty' },
    { Clave: '10202750', Descripcion: 'Rosal vivo latin spirit' },
    { Clave: '10202751', Descripcion: 'Rosal vivo latina' },
    { Clave: '10202752', Descripcion: 'Rosal vivo lina' },
    { Clave: '10202753', Descripcion: 'Rosal vivo lindsey' },
    { Clave: '10202754', Descripcion: 'Rosal vivo male' },
    { Clave: '10202755', Descripcion: 'Rosal vivo marie clare' },
    { Clave: '10202756', Descripcion: 'Rosal vivo marisa' },
    { Clave: '10202757', Descripcion: 'Rosal vivo matchball' },
    { Clave: '10202758', Descripcion: 'Rosal vivo melon' },
    { Clave: '10202759', Descripcion: 'Rosal vivo mohana' },
    { Clave: '10202760', Descripcion: 'Rosal vivo okie dokie' },
    { Clave: '10202761', Descripcion: 'Rosal vivo pailine' },
    { Clave: '10202762', Descripcion: 'Rosal vivo parrot' },
    { Clave: '10202763', Descripcion: 'Rosal vivo rio d oro' },
    { Clave: '10202764', Descripcion: 'Rosal vivo salami' },
    { Clave: '10202765', Descripcion: 'Rosal vivo santa fe' },
    { Clave: '10202766', Descripcion: 'Rosal vivo skyline' },
    { Clave: '10202767', Descripcion: 'Rosal vivo sonrisa' },
    { Clave: '10202768', Descripcion: 'Rosal vivo star ambiance' },
    { Clave: '10202769', Descripcion: 'Rosal vivo starbust' },
    { Clave: '10202770', Descripcion: 'Rosal vivo sun king' },
    { Clave: '10202771', Descripcion: 'Rosal vivo sunmaster' },
    { Clave: '10202772', Descripcion: 'Rosal vivo sunny milva' },
    { Clave: '10202773', Descripcion: 'Rosal vivo sushi' },
    { Clave: '10202774', Descripcion: 'Rosal vivo tabasco' },
    { Clave: '10202775', Descripcion: 'Rosal vivo tara' },
    { Clave: '10202776', Descripcion: 'Rosal vivo tresor 2000' },
    { Clave: '10202777', Descripcion: 'Rosal vivo ooty' },
    { Clave: '10202778', Descripcion: 'Rosal vivo yellow coral' },
    { Clave: '10202779', Descripcion: 'Rosal vivo yellow finess' },
    { Clave: '10202780', Descripcion: 'Rosal vivo yellow submarine' },
    { Clave: '10202781', Descripcion: 'Rosal vivo yellow sunset' },
    { Clave: '10202782', Descripcion: 'Rosal vivo yelllow timeless' },
    { Clave: '10202800', Descripcion: 'Rosales vivos spray' },
    { Clave: '10202801', Descripcion: 'Rosal vivo alegria spray' },
    { Clave: '10202802', Descripcion: 'Rosal vivo andrea follies spray' },
    { Clave: '10202803', Descripcion: 'Rosal vivo antara follies spray' },
    { Clave: '10202804', Descripcion: 'Rosal vivo arrow follies spray' },
    { Clave: '10202805', Descripcion: 'Rosal vivo babe spray' },
    { Clave: '10202806', Descripcion: 'Rosal vivo bellina collection spray' },
    { Clave: '10202807', Descripcion: 'Rosal vivo blue moon spray' },
    { Clave: '10202808', Descripcion: 'Rosal vivo chablis spray' },
    { Clave: '10202809', Descripcion: 'Rosal vivo cherry follies spray' },
    { Clave: '10202810', Descripcion: 'Rosal vivo chess spray' },
    { Clave: '10202811', Descripcion: 'Rosal vivo classic lydia spray' },
    { Clave: '10202812', Descripcion: 'Rosal vivo cream gracia spray' },
    { Clave: '10202813', Descripcion: 'Rosal vivo cream lydia spray' },
    { Clave: '10202814', Descripcion: 'Rosal vivo cream sensation spray' },
    { Clave: '10202815', Descripcion: 'Rosal vivo cremita spray' },
    { Clave: '10202816', Descripcion: 'Rosal vivo diablo spray' },
    { Clave: '10202817', Descripcion: 'Rosal vivo electra spray' },
    { Clave: '10202818', Descripcion: 'Rosal vivo fire king spray' },
    { Clave: '10202819', Descripcion: 'Rosal vivo fleur spray' },
    { Clave: '10202820', Descripcion: 'Rosal vivo girlie follies spray' },
    { Clave: '10202821', Descripcion: 'Rosal vivo giselle follies spray' },
    { Clave: '10202822', Descripcion: 'Rosal vivo golden collection spray' },
    { Clave: '10202823', Descripcion: 'Rosal vivo golden mimi spray' },
    { Clave: '10202824', Descripcion: 'Rosal vivo gracia spray' },
    { Clave: '10202825', Descripcion: 'Rosal vivo hot majolica spray' },
    { Clave: '10202826', Descripcion: 'Rosal vivo hot pink follies spray' },
    { Clave: '10202827', Descripcion: 'Rosal vivo ilse spray' },
    { Clave: '10202828', Descripcion: 'Rosal vivo jelena spray' },
    { Clave: '10202829', Descripcion: 'Rosal vivo laminuette spray' },
    { Clave: '10202830', Descripcion: 'Rosal vivo lavender follies spray' },
    { Clave: '10202831', Descripcion: 'Rosal vivo limoncello spray' },
    { Clave: '10202832', Descripcion: 'Rosal vivo little silver spray' },
    { Clave: '10202833', Descripcion: 'Rosal vivo lovely lydia spray' },
    { Clave: '10202834', Descripcion: 'Rosal vivo lucy spray' },
    { Clave: '10202835', Descripcion: 'Rosal vivo lydia spray' },
    { Clave: '10202836', Descripcion: 'Rosal vivo macarena spray' },
    { Clave: '10202837', Descripcion: 'Rosal vivo magic sensation spray' },
    { Clave: '10202838', Descripcion: 'Rosal vivo majolica spray' },
    { Clave: '10202839', Descripcion: 'Rosal vivo mambo number 5 spray' },
    { Clave: '10202840', Descripcion: 'Rosal vivo mambo spray' },
    { Clave: '10202841', Descripcion: 'Rosal vivo marlene spray' },
    { Clave: '10202842', Descripcion: 'Rosal vivo mimi eden spray' },
    { Clave: '10202843', Descripcion: 'Rosal vivo minou spray' },
    { Clave: '10202844', Descripcion: 'Rosal vivo nikita spray' },
    { Clave: '10202845', Descripcion: 'Rosal vivo novel collection spray' },
    { Clave: '10202846', Descripcion: 'Rosal vivo orange success spray' },
    { Clave: '10202847', Descripcion: 'Rosal vivo pepita spray' },
    { Clave: '10202848', Descripcion: 'Rosal vivo pink flash spray' },
    { Clave: '10202849', Descripcion: 'Rosal vivo pink sensation spray' },
    { Clave: '10202850', Descripcion: 'Rosal vivo porcelina spray' },
    { Clave: '10202851', Descripcion: 'Rosal vivo princess spray' },
    { Clave: '10202852', Descripcion: 'Rosal vivo purple mikado spray' },
    { Clave: '10202853', Descripcion: 'Rosal vivo red angel spray' },
    { Clave: '10202854', Descripcion: 'Rosal vivo red collection spray' },
    { Clave: '10202855', Descripcion: 'Rosal vivo red hero spray' },
    { Clave: '10202856', Descripcion: 'Rosal vivo red mikado spray' },
    { Clave: '10202857', Descripcion: 'Rosal vivo red vision spray' },
    { Clave: '10202858', Descripcion: 'Rosal vivo ritmo spray' },
    { Clave: '10202859', Descripcion: 'Rosal vivo romance mikado o eva spray' },
    { Clave: '10202860', Descripcion: 'Rosal vivo romantica follies spray' },
    { Clave: '10202861', Descripcion: 'Rosal vivo rubicon spray' },
    { Clave: '10202862', Descripcion: 'Rosal vivo rumba spray' },
    { Clave: '10202863', Descripcion: 'Rosal vivo salsa spray' },
    { Clave: '10202864', Descripcion: 'Rosal vivo sangrita spray' },
    { Clave: '10202865', Descripcion: 'Rosal vivo santa barbara spray' },
    { Clave: '10202866', Descripcion: 'Rosal vivo sashaba spray' },
    { Clave: '10202867', Descripcion: 'Rosal vivo scarlett spray' },
    { Clave: '10202868', Descripcion: 'Rosal vivo seline spray' },
    { Clave: '10202869', Descripcion: 'Rosal vivo sensation spray' },
    { Clave: '10202870', Descripcion: 'Rosal vivo silver collection spray' },
    { Clave: '10202871', Descripcion: 'Rosal vivo silver sensation spray' },
    { Clave: '10202872', Descripcion: 'Rosal vivo snowdance spray' },
    { Clave: '10202873', Descripcion: 'Rosal vivo snowflake spray' },
    { Clave: '10202874', Descripcion: 'Rosal vivo suncity spray' },
    { Clave: '10202875', Descripcion: 'Rosal vivo super nova spray' },
    { Clave: '10202876', Descripcion: 'Rosal vivo sweet sensation spray' },
    { Clave: '10202877', Descripcion: 'Rosal vivo taifun o typhoon spray' },
    { Clave: '10202878', Descripcion: 'Rosal vivo tamango spray' },
    { Clave: '10202879', Descripcion: 'Rosal vivo tanger follies spray' },
    { Clave: '10202880', Descripcion: 'Rosal vivo tiara spray' },
    { Clave: '10202881', Descripcion: 'Rosal vivo tiramisú spray' },
    { Clave: '10202882', Descripcion: 'Rosal vivo twinkle bride spray' },
    { Clave: '10202883', Descripcion: 'Rosal vivo viviane spray' },
    { Clave: '10202884', Descripcion: 'Rosal vivo white majolica spray' },
    { Clave: '10202885', Descripcion: 'Rosal vivo white mikado spray' },
    { Clave: '10202886', Descripcion: 'Rosal vivo xentina spray' },
    { Clave: '10202887', Descripcion: 'Rosal vivo yellow babe spray' },
    { Clave: '10202888', Descripcion: 'Rosal vivo yellow follies spray' },
    { Clave: '10211500', Descripcion: 'Anturios vivos' },
    { Clave: '10211501', Descripcion: 'Anturio vivo chocolate' },
    { Clave: '10211502', Descripcion: 'Anturio vivo rojo oscuro' },
    { Clave: '10211503', Descripcion: 'Anturio vivo verde' },
    { Clave: '10211504', Descripcion: 'Anturio vivo rosado fuerte' },
    { Clave: '10211505', Descripcion: 'Anturio vivo mickey mouse' },
    { Clave: '10211506', Descripcion: 'Anturio vivo obake verde y blanco' },
    { Clave: '10211507', Descripcion: 'Anturio vivo obake rojo y verde' },
    { Clave: '10211508', Descripcion: 'Anturio vivo anaranjado' },
    { Clave: '10211509', Descripcion: 'Anturio vivo durazno' },
    { Clave: '10211510', Descripcion: 'Anturio vivo picasso o pecoso' },
    { Clave: '10211511', Descripcion: 'Anturio vivo rojo' },
    { Clave: '10211512', Descripcion: 'Anturio vivo salpicadura' },
    { Clave: '10211513', Descripcion: 'Anturio vivo fuego del trópico' },
    { Clave: '10211514', Descripcion: 'Anturio vivo tulipán verde' },
    { Clave: '10211515', Descripcion: 'Anturio vivo tulipán rosado' },
    { Clave: '10211516', Descripcion: 'Anturio vivo tulipán púrpura' },
    { Clave: '10211517', Descripcion: 'Anturio vivo tulipán rojo' },
    { Clave: '10211518', Descripcion: 'Anturio vivo blanco' },
    { Clave: '10211519', Descripcion: 'Anturio vivo cosa salvaje' },
    { Clave: '10211600', Descripcion: 'Allium vivo' },
    { Clave: '10211601', Descripcion: 'Allium vivo embajador' },
    { Clave: '10211602', Descripcion: 'Allium vivo ampeloprasum' },
    { Clave: '10211603', Descripcion: 'Allium vivo bullit o drumstick' },
    { Clave: '10211604', Descripcion: 'Allium vivo christophii' },
    { Clave: '10211605', Descripcion: 'Allium vivo cowanii spray white' },
    { Clave: '10211606', Descripcion: 'Allium vivo gigante' },
    { Clave: '10211607', Descripcion: 'Allium vivo gladiador' },
    { Clave: '10211608', Descripcion: 'Allium vivo globemaster' },
    { Clave: '10211609', Descripcion: 'Allium vivo bola de golf blanca' },
    { Clave: '10211610', Descripcion: 'Allium vivo cabello' },
    { Clave: '10211611', Descripcion: 'Allium vivo rosado gigante' },
    { Clave: '10211612', Descripcion: 'Allium vivo sensación púrpura' },
    { Clave: '10211613', Descripcion: 'Allium vivo sicilum hanging' },
    { Clave: '10211614', Descripcion: 'Allium vivo spider schubertii' },
    { Clave: '10211615', Descripcion: 'Allium vivo spray moly' },
    { Clave: '10211616', Descripcion: 'Allium vivo spray roseum' },
    { Clave: '10211617', Descripcion: 'Allium vivo tuberosum' },
    { Clave: '10211618', Descripcion: 'Allium vivo unifolium o spray allium' },
    { Clave: '10211619', Descripcion: 'Allium vivo monte everest blanco' },
    { Clave: '10211700', Descripcion: 'Astromelia viva' },
    { Clave: '10211701', Descripcion: 'Astromelia viva agropoli' },
    { Clave: '10211702', Descripcion: 'Astromelia viva bourgogne' },
    { Clave: '10211703', Descripcion: 'Astromelia viva cairo' },
    { Clave: '10211704', Descripcion: 'Astromelia viva charmes' },
    { Clave: '10211705', Descripcion: 'Astromelia viva cherry bay' },
    { Clave: '10211706', Descripcion: 'Astromelia viva cherry white' },
    { Clave: '10211707', Descripcion: 'Astromelia viva dame blanche' },
    { Clave: '10211708', Descripcion: 'Astromelia viva diamond' },
    { Clave: '10211709', Descripcion: 'Astromelia viva gran canaria' },
    { Clave: '10211710', Descripcion: 'Astromelia viva harlekijn' },
    { Clave: '10211711', Descripcion: 'Astromelia viva indian summer' },
    { Clave: '10211712', Descripcion: 'Astromelia viva jamaica' },
    { Clave: '10211713', Descripcion: 'Astromelia viva macondo' },
    { Clave: '10211714', Descripcion: 'Astromelia viva mistique' },
    { Clave: '10211715', Descripcion: 'Astromelia viva my fair' },
    { Clave: '10211716', Descripcion: 'Astromelia viva new cairo' },
    { Clave: '10211717', Descripcion: 'Astromelia viva nice' },
    { Clave: '10211718', Descripcion: 'Astromelia viva orange bowl' },
    { Clave: '10211719', Descripcion: 'Astromelia viva orange queens' },
    { Clave: '10211720', Descripcion: 'Astromelia viva orange sun' },
    { Clave: '10211721', Descripcion: 'Astromelia viva paris' },
    { Clave: '10211722', Descripcion: 'Astromelia viva picasso' },
    { Clave: '10211723', Descripcion: 'Astromelia viva pink panther' },
    { Clave: '10211724', Descripcion: 'Astromelia viva prima donna' },
    { Clave: '10211725', Descripcion: 'Astromelia viva red silhouette' },
    { Clave: '10211726', Descripcion: 'Astromelia viva sacha' },
    { Clave: '10211727', Descripcion: 'Astromelia viva salmon' },
    { Clave: '10211728', Descripcion: 'Astromelia viva santiago' },
    { Clave: '10211729', Descripcion: 'Astromelia viva senna' },
    { Clave: '10211730', Descripcion: 'Astromelia viva snowball' },
    { Clave: '10211731', Descripcion: 'Astromelia viva sublime' },
    { Clave: '10211732', Descripcion: 'Astromelia viva tropicana' },
    { Clave: '10211733', Descripcion: 'Astromelia viva virginia' },
    { Clave: '10211734', Descripcion: 'Astromelia viva white' },
    { Clave: '10211800', Descripcion: 'Amaranto vivo' },
    { Clave: '10211801', Descripcion: 'Amaranto vivo verde colgante' },
    { Clave: '10211802', Descripcion: 'Amaranto vivo rojo colgante' },
    { Clave: '10211803', Descripcion: 'Amaranto vivo bonce erguido' },
    { Clave: '10211804', Descripcion: 'Amaranto vivo verde erguido' },
    { Clave: '10211805', Descripcion: 'Amaranto vivo rojo erguido' },
    { Clave: '10211900', Descripcion: 'Amaryllis vivo' },
    { Clave: '10211901', Descripcion: 'Amaryllis vivo naranja' },
    { Clave: '10211902', Descripcion: 'Amaryllis vivo orange nagano' },
    { Clave: '10211903', Descripcion: 'Amaryllis vivo pygmee mini' },
    { Clave: '10211904', Descripcion: 'Amaryllis vivo red lion' },
    { Clave: '10211905', Descripcion: 'Amaryllis vivo rilona' },
    { Clave: '10211906', Descripcion: 'Amaryllis vivo royal velvet' },
    { Clave: '10211907', Descripcion: 'Amaryllis vivo sonatini orange' },
    { Clave: '10211908', Descripcion: 'Amaryllis vivo sonatini red' },
    { Clave: '10211909', Descripcion: 'Amaryllis vivo tango' },
    { Clave: '10211910', Descripcion: 'Amaryllis vivo tinto night' },
    { Clave: '10212000', Descripcion: 'Anémona viva' },
    { Clave: '10212001', Descripcion: 'Anémona viva aubergine' },
    { Clave: '10212002', Descripcion: 'Anémona viva negra' },
    { Clave: '10212003', Descripcion: 'Anémona viva azul' },
    { Clave: '10212004', Descripcion: 'Anémona viva cereza' },
    { Clave: '10212005', Descripcion: 'Anémona viva coronaria' },
    { Clave: '10212006', Descripcion: 'Anémona viva rosada fuerte' },
    { Clave: '10212007', Descripcion: 'Anémona viva rosada claro' },
    { Clave: '10212008', Descripcion: 'Anémona viva rosada' },
    { Clave: '10212009', Descripcion: 'Anémona viva púrpura' },
    { Clave: '10212010', Descripcion: 'Anémona viva roja' },
    { Clave: '10212011', Descripcion: 'Anémona viva blanca' },
    { Clave: '10212100', Descripcion: 'Asclepia viva' },
    { Clave: '10212101', Descripcion: 'Asclepia viva lavanda' },
    { Clave: '10212102', Descripcion: 'Asclepia viva moby dick' },
    { Clave: '10212103', Descripcion: 'Asclepia viva tuberosa' },
    { Clave: '10212104', Descripcion: 'Asclepia viva blanca' },
    { Clave: '10212200', Descripcion: 'Aster viva' },
    { Clave: '10212201', Descripcion: 'Aster viva belleza' },
    { Clave: '10212202', Descripcion: 'Aster viva azul japonés' },
    { Clave: '10212203', Descripcion: 'Aster viva verde japonés' },
    { Clave: '10212204', Descripcion: 'Aster viva rosado fuerte japonés' },
    { Clave: '10212205', Descripcion: 'Aster viva lavanda japonés' },
    { Clave: '10212206', Descripcion: 'Aster viva rosado claro japonés' },
    { Clave: '10212207', Descripcion: 'Aster viva durazno japonés' },
    { Clave: '10212208', Descripcion: 'Aster viva rosado japonés' },
    { Clave: '10212209', Descripcion: 'Aster viva púrpura japonés' },
    { Clave: '10212210', Descripcion: 'Aster viva rojo japonés' },
    { Clave: '10212211', Descripcion: 'Aster viva araña japonés' },
    { Clave: '10212212', Descripcion: 'Aster viva blanca japonés' },
    { Clave: '10212213', Descripcion: 'Aster viva rosado fuerte novi belgii' },
    { Clave: '10212214', Descripcion: 'Aster viva lavanda novi belgii' },
    { Clave: '10212215', Descripcion: 'Aster viva rosado novi belgii' },
    { Clave: '10212216', Descripcion: 'Aster viva púrpura novi belgii' },
    { Clave: '10212217', Descripcion: 'Aster viva blanca novi belgii' },
    { Clave: '10212218', Descripcion: 'Aster viva solidago' },
    { Clave: '10212219', Descripcion: 'Aster viva araña' },
    { Clave: '10212300', Descripcion: 'Berzelia viva' },
    { Clave: '10212301', Descripcion: 'Berzelia lanuginosa viva abrotanoides' },
    { Clave: '10212302', Descripcion: 'Berzelia lanuginosa viva fireball' },
    { Clave: '10212303', Descripcion: 'Berzelia lanuginosa viva galpinii' },
    { Clave: '10212304', Descripcion: 'Berzelia lanuginosa viva galpini o baubles' },
    { Clave: '10212305', Descripcion: 'Berzelia lanuginosa viva squarrosa' },
    { Clave: '10212400', Descripcion: 'Bouvaria viva' },
    { Clave: '10212401', Descripcion: 'Bouvardia viva doble rosado fuerte' },
    { Clave: '10212402', Descripcion: 'Bouvardia viva rosado claro' },
    { Clave: '10212403', Descripcion: 'Bouvardia viva doble rosado claro' },
    { Clave: '10212404', Descripcion: 'Bouvardia viva roja' },
    { Clave: '10212405', Descripcion: 'Bouvardia viva blanca' },
    { Clave: '10212500', Descripcion: 'Brodiaea viva' },
    { Clave: '10212501', Descripcion: 'Brodiaea viva congesta' },
    { Clave: '10212502', Descripcion: 'Brodiaea viva congesta lavanda' },
    { Clave: '10212503', Descripcion: 'Brodiaea viva hyacintha' },
    { Clave: '10212504', Descripcion: 'Brodiaea viva ida maija' },
    { Clave: '10212505', Descripcion: 'Brodiaea viva starlight' },
    { Clave: '10212600', Descripcion: 'Calla viva' },
    { Clave: '10212601', Descripcion: 'Calla viva green goddess' },
    { Clave: '10212602', Descripcion: 'Calla viva ramillete albertville' },
    { Clave: '10212603', Descripcion: 'Calla viva ramillete aranal' },
    { Clave: '10212604', Descripcion: 'Calla viva ramillete black eyed beauty' },
    { Clave: '10212605', Descripcion: 'Calla viva ramillete black star' },
    { Clave: '10212606', Descripcion: 'Calla viva ramillete brisbane' },
    { Clave: '10212607', Descripcion: 'Calla viva ramillete crystal blush' },
    { Clave: '10212608', Descripcion: 'Calla viva ramillete crystal pink' },
    { Clave: '10212609', Descripcion: 'Calla viva ramillete crystal white' },
    { Clave: '10212610', Descripcion: 'Calla viva ramillete dark captain romanc' },
    { Clave: '10212611', Descripcion: 'Calla viva ramillete dark mozart' },
    { Clave: '10212612', Descripcion: 'Calla viva ramillete dark naomi' },
    { Clave: '10212613', Descripcion: 'Calla viva ramillete deformed calla' },
    { Clave: '10212614', Descripcion: 'Calla viva ramillete dordogne' },
    { Clave: '10212615', Descripcion: 'Calla viva ramillete etude' },
    { Clave: '10212616', Descripcion: 'Calla viva ramillete farao' },
    { Clave: '10212617', Descripcion: 'Calla viva ramillete fire glow' },
    { Clave: '10212618', Descripcion: 'Calla viva ramillete florex gold' },
    { Clave: '10212619', Descripcion: 'Calla viva ramillete garnet glow' },
    { Clave: '10212620', Descripcion: 'Calla viva ramillete hot chocolate' },
    { Clave: '10212621', Descripcion: 'Calla viva ramillete lavender improved' },
    { Clave: '10212622', Descripcion: 'Calla viva ramillete light cromance' },
    { Clave: '10212623', Descripcion: 'Calla viva ramillete little suzy' },
    { Clave: '10212624', Descripcion: 'Calla viva ramillete majestic red' },
    { Clave: '10212625', Descripcion: 'Calla viva ramillete mango' },
    { Clave: '10212626', Descripcion: 'Calla viva ramillete merlot' },
    { Clave: '10212627', Descripcion: 'Calla viva ramillete mozart' },
    { Clave: '10212628', Descripcion: 'Calla viva ramillete naomi' },
    { Clave: '10212629', Descripcion: 'Calla viva ramillete night cap' },
    { Clave: '10212630', Descripcion: 'Calla viva ramillete odessa' },
    { Clave: '10212631', Descripcion: 'Calla viva ramillete pacific pink' },
    { Clave: '10212632', Descripcion: 'Calla viva ramillete passion fruit' },
    { Clave: '10212633', Descripcion: 'Calla viva ramillete picasso' },
    { Clave: '10212634', Descripcion: 'Calla viva ramillete pillow talk' },
    { Clave: '10212635', Descripcion: 'Calla viva ramillete pink persuation' },
    { Clave: '10212636', Descripcion: 'Calla viva ramillete pisa' },
    { Clave: '10212637', Descripcion: 'Calla viva ramillete pot of' },
    { Clave: '10212638', Descripcion: 'Calla viva ramillete red sox' },
    { Clave: '10212639', Descripcion: 'Calla viva ramillete rosa' },
    { Clave: '10212640', Descripcion: 'Calla viva ramillete ruby light rose' },
    { Clave: '10212641', Descripcion: 'Calla viva ramillete samur' },
    { Clave: '10212642', Descripcion: 'Calla viva ramillete sapporo' },
    { Clave: '10212643', Descripcion: 'Calla viva ramillete schwarzwalder' },
    { Clave: '10212644', Descripcion: 'Calla viva ramillete serrada' },
    { Clave: '10212645', Descripcion: 'Calla viva ramillete solemio' },
    { Clave: '10212646', Descripcion: 'Calla viva ramillete sunrise' },
    { Clave: '10212647', Descripcion: 'Calla viva ramillete super mac' },
    { Clave: '10212648', Descripcion: 'Calla viva ramillete swan lake' },
    { Clave: '10212649', Descripcion: 'Calla viva ramillete vermeer' },
    { Clave: '10212650', Descripcion: 'Calla viva ramillete white butterfly' },
    { Clave: '10212651', Descripcion: 'Calla viva ramillete yellow' },
    { Clave: '10212652', Descripcion: 'Calla viva ramillete yellow mozart' },
    { Clave: '10212653', Descripcion: 'Calla viva ramillete white large' },
    { Clave: '10212800', Descripcion: 'Celosia viva' },
    { Clave: '10212801', Descripcion: 'Celosia viva cresta de gallo verde' },
    { Clave: '10212802', Descripcion: 'Celosia viva cresta de gallo anaranjado' },
    { Clave: '10212803', Descripcion: 'Celosia viva cresta de gallo rosado' },
    { Clave: '10212804', Descripcion: 'Celosia viva cresta de gallo púrpura' },
    { Clave: '10212805', Descripcion: 'Celosia viva cresta de gallo rojo' },
    { Clave: '10212806', Descripcion: 'Celosia viva cresta de gallo amarillo' },
    { Clave: '10212807', Descripcion: 'Celosia viva pluma rosado claro' },
    { Clave: '10212808', Descripcion: 'Celosia viva pluma anaranjado' },
    { Clave: '10212809', Descripcion: 'Celosia viva pluma púrpura' },
    { Clave: '10212810', Descripcion: 'Celosia viva pluma rojo' },
    { Clave: '10212811', Descripcion: 'Celosia viva pluma amarillo' },
    { Clave: '10212812', Descripcion: 'Celosia viva trigo rosado' },
    { Clave: '10212813', Descripcion: 'Celosia viva trigo amarillo' },
    { Clave: '10212900', Descripcion: 'Narciso vivo' },
    { Clave: '10212901', Descripcion: 'Narciso vivo dick wilden' },
    { Clave: '10212902', Descripcion: 'Narciso vivo dutch master' },
    { Clave: '10212903', Descripcion: 'Narciso vivo ice follies' },
    { Clave: '10212904', Descripcion: 'Narciso vivo ice king' },
    { Clave: '10212905', Descripcion: 'Narciso vivo johan strauss' },
    { Clave: '10212906', Descripcion: 'Narciso vivo yellow carlton' },
    { Clave: '10213000', Descripcion: 'Dalia viva' },
    { Clave: '10213001', Descripcion: 'Dalia viva bicolor' },
    { Clave: '10213002', Descripcion: 'Dalia viva rosado fuerte' },
    { Clave: '10213003', Descripcion: 'Dalia viva rosado claro' },
    { Clave: '10213004', Descripcion: 'Dalia viva rosado medio' },
    { Clave: '10213005', Descripcion: 'Dalia viva anaranjado' },
    { Clave: '10213006', Descripcion: 'Dalia viva durazno' },
    { Clave: '10213007', Descripcion: 'Dalia viva púrpura' },
    { Clave: '10213008', Descripcion: 'Dalia viva roja' },
    { Clave: '10213009', Descripcion: 'Dalia viva blanca' },
    { Clave: '10213010', Descripcion: 'Dalia viva amarilla' },
    { Clave: '10213100', Descripcion: 'Delphinium vivo' },
    { Clave: '10213101', Descripcion: 'Delphinium vivo bella azul oscuro' },
    { Clave: '10213102', Descripcion: 'Delphinium vivo bella azul claro' },
    { Clave: '10213103', Descripcion: 'Delphinium vivo bella blanco' },
    { Clave: '10213104', Descripcion: 'Delphinium vivo azul sombra' },
    { Clave: '10213105', Descripcion: 'Delphinium vivo híbrido azul oscuro' },
    { Clave: '10213106', Descripcion: 'Delphinium vivo híbrido azul claro' },
    { Clave: '10213107', Descripcion: 'Delphinium vivo híbrido malva' },
    { Clave: '10213108', Descripcion: 'Delphinium vivo híbrido rosado' },
    { Clave: '10213109', Descripcion: 'Delphinium vivo híbrido púrpura' },
    { Clave: '10213110', Descripcion: 'Delphinium vivo híbrido rojo' },
    { Clave: '10213111', Descripcion: 'Delphinium vivo híbrido blanco' },
    { Clave: '10213112', Descripcion: 'Delphinium vivo princesa carolina' },
    { Clave: '10213113', Descripcion: 'Delphinium vivo volkerfrieden' },
    { Clave: '10213200', Descripcion: 'Clavel (dianthus) vivo' },
    { Clave: '10213201', Descripcion: 'Clavel (dianthus) vivo chocolate' },
    { Clave: '10213202', Descripcion: 'Clavel (dianthus) vivo fucsia' },
    { Clave: '10213203', Descripcion: 'Clavel (dianthus) vivo bola verde' },
    { Clave: '10213204', Descripcion: 'Clavel (dianthus) vivo rosado fuerte' },
    { Clave: '10213205', Descripcion: 'Clavel (dianthus) vivo lavanda' },
    { Clave: '10213206', Descripcion: 'Clavel (dianthus) vivo frambuesa' },
    { Clave: '10213207', Descripcion: 'Clavel (dianthus) vivo rojo' },
    { Clave: '10213208', Descripcion: 'Clavel (dianthus) vivo rosado' },
    { Clave: '10213300', Descripcion: 'Eremruses vivas' },
    { Clave: '10213301', Descripcion: 'Eremurus vivo deruyter híbrido' },
    { Clave: '10213302', Descripcion: 'Eremurus vivo himalaicus blanco' },
    { Clave: '10213303', Descripcion: 'Eremurus vivo anaranjado' },
    { Clave: '10213304', Descripcion: 'Eremurus vivo durazno' },
    { Clave: '10213305', Descripcion: 'Eremurus vivo amarillo' },
    { Clave: '10213400', Descripcion: 'Brezo (erica) viva' },
    { Clave: '10213401', Descripcion: 'Brezo (erica) vivo campunalarus' },
    { Clave: '10213402', Descripcion: 'Brezo (erica) vivo conica' },
    { Clave: '10213403', Descripcion: 'Brezo (erica) vivo green ice' },
    { Clave: '10213404', Descripcion: 'Brezo (erica) vivo rosado' },
    { Clave: '10213405', Descripcion: 'Brezo (erica) vivo príncipe de gales' },
    { Clave: '10213500', Descripcion: 'Euphorbia viva' },
    { Clave: '10213501', Descripcion: 'Euphorbia viva characias' },
    { Clave: '10213502', Descripcion: 'Euphorbia viva griffithii fireglow' },
    { Clave: '10213503', Descripcion: 'Euphorbia viva martini' },
    { Clave: '10213504', Descripcion: 'Euphorbia viva anaranjada' },
    { Clave: '10213505', Descripcion: 'Euphorbia viva durazno' },
    { Clave: '10213506', Descripcion: 'Euphorbia viva rosada' },
    { Clave: '10213507', Descripcion: 'Euphorbia viva roja' },
    { Clave: '10213508', Descripcion: 'Euphorbia viva blanca' },
    { Clave: '10213509', Descripcion: 'Euphorbia viva amarilla' },
    { Clave: '10213510', Descripcion: 'Euphorbia viva amarilla spurge' },
    { Clave: '10213600', Descripcion: 'Fresia viva' },
    { Clave: '10213601', Descripcion: 'Fresia viva crema' },
    { Clave: '10213602', Descripcion: 'Fresia viva doble blanca' },
    { Clave: '10213603', Descripcion: 'Fresia viva doble amarilla' },
    { Clave: '10213604', Descripcion: 'Fresia viva rosado fuerte' },
    { Clave: '10213605', Descripcion: 'Fresia viva lady brunet' },
    { Clave: '10213606', Descripcion: 'Fresia viva lavanda' },
    { Clave: '10213607', Descripcion: 'Fresia viva rosado medio' },
    { Clave: '10213608', Descripcion: 'Fresia viva anaranjado' },
    { Clave: '10213609', Descripcion: 'Fresia viva pimpinela' },
    { Clave: '10213610', Descripcion: 'Fresia viva rosada' },
    { Clave: '10213611', Descripcion: 'Fresia viva pùrpura' },
    { Clave: '10213612', Descripcion: 'Fresia viva roja' },
    { Clave: '10213613', Descripcion: 'Fresia viva blanca' },
    { Clave: '10213614', Descripcion: 'Fresia viva amarilla' },
    { Clave: '10213700', Descripcion: 'Ajedrezada viva' },
    { Clave: '10213701', Descripcion: 'Ajedrezada viva acmopelata' },
    { Clave: '10213702', Descripcion: 'Ajedrezada viva assyriaca' },
    { Clave: '10213703', Descripcion: 'Ajedrezada viva assyriaca uva vulpis' },
    { Clave: '10213704', Descripcion: 'Ajedrezada viva elysee' },
    { Clave: '10213705', Descripcion: 'Ajedrezada viva imperialis anaranjada' },
    { Clave: '10213706', Descripcion: 'Ajedrezada viva imperialis amarilla' },
    { Clave: '10213707', Descripcion: 'Ajedrezada viva meleagris' },
    { Clave: '10213708', Descripcion: 'Ajedrezada viva michailowski' },
    { Clave: '10213709', Descripcion: 'Ajedrezada viva uva vulpis' },
    { Clave: '10213800', Descripcion: 'Hiniesta viva' },
    { Clave: '10213801', Descripcion: 'Hiniesta viva verde' },
    { Clave: '10213802', Descripcion: 'Hiniesta viva rosado fuerte' },
    { Clave: '10213803', Descripcion: 'Hiniesta viva lavanda' },
    { Clave: '10213804', Descripcion: 'Hiniesta viva rosado claro' },
    { Clave: '10213805', Descripcion: 'Hiniesta viva durazno' },
    { Clave: '10213806', Descripcion: 'Hiniesta viva púrpura' },
    { Clave: '10213807', Descripcion: 'Hiniesta viva blanco' },
    { Clave: '10213808', Descripcion: 'Hiniesta viva amarillo' },
    { Clave: '10213900', Descripcion: 'Margarita viva' },
    { Clave: '10213901', Descripcion: 'Margarita viva crema de centro negro' },
    { Clave: '10213902', Descripcion: 'Margarita viva crema' },
    { Clave: '10213903', Descripcion: 'Margarita viva dorada' },
    { Clave: '10213904', Descripcion: 'Margarita viva rosado fuerte' },
    { Clave: '10213905', Descripcion: 'Margarita viva rosado claro' },
    { Clave: '10213906', Descripcion: 'Margarita viva magenta' },
    { Clave: '10213907', Descripcion: 'Margarita viva mini coral' },
    { Clave: '10213908', Descripcion: 'Margarita viva mini fucsia' },
    { Clave: '10213909', Descripcion: 'Margarita viva mini rosado fuerte' },
    { Clave: '10213910', Descripcion: 'Margarita viva mini anaranjado claro de centro negro' },
    { Clave: '10213911', Descripcion: 'Margarita viva mini anaranjado de centro negro' },
    { Clave: '10213912', Descripcion: 'Margarita viva mini anaranjado' },
    { Clave: '10213913', Descripcion: 'Margarita viva mini rojo de centro negro' },
    { Clave: '10213914', Descripcion: 'Margarita viva mini blanco' },
    { Clave: '10213915', Descripcion: 'Margarita viva mini amarillo claro de centro negro' },
    { Clave: '10213916', Descripcion: 'Margarita viva anaranjado de centro negro' },
    { Clave: '10213917', Descripcion: 'Margarita viva anaranjada' },
    { Clave: '10213918', Descripcion: 'Margarita viva durazno de centro negro' },
    { Clave: '10213919', Descripcion: 'Margarita viva durazno' },
    { Clave: '10213920', Descripcion: 'Margarita viva rosada de centro negro' },
    { Clave: '10213921', Descripcion: 'Margarita viva rosada' },
    { Clave: '10213922', Descripcion: 'Margarita viva roja de centro negro' },
    { Clave: '10213923', Descripcion: 'Margarita viva roja' },
    { Clave: '10213924', Descripcion: 'Margarita viva spider durazno' },
    { Clave: '10213925', Descripcion: 'Margarita viva spider roja' },
    { Clave: '10213926', Descripcion: 'Margarita viva terracota' },
    { Clave: '10213927', Descripcion: 'Margarita viva blanca de centro negro' },
    { Clave: '10213928', Descripcion: 'Margarita viva blanca' },
    { Clave: '10213929', Descripcion: 'Margarita viva amarilla' },
    { Clave: '10214000', Descripcion: 'Jengibre vivo' },
    { Clave: '10214001', Descripcion: 'Jengibre vivo  indonesio' },
    { Clave: '10214002', Descripcion: 'Jengibre vivo jungle king rosado' },
    { Clave: '10214003', Descripcion: 'Jengibre vivo jungle king rojo' },
    { Clave: '10214004', Descripcion: 'Jengibre vivo rosado' },
    { Clave: '10214005', Descripcion: 'Jengibre vivo rojo' },
    { Clave: '10214006', Descripcion: 'Jengibre vivo torch' },
    { Clave: '10214100', Descripcion: 'Gladiolo vivo' },
    { Clave: '10214101', Descripcion: 'Gladiolo vivo vinotinto' },
    { Clave: '10214102', Descripcion: 'Gladiolo vivo fucsia' },
    { Clave: '10214103', Descripcion: 'Gladiolo vivo verde' },
    { Clave: '10214104', Descripcion: 'Gladiolo vivo rosado fuerte' },
    { Clave: '10214105', Descripcion: 'Gladiolo vivo rosado claro' },
    { Clave: '10214106', Descripcion: 'Gladiolo vivo anaranjado' },
    { Clave: '10214107', Descripcion: 'Gladiolo vivo durazno' },
    { Clave: '10214108', Descripcion: 'Gladiolo vivo rosado medio' },
    { Clave: '10214109', Descripcion: 'Gladiolo vivo púrpura' },
    { Clave: '10214110', Descripcion: 'Gladiolo vivo rojo bicolor' },
    { Clave: '10214111', Descripcion: 'Gladiolo vivo rojo' },
    { Clave: '10214112', Descripcion: 'Gladiolo vivo salmón' },
    { Clave: '10214113', Descripcion: 'Gladiolo vivo blanco' },
    { Clave: '10214114', Descripcion: 'Gladiolo vivo amarillo' },
    { Clave: '10214200', Descripcion: 'Godetia viva' },
    { Clave: '10214201', Descripcion: 'Godetia viva bicolor' },
    { Clave: '10214202', Descripcion: 'Godetia viva fucsia' },
    { Clave: '10214203', Descripcion: 'Godetia viva rosado fuerte' },
    { Clave: '10214204', Descripcion: 'Godetia viva anaranjada' },
    { Clave: '10214205', Descripcion: 'Godetia viva roja' },
    { Clave: '10214206', Descripcion: 'Godetia viva blanca' },
    { Clave: '10214300', Descripcion: 'Bromelia viva' },
    { Clave: '10214301', Descripcion: 'Bromelia lingulata viva anaranjada' },
    { Clave: '10214302', Descripcion: 'Bromelia lingulata viva roja' },
    { Clave: '10214303', Descripcion: 'Bromelia lingulata viva blanca' },
    { Clave: '10214304', Descripcion: 'Bromelia lingulata viva amarilla' },
    { Clave: '10214305', Descripcion: 'Bromelia variegata viva' },
    { Clave: '10214400', Descripcion: 'Gypsophilia viva' },
    { Clave: '10214401', Descripcion: 'Gypsophilia viva bambino' },
    { Clave: '10214402', Descripcion: 'Gypsophilia viva million stars' },
    { Clave: '10214403', Descripcion: 'Gypsophilia viva mirabella' },
    { Clave: '10214404', Descripcion: 'Gypsophilia viva new love' },
    { Clave: '10214405', Descripcion: 'Gypsophilia viva orión' },
    { Clave: '10214406', Descripcion: 'Gypsophilia viva perfecta' },
    { Clave: '10214500', Descripcion: 'Brezo vivo' },
    { Clave: '10214501', Descripcion: 'Brezo vivo augustine' },
    { Clave: '10214502', Descripcion: 'Brezo vivo érica cuatro hermanas' },
    { Clave: '10214503', Descripcion: 'Brezo vivo francés' },
    { Clave: '10214504', Descripcion: 'Brezo vivo verde' },
    { Clave: '10214505', Descripcion: 'Brezo sterling range blanco vivo' },
    { Clave: '10214506', Descripcion: 'Brezo vivo rosado sunset' },
    { Clave: '10214507', Descripcion: 'Brezo vivo blanco' },
    { Clave: '10214600', Descripcion: 'Heliconia viva' },
    { Clave: '10214601', Descripcion: 'Heliconia viva bihai claw' },
    { Clave: '10214602', Descripcion: 'Heliconia viva bihai flash' },
    { Clave: '10214603', Descripcion: 'Heliconia viva bihai lobster claw' },
    { Clave: '10214604', Descripcion: 'Heliconia viva caribea red' },
    { Clave: '10214605', Descripcion: 'Heliconia viva caribea yellow' },
    { Clave: '10214606', Descripcion: 'Heliconia viva christmas' },
    { Clave: '10214607', Descripcion: 'Heliconia viva edge of night' },
    { Clave: '10214608', Descripcion: 'Heliconia viva green bihai' },
    { Clave: '10214609', Descripcion: 'Heliconia viva marginata lutea' },
    { Clave: '10214610', Descripcion: 'Heliconia viva psitt fire opal' },
    { Clave: '10214611', Descripcion: 'Heliconia viva psittacorum' },
    { Clave: '10214612', Descripcion: 'Heliconia viva richmond red' },
    { Clave: '10214613', Descripcion: 'Heliconia viva rostrata' },
    { Clave: '10214614', Descripcion: 'Heliconia viva sexy pink' },
    { Clave: '10214615', Descripcion: 'Heliconia viva sexy scarlett' },
    { Clave: '10214616', Descripcion: 'Heliconia viva shogun' },
    { Clave: '10214617', Descripcion: 'Heliconia viva small red' },
    { Clave: '10214618', Descripcion: 'Heliconia viva southern cross' },
    { Clave: '10214619', Descripcion: 'Heliconia viva wagneriana' },
    { Clave: '10214700', Descripcion: 'Jacinto vivo' },
    { Clave: '10214701', Descripcion: 'Jacinto vivo bean' },
    { Clave: '10214702', Descripcion: 'Jacinto vivo durazno' },
    { Clave: '10214703', Descripcion: 'Jacinto vivo azul' },
    { Clave: '10214704', Descripcion: 'Jacinto vivo fucsia' },
    { Clave: '10214705', Descripcion: 'Jacinto vivo rosado fuerte' },
    { Clave: '10214706', Descripcion: 'Jacinto vivo lavanda' },
    { Clave: '10214707', Descripcion: 'Jacinto vivo azul claro' },
    { Clave: '10214708', Descripcion: 'Jacinto vivo rosado medio' },
    { Clave: '10214709', Descripcion: 'Jacinto vivo rosado' },
    { Clave: '10214710', Descripcion: 'Jacinto vivo estrella púrpura' },
    { Clave: '10214711', Descripcion: 'Jacinto vivo blanco' },
    { Clave: '10214712', Descripcion: 'Jacinto vivo amarillo' },
    { Clave: '10214800', Descripcion: 'Hydrangea viva' },
    { Clave: '10214801', Descripcion: 'Hydrangea viva anabella' },
    { Clave: '10214802', Descripcion: 'Hydrangea viva azul antiguo' },
    { Clave: '10214803', Descripcion: 'Hydrangea viva azul antiguo  o verde o nueva zelandia' },
    { Clave: '10214804', Descripcion: 'Hydrangea viva verde antiguo' },
    { Clave: '10214805', Descripcion: 'Hydrangea viva rosado antiguo' },
    { Clave: '10214806', Descripcion: 'Hydrangea viva púrpura antiguo o nueva zelandia' },
    { Clave: '10214807', Descripcion: 'Hydrangea viva aubergene o nueva zelandia' },
    { Clave: '10214808', Descripcion: 'Hydrangea viva azul oscuro' },
    { Clave: '10214809', Descripcion: 'Hydrangea viva rosado oscuro' },
    { Clave: '10214810', Descripcion: 'Hydrangea viva púrpura oscuro' },
    { Clave: '10214811', Descripcion: 'Hydrangea viva berenjena' },
    { Clave: '10214812', Descripcion: 'Hydrangea viva verde teñida' },
    { Clave: '10214813', Descripcion: 'Hydrangea viva limón verde' },
    { Clave: '10214814', Descripcion: 'Hydrangea viva rosado fuerte' },
    { Clave: '10214815', Descripcion: 'Hydrangea viva jumbo blanca' },
    { Clave: '10214816', Descripcion: 'Hydrangea viva lavanda o nueva zelandia' },
    { Clave: '10214817', Descripcion: 'Hydrangea viva azul claro' },
    { Clave: '10214818', Descripcion: 'Hydrangea viva rosado claro grande' },
    { Clave: '10214819', Descripcion: 'Hydrangea viva verde lima grande' },
    { Clave: '10214820', Descripcion: 'Hydrangea viva mini verde' },
    { Clave: '10214821', Descripcion: 'Hydrangea viva oakleaf' },
    { Clave: '10214822', Descripcion: 'Hydrangea viva oakleaf copo de nieve' },
    { Clave: '10214823', Descripcion: 'Hydrangea viva rosado teñido' },
    { Clave: '10214824', Descripcion: 'Hydrangea viva rosado' },
    { Clave: '10214825', Descripcion: 'Hydrangea viva púrpura o nueva zelandia' },
    { Clave: '10214826', Descripcion: 'Hydrangea viva rojo teñido' },
    { Clave: '10214827', Descripcion: 'Hydrangea viva shocking blue' },
    { Clave: '10214828', Descripcion: 'Hydrangea viva tardiva' },
    { Clave: '10214829', Descripcion: 'Hydrangea viva blanca' },
    { Clave: '10214900', Descripcion: 'Iris Viva' },
    { Clave: '10214901', Descripcion: 'Iris viva negra barbada' },
    { Clave: '10214902', Descripcion: 'Iris viva azul barbada' },
    { Clave: '10214903', Descripcion: 'Iris viva lavanda barbada' },
    { Clave: '10214904', Descripcion: 'Iris viva barbada azul clara' },
    { Clave: '10214905', Descripcion: 'Iris viva barbada púrpura' },
    { Clave: '10214906', Descripcion: 'Iris viva barbada roja' },
    { Clave: '10214907', Descripcion: 'Iris viva barbada blanca' },
    { Clave: '10214908', Descripcion: 'Iris viva baerbada blanca y púrpura' },
    { Clave: '10214909', Descripcion: 'Iris viva amarilla barbada' },
    { Clave: '10214910', Descripcion: 'Iris viva blue elegance' },
    { Clave: '10214911', Descripcion: 'Iris viva casablanca' },
    { Clave: '10214912', Descripcion: 'Iris viva golden beau' },
    { Clave: '10214913', Descripcion: 'Iris viva hildegard' },
    { Clave: '10214914', Descripcion: 'Iris viva hong kong' },
    { Clave: '10214915', Descripcion: 'Iris viva ideal' },
    { Clave: '10214916', Descripcion: 'Iris viva professor blue' },
    { Clave: '10214917', Descripcion: 'Iris viva purple' },
    { Clave: '10214918', Descripcion: 'Iris viva spuria' },
    { Clave: '10214919', Descripcion: 'Iris viva telstar' },
    { Clave: '10215000', Descripcion: 'Pata de canguro viva' },
    { Clave: '10215001', Descripcion: 'Pata de canguro viva bicolor' },
    { Clave: '10215002', Descripcion: 'Pata de canguro viva negra' },
    { Clave: '10215003', Descripcion: 'Pata de canguro viva verde' },
    { Clave: '10215004', Descripcion: 'Pata de canguro viva anaranjada' },
    { Clave: '10215005', Descripcion: 'Pata de canguro viva rosada' },
    { Clave: '10215006', Descripcion: 'Pata de canguro viva roja' },
    { Clave: '10215007', Descripcion: 'Pata de canguro viva amarilla' },
    { Clave: '10215100', Descripcion: 'Delfinio vivo' },
    { Clave: '10215101', Descripcion: 'Delfinio viva azul nube' },
    { Clave: '10215102', Descripcion: 'Delfinio viva rosado fuerte' },
    { Clave: '10215103', Descripcion: 'Delfinio viva lavanda' },
    { Clave: '10215104', Descripcion: 'Delfinio viva rosado claro' },
    { Clave: '10215105', Descripcion: 'Delfinio viva púrpura' },
    { Clave: '10215106', Descripcion: 'Delfinio viva blanca' },
    { Clave: '10215200', Descripcion: 'Lepto vivo' },
    { Clave: '10215201', Descripcion: 'Lepto vivo azul o floreciente' },
    { Clave: '10215202', Descripcion: 'Lepto vivo rosado fuerte' },
    { Clave: '10215203', Descripcion: 'Lepto vivo rosado claro' },
    { Clave: '10215204', Descripcion: 'Lepto vivo rosado' },
    { Clave: '10215205', Descripcion: 'Lepto vivo rojo' },
    { Clave: '10215206', Descripcion: 'Lepto vivo blanco' },
    { Clave: '10215300', Descripcion: 'Lila viva' },
    { Clave: '10215301', Descripcion: 'Lila  viva híbrida francesa lavanda' },
    { Clave: '10215302', Descripcion: 'Lila  viva híbrida francesa púrpura' },
    { Clave: '10215303', Descripcion: 'Lila  viva púrpura' },
    { Clave: '10215304', Descripcion: 'Lila  viva vino' },
    { Clave: '10215305', Descripcion: 'Lila  viva blanca' },
    { Clave: '10215306', Descripcion: 'Lila  viva blanca salvaje' },
    { Clave: '10215400', Descripcion: 'Azucena viva' },
    { Clave: '10215401', Descripcion: 'Azucena viva alteza longiflorum e híbrido asiática' },
    { Clave: '10215402', Descripcion: 'Azucena viva black out' },
    { Clave: '10215403', Descripcion: 'Azucena viva rosado oscuro' },
    { Clave: '10215404', Descripcion: 'Azucena viva leéctrica asiática' },
    { Clave: '10215405', Descripcion: 'Azucena viva festival asiática' },
    { Clave: '10215406', Descripcion: 'Azucena viva ginebra asiática' },
    { Clave: '10215407', Descripcion: 'Azucena viva rosado claro asiática' },
    { Clave: '10215408', Descripcion: 'Azucena viva colombina asiática' },
    { Clave: '10215409', Descripcion: 'Azucena viva miss américa púrpura asiática' },
    { Clave: '10215410', Descripcion: 'Azucena viva monte negro asiática' },
    { Clave: '10215411', Descripcion: 'Azucena viva anaranjada asiática' },
    { Clave: '10215412', Descripcion: 'Azucena viva durazno cannes asiática' },
    { Clave: '10215413', Descripcion: 'Azucena viva rosada asiática' },
    { Clave: '10215414', Descripcion: 'Azucena viva sancerre asiátia' },
    { Clave: '10215415', Descripcion: 'Azucena viva white dream asiática' },
    { Clave: '10215416', Descripcion: 'Azucena viva amarilla asiática' },
    { Clave: '10215417', Descripcion: 'Azucena viva diamante brillante longiflorum e híbrido asiática' },
    { Clave: '10215418', Descripcion: 'Azucena viva brindisi longiflorum e híbrido asiática' },
    { Clave: '10215419', Descripcion: 'Azucena viva carmine longiflorum e híbrido asiática' },
    { Clave: '10215420', Descripcion: 'Azucena viva cinnabar longiflorum e híbrido asiática' },
    { Clave: '10215421', Descripcion: 'Azucena viva club longiflorum e híbrido asiática' },
    { Clave: '10215422', Descripcion: 'Azucena viva discovery longiflorum e híbrido asiática' },
    { Clave: '10215423', Descripcion: 'Azucena viva de pascua' },
    { Clave: '10215424', Descripcion: 'Azucena viva isis longiflorum e híbrido asiática' },
    { Clave: '10215425', Descripcion: 'Azucena viva la hybrid justice longiflorum e híbrido asiática' },
    { Clave: '10215426', Descripcion: 'Azucena viva lace longiflorum e híbrido asiática' },
    { Clave: '10215427', Descripcion: 'Azucena viva lirio de los valles' },
    { Clave: '10215428', Descripcion: 'Azucena viva love longiflorum e híbrido asiática' },
    { Clave: '10215429', Descripcion: 'Azucena viva menorca longiflorum e híbrido asiática' },
    { Clave: '10215430', Descripcion: 'Azucena viva oriental acapulco' },
    { Clave: '10215431', Descripcion: 'Azucena viva oriental albion' },
    { Clave: '10215432', Descripcion: 'Azucena viva oriental argentina' },
    { Clave: '10215433', Descripcion: 'Azucena viva oriental auratum' },
    { Clave: '10215434', Descripcion: 'Azucena viva oriental barbaresco' },
    { Clave: '10215435', Descripcion: 'Azucena viva oriental bernini' },
    { Clave: '10215436', Descripcion: 'Azucena viva oriental beseno' },
    { Clave: '10215437', Descripcion: 'Azucena viva oriental broadway' },
    { Clave: '10215438', Descripcion: 'Azucena viva oriental canada' },
    { Clave: '10215439', Descripcion: 'Azucena viva oriental casablanca' },
    { Clave: '10215440', Descripcion: 'Azucena viva oriental chili' },
    { Clave: '10215441', Descripcion: 'Azucena viva oriental chrystal' },
    { Clave: '10215442', Descripcion: 'Azucena viva oriental cobra' },
    { Clave: '10215443', Descripcion: 'Azucena viva oriental conca d? or' },
    { Clave: '10215444', Descripcion: 'Azucena viva oriental cote d? ivor' },
    { Clave: '10215445', Descripcion: 'Azucena viva oriental dizzy' },
    { Clave: '10215446', Descripcion: 'Azucena viva oriental fireball' },
    { Clave: '10215447', Descripcion: 'Azucena viva oriental gluhwein' },
    { Clave: '10215448', Descripcion: 'Azucena viva oriental goldband' },
    { Clave: '10215449', Descripcion: 'Azucena viva oriental halifax' },
    { Clave: '10215450', Descripcion: 'Azucena viva oriental kathryn' },
    { Clave: '10215451', Descripcion: 'Azucena viva oriental kyoto' },
    { Clave: '10215452', Descripcion: 'Azucena viva oriental la mancha' },
    { Clave: '10215453', Descripcion: 'Azucena viva oriental medusa' },
    { Clave: '10215454', Descripcion: 'Azucena viva oriental montezuma' },
    { Clave: '10215455', Descripcion: 'Azucena viva oriental muscadet' },
    { Clave: '10215456', Descripcion: 'Azucena viva oriental nippon' },
    { Clave: '10215457', Descripcion: 'Azucena viva oriental opus one' },
    { Clave: '10215458', Descripcion: 'Azucena viva oriental pompeii' },
    { Clave: '10215459', Descripcion: 'Azucena viva oriental rialto' },
    { Clave: '10215460', Descripcion: 'Azucena viva oriental robina' },
    { Clave: '10215461', Descripcion: 'Azucena viva oriental rousilon' },
    { Clave: '10215462', Descripcion: 'Azucena viva oriental siberia' },
    { Clave: '10215463', Descripcion: 'Azucena viva oriental sorbonne' },
    { Clave: '10215464', Descripcion: 'Azucena viva oriental starfighter' },
    { Clave: '10215465', Descripcion: 'Azucena viva oriental stargazer' },
    { Clave: '10215466', Descripcion: 'Azucena viva oriental sumatra' },
    { Clave: '10215467', Descripcion: 'Azucena viva oriental time out' },
    { Clave: '10215468', Descripcion: 'Azucena viva oriental tom pouche' },
    { Clave: '10215469', Descripcion: 'Azucena viva oriental tropical' },
    { Clave: '10215470', Descripcion: 'Azucena viva oriental white cup' },
    { Clave: '10215471', Descripcion: 'Azucena viva oriental white merostar' },
    { Clave: '10215472', Descripcion: 'Azucena viva oriental white montana' },
    { Clave: '10215473', Descripcion: 'Azucena viva oriental white stargazer' },
    { Clave: '10215474', Descripcion: 'Azucena viva oriental yellow band' },
    { Clave: '10215475', Descripcion: 'Azucena viva oriental yellow dream' },
    { Clave: '10215476', Descripcion: 'Azucena viva oriental yellow queen' },
    { Clave: '10215477', Descripcion: 'Azucena viva oriental yellow star' },
    { Clave: '10215478', Descripcion: 'Azucena viva oriental yeloween' },
    { Clave: '10215479', Descripcion: 'Azucena viva ot red dutch' },
    { Clave: '10215480', Descripcion: 'Azucena viva sonata nimph' },
    { Clave: '10215481', Descripcion: 'Azucena viva sonata shocking' },
    { Clave: '10215482', Descripcion: 'Azucena viva sonata triumphateer' },
    { Clave: '10215483', Descripcion: 'Azucena viva sunset longiflorum e híbrido asiática' },
    { Clave: '10215484', Descripcion: 'Azucena viva de agua' },
    { Clave: '10215500', Descripcion: 'Lavanda viva' },
    { Clave: '10215501', Descripcion: 'Lavanda marina viva misty peach' },
    { Clave: '10215502', Descripcion: 'Lavanda marina viva misty rosada' },
    { Clave: '10215503', Descripcion: 'Lavanda marina viva misty blanca' },
    { Clave: '10215504', Descripcion: 'Lavanda marina viva misty amarilla' },
    { Clave: '10215505', Descripcion: 'Lavanda marina viva safora' },
    { Clave: '10215506', Descripcion: 'Lavanda marina viva sinensis' },
    { Clave: '10215600', Descripcion: 'Lisianthus vivo' },
    { Clave: '10215601', Descripcion: 'Lisianthus vivo crema' },
    { Clave: '10215602', Descripcion: 'Lisianthus vivo rosado fuerte' },
    { Clave: '10215603', Descripcion: 'Lisianthus vivo verde' },
    { Clave: '10215604', Descripcion: 'Lisianthus vivo lavanda' },
    { Clave: '10215605', Descripcion: 'Lisianthus vivo rosado claro' },
    { Clave: '10215606', Descripcion: 'Lisianthus vivo mini blanco' },
    { Clave: '10215607', Descripcion: 'Lisianthus vivo durazno' },
    { Clave: '10215608', Descripcion: 'Lisianthus vivo rosado con borde blanco' },
    { Clave: '10215609', Descripcion: 'Lisianthus vivo púrpura' },
    { Clave: '10215610', Descripcion: 'Lisianthus vivo púrpura con borde blanco' },
    { Clave: '10215611', Descripcion: 'Lisianthus vivo blanco con borde rosado' },
    { Clave: '10215612', Descripcion: 'Lisianthus vivo blanco' },
    { Clave: '10215613', Descripcion: 'Lisianthus vivo blanco con borde púrpura' },
    { Clave: '10215700', Descripcion: 'Muscari viva' },
    { Clave: '10215701', Descripcion: 'Muscari viva armeniacum' },
    { Clave: '10215702', Descripcion: 'Muscari viva bortyoides blanca' },
    { Clave: '10215703', Descripcion: 'Muscari viva verde' },
    { Clave: '10215704', Descripcion: 'Muscari viva latifolia' },
    { Clave: '10215705', Descripcion: 'Muscari viva valerie finn' },
    { Clave: '10215800', Descripcion: 'Narciso vivo' },
    { Clave: '10215801', Descripcion: 'Narciso vivo alegría' },
    { Clave: '10215802', Descripcion: 'Narciso vivo amanecer dorado' },
    { Clave: '10215803', Descripcion: 'Narciso vivo abba paperwhite' },
    { Clave: '10215804', Descripcion: 'Narciso vivo paperwhite' },
    { Clave: '10215805', Descripcion: 'Narciso vivo ojo de faisán' },
    { Clave: '10215806', Descripcion: 'Narciso vivo soleil d? or' },
    { Clave: '10215807', Descripcion: 'Narciso vivo tete a tete' },
    { Clave: '10215808', Descripcion: 'Narciso vivo thalia' },
    { Clave: '10216000', Descripcion: 'Pimientos ornamentales vivos' },
    { Clave: '10216001', Descripcion: 'Pimiento chile vivo ornamental' },
    { Clave: '10216002', Descripcion: 'Pimiento mezclado vivo ornamental' },
    { Clave: '10216003', Descripcion: 'Pimiento anaranjado vivo ornamental' },
    { Clave: '10216004', Descripcion: 'Pimiento rojo vivo ornamental' },
    { Clave: '10216005', Descripcion: 'Pimiento amarillo vivo ornamental' },
    { Clave: '10216100', Descripcion: 'Estrella de Belén viva' },
    { Clave: '10216101', Descripcion: 'Estrella de belén viva arábica' },
    { Clave: '10216102', Descripcion: 'Estrella de belén viva dubium anaranjada' },
    { Clave: '10216103', Descripcion: 'Estrella de belén viva umbellada' },
    { Clave: '10216104', Descripcion: 'Estrella de belén viva dubium blanca' },
    { Clave: '10216105', Descripcion: 'Estrella de belén viva dubium amarilla' },
    { Clave: '10216200', Descripcion: 'Peonia viva' },
    { Clave: '10216201', Descripcion: 'Peonia viva alexander fleming' },
    { Clave: '10216202', Descripcion: 'Peonia viva coral charm' },
    { Clave: '10216203', Descripcion: 'Peonia viva suset' },
    { Clave: '10216204', Descripcion: 'Peonia viva coral supreme' },
    { Clave: '10216205', Descripcion: 'Peonia viva gardenia doble' },
    { Clave: '10216206', Descripcion: 'Peonia viva doble jues eli oscura' },
    { Clave: '10216207', Descripcion: 'Peonia viva soble duquesa blanca' },
    { Clave: '10216208', Descripcion: 'Peonia viva felix crousse' },
    { Clave: '10216209', Descripcion: 'Peonia viva festiva máxima' },
    { Clave: '10216210', Descripcion: 'Peonia viva tesoro del jardín' },
    { Clave: '10216211', Descripcion: 'Peonia viva kansas rosado oscuro' },
    { Clave: '10216212', Descripcion: 'Peonia viva karl rosenfelt' },
    { Clave: '10216213', Descripcion: 'Peonia viva paula fay' },
    { Clave: '10216214', Descripcion: 'Peonia viva encanto rojo' },
    { Clave: '10216215', Descripcion: 'Peonia viva pasion roja' },
    { Clave: '10216216', Descripcion: 'Peonia viva sarah bernhardt roja' },
    { Clave: '10216217', Descripcion: 'Peonia viva scarlet o? hara' },
    { Clave: '10216218', Descripcion: 'Peonia viva shirley temple' },
    { Clave: '10216300', Descripcion: 'Banksia viva' },
    { Clave: '10216301', Descripcion: 'Banksia viva ashbyi' },
    { Clave: '10216302', Descripcion: 'Banksia viva baxteri' },
    { Clave: '10216306', Descripcion: 'Banksia viva coccinea' },
    { Clave: '10216311', Descripcion: 'Banksia viva ericifolia' },
    { Clave: '10216315', Descripcion: 'Banksia viva verde' },
    { Clave: '10216322', Descripcion: 'Banksia viva menziesii' },
    { Clave: '10216325', Descripcion: 'Banksia viva blanco natural' },
    { Clave: '10216326', Descripcion: 'Banksia viva anaranjado' },
    { Clave: '10216332', Descripcion: 'Banksia viva rosada' },
    { Clave: '10216400', Descripcion: 'Ranúculo vivo' },
    { Clave: '10216401', Descripcion: 'Ranúnculo vivo chocolate' },
    { Clave: '10216402', Descripcion: 'Ranúnculo vivo elegancia' },
    { Clave: '10216403', Descripcion: 'Ranúnculo vivo verde' },
    { Clave: '10216404', Descripcion: 'Ranúnculo vivo grimaldi' },
    { Clave: '10216405', Descripcion: 'Ranúnculo vivo rosado fuerte' },
    { Clave: '10216406', Descripcion: 'Ranúnculo vivo rosado claro' },
    { Clave: '10216407', Descripcion: 'Ranúnculo vivo anaranjado' },
    { Clave: '10216408', Descripcion: 'Ranúnculo vivo centro verde' },
    { Clave: '10216409', Descripcion: 'Ranúnculo vivo rosado' },
    { Clave: '10216410', Descripcion: 'Ranúnculo vivo rojo' },
    { Clave: '10216411', Descripcion: 'Ranúnculo vivo blanco' },
    { Clave: '10216412', Descripcion: 'Ranúnculo vivo amarillo' },
    { Clave: '10216413', Descripcion: 'Ranúnculo vivo salmón' },
    { Clave: '10216500', Descripcion: 'Escabiosa viva' },
    { Clave: '10216501', Descripcion: 'Escabiosa viva anual' },
    { Clave: '10216502', Descripcion: 'Escabiosa viva negra' },
    { Clave: '10216503', Descripcion: 'Escabiosa viva caucásica azul' },
    { Clave: '10216504', Descripcion: 'Escabiosa viva caucásica rosada' },
    { Clave: '10216505', Descripcion: 'Escabiosa viva caucásica vainas' },
    { Clave: '10216506', Descripcion: 'Escabiosa viva caucásica blanca' },
    { Clave: '10216507', Descripcion: 'Escabiosa viva fresa' },
    { Clave: '10216600', Descripcion: 'Retama de escobas viva' },
    { Clave: '10216601', Descripcion: 'Retama de escobas viva rosada' },
    { Clave: '10216602', Descripcion: 'Retama de escobas viva púrpura' },
    { Clave: '10216603', Descripcion: 'Retama de escobas viva blanca' },
    { Clave: '10216604', Descripcion: 'Retama de escobas viva amarilla' },
    { Clave: '10216700', Descripcion: 'Boca de dragón viva' },
    { Clave: '10216701', Descripcion: 'Boca de dragón viva bicolor' },
    { Clave: '10216702', Descripcion: 'Boca de dragón viva burgundy' },
    { Clave: '10216703', Descripcion: 'Boca de dragón viva rosado fuerte' },
    { Clave: '10216704', Descripcion: 'Boca de dragón viva lavanda' },
    { Clave: '10216705', Descripcion: 'Boca de dragón viva anaranjado claro' },
    { Clave: '10216706', Descripcion: 'Boca de dragón viva rosado claro' },
    { Clave: '10216707', Descripcion: 'Boca de dragón viva anaranjado' },
    { Clave: '10216708', Descripcion: 'Boca de dragón viva blanco' },
    { Clave: '10216709', Descripcion: 'Boca de dragón viva amarillo' },
    { Clave: '10216800', Descripcion: 'Romero del pantano viva' },
    { Clave: '10216801', Descripcion: 'Romero del pantano viva azul' },
    { Clave: '10216802', Descripcion: 'Romero del pantano viva lavanda' },
    { Clave: '10216803', Descripcion: 'Romero del pantano viva durazno' },
    { Clave: '10216804', Descripcion: 'Romero del pantano viva rosada' },
    { Clave: '10216805', Descripcion: 'Romero del pantano viva púrpura' },
    { Clave: '10216806', Descripcion: 'Romero del pantano viva espuma de mar' },
    { Clave: '10216807', Descripcion: 'Romero del pantano viva blanca' },
    { Clave: '10216808', Descripcion: 'Romero del pantano viva amarilla' },
    { Clave: '10216900', Descripcion: 'Matiola viva' },
    { Clave: '10216901', Descripcion: 'Matiola viva apricot' },
    { Clave: '10216902', Descripcion: 'Matiola viva crema' },
    { Clave: '10216903', Descripcion: 'Matiola viva fucsia' },
    { Clave: '10216904', Descripcion: 'Matiola viva lavanda' },
    { Clave: '10216905', Descripcion: 'Matiola viva lavanda claro' },
    { Clave: '10216906', Descripcion: 'Matiola viva rosada' },
    { Clave: '10216907', Descripcion: 'Matiola viva púrpura' },
    { Clave: '10216908', Descripcion: 'Matiola viva rojo rubí' },
    { Clave: '10216909', Descripcion: 'Matiola viva enamorada rosda' },
    { Clave: '10216910', Descripcion: 'Matiola viva blanca' },
    { Clave: '10217000', Descripcion: 'Girasol viva' },
    { Clave: '10217001', Descripcion: 'Girasol viva tinte festivo' },
    { Clave: '10217002', Descripcion: 'Girasol viva mahogany' },
    { Clave: '10217003', Descripcion: 'Girasol viva rayo de sol' },
    { Clave: '10217004', Descripcion: 'Girasol viva brillo del sol' },
    { Clave: '10217005', Descripcion: 'Girasol viva salpicada de sol' },
    { Clave: '10217006', Descripcion: 'Girasol viva oso de peluche' },
    { Clave: '10217100', Descripcion: 'Guisante de olor vivo' },
    { Clave: '10217101', Descripcion: 'Guisante de olor vivo verde teñido' },
    { Clave: '10217102', Descripcion: 'Guisante de olor vivo rosado fuerte' },
    { Clave: '10217103', Descripcion: 'Guisante de olor vivo lavanda' },
    { Clave: '10217104', Descripcion: 'Guisante de olor vivo rosado claro' },
    { Clave: '10217105', Descripcion: 'Guisante de olor vivo anaranjado' },
    { Clave: '10217106', Descripcion: 'Guisante de olor vivo durazno teñido' },
    { Clave: '10217107', Descripcion: 'Guisante de olor vivo púrpura' },
    { Clave: '10217108', Descripcion: 'Guisante de olor vivo blanco' },
    { Clave: '10217200', Descripcion: 'Cardo vivo' },
    { Clave: '10217201', Descripcion: 'Cardo vivo alpinum' },
    { Clave: '10217202', Descripcion: 'Cardo vivo echinops' },
    { Clave: '10217203', Descripcion: 'Cardo vivo eryngium árabe' },
    { Clave: '10217204', Descripcion: 'Cardo vivo eryngium azul' },
    { Clave: '10217205', Descripcion: 'Cardo vivo eryngium orión' },
    { Clave: '10217206', Descripcion: 'Cardo vivo eryngium frambuesa' },
    { Clave: '10217207', Descripcion: 'Cardo vivo eryngium supernova' },
    { Clave: '10217208', Descripcion: 'Cardo vivo eryngium campanita' },
    { Clave: '10217300', Descripcion: 'Tulipán vivo' },
    { Clave: '10217301', Descripcion: 'Tulipán vivo adrem' },
    { Clave: '10217302', Descripcion: 'Tulipán vivo apricot' },
    { Clave: '10217303', Descripcion: 'Tulipán vivo bicolor rojo y amarillo' },
    { Clave: '10217304', Descripcion: 'Tulipán vivo doble bicolor' },
    { Clave: '10217305', Descripcion: 'Tulipán vivo doble rosado' },
    { Clave: '10217306', Descripcion: 'Tulipán vivo doble rojo' },
    { Clave: '10217307', Descripcion: 'Tulipán vivo doble blanco' },
    { Clave: '10217308', Descripcion: 'Tulipán vivo doble amarillo' },
    { Clave: '10217309', Descripcion: 'Tulipán vivo francés avignon' },
    { Clave: '10217310', Descripcion: 'Tulipán vivo francés camarque' },
    { Clave: '10217311', Descripcion: 'Tulipán vivo francés dordogne' },
    { Clave: '10217312', Descripcion: 'Tulipán vivo francés fiat' },
    { Clave: '10217313', Descripcion: 'Tulipán vivo francés flamboyant' },
    { Clave: '10217314', Descripcion: 'Tulipán vivo francés flaming parrot' },
    { Clave: '10217315', Descripcion: 'Tulipán vivo francés florissa' },
    { Clave: '10217316', Descripcion: 'Tulipán vivo francés doble maureen' },
    { Clave: '10217317', Descripcion: 'Tulipán vivo francés maureen' },
    { Clave: '10217318', Descripcion: 'Tulipán vivo francés menton' },
    { Clave: '10217319', Descripcion: 'Tulipán vivo francés montpellier' },
    { Clave: '10217320', Descripcion: 'Tulipán vivo francés naranja unique' },
    { Clave: '10217321', Descripcion: 'Tulipán vivo francés peonia reconocido único' },
    { Clave: '10217322', Descripcion: 'Tulipán vivo francés loro rosado' },
    { Clave: '10217323', Descripcion: 'Tulipán vivo francés princesa unique' },
    { Clave: '10217324', Descripcion: 'Tulipán vivo francés reconocido' },
    { Clave: '10217325', Descripcion: 'Tulipán vivo francés schepppers' },
    { Clave: '10217326', Descripcion: 'Tulipán vivo francés gamuza' },
    { Clave: '10217327', Descripcion: 'Tulipán vivo francés toyota' },
    { Clave: '10217328', Descripcion: 'Tulipán vivo francés loro weber' },
    { Clave: '10217329', Descripcion: 'Tulipán vivo francés loro blanco' },
    { Clave: '10217330', Descripcion: 'Tulipán vivo lavanda de borde crespo' },
    { Clave: '10217331', Descripcion: 'Tulipán vivo rosado fuerte' },
    { Clave: '10217332', Descripcion: 'Tulipán vivo rosado fuerte hoja variegada' },
    { Clave: '10217333', Descripcion: 'Tulipán vivo lavanda' },
    { Clave: '10217334', Descripcion: 'Tulipán vivo rosado claro hoja variegada' },
    { Clave: '10217335', Descripcion: 'Tulipán vivo viuda alegre' },
    { Clave: '10217336', Descripcion: 'Tulipán vivo anaranjado' },
    { Clave: '10217337', Descripcion: 'Tulipán vivo loro negro' },
    { Clave: '10217338', Descripcion: 'Tulipán vivo loro estela rijnveld' },
    { Clave: '10217339', Descripcion: 'Tulipán vivo llameante' },
    { Clave: '10217340', Descripcion: 'Tulipán vivo loro verde' },
    { Clave: '10217341', Descripcion: 'Tulipán vivo loro lavanda' },
    { Clave: '10217342', Descripcion: 'Tulipán vivo loro anaranjado' },
    { Clave: '10217343', Descripcion: 'Tulipán vivo loro durazno' },
    { Clave: '10217344', Descripcion: 'Tulipán vivo loro rosado' },
    { Clave: '10217345', Descripcion: 'Tulipán vivo loro rojo' },
    { Clave: '10217346', Descripcion: 'Tulipán vivo loro rojo rococó' },
    { Clave: '10217347', Descripcion: 'Tulipán vivo loro weber' },
    { Clave: '10217348', Descripcion: 'Tulipán vivo loro blanco' },
    { Clave: '10217349', Descripcion: 'Tulipán vivo loro amarillo' },
    { Clave: '10217350', Descripcion: 'Tulipán vivo rosado' },
    { Clave: '10217351', Descripcion: 'Tulipán vivo púrpura' },
    { Clave: '10217352', Descripcion: 'Tulipán vivo rojo' },
    { Clave: '10217353', Descripcion: 'Tulipán vivo especias' },
    { Clave: '10217354', Descripcion: 'Tulipán vivo blanco' },
    { Clave: '10217355', Descripcion: 'Tulipán vivo amarillo' },
    { Clave: '10217400', Descripcion: 'Flor de cera viva' },
    { Clave: '10217401', Descripcion: 'Flor de cera viva alba' },
    { Clave: '10217402', Descripcion: 'Flor de cera viva bicolor' },
    { Clave: '10217403', Descripcion: 'Flor de cera viva chichilla' },
    { Clave: '10217404', Descripcion: 'Flor de cera viva reina danzante' },
    { Clave: '10217405', Descripcion: 'Flor de cera viva dinamarca' },
    { Clave: '10217406', Descripcion: 'Flor de cera viva denmar' },
    { Clave: '10217407', Descripcion: 'Flor de cera viva híbrida pastel flor gema' },
    { Clave: '10217408', Descripcion: 'Flor de cera viva híbrida rosada flor gem' },
    { Clave: '10217409', Descripcion: 'Flor de cera viva híbrida blanca rubia' },
    { Clave: '10217410', Descripcion: 'Flor de cera viva híbrida eric john' },
    { Clave: '10217411', Descripcion: 'Flor de cera viva híbrida dama pintada' },
    { Clave: '10217412', Descripcion: 'Flor de cera viva híbrida revelación' },
    { Clave: '10217413', Descripcion: 'Flor de cera viva híbrida bola de nieve' },
    { Clave: '10217414', Descripcion: 'Flor de cera viva juriens brook' },
    { Clave: '10217415', Descripcion: 'Flor de cera viva lady stephanie rosada' },
    { Clave: '10217416', Descripcion: 'Flor de cera viva madonna' },
    { Clave: '10217417', Descripcion: 'Flor de cera viva mini blanca' },
    { Clave: '10217418', Descripcion: 'Flor de cera viva anaranjada' },
    { Clave: '10217419', Descripcion: 'Flor de cera viva perla' },
    { Clave: '10217420', Descripcion: 'Flor de cera viva pixie moon' },
    { Clave: '10217421', Descripcion: 'Flor de cera viva orgullo púrpura' },
    { Clave: '10217422', Descripcion: 'Flor de cera viva roja' },
    { Clave: '10217423', Descripcion: 'Flor de cera viva wanaroo' },
    { Clave: '10217424', Descripcion: 'Flor de cera viva amarilla' },
    { Clave: '10217500', Descripcion: 'Hierba de aquiles viva' },
    { Clave: '10217501', Descripcion: 'Hierba de aquiles viva vinotinto' },
    { Clave: '10217502', Descripcion: 'Hierba de aquiles viva crema campesina' },
    { Clave: '10217503', Descripcion: 'Hierba de aquiles viva rosado campesino' },
    { Clave: '10217504', Descripcion: 'Hierba de aquiles viva luz de luna' },
    { Clave: '10217505', Descripcion: 'Hierba de aquiles viva anaranjado' },
    { Clave: '10217506', Descripcion: 'Hierba de aquiles viva durazno' },
    { Clave: '10217507', Descripcion: 'Hierba de aquiles viva rosada' },
    { Clave: '10217508', Descripcion: 'Hierba de aquiles viva rojo teñido' },
    { Clave: '10217509', Descripcion: 'Hierba de aquiles viva blanca' },
    { Clave: '10217510', Descripcion: 'Hierba de aquiles viva amarilla' },
    { Clave: '10217600', Descripcion: 'Zinnia viva' },
    { Clave: '10217601', Descripcion: 'Zinia viva amarilla' },
    { Clave: '10217602', Descripcion: 'Zinia viva mini' },
    { Clave: '10217603', Descripcion: 'Zinia viva rosado' },
    { Clave: '10217604', Descripcion: 'Zinia viva rojo' },
    { Clave: '10217605', Descripcion: 'Zinia viva salmón' },
    { Clave: '10217606', Descripcion: 'Zinia viva amarillo' },
    { Clave: '10217700', Descripcion: 'Forsythia viva' },
    { Clave: '10217701', Descripcion: 'Forsythia viva viridissima' },
    { Clave: '10217702', Descripcion: 'Forsythia viva giraldiana' },
    { Clave: '10217703', Descripcion: 'Forsythia viva mira' },
    { Clave: '10217704', Descripcion: 'Forsythia viva suspensa' },
    { Clave: '10217705', Descripcion: 'Forsythia viva intermedia' },
    { Clave: '10217706', Descripcion: 'Forsythia viva variabilis' },
    { Clave: '10217707', Descripcion: 'Forsythia viva ovate' },
    { Clave: '10217708', Descripcion: 'Forsythia viva intermedia lynwood' },
    { Clave: '10217800', Descripcion: 'Geranio vivo' },
    { Clave: '10217801', Descripcion: 'Geranio vivo argenteum' },
    { Clave: '10217802', Descripcion: 'Geranio vivo cinereum' },
    { Clave: '10217803', Descripcion: 'Geranio vivo clarkei' },
    { Clave: '10217804', Descripcion: 'Geranio vivo dalmaticum' },
    { Clave: '10217805', Descripcion: 'Geranio vivo endressii' },
    { Clave: '10217806', Descripcion: 'Geranio vivo eriostemon' },
    { Clave: '10217807', Descripcion: 'Geranio vivo farreri' },
    { Clave: '10217808', Descripcion: 'Geranio vivo himalayense o grandiflora' },
    { Clave: '10217809', Descripcion: 'Geranio vivo ibericum' },
    { Clave: '10217810', Descripcion: 'Geranio vivo macrorhizum o raiz grande' },
    { Clave: '10217811', Descripcion: 'Geranio vivo maculatum' },
    { Clave: '10217812', Descripcion: 'Geranio vivo nodosum' },
    { Clave: '10217813', Descripcion: 'Geranio vivo phaeum' },
    { Clave: '10217814', Descripcion: 'Geranio vivo platypetalum' },
    { Clave: '10217815', Descripcion: 'Geranio vivo pratense' },
    { Clave: '10217816', Descripcion: 'Geranio vivo procurrens' },
    { Clave: '10217817', Descripcion: 'Geranio vivo psilostemon' },
    { Clave: '10217818', Descripcion: 'Geranio vivo pylzowianum' },
    { Clave: '10217819', Descripcion: 'Geranio vivo renardii' },
    { Clave: '10217820', Descripcion: 'Geranio vivo sanguineum o sangruento' },
    { Clave: '10217821', Descripcion: 'Geranio vivo sylvaticum' },
    { Clave: '10217822', Descripcion: 'Geranio vivo traversii' },
    { Clave: '10217823', Descripcion: 'Geranio vivo tuberosum' },
    { Clave: '10217824', Descripcion: 'Geranio vivo versicolor' },
    { Clave: '10217825', Descripcion: 'Geranio vivo wallachianum' },
    { Clave: '10217826', Descripcion: 'Geranio vivo wlassovianum' },
    { Clave: '10217827', Descripcion: 'Geranio vivo x magnificum o llamativo' },
    { Clave: '10217900', Descripcion: 'Amaryllis viva' },
    { Clave: '10217901', Descripcion: 'Amaryllis viva aglaiae' },
    { Clave: '10217902', Descripcion: 'Amaryllis viva amaru' },
    { Clave: '10217903', Descripcion: 'Amaryllis viva angustifolium' },
    { Clave: '10217904', Descripcion: 'Amaryllis viva anzaldoi' },
    { Clave: '10217905', Descripcion: 'Amaryllis viva araripinum' },
    { Clave: '10217906', Descripcion: 'Amaryllis viva arboricola' },
    { Clave: '10217907', Descripcion: 'Amaryllis viva argentinum' },
    { Clave: '10217908', Descripcion: 'Amaryllis viva aulicum' },
    { Clave: '10217909', Descripcion: 'Amaryllis viva aviflorum' },
    { Clave: '10217910', Descripcion: 'Amaryllis viva barreirasum' },
    { Clave: '10217911', Descripcion: 'Amaryllis viva blossfeldiae' },
    { Clave: '10217912', Descripcion: 'Amaryllis viva blumenavium' },
    { Clave: '10217913', Descripcion: 'Amaryllis viva brasilianum' },
    { Clave: '10217914', Descripcion: 'Amaryllis viva breviflorum' },
    { Clave: '10217915', Descripcion: 'Amaryllis viva bukasovii' },
    { Clave: '10217916', Descripcion: 'Amaryllis viva calyptratum' },
    { Clave: '10217917', Descripcion: 'Amaryllis viva caupolicanense' },
    { Clave: '10217918', Descripcion: 'Amaryllis viva chionedyanthum' },
    { Clave: '10217919', Descripcion: 'Amaryllis viva condemaita' },
    { Clave: '10217920', Descripcion: 'Amaryllis viva corriense' },
    { Clave: '10217921', Descripcion: 'Amaryllis viva cuzcoense' },
    { Clave: '10217922', Descripcion: 'Amaryllis viva curitibanum' },
    { Clave: '10217923', Descripcion: 'Amaryllis viva cybister' },
    { Clave: '10217924', Descripcion: 'Amaryllis viva divijuliani' },
    { Clave: '10217925', Descripcion: 'Amaryllis viva evansiae' },
    { Clave: '10217926', Descripcion: 'Amaryllis viva ferreyrae' },
    { Clave: '10217927', Descripcion: 'Amaryllis viva forgetii' },
    { Clave: '10217928', Descripcion: 'Amaryllis viva fosteri' },
    { Clave: '10217929', Descripcion: 'Amaryllis viva fuscum' },
    { Clave: '10217930', Descripcion: 'Amaryllis viva glaucescens' },
    { Clave: '10217931', Descripcion: 'Amaryllis viva goianum' },
    { Clave: '10217932', Descripcion: 'Amaryllis viva guarapuavicum' },
    { Clave: '10217933', Descripcion: 'Amaryllis viva harrisonii' },
    { Clave: '10217934', Descripcion: 'Amaryllis viva hugoi' },
    { Clave: '10217935', Descripcion: 'Amaryllis viva iguazuanum' },
    { Clave: '10217936', Descripcion: 'Amaryllis viva illustre' },
    { Clave: '10217937', Descripcion: 'Amaryllis viva intiflorum' },
    { Clave: '10217938', Descripcion: 'Amaryllis viva kromeri' },
    { Clave: '10217939', Descripcion: 'Amaryllis viva lapacense' },
    { Clave: '10217940', Descripcion: 'Amaryllis viva leonardii' },
    { Clave: '10217941', Descripcion: 'Amaryllis viva leopoldii' },
    { Clave: '10217942', Descripcion: 'Amaryllis viva macbridei' },
    { Clave: '10217943', Descripcion: 'Amaryllis viva machupijchense' },
    { Clave: '10217944', Descripcion: 'Amaryllis viva mandonii' },
    { Clave: '10217945', Descripcion: 'Amaryllis viva minasgerais' },
    { Clave: '10217946', Descripcion: 'Amaryllis viva miniatum' },
    { Clave: '10217947', Descripcion: 'Amaryllis viva mollevillquense' },
    { Clave: '10217948', Descripcion: 'Amaryllis viva morelianum' },
    { Clave: '10217949', Descripcion: 'Amaryllis viva nelsonii' },
    { Clave: '10217950', Descripcion: 'Amaryllis viva oconoquense' },
    { Clave: '10217951', Descripcion: 'Amaryllis viva papilio' },
    { Clave: '10217952', Descripcion: 'Amaryllis viva paquichanum' },
    { Clave: '10217953', Descripcion: 'Amaryllis viva paradisiacum' },
    { Clave: '10217954', Descripcion: 'Amaryllis viva pardinum' },
    { Clave: '10217955', Descripcion: 'Amaryllis viva parodii' },
    { Clave: '10217956', Descripcion: 'Amaryllis viva petiolatum' },
    { Clave: '10217957', Descripcion: 'Amaryllis viva psittacinum' },
    { Clave: '10217958', Descripcion: 'Amaryllis viva puniceum' },
    { Clave: '10217959', Descripcion: 'Amaryllis viva reginae' },
    { Clave: '10217960', Descripcion: 'Amaryllis viva reticulatum' },
    { Clave: '10217961', Descripcion: 'Amaryllis viva rubropictum' },
    { Clave: '10217962', Descripcion: 'Amaryllis viva santacatarina' },
    { Clave: '10217963', Descripcion: 'Amaryllis viva solandraeflorum' },
    { Clave: '10217964', Descripcion: 'Amaryllis viva starkiorum' },
    { Clave: '10217965', Descripcion: 'Amaryllis viva striatum' },
    { Clave: '10217966', Descripcion: 'Amaryllis viva stylosum' },
    { Clave: '10217967', Descripcion: 'Amaryllis viva teyucuarense' },
    { Clave: '10217968', Descripcion: 'Amaryllis viva traubii' },
    { Clave: '10217969', Descripcion: 'Amaryllis viva vargasii' },
    { Clave: '10217970', Descripcion: 'Amaryllis viva variegatum' },
    { Clave: '10217971', Descripcion: 'Amaryllis viva vittatum' },
    { Clave: '10217972', Descripcion: 'Amaryllis viva yungacense' },
    { Clave: '10218000', Descripcion: 'Rubdeckia viva' },
    { Clave: '10218001', Descripcion: 'Rubdeckia viva alpicola' },
    { Clave: '10218002', Descripcion: 'Rubdeckia viva amplexicaulis' },
    { Clave: '10218003', Descripcion: 'Rubdeckia viva auriculata' },
    { Clave: '10218004', Descripcion: 'Rubdeckia viva bicolor' },
    { Clave: '10218005', Descripcion: 'Rubdeckia viva californica' },
    { Clave: '10218006', Descripcion: 'Rubdeckia viva fulgida' },
    { Clave: '10218007', Descripcion: 'Rubdeckia viva glaucescens' },
    { Clave: '10218008', Descripcion: 'Rubdeckia viva graminifolia' },
    { Clave: '10218009', Descripcion: 'Rubdeckia viva grandiflora' },
    { Clave: '10218010', Descripcion: 'Rubdeckia viva heliopsidis' },
    { Clave: '10218011', Descripcion: 'Rubdeckia viva hirta' },
    { Clave: '10218012', Descripcion: 'Rubdeckia viva klamathensis' },
    { Clave: '10218013', Descripcion: 'Rubdeckia viva laciniata' },
    { Clave: '10218014', Descripcion: 'Rubdeckia viva máxima' },
    { Clave: '10218015', Descripcion: 'Rubdeckia viva missouriensis' },
    { Clave: '10218016', Descripcion: 'Rubdeckia viva mohrii' },
    { Clave: '10218017', Descripcion: 'Rubdeckia viva mollis' },
    { Clave: '10218018', Descripcion: 'Rubdeckia viva montana' },
    { Clave: '10218019', Descripcion: 'Rubdeckia viva nítida' },
    { Clave: '10218020', Descripcion: 'Rubdeckia viva occidentalis' },
    { Clave: '10218021', Descripcion: 'Rubdeckia viva pinnata' },
    { Clave: '10218022', Descripcion: 'Rubdeckia viva scabrifolia' },
    { Clave: '10218023', Descripcion: 'Rubdeckia viva serotina' },
    { Clave: '10218024', Descripcion: 'Rubdeckia viva speciosa' },
    { Clave: '10218025', Descripcion: 'Rubdeckia viva subtomentosa' },
    { Clave: '10218026', Descripcion: 'Rubdeckia viva texana' },
    { Clave: '10218027', Descripcion: 'Rubdeckia viva triloba' },
    { Clave: '10218100', Descripcion: 'Protea viva' },
    { Clave: '10218101', Descripcion: 'Protea viva bouquet' },
    { Clave: '10218102', Descripcion: 'Protea viva cepillo botella' },
    { Clave: '10218103', Descripcion: 'Protea viva carnaval' },
    { Clave: '10218104', Descripcion: 'Protea viva follaje cordata' },
    { Clave: '10218105', Descripcion: 'Protea viva grandiceps' },
    { Clave: '10218106', Descripcion: 'Protea viva visón verde' },
    { Clave: '10218107', Descripcion: 'Protea viva hiedra' },
    { Clave: '10218108', Descripcion: 'Protea viva rey' },
    { Clave: '10218109', Descripcion: 'Protea viva nana cones' },
    { Clave: '10218110', Descripcion: 'Protea viva alfiletero anaranjada' },
    { Clave: '10218111', Descripcion: 'Protea viva alfiletero tango' },
    { Clave: '10218112', Descripcion: 'Protea viva alfiletero amarillo' },
    { Clave: '10218113', Descripcion: 'Protea viva hielo rosado' },
    { Clave: '10218114', Descripcion: 'Protea viva visón rosado' },
    { Clave: '10218115', Descripcion: 'Protea viva reina' },
    { Clave: '10218116', Descripcion: 'Protea viva repens' },
    { Clave: '10218117', Descripcion: 'Protea viva cuchara de rosas' },
    { Clave: '10218118', Descripcion: 'Protea viva silvia' },
    { Clave: '10218119', Descripcion: 'Protea viva sugarbush' },
    { Clave: '10218120', Descripcion: 'Protea viva susara' },
    { Clave: '10218121', Descripcion: 'Protea viva waratha' },
    { Clave: '10218122', Descripcion: 'Protea viva visón blanco' },
    { Clave: '10218200', Descripcion: 'leucadendron vivo' },
    { Clave: '10218201', Descripcion: 'Leucadendron vivo argenteum' },
    { Clave: '10218202', Descripcion: 'Leucadendron vivo delicia de crema' },
    { Clave: '10218203', Descripcion: 'Leucadendron vivo cumosum' },
    { Clave: '10218204', Descripcion: 'Leucadendron vivo discolor' },
    { Clave: '10218205', Descripcion: 'Leucadendron vivo galpini' },
    { Clave: '10218206', Descripcion: 'Leucadendron vivo mina de oro' },
    { Clave: '10218207', Descripcion: 'Leucadendron vivo oro inca' },
    { Clave: '10218208', Descripcion: 'Leucadendron vivo bufón' },
    { Clave: '10218209', Descripcion: 'Leucadendron vivo laxum' },
    { Clave: '10218210', Descripcion: 'Leucadendron vivo mini' },
    { Clave: '10218211', Descripcion: 'Leucadendron vivo oro patea' },
    { Clave: '10218212', Descripcion: 'Leucadendron vivo petra' },
    { Clave: '10218213', Descripcion: 'Leucadendron vivo plumosum' },
    { Clave: '10218214', Descripcion: 'Leucadendron vivo roseta' },
    { Clave: '10218215', Descripcion: 'Leucadendron vivo atardecer safari' },
    { Clave: '10218216', Descripcion: 'Leucadendron vivo atardecer safari spr' },
    { Clave: '10218217', Descripcion: 'Leucadendron vivo speciosa' },
    { Clave: '10218218', Descripcion: 'Leucadendron vivo spray' },
    { Clave: '10218219', Descripcion: 'Leucadendron vivo maravilla wilson' },
    { Clave: '10218220', Descripcion: 'Leucadendron vivo yarden' },
    { Clave: '10218300', Descripcion: 'leucospermum vivo' },
    { Clave: '10218301', Descripcion: 'Leucospermum vivo album' },
    { Clave: '10218302', Descripcion: 'Leucospermum vivo attenuatum' },
    { Clave: '10218303', Descripcion: 'Leucospermum vivo calligerum' },
    { Clave: '10218304', Descripcion: 'Leucospermum vivo conocarpodendron' },
    { Clave: '10218305', Descripcion: 'Leucospermum vivo cordatum' },
    { Clave: '10218306', Descripcion: 'Leucospermum vivo cuneiforme' },
    { Clave: '10218307', Descripcion: 'Leucospermum vivo formosum' },
    { Clave: '10218308', Descripcion: 'Leucospermum vivo glabrum' },
    { Clave: '10218309', Descripcion: 'Leucospermum vivo grandiflorum' },
    { Clave: '10218310', Descripcion: 'Leucospermum vivo harmatum' },
    { Clave: '10218311', Descripcion: 'Leucospermum vivo heterophyllum' },
    { Clave: '10218312', Descripcion: 'Leucospermum vivo innovans' },
    { Clave: '10218313', Descripcion: 'Leucospermum vivo muirii' },
    { Clave: '10218314', Descripcion: 'Leucospermum vivo oleifolium' },
    { Clave: '10218315', Descripcion: 'Leucospermum vivo patersonii' },
    { Clave: '10218316', Descripcion: 'Leucospermum vivo pluridens' },
    { Clave: '10218317', Descripcion: 'Leucospermum vivo praemorsum' },
    { Clave: '10218318', Descripcion: 'Leucospermum vivo prostratum' },
    { Clave: '10218319', Descripcion: 'Leucospermum vivo rodolentum' },
    { Clave: '10218320', Descripcion: 'Leucospermum vivo saxatile' },
    { Clave: '10218321', Descripcion: 'Leucospermum vivo secundifolium' },
    { Clave: '10218322', Descripcion: 'Leucospermum vivo tomentosus' },
    { Clave: '10218323', Descripcion: 'Leucospermum vivo truncatulum' },
    { Clave: '10218324', Descripcion: 'Leucospermum vivo utriculosum' },
    { Clave: '10218325', Descripcion: 'Leucospermum vivo winterii' },
    { Clave: '10218326', Descripcion: 'Leucospermum vivo arenarium' },
    { Clave: '10218327', Descripcion: 'Leucospermum vivo bolusii' },
    { Clave: '10218328', Descripcion: 'Leucospermum vivo catherinae' },
    { Clave: '10218329', Descripcion: 'Leucospermum vivo conocarpum' },
    { Clave: '10218330', Descripcion: 'Leucospermum vivo cordifolium' },
    { Clave: '10218331', Descripcion: 'Leucospermum vivo erubescens' },
    { Clave: '10218332', Descripcion: 'Leucospermum vivo gerrardii' },
    { Clave: '10218333', Descripcion: 'Leucospermum vivo gracile' },
    { Clave: '10218334', Descripcion: 'Leucospermum vivo gueinzii' },
    { Clave: '10218335', Descripcion: 'Leucospermum vivo harpagonatum' },
    { Clave: '10218336', Descripcion: 'Leucospermum vivo hypophyllocarpodendron' },
    { Clave: '10218337', Descripcion: 'Leucospermum vivo lineare' },
    { Clave: '10218338', Descripcion: 'Leucospermum vivo mundii' },
    { Clave: '10218339', Descripcion: 'Leucospermum vivo parile' },
    { Clave: '10218340', Descripcion: 'Leucospermum vivo pendunculatum' },
    { Clave: '10218341', Descripcion: 'Leucospermum vivo praecox' },
    { Clave: '10218342', Descripcion: 'Leucospermum vivo profugum' },
    { Clave: '10218343', Descripcion: 'Leucospermum vivo reflexum' },
    { Clave: '10218344', Descripcion: 'Leucospermum vivo royenifolium' },
    { Clave: '10218345', Descripcion: 'Leucospermum vivo saxosum' },
    { Clave: '10218346', Descripcion: 'Leucospermum vivo spathulatum' },
    { Clave: '10218347', Descripcion: 'Leucospermum vivo tottum' },
    { Clave: '10218348', Descripcion: 'Leucospermum vivo truncatum' },
    { Clave: '10218349', Descripcion: 'Leucospermum vivo vestitum' },
    { Clave: '10218350', Descripcion: 'Leucospermum vivo wittebergense' },
    { Clave: '10221500', Descripcion: 'Agapanto vivo' },
    { Clave: '10221501', Descripcion: 'Agapanto vivo azul' },
    { Clave: '10221502', Descripcion: 'Agapanto vivo blanco' },
    { Clave: '10221600', Descripcion: 'Alchimilla viva' },
    { Clave: '10221601', Descripcion: 'Alchimilla viva capa de dama' },
    { Clave: '10221602', Descripcion: 'Alchimilla viva robustica' },
    { Clave: '10221700', Descripcion: 'Astilbe vivo' },
    { Clave: '10221701', Descripcion: 'Astilbe vivo rosado fuerte' },
    { Clave: '10221702', Descripcion: 'Astilbe vivo rosado claro' },
    { Clave: '10221703', Descripcion: 'Astilbe vivo rojo' },
    { Clave: '10221704', Descripcion: 'Astilbe vivo blanco' },
    { Clave: '10221800', Descripcion: 'angélica viva' },
    { Clave: '10221801', Descripcion: 'Angélica viva gigas' },
    { Clave: '10221802', Descripcion: 'Angélica viva sylvestris' },
    { Clave: '10221900', Descripcion: 'ajenjo vivo' },
    { Clave: '10221901', Descripcion: 'Ajenjo vivo verde' },
    { Clave: '10221902', Descripcion: 'Ajenjo vivo rey plata' },
    { Clave: '10222000', Descripcion: 'Flor de alcachofa viva' },
    { Clave: '10222001', Descripcion: 'Flor de alcachofa viva chocolate' },
    { Clave: '10222002', Descripcion: 'Flor de alcachofa viva verde' },
    { Clave: '10222003', Descripcion: 'Flor de alcachofa viva púrpura o floreciente' },
    { Clave: '10222100', Descripcion: 'Astrantia viva' },
    { Clave: '10222101', Descripcion: 'Astrantia viva rosada' },
    { Clave: '10222102', Descripcion: 'Astrantia viva blanca' },
    { Clave: '10222200', Descripcion: 'Flor de banano viva' },
    { Clave: '10222201', Descripcion: 'Flor de banano viva anaranjada' },
    { Clave: '10222202', Descripcion: 'Flor de banano viva antorcha anaranjada' },
    { Clave: '10222203', Descripcion: 'Flor de banano viva púrpura' },
    { Clave: '10222300', Descripcion: 'Baptisia viva' },
    { Clave: '10222301', Descripcion: 'Baptisia viva australis' },
    { Clave: '10222302', Descripcion: 'Baptisia viva sphaerocarpa' },
    { Clave: '10222400', Descripcion: 'Boronia viva' },
    { Clave: '10222401', Descripcion: 'Boronia viva rosada' },
    { Clave: '10222402', Descripcion: 'Boronia viva amarilla' },
    { Clave: '10222500', Descripcion: 'Bromelia viva' },
    { Clave: '10222501', Descripcion: 'Bromelia viva amarilla reg' },
    { Clave: '10222502', Descripcion: 'Bromelia viva roja reg' },
    { Clave: '10222600', Descripcion: 'Brunia viva' },
    { Clave: '10222601', Descripcion: 'Brunia viva albiflora' },
    { Clave: '10222602', Descripcion: 'Brunia viva albiflora verde' },
    { Clave: '10222603', Descripcion: 'Brunia viva rocío de plata' },
    { Clave: '10222700', Descripcion: 'Catalea viva' },
    { Clave: '10222701', Descripcion: 'Calatea viva cigarro' },
    { Clave: '10222702', Descripcion: 'Calatea hielo verde' },
    { Clave: '10222703', Descripcion: 'Calatea serpiente cascabel' },
    { Clave: '10222800', Descripcion: 'Calcynia viva' },
    { Clave: '10222801', Descripcion: 'Calcynia viva rosada' },
    { Clave: '10222802', Descripcion: 'Calcynia viva princesa' },
    { Clave: '10222803', Descripcion: 'Calcynia viva blanca' },
    { Clave: '10222900', Descripcion: 'Caléndula viva' },
    { Clave: '10222901', Descripcion: 'Caléndula viva anaranjada' },
    { Clave: '10222902', Descripcion: 'Caléndula viva amarilla' },
    { Clave: '10223000', Descripcion: 'Campanilla viva' },
    { Clave: '10223001', Descripcion: 'Campanilla viva azul' },
    { Clave: '10223002', Descripcion: 'Campanilla viva rosada' },
    { Clave: '10223003', Descripcion: 'Campanilla viva blanca' },
    { Clave: '10223100', Descripcion: 'Cestrum vivo' },
    { Clave: '10223101', Descripcion: 'Cestrum vivo rojo' },
    { Clave: '10223102', Descripcion: 'Cestrum vivo rojo zohar' },
    { Clave: '10223103', Descripcion: 'Cestrum vivo amarillo' },
    { Clave: '10223200', Descripcion: 'Chasmante viva' },
    { Clave: '10223201', Descripcion: 'Chasmante floribunda viva amarilla' },
    { Clave: '10223202', Descripcion: 'Chasmante floribunda viva anaranjada' },
    { Clave: '10223300', Descripcion: 'Costus viva' },
    { Clave: '10223301', Descripcion: 'Costus barbada viva' },
    { Clave: '10223302', Descripcion: 'Costus viva cabeza de indio' },
    { Clave: '10223400', Descripcion: 'Vara de San José viva' },
    { Clave: '10223401', Descripcion: 'Vara de san josé lucifer viva' },
    { Clave: '10223402', Descripcion: 'Vara de san josé viva vainas' },
    { Clave: '10223403', Descripcion: 'Vara de san josé viva amarilla' },
    { Clave: '10223500', Descripcion: 'Lirio del Fuego vivo' },
    { Clave: '10223501', Descripcion: 'Lirio del fuego vivo anaranjado brillante' },
    { Clave: '10223502', Descripcion: 'Lirio del fuego vivo crema' },
    { Clave: '10223503', Descripcion: 'Lirio del fuego vivo anaranjado' },
    { Clave: '10223504', Descripcion: 'Lirio del fuego vivo amarillo' },
    { Clave: '10223600', Descripcion: 'Flor del muelle viva' },
    { Clave: '10223601', Descripcion: 'Flor del muelle viva verde' },
    { Clave: '10223602', Descripcion: 'Flor del muelle viva roja' },
    { Clave: '10223700', Descripcion: 'Planta erizo viva' },
    { Clave: '10223701', Descripcion: 'Planta erizo viva tintura negra' },
    { Clave: '10223702', Descripcion: 'Planta erizo viva tintura anaranjada' },
    { Clave: '10223800', Descripcion: 'Manzanilla viva' },
    { Clave: '10223801', Descripcion: 'Manzanilla viva sencilla vegmo' },
    { Clave: '10223802', Descripcion: 'Manzanilla viva doble blanca' },
    { Clave: '10223803', Descripcion: 'Manzanilla viva bola de nieve' },
    { Clave: '10223804', Descripcion: 'Manzanilla viva blanca' },
    { Clave: '10223900', Descripcion: 'Nomeolvides viva' },
    { Clave: '10223901', Descripcion: 'Nomeolvides viva azul' },
    { Clave: '10223902', Descripcion: 'Nomeolvides viva blanca' },
    { Clave: '10224000', Descripcion: 'Gallardia viva' },
    { Clave: '10224001', Descripcion: 'Gallardia viva anaranjada' },
    { Clave: '10224002', Descripcion: 'Gallardia viva amarilla' },
    { Clave: '10224100', Descripcion: 'Genciana viva' },
    { Clave: '10224101', Descripcion: 'Genciana viva azul' },
    { Clave: '10224102', Descripcion: 'Genciana viva blanca' },
    { Clave: '10224200', Descripcion: 'Gladiolo vivo' },
    { Clave: '10224201', Descripcion: 'Gladiolo vivo glamini rosado' },
    { Clave: '10224202', Descripcion: 'Gladiolo vivo glamini rojo' },
    { Clave: '10224300', Descripcion: 'Gloriosa viva' },
    { Clave: '10224301', Descripcion: 'Gloriosa viva anaranjada' },
    { Clave: '10224302', Descripcion: 'Gloriosa viva roja' },
    { Clave: '10224303', Descripcion: 'Gloriosa viva amarilla' },
    { Clave: '10224400', Descripcion: 'Violeta silvestre viva' },
    { Clave: '10224401', Descripcion: 'Violeta silvestre viva anaranjada' },
    { Clave: '10224402', Descripcion: 'Violeta silvestre viva roja' },
    { Clave: '10224500', Descripcion: 'Eléboro vivo' },
    { Clave: '10224501', Descripcion: 'Eléboro vivo verde' },
    { Clave: '10224502', Descripcion: 'Eléboro vivo rayo de luna' },
    { Clave: '10224600', Descripcion: 'Ixia viva' },
    { Clave: '10224601', Descripcion: 'Ixia viva rosada' },
    { Clave: '10224602', Descripcion: 'Ixia viva blanca' },
    { Clave: '10224700', Descripcion: 'Liatrises vivas' },
    { Clave: '10224701', Descripcion: 'Liatris viva púrpura' },
    { Clave: '10224702', Descripcion: 'Liatris viva spray' },
    { Clave: '10224703', Descripcion: 'Liatris viva blanca' },
    { Clave: '10224800', Descripcion: 'Lysimachia viva' },
    { Clave: '10224801', Descripcion: 'Lysimachia punctata viva' },
    { Clave: '10224802', Descripcion: 'Lysimachia vulgaris viva' },
    { Clave: '10224803', Descripcion: 'Lysimachia blanca viva' },
    { Clave: '10224804', Descripcion: 'Lysimachia amarilla viva' },
    { Clave: '10224900', Descripcion: 'Maraca viva' },
    { Clave: '10224901', Descripcion: 'Maraca viva marrón' },
    { Clave: '10224902', Descripcion: 'Maraca viva shampoo ginger' },
    { Clave: '10225000', Descripcion: 'Caléndula viva' },
    { Clave: '10225001', Descripcion: 'Caléndula viva francesa' },
    { Clave: '10225002', Descripcion: 'Caléndula viva verde' },
    { Clave: '10225003', Descripcion: 'Caléndula (marigold) viva anaranjada' },
    { Clave: '10225004', Descripcion: 'Caléndula (marigold) viva amarilla' },
    { Clave: '10225100', Descripcion: 'Mimosa viva' },
    { Clave: '10225101', Descripcion: 'Mimosa viva azul o púrpura' },
    { Clave: '10225102', Descripcion: 'Mimosa dedo viva' },
    { Clave: '10225103', Descripcion: 'Mimosa viva floribunda o italia' },
    { Clave: '10225104', Descripcion: 'Mimosa viva mirandola' },
    { Clave: '10225200', Descripcion: 'Nerina viva' },
    { Clave: '10225201', Descripcion: 'Nerina viva rosada' },
    { Clave: '10225202', Descripcion: 'Nerina samiensis blanca' },
    { Clave: '10225300', Descripcion: 'Flor de nogal viva' },
    { Clave: '10225301', Descripcion: 'Flor colgante de nogal blanco viva' },
    { Clave: '10225302', Descripcion: 'Flor sin hojas de nogal blanco viva' },
    { Clave: '10225303', Descripcion: 'Flor erecta de nogal blanco brasileño viva' },
    { Clave: '10225400', Descripcion: 'Phlox viva' },
    { Clave: '10225401', Descripcion: 'Phlox viva rosado oscur' },
    { Clave: '10225402', Descripcion: 'Phlox viva lavanda' },
    { Clave: '10225403', Descripcion: 'Phlox viva rosado claro' },
    { Clave: '10225404', Descripcion: 'Phlox viva blanca' },
    { Clave: '10225500', Descripcion: 'physostegia viva' },
    { Clave: '10225501', Descripcion: 'Physostegia viva rosada' },
    { Clave: '10225502', Descripcion: 'Physostegia viva vainas' },
    { Clave: '10225503', Descripcion: 'Physostegia viva blanca' },
    { Clave: '10225600', Descripcion: 'Saponaria viva' },
    { Clave: '10225601', Descripcion: 'Saponaria viva rosada' },
    { Clave: '10225602', Descripcion: 'Saponaria viva blanca' },
    { Clave: '10225700', Descripcion: 'Sarracenias vivas' },
    { Clave: '10225701', Descripcion: 'Sarracenia viva flava rugelii' },
    { Clave: '10225800', Descripcion: 'Sicilia viva' },
    { Clave: '10225801', Descripcion: 'Sicilia viva campanulata azul' },
    { Clave: '10225802', Descripcion: 'Sicilia viva campanulata rosada' },
    { Clave: '10225803', Descripcion: 'Sicilia viva campanulata blanca' },
    { Clave: '10225804', Descripcion: 'Sicilia viva peruana' },
    { Clave: '10225900', Descripcion: 'Sedum viva' },
    { Clave: '10225901', Descripcion: 'Sedum viva marrón' },
    { Clave: '10225902', Descripcion: 'Sedum viva verde' },
    { Clave: '10225903', Descripcion: 'Sedum viva rosada' },
    { Clave: '10225904', Descripcion: 'Sedum viva roja' },
    { Clave: '10226000', Descripcion: 'Especies individuales o variedades de flores vivas' },
    { Clave: '10226001', Descripcion: 'Agrsotema viva' },
    { Clave: '10226002', Descripcion: 'Kniphophia o assegai poker viva' },
    { Clave: '10226003', Descripcion: 'Bellis perennis viva' },
    { Clave: '10226004', Descripcion: 'Campana de irlanda o molucella viva' },
    { Clave: '10226005', Descripcion: 'Ave del paraíso viva' },
    { Clave: '10226006', Descripcion: 'Novia sonrojada viva' },
    { Clave: '10226007', Descripcion: 'Buddleia o arbusto mariposa viva' },
    { Clave: '10226008', Descripcion: 'Bupleurum griffithii viva' },
    { Clave: '10226009', Descripcion: 'California ginesta viva' },
    { Clave: '10226010', Descripcion: 'Callicarpa púrpura viva' },
    { Clave: '10226011', Descripcion: 'Campanilla blanca viva' },
    { Clave: '10226012', Descripcion: 'Penacho dulce viva' },
    { Clave: '10226013', Descripcion: 'Cariopteris viva' },
    { Clave: '10226014', Descripcion: 'Centaurea o marco polo viva' },
    { Clave: '10226015', Descripcion: 'Linterna china viva' },
    { Clave: '10226016', Descripcion: 'Clematis recta purpurea viva' },
    { Clave: '10226017', Descripcion: 'Cleome spinosa viva' },
    { Clave: '10226018', Descripcion: 'Coreopsis viva' },
    { Clave: '10226019', Descripcion: 'Farolitos de la virgen azul viva' },
    { Clave: '10226020', Descripcion: 'Cosmos chocolate viva' },
    { Clave: '10226021', Descripcion: 'Cotinus coggygria viva' },
    { Clave: '10226022', Descripcion: 'Craspedia o billy bolas viva' },
    { Clave: '10226023', Descripcion: 'Deutzia alta viva' },
    { Clave: '10226024', Descripcion: 'Diosma viva' },
    { Clave: '10226025', Descripcion: 'Echeveria suculenta chupahuevos viva' },
    { Clave: '10226026', Descripcion: 'Echinacea purpurea viva' },
    { Clave: '10226027', Descripcion: 'Edelweiss viva' },
    { Clave: '10226028', Descripcion: 'Erythronium pagoda viva' },
    { Clave: '10226029', Descripcion: 'Flor de eucalipto viva' },
    { Clave: '10226030', Descripcion: 'Eucaris o lirio del amazonas vivo' },
    { Clave: '10226031', Descripcion: 'Eucomis o lirio de piña vivo' },
    { Clave: '10226032', Descripcion: 'Eupatorium maculatum vivo' },
    { Clave: '10226033', Descripcion: 'Filipendula viva' },
    { Clave: '10226034', Descripcion: 'Digitalis vivo' },
    { Clave: '10226035', Descripcion: 'Gilia globo viva' },
    { Clave: '10226036', Descripcion: 'Globularia de ojo azul viva' },
    { Clave: '10226037', Descripcion: 'Washington hawthorne viva' },
    { Clave: '10226038', Descripcion: 'Helenio viva' },
    { Clave: '10226039', Descripcion: 'Helianto viva' },
    { Clave: '10226040', Descripcion: 'Herperis matronalis viva' },
    { Clave: '10226041', Descripcion: 'Houttuynia cordata chameleon viva' },
    { Clave: '10226042', Descripcion: 'Jacinto con bulbo viva' },
    { Clave: '10226043', Descripcion: 'Maiz indio vivo' },
    { Clave: '10226044', Descripcion: 'Jack en el púlpito vivo' },
    { Clave: '10226045', Descripcion: 'Arbol del cielo japonés vivo' },
    { Clave: '10226046', Descripcion: 'Enredadera de jazmin florecida viva' },
    { Clave: '10226047', Descripcion: 'Jatropha curcas o pólvora viva' },
    { Clave: '10226048', Descripcion: 'Knautia viva' },
    { Clave: '10226049', Descripcion: 'Kochia sedifolia viva' },
    { Clave: '10226050', Descripcion: 'Lachenalia romaud viva' },
    { Clave: '10226051', Descripcion: 'Flor de oreja de oveja viva' },
    { Clave: '10226052', Descripcion: 'Lavanda viva' },
    { Clave: '10226053', Descripcion: 'Leucocoryne speciosa viva' },
    { Clave: '10226054', Descripcion: 'Lythrum viva' },
    { Clave: '10226055', Descripcion: 'Malva zebrina viva' },
    { Clave: '10226056', Descripcion: 'Margarita blanca viva' },
    { Clave: '10226057', Descripcion: 'Montbretia amarilla viva' },
    { Clave: '10226058', Descripcion: 'Nebelia viva' },
    { Clave: '10226059', Descripcion: 'Nicotiana viva verde' },
    { Clave: '10226060', Descripcion: 'Nigella damascena o amor en la niebla viva' },
    { Clave: '10226061', Descripcion: 'Nigella viva' },
    { Clave: '10226062', Descripcion: 'Orquídea de monja viva' },
    { Clave: '10226063', Descripcion: 'Orquídea verde paphiopedilum' },
    { Clave: '10226064', Descripcion: 'Paranomus viva' },
    { Clave: '10226065', Descripcion: 'Penstemon husker rojo vivo' },
    { Clave: '10226066', Descripcion: 'Manzana peruana viva' },
    { Clave: '10226067', Descripcion: 'Phlomis sarnia viva' },
    { Clave: '10226068', Descripcion: 'Didiscus o flor de encaje rosada viva' },
    { Clave: '10226069', Descripcion: 'Platycodon o flor globo viva' },
    { Clave: '10226070', Descripcion: 'Retzia capensis viva' },
    { Clave: '10226071', Descripcion: 'Ricino común vivo' },
    { Clave: '10226072', Descripcion: 'Nieve en el monte viva' },
    { Clave: '10226073', Descripcion: 'Solidago teñida viva' },
    { Clave: '10226074', Descripcion: 'Scilla blanca viva' },
    { Clave: '10226075', Descripcion: 'Stachys byzantina viva' },
    { Clave: '10226076', Descripcion: 'Flor de paja viva' },
    { Clave: '10226077', Descripcion: 'Oscularia suculenta viva' },
    { Clave: '10226078', Descripcion: 'Flor de tillasandia viva' },
    { Clave: '10226079', Descripcion: 'Triteleia viva' },
    { Clave: '10226080', Descripcion: 'Tritoma naranja o chuzo caliente viva roja' },
    { Clave: '10226081', Descripcion: 'Veronicastrum virginiana viva' },
    { Clave: '10226082', Descripcion: 'Bromelia vriesea splendens viva' },
    { Clave: '10226084', Descripcion: 'Hipericim o hierba de san juan viva' },
    { Clave: '10226085', Descripcion: 'Spirea viva' },
    { Clave: '10226086', Descripcion: 'Yerba de san bonifacio viva' },
    { Clave: '10226100', Descripcion: 'Sello de salomón (polygonato) viva' },
    { Clave: '10226101', Descripcion: 'Sello de salomón (polygonato) falso viva' },
    { Clave: '10226102', Descripcion: 'Sello de salomón (polygonato) variegado viva' },
    { Clave: '10226200', Descripcion: 'Tanaceto vivo' },
    { Clave: '10226201', Descripcion: 'Tanaceto vivo amazonas' },
    { Clave: '10226202', Descripcion: 'Tanaceto vivo victoria doble blanco' },
    { Clave: '10226203', Descripcion: 'Tanaceto vivo victoria sencillo blanco' },
    { Clave: '10226204', Descripcion: 'Tanaceto vivo vegmo amarillo' },
    { Clave: '10226300', Descripcion: 'Traquelio vivo' },
    { Clave: '10226301', Descripcion: 'Trachelium jade viva' },
    { Clave: '10226302', Descripcion: 'Trachelium púrpura viva' },
    { Clave: '10226303', Descripcion: 'Trachelium blanca viva' },
    { Clave: '10226400', Descripcion: 'Tuberosas vivas' },
    { Clave: '10226401', Descripcion: 'Tuberosa viva doble' },
    { Clave: '10226402', Descripcion: 'Tuberosa viva sencilla' },
    { Clave: '10226500', Descripcion: 'Tweedias vivas' },
    { Clave: '10226501', Descripcion: 'Tweedia azul viva' },
    { Clave: '10226502', Descripcion: 'Tweedia blanca viva' },
    { Clave: '10226600', Descripcion: 'Verónicas vivas' },
    { Clave: '10226601', Descripcion: 'Verónica viva rosada' },
    { Clave: '10226602', Descripcion: 'Verónica viva púrpura' },
    { Clave: '10226603', Descripcion: 'Verónica viva blanca' },
    { Clave: '10226700', Descripcion: 'Watsonias vivas' },
    { Clave: '10226701', Descripcion: 'Watsonia viva anaranjada' },
    { Clave: '10226702', Descripcion: 'Watsonia viva rosada' },
    { Clave: '10226703', Descripcion: 'Watsonia viva roja' },
    { Clave: '10226704', Descripcion: 'Watsonia viva blanca' },
    { Clave: '10231500', Descripcion: 'Crisantemo vivo pompón' },
    { Clave: '10231501', Descripcion: 'Crisantemo vivo pompón delirock' },
    { Clave: '10231502', Descripcion: 'Crisantemo vivo pompón discovery' },
    { Clave: '10231503', Descripcion: 'Crisantemo vivo pompón focus' },
    { Clave: '10231504', Descripcion: 'Crisantemo vivo pompón jeanne' },
    { Clave: '10231505', Descripcion: 'Crisantemo vivo pompón lady' },
    { Clave: '10231506', Descripcion: 'Crisantemo vivo pompón leidi' },
    { Clave: '10231507', Descripcion: 'Crisantemo vivo pompón lexy' },
    { Clave: '10231508', Descripcion: 'Crisantemo vivo pompón ole' },
    { Clave: '10231509', Descripcion: 'Crisantemo vivo pompón revise' },
    { Clave: '10231510', Descripcion: 'Crisantemo vivo pompón estadista' },
    { Clave: '10231511', Descripcion: 'Crisantemo vivo pompón dulce' },
    { Clave: '10231512', Descripcion: 'Crisantemo vivo pompón yoko ono' },
    { Clave: '10231513', Descripcion: 'Crisantemo vivo pompón zip' },
    { Clave: '10231600', Descripcion: 'Crisantemo vivo pompón margarita' },
    { Clave: '10231601', Descripcion: 'Crisantemo vivo pompón artista rosado' },
    { Clave: '10231602', Descripcion: 'Crisantemo vivo pompón artista amarillo' },
    { Clave: '10231603', Descripcion: 'Crisantemo vivo pompón atlantis rosado' },
    { Clave: '10231604', Descripcion: 'Crisantemo vivo pompón atlantis blanco' },
    { Clave: '10231605', Descripcion: 'Crisantemo vivo pompón atlantis amarillo' },
    { Clave: '10231606', Descripcion: 'Crisantemo vivo pompón bennie jolink' },
    { Clave: '10231607', Descripcion: 'Crisantemo vivo pompón bennie jolink amarillo' },
    { Clave: '10231608', Descripcion: 'Crisantemo vivo pompón managua bronce' },
    { Clave: '10231609', Descripcion: 'Crisantemo vivo pompón clave' },
    { Clave: '10231610', Descripcion: 'Crisantemo vivo pompón ficción coral' },
    { Clave: '10231611', Descripcion: 'Crisantemo vivo pompón cumbia' },
    { Clave: '10231612', Descripcion: 'Crisantemo vivo pompón cantata oscura' },
    { Clave: '10231613', Descripcion: 'Crisantemo vivo pompón lineker oscuro' },
    { Clave: '10231614', Descripcion: 'Crisantemo vivo pompón dipper' },
    { Clave: '10231615', Descripcion: 'Crisantemo vivo pompón rosado elite' },
    { Clave: '10231616', Descripcion: 'Crisantemo vivo pompón blanco elite' },
    { Clave: '10231617', Descripcion: 'Crisantemo vivo pompón amarillo elite' },
    { Clave: '10231618', Descripcion: 'Crisantemo vivo pompón factor' },
    { Clave: '10231619', Descripcion: 'Crisantemo vivo pompón ficción' },
    { Clave: '10231620', Descripcion: 'Crisantemo vivo pompón fuerza' },
    { Clave: '10231621', Descripcion: 'Crisantemo vivo pompón reagan mejorado' },
    { Clave: '10231622', Descripcion: 'Crisantemo vivo pompón life' },
    { Clave: '10231623', Descripcion: 'Crisantemo vivo pompón managua anaranjado' },
    { Clave: '10231624', Descripcion: 'Crisantemo vivo pompón novedsd bronce cocarde' },
    { Clave: '10231625', Descripcion: 'Crisantemo vivo pompón reagan anaranjado' },
    { Clave: '10231626', Descripcion: 'Crisantemo vivo pompón orinoco' },
    { Clave: '10231627', Descripcion: 'Crisantemo vivo pompón petra' },
    { Clave: '10231628', Descripcion: 'Crisantemo vivo pompón balsas rosado' },
    { Clave: '10231629', Descripcion: 'Crisantemo vivo pompón mona lisa rosado' },
    { Clave: '10231630', Descripcion: 'Crisantemo vivo pompón reagan rosado' },
    { Clave: '10231631', Descripcion: 'Crisantemo vivo pompón marfil reagan' },
    { Clave: '10231632', Descripcion: 'Crisantemo vivo pompón reagan rosado' },
    { Clave: '10231633', Descripcion: 'Crisantemo vivo pompón rebasco' },
    { Clave: '10231634', Descripcion: 'Crisantemo vivo pompón redock' },
    { Clave: '10231635', Descripcion: 'Crisantemo vivo pompón salmón lineker' },
    { Clave: '10231636', Descripcion: 'Crisantemo vivo pompón sheba' },
    { Clave: '10231637', Descripcion: 'Crisantemo vivo pompón sirius' },
    { Clave: '10231638', Descripcion: 'Crisantemo vivo pompón reagan espléndido' },
    { Clave: '10231639', Descripcion: 'Crisantemo vivo pompón reagan soleado' },
    { Clave: '10231640', Descripcion: 'Crisantemo vivo pompón tina' },
    { Clave: '10231641', Descripcion: 'Crisantemo vivo pompón vero' },
    { Clave: '10231642', Descripcion: 'Crisantemo vivo pompón volare' },
    { Clave: '10231643', Descripcion: 'Crisantemo vivo pompón vida blanca' },
    { Clave: '10231644', Descripcion: 'Crisantemo vivo pompón regan blanco' },
    { Clave: '10231645', Descripcion: 'Crisantemo vivo pompón rino blanco' },
    { Clave: '10231646', Descripcion: 'Crisantemo vivo pompón vero amarillo' },
    { Clave: '10231647', Descripcion: 'Crisantemo vivo pompón zenith' },
    { Clave: '10231700', Descripcion: 'Crisantemo vivo espollado cremon' },
    { Clave: '10231701', Descripcion: 'Crisantemo vivo espollado cremon annecy oscura' },
    { Clave: '10231702', Descripcion: 'Crisantemo vivo espollado cremon' },
    { Clave: '10231703', Descripcion: 'Crisantemo vivo espollado cremon atlantis rosado' },
    { Clave: '10231704', Descripcion: 'Crisantemo vivo espollado cremon eleonora bronce' },
    { Clave: '10231705', Descripcion: 'Crisantemo vivo espollado cremon eleonora lila' },
    { Clave: '10231706', Descripcion: 'Crisantemo vivo espollado cremon eleonora rosado' },
    { Clave: '10231707', Descripcion: 'Crisantemo vivo espollado cremon eleonora nieve' },
    { Clave: '10231708', Descripcion: 'Crisantemo vivo espollado cremon eleonora amarillo' },
    { Clave: '10231709', Descripcion: 'Crisantemo vivo espollado cremon idea' },
    { Clave: '10231710', Descripcion: 'Crisantemo vivo espollado cremon ivanna púrpura' },
    { Clave: '10231711', Descripcion: 'Crisantemo vivo espollado cremon minka rosado' },
    { Clave: '10231712', Descripcion: 'Crisantemo vivo espollado cremon listo' },
    { Clave: '10231713', Descripcion: 'Crisantemo vivo espollado cremon sinatra' },
    { Clave: '10231714', Descripcion: 'Crisantemo vivo rover rojo' },
    { Clave: '10231800', Descripcion: 'Crisantemo vivo espollado football' },
    { Clave: '10231801', Descripcion: 'Crisantemo vivo espollado blaze' },
    { Clave: '10231802', Descripcion: 'Crisantemo vivo espollado beso de football' },
    { Clave: '10231803', Descripcion: 'Crisantemo vivo espollado football lavanda / rosado' },
    { Clave: '10231804', Descripcion: 'Crisantemo vivo espollado football resouci' },
    { Clave: '10231805', Descripcion: 'Crisantemo vivo espollado footbal blanco' },
    { Clave: '10231806', Descripcion: 'Crisantemo vivo espollado football amarillo' },
    { Clave: '10231807', Descripcion: 'Crisantemo vivo espollado promenade' },
    { Clave: '10231808', Descripcion: 'Crisantemo vivo espollado rebonnet' },
    { Clave: '10231809', Descripcion: 'Crisantemo vivo espollado reflex' },
    { Clave: '10231810', Descripcion: 'Crisantemo vivo espollado residence' },
    { Clave: '10231811', Descripcion: 'Crisantemo vivo espollado resomee perla' },
    { Clave: '10231812', Descripcion: 'Crisantemo vivo espollado resouci' },
    { Clave: '10231900', Descripcion: 'Crisantemo spider vivo' },
    { Clave: '10231901', Descripcion: 'Crisantemo spider vivo anastasia bronce' },
    { Clave: '10231902', Descripcion: 'Crisantemo spider vivo anastasia bronce oscuro' },
    { Clave: '10231903', Descripcion: 'Crisantemo spider vivo anastasia verde' },
    { Clave: '10231904', Descripcion: 'Crisantemo spider vivo anastasia lila' },
    { Clave: '10231905', Descripcion: 'Crisantemo spider vivo anastasia rosado' },
    { Clave: '10231906', Descripcion: 'Crisantemo spider vivo anastasia púrpura' },
    { Clave: '10231907', Descripcion: 'Crisantemo spider vivo anastasia soleado' },
    { Clave: '10231908', Descripcion: 'Crisantemo spider vivo anastasia blanco' },
    { Clave: '10231909', Descripcion: 'Crisantemo spider vivo bradford' },
    { Clave: '10231910', Descripcion: 'Crisantemo spider vivo delistar blanco' },
    { Clave: '10231911', Descripcion: 'Crisantemo spider vivo delistar amarillo' },
    { Clave: '10231912', Descripcion: 'Crisantemo spider vivo minka' },
    { Clave: '10231913', Descripcion: 'Crisantemo spider vivo natasha soleado' },
    { Clave: '10231914', Descripcion: 'Crisantemo spider vivo pirouette' },
    { Clave: '10231915', Descripcion: 'Crisantemo spider vivo reflejo' },
    { Clave: '10231916', Descripcion: 'Crisantemo spider vivo regata' },
    { Clave: '10231917', Descripcion: 'Crisantemo spider vivo render' },
    { Clave: '10231918', Descripcion: 'Crisantemo spider vivo repertorio' },
    { Clave: '10231919', Descripcion: 'Crisantemo spider vivo resolute' },
    { Clave: '10231920', Descripcion: 'Crisantemo spider vivo resomac' },
    { Clave: '10231921', Descripcion: 'Crisantemo spider vivo trébol' },
    { Clave: '10231922', Descripcion: 'Crisantemo spider vivo talante bronce' },
    { Clave: '10231923', Descripcion: 'Crisantemo spider vivo super blanco' },
    { Clave: '10231924', Descripcion: 'Crisantemo spider vivo super amarillo' },
    { Clave: '10231925', Descripcion: 'Crisantemo spider vivo tierno' },
    { Clave: '10231926', Descripcion: 'Crisantemo spider vivo zembia' },
    { Clave: '10232000', Descripcion: 'Crisantemo vivo  novelty' },
    { Clave: '10232001', Descripcion: 'Crisantemo vivo pompón anecy rosado' },
    { Clave: '10232002', Descripcion: 'Crisantemo vivo pompón ardilo royal' },
    { Clave: '10232003', Descripcion: 'Crisantemo vivo pompón athos' },
    { Clave: '10232004', Descripcion: 'Crisantemo vivo pompón biarritz' },
    { Clave: '10232005', Descripcion: 'Crisantemo vivo pompón bradford anaranjado' },
    { Clave: '10232006', Descripcion: 'Crisantemo vivo pompón bradford' },
    { Clave: '10232007', Descripcion: 'Crisantemo vivo pompón vela' },
    { Clave: '10232008', Descripcion: 'Crisantemo vivo pompón candor' },
    { Clave: '10232009', Descripcion: 'Crisantemo vivo pompón gallardía' },
    { Clave: '10232010', Descripcion: 'Crisantemo vivo pompón décima' },
    { Clave: '10232012', Descripcion: 'Crisantemo vivo pompón delisun' },
    { Clave: '10232013', Descripcion: 'Crisantemo vivo pompón dion' },
    { Clave: '10232014', Descripcion: 'Crisantemo vivo pompón dorena' },
    { Clave: '10232015', Descripcion: 'Crisantemo vivo pompón dublín' },
    { Clave: '10232016', Descripcion: 'Crisantemo vivo pompón everglades' },
    { Clave: '10232017', Descripcion: 'Crisantemo vivo pompón buenmozo' },
    { Clave: '10232018', Descripcion: 'Crisantemo vivo pompón hasting' },
    { Clave: '10232019', Descripcion: 'Crisantemo vivo pompón high five' },
    { Clave: '10232020', Descripcion: 'Crisantemo vivo pompón mundial mejorado' },
    { Clave: '10232021', Descripcion: 'Crisantemo vivo pompón juanes' },
    { Clave: '10232022', Descripcion: 'Crisantemo vivo pompón kiato verde' },
    { Clave: '10232023', Descripcion: 'Crisantemo vivo pompón kiato' },
    { Clave: '10232024', Descripcion: 'Crisantemo vivo pompón kiwi' },
    { Clave: '10232025', Descripcion: 'Crisantemo vivo pompón madeira' },
    { Clave: '10232026', Descripcion: 'Crisantemo vivo pompón magneto' },
    { Clave: '10232027', Descripcion: 'Crisantemo vivo pompón marimo' },
    { Clave: '10232028', Descripcion: 'Crisantemo vivo pompón matrix' },
    { Clave: '10232029', Descripcion: 'Crisantemo vivo pompón miletta' },
    { Clave: '10232030', Descripcion: 'Crisantemo vivo pompón monalisa' },
    { Clave: '10232031', Descripcion: 'Crisantemo vivo pompón omaha' },
    { Clave: '10232032', Descripcion: 'Crisantemo vivo pompón orinoco púrpura' },
    { Clave: '10232033', Descripcion: 'Crisantemo vivo pompón orinoco amarillo' },
    { Clave: '10232034', Descripcion: 'Crisantemo vivo pompón verde pacífico' },
    { Clave: '10232035', Descripcion: 'Crisantemo vivo pompón blanco puma' },
    { Clave: '10232036', Descripcion: 'Crisantemo vivo pompón amarillo puma' },
    { Clave: '10232037', Descripcion: 'Crisantemo vivo pompón mundial púrpura' },
    { Clave: '10232038', Descripcion: 'Crisantemo vivo pompón regata' },
    { Clave: '10232039', Descripcion: 'Crisantemo vivo pompón remco' },
    { Clave: '10232040', Descripcion: 'Crisantemo vivo pompón royal mundial' },
    { Clave: '10232041', Descripcion: 'Crisantemo vivo pompón sabrina' },
    { Clave: '10232042', Descripcion: 'Crisantemo vivo pompón shakira blanco' },
    { Clave: '10232043', Descripcion: 'Crisantemo vivo pompón sharp' },
    { Clave: '10232044', Descripcion: 'Crisantemo vivo pompón shock' },
    { Clave: '10232045', Descripcion: 'Crisantemo vivo pompón sizzle verde' },
    { Clave: '10232046', Descripcion: 'Crisantemo vivo pompón sizzle rosado' },
    { Clave: '10232047', Descripcion: 'Crisantemo vivo pompón sizzle' },
    { Clave: '10232048', Descripcion: 'Crisantemo vivo pompón sizzle púrpura' },
    { Clave: '10232049', Descripcion: 'Crisantemo vivo pompón sizzle salmón' },
    { Clave: '10232050', Descripcion: 'Crisantemo vivo pompón sizzle amarillo' },
    { Clave: '10232051', Descripcion: 'Crisantemo vivo pompón bandera española' },
    { Clave: '10232052', Descripcion: 'Crisantemo vivo pompón starbust o copo de nieve' },
    { Clave: '10232053', Descripcion: 'Crisantemo vivo pompón cisne' },
    { Clave: '10232054', Descripcion: 'Crisantemo vivo pompón tedcha anaranjado' },
    { Clave: '10232055', Descripcion: 'Crisantemo vivo pompón tierno' },
    { Clave: '10232056', Descripcion: 'Crisantemo vivo pompón oropel' },
    { Clave: '10232057', Descripcion: 'Crisantemo vivo pompón toque' },
    { Clave: '10232058', Descripcion: 'Crisantemo vivo pompón troyes' },
    { Clave: '10232059', Descripcion: 'Crisantemo vivo pompón valesca' },
    { Clave: '10232060', Descripcion: 'Crisantemo vivo pompón anaranjado vikingo' },
    { Clave: '10232061', Descripcion: 'Crisantemo vivo pompón vikingo' },
    { Clave: '10232062', Descripcion: 'Crisantemo vivo pompón reloj' },
    { Clave: '10232063', Descripcion: 'Crisantemo vivo pompón aguja blanco' },
    { Clave: '10232064', Descripcion: 'Crisantemo vivo pompón noche blanca' },
    { Clave: '10232065', Descripcion: 'Crisantemo vivo pompón artista amarillo' },
    { Clave: '10232066', Descripcion: 'Crisantemo vivo pompón ficción amarillo' },
    { Clave: '10232067', Descripcion: 'Crisantemo vivo pompón sharp amarillo' },
    { Clave: '10232100', Descripcion: 'Crisantemo vivo santini' },
    { Clave: '10232101', Descripcion: 'Crisantemo vivo pompón alma' },
    { Clave: '10232102', Descripcion: 'Crisantemo vivo pompón barón' },
    { Clave: '10232103', Descripcion: 'Crisantemo vivo pompón bernardo' },
    { Clave: '10232104', Descripcion: 'Crisantemo vivo pompón bistro' },
    { Clave: '10232105', Descripcion: 'Crisantemo vivo pompón bodega' },
    { Clave: '10232106', Descripcion: 'Crisantemo vivo pompón brisa' },
    { Clave: '10232107', Descripcion: 'Crisantemo vivo pompón centella bronce' },
    { Clave: '10232108', Descripcion: 'Crisantemo vivo pompón costa blanca' },
    { Clave: '10232109', Descripcion: 'Crisantemo vivo pompón creta' },
    { Clave: '10232110', Descripcion: 'Crisantemo vivo pompón deliflame' },
    { Clave: '10232111', Descripcion: 'Crisantemo vivo pompón dalila' },
    { Clave: '10232112', Descripcion: 'Crisantemo vivo pompón dígito' },
    { Clave: '10232113', Descripcion: 'Crisantemo vivo pompón evilio' },
    { Clave: '10232114', Descripcion: 'Crisantemo vivo pompón furense' },
    { Clave: '10232115', Descripcion: 'Crisantemo vivo pompón guía' },
    { Clave: '10232116', Descripcion: 'Crisantemo vivo pompón kerry' },
    { Clave: '10232117', Descripcion: 'Crisantemo vivo pompón kess' },
    { Clave: '10232118', Descripcion: 'Crisantemo vivo pompón lima' },
    { Clave: '10232119', Descripcion: 'Crisantemo vivo pompón lupo' },
    { Clave: '10232120', Descripcion: 'Crisantemo vivo pompón lineker anaranjado' },
    { Clave: '10232121', Descripcion: 'Crisantemo vivo pompón panuco rojo' },
    { Clave: '10232122', Descripcion: 'Crisantemo vivo pompón costa rosado' },
    { Clave: '10232123', Descripcion: 'Crisantemo vivo pompón rafael' },
    { Clave: '10232124', Descripcion: 'Crisantemo vivo pompón refine' },
    { Clave: '10232125', Descripcion: 'Crisantemo vivo pompón regaliz' },
    { Clave: '10232126', Descripcion: 'Crisantemo vivo pompón renella' },
    { Clave: '10232127', Descripcion: 'Crisantemo vivo pompón retorno' },
    { Clave: '10232128', Descripcion: 'Crisantemo vivo pompón río' },
    { Clave: '10232129', Descripcion: 'Crisantemo vivo pompón sabas' },
    { Clave: '10232130', Descripcion: 'Crisantemo vivo pompón target' },
    { Clave: '10232131', Descripcion: 'Crisantemo vivo pompón texto' },
    { Clave: '10232132', Descripcion: 'Crisantemo vivo pompón caballo amarillo' },
    { Clave: '10241500', Descripcion: 'Clavel vivo de floración sencilla' },
    { Clave: '10241501', Descripcion: 'Clavel vivo de floración sencilla burgundy bicolor' },
    { Clave: '10241502', Descripcion: 'Clavel vivo de floración sencilla burgundy' },
    { Clave: '10241503', Descripcion: 'Clavel vivo de floración sencilla cinderella' },
    { Clave: '10241504', Descripcion: 'Clavel vivo de floración sencilla crema bicolor' },
    { Clave: '10241505', Descripcion: 'Clavel vivo de floración sencilla crema' },
    { Clave: '10241506', Descripcion: 'Clavel vivo de floración sencilla verde o prado' },
    { Clave: '10241507', Descripcion: 'Clavel vivo de floración sencilla rosado fuerte' },
    { Clave: '10241508', Descripcion: 'Clavel vivo de floración sencilla verde claro' },
    { Clave: '10241509', Descripcion: 'Clavel vivo de floración sencilla rosado claro' },
    { Clave: '10241510', Descripcion: 'Clavel vivo de floración sencilla anaranjado bicolor' },
    { Clave: '10241511', Descripcion: 'Clavel vivo de floración sencilla anaranjado' },
    { Clave: '10241512', Descripcion: 'Clavel vivo de floración sencilla durazno' },
    { Clave: '10241513', Descripcion: 'Clavel vivo de floración sencilla menta bicolor' },
    { Clave: '10241514', Descripcion: 'Clavel vivo de floración sencilla rosado bicolor' },
    { Clave: '10241515', Descripcion: 'Clavel vivo de floración sencilla rosado' },
    { Clave: '10241516', Descripcion: 'Clavel vivo de floración sencilla púrpura bicolor' },
    { Clave: '10241517', Descripcion: 'Clavel vivo de floración sencilla rojo bicolor' },
    { Clave: '10241518', Descripcion: 'Clavel vivo de floración sencilla rojo' },
    { Clave: '10241519', Descripcion: 'Clavel vivo de floración sencilla blanco' },
    { Clave: '10241520', Descripcion: 'Clavel vivo de floración sencilla amarillo' },
    { Clave: '10241600', Descripcion: 'Clavel vivo mini o spray' },
    { Clave: '10241601', Descripcion: 'Clavel vivo mini o spray burgundy' },
    { Clave: '10241602', Descripcion: 'Clavel vivo mini o spray crema' },
    { Clave: '10241603', Descripcion: 'Clavel vivo mini o spray rosado fuerte' },
    { Clave: '10241604', Descripcion: 'Clavel vivo mini o spray lavanda' },
    { Clave: '10241605', Descripcion: 'Clavel vivo mini o spray rosado claro' },
    { Clave: '10241606', Descripcion: 'Clavel vivo mini o spray anaranjado' },
    { Clave: '10241607', Descripcion: 'Clavel vivo mini o spray durazno' },
    { Clave: '10241608', Descripcion: 'Clavel vivo mini o spray menta' },
    { Clave: '10241609', Descripcion: 'Clavel vivo mini o spray rosado' },
    { Clave: '10241610', Descripcion: 'Clavel vivo mini o spray púrpura bicolor' },
    { Clave: '10241611', Descripcion: 'Clavel vivo mini o spray púrpura' },
    { Clave: '10241612', Descripcion: 'Clavel vivo mini o spray rojo' },
    { Clave: '10241613', Descripcion: 'Clavel vivo mini o spray blanco' },
    { Clave: '10241614', Descripcion: 'Clavel vivo mini o spray amarillo' },
    { Clave: '10251500', Descripcion: 'Orquidea cypripedium  o pantuflas de dama viva' },
    { Clave: '10251501', Descripcion: 'Orquidea cypripedium viva verde' },
    { Clave: '10251502', Descripcion: 'Orquidea cypripedium viva francia' },
    { Clave: '10251503', Descripcion: 'Orquidea cypripedium viva púrpura rey arturo' },
    { Clave: '10251504', Descripcion: 'Orquidea paphiopedilum viva verde' },
    { Clave: '10251505', Descripcion: 'Orquidea aranthera maggie vie viva' },
    { Clave: '10251600', Descripcion: 'Orquídea mocara viva' },
    { Clave: '10251601', Descripcion: 'Orquídea mocara viva omyai' },
    { Clave: '10251602', Descripcion: 'Orquídea mocara viva roja' },
    { Clave: '10251603', Descripcion: 'Orquídea mocara viva calipso' },
    { Clave: '10251604', Descripcion: 'Orquídea mocara viva nora' },
    { Clave: '10251605', Descripcion: 'Orquídea mocara viva panee' },
    { Clave: '10251700', Descripcion: 'Orquídea catleya viva' },
    { Clave: '10251701', Descripcion: 'Orquídea cattleya viva blanca' },
    { Clave: '10251702', Descripcion: 'Orquídea cattleya viva r b lavanda' },
    { Clave: '10251800', Descripcion: 'Orquídea disa viva' },
    { Clave: '10251801', Descripcion: 'Orquídea disa viva roja' },
    { Clave: '10251802', Descripcion: 'Orquídea disa viva anaranjada' },
    { Clave: '10251803', Descripcion: 'Orquídea disa viva rosada' },
    { Clave: '10251804', Descripcion: 'Orquídea disa viva anaranjada y amarilla bicolor' },
    { Clave: '10251805', Descripcion: 'Orquídea disa viva durazno y amarilla bicolor' },
    { Clave: '10251806', Descripcion: 'Orquídea disa viva amarilla y roja bicolor' },
    { Clave: '10251900', Descripcion: 'Orquídea arachnis viva' },
    { Clave: '10251901', Descripcion: 'Orquídea arachnis viva james storie roja' },
    { Clave: '10251902', Descripcion: 'Orquídea arachnis viva maggie oei roja' },
    { Clave: '10251903', Descripcion: 'Orquídea arachnis viva maggie oei amarilla' },
    { Clave: '10251904', Descripcion: 'Orquídea arachnis viva maroon maggie' },
    { Clave: '10251905', Descripcion: 'Orquídea arachnis viva merry maggie' },
    { Clave: '10252000', Descripcion: 'Orquídea phalaenopsis viva' },
    { Clave: '10252001', Descripcion: 'Orquídea phalaenopsis viva amabilis' },
    { Clave: '10252002', Descripcion: 'Orquídea phalaenopsis viva amboinensis' },
    { Clave: '10252003', Descripcion: 'Orquídea phalaenopsis viva afrodita' },
    { Clave: '10252004', Descripcion: 'Orquídea phalaenopsis viva appendiculata' },
    { Clave: '10252005', Descripcion: 'Orquídea phalaenopsis viva bastianii' },
    { Clave: '10252006', Descripcion: 'Orquídea phalaenopsis viva bellina' },
    { Clave: '10252007', Descripcion: 'Orquídea phalaenopsis viva borneensis' },
    { Clave: '10252008', Descripcion: 'Orquídea phalaenopsis viva braceana' },
    { Clave: '10252009', Descripcion: 'Orquídea phalaenopsis viva buyssoniana' },
    { Clave: '10252010', Descripcion: 'Orquídea phalaenopsis viva celebensis' },
    { Clave: '10252011', Descripcion: 'Orquídea phalaenopsis viva chibae' },
    { Clave: '10252012', Descripcion: 'Orquídea phalaenopsis viva cochlearis' },
    { Clave: '10252013', Descripcion: 'Orquídea phalaenopsis viva corningiana' },
    { Clave: '10252014', Descripcion: 'Orquídea phalaenopsis viva cornu-cervi' },
    { Clave: '10252015', Descripcion: 'Orquídea phalaenopsis viva deliciosa' },
    { Clave: '10252016', Descripcion: 'Orquídea phalaenopsis viva doweryënsis' },
    { Clave: '10252017', Descripcion: 'Orquídea phalaenopsis viva equestris' },
    { Clave: '10252018', Descripcion: 'Orquídea phalaenopsis viva fasciata' },
    { Clave: '10252019', Descripcion: 'Orquídea phalaenopsis viva fimbriata' },
    { Clave: '10252020', Descripcion: 'Orquídea phalaenopsis viva floresensis' },
    { Clave: '10252021', Descripcion: 'Orquídea phalaenopsis viva fuscata' },
    { Clave: '10252022', Descripcion: 'Orquídea phalaenopsis viva gibbosa' },
    { Clave: '10252023', Descripcion: 'Orquídea phalaenopsis viva hainanensis' },
    { Clave: '10252024', Descripcion: 'Orquídea phalaenopsis viva hieroglyphica' },
    { Clave: '10252025', Descripcion: 'Orquídea phalaenopsis viva honghenensis' },
    { Clave: '10252026', Descripcion: 'Orquídea phalaenopsis viva inscriptiosinensis' },
    { Clave: '10252027', Descripcion: 'Orquídea phalaenopsis viva javanica' },
    { Clave: '10252028', Descripcion: 'Orquídea phalaenopsis viva kunstleri' },
    { Clave: '10252029', Descripcion: 'Orquídea phalaenopsis viva lamelligera' },
    { Clave: '10252030', Descripcion: 'Orquídea phalaenopsis viva lindenii' },
    { Clave: '10252031', Descripcion: 'Orquídea phalaenopsis viva lobbii' },
    { Clave: '10252032', Descripcion: 'Orquídea phalaenopsis viva lowii' },
    { Clave: '10252033', Descripcion: 'Orquídea phalaenopsis viva lueddemanniana' },
    { Clave: '10252034', Descripcion: 'Orquídea phalaenopsis viva mambo' },
    { Clave: '10252035', Descripcion: 'Orquídea phalaenopsis viva luteola' },
    { Clave: '10252036', Descripcion: 'Orquídea phalaenopsis viva maculata' },
    { Clave: '10252037', Descripcion: 'Orquídea phalaenopsis viva malipoensis' },
    { Clave: '10252038', Descripcion: 'Orquídea phalaenopsis viva mannii' },
    { Clave: '10252039', Descripcion: 'Orquídea phalaenopsis viva mariae' },
    { Clave: '10252040', Descripcion: 'Orquídea phalaenopsis viva micholitzii' },
    { Clave: '10252041', Descripcion: 'Orquídea phalaenopsis viva modesta' },
    { Clave: '10252042', Descripcion: 'Orquídea phalaenopsis viva mysorensis' },
    { Clave: '10252043', Descripcion: 'Orquídea phalaenopsis viva pallens' },
    { Clave: '10252044', Descripcion: 'Orquídea phalaenopsis viva pantherina' },
    { Clave: '10252045', Descripcion: 'Orquídea phalaenopsis viva parishii' },
    { Clave: '10252046', Descripcion: 'Orquídea phalaenopsis viva petelotii' },
    { Clave: '10252047', Descripcion: 'Orquídea phalaenopsis viva philippinensis' },
    { Clave: '10252048', Descripcion: 'Orquídea phalaenopsis viva pulcherrima' },
    { Clave: '10252049', Descripcion: 'Orquídea phalaenopsis viva pulchra' },
    { Clave: '10252050', Descripcion: 'Orquídea phalaenopsis viva regnieriana' },
    { Clave: '10252051', Descripcion: 'Orquídea phalaenopsis viva reichenbachiana' },
    { Clave: '10252052', Descripcion: 'Orquídea phalaenopsis viva nivacolor' },
    { Clave: '10252053', Descripcion: 'Orquídea phalaenopsis viva sanderiana' },
    { Clave: '10252054', Descripcion: 'Orquídea phalaenopsis viva schilleriana' },
    { Clave: '10252055', Descripcion: 'Orquídea phalaenopsis viva speciosa' },
    { Clave: '10252056', Descripcion: 'Orquídea phalaenopsis viva stobartiana' },
    { Clave: '10252057', Descripcion: 'Orquídea phalaenopsis viva stuartiana' },
    { Clave: '10252058', Descripcion: 'Orquídea phalaenopsis viva sumatrana' },
    { Clave: '10252059', Descripcion: 'Orquídea phalaenopsis viva taenialis' },
    { Clave: '10252060', Descripcion: 'Orquídea phalaenopsis viva tetraspis' },
    { Clave: '10252061', Descripcion: 'Orquídea phalaenopsis viva venosa' },
    { Clave: '10252062', Descripcion: 'Orquídea phalaenopsis viva violácea' },
    { Clave: '10252063', Descripcion: 'Orquídea phalaenopsis viva viridis' },
    { Clave: '10252064', Descripcion: 'Orquídea phalaenopsis viva wilsonii' },
    { Clave: '10252065', Descripcion: 'Orquídea phalaenopsis viva zebrina' },
    { Clave: '10252067', Descripcion: 'Orquídea phalaenopsis viva labio lavanda' },
    { Clave: '10252100', Descripcion: 'Orquídea dendrobium viva' },
    { Clave: '10252101', Descripcion: 'Orquídea dendrobium viva bom' },
    { Clave: '10252102', Descripcion: 'Orquídea dendrobium viva burana' },
    { Clave: '10252103', Descripcion: 'Orquídea dendrobium viva chita' },
    { Clave: '10252104', Descripcion: 'Orquídea dendrobium viva fátima' },
    { Clave: '10252105', Descripcion: 'Orquídea dendrobium viva intuwong' },
    { Clave: '10252106', Descripcion: 'Orquídea dendrobium viva jumbo blanca' },
    { Clave: '10252107', Descripcion: 'Orquídea dendrobium viva kating dang' },
    { Clave: '10252108', Descripcion: 'Orquídea dendrobium viva libertad' },
    { Clave: '10252109', Descripcion: 'Orquídea dendrobium viva hawaii' },
    { Clave: '10252110', Descripcion: 'Orquídea dendrobium viva sakura sweet rosada' },
    { Clave: '10252111', Descripcion: 'Orquídea dendrobium viva sensacional púrpura' },
    { Clave: '10252112', Descripcion: 'Orquídea dendrobium viva blanca' },
    { Clave: '10252200', Descripcion: 'Orquídea cymbidium viva' },
    { Clave: '10252201', Descripcion: 'Orquídea cymbidium viva crema' },
    { Clave: '10252202', Descripcion: 'Orquídea cymbidium viva verde' },
    { Clave: '10252203', Descripcion: 'Orquídea cymbidium viva mini verde' },
    { Clave: '10252204', Descripcion: 'Orquídea cymbidium viva mini rosada' },
    { Clave: '10252205', Descripcion: 'Orquídea cymbidium viva mini roja' },
    { Clave: '10252206', Descripcion: 'Orquídea cymbidium viva mini blanca' },
    { Clave: '10252207', Descripcion: 'Orquídea cymbidium viva mini amarilla' },
    { Clave: '10252208', Descripcion: 'Orquídea cymbidium viva chocolate' },
    { Clave: '10252209', Descripcion: 'Orquídea cymbidium viva rosado oscuro' },
    { Clave: '10252210', Descripcion: 'Orquídea cymbidium viva anaranjada' },
    { Clave: '10252211', Descripcion: 'Orquídea cymbidium viva rosada' },
    { Clave: '10252212', Descripcion: 'Orquídea cymbidium viva blanca' },
    { Clave: '10252213', Descripcion: 'Orquídea cymbidium viva amarilla' },
    { Clave: '10252300', Descripcion: 'Orquídea oncidium viva' },
    { Clave: '10252301', Descripcion: 'Orquídea oncidium viva ducha dorada' },
    { Clave: '10252302', Descripcion: 'Orquídea oncidium viva rhamsey' },
    { Clave: '10252400', Descripcion: 'Orquídea vanda viva' },
    { Clave: '10252401', Descripcion: 'Orquídea vanda alizarin' },
    { Clave: '10252402', Descripcion: 'Orquídea vanda rosada fuerte' },
    { Clave: '10252403', Descripcion: 'Orquídea vanda lavanda' },
    { Clave: '10252404', Descripcion: 'Orquídea vanda púrpura' },
    { Clave: '10252405', Descripcion: 'Orquídea vanda hazme cosquillas rosada' },
    { Clave: '10252406', Descripcion: 'Orquídea vanda amarilla' },
    { Clave: '10301500', Descripcion: 'Rosas cortadas frescas azules o lavanda o púrpura' },
    { Clave: '10301501', Descripcion: 'Rosal cortado fresco allure o sterling 95' },
    { Clave: '10301502', Descripcion: 'Rosal cortado fresco amnesia' },
    { Clave: '10301503', Descripcion: 'Rosal cortado fresco augusta louise' },
    { Clave: '10301504', Descripcion: 'Rosal cortado fresco avant garde' },
    { Clave: '10301505', Descripcion: 'Rosal cortado fresco blue bird' },
    { Clave: '10301506', Descripcion: 'Rosal cortado fresco curiosa' },
    { Clave: '10301507', Descripcion: 'Rosal cortado fresco cool water' },
    { Clave: '10301508', Descripcion: 'Rosal cortado fresco delilah' },
    { Clave: '10301509', Descripcion: 'Rosal cortado fresco double party' },
    { Clave: '10301510', Descripcion: 'Rosal cortado fresco faith' },
    { Clave: '10301511', Descripcion: 'Rosal cortado fresco mami blue o mammy blue' },
    { Clave: '10301512', Descripcion: 'Rosal cortado fresco maritime' },
    { Clave: '10301513', Descripcion: 'Rosal cortado fresco milano' },
    { Clave: '10301514', Descripcion: 'Rosal cortado fresco mistery' },
    { Clave: '10301515', Descripcion: 'Rosal cortado fresco ocean song o boyfriend' },
    { Clave: '10301516', Descripcion: 'Rosal cortado fresco cezanne púrpura' },
    { Clave: '10301517', Descripcion: 'Rosal cortado fresco purple fragrance' },
    { Clave: '10301518', Descripcion: 'Rosal cortado fresco sanaa' },
    { Clave: '10301519', Descripcion: 'Rosal cortado fresco silverstone' },
    { Clave: '10301520', Descripcion: 'Rosal cortado fresco soulmate' },
    { Clave: '10301521', Descripcion: 'Rosal cortado fresco stranger' },
    { Clave: '10301522', Descripcion: 'Rosal cortado fresco tinted blue' },
    { Clave: '10301523', Descripcion: 'Rosal cortado fresco two faces' },
    { Clave: '10301600', Descripcion: 'Rosas cortadas frescas chocolate o marrón' },
    { Clave: '10301601', Descripcion: 'Rosal cortado fresco black lava' },
    { Clave: '10301602', Descripcion: 'Rosal cortado fresco cimarrón' },
    { Clave: '10301603', Descripcion: 'Rosal cortado fresco coffee break' },
    { Clave: '10301604', Descripcion: 'Rosal cortado fresco estelle' },
    { Clave: '10301605', Descripcion: 'Rosal cortado fresco gypsy leonidas' },
    { Clave: '10301606', Descripcion: 'Rosal cortado fresco leonidas' },
    { Clave: '10301607', Descripcion: 'Rosal cortado fresco matilda' },
    { Clave: '10301608', Descripcion: 'Rosal cortado fresco sunny leonidas' },
    { Clave: '10301609', Descripcion: 'Rosal cortado fresco terra nostra' },
    { Clave: '10301610', Descripcion: 'Rosal cortado fresco terracotta' },
    { Clave: '10301700', Descripcion: 'Rosas cortadas frescas crema' },
    { Clave: '10301701', Descripcion: 'Rosal cortado fresco advenire' },
    { Clave: '10301702', Descripcion: 'Rosal cortado fresco alex' },
    { Clave: '10301703', Descripcion: 'Rosal cortado fresco antique brass' },
    { Clave: '10301704', Descripcion: 'Rosal cortado fresco aubade' },
    { Clave: '10301705', Descripcion: 'Rosal cortado fresco beach' },
    { Clave: '10301706', Descripcion: 'Rosal cortado fresco belle pearl' },
    { Clave: '10301707', Descripcion: 'Rosal cortado fresco blush o blush de los andes' },
    { Clave: '10301708', Descripcion: 'Rosal cortado fresco camel' },
    { Clave: '10301709', Descripcion: 'Rosal cortado fresco caramel antike o caramel antique' },
    { Clave: '10301710', Descripcion: 'Rosal cortado fresco champagne' },
    { Clave: '10301711', Descripcion: 'Rosal cortado fresco clear ocean' },
    { Clave: '10301712', Descripcion: 'Rosal cortado fresco combo' },
    { Clave: '10301713', Descripcion: 'Rosal cortado fresco creme de la creme' },
    { Clave: '10301714', Descripcion: 'Rosal cortado fresco emanuella' },
    { Clave: '10301715', Descripcion: 'Rosal cortado fresco evolution' },
    { Clave: '10301716', Descripcion: 'Rosal cortado fresco fedora' },
    { Clave: '10301717', Descripcion: 'Rosal cortado fresco fenice' },
    { Clave: '10301718', Descripcion: 'Rosal cortado fresco french vanilla' },
    { Clave: '10301719', Descripcion: 'Rosal cortado fresco hollywood' },
    { Clave: '10301720', Descripcion: 'Rosal cortado fresco ilios' },
    { Clave: '10301721', Descripcion: 'Rosal cortado fresco jelena' },
    { Clave: '10301722', Descripcion: 'Rosal cortado fresco kameleon' },
    { Clave: '10301723', Descripcion: 'Rosal cortado fresco kentucky' },
    { Clave: '10301724', Descripcion: 'Rosal cortado fresco kings pride' },
    { Clave: '10301725', Descripcion: 'Rosal cortado fresco latin fusion' },
    { Clave: '10301726', Descripcion: 'Rosal cortado fresco lemon dream' },
    { Clave: '10301727', Descripcion: 'Rosal cortado fresco magic moka' },
    { Clave: '10301728', Descripcion: 'Rosal cortado fresco mama mia' },
    { Clave: '10301729', Descripcion: 'Rosal cortado fresco message' },
    { Clave: '10301730', Descripcion: 'Rosal cortado fresco muñeca o munieca' },
    { Clave: '10301731', Descripcion: 'Rosal cortado fresco parfum de rosas' },
    { Clave: '10301732', Descripcion: 'Rosal cortado fresco porcelina' },
    { Clave: '10301733', Descripcion: 'Rosal cortado fresco privilege' },
    { Clave: '10301734', Descripcion: 'Rosal cortado fresco quick sand' },
    { Clave: '10301735', Descripcion: 'Rosal cortado fresco rollercoaster' },
    { Clave: '10301736', Descripcion: 'Rosal cortado fresco romantic curiosa' },
    { Clave: '10301737', Descripcion: 'Rosal cortado fresco safari' },
    { Clave: '10301738', Descripcion: 'Rosal cortado fresco sahara' },
    { Clave: '10301739', Descripcion: 'Rosal cortado fresco sandy femma' },
    { Clave: '10301740', Descripcion: 'Rosal cortado fresco talea' },
    { Clave: '10301741', Descripcion: 'Rosal cortado fresco timeless' },
    { Clave: '10301742', Descripcion: 'Rosal cortado fresco transition' },
    { Clave: '10301743', Descripcion: 'Rosal cortado fresco trump' },
    { Clave: '10301744', Descripcion: 'Rosal cortado fresco twin' },
    { Clave: '10301745', Descripcion: 'Rosal cortado fresco vendela' },
    { Clave: '10301746', Descripcion: 'Rosal cortado fresco virginia' },
    { Clave: '10301800', Descripcion: 'Rosas cortadas frescas verdes o lima' },
    { Clave: '10301801', Descripcion: 'Rosal cortado fresco amandina' },
    { Clave: '10301802', Descripcion: 'Rosal cortado fresco caipirinha' },
    { Clave: '10301803', Descripcion: 'Rosal cortado fresco green fashion' },
    { Clave: '10301804', Descripcion: 'Rosal cortado fresco green tea' },
    { Clave: '10301805', Descripcion: 'Rosal cortado fresco jade' },
    { Clave: '10301806', Descripcion: 'Rosal cortado fresco limbo' },
    { Clave: '10301807', Descripcion: 'Rosal cortado fresco limeña o limenia' },
    { Clave: '10301808', Descripcion: 'Rosal cortado fresco limona' },
    { Clave: '10301809', Descripcion: 'Rosal cortado fresco old dutch' },
    { Clave: '10301810', Descripcion: 'Rosal cortado fresco super green' },
    { Clave: '10301811', Descripcion: 'Rosal cortado fresco sweet green' },
    { Clave: '10301812', Descripcion: 'Rosal cortado fresco cortada fresca' },
    { Clave: '10301813', Descripcion: 'Rosal cortado fresco zazu' },
    { Clave: '10301900', Descripcion: 'Rosas cortadas frescas rosado claro' },
    { Clave: '10301901', Descripcion: 'Rosal cortado fresco anna' },
    { Clave: '10301902', Descripcion: 'Rosal cortado fresco bella vita' },
    { Clave: '10301903', Descripcion: 'Rosal cortado fresco bridal dream' },
    { Clave: '10301904', Descripcion: 'Rosal cortado fresco candy bianca' },
    { Clave: '10301905', Descripcion: 'Rosal cortado fresco caress' },
    { Clave: '10301906', Descripcion: 'Rosal cortado fresco carolina' },
    { Clave: '10301907', Descripcion: 'Rosal cortado fresco climax' },
    { Clave: '10301908', Descripcion: 'Rosal cortado fresco danny' },
    { Clave: '10301909', Descripcion: 'Rosal cortado fresco dolce vita' },
    { Clave: '10301910', Descripcion: 'Rosal cortado fresco elite' },
    { Clave: '10301911', Descripcion: 'Rosal cortado fresco emma' },
    { Clave: '10301912', Descripcion: 'Rosal cortado fresco engagement' },
    { Clave: '10301913', Descripcion: 'Rosal cortado fresco esther' },
    { Clave: '10301914', Descripcion: 'Rosal cortado fresco excalibur' },
    { Clave: '10301915', Descripcion: 'Rosal cortado fresco exciting' },
    { Clave: '10301916', Descripcion: 'Rosal cortado fresco first lady' },
    { Clave: '10301917', Descripcion: 'Rosal cortado fresco geraldine' },
    { Clave: '10301918', Descripcion: 'Rosal cortado fresco gotcha' },
    { Clave: '10301919', Descripcion: 'Rosal cortado fresco harmonie' },
    { Clave: '10301920', Descripcion: 'Rosal cortado fresco heaven' },
    { Clave: '10301921', Descripcion: 'Rosal cortado fresco high and elegant' },
    { Clave: '10301922', Descripcion: 'Rosal cortado fresco katherine' },
    { Clave: '10301923', Descripcion: 'Rosal cortado fresco king kong' },
    { Clave: '10301924', Descripcion: 'Rosal cortado fresco livia' },
    { Clave: '10301925', Descripcion: 'Rosal cortado fresco lorena' },
    { Clave: '10301926', Descripcion: 'Rosal cortado fresco lovely' },
    { Clave: '10301927', Descripcion: 'Rosal cortado fresco maaike' },
    { Clave: '10301928', Descripcion: 'Rosal cortado fresco marilyn' },
    { Clave: '10301929', Descripcion: 'Rosal cortado fresco marlise' },
    { Clave: '10301930', Descripcion: 'Rosal cortado fresco miranda o ausimmon' },
    { Clave: '10301931', Descripcion: 'Rosal cortado fresco mona lisa' },
    { Clave: '10301932', Descripcion: 'Rosal cortado fresco nirvana' },
    { Clave: '10301933', Descripcion: 'Rosal cortado fresco o?hara' },
    { Clave: '10301934', Descripcion: 'Rosal cortado fresco ole' },
    { Clave: '10301935', Descripcion: 'Rosal cortado fresco olga' },
    { Clave: '10301936', Descripcion: 'Rosal cortado fresco pacífica' },
    { Clave: '10301937', Descripcion: 'Rosal cortado fresco party mix' },
    { Clave: '10301938', Descripcion: 'Rosal cortado fresco pekoubo o pekcoubo' },
    { Clave: '10301939', Descripcion: 'Rosal cortado fresco phoebe o ausnotice' },
    { Clave: '10301940', Descripcion: 'Rosal cortado fresco pink farfalla' },
    { Clave: '10301941', Descripcion: 'Rosal cortado fresco pink finess' },
    { Clave: '10301942', Descripcion: 'Rosal cortado fresco pink magic' },
    { Clave: '10301943', Descripcion: 'Rosal cortado fresco pink osiana' },
    { Clave: '10301944', Descripcion: 'Rosal cortado fresco pretty woman' },
    { Clave: '10301945', Descripcion: 'Rosal cortado fresco romance' },
    { Clave: '10301946', Descripcion: 'Rosal cortado fresco romantic antike o antique' },
    { Clave: '10301947', Descripcion: 'Rosal cortado fresco rosalind o astew' },
    { Clave: '10301948', Descripcion: 'Rosal cortado fresco rosita vendela' },
    { Clave: '10301949', Descripcion: 'Rosal cortado fresco secret garden' },
    { Clave: '10301950', Descripcion: 'Rosal cortado fresco solaire' },
    { Clave: '10301951', Descripcion: 'Rosal cortado fresco sophie' },
    { Clave: '10301952', Descripcion: 'Rosal cortado fresco sweet akito' },
    { Clave: '10301953', Descripcion: 'Rosal cortado fresco sweet avalanche' },
    { Clave: '10301954', Descripcion: 'Rosal cortado fresco sweet elegance' },
    { Clave: '10301955', Descripcion: 'Rosal cortado fresco sweet pink' },
    { Clave: '10301956', Descripcion: 'Rosal cortado fresco titanic' },
    { Clave: '10301957', Descripcion: 'Rosal cortado fresco toscanini' },
    { Clave: '10301958', Descripcion: 'Rosal cortado fresco vania' },
    { Clave: '10301959', Descripcion: 'Rosal cortado fresco vanity' },
    { Clave: '10301960', Descripcion: 'Rosal cortado fresco vision' },
    { Clave: '10301961', Descripcion: 'Rosal cortado fresco vivaldi' },
    { Clave: '10301962', Descripcion: 'Rosal cortado fresco whisper' },
    { Clave: '10302000', Descripcion: 'Rosas cortadas frescas multicolor rosados' },
    { Clave: '10302001', Descripcion: 'Rosal cortado fresco attracta' },
    { Clave: '10302002', Descripcion: 'Rosal cortado fresco boheme' },
    { Clave: '10302003', Descripcion: 'Rosal cortado fresco carousel' },
    { Clave: '10302004', Descripcion: 'Rosal cortado fresco cezanne' },
    { Clave: '10302005', Descripcion: 'Rosal cortado fresco crazy one' },
    { Clave: '10302006', Descripcion: 'Rosal cortado fresco dance valley' },
    { Clave: '10302007', Descripcion: 'Rosal cortado fresco duett' },
    { Clave: '10302008', Descripcion: 'Rosal cortado fresco esperance' },
    { Clave: '10302009', Descripcion: 'Rosal cortado fresco fiesta' },
    { Clave: '10302010', Descripcion: 'Rosal cortado fresco halloween' },
    { Clave: '10302011', Descripcion: 'Rosal cortado fresco highlander' },
    { Clave: '10302012', Descripcion: 'Rosal cortado fresco hot ambiance' },
    { Clave: '10302013', Descripcion: 'Rosal cortado fresco la belle' },
    { Clave: '10302014', Descripcion: 'Rosal cortado fresco laguna' },
    { Clave: '10302015', Descripcion: 'Rosal cortado fresco latin ambiance' },
    { Clave: '10302016', Descripcion: 'Rosal cortado fresco latin breeze' },
    { Clave: '10302017', Descripcion: 'Rosal cortado fresco long arifa' },
    { Clave: '10302018', Descripcion: 'Rosal cortado fresco murano' },
    { Clave: '10302019', Descripcion: 'Rosal cortado fresco n ? joy rose' },
    { Clave: '10302020', Descripcion: 'Rosal cortado fresco panama' },
    { Clave: '10302021', Descripcion: 'Rosal cortado fresco peppermint' },
    { Clave: '10302022', Descripcion: 'Rosal cortado fresco pujama party' },
    { Clave: '10302023', Descripcion: 'Rosal cortado fresco portofino' },
    { Clave: '10302024', Descripcion: 'Rosal cortado fresco priceless' },
    { Clave: '10302025', Descripcion: 'Rosal cortado fresco queen amazon' },
    { Clave: '10302026', Descripcion: 'Rosal cortado fresco ranuncula' },
    { Clave: '10302027', Descripcion: 'Rosal cortado fresco rossini' },
    { Clave: '10302028', Descripcion: 'Rosal cortado fresco sabina o sabrina' },
    { Clave: '10302029', Descripcion: 'Rosal cortado fresco scandal' },
    { Clave: '10302030', Descripcion: 'Rosal cortado fresco silvery pink' },
    { Clave: '10302031', Descripcion: 'Rosal cortado fresco something else' },
    { Clave: '10302032', Descripcion: 'Rosal cortado fresco southine' },
    { Clave: '10302033', Descripcion: 'Rosal cortado fresco sovereign' },
    { Clave: '10302034', Descripcion: 'Rosal cortado fresco super disco' },
    { Clave: '10302035', Descripcion: 'Rosal cortado fresco ts 1968' },
    { Clave: '10302036', Descripcion: 'Rosal cortado fresco variance' },
    { Clave: '10302037', Descripcion: 'Rosal cortado fresco verdi' },
    { Clave: '10302100', Descripcion: 'Rosas cortadas frescas anaranjados' },
    { Clave: '10302101', Descripcion: 'Rosal cortado fresco alhambra' },
    { Clave: '10302102', Descripcion: 'Rosal cortado fresco aloha' },
    { Clave: '10302103', Descripcion: 'Rosal cortado fresco amber' },
    { Clave: '10302104', Descripcion: 'Rosal cortado fresco apache' },
    { Clave: '10302105', Descripcion: 'Rosal cortado fresco arabia' },
    { Clave: '10302106', Descripcion: 'Rosal cortado fresco bengala' },
    { Clave: '10302107', Descripcion: 'Rosal cortado fresco bibi' },
    { Clave: '10302108', Descripcion: 'Rosal cortado fresco caramba' },
    { Clave: '10302109', Descripcion: 'Rosal cortado fresco caramella' },
    { Clave: '10302110', Descripcion: 'Rosal cortado fresco carla' },
    { Clave: '10302111', Descripcion: 'Rosal cortado fresco cartagena' },
    { Clave: '10302112', Descripcion: 'Rosal cortado fresco chanson' },
    { Clave: '10302113', Descripcion: 'Rosal cortado fresco charmer' },
    { Clave: '10302114', Descripcion: 'Rosal cortado fresco cherry brandy' },
    { Clave: '10302115', Descripcion: 'Rosal cortado fresco chilis' },
    { Clave: '10302116', Descripcion: 'Rosal cortado fresco cinnamon' },
    { Clave: '10302117', Descripcion: 'Rosal cortado fresco colandro' },
    { Clave: '10302118', Descripcion: 'Rosal cortado fresco coral sea' },
    { Clave: '10302119', Descripcion: 'Rosal cortado fresco corvette o red covette' },
    { Clave: '10302120', Descripcion: 'Rosal cortado fresco dark milva' },
    { Clave: '10302121', Descripcion: 'Rosal cortado fresco donna' },
    { Clave: '10302122', Descripcion: 'Rosal cortado fresco dreamer' },
    { Clave: '10302123', Descripcion: 'Rosal cortado fresco el dorado' },
    { Clave: '10302124', Descripcion: 'Rosal cortado fresco el toro' },
    { Clave: '10302125', Descripcion: 'Rosal cortado fresco elena' },
    { Clave: '10302126', Descripcion: 'Rosal cortado fresco ensueño' },
    { Clave: '10302127', Descripcion: 'Rosal cortado fresco euforia' },
    { Clave: '10302128', Descripcion: 'Rosal cortado fresco exótica' },
    { Clave: '10302129', Descripcion: 'Rosal cortado fresco fancy amazon' },
    { Clave: '10302130', Descripcion: 'Rosal cortado fresco fiction' },
    { Clave: '10302131', Descripcion: 'Rosal cortado fresco finess' },
    { Clave: '10302132', Descripcion: 'Rosal cortado fresco flameco' },
    { Clave: '10302133', Descripcion: 'Rosal cortado fresco free spirit' },
    { Clave: '10302134', Descripcion: 'Rosal cortado fresco gelato' },
    { Clave: '10302135', Descripcion: 'Rosal cortado fresco gypsy curiosa' },
    { Clave: '10302136', Descripcion: 'Rosal cortado fresco high and magic' },
    { Clave: '10302137', Descripcion: 'Rosal cortado fresco high and orange' },
    { Clave: '10302138', Descripcion: 'Rosal cortado fresco iguana o alegra' },
    { Clave: '10302139', Descripcion: 'Rosal cortado fresco impulse' },
    { Clave: '10302140', Descripcion: 'Rosal cortado fresco indian femma' },
    { Clave: '10302141', Descripcion: 'Rosal cortado fresco indian sunset' },
    { Clave: '10302142', Descripcion: 'Rosal cortado fresco karusso' },
    { Clave: '10302143', Descripcion: 'Rosal cortado fresco kerio' },
    { Clave: '10302144', Descripcion: 'Rosal cortado fresco kiki' },
    { Clave: '10302145', Descripcion: 'Rosal cortado fresco latin circus' },
    { Clave: '10302146', Descripcion: 'Rosal cortado fresco leonisa' },
    { Clave: '10302147', Descripcion: 'Rosal cortado fresco lipstick' },
    { Clave: '10302148', Descripcion: 'Rosal cortado fresco lobita' },
    { Clave: '10302149', Descripcion: 'Rosal cortado fresco lica' },
    { Clave: '10302150', Descripcion: 'Rosal cortado fresco manitou' },
    { Clave: '10302151', Descripcion: 'Rosal cortado fresco mariana' },
    { Clave: '10302152', Descripcion: 'Rosal cortado fresco marjan o pk sensation' },
    { Clave: '10302153', Descripcion: 'Rosal cortado fresco milonga' },
    { Clave: '10302154', Descripcion: 'Rosal cortado fresco milva' },
    { Clave: '10302155', Descripcion: 'Rosal cortado fresco miracle' },
    { Clave: '10302156', Descripcion: 'Rosal cortado fresco mirage' },
    { Clave: '10302157', Descripcion: 'Rosal cortado fresco monte carlo' },
    { Clave: '10302158', Descripcion: 'Rosal cortado fresco movie star' },
    { Clave: '10302159', Descripcion: 'Rosal cortado fresco nikita' },
    { Clave: '10302160', Descripcion: 'Rosal cortado fresco orange flame' },
    { Clave: '10302161', Descripcion: 'Rosal cortado fresco orange france' },
    { Clave: '10302162', Descripcion: 'Rosal cortado fresco orange intuition' },
    { Clave: '10302163', Descripcion: 'Rosal cortado fresco orange unique' },
    { Clave: '10302164', Descripcion: 'Rosal cortado fresco orangine u orangina' },
    { Clave: '10302165', Descripcion: 'Rosal cortado fresco papaya' },
    { Clave: '10302166', Descripcion: 'Rosal cortado fresco pareo' },
    { Clave: '10302167', Descripcion: 'Rosal cortado fresco peach' },
    { Clave: '10302168', Descripcion: 'Rosal cortado fresco queensday' },
    { Clave: '10302169', Descripcion: 'Rosal cortado fresco rosselle' },
    { Clave: '10302170', Descripcion: 'Rosal cortado fresco royal circus' },
    { Clave: '10302171', Descripcion: 'Rosal cortado fresco sari' },
    { Clave: '10302172', Descripcion: 'Rosal cortado fresco sensual' },
    { Clave: '10302173', Descripcion: 'Rosal cortado fresco soap' },
    { Clave: '10302174', Descripcion: 'Rosal cortado fresco sombrero' },
    { Clave: '10302175', Descripcion: 'Rosal cortado fresco spicy' },
    { Clave: '10302176', Descripcion: 'Rosal cortado fresco estrella 2000' },
    { Clave: '10302177', Descripcion: 'Rosal cortado fresco summer versilia' },
    { Clave: '10302178', Descripcion: 'Rosal cortado fresco trixx' },
    { Clave: '10302179', Descripcion: 'Rosal cortado fresco tropical amazon' },
    { Clave: '10302180', Descripcion: 'Rosal cortado fresco utopia' },
    { Clave: '10302181', Descripcion: 'Rosal cortado fresco valentine' },
    { Clave: '10302182', Descripcion: 'Rosal cortado fresco verano' },
    { Clave: '10302183', Descripcion: 'Rosal cortado fresco versilia' },
    { Clave: '10302184', Descripcion: 'Rosal cortado fresco voodoo' },
    { Clave: '10302185', Descripcion: 'Rosal cortado fresco wow' },
    { Clave: '10302186', Descripcion: 'Rosal cortado fresco yabadabadoo' },
    { Clave: '10302200', Descripcion: 'Rosas cortadas frescas durazno' },
    { Clave: '10302201', Descripcion: 'Rosal cortado fresco alejandra' },
    { Clave: '10302202', Descripcion: 'Rosal cortado fresco azafrán' },
    { Clave: '10302203', Descripcion: 'Rosal cortado fresco big fun' },
    { Clave: '10302204', Descripcion: 'Rosal cortado fresco cabaret' },
    { Clave: '10302205', Descripcion: 'Rosal cortado fresco capuccino' },
    { Clave: '10302206', Descripcion: 'Rosal cortado fresco carpe diem' },
    { Clave: '10302207', Descripcion: 'Rosal cortado fresco cosima' },
    { Clave: '10302208', Descripcion: 'Rosal cortado fresco cumbia' },
    { Clave: '10302209', Descripcion: 'Rosal cortado fresco dream' },
    { Clave: '10302210', Descripcion: 'Rosal cortado fresco época' },
    { Clave: '10302211', Descripcion: 'Rosal cortado fresco fado' },
    { Clave: '10302212', Descripcion: 'Rosal cortado fresco femma' },
    { Clave: '10302213', Descripcion: 'Rosal cortado fresco guajira' },
    { Clave: '10302214', Descripcion: 'Rosal cortado fresco high and arena' },
    { Clave: '10302215', Descripcion: 'Rosal cortado fresco high and dandy' },
    { Clave: '10302216', Descripcion: 'Rosal cortado fresco high and lucky' },
    { Clave: '10302217', Descripcion: 'Rosal cortado fresco high and peach' },
    { Clave: '10302218', Descripcion: 'Rosal cortado fresco imagination' },
    { Clave: '10302219', Descripcion: 'Rosal cortado fresco isis' },
    { Clave: '10302220', Descripcion: 'Rosal cortado fresco joy o light versilia' },
    { Clave: '10302221', Descripcion: 'Rosal cortado fresco juliet ausjameson' },
    { Clave: '10302222', Descripcion: 'Rosal cortado fresco la parisienne' },
    { Clave: '10302223', Descripcion: 'Rosal cortado fresco la perla' },
    { Clave: '10302224', Descripcion: 'Rosal cortado fresco lovita sunblaze' },
    { Clave: '10302225', Descripcion: 'Rosal cortado fresco malilena o marilena' },
    { Clave: '10302226', Descripcion: 'Rosal cortado fresco monyna' },
    { Clave: '10302227', Descripcion: 'Rosal cortado fresco nectarine' },
    { Clave: '10302228', Descripcion: 'Rosal cortado fresco oriental curiosa' },
    { Clave: '10302229', Descripcion: 'Rosal cortado fresco osiana' },
    { Clave: '10302230', Descripcion: 'Rosal cortado fresco preach avalanche' },
    { Clave: '10302231', Descripcion: 'Rosal cortado fresco peach deja vu' },
    { Clave: '10302232', Descripcion: 'Rosal cortado fresco picanto' },
    { Clave: '10302233', Descripcion: 'Rosal cortado fresco prima donna' },
    { Clave: '10302234', Descripcion: 'Rosal cortado fresco sheril' },
    { Clave: '10302235', Descripcion: 'Rosal cortado fresco sirocco' },
    { Clave: '10302236', Descripcion: 'Rosal cortado fresco tamara' },
    { Clave: '10302237', Descripcion: 'Rosal cortado fresco taxo' },
    { Clave: '10302238', Descripcion: 'Rosal cortado fresco trust' },
    { Clave: '10302239', Descripcion: 'Rosal cortado fresco valencia' },
    { Clave: '10302240', Descripcion: 'Rosal cortado fresco cinci' },
    { Clave: '10302241', Descripcion: 'Rosal cortado fresco wanda' },
    { Clave: '10302300', Descripcion: 'Rosas cortadas frescas rosados' },
    { Clave: '10302301', Descripcion: 'Rosal cortado fresco aerobic' },
    { Clave: '10302302', Descripcion: 'Rosal cortado fresco after party' },
    { Clave: '10302303', Descripcion: 'Rosal cortado fresco amsterdam' },
    { Clave: '10302304', Descripcion: 'Rosal cortado fresco aqua rose' },
    { Clave: '10302305', Descripcion: 'Rosal cortado fresco attache' },
    { Clave: '10302306', Descripcion: 'Rosal cortado fresco attitude' },
    { Clave: '10302307', Descripcion: 'Rosal cortado fresco ballet' },
    { Clave: '10302308', Descripcion: 'Rosal cortado fresco belami' },
    { Clave: '10302309', Descripcion: 'Rosal cortado fresco bella voo o belle vue' },
    { Clave: '10302310', Descripcion: 'Rosal cortado fresco bling bling' },
    { Clave: '10302311', Descripcion: 'Rosal cortado fresco blushing akito' },
    { Clave: '10302312', Descripcion: 'Rosal cortado fresco brooke' },
    { Clave: '10302313', Descripcion: 'Rosal cortado fresco bugatti' },
    { Clave: '10302314', Descripcion: 'Rosal cortado fresco cadillac' },
    { Clave: '10302315', Descripcion: 'Rosal cortado fresco carnaval' },
    { Clave: '10302316', Descripcion: 'Rosal cortado fresco cereza' },
    { Clave: '10302317', Descripcion: 'Rosal cortado fresco charming' },
    { Clave: '10302318', Descripcion: 'Rosal cortado fresco cherry o' },
    { Clave: '10302319', Descripcion: 'Rosal cortado fresco ciciolina' },
    { Clave: '10302320', Descripcion: 'Rosal cortado fresco classic cezanne' },
    { Clave: '10302321', Descripcion: 'Rosal cortado fresco clasic duett' },
    { Clave: '10302322', Descripcion: 'Rosal cortado fresco cosmiq' },
    { Clave: '10302323', Descripcion: 'Rosal cortado fresco dark engagement' },
    { Clave: '10302324', Descripcion: 'Rosal cortado fresco daytona' },
    { Clave: '10302325', Descripcion: 'Rosal cortado fresco dekora' },
    { Clave: '10302326', Descripcion: 'Rosal cortado fresco dolores' },
    { Clave: '10302327', Descripcion: 'Rosal cortado fresco eliza' },
    { Clave: '10302328', Descripcion: 'Rosal cortado fresco baccara' },
    { Clave: '10302329', Descripcion: 'Rosal cortado fresco full house' },
    { Clave: '10302330', Descripcion: 'Rosal cortado fresco funky' },
    { Clave: '10302331', Descripcion: 'Rosal cortado fresco giliane' },
    { Clave: '10302332', Descripcion: 'Rosal cortado fresco gran europe' },
    { Clave: '10302333', Descripcion: 'Rosal cortado fresco habari' },
    { Clave: '10302334', Descripcion: 'Rosal cortado fresco hanseat' },
    { Clave: '10302335', Descripcion: 'Rosal cortado fresco high and amazing' },
    { Clave: '10302336', Descripcion: 'Rosal cortado fresco high and bonita' },
    { Clave: '10302337', Descripcion: 'Rosal cortado fresco high and booming' },
    { Clave: '10302338', Descripcion: 'Rosal cortado fresco high and fantasy' },
    { Clave: '10302339', Descripcion: 'Rosal cortado fresco high and rich' },
    { Clave: '10302340', Descripcion: 'Rosal cortado fresco hot lady' },
    { Clave: '10302341', Descripcion: 'Rosal cortado fresco hot princess' },
    { Clave: '10302342', Descripcion: 'Rosal cortado fresco inspiration' },
    { Clave: '10302343', Descripcion: 'Rosal cortado fresco jeimy' },
    { Clave: '10302344', Descripcion: 'Rosal cortado fresco kachita' },
    { Clave: '10302345', Descripcion: 'Rosal cortado fresco karen' },
    { Clave: '10302346', Descripcion: 'Rosal cortado fresco kenji' },
    { Clave: '10302347', Descripcion: 'Rosal cortado fresco kiko' },
    { Clave: '10302348', Descripcion: 'Rosal cortado fresco laser' },
    { Clave: '10302349', Descripcion: 'Rosal cortado fresco latin duett' },
    { Clave: '10302350', Descripcion: 'Rosal cortado fresco latin fever' },
    { Clave: '10302351', Descripcion: 'Rosal cortado fresco lifestyle' },
    { Clave: '10302352', Descripcion: 'Rosal cortado fresco light orlando' },
    { Clave: '10302353', Descripcion: 'Rosal cortado fresco lovely dreams' },
    { Clave: '10302354', Descripcion: 'Rosal cortado fresco loyalty' },
    { Clave: '10302355', Descripcion: 'Rosal cortado fresco malibu' },
    { Clave: '10302356', Descripcion: 'Rosal cortado fresco mata ? hari' },
    { Clave: '10302357', Descripcion: 'Rosal cortado fresco memphis' },
    { Clave: '10302358', Descripcion: 'Rosal cortado fresco mi amor' },
    { Clave: '10302359', Descripcion: 'Rosal cortado fresco miami' },
    { Clave: '10302360', Descripcion: 'Rosal cortado fresco michelle' },
    { Clave: '10302361', Descripcion: 'Rosal cortado fresco mikaela' },
    { Clave: '10302362', Descripcion: 'Rosal cortado fresco orchestra' },
    { Clave: '10302363', Descripcion: 'Rosal cortado fresco orlando' },
    { Clave: '10302364', Descripcion: 'Rosal cortado fresco osadia' },
    { Clave: '10302365', Descripcion: 'Rosal cortado fresco paeonia freelander' },
    { Clave: '10302366', Descripcion: 'Rosal cortado fresco paula' },
    { Clave: '10302367', Descripcion: 'Rosal cortado fresco pavarotti' },
    { Clave: '10302368', Descripcion: 'Rosal cortado fresco pink intuition' },
    { Clave: '10302369', Descripcion: 'Rosal cortado fresco poison' },
    { Clave: '10302370', Descripcion: 'Rosal cortado fresco princess' },
    { Clave: '10302371', Descripcion: 'Rosal cortado fresco queen mary' },
    { Clave: '10302372', Descripcion: 'Rosal cortado fresco raphaela' },
    { Clave: '10302373', Descripcion: 'Rosal cortado fresco raspberry' },
    { Clave: '10302374', Descripcion: 'Rosal cortado fresco ravel' },
    { Clave: '10302375', Descripcion: 'Rosal cortado fresco riviera' },
    { Clave: '10302376', Descripcion: 'Rosal cortado fresco sade' },
    { Clave: '10302377', Descripcion: 'Rosal cortado fresco sashimi' },
    { Clave: '10302378', Descripcion: 'Rosal cortado fresco shanya' },
    { Clave: '10302379', Descripcion: 'Rosal cortado fresco shocking versilia' },
    { Clave: '10302380', Descripcion: 'Rosal cortado fresco solitaire' },
    { Clave: '10302381', Descripcion: 'Rosal cortado fresco something different' },
    { Clave: '10302382', Descripcion: 'Rosal cortado fresco splendid renate' },
    { Clave: '10302383', Descripcion: 'Rosal cortado fresco star' },
    { Clave: '10302384', Descripcion: 'Rosal cortado fresco sweet candia' },
    { Clave: '10302385', Descripcion: 'Rosal cortado fresco sweet moments' },
    { Clave: '10302386', Descripcion: 'Rosal cortado fresco sweet unique' },
    { Clave: '10302387', Descripcion: 'Rosal cortado fresco taboo' },
    { Clave: '10302388', Descripcion: 'Rosal cortado fresco timona' },
    { Clave: '10302389', Descripcion: 'Rosal cortado fresco topaz' },
    { Clave: '10302390', Descripcion: 'Rosal cortado fresco vogue' },
    { Clave: '10302391', Descripcion: 'Rosal cortado fresco voila' },
    { Clave: '10302392', Descripcion: 'Rosal cortado fresco wild' },
    { Clave: '10302393', Descripcion: 'Rosal cortado fresco yves piaget' },
    { Clave: '10302400', Descripcion: 'Rosas cortadas frescas rojos o burgundy' },
    { Clave: '10302401', Descripcion: 'Rosal cortado fresco african dawn' },
    { Clave: '10302402', Descripcion: 'Rosal cortado fresco amada' },
    { Clave: '10302403', Descripcion: 'Rosal cortado fresco black baccara' },
    { Clave: '10302404', Descripcion: 'Rosal cortado fresco black beauty' },
    { Clave: '10302405', Descripcion: 'Rosal cortado fresco black finess o black magic' },
    { Clave: '10302406', Descripcion: 'Rosal cortado fresco black magic' },
    { Clave: '10302407', Descripcion: 'Rosal cortado fresco bohemian o pasarela' },
    { Clave: '10302408', Descripcion: 'Rosal cortado fresco breathless' },
    { Clave: '10302409', Descripcion: 'Rosal cortado fresco caballero' },
    { Clave: '10302410', Descripcion: 'Rosal cortado fresco carrera' },
    { Clave: '10302411', Descripcion: 'Rosal cortado fresco charlene' },
    { Clave: '10302412', Descripcion: 'Rosal cortado fresco charlotte' },
    { Clave: '10302413', Descripcion: 'Rosal cortado fresco cherry lady' },
    { Clave: '10302414', Descripcion: 'Rosal cortado fresco cherry love' },
    { Clave: '10302415', Descripcion: 'Rosal cortado fresco classy rose' },
    { Clave: '10302416', Descripcion: 'Rosal cortado fresco colorado velvet' },
    { Clave: '10302417', Descripcion: 'Rosal cortado fresco corazón' },
    { Clave: '10302418', Descripcion: 'Rosal cortado fresco corrida' },
    { Clave: '10302419', Descripcion: 'Rosal cortado fresco dynamite' },
    { Clave: '10302420', Descripcion: 'Rosal cortado fresco eurored' },
    { Clave: '10302421', Descripcion: 'Rosal cortado fresco fashion' },
    { Clave: '10302422', Descripcion: 'Rosal cortado fresco fire and ice' },
    { Clave: '10302423', Descripcion: 'Rosal cortado fresco first red' },
    { Clave: '10302424', Descripcion: 'Rosal cortado fresco forever young' },
    { Clave: '10302425', Descripcion: 'Rosal cortado fresco freedom' },
    { Clave: '10302426', Descripcion: 'Rosal cortado fresco freestyle' },
    { Clave: '10302427', Descripcion: 'Rosal cortado fresco friendship' },
    { Clave: '10302428', Descripcion: 'Rosal cortado fresco gospel' },
    { Clave: '10302429', Descripcion: 'Rosal cortado fresco graffity' },
    { Clave: '10302430', Descripcion: 'Rosal cortado fresco grand gala' },
    { Clave: '10302431', Descripcion: 'Rosal cortado fresco grand prix' },
    { Clave: '10302432', Descripcion: 'Rosal cortado fresco grand classe' },
    { Clave: '10302433', Descripcion: 'Rosal cortado fresco hearts' },
    { Clave: '10302434', Descripcion: 'Rosal cortado fresco heat' },
    { Clave: '10302435', Descripcion: 'Rosal cortado fresco hocus pocus' },
    { Clave: '10302436', Descripcion: 'Rosal cortado fresco lady in red' },
    { Clave: '10302437', Descripcion: 'Rosal cortado fresco latin lady' },
    { Clave: '10302438', Descripcion: 'Rosal cortado fresco legend' },
    { Clave: '10302439', Descripcion: 'Rosal cortado fresco lulu' },
    { Clave: '10302440', Descripcion: 'Rosal cortado fresco luna rossa' },
    { Clave: '10302441', Descripcion: 'Rosal cortado fresco luxor' },
    { Clave: '10302442', Descripcion: 'Rosal cortado fresco madame delbard o carola' },
    { Clave: '10302443', Descripcion: 'Rosal cortado fresco miss paris' },
    { Clave: '10302444', Descripcion: 'Rosal cortado fresco nicole' },
    { Clave: '10302445', Descripcion: 'Rosal cortado fresco night fever' },
    { Clave: '10302446', Descripcion: 'Rosal cortado fresco obsession' },
    { Clave: '10302447', Descripcion: 'Rosal cortado fresco opium' },
    { Clave: '10302448', Descripcion: 'Rosal cortado fresco paz' },
    { Clave: '10302449', Descripcion: 'Rosal cortado fresco preference' },
    { Clave: '10302450', Descripcion: 'Rosal cortado fresco red berlin' },
    { Clave: '10302451', Descripcion: 'Rosal cortado fresco red bull' },
    { Clave: '10302452', Descripcion: 'Rosal cortado fresco red calypso' },
    { Clave: '10302453', Descripcion: 'Rosal cortado fresco red diamond' },
    { Clave: '10302454', Descripcion: 'Rosal cortado fresco red fantasy' },
    { Clave: '10302455', Descripcion: 'Rosal cortado fresco red france' },
    { Clave: '10302456', Descripcion: 'Rosal cortado fresco red intuition' },
    { Clave: '10302457', Descripcion: 'Rosal cortado fresco red jewel' },
    { Clave: '10302458', Descripcion: 'Rosal cortado fresco red magic' },
    { Clave: '10302459', Descripcion: 'Rosal cortado fresco red one' },
    { Clave: '10302460', Descripcion: 'Rosal cortado fresco red paris' },
    { Clave: '10302461', Descripcion: 'Rosal cortado fresco red princess' },
    { Clave: '10302462', Descripcion: 'Rosal cortado fresco red sensation o colorad' },
    { Clave: '10302463', Descripcion: 'Rosal cortado fresco red unique' },
    { Clave: '10302464', Descripcion: 'Rosal cortado fresco rockefeller' },
    { Clave: '10302465', Descripcion: 'Rosal cortado fresco romeo' },
    { Clave: '10302466', Descripcion: 'Rosal cortado fresco rouge baiser' },
    { Clave: '10302467', Descripcion: 'Rosal cortado fresco roulette' },
    { Clave: '10302468', Descripcion: 'Rosal cortado fresco royal massai' },
    { Clave: '10302469', Descripcion: 'Rosal cortado fresco royal red' },
    { Clave: '10302470', Descripcion: 'Rosal cortado fresco samurai' },
    { Clave: '10302471', Descripcion: 'Rosal cortado fresco sexy red' },
    { Clave: '10302472', Descripcion: 'Rosal cortado fresco starfire' },
    { Clave: '10302473', Descripcion: 'Rosal cortado fresco tango' },
    { Clave: '10302474', Descripcion: 'Rosal cortado fresco tiger tail' },
    { Clave: '10302475', Descripcion: 'Rosal cortado fresco tinto' },
    { Clave: '10302476', Descripcion: 'Rosal cortado fresco top secret' },
    { Clave: '10302477', Descripcion: 'Rosal cortado fresco vital' },
    { Clave: '10302478', Descripcion: 'Rosal cortado fresco wisdom' },
    { Clave: '10302479', Descripcion: 'Rosal cortado fresco xantia' },
    { Clave: '10302480', Descripcion: 'Rosal cortado fresco xcite' },
    { Clave: '10302500', Descripcion: 'Rosas cortadas frescas sweetheart' },
    { Clave: '10302501', Descripcion: 'Rosal cortado fresco burgundy sweetheart' },
    { Clave: '10302502', Descripcion: 'Rosal cortado fresco cream sweetheart' },
    { Clave: '10302503', Descripcion: 'Rosal cortado fresco hot pink sweetheart' },
    { Clave: '10302504', Descripcion: 'Rosal cortado fresco lavender sweetheart' },
    { Clave: '10302505', Descripcion: 'Rosal cortado fresco light pink sweetheart' },
    { Clave: '10302506', Descripcion: 'Rosal cortado fresco orange sweetheart' },
    { Clave: '10302507', Descripcion: 'Rosal cortado fresco peach sweetheart' },
    { Clave: '10302508', Descripcion: 'Rosal cortado fresco red sweetheart' },
    { Clave: '10302509', Descripcion: 'Rosal cortado fresco white sweetheart' },
    { Clave: '10302510', Descripcion: 'Rosal cortado fresco yelllow sweetheart' },
    { Clave: '10302600', Descripcion: 'Rosas cortadas frescas blancos' },
    { Clave: '10302601', Descripcion: 'Rosal cortado fresco absolut' },
    { Clave: '10302602', Descripcion: 'Rosal cortado fresco aida' },
    { Clave: '10302603', Descripcion: 'Rosal cortado fresco akito' },
    { Clave: '10302604', Descripcion: 'Rosal cortado fresco amelia' },
    { Clave: '10302605', Descripcion: 'Rosal cortado fresco anastasia' },
    { Clave: '10302606', Descripcion: 'Rosal cortado fresco andean crystal' },
    { Clave: '10302607', Descripcion: 'Rosal cortado fresco angel' },
    { Clave: '10302608', Descripcion: 'Rosal cortado fresco annemarie' },
    { Clave: '10302609', Descripcion: 'Rosal cortado fresco avalanche' },
    { Clave: '10302610', Descripcion: 'Rosal cortado fresco bianca' },
    { Clave: '10302611', Descripcion: 'Rosal cortado fresco blizzard' },
    { Clave: '10302612', Descripcion: 'Rosal cortado fresco bridal akito' },
    { Clave: '10302613', Descripcion: 'Rosal cortado fresco domenica' },
    { Clave: '10302614', Descripcion: 'Rosal cortado fresco escimo' },
    { Clave: '10302615', Descripcion: 'Rosal cortado fresco farfalla' },
    { Clave: '10302616', Descripcion: 'Rosal cortado fresco high and peace' },
    { Clave: '10302617', Descripcion: 'Rosal cortado fresco high and pure' },
    { Clave: '10302618', Descripcion: 'Rosal cortado fresco inocencia o innocenti' },
    { Clave: '10302619', Descripcion: 'Rosal cortado fresco ivory' },
    { Clave: '10302620', Descripcion: 'Rosal cortado fresco mondial' },
    { Clave: '10302621', Descripcion: 'Rosal cortado fresco mount everest' },
    { Clave: '10302622', Descripcion: 'Rosal cortado fresco nova zembla' },
    { Clave: '10302623', Descripcion: 'Rosal cortado fresco patience o auspator' },
    { Clave: '10302624', Descripcion: 'Rosal cortado fresco polar star' },
    { Clave: '10302625', Descripcion: 'Rosal cortado fresco polo' },
    { Clave: '10302626', Descripcion: 'Rosal cortado fresco proud' },
    { Clave: '10302627', Descripcion: 'Rosal cortado fresco snowy jewel' },
    { Clave: '10302628', Descripcion: 'Rosal cortado fresco tibet' },
    { Clave: '10302629', Descripcion: 'Rosal cortado fresco tineke' },
    { Clave: '10302630', Descripcion: 'Rosal cortado fresco vitality' },
    { Clave: '10302631', Descripcion: 'Rosal cortado fresco white cadillac' },
    { Clave: '10302632', Descripcion: 'Rosal cortado fresco white dove' },
    { Clave: '10302700', Descripcion: 'Rosas cortadas frescas amarillos' },
    { Clave: '10302701', Descripcion: 'Rosal cortado fresco aalsmeer gold' },
    { Clave: '10302702', Descripcion: 'Rosal cortado fresco alina' },
    { Clave: '10302703', Descripcion: 'Rosal cortado fresco ambiance' },
    { Clave: '10302704', Descripcion: 'Rosal cortado fresco aquarel' },
    { Clave: '10302705', Descripcion: 'Rosal cortado fresco autumn' },
    { Clave: '10302706', Descripcion: 'Rosal cortado fresco brasil' },
    { Clave: '10302707', Descripcion: 'Rosal cortado fresco candle light' },
    { Clave: '10302708', Descripcion: 'Rosal cortado fresco cantata o cantate' },
    { Clave: '10302709', Descripcion: 'Rosal cortado fresco capriccio' },
    { Clave: '10302710', Descripcion: 'Rosal cortado fresco caribbean' },
    { Clave: '10302711', Descripcion: 'Rosal cortado fresco circus' },
    { Clave: '10302712', Descripcion: 'Rosal cortado fresco citran' },
    { Clave: '10302713', Descripcion: 'Rosal cortado fresco concorde' },
    { Clave: '10302714', Descripcion: 'Rosal cortado fresco conga' },
    { Clave: '10302715', Descripcion: 'Rosal cortado fresco deja vu' },
    { Clave: '10302716', Descripcion: 'Rosal cortado fresco desire' },
    { Clave: '10302717', Descripcion: 'Rosal cortado fresco donia sol' },
    { Clave: '10302718', Descripcion: 'Rosal cortado fresco dueto' },
    { Clave: '10302719', Descripcion: 'Rosal cortado fresco erin' },
    { Clave: '10302720', Descripcion: 'Rosal cortado fresco exotic curiosa' },
    { Clave: '10302721', Descripcion: 'Rosal cortado fresco feria' },
    { Clave: '10302722', Descripcion: 'Rosal cortado fresco fire bird' },
    { Clave: '10302723', Descripcion: 'Rosal cortado fresco florida' },
    { Clave: '10302724', Descripcion: 'Rosal cortado fresco friendly' },
    { Clave: '10302725', Descripcion: 'Rosal cortado fresco gallinda' },
    { Clave: '10302726', Descripcion: 'Rosal cortado fresco geisha' },
    { Clave: '10302727', Descripcion: 'Rosal cortado fresco gelbe' },
    { Clave: '10302728', Descripcion: 'Rosal cortado fresco gelosia o yellow flame' },
    { Clave: '10302729', Descripcion: 'Rosal cortado fresco gold rush' },
    { Clave: '10302730', Descripcion: 'Rosal cortado fresco gold star' },
    { Clave: '10302731', Descripcion: 'Rosal cortado fresco gold strike' },
    { Clave: '10302732', Descripcion: 'Rosal cortado fresco golda' },
    { Clave: '10302733', Descripcion: 'Rosal cortado fresco golden fashion' },
    { Clave: '10302734', Descripcion: 'Rosal cortado fresco golden gate' },
    { Clave: '10302735', Descripcion: 'Rosal cortado fresco gran dorado' },
    { Clave: '10302736', Descripcion: 'Rosal cortado fresco helio' },
    { Clave: '10302737', Descripcion: 'Rosal cortado fresco high and exotic' },
    { Clave: '10302738', Descripcion: 'Rosal cortado fresco high and yellow' },
    { Clave: '10302739', Descripcion: 'Rosal cortado fresco high and yellow magic' },
    { Clave: '10302740', Descripcion: 'Rosal cortado fresco high society' },
    { Clave: '10302741', Descripcion: 'Rosal cortado fresco hummer' },
    { Clave: '10302742', Descripcion: 'Rosal cortado fresco idole o elle' },
    { Clave: '10302743', Descripcion: 'Rosal cortado fresco inti' },
    { Clave: '10302744', Descripcion: 'Rosal cortado fresco jet set' },
    { Clave: '10302745', Descripcion: 'Rosal cortado fresco judy' },
    { Clave: '10302746', Descripcion: 'Rosal cortado fresco jupiter' },
    { Clave: '10302747', Descripcion: 'Rosal cortado fresco konfetti' },
    { Clave: '10302748', Descripcion: 'Rosal cortado fresco kyara o kira' },
    { Clave: '10302749', Descripcion: 'Rosal cortado fresco latin beauty' },
    { Clave: '10302750', Descripcion: 'Rosal cortado fresco latin spirit' },
    { Clave: '10302751', Descripcion: 'Rosal cortado fresco latina' },
    { Clave: '10302752', Descripcion: 'Rosal cortado fresco lina' },
    { Clave: '10302753', Descripcion: 'Rosal cortado fresco lindsey' },
    { Clave: '10302754', Descripcion: 'Rosal cortado fresco male' },
    { Clave: '10302755', Descripcion: 'Rosal cortado fresco marie clare' },
    { Clave: '10302756', Descripcion: 'Rosal cortado fresco marisa' },
    { Clave: '10302757', Descripcion: 'Rosal cortado fresco matchball' },
    { Clave: '10302758', Descripcion: 'Rosal cortado fresco melon' },
    { Clave: '10302759', Descripcion: 'Rosal cortado fresco mohana' },
    { Clave: '10302760', Descripcion: 'Rosal cortado fresco okie dokie' },
    { Clave: '10302761', Descripcion: 'Rosal cortado fresco pailine' },
    { Clave: '10302762', Descripcion: 'Rosal cortado fresco parrot' },
    { Clave: '10302763', Descripcion: 'Rosal cortado fresco rio d oro' },
    { Clave: '10302764', Descripcion: 'Rosal cortado fresco salami' },
    { Clave: '10302765', Descripcion: 'Rosal cortado fresco santa fe' },
    { Clave: '10302766', Descripcion: 'Rosal cortado fresco skyline' },
    { Clave: '10302767', Descripcion: 'Rosal cortado fresco sonrisa' },
    { Clave: '10302768', Descripcion: 'Rosal cortado fresco star ambiance' },
    { Clave: '10302769', Descripcion: 'Rosal cortado fresco starbust' },
    { Clave: '10302770', Descripcion: 'Rosal cortado fresco sun king' },
    { Clave: '10302771', Descripcion: 'Rosal cortado fresco sunmaster' },
    { Clave: '10302772', Descripcion: 'Rosal cortado fresco sunny milva' },
    { Clave: '10302773', Descripcion: 'Rosal cortado fresco sushi' },
    { Clave: '10302774', Descripcion: 'Rosal cortado fresco tabasco' },
    { Clave: '10302775', Descripcion: 'Rosal cortado fresco tara' },
    { Clave: '10302776', Descripcion: 'Rosal cortado fresco tresor 2000' },
    { Clave: '10302777', Descripcion: 'Rosal cortado fresco ooty' },
    { Clave: '10302778', Descripcion: 'Rosal cortado fresco yellow coral' },
    { Clave: '10302779', Descripcion: 'Rosal cortado fresco yellow finess' },
    { Clave: '10302780', Descripcion: 'Rosal cortado fresco yellow submarine' },
    { Clave: '10302781', Descripcion: 'Rosal cortado fresco yellow sunset' },
    { Clave: '10302782', Descripcion: 'Rosal cortado fresco yelllow timeless' },
    { Clave: '10302800', Descripcion: 'Rosas cortadas frescas spray' },
    { Clave: '10302801', Descripcion: 'Rosal cortado fresco alegría spray' },
    { Clave: '10302802', Descripcion: 'Rosal cortado fresco andrea follies' },
    { Clave: '10302803', Descripcion: 'Rosal cortado fresco antara follies' },
    { Clave: '10302804', Descripcion: 'Rosal cortado fresco arrow follies' },
    { Clave: '10302805', Descripcion: 'Rosal cortado fresco babe spray' },
    { Clave: '10302806', Descripcion: 'Rosal cortado fresco bellina collection' },
    { Clave: '10302807', Descripcion: 'Rosal cortado fresco blue moon' },
    { Clave: '10302808', Descripcion: 'Rosal cortado fresco chablis spray' },
    { Clave: '10302809', Descripcion: 'Rosal cortado fresco cherry follies' },
    { Clave: '10302810', Descripcion: 'Rosal cortado fresco chess spray' },
    { Clave: '10302811', Descripcion: 'Rosal cortado fresco classic lydia spray' },
    { Clave: '10302812', Descripcion: 'Rosal cortado fresco cream gracia spray' },
    { Clave: '10302813', Descripcion: 'Rosal cortado fresco cream lydia srpay' },
    { Clave: '10302814', Descripcion: 'Rosal cortado fresco cream sensation spray' },
    { Clave: '10302815', Descripcion: 'Rosal cortado fresco cremita spray' },
    { Clave: '10302816', Descripcion: 'Rosal cortado fresco diablo spray' },
    { Clave: '10302817', Descripcion: 'Rosal cortado fresco electra spray' },
    { Clave: '10302818', Descripcion: 'Rosal cortado fresco fire king spray' },
    { Clave: '10302819', Descripcion: 'Rosal cortado fresco fleurspray' },
    { Clave: '10302820', Descripcion: 'Rosal cortado fresco girlie follies spray' },
    { Clave: '10302821', Descripcion: 'Rosal cortado fresco giselle follies spray' },
    { Clave: '10302822', Descripcion: 'Rosal cortado fresco golden collection spray' },
    { Clave: '10302823', Descripcion: 'Rosal cortado fresco golden mimi spray' },
    { Clave: '10302824', Descripcion: 'Rosal cortado fresco gracia spray' },
    { Clave: '10302825', Descripcion: 'Rosal cortado fresco hot majolica spray' },
    { Clave: '10302826', Descripcion: 'Rosal cortado fresco hot pink follies spray' },
    { Clave: '10302827', Descripcion: 'Rosal cortado fresco ilse spray' },
    { Clave: '10302828', Descripcion: 'Rosal cortado fresco jelena spray' },
    { Clave: '10302829', Descripcion: 'Rosal cortado fresco laminuette spray' },
    { Clave: '10302830', Descripcion: 'Rosal cortado fresco lavender follies spray' },
    { Clave: '10302831', Descripcion: 'Rosal cortado fresco limoncello spray' },
    { Clave: '10302832', Descripcion: 'Rosal cortado fresco little silver spray' },
    { Clave: '10302833', Descripcion: 'Rosal cortado fresco lovely lydia spray' },
    { Clave: '10302834', Descripcion: 'Rosal cortado fresco lucy spray' },
    { Clave: '10302835', Descripcion: 'Rosal cortado fresco lydia spray' },
    { Clave: '10302836', Descripcion: 'Rosal cortado fresco macarena spray' },
    { Clave: '10302837', Descripcion: 'Rosal cortado fresco magic sensation spray' },
    { Clave: '10302838', Descripcion: 'Rosal cortado fresco majolica spray' },
    { Clave: '10302839', Descripcion: 'Rosal cortado fresco mambo número 5 spray' },
    { Clave: '10302840', Descripcion: 'Rosal cortado fresco mambo spray' },
    { Clave: '10302841', Descripcion: 'Rosal cortado fresco marlene spray' },
    { Clave: '10302842', Descripcion: 'Rosal cortado fresco mimi eden spray' },
    { Clave: '10302843', Descripcion: 'Rosal cortado fresco minou spray' },
    { Clave: '10302844', Descripcion: 'Rosal cortado fresco nikita spray' },
    { Clave: '10302845', Descripcion: 'Rosal cortado fresco novel collection spray' },
    { Clave: '10302846', Descripcion: 'Rosal cortado fresco orange success spray' },
    { Clave: '10302847', Descripcion: 'Rosal cortado fresco pepita spray' },
    { Clave: '10302848', Descripcion: 'Rosal cortado fresco pink flash spray' },
    { Clave: '10302849', Descripcion: 'Rosal cortado fresco pink sensation spray' },
    { Clave: '10302850', Descripcion: 'Rosal cortado fresco porcelina spray' },
    { Clave: '10302851', Descripcion: 'Rosal cortado fresco princess spray' },
    { Clave: '10302852', Descripcion: 'Rosal cortado fresco purple mikado spray' },
    { Clave: '10302853', Descripcion: 'Rosal cortado fresco red angel spray' },
    { Clave: '10302854', Descripcion: 'Rosal cortado fresco red collection spray' },
    { Clave: '10302855', Descripcion: 'Rosal cortado fresco red hero spray' },
    { Clave: '10302856', Descripcion: 'Rosal cortado fresco red mikado spray' },
    { Clave: '10302857', Descripcion: 'Rosal cortado fresco red vision spray' },
    { Clave: '10302858', Descripcion: 'Rosal cortado fresco ritmo spray' },
    { Clave: '10302859', Descripcion: 'Rosal cortado fresco romance mikado o eva spray' },
    { Clave: '10302860', Descripcion: 'Rosal cortado fresco romántica follies spray' },
    { Clave: '10302861', Descripcion: 'Rosal cortado fresco rubicon spray' },
    { Clave: '10302862', Descripcion: 'Rosal cortado fresco rumba spray' },
    { Clave: '10302863', Descripcion: 'Rosal cortado fresco salsa spray' },
    { Clave: '10302864', Descripcion: 'Rosal cortado fresco sangrita spray' },
    { Clave: '10302865', Descripcion: 'Rosal cortado fresco santa bárbara spray' },
    { Clave: '10302866', Descripcion: 'Rosal cortado fresco sashaba spray' },
    { Clave: '10302867', Descripcion: 'Rosal cortado fresco scarlett spray' },
    { Clave: '10302868', Descripcion: 'Rosal cortado fresco seline spray' },
    { Clave: '10302869', Descripcion: 'Rosal cortado fresco sensation spray' },
    { Clave: '10302870', Descripcion: 'Rosal cortado fresco silver collection spray' },
    { Clave: '10302871', Descripcion: 'Rosal cortado fresco silver sensation spray' },
    { Clave: '10302872', Descripcion: 'Rosal cortado fresco snowdance spray' },
    { Clave: '10302873', Descripcion: 'Rosal cortado fresco snowflake spray' },
    { Clave: '10302874', Descripcion: 'Rosal cortado fresco suncity spray' },
    { Clave: '10302875', Descripcion: 'Rosal cortado fresco super nova spray' },
    { Clave: '10302876', Descripcion: 'Rosal cortado fresco sweet sensation spray' },
    { Clave: '10302877', Descripcion: 'Rosal cortado fresco taifun o typhoon spray' },
    { Clave: '10302878', Descripcion: 'Rosal cortado fresco tamango spray' },
    { Clave: '10302879', Descripcion: 'Rosal cortado fresco tanger follies spray' },
    { Clave: '10302880', Descripcion: 'Rosal cortado fresco tiara spray' },
    { Clave: '10302881', Descripcion: 'Rosal cortado fresco tiramisú spray' },
    { Clave: '10302882', Descripcion: 'Rosal cortado fresco twinkle bride spray' },
    { Clave: '10302883', Descripcion: 'Rosal cortado fresco viviane spray' },
    { Clave: '10302884', Descripcion: 'Rosal cortado fresco white majolica spray' },
    { Clave: '10302885', Descripcion: 'Rosal cortado fresco white mikado spray' },
    { Clave: '10302886', Descripcion: 'Rosal cortado fresco xentina spray' },
    { Clave: '10302887', Descripcion: 'Rosal cortado fresco yellow babe spray' },
    { Clave: '10302888', Descripcion: 'Rosal cortado fresco yellow follies spray' },
    { Clave: '10311500', Descripcion: 'Anturios cortados frescos' },
    { Clave: '10311501', Descripcion: 'Anturio cortado fresco chocolate' },
    { Clave: '10311502', Descripcion: 'Anturio cortado fresco rojo oscuro' },
    { Clave: '10311503', Descripcion: 'Anturio cortado fresco verde' },
    { Clave: '10311504', Descripcion: 'Anturio cortado fresco rosado fuerte' },
    { Clave: '10311505', Descripcion: 'Anturio cortado fresco mickey mouse' },
    { Clave: '10311506', Descripcion: 'Anturio cortado fresco obake verde y blanco' },
    { Clave: '10311507', Descripcion: 'Anturio cortado fresco obake rojo y verde' },
    { Clave: '10311508', Descripcion: 'Anturio cortado fresco anaranjado' },
    { Clave: '10311509', Descripcion: 'Anturio cortado fresco durazno' },
    { Clave: '10311510', Descripcion: 'Anturio cortado fresco picasso o pecoso' },
    { Clave: '10311511', Descripcion: 'Anturio cortado fresco rojo' },
    { Clave: '10311512', Descripcion: 'Anturio cortado fresco splash' },
    { Clave: '10311513', Descripcion: 'Anturio cortado fresco tropic fire' },
    { Clave: '10311514', Descripcion: 'Anturio cortado fresco verde tulipán' },
    { Clave: '10311515', Descripcion: 'Anturio cortado fresco rosado tulipán' },
    { Clave: '10311516', Descripcion: 'Anturio cortado fresco púrpura tulipán' },
    { Clave: '10311517', Descripcion: 'Anturio cortado fresco rojo tulipán' },
    { Clave: '10311518', Descripcion: 'Anturio cortado fresco blanco' },
    { Clave: '10311519', Descripcion: 'Anturio cortado fresco blanco salvaje' },
    { Clave: '10311600', Descripcion: 'Allium cortado fresco' },
    { Clave: '10311601', Descripcion: 'Allium cortado fresco ambassador' },
    { Clave: '10311602', Descripcion: 'Allium cortado fresco ampeloprasum' },
    { Clave: '10311603', Descripcion: 'Allium cortado fresco bullit o drumstick' },
    { Clave: '10311604', Descripcion: 'Allium cortado fresco christophii' },
    { Clave: '10311605', Descripcion: 'Allium cortado fresco cowanii' },
    { Clave: '10311606', Descripcion: 'Allium cortado fresco gigante' },
    { Clave: '10311607', Descripcion: 'Allium cortado fresco gladiador' },
    { Clave: '10311608', Descripcion: 'Allium cortado fresco globemaster' },
    { Clave: '10311609', Descripcion: 'Allium cortado fresco bola de golf' },
    { Clave: '10311610', Descripcion: 'Allium cortado fresco cabello' },
    { Clave: '10311611', Descripcion: 'Allium cortado fresco rosado gigante' },
    { Clave: '10311612', Descripcion: 'Allium cortado fresco sensación púrpura' },
    { Clave: '10311613', Descripcion: 'Allium cortado fresco sicilum hanging' },
    { Clave: '10311614', Descripcion: 'Allium cortado fresco spider schubertii' },
    { Clave: '10311615', Descripcion: 'Allium cortado fresco spray moly' },
    { Clave: '10311616', Descripcion: 'Allium cortado fresco spray roseum' },
    { Clave: '10311617', Descripcion: 'Allium cortado fresco tuberosum' },
    { Clave: '10311618', Descripcion: 'Allium cortado fresco unifolium o spray' },
    { Clave: '10311619', Descripcion: 'Allium cortado fresco monte everest blanco' },
    { Clave: '10311700', Descripcion: 'Astromelia cortada fresca' },
    { Clave: '10311701', Descripcion: 'Astromelia cortada fresca agropoli' },
    { Clave: '10311702', Descripcion: 'Astromelia cortada fresca bourgogne' },
    { Clave: '10311703', Descripcion: 'Astromelia cortada fresca cairo' },
    { Clave: '10311704', Descripcion: 'Astromelia cortada fresca charmes' },
    { Clave: '10311705', Descripcion: 'Astromelia cortada fresca cherry bay' },
    { Clave: '10311706', Descripcion: 'Astromelia cortada fresca cherry white' },
    { Clave: '10311707', Descripcion: 'Astromelia cortada fresca dame blanche' },
    { Clave: '10311708', Descripcion: 'Astromelia cortada fresca diamond' },
    { Clave: '10311709', Descripcion: 'Astromelia cortada fresca gran canaria' },
    { Clave: '10311710', Descripcion: 'Astromelia cortada fresca harlekijn' },
    { Clave: '10311711', Descripcion: 'Astromelia cortada fresca indian summer' },
    { Clave: '10311712', Descripcion: 'Astromelia cortada fresca jamaica' },
    { Clave: '10311713', Descripcion: 'Astromelia cortada fresca macondo' },
    { Clave: '10311714', Descripcion: 'Astromelia cortada fresca mistique' },
    { Clave: '10311715', Descripcion: 'Astromelia cortada fresca my fair' },
    { Clave: '10311716', Descripcion: 'Astromelia cortada fresca new cairo' },
    { Clave: '10311717', Descripcion: 'Astromelia cortada fresca nice' },
    { Clave: '10311718', Descripcion: 'Astromelia cortada fresca orange bowl' },
    { Clave: '10311719', Descripcion: 'Astromelia cortada fresca orange queens' },
    { Clave: '10311720', Descripcion: 'Astromelia cortada fresca orange sun' },
    { Clave: '10311721', Descripcion: 'Astromelia cortada fresca paris' },
    { Clave: '10311722', Descripcion: 'Astromelia cortada fresca picasso' },
    { Clave: '10311723', Descripcion: 'Astromelia cortada fresca pink panther' },
    { Clave: '10311724', Descripcion: 'Astromelia cortada fresca prima donna' },
    { Clave: '10311725', Descripcion: 'Astromelia cortada fresca red silhouette' },
    { Clave: '10311726', Descripcion: 'Astromelia cortada fresca sacha' },
    { Clave: '10311727', Descripcion: 'Astromelia cortada fresca salmón' },
    { Clave: '10311728', Descripcion: 'Astromelia cortada fresca santiago' },
    { Clave: '10311729', Descripcion: 'Astromelia cortada fresca senna' },
    { Clave: '10311730', Descripcion: 'Astromelia cortada fresca snowball' },
    { Clave: '10311731', Descripcion: 'Astromelia cortada fresca sublime' },
    { Clave: '10311732', Descripcion: 'Astromelia cortada fresca tropicana' },
    { Clave: '10311733', Descripcion: 'Astromelia cortada fresca virginia' },
    { Clave: '10311734', Descripcion: 'Astromelia cortada fresca blanca' },
    { Clave: '10311800', Descripcion: 'Amaranto cortado fresco' },
    { Clave: '10311801', Descripcion: 'Amaranto cortado fresco verde colgante' },
    { Clave: '10311802', Descripcion: 'Amaranto cortado fresco rojo colgante' },
    { Clave: '10311803', Descripcion: 'Amaranto cortado fresco bronce erguido' },
    { Clave: '10311804', Descripcion: 'Amaranto cortado fresco verde erguido' },
    { Clave: '10311805', Descripcion: 'Amaranto cortado fresco rojo erguido' },
    { Clave: '10311900', Descripcion: 'Amaryllis cortado fresco' },
    { Clave: '10311901', Descripcion: 'Amaryllis cortado fresco naranja' },
    { Clave: '10311902', Descripcion: 'Amaryllis cortado fresco orange nagano' },
    { Clave: '10311903', Descripcion: 'Amaryllis cortado fresco pigmeo mini' },
    { Clave: '10311904', Descripcion: 'Amaryllis cortado fresco red lion' },
    { Clave: '10311905', Descripcion: 'Amaryllis cortado fresco rilona' },
    { Clave: '10311906', Descripcion: 'Amaryllis cortado fresco royal velvet' },
    { Clave: '10311907', Descripcion: 'Amaryllis cortado fresco sonatini naranja' },
    { Clave: '10311908', Descripcion: 'Amaryllis cortado fresco sonatini rojo' },
    { Clave: '10311909', Descripcion: 'Amaryllis cortado fresco tango' },
    { Clave: '10311910', Descripcion: 'Amaryllis cortado fresco tinto night' },
    { Clave: '10312000', Descripcion: 'Anémona cortada fresca' },
    { Clave: '10312001', Descripcion: 'Anémona cortada fresca aubergine' },
    { Clave: '10312002', Descripcion: 'Anémona cortada fresca negra' },
    { Clave: '10312003', Descripcion: 'Anémona cortada fresca azul' },
    { Clave: '10312004', Descripcion: 'Anémona cortada fresca cereza' },
    { Clave: '10312005', Descripcion: 'Anémona cortada fresca coronaria' },
    { Clave: '10312006', Descripcion: 'Anémona cortada fresca rosado fuerte' },
    { Clave: '10312007', Descripcion: 'Anémona cortada fresca rosado claro' },
    { Clave: '10312008', Descripcion: 'Anémona cortada fresca rosado' },
    { Clave: '10312009', Descripcion: 'Anémona cortada fresca púrpura' },
    { Clave: '10312010', Descripcion: 'Anémona cortada fresca roja' },
    { Clave: '10312011', Descripcion: 'Anémona cortada fresca blanca' },
    { Clave: '10312100', Descripcion: 'Asclepia cortada fresca' },
    { Clave: '10312101', Descripcion: 'Asclepia cortada fresca lavanda' },
    { Clave: '10312102', Descripcion: 'Asclepia cortada fresca moby dick' },
    { Clave: '10312103', Descripcion: 'Asclepia cortada fresca tuberosa' },
    { Clave: '10312104', Descripcion: 'Asclepia cortada fresca blanca' },
    { Clave: '10312200', Descripcion: 'Aster cortada fresca' },
    { Clave: '10312201', Descripcion: 'Aster cortada fresca beauty' },
    { Clave: '10312202', Descripcion: 'Aster cortada fresca japonés azul' },
    { Clave: '10312203', Descripcion: 'Aster cortada fresca japonés verde' },
    { Clave: '10312204', Descripcion: 'Aster cortada fresca japonés rosado fuerte' },
    { Clave: '10312205', Descripcion: 'Aster cortada fresca japonés lavanda' },
    { Clave: '10312206', Descripcion: 'Aster cortada fresca japonés rosado claro' },
    { Clave: '10312207', Descripcion: 'Aster cortada fresca japonés durazno' },
    { Clave: '10312208', Descripcion: 'Aster cortada fresca japonés rosado' },
    { Clave: '10312209', Descripcion: 'Aster cortada fresca japonés púrpura' },
    { Clave: '10312210', Descripcion: 'Aster cortada fresca japonés rojo' },
    { Clave: '10312211', Descripcion: 'Aster cortada fresca japonés spider' },
    { Clave: '10312212', Descripcion: 'Aster cortada fresca japonés  blanco' },
    { Clave: '10312213', Descripcion: 'Aster cortada fresca novi belgii rosado fuerte' },
    { Clave: '10312214', Descripcion: 'Aster cortada fresca novi belgii lavanda' },
    { Clave: '10312215', Descripcion: 'Aster cortada fresca novi belgii rosado' },
    { Clave: '10312216', Descripcion: 'Aster cortada fresca novi belgii púrpura' },
    { Clave: '10312217', Descripcion: 'Aster cortada fresca novi belgii blanco' },
    { Clave: '10312218', Descripcion: 'Aster cortada fresca solidago' },
    { Clave: '10312219', Descripcion: 'Aster cortada fresca spider' },
    { Clave: '10312300', Descripcion: 'Berzelia cortada fresca' },
    { Clave: '10312301', Descripcion: 'Berzelia lanuginosa cortada fresca abrotnoides' },
    { Clave: '10312302', Descripcion: 'Berzelia lanuginosa cortada fresca fireball' },
    { Clave: '10312303', Descripcion: 'Berzelia lanuginosa cortada fresca galpinii' },
    { Clave: '10312304', Descripcion: 'Berzelia lanuginosa cortada fresca galpini o baubles' },
    { Clave: '10312305', Descripcion: 'Berzelia lanuginosa cortada fresca squarrosa' },
    { Clave: '10312400', Descripcion: 'Bouvaria cortada fresca' },
    { Clave: '10312401', Descripcion: 'Bouvardia cortada fresca doble rosado fuerte' },
    { Clave: '10312402', Descripcion: 'Bouvardia cortada fresca rosado claro' },
    { Clave: '10312403', Descripcion: 'Bouvardia cortada fresca doble rosado claro' },
    { Clave: '10312404', Descripcion: 'Bouvardia cortada fresca roja' },
    { Clave: '10312405', Descripcion: 'Bouvardia cortada fresca blanca' },
    { Clave: '10312500', Descripcion: 'Brodiaea cortada fresca' },
    { Clave: '10312501', Descripcion: 'Brodiaea cortada fresca congesta' },
    { Clave: '10312502', Descripcion: 'Brodiaea cortada fresca congesta lavanda' },
    { Clave: '10312503', Descripcion: 'Brodiaea cortada fresca jacinta' },
    { Clave: '10312504', Descripcion: 'Brodiaea cortada fresca ida maija' },
    { Clave: '10312505', Descripcion: 'Brodiaea cortada fresca starlight' },
    { Clave: '10312600', Descripcion: 'Calla cortada fresca' },
    { Clave: '10312601', Descripcion: 'Calla cortada fresca green goddess' },
    { Clave: '10312602', Descripcion: 'Calla cortada fresca ramillete albertville' },
    { Clave: '10312603', Descripcion: 'Calla cortada fresca ramillete aranal' },
    { Clave: '10312604', Descripcion: 'Calla cortada fresca ramillete black eyed beauty' },
    { Clave: '10312605', Descripcion: 'Calla cortada fresca black star' },
    { Clave: '10312606', Descripcion: 'Calla cortada fresca brisbane' },
    { Clave: '10312607', Descripcion: 'Calla cortada fresca crystal blush' },
    { Clave: '10312608', Descripcion: 'Calla cortada fresca crystal pink' },
    { Clave: '10312609', Descripcion: 'Calla cortada fresca crystal white' },
    { Clave: '10312610', Descripcion: 'Calla cortada fresca dark captain romanc' },
    { Clave: '10312611', Descripcion: 'Calla cortada fresca ramillete dark mozart' },
    { Clave: '10312612', Descripcion: 'Calla cortada fresca ramillete dark naomi' },
    { Clave: '10312613', Descripcion: 'Calla cortada fresca ramillete deformed calla' },
    { Clave: '10312614', Descripcion: 'Calla cortada fresca ramillete dordogne' },
    { Clave: '10312615', Descripcion: 'Calla cortada fresca ramillete etude' },
    { Clave: '10312616', Descripcion: 'Calla cortada fresca ramillete farao' },
    { Clave: '10312617', Descripcion: 'Calla cortada fresca ramillete fire glow' },
    { Clave: '10312618', Descripcion: 'Calla cortada fresca ramillete florex gold' },
    { Clave: '10312619', Descripcion: 'Calla cortada fresca ramillete garnet glow' },
    { Clave: '10312620', Descripcion: 'Calla cortada fresca ramillete hot chocolate' },
    { Clave: '10312621', Descripcion: 'Calla cortada fresca ramillete lavender improved' },
    { Clave: '10312622', Descripcion: 'Calla cortada fresca ramillete light cromance' },
    { Clave: '10312623', Descripcion: 'Calla cortada fresca ramillete little suzy' },
    { Clave: '10312624', Descripcion: 'Calla cortada fresca ramillete majestic red' },
    { Clave: '10312625', Descripcion: 'Calla cortada fresca ramillete mango' },
    { Clave: '10312626', Descripcion: 'Calla cortada fresca ramillete merlot' },
    { Clave: '10312627', Descripcion: 'Calla cortada fresca ramillete mozart' },
    { Clave: '10312628', Descripcion: 'Calla cortada fresca ramillete naomi' },
    { Clave: '10312629', Descripcion: 'Calla cortada fresca ramillete night cap' },
    { Clave: '10312630', Descripcion: 'Calla cortada fresca ramillete odessa' },
    { Clave: '10312631', Descripcion: 'Calla cortada fresca ramillete pacific pink' },
    { Clave: '10312632', Descripcion: 'Calla cortada fresca ramillete passion fruit' },
    { Clave: '10312633', Descripcion: 'Calla cortada fresca ramillete picasso' },
    { Clave: '10312634', Descripcion: 'Calla cortada fresca ramillete pillow talk' },
    { Clave: '10312635', Descripcion: 'Calla cortada fresca ramillete pink persuation' },
    { Clave: '10312636', Descripcion: 'Calla cortada fresca ramillete pisa' },
    { Clave: '10312637', Descripcion: 'Calla cortada fresca ramillete pot of' },
    { Clave: '10312638', Descripcion: 'Calla cortada fresca ramillete red sox' },
    { Clave: '10312639', Descripcion: 'Calla cortada fresca ramillete rosa' },
    { Clave: '10312640', Descripcion: 'Calla cortada fresca ramillete ruby light rose' },
    { Clave: '10312641', Descripcion: 'Calla cortada fresca ramillete samur' },
    { Clave: '10312642', Descripcion: 'Calla cortada fresca ramillete sapporo' },
    { Clave: '10312643', Descripcion: 'Calla cortada fresca ramillete schwarzwalder' },
    { Clave: '10312644', Descripcion: 'Calla cortada fresca ramillete serrada' },
    { Clave: '10312645', Descripcion: 'Calla cortada fresca ramillete solemio' },
    { Clave: '10312646', Descripcion: 'Calla cortada fresca ramillete sunrise' },
    { Clave: '10312647', Descripcion: 'Calla cortada fresca ramillete super mac' },
    { Clave: '10312648', Descripcion: 'Calla cortada fresca ramillete swan lake' },
    { Clave: '10312649', Descripcion: 'Calla cortada fresca ramillete vermeer' },
    { Clave: '10312650', Descripcion: 'Calla cortada fresca ramillete white butterfly' },
    { Clave: '10312651', Descripcion: 'Calla cortada fresca ramillete amarilla' },
    { Clave: '10312652', Descripcion: 'Calla cortada fresca ramillete yellow mozart' },
    { Clave: '10312653', Descripcion: 'Calla cortada fresca ramillete white large' },
    { Clave: '10312800', Descripcion: 'Celosia cortada fresca' },
    { Clave: '10312801', Descripcion: 'Cresta de gallo (celosia) cortada fresca verde' },
    { Clave: '10312802', Descripcion: 'Cresta de gallo (celosia) cortada fresca anaranjado' },
    { Clave: '10312803', Descripcion: 'Cresta de gallo (celosia) cortada fresca rosado' },
    { Clave: '10312804', Descripcion: 'Cresta de gallo (celosia) cortada fresca púrpura' },
    { Clave: '10312805', Descripcion: 'Cresta de gallo (celosia) cortada fresca rojo' },
    { Clave: '10312806', Descripcion: 'Cresta de gallo (celosia) cortada fresca amarillo' },
    { Clave: '10312807', Descripcion: 'Cresta de gallo (celosia) cortada fresca plume light' },
    { Clave: '10312808', Descripcion: 'Cresta de gallo (celosia) cortada fresca plume orange' },
    { Clave: '10312809', Descripcion: 'Cresta de gallo (celosia) cortada fresca plume purple' },
    { Clave: '10312810', Descripcion: 'Cresta de gallo (celosia) cortada fresca plume red' },
    { Clave: '10312811', Descripcion: 'Cresta de gallo (celosia) cortada fresca plume yellow' },
    { Clave: '10312812', Descripcion: 'Cresta de gallo (celosia) cortada fresca wheat pink' },
    { Clave: '10312813', Descripcion: 'Cresta de gallo (celosia) cortada fresca wheat yellow' },
    { Clave: '10312900', Descripcion: 'Narciso cortado fresco' },
    { Clave: '10312901', Descripcion: 'Narciso cortado fresco dick wilden' },
    { Clave: '10312902', Descripcion: 'Narciso cortado fresco dutch master' },
    { Clave: '10312903', Descripcion: 'Narciso cortado fresco ice follies' },
    { Clave: '10312904', Descripcion: 'Narciso cortado fresco ice king' },
    { Clave: '10312905', Descripcion: 'Narciso cortado fresco johan strauss' },
    { Clave: '10312906', Descripcion: 'Narciso cortado fresco yellow carlton' },
    { Clave: '10313000', Descripcion: 'Dalia cortada fresca' },
    { Clave: '10313001', Descripcion: 'Dalia cortada fresca bicolor' },
    { Clave: '10313002', Descripcion: 'Dalia cortada fresca rosado fuerte' },
    { Clave: '10313003', Descripcion: 'Dalia cortada fresca rosado claro' },
    { Clave: '10313004', Descripcion: 'Dalia cortada fresca rosado medio' },
    { Clave: '10313005', Descripcion: 'Dalia cortada fresca anaranjado' },
    { Clave: '10313006', Descripcion: 'Dalia cortada fresca durazno' },
    { Clave: '10313007', Descripcion: 'Dalia cortada fresca púrpura' },
    { Clave: '10313008', Descripcion: 'Dalia cortada fresca roja' },
    { Clave: '10313009', Descripcion: 'Dalia cortada fresca blanca' },
    { Clave: '10313010', Descripcion: 'Dalia cortada fresca amarilla' },
    { Clave: '10313100', Descripcion: 'Delphinium cortado fresco' },
    { Clave: '10313101', Descripcion: 'Delphinium cortado fresco bella azul oscuro' },
    { Clave: '10313102', Descripcion: 'Delphinium cortado fresco bella azul claro' },
    { Clave: '10313103', Descripcion: 'Delphinium cortado fresco bella blanco' },
    { Clave: '10313104', Descripcion: 'Delphinium cortado fresco azul sombra' },
    { Clave: '10313105', Descripcion: 'Delphinium cortado fresco híbrido azul oscuro' },
    { Clave: '10313106', Descripcion: 'Delphinium cortado fresco híbrido azul claro' },
    { Clave: '10313107', Descripcion: 'Delphinium cortado fresco híbrido malva' },
    { Clave: '10313108', Descripcion: 'Delphinium cortado fresco híbrido rosado' },
    { Clave: '10313109', Descripcion: 'Delphinium cortado fresco híbrido púrpura' },
    { Clave: '10313110', Descripcion: 'Delphinium cortado fresco híbrido rojo' },
    { Clave: '10313111', Descripcion: 'Delphinium cortado fresco híbrido blanco' },
    { Clave: '10313112', Descripcion: 'Delphinium cortado fresco princesa carolina' },
    { Clave: '10313113', Descripcion: 'Delphinium cortado fresco volkerfrieden' },
    { Clave: '10313200', Descripcion: 'Clavel (dianthus) cortado fresco' },
    { Clave: '10313201', Descripcion: 'Clavel (dianthus) cortado fresco chocolate' },
    { Clave: '10313202', Descripcion: 'Clavel (dianthus) cortado fresco fucsia' },
    { Clave: '10313203', Descripcion: 'Clavel (dianthus) cortado fresco bola verde' },
    { Clave: '10313204', Descripcion: 'Clavel (dianthus) cortado fresco rosado fuerte' },
    { Clave: '10313205', Descripcion: 'Clavel (dianthus) cortado fresco lavanda' },
    { Clave: '10313206', Descripcion: 'Clavel (dianthus) cortado fresco frambuesa' },
    { Clave: '10313207', Descripcion: 'Clavel (dianthus) cortado fresco rojo' },
    { Clave: '10313208', Descripcion: 'Clavel (dianthus) cortado fresco rosado' },
    { Clave: '10313300', Descripcion: 'Eremruses cortadas frescas' },
    { Clave: '10313301', Descripcion: 'Eremurus cortado fresco deruyter híbrido' },
    { Clave: '10313302', Descripcion: 'Eremurus cortado fresco himalaicus blanco' },
    { Clave: '10313303', Descripcion: 'Eremurus cortado fresco anaranjado' },
    { Clave: '10313304', Descripcion: 'Eremurus cortado fresco durazno' },
    { Clave: '10313305', Descripcion: 'Eremurus cortado fresco amarillo' },
    { Clave: '10313400', Descripcion: 'Brezo (erica) cortada fresca' },
    { Clave: '10313401', Descripcion: 'Brezo (erica) cortado fresco campunalarus' },
    { Clave: '10313402', Descripcion: 'Brezo (erica) cortado fresco conica' },
    { Clave: '10313403', Descripcion: 'Brezo (erica) cortado fresco green ice' },
    { Clave: '10313404', Descripcion: 'Brezo (erica) cortado fresco rosado' },
    { Clave: '10313405', Descripcion: 'Brezo (erica) cortado fresco príncipe de gales' },
    { Clave: '10313500', Descripcion: 'Euphorbia cortada fresca' },
    { Clave: '10313501', Descripcion: 'Euphorbia cortada fresca characias' },
    { Clave: '10313502', Descripcion: 'Euphorbia cortada fresca griffithii fireglow' },
    { Clave: '10313503', Descripcion: 'Euphorbia cortada fresca martini' },
    { Clave: '10313504', Descripcion: 'Euphorbia cortada fresca anaranjada' },
    { Clave: '10313505', Descripcion: 'Euphorbia cortada fresca durazno' },
    { Clave: '10313506', Descripcion: 'Euphorbia cortada fresca rosada' },
    { Clave: '10313507', Descripcion: 'Euphorbia cortada fresca roja' },
    { Clave: '10313508', Descripcion: 'Euphorbia cortada fresca blanca' },
    { Clave: '10313509', Descripcion: 'Euphorbia cortada fresca amarilla' },
    { Clave: '10313510', Descripcion: 'Euphorbia cortada fresca amarilla spurge' },
    { Clave: '10313600', Descripcion: 'Fresia cortada fresca' },
    { Clave: '10313601', Descripcion: 'Fresia cortada fresca crema' },
    { Clave: '10313602', Descripcion: 'Fresia cortada fresca doble blanca' },
    { Clave: '10313603', Descripcion: 'Fresia cortada fresca doble amarilla' },
    { Clave: '10313604', Descripcion: 'Fresia cortada fresca rosado fuerte' },
    { Clave: '10313605', Descripcion: 'Fresia cortada fresca lady brunet' },
    { Clave: '10313606', Descripcion: 'Fresia cortada fresca lavanda' },
    { Clave: '10313607', Descripcion: 'Fresia cortada fresca rosado medio' },
    { Clave: '10313608', Descripcion: 'Fresia cortada fresca anaranjado' },
    { Clave: '10313609', Descripcion: 'Fresia cortada fresca pimpinela' },
    { Clave: '10313610', Descripcion: 'Fresia cortada fresca rosada' },
    { Clave: '10313611', Descripcion: 'Fresia cortada fresca pùrpura' },
    { Clave: '10313612', Descripcion: 'Fresia cortada fresca roja' },
    { Clave: '10313613', Descripcion: 'Fresia cortada fresca blanca' },
    { Clave: '10313614', Descripcion: 'Fresia cortada fresca amarilla' },
    { Clave: '10313700', Descripcion: 'Ajedrezada cortada fresca' },
    { Clave: '10313701', Descripcion: 'Ajedrezada cortada fresca acmopelata' },
    { Clave: '10313702', Descripcion: 'Ajedrezada cortada fresca assyriaca' },
    { Clave: '10313703', Descripcion: 'Ajedrezada cortada fresca assyriaca uva vulpis' },
    { Clave: '10313704', Descripcion: 'Ajedrezada cortada fresca elysee' },
    { Clave: '10313705', Descripcion: 'Ajedrezada cortada fresca imperialis anaranjada' },
    { Clave: '10313706', Descripcion: 'Ajedrezada cortada fresca imperialis amarilla' },
    { Clave: '10313707', Descripcion: 'Ajedrezada cortada fresca meleagris' },
    { Clave: '10313708', Descripcion: 'Ajedrezada cortada fresca michailowski' },
    { Clave: '10313709', Descripcion: 'Ajedrezada cortada fresca uva vulpis' },
    { Clave: '10313800', Descripcion: 'Hiniesta cortada fresca' },
    { Clave: '10313801', Descripcion: 'Hiniesta cortada fresca verde' },
    { Clave: '10313802', Descripcion: 'Hiniesta cortada fresca rosado fuerte' },
    { Clave: '10313803', Descripcion: 'Hiniesta cortada fresca lavanda' },
    { Clave: '10313804', Descripcion: 'Hiniesta cortada fresca rosado claro' },
    { Clave: '10313805', Descripcion: 'Hiniesta cortada fresca durazno' },
    { Clave: '10313806', Descripcion: 'Hiniesta cortada fresca púrpura' },
    { Clave: '10313807', Descripcion: 'Hiniesta cortada fresca blanco' },
    { Clave: '10313808', Descripcion: 'Hiniesta cortada fresca amarillo' },
    { Clave: '10313900', Descripcion: 'Margarita cortada fresca' },
    { Clave: '10313901', Descripcion: 'Margarita cortada fresca crema de centro negro' },
    { Clave: '10313902', Descripcion: 'Margarita cortada fresca crema' },
    { Clave: '10313903', Descripcion: 'Margarita cortada fresca dorada' },
    { Clave: '10313904', Descripcion: 'Margarita cortada fresca rosado fuerte' },
    { Clave: '10313905', Descripcion: 'Margarita cortada fresca rosado claro' },
    { Clave: '10313906', Descripcion: 'Margarita cortada fresca magenta' },
    { Clave: '10313907', Descripcion: 'Margarita cortada fresca mini coral' },
    { Clave: '10313908', Descripcion: 'Margarita cortada fresca mini fucsia' },
    { Clave: '10313909', Descripcion: 'Margarita cortada fresca mini rosado fuerte' },
    { Clave: '10313910', Descripcion: 'Margarita cortada fresca mini anaranjado claro de centro negro' },
    { Clave: '10313911', Descripcion: 'Margarita cortada fresca mini anaranjado de centro negro' },
    { Clave: '10313912', Descripcion: 'Margarita cortada fresca mini anaranjado' },
    { Clave: '10313913', Descripcion: 'Margarita cortada fresca mini rojo de centro negro' },
    { Clave: '10313914', Descripcion: 'Margarita cortada fresca mini blanco' },
    { Clave: '10313915', Descripcion: 'Margarita cortada fresca mini amarillo claro de centro negro' },
    { Clave: '10313916', Descripcion: 'Margarita cortada fresca anaranjado de centro negro' },
    { Clave: '10313917', Descripcion: 'Margarita cortada fresca anaranjada' },
    { Clave: '10313918', Descripcion: 'Margarita cortada fresca durazno de centro negro' },
    { Clave: '10313919', Descripcion: 'Margarita cortada fresca durazno' },
    { Clave: '10313920', Descripcion: 'Margarita cortada fresca rosada de centro negro' },
    { Clave: '10313921', Descripcion: 'Margarita cortada fresca rosada' },
    { Clave: '10313922', Descripcion: 'Margarita cortada fresca roja de centro negro' },
    { Clave: '10313923', Descripcion: 'Margarita cortada fresca roja' },
    { Clave: '10313924', Descripcion: 'Margarita cortada fresca spider durazno' },
    { Clave: '10313925', Descripcion: 'Margarita cortada fresca spider roja' },
    { Clave: '10313926', Descripcion: 'Margarita cortada fresca terracota' },
    { Clave: '10313927', Descripcion: 'Margarita cortada fresca blanca de centro negro' },
    { Clave: '10313928', Descripcion: 'Margarita cortada fresca blanca' },
    { Clave: '10313929', Descripcion: 'Margarita cortada fresca amarilla' },
    { Clave: '10314000', Descripcion: 'Jengibre cortado fresco' },
    { Clave: '10314001', Descripcion: 'Jengibre cortado fresco  indonesio' },
    { Clave: '10314002', Descripcion: 'Jengibre cortado fresco jungle king rosado' },
    { Clave: '10314003', Descripcion: 'Jengibre cortado fresco jungle king rojo' },
    { Clave: '10314004', Descripcion: 'Jengibre cortado fresco rosado' },
    { Clave: '10314005', Descripcion: 'Jengibre cortado fresco rojo' },
    { Clave: '10314006', Descripcion: 'Jengibre cortado fresco torch' },
    { Clave: '10314100', Descripcion: 'Gladiolo cortado fresco' },
    { Clave: '10314101', Descripcion: 'Gladiolo cortado fresco burgundy' },
    { Clave: '10314102', Descripcion: 'Gladiolo cortado fresco fucsia' },
    { Clave: '10314103', Descripcion: 'Gladiolo cortado fresco verde' },
    { Clave: '10314104', Descripcion: 'Gladiolo cortado fresco rosado fuerte' },
    { Clave: '10314105', Descripcion: 'Gladiolo cortado fresco rosado claro' },
    { Clave: '10314106', Descripcion: 'Gladiolo cortado fresco anaranjado' },
    { Clave: '10314107', Descripcion: 'Gladiolo cortado fresco durazno' },
    { Clave: '10314108', Descripcion: 'Gladiolo cortado fresco rosado medio' },
    { Clave: '10314109', Descripcion: 'Gladiolo cortado fresco púrpura' },
    { Clave: '10314110', Descripcion: 'Gladiolo cortado fresco rojo bi ? color' },
    { Clave: '10314111', Descripcion: 'Gladiolo cortado fresco rojo' },
    { Clave: '10314112', Descripcion: 'Gladiolo cortado fresco salmón' },
    { Clave: '10314113', Descripcion: 'Gladiolo cortado fresco blanco' },
    { Clave: '10314114', Descripcion: 'Gladiolo cortado fresco amarillo' },
    { Clave: '10314200', Descripcion: 'Godetia cortada fresca' },
    { Clave: '10314201', Descripcion: 'Godetia cortada fresca bicolor' },
    { Clave: '10314202', Descripcion: 'Godetia cortada fresca fucsia' },
    { Clave: '10314203', Descripcion: 'Godetia cortada fresca rosado fuerte' },
    { Clave: '10314204', Descripcion: 'Godetia cortada fresca anaranjada' },
    { Clave: '10314205', Descripcion: 'Godetia cortada fresca roja' },
    { Clave: '10314206', Descripcion: 'Godetia cortada fresca blanca' },
    { Clave: '10314300', Descripcion: 'Bromelia cortada fresca' },
    { Clave: '10314301', Descripcion: 'Bromelia lingulata cortada fresca anaranjada' },
    { Clave: '10314302', Descripcion: 'Bromelia lingulata cortada fresca roja' },
    { Clave: '10314303', Descripcion: 'Bromelia lingulata cortada fresca blanca' },
    { Clave: '10314304', Descripcion: 'Bromelia lingulata cortada fresca amarilla' },
    { Clave: '10314305', Descripcion: 'Bromelia variegata cortada fresca' },
    { Clave: '10314400', Descripcion: 'Gypsophilia cortada fresca' },
    { Clave: '10314401', Descripcion: 'Gypsophilia cortada fresca bambino' },
    { Clave: '10314402', Descripcion: 'Gypsophilia cortada fresca million stars' },
    { Clave: '10314403', Descripcion: 'Gypsophilia cortada fresca mirabella' },
    { Clave: '10314404', Descripcion: 'Gypsophilia cortada fresca new love' },
    { Clave: '10314405', Descripcion: 'Gypsophilia cortada fresca orión' },
    { Clave: '10314406', Descripcion: 'Gypsophilia cortada fresca perfecta' },
    { Clave: '10314500', Descripcion: 'Brezo cortado fresco' },
    { Clave: '10314501', Descripcion: 'Brezo cortado fresco augustine' },
    { Clave: '10314502', Descripcion: 'Brezo cortado fresco érica cuatro hermanas' },
    { Clave: '10314503', Descripcion: 'Brezo cortado fresco francés' },
    { Clave: '10314504', Descripcion: 'Brezo cortado fresco verde' },
    { Clave: '10314505', Descripcion: 'Brezo corte fresco sterling range blanco' },
    { Clave: '10314506', Descripcion: 'Brezo cortado fresco rosado sunset' },
    { Clave: '10314507', Descripcion: 'Brezo cortado fresco blanco' },
    { Clave: '10314600', Descripcion: 'Heliconia cortada fresca' },
    { Clave: '10314601', Descripcion: 'Heliconia cortada fresca bihai claw' },
    { Clave: '10314602', Descripcion: 'Heliconia cortada fresca bihai flash' },
    { Clave: '10314603', Descripcion: 'Heliconia cortada fresca bihai lobster claw' },
    { Clave: '10314604', Descripcion: 'Heliconia cortada fresca caribea red' },
    { Clave: '10314605', Descripcion: 'Heliconia cortada fresca caribea yellow' },
    { Clave: '10314606', Descripcion: 'Heliconia cortada fresca christmas' },
    { Clave: '10314607', Descripcion: 'Heliconia cortada fresca edge of night' },
    { Clave: '10314608', Descripcion: 'Heliconia cortada fresca green bihai' },
    { Clave: '10314609', Descripcion: 'Heliconia cortada fresca marginata lutea' },
    { Clave: '10314610', Descripcion: 'Heliconia cortada fresca psitt fire opal' },
    { Clave: '10314611', Descripcion: 'Heliconia cortada fresca psittacorum' },
    { Clave: '10314612', Descripcion: 'Heliconia cortada fresca richmond red' },
    { Clave: '10314613', Descripcion: 'Heliconia cortada fresca rostrata' },
    { Clave: '10314614', Descripcion: 'Heliconia cortada fresca sexy pink' },
    { Clave: '10314615', Descripcion: 'Heliconia cortada fresca sexy scarlett' },
    { Clave: '10314616', Descripcion: 'Heliconia cortada fresca shogun' },
    { Clave: '10314617', Descripcion: 'Heliconia cortada fresca small red' },
    { Clave: '10314618', Descripcion: 'Heliconia cortada fresca southern cross' },
    { Clave: '10314619', Descripcion: 'Heliconia cortada fresca wagneriana' },
    { Clave: '10314700', Descripcion: 'Jacinto cortado fresco' },
    { Clave: '10314701', Descripcion: 'Jacinto cortado fresco bean' },
    { Clave: '10314702', Descripcion: 'Jacinto cortado fresco durazno' },
    { Clave: '10314703', Descripcion: 'Jacinto cortado fresco azul' },
    { Clave: '10314704', Descripcion: 'Jacinto cortado fresco fucsia' },
    { Clave: '10314705', Descripcion: 'Jacinto cortado fresco rosado fuerte' },
    { Clave: '10314706', Descripcion: 'Jacinto cortado fresco lavanda' },
    { Clave: '10314707', Descripcion: 'Jacinto cortado fresco azul claro' },
    { Clave: '10314708', Descripcion: 'Jacinto cortado fresco rosado medio' },
    { Clave: '10314709', Descripcion: 'Jacinto cortado fresco rosado' },
    { Clave: '10314710', Descripcion: 'Jacinto cortado fresco estrella púrpura' },
    { Clave: '10314711', Descripcion: 'Jacinto cortado fresco blanco' },
    { Clave: '10314712', Descripcion: 'Jacinto cortado fresco amarillo' },
    { Clave: '10314800', Descripcion: 'Hydrangea cortada fresca' },
    { Clave: '10314801', Descripcion: 'Hydrangea cortada fresca anabella' },
    { Clave: '10314802', Descripcion: 'Hydrangea cortada fresca azul antiguo' },
    { Clave: '10314803', Descripcion: 'Hydrangea cortada fresca azul antiguo  o verde o nueva zelandia' },
    { Clave: '10314804', Descripcion: 'Hydrangea cortada fresca verde antiguo' },
    { Clave: '10314805', Descripcion: 'Hydrangea cortada fresca rosado antiguo' },
    { Clave: '10314806', Descripcion: 'Hydrangea cortada fresca púrpura antiguo o nueva zelandia' },
    { Clave: '10314807', Descripcion: 'Hydrangea cortada fresca aubergene o nueva zelandia' },
    { Clave: '10314808', Descripcion: 'Hydrangea cortada fresca azul oscuro' },
    { Clave: '10314809', Descripcion: 'Hydrangea cortada fresca rosado oscuro' },
    { Clave: '10314810', Descripcion: 'Hydrangea cortada fresca púrpura oscuro' },
    { Clave: '10314811', Descripcion: 'Hydrangea cortada fresca berenjena' },
    { Clave: '10314812', Descripcion: 'Hydrangea cortada fresca verde teñida' },
    { Clave: '10314813', Descripcion: 'Hydrangea cortada fresca limón verde' },
    { Clave: '10314814', Descripcion: 'Hydrangea cortada fresca rosado fuerte' },
    { Clave: '10314815', Descripcion: 'Hydrangea cortada fresca jumbo blanca' },
    { Clave: '10314816', Descripcion: 'Hydrangea cortada fresca lavanda o nueva zelandia' },
    { Clave: '10314817', Descripcion: 'Hydrangea cortada fresca azul claro' },
    { Clave: '10314818', Descripcion: 'Hydrangea cortada fresca rosado claro grande' },
    { Clave: '10314819', Descripcion: 'Hydrangea cortada fresca verde lima grande' },
    { Clave: '10314820', Descripcion: 'Hydrangea cortada fresca mini verde' },
    { Clave: '10314821', Descripcion: 'Hydrangea cortada fresca oakleaf' },
    { Clave: '10314822', Descripcion: 'Hydrangea cortada fresca oakleaf copo de nieve' },
    { Clave: '10314823', Descripcion: 'Hydrangea cortada fresca rosado teñido' },
    { Clave: '10314824', Descripcion: 'Hydrangea cortada fresca rosado' },
    { Clave: '10314825', Descripcion: 'Hydrangea cortada fresca púrpura o nueva zelandia' },
    { Clave: '10314826', Descripcion: 'Hydrangea cortada fresca rojo teñido' },
    { Clave: '10314827', Descripcion: 'Hydrangea cortada fresca shocking blue' },
    { Clave: '10314828', Descripcion: 'Hydrangea cortada fresca tardiva' },
    { Clave: '10314829', Descripcion: 'Hydrangea cortada fresca blanca' },
    { Clave: '10314900', Descripcion: 'Iris cortada fresca' },
    { Clave: '10314901', Descripcion: 'Iris cortada fresca negra barbada' },
    { Clave: '10314902', Descripcion: 'Iris cortada fresca azul barbada' },
    { Clave: '10314903', Descripcion: 'Iris cortada fresca lavanda barbada' },
    { Clave: '10314904', Descripcion: 'Iris cortada fresca barbada azul clara' },
    { Clave: '10314905', Descripcion: 'Iris cortada fresca barbada púrpura' },
    { Clave: '10314906', Descripcion: 'Iris cortada fresca barbada roja' },
    { Clave: '10314907', Descripcion: 'Iris cortada fresca barbada blanca' },
    { Clave: '10314908', Descripcion: 'Iris cortada fresca baerbada blanca y púrpura' },
    { Clave: '10314909', Descripcion: 'Iris cortada fresca amarilla barbada' },
    { Clave: '10314910', Descripcion: 'Iris cortada fresca blue elegance' },
    { Clave: '10314911', Descripcion: 'Iris cortada fresca casablanca' },
    { Clave: '10314912', Descripcion: 'Iris cortada fresca golden beau' },
    { Clave: '10314913', Descripcion: 'Iris cortada fresca hildegard' },
    { Clave: '10314914', Descripcion: 'Iris cortada fresca hong kong' },
    { Clave: '10314915', Descripcion: 'Iris cortada fresca ideal' },
    { Clave: '10314916', Descripcion: 'Iris cortada fresca professor blue' },
    { Clave: '10314917', Descripcion: 'Iris cortada fresca purple' },
    { Clave: '10314918', Descripcion: 'Iris cortada fresca spuria' },
    { Clave: '10314919', Descripcion: 'Iris cortada fresca telstar' },
    { Clave: '10315000', Descripcion: 'Pata de canguro cortada fresca' },
    { Clave: '10315001', Descripcion: 'Pata de canguro cortada fresca bicolor' },
    { Clave: '10315002', Descripcion: 'Pata de canguro cortada fresca negra' },
    { Clave: '10315003', Descripcion: 'Pata de canguro cortada fresca verde' },
    { Clave: '10315004', Descripcion: 'Pata de canguro cortada fresca anaranjada' },
    { Clave: '10315005', Descripcion: 'Pata de canguro cortada fresca rosada' },
    { Clave: '10315006', Descripcion: 'Pata de canguro cortada fresca roja' },
    { Clave: '10315007', Descripcion: 'Pata de canguro cortada fresca amarilla' },
    { Clave: '10315100', Descripcion: 'Delfinio cortado fresco' },
    { Clave: '10315101', Descripcion: 'Delfinio cortada fresca azul nube' },
    { Clave: '10315102', Descripcion: 'Delfinio cortada fresca rosado fuerte' },
    { Clave: '10315103', Descripcion: 'Delfinio cortada fresca lavanda' },
    { Clave: '10315104', Descripcion: 'Delfinio cortada fresca rosado claro' },
    { Clave: '10315105', Descripcion: 'Delfinio cortada fresca púrpura' },
    { Clave: '10315106', Descripcion: 'Delfinio cortada fresca blanca' },
    { Clave: '10315200', Descripcion: 'Lepto cortado fresco' },
    { Clave: '10315201', Descripcion: 'Lepto cortado fresco azul o floreciente' },
    { Clave: '10315202', Descripcion: 'Lepto cortado fresco rosado fuerte' },
    { Clave: '10315203', Descripcion: 'Lepto cortado fresco rosado claro' },
    { Clave: '10315204', Descripcion: 'Lepto cortado fresco rosado' },
    { Clave: '10315205', Descripcion: 'Lepto cortado fresco rojo' },
    { Clave: '10315206', Descripcion: 'Lepto cortado fresco blanco' },
    { Clave: '10315300', Descripcion: 'Lila cortada fresca' },
    { Clave: '10315301', Descripcion: 'Lila  cortada fresca híbrida francesa lavanda' },
    { Clave: '10315302', Descripcion: 'Lila  cortada fresca híbrida francesa púrpura' },
    { Clave: '10315303', Descripcion: 'Lila  cortada fresca púrpura' },
    { Clave: '10315304', Descripcion: 'Lila  cortada fresca vino' },
    { Clave: '10315305', Descripcion: 'Lila  cortada fresca blanca' },
    { Clave: '10315306', Descripcion: 'Lila  cortada fresca blanca salvaje' },
    { Clave: '10315400', Descripcion: 'Azucena cortada fresca' },
    { Clave: '10315401', Descripcion: 'Azucena cortada fresca alteza longiflorum e híbrido asiática' },
    { Clave: '10315402', Descripcion: 'Azucena cortada fresca black out asiática' },
    { Clave: '10315403', Descripcion: 'Azucena cortada fresca rosado oscuro asiática' },
    { Clave: '10315404', Descripcion: 'Azucena cortada fresca leéctrica asiática' },
    { Clave: '10315405', Descripcion: 'Azucena cortada fresca festival asiática' },
    { Clave: '10315406', Descripcion: 'Azucena cortada fresca ginebra asiática' },
    { Clave: '10315407', Descripcion: 'Azucena cortada fresca rosado claro asiática' },
    { Clave: '10315408', Descripcion: 'Azucena cortada fresca colombina asiática' },
    { Clave: '10315409', Descripcion: 'Azucena cortada fresca miss américa púrpura asiática' },
    { Clave: '10315410', Descripcion: 'Azucena cortada fresca monte negro asiática' },
    { Clave: '10315411', Descripcion: 'Azucena cortada fresca anaranjada asiática' },
    { Clave: '10315412', Descripcion: 'Azucena cortada fresca durazno cannes asiática' },
    { Clave: '10315413', Descripcion: 'Azucena cortada fresca risada asiática' },
    { Clave: '10315414', Descripcion: 'Azucena cortada fresca sancerre asiátia' },
    { Clave: '10315415', Descripcion: 'Azucena cortada fresca white dream asiática' },
    { Clave: '10315416', Descripcion: 'Azucena cortada fresca amarilla asiática' },
    { Clave: '10315417', Descripcion: 'Azucena cortada fresca diamante brillante longiflorum e híbrido asiática' },
    { Clave: '10315418', Descripcion: 'Azucena cortada fresca brindisi longiflorum e híbrido asiática' },
    { Clave: '10315419', Descripcion: 'Azucena cortada fresca carmine longiflorum e híbrido asiática' },
    { Clave: '10315420', Descripcion: 'Azucena cortada fresca cinnabar longiflorum e híbrido asiática' },
    { Clave: '10315421', Descripcion: 'Azucena cortada fresca club longiflorum e híbrido asiática' },
    { Clave: '10315422', Descripcion: 'Azucena cortada fresca discovery longiflorum e híbrido asiática' },
    { Clave: '10315423', Descripcion: 'Azucena cortada fresca de pascua' },
    { Clave: '10315424', Descripcion: 'Azucena cortada fresca isis longiflorum e híbrido asiática' },
    { Clave: '10315425', Descripcion: 'Azucena cortada fresca la hybrid justice longiflorum e híbrido asiática' },
    { Clave: '10315426', Descripcion: 'Azucena cortada fresca lace longiflorum e híbrido asiática' },
    { Clave: '10315427', Descripcion: 'Azucena cortada fresca lirio de los valles' },
    { Clave: '10315428', Descripcion: 'Azucena cortada fresca love longiflorum e híbrido asiática' },
    { Clave: '10315429', Descripcion: 'Azucena cortada fresca menorca longiflorum e híbrido asiática' },
    { Clave: '10315430', Descripcion: 'Azucena cortada fresca oriental acapulco' },
    { Clave: '10315431', Descripcion: 'Azucena cortada fresca oriental albion' },
    { Clave: '10315432', Descripcion: 'Azucena cortada fresca oriental argentina' },
    { Clave: '10315433', Descripcion: 'Azucena cortada fresca oriental auratum' },
    { Clave: '10315434', Descripcion: 'Azucena cortada fresca oriental barbaresco' },
    { Clave: '10315435', Descripcion: 'Azucena cortada fresca oriental bernini' },
    { Clave: '10315436', Descripcion: 'Azucena cortada fresca oriental beseno' },
    { Clave: '10315437', Descripcion: 'Azucena cortada fresca oriental broadway' },
    { Clave: '10315438', Descripcion: 'Azucena cortada fresca oriental canada' },
    { Clave: '10315439', Descripcion: 'Azucena cortada fresca oriental casablanca' },
    { Clave: '10315440', Descripcion: 'Azucena cortada fresca oriental chili' },
    { Clave: '10315441', Descripcion: 'Azucena cortada fresca oriental chrystal' },
    { Clave: '10315442', Descripcion: 'Azucena cortada fresca oriental cobra' },
    { Clave: '10315443', Descripcion: 'Azucena cortada fresca oriental conca d? or' },
    { Clave: '10315444', Descripcion: 'Azucena cortada fresca oriental cote d? ivor' },
    { Clave: '10315445', Descripcion: 'Azucena cortada fresca oriental dizzy' },
    { Clave: '10315446', Descripcion: 'Azucena cortada fresca oriental fireball' },
    { Clave: '10315447', Descripcion: 'Azucena cortada fresca oriental gluhwein' },
    { Clave: '10315448', Descripcion: 'Azucena cortada fresca oriental goldband' },
    { Clave: '10315449', Descripcion: 'Azucena cortada fresca oriental halifax' },
    { Clave: '10315450', Descripcion: 'Azucena cortada fresca oriental kathryn' },
    { Clave: '10315451', Descripcion: 'Azucena cortada fresca oriental kyoto' },
    { Clave: '10315452', Descripcion: 'Azucena cortada fresca oriental la mancha' },
    { Clave: '10315453', Descripcion: 'Azucena cortada fresca oriental medusa' },
    { Clave: '10315454', Descripcion: 'Azucena cortada fresca oriental montezuma' },
    { Clave: '10315455', Descripcion: 'Azucena cortada fresca oriental muscadet' },
    { Clave: '10315456', Descripcion: 'Azucena cortada fresca oriental nippon' },
    { Clave: '10315457', Descripcion: 'Azucena cortada fresca oriental opus one' },
    { Clave: '10315458', Descripcion: 'Azucena cortada fresca oriental pompeii' },
    { Clave: '10315459', Descripcion: 'Azucena cortada fresca oriental rialto' },
    { Clave: '10315460', Descripcion: 'Azucena cortada fresca oriental robina' },
    { Clave: '10315461', Descripcion: 'Azucena cortada fresca oriental rousilon' },
    { Clave: '10315462', Descripcion: 'Azucena cortada fresca oriental siberia' },
    { Clave: '10315463', Descripcion: 'Azucena cortada fresca oriental sorbonne' },
    { Clave: '10315464', Descripcion: 'Azucena cortada fresca oriental starfighter' },
    { Clave: '10315465', Descripcion: 'Azucena cortada fresca oriental stargazer' },
    { Clave: '10315466', Descripcion: 'Azucena cortada fresca oriental sumatra' },
    { Clave: '10315467', Descripcion: 'Azucena cortada fresca oriental time out' },
    { Clave: '10315468', Descripcion: 'Azucena cortada fresca oriental tom pouche' },
    { Clave: '10315469', Descripcion: 'Azucena cortada fresca oriental tropical' },
    { Clave: '10315470', Descripcion: 'Azucena cortada fresca oriental white cup' },
    { Clave: '10315471', Descripcion: 'Azucena cortada fresca oriental white merostar' },
    { Clave: '10315472', Descripcion: 'Azucena cortada fresca oriental white montana' },
    { Clave: '10315473', Descripcion: 'Azucena cortada fresca oriental white stargazer' },
    { Clave: '10315474', Descripcion: 'Azucena cortada fresca oriental yellow band' },
    { Clave: '10315475', Descripcion: 'Azucena cortada fresca oriental yellow dream' },
    { Clave: '10315476', Descripcion: 'Azucena cortada fresca oriental yellow queen' },
    { Clave: '10315477', Descripcion: 'Azucena cortada fresca oriental yellow star' },
    { Clave: '10315478', Descripcion: 'Azucena cortada fresca oriental yeloween' },
    { Clave: '10315479', Descripcion: 'Azucena cortada fresca ot red dutch' },
    { Clave: '10315480', Descripcion: 'Azucena cortada fresca sonata nimph' },
    { Clave: '10315481', Descripcion: 'Azucena cortada fresca sonata shocking' },
    { Clave: '10315482', Descripcion: 'Azucena cortada fresca sonata triumphateer' },
    { Clave: '10315483', Descripcion: 'Azucena cortada fresca sunset longiflorum e híbrido asiática' },
    { Clave: '10315484', Descripcion: 'Azucena cortada fresca de agua' },
    { Clave: '10315500', Descripcion: 'Lavanda cortada fresca' },
    { Clave: '10315501', Descripcion: 'Lavanda marina cortada fresca misty peach' },
    { Clave: '10315502', Descripcion: 'Lavanda marina cortada fresca misty rosada' },
    { Clave: '10315503', Descripcion: 'Lavanda marina cortada fresca misty blanca' },
    { Clave: '10315504', Descripcion: 'Lavanda marina cortada fresca misty amarilla' },
    { Clave: '10315505', Descripcion: 'Lavanda marina cortada fresca safora' },
    { Clave: '10315506', Descripcion: 'Lavanda marina cortada fresca sinensis' },
    { Clave: '10315600', Descripcion: 'Lisianthus cortado fresco' },
    { Clave: '10315601', Descripcion: 'Lisianthus cortado fresco crema' },
    { Clave: '10315602', Descripcion: 'Lisianthus cortado fresco rosado fuerte' },
    { Clave: '10315603', Descripcion: 'Lisianthus cortado fresco verde' },
    { Clave: '10315604', Descripcion: 'Lisianthus cortado fresco lavanda' },
    { Clave: '10315605', Descripcion: 'Lisianthus cortado fresco rosado claro' },
    { Clave: '10315606', Descripcion: 'Lisianthus cortado fresco mini blanco' },
    { Clave: '10315607', Descripcion: 'Lisianthus cortado fresco durazno' },
    { Clave: '10315608', Descripcion: 'Lisianthus cortado fresco rosado con borde blanco' },
    { Clave: '10315609', Descripcion: 'Lisianthus cortado fresco púrpura' },
    { Clave: '10315610', Descripcion: 'Lisianthus cortado fresco púrpura con borde blanco' },
    { Clave: '10315611', Descripcion: 'Lisianthus cortado fresco blanco con borde rosado' },
    { Clave: '10315612', Descripcion: 'Lisianthus cortado fresco blanco' },
    { Clave: '10315613', Descripcion: 'Lisianthus cortado fresco blanco con borde pùrpura' },
    { Clave: '10315700', Descripcion: 'Muscari cortada fresca' },
    { Clave: '10315701', Descripcion: 'Muscari cortada fresca armeniacum' },
    { Clave: '10315702', Descripcion: 'Muscari cortada fresca bortyoides blanca' },
    { Clave: '10315703', Descripcion: 'Muscari cortada fresca verde' },
    { Clave: '10315704', Descripcion: 'Muscari cortada fresca latifolia' },
    { Clave: '10315705', Descripcion: 'Muscari cortada fresca valerie finn' },
    { Clave: '10315800', Descripcion: 'Narciso cortado fresco' },
    { Clave: '10315801', Descripcion: 'Narciso cortado fresco alegría' },
    { Clave: '10315802', Descripcion: 'Narciso cortado fresco amanecer dorado' },
    { Clave: '10315803', Descripcion: 'Narciso cortado fresco abba paperwhite' },
    { Clave: '10315804', Descripcion: 'Narciso cortado fresco paperwhite' },
    { Clave: '10315805', Descripcion: 'Narciso cortado fresco ojo de faisán' },
    { Clave: '10315806', Descripcion: 'Narciso cortado fresco soleil d? or' },
    { Clave: '10315807', Descripcion: 'Narciso cortado fresco tete a tete' },
    { Clave: '10315808', Descripcion: 'Narciso cortado fresco thalia' },
    { Clave: '10316000', Descripcion: 'Pimientos ornamentales cortados frescos' },
    { Clave: '10316001', Descripcion: 'Pimiento chile cortado fresco ornamental' },
    { Clave: '10316002', Descripcion: 'Pimiento mezclado cortado fresco ornamental' },
    { Clave: '10316003', Descripcion: 'Pimiento anaranjado cortado fresco ornamental' },
    { Clave: '10316004', Descripcion: 'Pimiento rojo cortado fresco ornamental' },
    { Clave: '10316005', Descripcion: 'Pimiento amarillo cortado fresco ornamental' },
    { Clave: '10316100', Descripcion: 'Estrella de Belén cortada fresca' },
    { Clave: '10316101', Descripcion: 'Estrella de belén cortada fresca arábica' },
    { Clave: '10316102', Descripcion: 'Estrella de belén cortada fresca dubium anaranjada' },
    { Clave: '10316103', Descripcion: 'Estrella de belén cortada fresca umbellada' },
    { Clave: '10316104', Descripcion: 'Estrella de belén cortada fresca dubium blanca' },
    { Clave: '10316105', Descripcion: 'Estrella de belén cortada fresca dubium amarilla' },
    { Clave: '10316200', Descripcion: 'Peonia cortada fresca' },
    { Clave: '10316201', Descripcion: 'Peonia cortada fresca alexander fleming' },
    { Clave: '10316202', Descripcion: 'Peonia cortada fresca coral charm' },
    { Clave: '10316203', Descripcion: 'Peonia cortada fresca suset' },
    { Clave: '10316204', Descripcion: 'Peonia cortada fresca coral supreme' },
    { Clave: '10316205', Descripcion: 'Peonia cortada fresca gardenia doble' },
    { Clave: '10316206', Descripcion: 'Peonia cortada fresca doble jues eli oscura' },
    { Clave: '10316207', Descripcion: 'Peonia cortada fresca soble duquesa blanca' },
    { Clave: '10316208', Descripcion: 'Peonia cortada fresca felix crousse' },
    { Clave: '10316209', Descripcion: 'Peonia cortada fresca festiva máxima' },
    { Clave: '10316210', Descripcion: 'Peonia cortada fresca tesoro del jardín' },
    { Clave: '10316211', Descripcion: 'Peonia cortada fresca kansas rosado oscuro' },
    { Clave: '10316212', Descripcion: 'Peonia cortada fresca karl rosenfelt' },
    { Clave: '10316213', Descripcion: 'Peonia cortada fresca paula fay' },
    { Clave: '10316214', Descripcion: 'Peonia cortada fresca encanto rojo' },
    { Clave: '10316215', Descripcion: 'Peonia cortada fresca pasion roja' },
    { Clave: '10316216', Descripcion: 'Peonia cortada fresca sarah bernhardt roja' },
    { Clave: '10316217', Descripcion: 'Peonia cortada fresca scarlet o? hara' },
    { Clave: '10316218', Descripcion: 'Peonia cortada fresca shirley temple' },
    { Clave: '10316300', Descripcion: 'Banksia cortada fresca' },
    { Clave: '10316301', Descripcion: 'Banksia cortada fresca ashbyi' },
    { Clave: '10316302', Descripcion: 'Banksia cortada fresca baxteri' },
    { Clave: '10316306', Descripcion: 'Banksia cortada fresca coccinea' },
    { Clave: '10316311', Descripcion: 'Banksia cortada fresca ericifolia' },
    { Clave: '10316315', Descripcion: 'Banksia cortada fresca verde' },
    { Clave: '10316322', Descripcion: 'Banksia cortada fresca menziesii' },
    { Clave: '10316325', Descripcion: 'Banksia cortada fresca blanco natural' },
    { Clave: '10316326', Descripcion: 'Banksia cortada fresca anaranjado' },
    { Clave: '10316332', Descripcion: 'Banksia cortada fresca rosada' },
    { Clave: '10316400', Descripcion: 'Ranúculo cortado fresco' },
    { Clave: '10316401', Descripcion: 'Ranúnculo cortado fresco chocolate' },
    { Clave: '10316402', Descripcion: 'Ranúnculo cortado fresco elegancia' },
    { Clave: '10316403', Descripcion: 'Ranúnculo cortado fresco verde' },
    { Clave: '10316404', Descripcion: 'Ranúnculo cortado fresco grimaldi' },
    { Clave: '10316405', Descripcion: 'Ranúnculo cortado fresco rosado fuerte' },
    { Clave: '10316406', Descripcion: 'Ranúnculo cortado fresco rosado claro' },
    { Clave: '10316407', Descripcion: 'Ranúnculo cortado fresco anaranjado' },
    { Clave: '10316408', Descripcion: 'Ranúnculo cortado fresco centro verde' },
    { Clave: '10316409', Descripcion: 'Ranúnculo cortado fresco rosado' },
    { Clave: '10316410', Descripcion: 'Ranúnculo cortado fresco rojo' },
    { Clave: '10316411', Descripcion: 'Ranúnculo cortado fresco blanco' },
    { Clave: '10316412', Descripcion: 'Ranúnculo cortado fresco amarillo' },
    { Clave: '10316413', Descripcion: 'Ranúnculo cortado fresco salmón' },
    { Clave: '10316500', Descripcion: 'Escabiosa cortada fresca' },
    { Clave: '10316501', Descripcion: 'Escabiosa cortada fresca anual' },
    { Clave: '10316502', Descripcion: 'Escabiosa cortada fresca negra' },
    { Clave: '10316503', Descripcion: 'Escabiosa cortada fresca caucásica azul' },
    { Clave: '10316504', Descripcion: 'Escabiosa cortada fresca caucásica rosada' },
    { Clave: '10316505', Descripcion: 'Escabiosa cortada fresca caucásica vainas' },
    { Clave: '10316506', Descripcion: 'Escabiosa cortada fresca caucásica blanca' },
    { Clave: '10316507', Descripcion: 'Escabiosa cortada fresca fresa' },
    { Clave: '10316600', Descripcion: 'Retama de escobas cortada fresca' },
    { Clave: '10316601', Descripcion: 'Retama de escobas cortada fresca rosada' },
    { Clave: '10316602', Descripcion: 'Retama de escobas cortada fresca púrpura' },
    { Clave: '10316603', Descripcion: 'Retama de escobas cortada fresca blanca' },
    { Clave: '10316604', Descripcion: 'Retama de escobas cortada fresca amarilla' },
    { Clave: '10316700', Descripcion: 'Boca de dragón cortada fresca' },
    { Clave: '10316701', Descripcion: 'Boca de dragón cortada fresca bicolor' },
    { Clave: '10316702', Descripcion: 'Boca de dragón cortada fresca burgundy' },
    { Clave: '10316703', Descripcion: 'Boca de dragón cortada fresca rosado fuerte' },
    { Clave: '10316704', Descripcion: 'Boca de dragón cortada fresca lavanda' },
    { Clave: '10316705', Descripcion: 'Boca de dragón cortada fresca anaranjado claro' },
    { Clave: '10316706', Descripcion: 'Boca de dragón cortada fresca rosado claro' },
    { Clave: '10316707', Descripcion: 'Boca de dragón cortada fresca anaranjado' },
    { Clave: '10316708', Descripcion: 'Boca de dragón cortada fresca blanco' },
    { Clave: '10316709', Descripcion: 'Boca de dragón cortada fresca amarillo' },
    { Clave: '10316800', Descripcion: 'Romero del pantano cortada fresca' },
    { Clave: '10316801', Descripcion: 'Romero del pantano cortada fresca azul' },
    { Clave: '10316802', Descripcion: 'Romero del pantano cortada fresca lavanda' },
    { Clave: '10316803', Descripcion: 'Romero del pantano cortada fresca durazno' },
    { Clave: '10316804', Descripcion: 'Romero del pantano cortada fresca rosada' },
    { Clave: '10316805', Descripcion: 'Romero del pantano cortada fresca púrpura' },
    { Clave: '10316806', Descripcion: 'Romero del pantano cortada fresca espuma de mar' },
    { Clave: '10316807', Descripcion: 'Romero del pantano cortada fresca blanca' },
    { Clave: '10316808', Descripcion: 'Romero del pantano cortada fresca amarilla' },
    { Clave: '10316900', Descripcion: 'Matiola cortada fresca' },
    { Clave: '10316901', Descripcion: 'Matiola cortada fresca apricot' },
    { Clave: '10316902', Descripcion: 'Matiola cortada fresca crema' },
    { Clave: '10316903', Descripcion: 'Matiola cortada fresca fucsia' },
    { Clave: '10316904', Descripcion: 'Matiola cortada fresca lavanda' },
    { Clave: '10316905', Descripcion: 'Matiola cortada fresca lavanda claro' },
    { Clave: '10316906', Descripcion: 'Matiola cortada fresca rosada' },
    { Clave: '10316907', Descripcion: 'Matiola cortada fresca púrpura' },
    { Clave: '10316908', Descripcion: 'Matiola cortada fresca rojo rubí' },
    { Clave: '10316909', Descripcion: 'Matiola cortada fresca enamorada rosda' },
    { Clave: '10316910', Descripcion: 'Matiola cortada fresca blanca' },
    { Clave: '10317000', Descripcion: 'Girasol cortada fresca' },
    { Clave: '10317001', Descripcion: 'Girasol cortada fresca tinte festivo' },
    { Clave: '10317002', Descripcion: 'Girasol cortada fresca mahogany' },
    { Clave: '10317003', Descripcion: 'Girasol cortada fresca rayo de sol' },
    { Clave: '10317004', Descripcion: 'Girasol cortada fresca brillo del sol' },
    { Clave: '10317005', Descripcion: 'Girasol cortada fresca salpicada de sol' },
    { Clave: '10317006', Descripcion: 'Girasol cortada fresca oso de peluche' },
    { Clave: '10317100', Descripcion: 'Guisante de olor cortado fresco' },
    { Clave: '10317101', Descripcion: 'Guisante de olor cortado fresco verde teñido' },
    { Clave: '10317102', Descripcion: 'Guisante de olor cortado fresco rosado fuerte' },
    { Clave: '10317103', Descripcion: 'Guisante de olor cortado fresco lavanda' },
    { Clave: '10317104', Descripcion: 'Guisante de olor cortado fresco rosado claro' },
    { Clave: '10317105', Descripcion: 'Guisante de olor cortado fresco anaranjado' },
    { Clave: '10317106', Descripcion: 'Guisante de olor cortado fresco durazno teñido' },
    { Clave: '10317107', Descripcion: 'Guisante de olor cortado fresco púrpura' },
    { Clave: '10317108', Descripcion: 'Guisante de olor cortado fresco blanco' },
    { Clave: '10317200', Descripcion: 'Cardo cortado fresco' },
    { Clave: '10317201', Descripcion: 'Cardo cortado fresco alpinum' },
    { Clave: '10317202', Descripcion: 'Cardo cortado fresco echinops' },
    { Clave: '10317203', Descripcion: 'Cardo cortado fresco eryngium árabe' },
    { Clave: '10317204', Descripcion: 'Cardo cortado fresco eryngium azul' },
    { Clave: '10317205', Descripcion: 'Cardo cortado fresco eryngium orión' },
    { Clave: '10317206', Descripcion: 'Cardo cortado fresco eryngium frambuesa' },
    { Clave: '10317207', Descripcion: 'Cardo cortado fresco eryngium supernova' },
    { Clave: '10317208', Descripcion: 'Cardo cortado fresco eryngium campanita' },
    { Clave: '10317300', Descripcion: 'Tulipán cortado fresco' },
    { Clave: '10317301', Descripcion: 'Tulipán cortado fresco adrem' },
    { Clave: '10317302', Descripcion: 'Tulipán cortado fresco apricot' },
    { Clave: '10317303', Descripcion: 'Tulipán cortado fresco bicolor rojo y amarillo' },
    { Clave: '10317304', Descripcion: 'Tulipán cortado fresco doble bicolor' },
    { Clave: '10317305', Descripcion: 'Tulipán cortado fresco doble rosado' },
    { Clave: '10317306', Descripcion: 'Tulipán cortado fresco doble rojo' },
    { Clave: '10317307', Descripcion: 'Tulipán cortado fresco doble blanco' },
    { Clave: '10317308', Descripcion: 'Tulipán cortado fresco doble amarillo' },
    { Clave: '10317309', Descripcion: 'Tulipán cortado fresco francés avignon' },
    { Clave: '10317310', Descripcion: 'Tulipán cortado fresco francés camarque' },
    { Clave: '10317311', Descripcion: 'Tulipán cortado fresco francés dordogne' },
    { Clave: '10317312', Descripcion: 'Tulipán cortado fresco francés fiat' },
    { Clave: '10317313', Descripcion: 'Tulipán cortado fresco francés flamboyant' },
    { Clave: '10317314', Descripcion: 'Tulipán cortado fresco francés flaming parrot' },
    { Clave: '10317315', Descripcion: 'Tulipán cortado fresco francés florissa' },
    { Clave: '10317316', Descripcion: 'Tulipán cortado fresco francés doble maureen' },
    { Clave: '10317317', Descripcion: 'Tulipán cortado fresco francés maureen' },
    { Clave: '10317318', Descripcion: 'Tulipán cortado fresco francés menton' },
    { Clave: '10317319', Descripcion: 'Tulipán cortado fresco francés montpellier' },
    { Clave: '10317320', Descripcion: 'Tulipán cortado fresco francés naranja unique' },
    { Clave: '10317321', Descripcion: 'Tulipán cortado fresco francés peonia reconocido único' },
    { Clave: '10317322', Descripcion: 'Tulipán cortado fresco francés loro rosado' },
    { Clave: '10317323', Descripcion: 'Tulipán cortado fresco francés princesa unique' },
    { Clave: '10317324', Descripcion: 'Tulipán cortado fresco francés reconocido' },
    { Clave: '10317325', Descripcion: 'Tulipán cortado fresco francés schepppers' },
    { Clave: '10317326', Descripcion: 'Tulipán cortado fresco francés gamuza' },
    { Clave: '10317327', Descripcion: 'Tulipán cortado fresco francés toyota' },
    { Clave: '10317328', Descripcion: 'Tulipán cortado fresco francés loro weber' },
    { Clave: '10317329', Descripcion: 'Tulipán cortado fresco francés loro blanco' },
    { Clave: '10317330', Descripcion: 'Tulipán cortado fresco lavanda de borde crespo' },
    { Clave: '10317331', Descripcion: 'Tulipán cortado fresco rosado fuerte' },
    { Clave: '10317332', Descripcion: 'Tulipán cortado fresco rosado fuerte hoja variegada' },
    { Clave: '10317333', Descripcion: 'Tulipán cortado fresco lavanda' },
    { Clave: '10317334', Descripcion: 'Tulipán cortado fresco rosado claro hoja variegada' },
    { Clave: '10317335', Descripcion: 'Tulipán cortado fresco viuda alegre' },
    { Clave: '10317336', Descripcion: 'Tulipán cortado fresco anaranjado' },
    { Clave: '10317337', Descripcion: 'Tulipán cortado fresco loro negro' },
    { Clave: '10317338', Descripcion: 'Tulipán cortado fresco loro estela rijnveld' },
    { Clave: '10317339', Descripcion: 'Tulipán cortado fresco llameante' },
    { Clave: '10317340', Descripcion: 'Tulipán cortado fresco loro verde' },
    { Clave: '10317341', Descripcion: 'Tulipán cortado fresco loro lavanda' },
    { Clave: '10317342', Descripcion: 'Tulipán cortado fresco loro anaranjado' },
    { Clave: '10317343', Descripcion: 'Tulipán cortado fresco loro durazno' },
    { Clave: '10317344', Descripcion: 'Tulipán cortado fresco loro rosado' },
    { Clave: '10317345', Descripcion: 'Tulipán cortado fresco loro rojo' },
    { Clave: '10317346', Descripcion: 'Tulipán cortado fresco loro rojo rococó' },
    { Clave: '10317347', Descripcion: 'Tulipán cortado fresco loro weber' },
    { Clave: '10317348', Descripcion: 'Tulipán cortado fresco loro blanco' },
    { Clave: '10317349', Descripcion: 'Tulipán cortado fresco loro rosado' },
    { Clave: '10317350', Descripcion: 'Tulipán cortado fresco rosado' },
    { Clave: '10317351', Descripcion: 'Tulipán cortado fresco púrpura' },
    { Clave: '10317352', Descripcion: 'Tulipán cortado fresco rojo' },
    { Clave: '10317353', Descripcion: 'Tulipán cortado fresco especias' },
    { Clave: '10317354', Descripcion: 'Tulipán cortado fresco blanco' },
    { Clave: '10317355', Descripcion: 'Tulipán cortado fresco amarillo' },
    { Clave: '10317400', Descripcion: 'Flor de cera cortada fresca' },
    { Clave: '10317401', Descripcion: 'Flor de cera cortada fresca alba' },
    { Clave: '10317402', Descripcion: 'Flor de cera cortada fresca bicolor' },
    { Clave: '10317403', Descripcion: 'Flor de cera cortada fresca chichilla' },
    { Clave: '10317404', Descripcion: 'Flor de cera cortada fresca reina danzante' },
    { Clave: '10317405', Descripcion: 'Flor de cera cortada fresca dinamarca' },
    { Clave: '10317406', Descripcion: 'Flor de cera cortada fresca denmar' },
    { Clave: '10317407', Descripcion: 'Flor de cera cortada fresca híbrida pastel flor gema' },
    { Clave: '10317408', Descripcion: 'Flor de cera cortada fresca híbrida rosada flor gema' },
    { Clave: '10317409', Descripcion: 'Flor de cera cortada fresca híbrida blanca rubia' },
    { Clave: '10317410', Descripcion: 'Flor de cera cortada fresca híbrida eric john' },
    { Clave: '10317411', Descripcion: 'Flor de cera cortada fresca híbrida dama pintada' },
    { Clave: '10317412', Descripcion: 'Flor de cera cortada fresca híbrida revelación' },
    { Clave: '10317413', Descripcion: 'Flor de cera cortada fresca híbrida bola de nieve' },
    { Clave: '10317414', Descripcion: 'Flor de cera cortada fresca juriens brook' },
    { Clave: '10317415', Descripcion: 'Flor de cera cortada fresca lady stephanie rosada' },
    { Clave: '10317416', Descripcion: 'Flor de cera cortada fresca madonna' },
    { Clave: '10317417', Descripcion: 'Flor de cera cortada fresca mini blanca' },
    { Clave: '10317418', Descripcion: 'Flor de cera cortada fresca anaranjada' },
    { Clave: '10317419', Descripcion: 'Flor de cera cortada fresca perla' },
    { Clave: '10317420', Descripcion: 'Flor de cera cortada fresca pixie moon' },
    { Clave: '10317421', Descripcion: 'Flor de cera cortada fresca orgullo púrpura' },
    { Clave: '10317422', Descripcion: 'Flor de cera cortada fresca roja' },
    { Clave: '10317423', Descripcion: 'Flor de cera cortada fresca wanaroo' },
    { Clave: '10317424', Descripcion: 'Flor de cera cortada fresca amarilla' },
    { Clave: '10317500', Descripcion: 'Hierba de aquiles cortada fresca' },
    { Clave: '10317501', Descripcion: 'Hierba de aquiles cortada fresca burgundy' },
    { Clave: '10317502', Descripcion: 'Hierba de aquiles cortada fresca crema campesina' },
    { Clave: '10317503', Descripcion: 'Hierba de aquiles cortada fresca rosado campesino' },
    { Clave: '10317504', Descripcion: 'Hierba de aquiles cortada fresca moonshine' },
    { Clave: '10317505', Descripcion: 'Hierba de aquiles cortada fresca anaranjado' },
    { Clave: '10317506', Descripcion: 'Hierba de aquiles cortada fresca durazno' },
    { Clave: '10317507', Descripcion: 'Hierba de aquiles cortada fresca rosada' },
    { Clave: '10317508', Descripcion: 'Hierba de aquiles cortada fresca rojo teñido' },
    { Clave: '10317509', Descripcion: 'Hierba de aquiles cortada fresca blanca' },
    { Clave: '10317510', Descripcion: 'Hierba de aquiles cortada fresca amarilla' },
    { Clave: '10317600', Descripcion: 'Zinia cortada fresca' },
    { Clave: '10317601', Descripcion: 'Zinia cortada fresca amarilla' },
    { Clave: '10317602', Descripcion: 'Zinia cortada fresca mini' },
    { Clave: '10317603', Descripcion: 'Zinia cortada fresca rosado' },
    { Clave: '10317604', Descripcion: 'Zinia cortada fresca rojo' },
    { Clave: '10317605', Descripcion: 'Zinia cortada fresca salmón' },
    { Clave: '10317606', Descripcion: 'Zinia cortada fresca amarillo' },
    { Clave: '10317700', Descripcion: 'Forsythia cortada fresca' },
    { Clave: '10317701', Descripcion: 'Forsythia cortada fresca viridissima' },
    { Clave: '10317702', Descripcion: 'Forsythia cortada fresca giraldiana' },
    { Clave: '10317703', Descripcion: 'Forsythia cortada fresca mira' },
    { Clave: '10317704', Descripcion: 'Forsythia cortada fresca suspensa' },
    { Clave: '10317705', Descripcion: 'Forsythia cortada fresca intermedia' },
    { Clave: '10317706', Descripcion: 'Forsythia cortada fresca variabilis' },
    { Clave: '10317707', Descripcion: 'Forsythia cortada fresca ovate' },
    { Clave: '10317708', Descripcion: 'Forsythia cortada fresca intermedia lynwood' },
    { Clave: '10317800', Descripcion: 'Geranio cortado fresco' },
    { Clave: '10317801', Descripcion: 'Geranio cortado fresco argenteum' },
    { Clave: '10317802', Descripcion: 'Geranio cortado fresco cinereum' },
    { Clave: '10317803', Descripcion: 'Geranio cortado fresco clarkei' },
    { Clave: '10317804', Descripcion: 'Geranio cortado fresco dalmaticum' },
    { Clave: '10317805', Descripcion: 'Geranio cortado fresco endressii' },
    { Clave: '10317806', Descripcion: 'Geranio cortado fresco eriostemon' },
    { Clave: '10317807', Descripcion: 'Geranio cortado fresco farreri' },
    { Clave: '10317808', Descripcion: 'Geranio cortado fresco himalayense o grandiflora' },
    { Clave: '10317809', Descripcion: 'Geranio cortado fresco ibericum' },
    { Clave: '10317810', Descripcion: 'Geranio cortado fresco macrorhizum o raiz grande' },
    { Clave: '10317811', Descripcion: 'Geranio cortado fresco maculatum' },
    { Clave: '10317812', Descripcion: 'Geranio cortado fresco nodosum' },
    { Clave: '10317813', Descripcion: 'Geranio cortado fresco phaeum' },
    { Clave: '10317814', Descripcion: 'Geranio cortado fresco platypetalum' },
    { Clave: '10317815', Descripcion: 'Geranio cortado fresco pratense' },
    { Clave: '10317816', Descripcion: 'Geranio cortado fresco procurrens' },
    { Clave: '10317817', Descripcion: 'Geranio cortado fresco psilostemon' },
    { Clave: '10317818', Descripcion: 'Geranio cortado fresco pylzowianum' },
    { Clave: '10317819', Descripcion: 'Geranio cortado fresco renardii' },
    { Clave: '10317820', Descripcion: 'Geranio cortado fresco sanguineum o sangruento' },
    { Clave: '10317821', Descripcion: 'Geranio cortado fresco sylvaticum' },
    { Clave: '10317822', Descripcion: 'Geranio cortado fresco traversii' },
    { Clave: '10317823', Descripcion: 'Geranio cortado fresco tuberosum' },
    { Clave: '10317824', Descripcion: 'Geranio cortado fresco versicolor' },
    { Clave: '10317825', Descripcion: 'Geranio cortado fresco wallachianum' },
    { Clave: '10317826', Descripcion: 'Geranio cortado fresco wlassovianum' },
    { Clave: '10317827', Descripcion: 'Geranio cortado fresco x magnificum o llamativo' },
    { Clave: '10317900', Descripcion: 'Amaryllis cortada fresca' },
    { Clave: '10317901', Descripcion: 'Amaryllis cortada fresca aglaiae' },
    { Clave: '10317902', Descripcion: 'Amaryllis cortada fresca amaru' },
    { Clave: '10317903', Descripcion: 'Amaryllis cortada fresca angustifolium' },
    { Clave: '10317904', Descripcion: 'Amaryllis cortada fresca anzaldoi' },
    { Clave: '10317905', Descripcion: 'Amaryllis cortada fresca araripinum' },
    { Clave: '10317906', Descripcion: 'Amaryllis cortada fresca arboricola' },
    { Clave: '10317907', Descripcion: 'Amaryllis cortada fresca argentinum' },
    { Clave: '10317908', Descripcion: 'Amaryllis cortada fresca aulicum' },
    { Clave: '10317909', Descripcion: 'Amaryllis cortada fresca aviflorum' },
    { Clave: '10317910', Descripcion: 'Amaryllis cortada fresca barreirasum' },
    { Clave: '10317911', Descripcion: 'Amaryllis cortada fresca blossfeldiae' },
    { Clave: '10317912', Descripcion: 'Amaryllis cortada fresca blumenavium' },
    { Clave: '10317913', Descripcion: 'Amaryllis cortada fresca brasilianum' },
    { Clave: '10317914', Descripcion: 'Amaryllis cortada fresca breviflorum' },
    { Clave: '10317915', Descripcion: 'Amaryllis cortada fresca bukasovii' },
    { Clave: '10317916', Descripcion: 'Amaryllis cortada fresca calyptratum' },
    { Clave: '10317917', Descripcion: 'Amaryllis cortada fresca caupolicanense' },
    { Clave: '10317918', Descripcion: 'Amaryllis cortada fresca chionedyanthum' },
    { Clave: '10317919', Descripcion: 'Amaryllis cortada fresca condemaita' },
    { Clave: '10317920', Descripcion: 'Amaryllis cortada fresca corriense' },
    { Clave: '10317921', Descripcion: 'Amaryllis cortada fresca cuzcoense' },
    { Clave: '10317922', Descripcion: 'Amaryllis cortada fresca curitibanum' },
    { Clave: '10317923', Descripcion: 'Amaryllis cortada fresca cybister' },
    { Clave: '10317924', Descripcion: 'Amaryllis cortada fresca divijuliani' },
    { Clave: '10317925', Descripcion: 'Amaryllis cortada fresca evansiae' },
    { Clave: '10317926', Descripcion: 'Amaryllis cortada fresca ferreyrae' },
    { Clave: '10317927', Descripcion: 'Amaryllis cortada fresca forgetii' },
    { Clave: '10317928', Descripcion: 'Amaryllis cortada fresca fosteri' },
    { Clave: '10317929', Descripcion: 'Amaryllis cortada fresca fuscum' },
    { Clave: '10317930', Descripcion: 'Amaryllis cortada fresca glaucescens' },
    { Clave: '10317931', Descripcion: 'Amaryllis cortada fresca goianum' },
    { Clave: '10317932', Descripcion: 'Amaryllis cortada fresca guarapuavicum' },
    { Clave: '10317933', Descripcion: 'Amaryllis cortada fresca harrisonii' },
    { Clave: '10317934', Descripcion: 'Amaryllis cortada fresca hugoi' },
    { Clave: '10317935', Descripcion: 'Amaryllis cortada fresca iguazuanum' },
    { Clave: '10317936', Descripcion: 'Amaryllis cortada fresca illustre' },
    { Clave: '10317937', Descripcion: 'Amaryllis cortada fresca intiflorum' },
    { Clave: '10317938', Descripcion: 'Amaryllis cortada fresca kromeri' },
    { Clave: '10317939', Descripcion: 'Amaryllis cortada fresca lapacense' },
    { Clave: '10317940', Descripcion: 'Amaryllis cortada fresca leonardii' },
    { Clave: '10317941', Descripcion: 'Amaryllis cortada fresca leopoldii' },
    { Clave: '10317942', Descripcion: 'Amaryllis cortada fresca macbridei' },
    { Clave: '10317943', Descripcion: 'Amaryllis cortada fresca machupijchense' },
    { Clave: '10317944', Descripcion: 'Amaryllis cortada fresca mandonii' },
    { Clave: '10317945', Descripcion: 'Amaryllis cortada fresca minasgerais' },
    { Clave: '10317946', Descripcion: 'Amaryllis cortada fresca miniatum' },
    { Clave: '10317947', Descripcion: 'Amaryllis cortada fresca mollevillquense' },
    { Clave: '10317948', Descripcion: 'Amaryllis cortada fresca morelianum' },
    { Clave: '10317949', Descripcion: 'Amaryllis cortada fresca nelsonii' },
    { Clave: '10317950', Descripcion: 'Amaryllis cortada fresca oconoquense' },
    { Clave: '10317951', Descripcion: 'Amaryllis cortada fresca papilio' },
    { Clave: '10317952', Descripcion: 'Amaryllis cortada fresca paquichanum' },
    { Clave: '10317953', Descripcion: 'Amaryllis cortada fresca paradisiacum' },
    { Clave: '10317954', Descripcion: 'Amaryllis cortada fresca pardinum' },
    { Clave: '10317955', Descripcion: 'Amaryllis cortada fresca parodii' },
    { Clave: '10317956', Descripcion: 'Amaryllis cortada fresca petiolatum' },
    { Clave: '10317957', Descripcion: 'Amaryllis cortada fresca psittacinum' },
    { Clave: '10317958', Descripcion: 'Amaryllis cortada fresca puniceum' },
    { Clave: '10317959', Descripcion: 'Amaryllis cortada fresca reginae' },
    { Clave: '10317960', Descripcion: 'Amaryllis cortada fresca reticulatum' },
    { Clave: '10317961', Descripcion: 'Amaryllis cortada fresca rubropictum' },
    { Clave: '10317962', Descripcion: 'Amaryllis cortada fresca santacatarina' },
    { Clave: '10317963', Descripcion: 'Amaryllis cortada fresca solandraeflorum' },
    { Clave: '10317964', Descripcion: 'Amaryllis cortada fresca starkiorum' },
    { Clave: '10317965', Descripcion: 'Amaryllis cortada fresca striatum' },
    { Clave: '10317966', Descripcion: 'Amaryllis cortada fresca stylosum' },
    { Clave: '10317967', Descripcion: 'Amaryllis cortada fresca teyucuarense' },
    { Clave: '10317968', Descripcion: 'Amaryllis cortada fresca traubii' },
    { Clave: '10317969', Descripcion: 'Amaryllis cortada fresca vargasii' },
    { Clave: '10317970', Descripcion: 'Amaryllis cortada fresca variegatum' },
    { Clave: '10317971', Descripcion: 'Amaryllis cortada fresca vittatum' },
    { Clave: '10317972', Descripcion: 'Amaryllis cortada fresca yungacense' },
    { Clave: '10318000', Descripcion: 'Rubdeckia cortada fresca' },
    { Clave: '10318001', Descripcion: 'Rubdeckia cortada fresca alpicola' },
    { Clave: '10318002', Descripcion: 'Rubdeckia cortada fresca amplexicaulis' },
    { Clave: '10318003', Descripcion: 'Rubdeckia cortada fresca auriculata' },
    { Clave: '10318004', Descripcion: 'Rubdeckia cortada fresca bicolor' },
    { Clave: '10318005', Descripcion: 'Rubdeckia cortada fresca californica' },
    { Clave: '10318006', Descripcion: 'Rubdeckia cortada fresca fulgida' },
    { Clave: '10318007', Descripcion: 'Rubdeckia cortada fresca glaucescens' },
    { Clave: '10318008', Descripcion: 'Rubdeckia cortada fresca graminifolia' },
    { Clave: '10318009', Descripcion: 'Rubdeckia cortada fresca grandiflora' },
    { Clave: '10318010', Descripcion: 'Rubdeckia cortada fresca heliopsidis' },
    { Clave: '10318011', Descripcion: 'Rubdeckia cortada fresca hirta' },
    { Clave: '10318012', Descripcion: 'Rubdeckia cortada fresca klamathensis' },
    { Clave: '10318013', Descripcion: 'Rubdeckia cortada fresca laciniata' },
    { Clave: '10318014', Descripcion: 'Rubdeckia cortada fresca máxima' },
    { Clave: '10318015', Descripcion: 'Rubdeckia cortada fresca missouriensis' },
    { Clave: '10318016', Descripcion: 'Rubdeckia cortada fresca mohrii' },
    { Clave: '10318017', Descripcion: 'Rubdeckia cortada fresca mollis' },
    { Clave: '10318018', Descripcion: 'Rubdeckia cortada fresca montana' },
    { Clave: '10318019', Descripcion: 'Rubdeckia cortada fresca nítida' },
    { Clave: '10318020', Descripcion: 'Rubdeckia cortada fresca occidentalis' },
    { Clave: '10318021', Descripcion: 'Rubdeckia cortada fresca pinnata' },
    { Clave: '10318022', Descripcion: 'Rubdeckia cortada fresca scabrifolia' },
    { Clave: '10318023', Descripcion: 'Rubdeckia cortada fresca serotina' },
    { Clave: '10318024', Descripcion: 'Rubdeckia cortada fresca speciosa' },
    { Clave: '10318025', Descripcion: 'Rubdeckia cortada fresca subtomentosa' },
    { Clave: '10318026', Descripcion: 'Rubdeckia cortada fresca texana' },
    { Clave: '10318027', Descripcion: 'Rubdeckia cortada fresca triloba' },
    { Clave: '10318100', Descripcion: 'Protea cortada fresca' },
    { Clave: '10318101', Descripcion: 'Protea cortada fresca bouquet' },
    { Clave: '10318102', Descripcion: 'Protea cortada fresca cepillo botella' },
    { Clave: '10318103', Descripcion: 'Protea cortada fresca carnaval' },
    { Clave: '10318104', Descripcion: 'Protea cortada fresca follaje cordata' },
    { Clave: '10318105', Descripcion: 'Protea cortada fresca grandiceps' },
    { Clave: '10318106', Descripcion: 'Protea cortada fresca visón verde' },
    { Clave: '10318107', Descripcion: 'Protea cortada fresca hiedra' },
    { Clave: '10318108', Descripcion: 'Protea cortada fresca rey' },
    { Clave: '10318109', Descripcion: 'Protea cortada fresca nana cones' },
    { Clave: '10318110', Descripcion: 'Protea cortada fresca alfiletero anaranjada' },
    { Clave: '10318111', Descripcion: 'Protea cortada fresca alfiletero tango' },
    { Clave: '10318112', Descripcion: 'Protea cortada fresca alfiletero amarillo' },
    { Clave: '10318113', Descripcion: 'Protea cortada fresca hielo rosado' },
    { Clave: '10318114', Descripcion: 'Protea cortada fresca visón rosado' },
    { Clave: '10318115', Descripcion: 'Protea cortada fresca reina' },
    { Clave: '10318116', Descripcion: 'Protea cortada fresca repens' },
    { Clave: '10318117', Descripcion: 'Protea cortada fresca cuchara de rosas' },
    { Clave: '10318118', Descripcion: 'Protea cortada fresca silvia' },
    { Clave: '10318119', Descripcion: 'Protea cortada fresca sugarbush' },
    { Clave: '10318120', Descripcion: 'Protea cortada fresca susara' },
    { Clave: '10318121', Descripcion: 'Protea cortada fresca waratha' },
    { Clave: '10318122', Descripcion: 'Protea cortada fresca visón blanco' },
    { Clave: '10318200', Descripcion: 'leucadendron cortado fresco' },
    { Clave: '10318201', Descripcion: 'Leucadendron cortado fresco argenteum' },
    { Clave: '10318202', Descripcion: 'Leucadendron cortado fresco delicia de crema' },
    { Clave: '10318203', Descripcion: 'Leucadendron cortado fresco cumosum' },
    { Clave: '10318204', Descripcion: 'Leucadendron cortado fresco discolor' },
    { Clave: '10318205', Descripcion: 'Leucadendron cortado fresco galpini' },
    { Clave: '10318206', Descripcion: 'Leucadendron cortado fresco mina de oro' },
    { Clave: '10318207', Descripcion: 'Leucadendron cortado fresco oro inca' },
    { Clave: '10318208', Descripcion: 'Leucadendron cortado fresco bufón' },
    { Clave: '10318209', Descripcion: 'Leucadendron cortado fresco laxum' },
    { Clave: '10318210', Descripcion: 'Leucadendron cortado fresco mini' },
    { Clave: '10318211', Descripcion: 'Leucadendron cortado fresco oro patea' },
    { Clave: '10318212', Descripcion: 'Leucadendron cortado fresco petra' },
    { Clave: '10318213', Descripcion: 'Leucadendron cortado fresco plumosum' },
    { Clave: '10318214', Descripcion: 'Leucadendron cortado fresco roseta' },
    { Clave: '10318215', Descripcion: 'Leucadendron cortado fresco atardecer safari' },
    { Clave: '10318216', Descripcion: 'Leucadendron cortado fresco atardecer safari spr' },
    { Clave: '10318217', Descripcion: 'Leucadendron cortado fresco speciosa' },
    { Clave: '10318218', Descripcion: 'Leucadendron cortado fresco spray' },
    { Clave: '10318219', Descripcion: 'Leucadendron cortado fresco maravilla wilson' },
    { Clave: '10318220', Descripcion: 'Leucadendron cortado fresco yarden' },
    { Clave: '10318300', Descripcion: 'leucospermum cortado fresco' },
    { Clave: '10318301', Descripcion: 'Leucospermum cortado fresco album' },
    { Clave: '10318302', Descripcion: 'Leucospermum cortado fresco attenuatum' },
    { Clave: '10318303', Descripcion: 'Leucospermum cortado fresco calligerum' },
    { Clave: '10318304', Descripcion: 'Leucospermum cortado fresco conocarpodendron' },
    { Clave: '10318305', Descripcion: 'Leucospermum cortado fresco cordatum' },
    { Clave: '10318306', Descripcion: 'Leucospermum cortado fresco cuneiforme' },
    { Clave: '10318307', Descripcion: 'Leucospermum cortado fresco formosum' },
    { Clave: '10318308', Descripcion: 'Leucospermum cortado fresco glabrum' },
    { Clave: '10318309', Descripcion: 'Leucospermum cortado fresco grandiflorum' },
    { Clave: '10318310', Descripcion: 'Leucospermum cortado fresco harmatum' },
    { Clave: '10318311', Descripcion: 'Leucospermum cortado fresco heterophyllum' },
    { Clave: '10318312', Descripcion: 'Leucospermum cortado fresco innovans' },
    { Clave: '10318313', Descripcion: 'Leucospermum cortado fresco muirii' },
    { Clave: '10318314', Descripcion: 'Leucospermum cortado fresco oleifolium' },
    { Clave: '10318315', Descripcion: 'Leucospermum cortado fresco patersonii' },
    { Clave: '10318316', Descripcion: 'Leucospermum cortado fresco pluridens' },
    { Clave: '10318317', Descripcion: 'Leucospermum cortado fresco praemorsum' },
    { Clave: '10318318', Descripcion: 'Leucospermum cortado fresco prostratum' },
    { Clave: '10318319', Descripcion: 'Leucospermum cortado fresco rodolentum' },
    { Clave: '10318320', Descripcion: 'Leucospermum cortado fresco saxatile' },
    { Clave: '10318321', Descripcion: 'Leucospermum cortado fresco secundifolium' },
    { Clave: '10318322', Descripcion: 'Leucospermum cortado fresco tomentosus' },
    { Clave: '10318323', Descripcion: 'Leucospermum cortado fresco truncatulum' },
    { Clave: '10318324', Descripcion: 'Leucospermum cortado fresco utriculosum' },
    { Clave: '10318325', Descripcion: 'Leucospermum cortado fresco winterii' },
    { Clave: '10318326', Descripcion: 'Leucospermum cortado fresco arenarium' },
    { Clave: '10318327', Descripcion: 'Leucospermum cortado fresco bolusii' },
    { Clave: '10318328', Descripcion: 'Leucospermum cortado fresco catherinae' },
    { Clave: '10318329', Descripcion: 'Leucospermum cortado fresco conocarpum' },
    { Clave: '10318330', Descripcion: 'Leucospermum cortado fresco cordifolium' },
    { Clave: '10318331', Descripcion: 'Leucospermum cortado fresco erubescens' },
    { Clave: '10318332', Descripcion: 'Leucospermum cortado fresco gerrardii' },
    { Clave: '10318333', Descripcion: 'Leucospermum cortado fresco gracile' },
    { Clave: '10318334', Descripcion: 'Leucospermum cortado fresco gueinzii' },
    { Clave: '10318335', Descripcion: 'Leucospermum cortado fresco harpagonatum' },
    { Clave: '10318336', Descripcion: 'Leucospermum cortado fresco hypophyllocarpodendron' },
    { Clave: '10318337', Descripcion: 'Leucospermum cortado fresco lineare' },
    { Clave: '10318338', Descripcion: 'Leucospermum cortado fresco mundii' },
    { Clave: '10318339', Descripcion: 'Leucospermum cortado fresco parile' },
    { Clave: '10318340', Descripcion: 'Leucospermum cortado fresco pendunculatum' },
    { Clave: '10318341', Descripcion: 'Leucospermum cortado fresco praecox' },
    { Clave: '10318342', Descripcion: 'Leucospermum cortado fresco profugum' },
    { Clave: '10318343', Descripcion: 'Leucospermum cortado fresco reflexum' },
    { Clave: '10318344', Descripcion: 'Leucospermum cortado fresco royenifolium' },
    { Clave: '10318345', Descripcion: 'Leucospermum cortado fresco saxosum' },
    { Clave: '10318346', Descripcion: 'Leucospermum cortado fresco spathulatum' },
    { Clave: '10318347', Descripcion: 'Leucospermum cortado fresco tottum' },
    { Clave: '10318348', Descripcion: 'Leucospermum cortado fresco truncatum' },
    { Clave: '10318349', Descripcion: 'Leucospermum cortado fresco vestitum' },
    { Clave: '10318350', Descripcion: 'Leucospermum cortado fresco wittebergense' },
    { Clave: '10321500', Descripcion: 'Agapanto cortado fresco' },
    { Clave: '10321501', Descripcion: 'Agapanto cortado fresco azul' },
    { Clave: '10321502', Descripcion: 'Agapanto cortado fresco blanco' },
    { Clave: '10321600', Descripcion: 'Alchimilla cortada fresca' },
    { Clave: '10321601', Descripcion: 'Alchimilla cortada fresca capa de dama' },
    { Clave: '10321602', Descripcion: 'Alchimilla cortada fresca robustica' },
    { Clave: '10321700', Descripcion: 'Astilbe cortado fresco' },
    { Clave: '10321701', Descripcion: 'Astilbe cortado fresco rosado fuerte' },
    { Clave: '10321702', Descripcion: 'Astilbe cortado fresco rosado claro' },
    { Clave: '10321703', Descripcion: 'Astilbe cortado fresco rojo' },
    { Clave: '10321704', Descripcion: 'Astilbe cortado fresco blanco' },
    { Clave: '10321800', Descripcion: 'angélica cortada fresca' },
    { Clave: '10321801', Descripcion: 'Angélica cortada fresca gigas' },
    { Clave: '10321802', Descripcion: 'Angélica cortada fresca sylvestris' },
    { Clave: '10321900', Descripcion: 'ajenjo cortado fresco' },
    { Clave: '10321901', Descripcion: 'Ajenjo cortado fresco verde' },
    { Clave: '10321902', Descripcion: 'Ajenjo cortado fresco rey dorado' },
    { Clave: '10322000', Descripcion: 'Flor de alcachofa cortada fresca' },
    { Clave: '10322001', Descripcion: 'Flor de alcachofa cortada fresca chocolate' },
    { Clave: '10322002', Descripcion: 'Flor de alcachofa cortada fresca verde' },
    { Clave: '10322003', Descripcion: 'Flor de alcachofa cortada fresca púrpura o floreciente' },
    { Clave: '10322100', Descripcion: 'Astrantia cortada fresca' },
    { Clave: '10322101', Descripcion: 'Astrantia cortada fresca rosada' },
    { Clave: '10322102', Descripcion: 'Astrantia cortada fresca blanca' },
    { Clave: '10322200', Descripcion: 'Flor de banano cortada fresca' },
    { Clave: '10322201', Descripcion: 'Flor de banano cortada fresca anaranjada' },
    { Clave: '10322202', Descripcion: 'Flor de banano cortada fresca antorcha anaranjada' },
    { Clave: '10322203', Descripcion: 'Flor de banano cortada fresca púrpura' },
    { Clave: '10322300', Descripcion: 'Baptisia cortada fresca' },
    { Clave: '10322301', Descripcion: 'Baptisia cortada fresca australis' },
    { Clave: '10322302', Descripcion: 'Baptisia cortada fresca sphaerocarpa' },
    { Clave: '10322400', Descripcion: 'Boronia cortada fresca' },
    { Clave: '10322401', Descripcion: 'Boronia cortada fresca rosada' },
    { Clave: '10322402', Descripcion: 'Boronia cortada fresca amarilla' },
    { Clave: '10322500', Descripcion: 'Bromelia cortada fresca' },
    { Clave: '10322501', Descripcion: 'Bromelia cortada fresca amarilla reg' },
    { Clave: '10322502', Descripcion: 'Bromelia cortada fresca roja reg' },
    { Clave: '10322600', Descripcion: 'Brunia cortada fresca' },
    { Clave: '10322601', Descripcion: 'Brunia albiflora cortada fresca' },
    { Clave: '10322602', Descripcion: 'Brunia albiflora cortada fresca verde' },
    { Clave: '10322603', Descripcion: 'Brunia cortada fresca rocío de plata' },
    { Clave: '10322700', Descripcion: 'Catalea cortada fresca' },
    { Clave: '10322701', Descripcion: 'Calatea cortada fresca cigarro' },
    { Clave: '10322702', Descripcion: 'Calatea cortada fresca hielo verde' },
    { Clave: '10322703', Descripcion: 'Calatea cortada fresca serpiente cascabel' },
    { Clave: '10322800', Descripcion: 'Calcynia cortada fresca' },
    { Clave: '10322801', Descripcion: 'Calcynia cortada fresca rosada' },
    { Clave: '10322802', Descripcion: 'Calcynia cortada fresca princesa' },
    { Clave: '10322803', Descripcion: 'Calcynia cortada fresca blanca' },
    { Clave: '10322900', Descripcion: 'Caléndula cortada fresca' },
    { Clave: '10322901', Descripcion: 'Caléndula cortada fresca anaranjada' },
    { Clave: '10322902', Descripcion: 'Caléndula cortada fresca amarilla' },
    { Clave: '10323000', Descripcion: 'Campanilla cortada fresca' },
    { Clave: '10323001', Descripcion: 'Campanilla cortada fresca azul' },
    { Clave: '10323002', Descripcion: 'Campanilla cortada fresca rosada' },
    { Clave: '10323003', Descripcion: 'Campanilla cortada fresca blanca' },
    { Clave: '10323100', Descripcion: 'Cestrum cortado fresco' },
    { Clave: '10323101', Descripcion: 'Cestrum cortado fresco rojo' },
    { Clave: '10323102', Descripcion: 'Cestrum cortado fresco rojo zohar' },
    { Clave: '10323103', Descripcion: 'Cestrum cortado fresco amarillo' },
    { Clave: '10323200', Descripcion: 'Chasmante cortada fresca' },
    { Clave: '10323201', Descripcion: 'Chasmante floribunda cortada fresca amarilla' },
    { Clave: '10323202', Descripcion: 'Chasmante floribunda cortada fresca anaranjada' },
    { Clave: '10323300', Descripcion: 'Costus cortada fresca' },
    { Clave: '10323301', Descripcion: 'Costus barbada cortada fresca' },
    { Clave: '10323302', Descripcion: 'Costus cortada fresca cabeza de indio' },
    { Clave: '10323400', Descripcion: 'Vara de San José cortada fresca' },
    { Clave: '10323401', Descripcion: 'Vara de san josé lucifer cortada fresca' },
    { Clave: '10323402', Descripcion: 'Vara de san josé cortada fresca vainas' },
    { Clave: '10323403', Descripcion: 'Vara de san josé cortada fresca amarilla' },
    { Clave: '10323500', Descripcion: 'Lirio del Fuego cortado fresco' },
    { Clave: '10323501', Descripcion: 'Lirio del fuego cortado fresco anaranjado brillante' },
    { Clave: '10323502', Descripcion: 'Lirio del fuego cortado fresco crema' },
    { Clave: '10323503', Descripcion: 'Lirio del fuego cortado fresco anaranjado' },
    { Clave: '10323504', Descripcion: 'Lirio del fuego cortado fresco amarillo' },
    { Clave: '10323600', Descripcion: 'Flor del muelle cortada fresca' },
    { Clave: '10323601', Descripcion: 'Flor del muelle cortada fresca verde' },
    { Clave: '10323602', Descripcion: 'Flor del muelle cortada fresca roja' },
    { Clave: '10323700', Descripcion: 'Planta erizo cortada fresca' },
    { Clave: '10323701', Descripcion: 'Planta erizo cortada fresca tintura negra' },
    { Clave: '10323702', Descripcion: 'Planta erizo cortada fresca tintura anaranjada' },
    { Clave: '10323800', Descripcion: 'Manzanilla cortada fresca' },
    { Clave: '10323801', Descripcion: 'Manzanilla cortada fresca sencilla vegmo' },
    { Clave: '10323802', Descripcion: 'Manzanilla cortada fresca doble blanca' },
    { Clave: '10323803', Descripcion: 'Manzanilla cortada fresca bola de nieve' },
    { Clave: '10323804', Descripcion: 'Manzanilla cortada fresca blanca' },
    { Clave: '10323900', Descripcion: 'Nomeolvides cortada fresca' },
    { Clave: '10323901', Descripcion: 'Nomeolvides cortada fresca azul' },
    { Clave: '10323902', Descripcion: 'Nomeolvides cortada fresca blanca' },
    { Clave: '10324000', Descripcion: 'Gallardia cortada fresca' },
    { Clave: '10324001', Descripcion: 'Gallardia cortada fresca anaranjada' },
    { Clave: '10324002', Descripcion: 'Gallardia cortada fresca amarilla' },
    { Clave: '10324100', Descripcion: 'Genciana cortada fresca' },
    { Clave: '10324101', Descripcion: 'Genciana cortada fresca azul' },
    { Clave: '10324102', Descripcion: 'Genciana cortada fresca blanca' },
    { Clave: '10324200', Descripcion: 'Gladiolo cortado fresco' },
    { Clave: '10324201', Descripcion: 'Gladiolo cortado fresco glamini rosado' },
    { Clave: '10324202', Descripcion: 'Gladiolo cortado fresco glamini rojo' },
    { Clave: '10324300', Descripcion: 'Gloriosa cortada fresca' },
    { Clave: '10324301', Descripcion: 'Gloriosa cortada fresca anaranjada' },
    { Clave: '10324302', Descripcion: 'Gloriosa cortada fresca roja' },
    { Clave: '10324303', Descripcion: 'Gloriosa cortada fresca amarilla' },
    { Clave: '10324400', Descripcion: 'Violeta silvestre cortada fresca' },
    { Clave: '10324401', Descripcion: 'Violeta silvestre cortada fresca anaranjada' },
    { Clave: '10324402', Descripcion: 'Violeta silvestre cortada fresca roja' },
    { Clave: '10324500', Descripcion: 'Eléboro cortado fresco' },
    { Clave: '10324501', Descripcion: 'Eléboro cortado fresco verde' },
    { Clave: '10324502', Descripcion: 'Eléboro cortado fresco rayo de luna' },
    { Clave: '10324600', Descripcion: 'Ixia cortada fresca' },
    { Clave: '10324601', Descripcion: 'Ixia cortada fresca rosada' },
    { Clave: '10324602', Descripcion: 'Ixia cortada fresca blanca' },
    { Clave: '10324700', Descripcion: 'Liatrises cortadas frescas' },
    { Clave: '10324701', Descripcion: 'Liatris cortada fresca púrpura' },
    { Clave: '10324702', Descripcion: 'Liatris cortada fresca spray' },
    { Clave: '10324703', Descripcion: 'Liatris cortada fresca blanca' },
    { Clave: '10324800', Descripcion: 'Lysimachia cortada fresca' },
    { Clave: '10324801', Descripcion: 'Lysimachia punctata cortada fresca' },
    { Clave: '10324802', Descripcion: 'Lysimachia vulgaris cortada fresca' },
    { Clave: '10324803', Descripcion: 'Lysimachia blanca cortada fresca' },
    { Clave: '10324804', Descripcion: 'Lysimachia amarilla cortada fresca' },
    { Clave: '10324900', Descripcion: 'Maraca cortada fresca' },
    { Clave: '10324901', Descripcion: 'Maraca cortada fresca marrón' },
    { Clave: '10324902', Descripcion: 'Maraca cortada fresca shampoo ginger' },
    { Clave: '10325000', Descripcion: 'Caléndula cortada fresca' },
    { Clave: '10325001', Descripcion: 'Caléndula cortada fresca francesa' },
    { Clave: '10325002', Descripcion: 'Caléndula cortada fresca verde' },
    { Clave: '10325003', Descripcion: 'Caléndula (marigold) cortada fresca anaranjada' },
    { Clave: '10325004', Descripcion: 'Caléndula (marigold) cortada fresca amarilla' },
    { Clave: '10325100', Descripcion: 'Mimosa cortada fresca' },
    { Clave: '10325101', Descripcion: 'Mimosa cortada fresca azul o púrpura' },
    { Clave: '10325102', Descripcion: 'Mimosa dedo cortada fresca' },
    { Clave: '10325103', Descripcion: 'Mimosa cortada fresca floribunda o italia' },
    { Clave: '10325104', Descripcion: 'Mimosa cortada fresca mirandola' },
    { Clave: '10325200', Descripcion: 'Nerina cortada fresca' },
    { Clave: '10325201', Descripcion: 'Nerina cortada fresca rosada' },
    { Clave: '10325202', Descripcion: 'Nerina samiensis blanca' },
    { Clave: '10325300', Descripcion: 'Flor de nogal cortada fresca' },
    { Clave: '10325301', Descripcion: 'Flor colgante de nogal blanco cortada fresca' },
    { Clave: '10325302', Descripcion: 'Flor sin hojas de nogal blanco cortada fresca' },
    { Clave: '10325303', Descripcion: 'Flor erecta de nogal blanco brasileño cortada fresca' },
    { Clave: '10325400', Descripcion: 'Phlox cortada fresca' },
    { Clave: '10325401', Descripcion: 'Phlox cortada fresca rosado oscur' },
    { Clave: '10325402', Descripcion: 'Phlox cortada fresca lavanda' },
    { Clave: '10325403', Descripcion: 'Phlox cortada fresca rosado claro' },
    { Clave: '10325404', Descripcion: 'Phlox cortada fresca blanca' },
    { Clave: '10325500', Descripcion: 'physostegia cortada fresca' },
    { Clave: '10325501', Descripcion: 'Physostegia cortada fresca rosada' },
    { Clave: '10325502', Descripcion: 'Physostegia cortada fresca vainas' },
    { Clave: '10325503', Descripcion: 'Physostegia cortada fresca blanca' },
    { Clave: '10325600', Descripcion: 'Saponaria cortada fresca' },
    { Clave: '10325601', Descripcion: 'Saponaria cortada fresca rosada' },
    { Clave: '10325602', Descripcion: 'Saponaria cortada fresca blanca' },
    { Clave: '10325700', Descripcion: 'Sarracenias cortadas frescas' },
    { Clave: '10325701', Descripcion: 'Sarracenia cortada fresca flava rugelii' },
    { Clave: '10325800', Descripcion: 'Sicilia cortada fresca' },
    { Clave: '10325801', Descripcion: 'Sicilia cortada fresca campanulata azul' },
    { Clave: '10325802', Descripcion: 'Sicilia cortada fresca campanulata rosada' },
    { Clave: '10325803', Descripcion: 'Sicilia cortada fresca campanulata blanca' },
    { Clave: '10325804', Descripcion: 'Sicilia cortada fresca peruana' },
    { Clave: '10325900', Descripcion: 'Sedum cortada fresca' },
    { Clave: '10325901', Descripcion: 'Sedum cortada fresca marrón' },
    { Clave: '10325902', Descripcion: 'Sedum cortada fresca verde' },
    { Clave: '10325903', Descripcion: 'Sedum cortada fresca rosada' },
    { Clave: '10325904', Descripcion: 'Sedum cortada fresca roja' },
    { Clave: '10326000', Descripcion: 'Especies individuales o variedades de flores cortadas frescas' },
    { Clave: '10326001', Descripcion: 'Agrsotema cortada fresca' },
    { Clave: '10326002', Descripcion: 'Kniphophia o assegai poker cortada fresca' },
    { Clave: '10326003', Descripcion: 'Bellis perennis cortada fresca' },
    { Clave: '10326004', Descripcion: 'Campana de irlanda o molucella cortada fresca' },
    { Clave: '10326005', Descripcion: 'Ave del paraíso cortada fresca' },
    { Clave: '10326006', Descripcion: 'Novia sonrojada cortada fresca' },
    { Clave: '10326007', Descripcion: 'Buddleia o arbusto mariposa cortada fresca' },
    { Clave: '10326008', Descripcion: 'Bupleurum griffithii cortada fresca' },
    { Clave: '10326009', Descripcion: 'California ginesta cortada fresca' },
    { Clave: '10326010', Descripcion: 'Callicarpa púrpura cortada fresca' },
    { Clave: '10326011', Descripcion: 'Campanilla blanca cortada fresca' },
    { Clave: '10326012', Descripcion: 'Penacho dulce cortada fresca' },
    { Clave: '10326013', Descripcion: 'Cariopteris cortada fresca' },
    { Clave: '10326014', Descripcion: 'Centaurea o marco polo cortada fresca' },
    { Clave: '10326015', Descripcion: 'Linterna china cortada fresca' },
    { Clave: '10326016', Descripcion: 'Clematis recta purpurea cortada fresca' },
    { Clave: '10326017', Descripcion: 'Cleome spinosa cortada fresca' },
    { Clave: '10326018', Descripcion: 'Coreopsis cortada fresca' },
    { Clave: '10326019', Descripcion: 'Farolitos de la virgen azul cortada fresca' },
    { Clave: '10326020', Descripcion: 'Cosmos chocolate cortada fresca' },
    { Clave: '10326021', Descripcion: 'Cotinus coggygria cortada fresca' },
    { Clave: '10326022', Descripcion: 'Craspedia o billy bolas cortada fresca' },
    { Clave: '10326023', Descripcion: 'Deutzia alta cortada fresca' },
    { Clave: '10326024', Descripcion: 'Diosma cortada fresca' },
    { Clave: '10326025', Descripcion: 'Echeveria suculenta chupahuevos cortada fresca' },
    { Clave: '10326026', Descripcion: 'Echinacea purpurea cortada fresca' },
    { Clave: '10326027', Descripcion: 'Edelweiss cortada fresca' },
    { Clave: '10326028', Descripcion: 'Erythronium pagoda cortada fresca' },
    { Clave: '10326029', Descripcion: 'Flor de eucalipto cortada fresca' },
    { Clave: '10326030', Descripcion: 'Eucaris o lirio del amazonas cortado fresco' },
    { Clave: '10326031', Descripcion: 'Eucomis o lirio de piña cortado fresco' },
    { Clave: '10326032', Descripcion: 'Eupatorium maculatum cortado fresco' },
    { Clave: '10326033', Descripcion: 'Filipendula cortada fresca' },
    { Clave: '10326034', Descripcion: 'Digitalis cortado fresco' },
    { Clave: '10326035', Descripcion: 'Gilia globo cortada fresca' },
    { Clave: '10326036', Descripcion: 'Globularia de ojo azul cortada fresca' },
    { Clave: '10326037', Descripcion: 'Washington hawthorne cortada fresca' },
    { Clave: '10326038', Descripcion: 'Helenio cortada fresca' },
    { Clave: '10326039', Descripcion: 'Helianto cortada fresca' },
    { Clave: '10326040', Descripcion: 'Herperis matronalis cortada fresca' },
    { Clave: '10326041', Descripcion: 'Houttuynia cordata chameleon cortada fresca' },
    { Clave: '10326042', Descripcion: 'Jacinto con bulbo cortada fresca' },
    { Clave: '10326043', Descripcion: 'Maiz indio cortado fresco' },
    { Clave: '10326044', Descripcion: 'Aro cortado fresco' },
    { Clave: '10326045', Descripcion: 'Arbol del cielo japonés cortado fresco' },
    { Clave: '10326046', Descripcion: 'Enredadera de jazmin florecida cortada fresca' },
    { Clave: '10326047', Descripcion: 'Jatropha curcas o pólvora cortada fresca' },
    { Clave: '10326048', Descripcion: 'Knautia cortada fresca' },
    { Clave: '10326049', Descripcion: 'Kochia sedifolia cortada fresca' },
    { Clave: '10326050', Descripcion: 'Lachenalia romaud cortada fresca' },
    { Clave: '10326051', Descripcion: 'Flor de oereja de oveja cortada fresca' },
    { Clave: '10326052', Descripcion: 'Lavanda cortada fresca' },
    { Clave: '10326053', Descripcion: 'Leucocoryne speciosa cortada fresca' },
    { Clave: '10326054', Descripcion: 'Lythrum cortada fresca' },
    { Clave: '10326055', Descripcion: 'Malva zebrina cortada fresca' },
    { Clave: '10326056', Descripcion: 'Margarita blanca cortada fresca' },
    { Clave: '10326057', Descripcion: 'Montbretia amarilla cortada fresca' },
    { Clave: '10326058', Descripcion: 'Nebelia cortada fresca' },
    { Clave: '10326059', Descripcion: 'Nicotiana cortada fresca' },
    { Clave: '10326060', Descripcion: 'Nigella damascena o amor en la niebla cortada fresca' },
    { Clave: '10326061', Descripcion: 'Vainas nigella cortada fresca' },
    { Clave: '10326062', Descripcion: 'Orquídea de monja cortada fresca' },
    { Clave: '10326063', Descripcion: 'Orquídea verde paphiopedilum' },
    { Clave: '10326064', Descripcion: 'Paranomus cortada fresca' },
    { Clave: '10326065', Descripcion: 'Penstemon husker rojo cortado fresco' },
    { Clave: '10326066', Descripcion: 'Manzana peruana cortada fresca' },
    { Clave: '10326067', Descripcion: 'Phlomis sarnia cortada fresca' },
    { Clave: '10326068', Descripcion: 'Didiscus o flor de encaje rosada cortada fresca' },
    { Clave: '10326069', Descripcion: 'Platycodon o flor globo cortada fresca' },
    { Clave: '10326070', Descripcion: 'Retzia capensis cortada fresca' },
    { Clave: '10326071', Descripcion: 'Ricino común cortado fresco' },
    { Clave: '10326072', Descripcion: 'Nieve en el monte cortada fresca' },
    { Clave: '10326073', Descripcion: 'Solidago teñida cortada fresca' },
    { Clave: '10326074', Descripcion: 'Scilla blanca cortada fresca' },
    { Clave: '10326075', Descripcion: 'Stachys byzantina cortada fresca' },
    { Clave: '10326076', Descripcion: 'Flor de paja cortada fresca' },
    { Clave: '10326077', Descripcion: 'Oscularia suculenta cortada fresca' },
    { Clave: '10326078', Descripcion: 'Flor de tillasandia cortada fresca' },
    { Clave: '10326079', Descripcion: 'Triteleia cortada fresca' },
    { Clave: '10326080', Descripcion: 'Tritoma naranja o chuzo caliente cortada fresca roja' },
    { Clave: '10326081', Descripcion: 'Veronicastrum virginiana cortada fresca' },
    { Clave: '10326082', Descripcion: 'Bromelia vriesea splendens cortada fresca' },
    { Clave: '10326084', Descripcion: 'Hipericim o hierba de san juan cortada fresca' },
    { Clave: '10326085', Descripcion: 'Spirea cortada fresca' },
    { Clave: '10326086', Descripcion: 'Yerba de san bonifacio cortada fresca' },
    { Clave: '10326100', Descripcion: 'Sello de salomón (polygonato) cortada fresca' },
    { Clave: '10326101', Descripcion: 'Sello de salomón (polygonato) falso cortada fresca' },
    { Clave: '10326102', Descripcion: 'Sello de salomón (polygonato) variegado cortada fresca' },
    { Clave: '10326200', Descripcion: 'Tanaceto cortado fresco' },
    { Clave: '10326201', Descripcion: 'Tanaceto cortado fresco amazonas' },
    { Clave: '10326202', Descripcion: 'Tanaceto cortado fresco victoria doble blanco' },
    { Clave: '10326203', Descripcion: 'Tanaceto cortado fresco victoria sencillo blanco' },
    { Clave: '10326204', Descripcion: 'Tanaceto cortado fresco vegmo amarillo' },
    { Clave: '10326300', Descripcion: 'Traquelio cortado fresco' },
    { Clave: '10326301', Descripcion: 'Trachelium jade cortada fresca' },
    { Clave: '10326302', Descripcion: 'Trachelium púrpura cortada fresca' },
    { Clave: '10326303', Descripcion: 'Trachelium blanca cortada fresca' },
    { Clave: '10326400', Descripcion: 'Tuberosas cortadas frescas' },
    { Clave: '10326401', Descripcion: 'Tuberosa cortada fresca doble' },
    { Clave: '10326402', Descripcion: 'Tuberosa cortada fresca sencilla' },
    { Clave: '10326500', Descripcion: 'Tweedias cortadas frescas' },
    { Clave: '10326501', Descripcion: 'Tweedia azul cortada fresca' },
    { Clave: '10326502', Descripcion: 'Tweedia blanca cortada fresca' },
    { Clave: '10326600', Descripcion: 'Verónicas cortadas frescas' },
    { Clave: '10326601', Descripcion: 'Verónica cortada fresca rosada' },
    { Clave: '10326602', Descripcion: 'Verónica cortada fresca púrpura' },
    { Clave: '10326603', Descripcion: 'Verónica cortada fresca blanca' },
    { Clave: '10326700', Descripcion: 'Watsonias cortadas frescas' },
    { Clave: '10326701', Descripcion: 'Watsonia cortada fresca anaranjada' },
    { Clave: '10326702', Descripcion: 'Watsonia cortada fresca rosada' },
    { Clave: '10326703', Descripcion: 'Watsonia cortada fresca roja' },
    { Clave: '10326704', Descripcion: 'Watsonia cortada fresca blanca' },
    { Clave: '10331500', Descripcion: 'Crisantemo cortado fresco pompón' },
    { Clave: '10331501', Descripcion: 'Crisantemo cortado fresco pompón delirock' },
    { Clave: '10331502', Descripcion: 'Crisantemo cortado fresco pompón discovery' },
    { Clave: '10331503', Descripcion: 'Crisantemo cortado fresco pompón focus' },
    { Clave: '10331504', Descripcion: 'Crisantemo cortado fresco pompón jeanne' },
    { Clave: '10331505', Descripcion: 'Crisantemo cortado fresco pompón lady' },
    { Clave: '10331506', Descripcion: 'Crisantemo cortado fresco pompón leidi' },
    { Clave: '10331507', Descripcion: 'Crisantemo cortado fresco pompón lexy' },
    { Clave: '10331508', Descripcion: 'Crisantemo cortado fresco pompón ole' },
    { Clave: '10331509', Descripcion: 'Crisantemo cortado fresco pompón revise' },
    { Clave: '10331510', Descripcion: 'Crisantemo cortado fresco pompón estadista' },
    { Clave: '10331511', Descripcion: 'Crisantemo cortado fresco pompón dulce' },
    { Clave: '10331512', Descripcion: 'Crisantemo cortado fresco pompón yoko ono' },
    { Clave: '10331513', Descripcion: 'Crisantemo cortado fresco pompón zip' },
    { Clave: '10331600', Descripcion: 'Crisantemo cortado fresco pompón margarita' },
    { Clave: '10331601', Descripcion: 'Crisantemo cortado fresco pompón artista rosado' },
    { Clave: '10331602', Descripcion: 'Crisantemo cortado fresco pompón artista amarillo' },
    { Clave: '10331603', Descripcion: 'Crisantemo cortado fresco pompón atlantis rosado' },
    { Clave: '10331604', Descripcion: 'Crisantemo cortado fresco pompón atlantis blanco' },
    { Clave: '10331605', Descripcion: 'Crisantemo cortado fresco pompón atlantis amarillo' },
    { Clave: '10331606', Descripcion: 'Crisantemo cortado fresco pompón bennie jolink' },
    { Clave: '10331607', Descripcion: 'Crisantemo cortado fresco pompón bennie jolink amarillo' },
    { Clave: '10331608', Descripcion: 'Crisantemo cortado fresco pompón managua bronce' },
    { Clave: '10331609', Descripcion: 'Crisantemo cortado fresco pompón clave' },
    { Clave: '10331610', Descripcion: 'Crisantemo cortado fresco pompón ficción coral' },
    { Clave: '10331611', Descripcion: 'Crisantemo cortado fresco pompón cumbia' },
    { Clave: '10331612', Descripcion: 'Crisantemo cortado fresco pompón cantata oscura' },
    { Clave: '10331613', Descripcion: 'Crisantemo cortado fresco pompón lineker oscuro' },
    { Clave: '10331614', Descripcion: 'Crisantemo cortado fresco pompón dipper' },
    { Clave: '10331615', Descripcion: 'Crisantemo cortado fresco pompón rosado elite' },
    { Clave: '10331616', Descripcion: 'Crisantemo cortado fresco pompón blanco elite' },
    { Clave: '10331617', Descripcion: 'Crisantemo cortado fresco pompón amarillo elite' },
    { Clave: '10331618', Descripcion: 'Crisantemo cortado fresco pompón factor' },
    { Clave: '10331619', Descripcion: 'Crisantemo cortado fresco pompón ficción' },
    { Clave: '10331620', Descripcion: 'Crisantemo cortado fresco pompón fuerza' },
    { Clave: '10331621', Descripcion: 'Crisantemo cortado fresco pompón reagan mejorado' },
    { Clave: '10331622', Descripcion: 'Crisantemo cortado fresco pompón life' },
    { Clave: '10331623', Descripcion: 'Crisantemo cortado fresco pompón managua anaranjado' },
    { Clave: '10331624', Descripcion: 'Crisantemo cortado fresco pompón novedsd bronce cocarde' },
    { Clave: '10331625', Descripcion: 'Crisantemo cortado fresco pompón reagan anaranjado' },
    { Clave: '10331626', Descripcion: 'Crisantemo cortado fresco pompón orinoco' },
    { Clave: '10331627', Descripcion: 'Crisantemo cortado fresco pompón petra' },
    { Clave: '10331628', Descripcion: 'Crisantemo cortado fresco pompón balsas rosado' },
    { Clave: '10331629', Descripcion: 'Crisantemo cortado fresco pompón mona lisa rosado' },
    { Clave: '10331630', Descripcion: 'Crisantemo cortado fresco pompón reagan rosado' },
    { Clave: '10331631', Descripcion: 'Crisantemo cortado fresco pompón marfil reagan' },
    { Clave: '10331632', Descripcion: 'Crisantemo cortado fresco pompón reagan rosado' },
    { Clave: '10331633', Descripcion: 'Crisantemo cortado fresco pompón rebasco' },
    { Clave: '10331634', Descripcion: 'Crisantemo cortado fresco pompón redock' },
    { Clave: '10331635', Descripcion: 'Crisantemo cortado fresco pompón salmón lineker' },
    { Clave: '10331636', Descripcion: 'Crisantemo cortado fresco pompón sheba' },
    { Clave: '10331637', Descripcion: 'Crisantemo cortado fresco pompón sirius' },
    { Clave: '10331638', Descripcion: 'Crisantemo cortado fresco pompón reagan espléndido' },
    { Clave: '10331639', Descripcion: 'Crisantemo cortado fresco pompón reagan soleado' },
    { Clave: '10331640', Descripcion: 'Crisantemo cortado fresco pompón tina' },
    { Clave: '10331641', Descripcion: 'Crisantemo cortado fresco pompón vero' },
    { Clave: '10331642', Descripcion: 'Crisantemo cortado fresco pompón volare' },
    { Clave: '10331643', Descripcion: 'Crisantemo cortado fresco pompón vida blanca' },
    { Clave: '10331644', Descripcion: 'Crisantemo cortado fresco pompón regan blanco' },
    { Clave: '10331645', Descripcion: 'Crisantemo cortado fresco pompón rino blanco' },
    { Clave: '10331646', Descripcion: 'Crisantemo cortado fresco pompón vero amarillo' },
    { Clave: '10331647', Descripcion: 'Crisantemo cortado fresco pompón zenith' },
    { Clave: '10331700', Descripcion: 'Crisantemo cortado fresco espollado cremon' },
    { Clave: '10331701', Descripcion: 'Crisantemo cortado fresco espollado cremon annecy oscura' },
    { Clave: '10331702', Descripcion: 'Crisantemo cortado fresco espollado cremon' },
    { Clave: '10331703', Descripcion: 'Crisantemo cortado fresco espollado cremon atlantis rosado' },
    { Clave: '10331704', Descripcion: 'Crisantemo cortado fresco espollado cremon eleonora bronce' },
    { Clave: '10331705', Descripcion: 'Crisantemo cortado fresco espollado cremon eleonora lila' },
    { Clave: '10331706', Descripcion: 'Crisantemo cortado fresco espollado cremon eleonora rosado' },
    { Clave: '10331707', Descripcion: 'Crisantemo cortado fresco espollado cremon eleonora nieve' },
    { Clave: '10331708', Descripcion: 'Crisantemo cortado fresco espollado cremon eleonora amarillo' },
    { Clave: '10331709', Descripcion: 'Crisantemo cortado fresco espollado cremon idea' },
    { Clave: '10331710', Descripcion: 'Crisantemo cortado fresco espollado cremon ivanna púrpura' },
    { Clave: '10331711', Descripcion: 'Crisantemo cortado fresco espollado cremon minka rosado' },
    { Clave: '10331712', Descripcion: 'Crisantemo cortado fresco espollado cremon listo' },
    { Clave: '10331713', Descripcion: 'Crisantemo cortado fresco espollado cremon sinatra' },
    { Clave: '10331714', Descripcion: 'Crisantemo cortado fresco rover rojo' },
    { Clave: '10331800', Descripcion: 'Crisantemo cortado fresco espollado football' },
    { Clave: '10331801', Descripcion: 'Crisantemo cortado fresco espollado blaze' },
    { Clave: '10331802', Descripcion: 'Crisantemo cortado fresco espollado beso de football' },
    { Clave: '10331803', Descripcion: 'Crisantemo cortado fresco espollado football lavanda / rosado' },
    { Clave: '10331804', Descripcion: 'Crisantemo cortado fresco espollado football resouci' },
    { Clave: '10331805', Descripcion: 'Crisantemo cortado fresco espollado footbal blanco' },
    { Clave: '10331806', Descripcion: 'Crisantemo cortado fresco espollado football amarillo' },
    { Clave: '10331807', Descripcion: 'Crisantemo cortado fresco espollado promenade' },
    { Clave: '10331808', Descripcion: 'Crisantemo cortado fresco espollado rebonnet' },
    { Clave: '10331809', Descripcion: 'Crisantemo cortado fresco espollado reflex' },
    { Clave: '10331810', Descripcion: 'Crisantemo cortado fresco espollado residence' },
    { Clave: '10331811', Descripcion: 'Crisantemo cortado fresco espollado resomee perla' },
    { Clave: '10331812', Descripcion: 'Crisantemo cortado fresco espollado resouci' },
    { Clave: '10331900', Descripcion: 'Crisantemo spider cortado fresco' },
    { Clave: '10331901', Descripcion: 'Crisantemo spider cortado fresco anastasia bronce' },
    { Clave: '10331902', Descripcion: 'Crisantemo spider cortado fresco anastasia bronce oscuro' },
    { Clave: '10331903', Descripcion: 'Crisantemo spider cortado fresco anastasia verde' },
    { Clave: '10331904', Descripcion: 'Crisantemo spider cortado fresco anastasia lila' },
    { Clave: '10331905', Descripcion: 'Crisantemo spider cortado fresco anastasia rosado' },
    { Clave: '10331906', Descripcion: 'Crisantemo spider cortado fresco anastasia púrpura' },
    { Clave: '10331907', Descripcion: 'Crisantemo spider cortado fresco anastasia soleado' },
    { Clave: '10331908', Descripcion: 'Crisantemo spider cortado fresco anastasia blanco' },
    { Clave: '10331909', Descripcion: 'Crisantemo spider cortado fresco bradford' },
    { Clave: '10331910', Descripcion: 'Crisantemo spider cortado fresco delistar blanco' },
    { Clave: '10331911', Descripcion: 'Crisantemo spider cortado fresco delistar amarillo' },
    { Clave: '10331912', Descripcion: 'Crisantemo spider cortado fresco minka' },
    { Clave: '10331913', Descripcion: 'Crisantemo spider cortado fresco natasha soleado' },
    { Clave: '10331914', Descripcion: 'Crisantemo spider cortado fresco pirouette' },
    { Clave: '10331915', Descripcion: 'Crisantemo spider cortado fresco reflejo' },
    { Clave: '10331916', Descripcion: 'Crisantemo spider cortado fresco regata' },
    { Clave: '10331917', Descripcion: 'Crisantemo spider cortado fresco render' },
    { Clave: '10331918', Descripcion: 'Crisantemo spider cortado fresco repertorio' },
    { Clave: '10331919', Descripcion: 'Crisantemo spider cortado fresco resolute' },
    { Clave: '10331920', Descripcion: 'Crisantemo spider cortado fresco resomac' },
    { Clave: '10331921', Descripcion: 'Crisantemo spider cortado fresco trébol' },
    { Clave: '10331922', Descripcion: 'Crisantemo spider cortado fresco talante bronce' },
    { Clave: '10331923', Descripcion: 'Crisantemo spider cortado fresco super blanco' },
    { Clave: '10331924', Descripcion: 'Crisantemo spider cortado fresco super amarillo' },
    { Clave: '10331925', Descripcion: 'Crisantemo spider cortado fresco tierno' },
    { Clave: '10331926', Descripcion: 'Crisantemo spider cortado fresco zembia' },
    { Clave: '10332000', Descripcion: 'Crisantemo cortado fresco  novelty' },
    { Clave: '10332001', Descripcion: 'Crisantemo cortado fresco pompón anecy rosado' },
    { Clave: '10332002', Descripcion: 'Crisantemo cortado fresco pompón ardilo royal' },
    { Clave: '10332003', Descripcion: 'Crisantemo cortado fresco pompón athos' },
    { Clave: '10332004', Descripcion: 'Crisantemo cortado fresco pompón biarritz' },
    { Clave: '10332005', Descripcion: 'Crisantemo cortado fresco pompón bradford anaranjado' },
    { Clave: '10332006', Descripcion: 'Crisantemo cortado fresco pompón bradford' },
    { Clave: '10332007', Descripcion: 'Crisantemo cortado fresco pompón esperma' },
    { Clave: '10332008', Descripcion: 'Crisantemo cortado fresco pompón candor' },
    { Clave: '10332009', Descripcion: 'Crisantemo cortado fresco pompón gallardía' },
    { Clave: '10332010', Descripcion: 'Crisantemo cortado fresco pompón décima' },
    { Clave: '10332012', Descripcion: 'Crisantemo cortado fresco pompón delisun' },
    { Clave: '10332013', Descripcion: 'Crisantemo cortado fresco pompón dion' },
    { Clave: '10332014', Descripcion: 'Crisantemo cortado fresco pompón dorena' },
    { Clave: '10332015', Descripcion: 'Crisantemo cortado fresco pompón dublín' },
    { Clave: '10332016', Descripcion: 'Crisantemo cortado fresco pompón everglades' },
    { Clave: '10332017', Descripcion: 'Crisantemo cortado fresco pompón buenmozo' },
    { Clave: '10332018', Descripcion: 'Crisantemo cortado fresco pompón hasting' },
    { Clave: '10332019', Descripcion: 'Crisantemo cortado fresco pompón high five' },
    { Clave: '10332020', Descripcion: 'Crisantemo cortado fresco pompón mundial mejorado' },
    { Clave: '10332021', Descripcion: 'Crisantemo cortado fresco pompón juanes' },
    { Clave: '10332022', Descripcion: 'Crisantemo cortado fresco pompón kiato verde' },
    { Clave: '10332023', Descripcion: 'Crisantemo cortado fresco pompón kiato' },
    { Clave: '10332024', Descripcion: 'Crisantemo cortado fresco pompón kiwi' },
    { Clave: '10332025', Descripcion: 'Crisantemo cortado fresco pompón madeira' },
    { Clave: '10332026', Descripcion: 'Crisantemo cortado fresco pompón magneto' },
    { Clave: '10332027', Descripcion: 'Crisantemo cortado fresco pompón marimo' },
    { Clave: '10332028', Descripcion: 'Crisantemo cortado fresco pompón matrix' },
    { Clave: '10332029', Descripcion: 'Crisantemo cortado fresco pompón miletta' },
    { Clave: '10332030', Descripcion: 'Crisantemo cortado fresco pompón monalisa' },
    { Clave: '10332031', Descripcion: 'Crisantemo cortado fresco pompón omaha' },
    { Clave: '10332032', Descripcion: 'Crisantemo cortado fresco pompón orinoco púrpura' },
    { Clave: '10332033', Descripcion: 'Crisantemo cortado fresco pompón orinoco amarillo' },
    { Clave: '10332034', Descripcion: 'Crisantemo cortado fresco pompón verde pacífico' },
    { Clave: '10332035', Descripcion: 'Crisantemo cortado fresco pompón blanco puma' },
    { Clave: '10332036', Descripcion: 'Crisantemo cortado fresco pompón amarillo puma' },
    { Clave: '10332037', Descripcion: 'Crisantemo cortado fresco pompón mundial púrpura' },
    { Clave: '10332038', Descripcion: 'Crisantemo cortado fresco pompón regata' },
    { Clave: '10332039', Descripcion: 'Crisantemo cortado fresco pompón remco' },
    { Clave: '10332040', Descripcion: 'Crisantemo cortado fresco pompón royal mundial' },
    { Clave: '10332041', Descripcion: 'Crisantemo cortado fresco pompón sabrina' },
    { Clave: '10332042', Descripcion: 'Crisantemo cortado fresco pompón shakira blanco' },
    { Clave: '10332043', Descripcion: 'Crisantemo cortado fresco pompón sharp' },
    { Clave: '10332044', Descripcion: 'Crisantemo cortado fresco pompón shock' },
    { Clave: '10332045', Descripcion: 'Crisantemo cortado fresco pompón sizzle verde' },
    { Clave: '10332046', Descripcion: 'Crisantemo cortado fresco pompón sizzle rosado' },
    { Clave: '10332047', Descripcion: 'Crisantemo cortado fresco pompón sizzle' },
    { Clave: '10332048', Descripcion: 'Crisantemo cortado fresco pompón sizzle púrpura' },
    { Clave: '10332049', Descripcion: 'Crisantemo cortado fresco pompón sizzle salmón' },
    { Clave: '10332050', Descripcion: 'Crisantemo cortado fresco pompón sizzle amarillo' },
    { Clave: '10332051', Descripcion: 'Crisantemo cortado fresco pompón bandera española' },
    { Clave: '10332052', Descripcion: 'Crisantemo cortado fresco pompón starbust o copo de nieve' },
    { Clave: '10332053', Descripcion: 'Crisantemo cortado fresco pompón cisne' },
    { Clave: '10332054', Descripcion: 'Crisantemo cortado fresco pompón tedcha anaranjado' },
    { Clave: '10332055', Descripcion: 'Crisantemo cortado fresco pompón tierno' },
    { Clave: '10332056', Descripcion: 'Crisantemo cortado fresco pompón oropel' },
    { Clave: '10332057', Descripcion: 'Crisantemo cortado fresco pompón toque' },
    { Clave: '10332058', Descripcion: 'Crisantemo cortado fresco pompón troyes' },
    { Clave: '10332059', Descripcion: 'Crisantemo cortado fresco pompón valesca' },
    { Clave: '10332060', Descripcion: 'Crisantemo cortado fresco pompón anaranjado vikingo' },
    { Clave: '10332061', Descripcion: 'Crisantemo cortado fresco pompón vikingo' },
    { Clave: '10332062', Descripcion: 'Crisantemo cortado fresco pompón reloj' },
    { Clave: '10332063', Descripcion: 'Crisantemo cortado fresco pompón aguja blanco' },
    { Clave: '10332064', Descripcion: 'Crisantemo cortado fresco pompón noche blanca' },
    { Clave: '10332065', Descripcion: 'Crisantemo cortado fresco pompón artista amarillo' },
    { Clave: '10332066', Descripcion: 'Crisantemo cortado fresco pompón ficción amarillo' },
    { Clave: '10332067', Descripcion: 'Crisantemo cortado fresco pompón sharp amarillo' },
    { Clave: '10332100', Descripcion: 'Crisantemo cortado fresco santini' },
    { Clave: '10332101', Descripcion: 'Crisantemo cortado fresco pompón alma' },
    { Clave: '10332102', Descripcion: 'Crisantemo cortado fresco pompón barón' },
    { Clave: '10332103', Descripcion: 'Crisantemo cortado fresco pompón bernardo' },
    { Clave: '10332104', Descripcion: 'Crisantemo cortado fresco pompón bistro' },
    { Clave: '10332105', Descripcion: 'Crisantemo cortado fresco pompón bodega' },
    { Clave: '10332106', Descripcion: 'Crisantemo cortado fresco pompón brisa' },
    { Clave: '10332107', Descripcion: 'Crisantemo cortado fresco pompón centella bronce' },
    { Clave: '10332108', Descripcion: 'Crisantemo cortado fresco pompón costa blanca' },
    { Clave: '10332109', Descripcion: 'Crisantemo cortado fresco pompón creta' },
    { Clave: '10332110', Descripcion: 'Crisantemo cortado fresco pompón deliflame' },
    { Clave: '10332111', Descripcion: 'Crisantemo cortado fresco pompón dalila' },
    { Clave: '10332112', Descripcion: 'Crisantemo cortado fresco pompón dígito' },
    { Clave: '10332113', Descripcion: 'Crisantemo cortado fresco pompón evilio' },
    { Clave: '10332114', Descripcion: 'Crisantemo cortado fresco pompón furense' },
    { Clave: '10332115', Descripcion: 'Crisantemo cortado fresco pompón guía' },
    { Clave: '10332116', Descripcion: 'Crisantemo cortado fresco pompón kerry' },
    { Clave: '10332117', Descripcion: 'Crisantemo cortado fresco pompón kess' },
    { Clave: '10332118', Descripcion: 'Crisantemo cortado fresco pompón lima' },
    { Clave: '10332119', Descripcion: 'Crisantemo cortado fresco pompón lupo' },
    { Clave: '10332120', Descripcion: 'Crisantemo cortado fresco pompón lineker anaranjado' },
    { Clave: '10332121', Descripcion: 'Crisantemo cortado fresco pompón panuco rojo' },
    { Clave: '10332122', Descripcion: 'Crisantemo cortado fresco pompón costa rosado' },
    { Clave: '10332123', Descripcion: 'Crisantemo cortado fresco pompón rafael' },
    { Clave: '10332124', Descripcion: 'Crisantemo cortado fresco pompón refine' },
    { Clave: '10332125', Descripcion: 'Crisantemo cortado fresco pompón regaliz' },
    { Clave: '10332126', Descripcion: 'Crisantemo cortado fresco pompón renella' },
    { Clave: '10332127', Descripcion: 'Crisantemo cortado fresco pompón retorno' },
    { Clave: '10332128', Descripcion: 'Crisantemo cortado fresco pompón río' },
    { Clave: '10332129', Descripcion: 'Crisantemo cortado fresco pompón sabas' },
    { Clave: '10332130', Descripcion: 'Crisantemo cortado fresco pompón target' },
    { Clave: '10332131', Descripcion: 'Crisantemo cortado fresco pompón texto' },
    { Clave: '10332132', Descripcion: 'Crisantemo cortado fresco pompón caballo amarillo' },
    { Clave: '10341500', Descripcion: 'Bouquet cortado fresco de rosa' },
    { Clave: '10341501', Descripcion: 'Bouquet cortado fresco de rosa azul o lavanda o púrpura' },
    { Clave: '10341600', Descripcion: 'Bouquet cortado fresco de clavel' },
    { Clave: '10341601', Descripcion: 'Bouquet cortado fresco de clavel burgundi bi color' },
    { Clave: '10341700', Descripcion: 'Bouquet cortado fresco de mini clavel' },
    { Clave: '10341701', Descripcion: 'Bouquet cortado fresco de mini clavel burgundy' },
    { Clave: '10341800', Descripcion: 'Bouquet cortado fresco de lirio' },
    { Clave: '10341801', Descripcion: 'Bouquet cortado fresco de lirio asiático black out' },
    { Clave: '10341900', Descripcion: 'Bouquet cortado fresco de crisantemo pompón' },
    { Clave: '10341901', Descripcion: 'Bouquet cortado fresco de crisantemo pompón margarita' },
    { Clave: '10342000', Descripcion: 'Bouquet cortado fresco de astromelia' },
    { Clave: '10342001', Descripcion: 'Bouquet cortado fresco de astromelia agropoli' },
    { Clave: '10342100', Descripcion: 'Bouquet cortado fresco de flores tropicales' },
    { Clave: '10342101', Descripcion: 'Bouquet cortado fresco de orquidea dendrobium' },
    { Clave: '10342200', Descripcion: 'Bouquet cortado fresco floral mixto' },
    { Clave: '10342201', Descripcion: 'Bouquet cortado fresco de rosa clavel peonía' },
    { Clave: '10351500', Descripcion: 'Bouquet cortado fresco de clavel de floración sencilla' },
    { Clave: '10351501', Descripcion: 'Clavel cortado fresco de floración sencilla burgundy bicolor' },
    { Clave: '10351502', Descripcion: 'Clavel cortado fresco de floración sencilla burgundy' },
    { Clave: '10351503', Descripcion: 'Clavel cortado fresco de floración sencilla cinderella' },
    { Clave: '10351504', Descripcion: 'Clavel cortado fresco de floración sencilla crema bicolor' },
    { Clave: '10351505', Descripcion: 'Clavel cortado fresco de floración sencilla crema' },
    { Clave: '10351506', Descripcion: 'Clavel cortado fresco de floración sencilla verde o prado' },
    { Clave: '10351507', Descripcion: 'Clavel cortado fresco de floración sencilla rosado fuerte' },
    { Clave: '10351508', Descripcion: 'Clavel cortado fresco de floración sencilla verde claro' },
    { Clave: '10351509', Descripcion: 'Clavel cortado fresco de floración sencilla rosado claro' },
    { Clave: '10351510', Descripcion: 'Clavel cortado fresco de floración sencilla anaranjado bicolor' },
    { Clave: '10351511', Descripcion: 'Clavel cortado fresco de floración sencilla anaranjado' },
    { Clave: '10351512', Descripcion: 'Clavel cortado fresco de floración sencilla durazno' },
    { Clave: '10351513', Descripcion: 'Clavel cortado fresco de floración sencilla menta bicolor' },
    { Clave: '10351514', Descripcion: 'Clavel cortado fresco de floración sencilla rosado bicolor' },
    { Clave: '10351515', Descripcion: 'Clavel cortado fresco de floración sencilla rosado' },
    { Clave: '10351516', Descripcion: 'Clavel cortado fresco de floración sencilla púrpura bicolor' },
    { Clave: '10351517', Descripcion: 'Clavel cortado fresco de floración sencilla rojo bicolor' },
    { Clave: '10351518', Descripcion: 'Clavel cortado fresco de floración sencilla rojo' },
    { Clave: '10351519', Descripcion: 'Clavel cortado fresco de floración sencilla blanco' },
    { Clave: '10351520', Descripcion: 'Clavel cortado fresco de floración sencilla amarillo' },
    { Clave: '10351600', Descripcion: 'Bouquet cortado fresco de clavel min o spray' },
    { Clave: '10351601', Descripcion: 'Clavel cortado fresco mini o spray burgundy' },
    { Clave: '10351602', Descripcion: 'Clavel cortado fresco mini o spray' },
    { Clave: '10351603', Descripcion: 'Clavel cortado fresco mini o spray rosado fuerte' },
    { Clave: '10351604', Descripcion: 'Clavel cortado fresco mini o spray lavanda' },
    { Clave: '10351605', Descripcion: 'Clavel cortado fresco mini o spray rosado claro' },
    { Clave: '10351606', Descripcion: 'Clavel cortado fresco mini o spray anaranjado' },
    { Clave: '10351607', Descripcion: 'Clavel cortado fresco mini o spray durazno' },
    { Clave: '10351608', Descripcion: 'Clavel cortado fresco mini o spray menta' },
    { Clave: '10351609', Descripcion: 'Clavel cortado fresco mini o spray rosado' },
    { Clave: '10351610', Descripcion: 'Clavel cortado fresco mini o spray púrpura bicolor' },
    { Clave: '10351611', Descripcion: 'Clavel cortado fresco mini o spray púrpura' },
    { Clave: '10351612', Descripcion: 'Clavel cortado fresco mini o spray rojo' },
    { Clave: '10351613', Descripcion: 'Clavel cortado fresco mini o spray blanco' },
    { Clave: '10351614', Descripcion: 'Clavel cortado fresco mini o spray amarillo' },
    { Clave: '10361500', Descripcion: 'Orquidea cypripedium  o pantuflas de dama cortada fresca' },
    { Clave: '10361501', Descripcion: 'Orquidea cypripedium cortada fresca verde' },
    { Clave: '10361502', Descripcion: 'Orquidea cypripedium cortada fresca francia' },
    { Clave: '10361503', Descripcion: 'Orquidea cypripedium cortada fresca púrpura rey arturo' },
    { Clave: '10361504', Descripcion: 'Orquidea paphiopedilum cortada fresca verde' },
    { Clave: '10361505', Descripcion: 'Orquidea aranthera maggie vie cortada fresca' },
    { Clave: '10361600', Descripcion: 'Orquídea mocara cortada fresca' },
    { Clave: '10361601', Descripcion: 'Orquídea mocara cortada fresca omyai' },
    { Clave: '10361602', Descripcion: 'Orquídea mocara cortada fresca roja' },
    { Clave: '10361603', Descripcion: 'Orquídea mocara cortada fresca calipso' },
    { Clave: '10361604', Descripcion: 'Orquídea mocara cortada fresca nora' },
    { Clave: '10361605', Descripcion: 'Orquídea mocara cortada fresca panee' },
    { Clave: '10361700', Descripcion: 'Orquídea catleya cortada fresca' },
    { Clave: '10361701', Descripcion: 'Orquídea cattleya cortada fresca blanca' },
    { Clave: '10361702', Descripcion: 'Orquídea cattleya cortada fresca r b lavanda' },
    { Clave: '10361800', Descripcion: 'Orquídea disa cortada fresca' },
    { Clave: '10361801', Descripcion: 'Orquídea disa cortada fresca roja' },
    { Clave: '10361802', Descripcion: 'Orquídea disa cortada fresca anaranjada' },
    { Clave: '10361803', Descripcion: 'Orquídea disa cortada fresca rosada' },
    { Clave: '10361804', Descripcion: 'Orquídea disa cortada fresca anaranjada y amarilla bicolor' },
    { Clave: '10361805', Descripcion: 'Orquídea disa cortada fresca durazno y amarilla bicolor' },
    { Clave: '10361806', Descripcion: 'Orquídea disa cortada fresca amarilla y roja bicolor' },
    { Clave: '10361900', Descripcion: 'Orquídea arachnis cortada fresca' },
    { Clave: '10361901', Descripcion: 'Orquídea arachnis cortada fresca james storie roja' },
    { Clave: '10361902', Descripcion: 'Orquídea arachnis cortada fresca maggie oei roja' },
    { Clave: '10361903', Descripcion: 'Orquídea arachnis cortada fresca maggie oei amarilla' },
    { Clave: '10361904', Descripcion: 'Orquídea arachnis cortada fresca maroon maggie' },
    { Clave: '10361905', Descripcion: 'Orquídea arachnis cortada fresca merry maggie' },
    { Clave: '10362000', Descripcion: 'Orquídea phalaenopsis cortada fresca' },
    { Clave: '10362001', Descripcion: 'Orquídea phalaenopsis cortada fresca amabilis' },
    { Clave: '10362002', Descripcion: 'Orquídea phalaenopsis cortada fresca amboinensis' },
    { Clave: '10362003', Descripcion: 'Orquídea phalaenopsis cortada fresca afrodita' },
    { Clave: '10362004', Descripcion: 'Orquídea phalaenopsis cortada fresca appendiculata' },
    { Clave: '10362005', Descripcion: 'Orquídea phalaenopsis cortada fresca bastianii' },
    { Clave: '10362006', Descripcion: 'Orquídea phalaenopsis cortada fresca bellina' },
    { Clave: '10362007', Descripcion: 'Orquídea phalaenopsis cortada fresca borneensis' },
    { Clave: '10362008', Descripcion: 'Orquídea phalaenopsis cortada fresca braceana' },
    { Clave: '10362009', Descripcion: 'Orquídea phalaenopsis cortada fresca buyssoniana' },
    { Clave: '10362010', Descripcion: 'Orquídea phalaenopsis cortada fresca celebensis' },
    { Clave: '10362011', Descripcion: 'Orquídea phalaenopsis cortada fresca chibae' },
    { Clave: '10362012', Descripcion: 'Orquídea phalaenopsis cortada fresca cochlearis' },
    { Clave: '10362013', Descripcion: 'Orquídea phalaenopsis cortada fresca corningiana' },
    { Clave: '10362014', Descripcion: 'Orquídea phalaenopsis cortada fresca cornu-cervi' },
    { Clave: '10362015', Descripcion: 'Orquídea phalaenopsis cortada fresca deliciosa' },
    { Clave: '10362016', Descripcion: 'Orquídea phalaenopsis cortada fresca doweryënsis' },
    { Clave: '10362017', Descripcion: 'Orquídea phalaenopsis cortada fresca equestris' },
    { Clave: '10362018', Descripcion: 'Orquídea phalaenopsis cortada fresca fasciata' },
    { Clave: '10362019', Descripcion: 'Orquídea phalaenopsis cortada fresca fimbriata' },
    { Clave: '10362020', Descripcion: 'Orquídea phalaenopsis cortada fresca floresensis' },
    { Clave: '10362021', Descripcion: 'Orquídea phalaenopsis cortada fresca fuscata' },
    { Clave: '10362022', Descripcion: 'Orquídea phalaenopsis cortada fresca gibbosa' },
    { Clave: '10362023', Descripcion: 'Orquídea phalaenopsis cortada fresca hainanensis' },
    { Clave: '10362024', Descripcion: 'Orquídea phalaenopsis cortada fresca hieroglyphica' },
    { Clave: '10362025', Descripcion: 'Orquídea phalaenopsis cortada fresca honghenensis' },
    { Clave: '10362026', Descripcion: 'Orquídea phalaenopsis cortada fresca inscriptiosinensis' },
    { Clave: '10362027', Descripcion: 'Orquídea phalaenopsis cortada fresca javanica' },
    { Clave: '10362028', Descripcion: 'Orquídea phalaenopsis cortada fresca kunstleri' },
    { Clave: '10362029', Descripcion: 'Orquídea phalaenopsis cortada fresca lamelligera' },
    { Clave: '10362030', Descripcion: 'Orquídea phalaenopsis cortada fresca lindenii' },
    { Clave: '10362031', Descripcion: 'Orquídea phalaenopsis cortada fresca lobbii' },
    { Clave: '10362032', Descripcion: 'Orquídea phalaenopsis cortada fresca lowii' },
    { Clave: '10362033', Descripcion: 'Orquídea phalaenopsis cortada fresca lueddemanniana' },
    { Clave: '10362034', Descripcion: 'Orquídea phalaenopsis cortada fresca mambo' },
    { Clave: '10362035', Descripcion: 'Orquídea phalaenopsis cortada fresca luteola' },
    { Clave: '10362036', Descripcion: 'Orquídea phalaenopsis cortada fresca maculata' },
    { Clave: '10362037', Descripcion: 'Orquídea phalaenopsis cortada fresca malipoensis' },
    { Clave: '10362038', Descripcion: 'Orquídea phalaenopsis cortada fresca mannii' },
    { Clave: '10362039', Descripcion: 'Orquídea phalaenopsis cortada fresca mariae' },
    { Clave: '10362040', Descripcion: 'Orquídea phalaenopsis cortada fresca micholitzii' },
    { Clave: '10362041', Descripcion: 'Orquídea phalaenopsis cortada fresca modesta' },
    { Clave: '10362042', Descripcion: 'Orquídea phalaenopsis cortada fresca mysorensis' },
    { Clave: '10362043', Descripcion: 'Orquídea phalaenopsis cortada fresca pallens' },
    { Clave: '10362044', Descripcion: 'Orquídea phalaenopsis cortada fresca pantherina' },
    { Clave: '10362045', Descripcion: 'Orquídea phalaenopsis cortada fresca parishii' },
    { Clave: '10362046', Descripcion: 'Orquídea phalaenopsis cortada fresca petelotii' },
    { Clave: '10362047', Descripcion: 'Orquídea phalaenopsis cortada fresca philippinensis' },
    { Clave: '10362048', Descripcion: 'Orquídea phalaenopsis cortada fresca pulcherrima' },
    { Clave: '10362049', Descripcion: 'Orquídea phalaenopsis cortada fresca pulchra' },
    { Clave: '10362050', Descripcion: 'Orquídea phalaenopsis cortada fresca regnieriana' },
    { Clave: '10362051', Descripcion: 'Orquídea phalaenopsis cortada fresca reichenbachiana' },
    { Clave: '10362052', Descripcion: 'Orquídea phalaenopsis cortada fresca nivacolor' },
    { Clave: '10362053', Descripcion: 'Orquídea phalaenopsis cortada fresca sanderiana' },
    { Clave: '10362054', Descripcion: 'Orquídea phalaenopsis cortada fresca schilleriana' },
    { Clave: '10362055', Descripcion: 'Orquídea phalaenopsis cortada fresca speciosa' },
    { Clave: '10362056', Descripcion: 'Orquídea phalaenopsis cortada fresca stobartiana' },
    { Clave: '10362057', Descripcion: 'Orquídea phalaenopsis cortada fresca stuartiana' },
    { Clave: '10362058', Descripcion: 'Orquídea phalaenopsis cortada fresca sumatrana' },
    { Clave: '10362059', Descripcion: 'Orquídea phalaenopsis cortada fresca taenialis' },
    { Clave: '10362060', Descripcion: 'Orquídea phalaenopsis cortada fresca tetraspis' },
    { Clave: '10362061', Descripcion: 'Orquídea phalaenopsis cortada fresca venosa' },
    { Clave: '10362062', Descripcion: 'Orquídea phalaenopsis cortada fresca violácea' },
    { Clave: '10362063', Descripcion: 'Orquídea phalaenopsis cortada fresca viridis' },
    { Clave: '10362064', Descripcion: 'Orquídea phalaenopsis cortada fresca wilsonii' },
    { Clave: '10362065', Descripcion: 'Orquídea phalaenopsis cortada fresca zebrina' },
    { Clave: '10362067', Descripcion: 'Orquídea phalaenopsis cortada fresca labio lavanda' },
    { Clave: '10362100', Descripcion: 'Orquídea dendrobium cortada fresca' },
    { Clave: '10362101', Descripcion: 'Orquídea dendrobium cortada fresca bom' },
    { Clave: '10362102', Descripcion: 'Orquídea dendrobium cortada fresca burana' },
    { Clave: '10362103', Descripcion: 'Orquídea dendrobium cortada fresca chita' },
    { Clave: '10362104', Descripcion: 'Orquídea dendrobium cortada fresca fátima' },
    { Clave: '10362105', Descripcion: 'Orquídea dendrobium cortada fresca intuwong' },
    { Clave: '10362106', Descripcion: 'Orquídea dendrobium cortada fresca jumbo blanca' },
    { Clave: '10362107', Descripcion: 'Orquídea dendrobium cortada fresca kating dang' },
    { Clave: '10362108', Descripcion: 'Orquídea dendrobium cortada fresca libertad' },
    { Clave: '10362109', Descripcion: 'Orquídea dendrobium cortada fresca hawaii' },
    { Clave: '10362110', Descripcion: 'Orquídea dendrobium cortada fresca sakura sweet rosada' },
    { Clave: '10362111', Descripcion: 'Orquídea dendrobium cortada fresca sensacional púrpura' },
    { Clave: '10362112', Descripcion: 'Orquídea dendrobium cortada fresca blanca' },
    { Clave: '10362200', Descripcion: 'Orquídea cymbidium cortada fresca' },
    { Clave: '10362201', Descripcion: 'Orquídea cymbidium cortada fresca crema' },
    { Clave: '10362202', Descripcion: 'Orquídea cymbidium cortada fresca verde' },
    { Clave: '10362203', Descripcion: 'Orquídea cymbidium cortada fresca mini verde' },
    { Clave: '10362204', Descripcion: 'Orquídea cymbidium cortada fresca mini rosada' },
    { Clave: '10362205', Descripcion: 'Orquídea cymbidium cortada fresca mini roja' },
    { Clave: '10362206', Descripcion: 'Orquídea cymbidium cortada fresca mini blanca' },
    { Clave: '10362207', Descripcion: 'Orquídea cymbidium cortada fresca mini amarilla' },
    { Clave: '10362208', Descripcion: 'Orquídea cymbidium cortada fresca chocolate' },
    { Clave: '10362209', Descripcion: 'Orquídea cymbidium cortada fresca rosado oscuro' },
    { Clave: '10362210', Descripcion: 'Orquídea cymbidium cortada fresca anaranjada' },
    { Clave: '10362211', Descripcion: 'Orquídea cymbidium cortada fresca rosada' },
    { Clave: '10362212', Descripcion: 'Orquídea cymbidium cortada fresca blanca' },
    { Clave: '10362213', Descripcion: 'Orquídea cymbidium cortada fresca amarilla' },
    { Clave: '10362300', Descripcion: 'Orquídea oncidium cortada fresca' },
    { Clave: '10362301', Descripcion: 'Orquídea oncidium cortada fresca ducha dorada' },
    { Clave: '10362302', Descripcion: 'Orquídea oncidium cortada fresca rhamsey' },
    { Clave: '10362400', Descripcion: 'Orquídea vanda cortada fresca' },
    { Clave: '10362401', Descripcion: 'Orquídea cortada fresca vanda alizarin' },
    { Clave: '10362402', Descripcion: 'Orquídea cortada fresca vanda rosada fuerte' },
    { Clave: '10362403', Descripcion: 'Orquídea cortada fresca vanda lavanda' },
    { Clave: '10362404', Descripcion: 'Orquídea cortada fresca vanda púrpura' },
    { Clave: '10362405', Descripcion: 'Orquídea cortada fresca vanda hazme cosquillas rosada' },
    { Clave: '10362406', Descripcion: 'Orquídea cortada fresca vanda amarilla' },
    { Clave: '10401500', Descripcion: 'Rosas cortadas secas azules o lavanda o púrpura' },
    { Clave: '10401501', Descripcion: 'Rosal cortado seco allure o sterling 95' },
    { Clave: '10401502', Descripcion: 'Rosal cortado seco amnesia' },
    { Clave: '10401503', Descripcion: 'Rosal cortado seco augusta louise' },
    { Clave: '10401504', Descripcion: 'Rosal cortado seco avant garde' },
    { Clave: '10401505', Descripcion: 'Rosal cortado seco blue bird' },
    { Clave: '10401506', Descripcion: 'Rosal cortado seco curiosa' },
    { Clave: '10401507', Descripcion: 'Rosal cortado seco cool water' },
    { Clave: '10401508', Descripcion: 'Rosal cortado seco delilah' },
    { Clave: '10401509', Descripcion: 'Rosal cortado seco double party' },
    { Clave: '10401510', Descripcion: 'Rosal cortado seco faith' },
    { Clave: '10401511', Descripcion: 'Rosal cortado seco mami blue o mammy blue' },
    { Clave: '10401512', Descripcion: 'Rosal cortado seco maritime' },
    { Clave: '10401513', Descripcion: 'Rosal cortado seco milano' },
    { Clave: '10401514', Descripcion: 'Rosal cortado seco mistery' },
    { Clave: '10401515', Descripcion: 'Rosal cortado seco ocean song o boyfriend' },
    { Clave: '10401516', Descripcion: 'Rosal cortado seco cezanne púrpura' },
    { Clave: '10401517', Descripcion: 'Rosal cortado seco purple fragrance' },
    { Clave: '10401518', Descripcion: 'Rosal cortado seco sanaa' },
    { Clave: '10401519', Descripcion: 'Rosal cortado seco silverstone' },
    { Clave: '10401520', Descripcion: 'Rosal cortado seco soulmate' },
    { Clave: '10401521', Descripcion: 'Rosal cortado seco stranger' },
    { Clave: '10401522', Descripcion: 'Rosal cortado seco tinted blue' },
    { Clave: '10401523', Descripcion: 'Rosal cortado seco two faces' },
    { Clave: '10401600', Descripcion: 'Rosas cortadas secas chocolate o marrón' },
    { Clave: '10401601', Descripcion: 'Rosal cortado seco black lava' },
    { Clave: '10401602', Descripcion: 'Rosal cortado seco cimarrón' },
    { Clave: '10401603', Descripcion: 'Rosal cortado seco coffee break' },
    { Clave: '10401604', Descripcion: 'Rosal cortado seco estelle' },
    { Clave: '10401605', Descripcion: 'Rosal cortado seco gypsy leonidas' },
    { Clave: '10401606', Descripcion: 'Rosal cortado seco leonidas' },
    { Clave: '10401607', Descripcion: 'Rosal cortado seco matilda' },
    { Clave: '10401608', Descripcion: 'Rosal cortado seco sunny leonidas' },
    { Clave: '10401609', Descripcion: 'Rosal cortado seco terra nostra' },
    { Clave: '10401610', Descripcion: 'Rosal cortado seco terracotta' },
    { Clave: '10401700', Descripcion: 'Rosas cortadas secas crema' },
    { Clave: '10401701', Descripcion: 'Rosal cortado seco advenire' },
    { Clave: '10401702', Descripcion: 'Rosal cortado seco alex' },
    { Clave: '10401703', Descripcion: 'Rosal cortado seco antique brass' },
    { Clave: '10401704', Descripcion: 'Rosal cortado seco aubade' },
    { Clave: '10401705', Descripcion: 'Rosal cortado seco beach' },
    { Clave: '10401706', Descripcion: 'Rosal cortado seco belle pearl' },
    { Clave: '10401707', Descripcion: 'Rosal cortado seco blush o blush de los andes' },
    { Clave: '10401708', Descripcion: 'Rosal cortado seco camel' },
    { Clave: '10401709', Descripcion: 'Rosal cortado seco caramel antike o caramel antique' },
    { Clave: '10401710', Descripcion: 'Rosal cortado seco champagne' },
    { Clave: '10401711', Descripcion: 'Rosal cortado seco clear ocean' },
    { Clave: '10401712', Descripcion: 'Rosal cortado seco combo' },
    { Clave: '10401713', Descripcion: 'Rosal cortado seco creme de la creme' },
    { Clave: '10401714', Descripcion: 'Rosal cortado seco emanuella' },
    { Clave: '10401715', Descripcion: 'Rosal cortado seco evolution' },
    { Clave: '10401716', Descripcion: 'Rosal cortado seco fedora' },
    { Clave: '10401717', Descripcion: 'Rosal cortado seco fenice' },
    { Clave: '10401718', Descripcion: 'Rosal cortado seco french vanilla' },
    { Clave: '10401719', Descripcion: 'Rosal cortado seco hollywood' },
    { Clave: '10401720', Descripcion: 'Rosal cortado seco ilios' },
    { Clave: '10401721', Descripcion: 'Rosal cortado seco jelena' },
    { Clave: '10401722', Descripcion: 'Rosal cortado seco kameleon' },
    { Clave: '10401723', Descripcion: 'Rosal cortado seco kentucky' },
    { Clave: '10401724', Descripcion: 'Rosal cortado seco kings pride' },
    { Clave: '10401725', Descripcion: 'Rosal cortado seco latin fusion' },
    { Clave: '10401726', Descripcion: 'Rosal cortado seco lemon dream' },
    { Clave: '10401727', Descripcion: 'Rosal cortado seco magic moka' },
    { Clave: '10401728', Descripcion: 'Rosal cortado seco mama mia' },
    { Clave: '10401729', Descripcion: 'Rosal cortado seco message' },
    { Clave: '10401730', Descripcion: 'Rosal cortado seco muñeca o munieca' },
    { Clave: '10401731', Descripcion: 'Rosal cortado seco parfum de rosas' },
    { Clave: '10401732', Descripcion: 'Rosal cortado seco porcelina' },
    { Clave: '10401733', Descripcion: 'Rosal cortado seco privilege' },
    { Clave: '10401734', Descripcion: 'Rosal cortado seco quick sand' },
    { Clave: '10401735', Descripcion: 'Rosal cortado seco rollercoaster' },
    { Clave: '10401736', Descripcion: 'Rosal cortado seco romantic curiosa' },
    { Clave: '10401737', Descripcion: 'Rosal cortado seco safari' },
    { Clave: '10401738', Descripcion: 'Rosal cortado seco sahara' },
    { Clave: '10401739', Descripcion: 'Rosal cortado seco sandy femma' },
    { Clave: '10401740', Descripcion: 'Rosal cortado seco talea' },
    { Clave: '10401741', Descripcion: 'Rosal cortado seco timeless' },
    { Clave: '10401742', Descripcion: 'Rosal cortado seco transition' },
    { Clave: '10401743', Descripcion: 'Rosal cortado seco trump' },
    { Clave: '10401744', Descripcion: 'Rosal cortado seco twin' },
    { Clave: '10401745', Descripcion: 'Rosal cortado seco vendela' },
    { Clave: '10401746', Descripcion: 'Rosal cortado seco virginia' },
    { Clave: '10401800', Descripcion: 'Rosas cortadas secas verdes o lima' },
    { Clave: '10401801', Descripcion: 'Rosal cortado seco amandina' },
    { Clave: '10401802', Descripcion: 'Rosal cortado seco caipirinha' },
    { Clave: '10401803', Descripcion: 'Rosal cortado seco green fashion' },
    { Clave: '10401804', Descripcion: 'Rosal cortado seco green tea' },
    { Clave: '10401805', Descripcion: 'Rosal cortado seco jade' },
    { Clave: '10401806', Descripcion: 'Rosal cortado seco limbo' },
    { Clave: '10401807', Descripcion: 'Rosal cortado seco limeña o limenia' },
    { Clave: '10401808', Descripcion: 'Rosal cortado seco limona' },
    { Clave: '10401809', Descripcion: 'Rosal cortado seco old dutch' },
    { Clave: '10401810', Descripcion: 'Rosal cortado seco super green' },
    { Clave: '10401811', Descripcion: 'Rosal cortado seco sweet green' },
    { Clave: '10401812', Descripcion: 'Rosal cortado seco cortada seca' },
    { Clave: '10401813', Descripcion: 'Rosal cortado seco zazu' },
    { Clave: '10401900', Descripcion: 'Rosas cortadas secas rosado claro' },
    { Clave: '10401901', Descripcion: 'Rosal cortado seco anna' },
    { Clave: '10401902', Descripcion: 'Rosal cortado seco bella vita' },
    { Clave: '10401903', Descripcion: 'Rosal cortado seco bridal dream' },
    { Clave: '10401904', Descripcion: 'Rosal cortado seco candy bianca' },
    { Clave: '10401905', Descripcion: 'Rosal cortado seco caress' },
    { Clave: '10401906', Descripcion: 'Rosal cortado seco carolina' },
    { Clave: '10401907', Descripcion: 'Rosal cortado seco climax' },
    { Clave: '10401908', Descripcion: 'Rosal cortado seco danny' },
    { Clave: '10401909', Descripcion: 'Rosal cortado seco dolce vita' },
    { Clave: '10401910', Descripcion: 'Rosal cortado seco elite' },
    { Clave: '10401911', Descripcion: 'Rosal cortado seco emma' },
    { Clave: '10401912', Descripcion: 'Rosal cortado seco engagement' },
    { Clave: '10401913', Descripcion: 'Rosal cortado seco esther' },
    { Clave: '10401914', Descripcion: 'Rosal cortado seco excalibur' },
    { Clave: '10401915', Descripcion: 'Rosal cortado seco exciting' },
    { Clave: '10401916', Descripcion: 'Rosal cortado seco first lady' },
    { Clave: '10401917', Descripcion: 'Rosal cortado seco geraldine' },
    { Clave: '10401918', Descripcion: 'Rosal cortado seco gotcha' },
    { Clave: '10401919', Descripcion: 'Rosal cortado seco harmonie' },
    { Clave: '10401920', Descripcion: 'Rosal cortado seco heaven' },
    { Clave: '10401921', Descripcion: 'Rosal cortado seco high and elegant' },
    { Clave: '10401922', Descripcion: 'Rosal cortado seco katherine' },
    { Clave: '10401923', Descripcion: 'Rosal cortado seco king kong' },
    { Clave: '10401924', Descripcion: 'Rosal cortado seco livia' },
    { Clave: '10401925', Descripcion: 'Rosal cortado seco lorena' },
    { Clave: '10401926', Descripcion: 'Rosal cortado seco lovely' },
    { Clave: '10401927', Descripcion: 'Rosal cortado seco maaike' },
    { Clave: '10401928', Descripcion: 'Rosal cortado seco marilyn' },
    { Clave: '10401929', Descripcion: 'Rosal cortado seco marlise' },
    { Clave: '10401930', Descripcion: 'Rosal cortado seco miranda o ausimmon' },
    { Clave: '10401931', Descripcion: 'Rosal cortado seco mona lisa' },
    { Clave: '10401932', Descripcion: 'Rosal cortado seco nirvana' },
    { Clave: '10401933', Descripcion: 'Rosal cortado seco o?hara' },
    { Clave: '10401934', Descripcion: 'Rosal cortado seco ole' },
    { Clave: '10401935', Descripcion: 'Rosal cortado seco olga' },
    { Clave: '10401936', Descripcion: 'Rosal cortado seco pacífica' },
    { Clave: '10401937', Descripcion: 'Rosal cortado seco party mix' },
    { Clave: '10401938', Descripcion: 'Rosal cortado seco pekoubo o pekcoubo' },
    { Clave: '10401939', Descripcion: 'Rosal cortado seco phoebe o ausnotice' },
    { Clave: '10401940', Descripcion: 'Rosal cortado seco pink farfalla' },
    { Clave: '10401941', Descripcion: 'Rosal cortado seco pink finess' },
    { Clave: '10401942', Descripcion: 'Rosal cortado seco pink magic' },
    { Clave: '10401943', Descripcion: 'Rosal cortado seco pink osiana' },
    { Clave: '10401944', Descripcion: 'Rosal cortado seco pretty woman' },
    { Clave: '10401945', Descripcion: 'Rosal cortado seco romance' },
    { Clave: '10401946', Descripcion: 'Rosal cortado seco romantic antike o antique' },
    { Clave: '10401947', Descripcion: 'Rosal cortado seco rosalind o astew' },
    { Clave: '10401948', Descripcion: 'Rosal cortado seco rosita vendela' },
    { Clave: '10401949', Descripcion: 'Rosal cortado seco secret garden' },
    { Clave: '10401950', Descripcion: 'Rosal cortado seco solaire' },
    { Clave: '10401951', Descripcion: 'Rosal cortado seco sophie' },
    { Clave: '10401952', Descripcion: 'Rosal cortado seco sweet akito' },
    { Clave: '10401953', Descripcion: 'Rosal cortado seco sweet avalanche' },
    { Clave: '10401954', Descripcion: 'Rosal cortado seco sweet elegance' },
    { Clave: '10401955', Descripcion: 'Rosal cortado seco sweet pink' },
    { Clave: '10401956', Descripcion: 'Rosal cortado seco titanic' },
    { Clave: '10401957', Descripcion: 'Rosal cortado seco toscanini' },
    { Clave: '10401958', Descripcion: 'Rosal cortado seco vania' },
    { Clave: '10401959', Descripcion: 'Rosal cortado seco vanity' },
    { Clave: '10401960', Descripcion: 'Rosal cortado seco vision' },
    { Clave: '10401961', Descripcion: 'Rosal cortado seco vivaldi' },
    { Clave: '10401962', Descripcion: 'Rosal cortado seco whisper' },
    { Clave: '10402000', Descripcion: 'Rosas cortadas secas multicolor rosados' },
    { Clave: '10402001', Descripcion: 'Rosal cortado seco attracta' },
    { Clave: '10402002', Descripcion: 'Rosal cortado seco boheme' },
    { Clave: '10402003', Descripcion: 'Rosal cortado seco carousel' },
    { Clave: '10402004', Descripcion: 'Rosal cortado seco cezanne' },
    { Clave: '10402005', Descripcion: 'Rosal cortado seco crazy one' },
    { Clave: '10402006', Descripcion: 'Rosal cortado seco dance valley' },
    { Clave: '10402007', Descripcion: 'Rosal cortado seco duett' },
    { Clave: '10402008', Descripcion: 'Rosal cortado seco esperance' },
    { Clave: '10402009', Descripcion: 'Rosal cortado seco fiesta' },
    { Clave: '10402010', Descripcion: 'Rosal cortado seco halloween' },
    { Clave: '10402011', Descripcion: 'Rosal cortado seco highlander' },
    { Clave: '10402012', Descripcion: 'Rosal cortado seco hot ambiance' },
    { Clave: '10402013', Descripcion: 'Rosal cortado seco la belle' },
    { Clave: '10402014', Descripcion: 'Rosal cortado seco laguna' },
    { Clave: '10402015', Descripcion: 'Rosal cortado seco latin ambiance' },
    { Clave: '10402016', Descripcion: 'Rosal cortado seco latin breeze' },
    { Clave: '10402017', Descripcion: 'Rosal cortado seco long arifa' },
    { Clave: '10402018', Descripcion: 'Rosal cortado seco murano' },
    { Clave: '10402019', Descripcion: 'Rosal cortado seco n ? joy rose' },
    { Clave: '10402020', Descripcion: 'Rosal cortado seco panama' },
    { Clave: '10402021', Descripcion: 'Rosal cortado seco peppermint' },
    { Clave: '10402022', Descripcion: 'Rosal cortado seco pujama party' },
    { Clave: '10402023', Descripcion: 'Rosal cortado seco portofino' },
    { Clave: '10402024', Descripcion: 'Rosal cortado seco priceless' },
    { Clave: '10402025', Descripcion: 'Rosal cortado seco queen amazon' },
    { Clave: '10402026', Descripcion: 'Rosal cortado seco ranuncula' },
    { Clave: '10402027', Descripcion: 'Rosal cortado seco rossini' },
    { Clave: '10402028', Descripcion: 'Rosal cortado seco sabina o sabrina' },
    { Clave: '10402029', Descripcion: 'Rosal cortado seco scandal' },
    { Clave: '10402030', Descripcion: 'Rosal cortado seco silvery pink' },
    { Clave: '10402031', Descripcion: 'Rosal cortado seco something else' },
    { Clave: '10402032', Descripcion: 'Rosal cortado seco southine' },
    { Clave: '10402033', Descripcion: 'Rosal cortado seco sovereign' },
    { Clave: '10402034', Descripcion: 'Rosal cortado seco super disco' },
    { Clave: '10402035', Descripcion: 'Rosal cortado seco ts 1968' },
    { Clave: '10402036', Descripcion: 'Rosal cortado seco variance' },
    { Clave: '10402037', Descripcion: 'Rosal cortado seco verdi' },
    { Clave: '10402100', Descripcion: 'Rosas cortadas secas anaranjados' },
    { Clave: '10402101', Descripcion: 'Rosal cortado seco alhambra' },
    { Clave: '10402102', Descripcion: 'Rosal cortado seco aloha' },
    { Clave: '10402103', Descripcion: 'Rosal cortado seco amber' },
    { Clave: '10402104', Descripcion: 'Rosal cortado seco apache' },
    { Clave: '10402105', Descripcion: 'Rosal cortado seco arabia' },
    { Clave: '10402106', Descripcion: 'Rosal cortado seco bengala' },
    { Clave: '10402107', Descripcion: 'Rosal cortado seco bibi' },
    { Clave: '10402108', Descripcion: 'Rosal cortado seco caramba' },
    { Clave: '10402109', Descripcion: 'Rosal cortado seco caramella' },
    { Clave: '10402110', Descripcion: 'Rosal cortado seco carla' },
    { Clave: '10402111', Descripcion: 'Rosal cortado seco cartagena' },
    { Clave: '10402112', Descripcion: 'Rosal cortado seco chanson' },
    { Clave: '10402113', Descripcion: 'Rosal cortado seco charmer' },
    { Clave: '10402114', Descripcion: 'Rosal cortado seco cherry brandy' },
    { Clave: '10402115', Descripcion: 'Rosal cortado seco chilis' },
    { Clave: '10402116', Descripcion: 'Rosal cortado seco cinnamon' },
    { Clave: '10402117', Descripcion: 'Rosal cortado seco colandro' },
    { Clave: '10402118', Descripcion: 'Rosal cortado seco coral sea' },
    { Clave: '10402119', Descripcion: 'Rosal cortado seco corvette o red covette' },
    { Clave: '10402120', Descripcion: 'Rosal cortado seco dark milva' },
    { Clave: '10402121', Descripcion: 'Rosal cortado seco donna' },
    { Clave: '10402122', Descripcion: 'Rosal cortado seco dreamer' },
    { Clave: '10402123', Descripcion: 'Rosal cortado seco el dorado' },
    { Clave: '10402124', Descripcion: 'Rosal cortado seco el toro' },
    { Clave: '10402125', Descripcion: 'Rosal cortado seco elena' },
    { Clave: '10402126', Descripcion: 'Rosal cortado seco ensueño' },
    { Clave: '10402127', Descripcion: 'Rosal cortado seco euforia' },
    { Clave: '10402128', Descripcion: 'Rosal cortado seco exótica' },
    { Clave: '10402129', Descripcion: 'Rosal cortado seco fancy amazon' },
    { Clave: '10402130', Descripcion: 'Rosal cortado seco fiction' },
    { Clave: '10402131', Descripcion: 'Rosal cortado seco finess' },
    { Clave: '10402132', Descripcion: 'Rosal cortado seco flameco' },
    { Clave: '10402133', Descripcion: 'Rosal cortado seco free spirit' },
    { Clave: '10402134', Descripcion: 'Rosal cortado seco gelato' },
    { Clave: '10402135', Descripcion: 'Rosal cortado seco gypsy curiosa' },
    { Clave: '10402136', Descripcion: 'Rosal cortado seco high and magic' },
    { Clave: '10402137', Descripcion: 'Rosal cortado seco high and orange' },
    { Clave: '10402138', Descripcion: 'Rosal cortado seco iguana o alegra' },
    { Clave: '10402139', Descripcion: 'Rosal cortado seco impulse' },
    { Clave: '10402140', Descripcion: 'Rosal cortado seco indian femma' },
    { Clave: '10402141', Descripcion: 'Rosal cortado seco indian sunset' },
    { Clave: '10402142', Descripcion: 'Rosal cortado seco karusso' },
    { Clave: '10402143', Descripcion: 'Rosal cortado seco kerio' },
    { Clave: '10402144', Descripcion: 'Rosal cortado seco kiki' },
    { Clave: '10402145', Descripcion: 'Rosal cortado seco latin circus' },
    { Clave: '10402146', Descripcion: 'Rosal cortado seco leonisa' },
    { Clave: '10402147', Descripcion: 'Rosal cortado seco lipstick' },
    { Clave: '10402148', Descripcion: 'Rosal cortado seco lobita' },
    { Clave: '10402149', Descripcion: 'Rosal cortado seco luca' },
    { Clave: '10402150', Descripcion: 'Rosal cortado seco manitou' },
    { Clave: '10402151', Descripcion: 'Rosal cortado seco mariana' },
    { Clave: '10402152', Descripcion: 'Rosal cortado seco marjan o pk sensation' },
    { Clave: '10402153', Descripcion: 'Rosal cortado seco milonga' },
    { Clave: '10402154', Descripcion: 'Rosal cortado seco milva' },
    { Clave: '10402155', Descripcion: 'Rosal cortado seco miracle' },
    { Clave: '10402156', Descripcion: 'Rosal cortado seco mirage' },
    { Clave: '10402157', Descripcion: 'Rosal cortado seco monte carlo' },
    { Clave: '10402158', Descripcion: 'Rosal cortado seco movie star' },
    { Clave: '10402159', Descripcion: 'Rosal cortado seco nikita' },
    { Clave: '10402160', Descripcion: 'Rosal cortado seco orange flame' },
    { Clave: '10402161', Descripcion: 'Rosal cortado seco orange france' },
    { Clave: '10402162', Descripcion: 'Rosal cortado seco orange intuition' },
    { Clave: '10402163', Descripcion: 'Rosal cortado seco orange unique' },
    { Clave: '10402164', Descripcion: 'Rosal cortado seco orangine u orangina' },
    { Clave: '10402165', Descripcion: 'Rosal cortado seco papaya' },
    { Clave: '10402166', Descripcion: 'Rosal cortado seco pareo' },
    { Clave: '10402167', Descripcion: 'Rosal cortado seco peach sherbet' },
    { Clave: '10402168', Descripcion: 'Rosal cortado seco queensday' },
    { Clave: '10402169', Descripcion: 'Rosal cortado seco rosselle' },
    { Clave: '10402170', Descripcion: 'Rosal cortado seco royal circus' },
    { Clave: '10402171', Descripcion: 'Rosal cortado seco sari' },
    { Clave: '10402172', Descripcion: 'Rosal cortado seco sensual' },
    { Clave: '10402173', Descripcion: 'Rosal cortado seco soap' },
    { Clave: '10402174', Descripcion: 'Rosal cortado seco sombrero' },
    { Clave: '10402175', Descripcion: 'Rosal cortado seco spicy' },
    { Clave: '10402176', Descripcion: 'Rosal cortado seco estrella 2000' },
    { Clave: '10402177', Descripcion: 'Rosal cortado seco summer versilia' },
    { Clave: '10402178', Descripcion: 'Rosal cortado seco trixx' },
    { Clave: '10402179', Descripcion: 'Rosal cortado seco tropical amazon' },
    { Clave: '10402180', Descripcion: 'Rosal cortado seco utopia' },
    { Clave: '10402181', Descripcion: 'Rosal cortado seco valentine' },
    { Clave: '10402182', Descripcion: 'Rosal cortado seco verano' },
    { Clave: '10402183', Descripcion: 'Rosal cortado seco versilia' },
    { Clave: '10402184', Descripcion: 'Rosal cortado seco voodoo' },
    { Clave: '10402185', Descripcion: 'Rosal cortado seco wow' },
    { Clave: '10402186', Descripcion: 'Rosal cortado seco yabadabadoo' },
    { Clave: '10402200', Descripcion: 'Rosas cortadas secas durazno' },
    { Clave: '10402201', Descripcion: 'Rosal cortado seco alejandra' },
    { Clave: '10402202', Descripcion: 'Rosal cortado seco azafrán' },
    { Clave: '10402203', Descripcion: 'Rosal cortado seco big fun' },
    { Clave: '10402204', Descripcion: 'Rosal cortado seco cabaret' },
    { Clave: '10402205', Descripcion: 'Rosal cortado seco capuccino' },
    { Clave: '10402206', Descripcion: 'Rosal cortado seco carpe diem' },
    { Clave: '10402207', Descripcion: 'Rosal cortado seco cosima' },
    { Clave: '10402208', Descripcion: 'Rosal cortado seco cumbia' },
    { Clave: '10402209', Descripcion: 'Rosal cortado seco dream' },
    { Clave: '10402210', Descripcion: 'Rosal cortado seco época' },
    { Clave: '10402211', Descripcion: 'Rosal cortado seco fado' },
    { Clave: '10402212', Descripcion: 'Rosal cortado seco femma' },
    { Clave: '10402213', Descripcion: 'Rosal cortado seco guajira' },
    { Clave: '10402214', Descripcion: 'Rosal cortado seco high and arena' },
    { Clave: '10402215', Descripcion: 'Rosal cortado seco high and dandy' },
    { Clave: '10402216', Descripcion: 'Rosal cortado seco high and lucky' },
    { Clave: '10402217', Descripcion: 'Rosal cortado seco high and peach' },
    { Clave: '10402218', Descripcion: 'Rosal cortado seco imagination' },
    { Clave: '10402219', Descripcion: 'Rosal cortado seco isis' },
    { Clave: '10402220', Descripcion: 'Rosal cortado seco joy o light versilia' },
    { Clave: '10402221', Descripcion: 'Rosal cortado seco juliet ausjameson' },
    { Clave: '10402222', Descripcion: 'Rosal cortado seco la parisienne' },
    { Clave: '10402223', Descripcion: 'Rosal cortado seco la perla' },
    { Clave: '10402224', Descripcion: 'Rosal cortado seco lovita sunblaze' },
    { Clave: '10402225', Descripcion: 'Rosal cortado seco malilena o marilena' },
    { Clave: '10402226', Descripcion: 'Rosal cortado seco monyna' },
    { Clave: '10402227', Descripcion: 'Rosal cortado seco nectarine' },
    { Clave: '10402228', Descripcion: 'Rosal cortado seco oriental curiosa' },
    { Clave: '10402229', Descripcion: 'Rosal cortado seco osiana' },
    { Clave: '10402230', Descripcion: 'Rosal cortado seco preach avalanche' },
    { Clave: '10402231', Descripcion: 'Rosal cortado seco peach deja vu' },
    { Clave: '10402232', Descripcion: 'Rosal cortado seco picanto' },
    { Clave: '10402233', Descripcion: 'Rosal cortado seco prima donna' },
    { Clave: '10402234', Descripcion: 'Rosal cortado seco sheril' },
    { Clave: '10402235', Descripcion: 'Rosal cortado seco sirocco' },
    { Clave: '10402236', Descripcion: 'Rosal cortado seco tamara' },
    { Clave: '10402237', Descripcion: 'Rosal cortado seco taxo' },
    { Clave: '10402238', Descripcion: 'Rosal cortado seco trust' },
    { Clave: '10402239', Descripcion: 'Rosal cortado seco valencia' },
    { Clave: '10402240', Descripcion: 'Rosal cortado seco cinci' },
    { Clave: '10402241', Descripcion: 'Rosal cortado seco wanda' },
    { Clave: '10402300', Descripcion: 'Rosas cortadas secas rosados' },
    { Clave: '10402301', Descripcion: 'Rosal cortado seco aerobic' },
    { Clave: '10402302', Descripcion: 'Rosal cortado seco after party' },
    { Clave: '10402303', Descripcion: 'Rosal cortado seco amsterdam' },
    { Clave: '10402304', Descripcion: 'Rosal cortado seco aqua rose' },
    { Clave: '10402305', Descripcion: 'Rosal cortado seco attache' },
    { Clave: '10402306', Descripcion: 'Rosal cortado seco attitude' },
    { Clave: '10402307', Descripcion: 'Rosal cortado seco ballet' },
    { Clave: '10402308', Descripcion: 'Rosal cortado seco belami' },
    { Clave: '10402309', Descripcion: 'Rosal cortado seco bella voo o belle vue' },
    { Clave: '10402310', Descripcion: 'Rosal cortado seco bling bling' },
    { Clave: '10402311', Descripcion: 'Rosal cortado seco blushing akito' },
    { Clave: '10402312', Descripcion: 'Rosal cortado seco brooke' },
    { Clave: '10402313', Descripcion: 'Rosal cortado seco bugatti' },
    { Clave: '10402314', Descripcion: 'Rosal cortado seco cadillac' },
    { Clave: '10402315', Descripcion: 'Rosal cortado seco carnaval' },
    { Clave: '10402316', Descripcion: 'Rosal cortado seco cereza' },
    { Clave: '10402317', Descripcion: 'Rosal cortado seco charming' },
    { Clave: '10402318', Descripcion: 'Rosal cortado seco cherry o' },
    { Clave: '10402319', Descripcion: 'Rosal cortado seco ciciolina' },
    { Clave: '10402320', Descripcion: 'Rosal cortado seco classic cezanne' },
    { Clave: '10402321', Descripcion: 'Rosal cortado seco clasic duett' },
    { Clave: '10402322', Descripcion: 'Rosal cortado seco cosmiq' },
    { Clave: '10402323', Descripcion: 'Rosal cortado seco dark engagement' },
    { Clave: '10402324', Descripcion: 'Rosal cortado seco daytona' },
    { Clave: '10402325', Descripcion: 'Rosal cortado seco dekora' },
    { Clave: '10402326', Descripcion: 'Rosal cortado seco dolores' },
    { Clave: '10402327', Descripcion: 'Rosal cortado seco eliza' },
    { Clave: '10402328', Descripcion: 'Rosal cortado seco baccara' },
    { Clave: '10402329', Descripcion: 'Rosal cortado seco full house' },
    { Clave: '10402330', Descripcion: 'Rosal cortado seco funky' },
    { Clave: '10402331', Descripcion: 'Rosal cortado seco giliane' },
    { Clave: '10402332', Descripcion: 'Rosal cortado seco gran europe' },
    { Clave: '10402333', Descripcion: 'Rosal cortado seco habari' },
    { Clave: '10402334', Descripcion: 'Rosal cortado seco hanseat' },
    { Clave: '10402335', Descripcion: 'Rosal cortado seco high and amazing' },
    { Clave: '10402336', Descripcion: 'Rosal cortado seco high and bonita' },
    { Clave: '10402337', Descripcion: 'Rosal cortado seco high and booming' },
    { Clave: '10402338', Descripcion: 'Rosal cortado seco high and fantasy' },
    { Clave: '10402339', Descripcion: 'Rosal cortado seco high and rich' },
    { Clave: '10402340', Descripcion: 'Rosal cortado seco hot lady' },
    { Clave: '10402341', Descripcion: 'Rosal cortado seco hot princess' },
    { Clave: '10402342', Descripcion: 'Rosal cortado seco inspiration' },
    { Clave: '10402343', Descripcion: 'Rosal cortado seco jeimy' },
    { Clave: '10402344', Descripcion: 'Rosal cortado seco kachita' },
    { Clave: '10402345', Descripcion: 'Rosal cortado seco karen' },
    { Clave: '10402346', Descripcion: 'Rosal cortado seco kenji' },
    { Clave: '10402347', Descripcion: 'Rosal cortado seco kiko' },
    { Clave: '10402348', Descripcion: 'Rosal cortado seco laser' },
    { Clave: '10402349', Descripcion: 'Rosal cortado seco latin duett' },
    { Clave: '10402350', Descripcion: 'Rosal cortado seco latin fever' },
    { Clave: '10402351', Descripcion: 'Rosal cortado seco lifestyle' },
    { Clave: '10402352', Descripcion: 'Rosal cortado seco light orlando' },
    { Clave: '10402353', Descripcion: 'Rosal cortado seco lovely dreams' },
    { Clave: '10402354', Descripcion: 'Rosal cortado seco loyalty' },
    { Clave: '10402355', Descripcion: 'Rosal cortado seco malibu' },
    { Clave: '10402356', Descripcion: 'Rosal cortado seco mata ? hari' },
    { Clave: '10402357', Descripcion: 'Rosal cortado seco memphis' },
    { Clave: '10402358', Descripcion: 'Rosal cortado seco mi amor' },
    { Clave: '10402359', Descripcion: 'Rosal cortado seco miami' },
    { Clave: '10402360', Descripcion: 'Rosal cortado seco michelle' },
    { Clave: '10402361', Descripcion: 'Rosal cortado seco mikaela' },
    { Clave: '10402362', Descripcion: 'Rosal cortado seco orchestra' },
    { Clave: '10402363', Descripcion: 'Rosal cortado seco orlando' },
    { Clave: '10402364', Descripcion: 'Rosal cortado seco osadia' },
    { Clave: '10402365', Descripcion: 'Rosal cortado seco paeonia freelander' },
    { Clave: '10402366', Descripcion: 'Rosal cortado seco paula' },
    { Clave: '10402367', Descripcion: 'Rosal cortado seco pavarotti' },
    { Clave: '10402368', Descripcion: 'Rosal cortado seco pink intuition' },
    { Clave: '10402369', Descripcion: 'Rosal cortado seco poison' },
    { Clave: '10402370', Descripcion: 'Rosal cortado seco princess' },
    { Clave: '10402371', Descripcion: 'Rosal cortado seco queen mary' },
    { Clave: '10402372', Descripcion: 'Rosal cortado seco raphaela' },
    { Clave: '10402373', Descripcion: 'Rosal cortado seco raspberry' },
    { Clave: '10402374', Descripcion: 'Rosal cortado seco ravel' },
    { Clave: '10402375', Descripcion: 'Rosal cortado seco riviera' },
    { Clave: '10402376', Descripcion: 'Rosal cortado seco sade' },
    { Clave: '10402377', Descripcion: 'Rosal cortado seco sashimi' },
    { Clave: '10402378', Descripcion: 'Rosal cortado seco shanya' },
    { Clave: '10402379', Descripcion: 'Rosal cortado seco shocking versilia' },
    { Clave: '10402380', Descripcion: 'Rosal cortado seco solitaire' },
    { Clave: '10402381', Descripcion: 'Rosal cortado seco something different' },
    { Clave: '10402382', Descripcion: 'Rosal cortado seco splendid renate' },
    { Clave: '10402383', Descripcion: 'Rosal cortado seco star' },
    { Clave: '10402384', Descripcion: 'Rosal cortado seco sweet candia' },
    { Clave: '10402385', Descripcion: 'Rosal cortado seco sweet moments' },
    { Clave: '10402386', Descripcion: 'Rosal cortado seco sweet unique' },
    { Clave: '10402387', Descripcion: 'Rosal cortado seco taboo' },
    { Clave: '10402388', Descripcion: 'Rosal cortado seco timona' },
    { Clave: '10402389', Descripcion: 'Rosal cortado seco topaz' },
    { Clave: '10402390', Descripcion: 'Rosal cortado seco vogue' },
    { Clave: '10402391', Descripcion: 'Rosal cortado seco voila' },
    { Clave: '10402392', Descripcion: 'Rosal cortado seco wild' },
    { Clave: '10402393', Descripcion: 'Rosal cortado seco yves piaget' },
    { Clave: '10402400', Descripcion: 'Rosas cortadas secas rojos o burgundy' },
    { Clave: '10402401', Descripcion: 'Rosal cortado seco african dawn' },
    { Clave: '10402402', Descripcion: 'Rosal cortado seco amada' },
    { Clave: '10402403', Descripcion: 'Rosal cortado seco black baccara' },
    { Clave: '10402404', Descripcion: 'Rosal cortado seco black beauty' },
    { Clave: '10402405', Descripcion: 'Rosal cortado seco black finess o black magic' },
    { Clave: '10402406', Descripcion: 'Rosal cortado seco black magic' },
    { Clave: '10402407', Descripcion: 'Rosal cortado seco bohemian o pasarela' },
    { Clave: '10402408', Descripcion: 'Rosal cortado seco breathless' },
    { Clave: '10402409', Descripcion: 'Rosal cortado seco caballero' },
    { Clave: '10402410', Descripcion: 'Rosal cortado seco carrera' },
    { Clave: '10402411', Descripcion: 'Rosal cortado seco charlene' },
    { Clave: '10402412', Descripcion: 'Rosal cortado seco charlotte' },
    { Clave: '10402413', Descripcion: 'Rosal cortado seco cherry lady' },
    { Clave: '10402414', Descripcion: 'Rosal cortado seco cherry love' },
    { Clave: '10402415', Descripcion: 'Rosal cortado seco classy rose' },
    { Clave: '10402416', Descripcion: 'Rosal cortado seco colorado velvet' },
    { Clave: '10402417', Descripcion: 'Rosal cortado seco corazón' },
    { Clave: '10402418', Descripcion: 'Rosal cortado seco corrida' },
    { Clave: '10402419', Descripcion: 'Rosal cortado seco dynamite' },
    { Clave: '10402420', Descripcion: 'Rosal cortado seco eurored' },
    { Clave: '10402421', Descripcion: 'Rosal cortado seco fashion' },
    { Clave: '10402422', Descripcion: 'Rosal cortado seco fire and ice' },
    { Clave: '10402423', Descripcion: 'Rosal cortado seco first red' },
    { Clave: '10402424', Descripcion: 'Rosal cortado seco forever young' },
    { Clave: '10402425', Descripcion: 'Rosal cortado seco freedom' },
    { Clave: '10402426', Descripcion: 'Rosal cortado seco freestyle' },
    { Clave: '10402427', Descripcion: 'Rosal cortado seco friendship' },
    { Clave: '10402428', Descripcion: 'Rosal cortado seco gospel' },
    { Clave: '10402429', Descripcion: 'Rosal cortado seco graffity' },
    { Clave: '10402430', Descripcion: 'Rosal cortado seco grand gala' },
    { Clave: '10402431', Descripcion: 'Rosal cortado seco grand prix' },
    { Clave: '10402432', Descripcion: 'Rosal cortado seco grand classe' },
    { Clave: '10402433', Descripcion: 'Rosal cortado seco hearts' },
    { Clave: '10402434', Descripcion: 'Rosal cortado seco heat' },
    { Clave: '10402435', Descripcion: 'Rosal cortado seco hocus pocus' },
    { Clave: '10402436', Descripcion: 'Rosal cortado seco lady in red' },
    { Clave: '10402437', Descripcion: 'Rosal cortado seco latin lady' },
    { Clave: '10402438', Descripcion: 'Rosal cortado seco legend' },
    { Clave: '10402439', Descripcion: 'Rosal cortado seco lulu' },
    { Clave: '10402440', Descripcion: 'Rosal cortado seco luna rossa' },
    { Clave: '10402441', Descripcion: 'Rosal cortado seco luxor' },
    { Clave: '10402442', Descripcion: 'Rosal cortado seco madame delbard o carola' },
    { Clave: '10402443', Descripcion: 'Rosal cortado seco miss paris' },
    { Clave: '10402444', Descripcion: 'Rosal cortado seco nicole' },
    { Clave: '10402445', Descripcion: 'Rosal cortado seco night fever' },
    { Clave: '10402446', Descripcion: 'Rosal cortado seco obsession' },
    { Clave: '10402447', Descripcion: 'Rosal cortado seco opium' },
    { Clave: '10402448', Descripcion: 'Rosal cortado seco paz' },
    { Clave: '10402449', Descripcion: 'Rosal cortado seco preference' },
    { Clave: '10402450', Descripcion: 'Rosal cortado seco red berlin' },
    { Clave: '10402451', Descripcion: 'Rosal cortado seco red bull' },
    { Clave: '10402452', Descripcion: 'Rosal cortado seco red calypso' },
    { Clave: '10402453', Descripcion: 'Rosal cortado seco red diamond' },
    { Clave: '10402454', Descripcion: 'Rosal cortado seco red fantasy' },
    { Clave: '10402455', Descripcion: 'Rosal cortado seco red france' },
    { Clave: '10402456', Descripcion: 'Rosal cortado seco red intuition' },
    { Clave: '10402457', Descripcion: 'Rosal cortado seco red jewel' },
    { Clave: '10402458', Descripcion: 'Rosal cortado seco red magic' },
    { Clave: '10402459', Descripcion: 'Rosal cortado seco red one' },
    { Clave: '10402460', Descripcion: 'Rosal cortado seco red paris' },
    { Clave: '10402461', Descripcion: 'Rosal cortado seco red princess' },
    { Clave: '10402462', Descripcion: 'Rosal cortado seco red sensation o colorad' },
    { Clave: '10402463', Descripcion: 'Rosal cortado seco red unique' },
    { Clave: '10402464', Descripcion: 'Rosal cortado seco rockefeller' },
    { Clave: '10402465', Descripcion: 'Rosal cortado seco romeo' },
    { Clave: '10402466', Descripcion: 'Rosal cortado seco rouge baiser' },
    { Clave: '10402467', Descripcion: 'Rosal cortado seco roulette' },
    { Clave: '10402468', Descripcion: 'Rosal cortado seco royal massai' },
    { Clave: '10402469', Descripcion: 'Rosal cortado seco royal red' },
    { Clave: '10402470', Descripcion: 'Rosal cortado seco samurai' },
    { Clave: '10402471', Descripcion: 'Rosal cortado seco sexy red' },
    { Clave: '10402472', Descripcion: 'Rosal cortado seco starfire' },
    { Clave: '10402473', Descripcion: 'Rosal cortado seco tango' },
    { Clave: '10402474', Descripcion: 'Rosal cortado seco tiger tail' },
    { Clave: '10402475', Descripcion: 'Rosal cortado seco tinto' },
    { Clave: '10402476', Descripcion: 'Rosal cortado seco top secret' },
    { Clave: '10402477', Descripcion: 'Rosal cortado seco vital' },
    { Clave: '10402478', Descripcion: 'Rosal cortado seco wisdom' },
    { Clave: '10402479', Descripcion: 'Rosal cortado seco xantia' },
    { Clave: '10402480', Descripcion: 'Rosal cortado seco xcite' },
    { Clave: '10402500', Descripcion: 'Rosas cortadas secas sweetheart' },
    { Clave: '10402501', Descripcion: 'Rosal cortado seco burgundy sweetheart' },
    { Clave: '10402502', Descripcion: 'Rosal cortado seco cream sweetheart' },
    { Clave: '10402503', Descripcion: 'Rosal cortado seco hot pink sweetheart' },
    { Clave: '10402504', Descripcion: 'Rosal cortado seco lavender sweetheart' },
    { Clave: '10402505', Descripcion: 'Rosal cortado seco light pink sweetheart' },
    { Clave: '10402506', Descripcion: 'Rosal cortado seco orange sweetheart' },
    { Clave: '10402507', Descripcion: 'Rosal cortado seco peach sweetheart' },
    { Clave: '10402508', Descripcion: 'Rosal cortado seco red sweetheart' },
    { Clave: '10402509', Descripcion: 'Rosal cortado seco white sweetheart' },
    { Clave: '10402510', Descripcion: 'Rosal cortado seco yelllow sweetheart' },
    { Clave: '10402600', Descripcion: 'Rosas cortadas secas blancos' },
    { Clave: '10402601', Descripcion: 'Rosal cortado seco absolut' },
    { Clave: '10402602', Descripcion: 'Rosal cortado seco aida' },
    { Clave: '10402603', Descripcion: 'Rosal cortado seco akito' },
    { Clave: '10402604', Descripcion: 'Rosal cortado seco amelia' },
    { Clave: '10402605', Descripcion: 'Rosal cortado seco anastasia' },
    { Clave: '10402606', Descripcion: 'Rosal cortado seco andean crystal' },
    { Clave: '10402607', Descripcion: 'Rosal cortado seco angel' },
    { Clave: '10402608', Descripcion: 'Rosal cortado seco annemarie' },
    { Clave: '10402609', Descripcion: 'Rosal cortado seco avalanche' },
    { Clave: '10402610', Descripcion: 'Rosal cortado seco bianca' },
    { Clave: '10402611', Descripcion: 'Rosal cortado seco blizzard' },
    { Clave: '10402612', Descripcion: 'Rosal cortado seco bridal akito' },
    { Clave: '10402613', Descripcion: 'Rosal cortado seco domenica' },
    { Clave: '10402614', Descripcion: 'Rosal cortado seco escimo' },
    { Clave: '10402615', Descripcion: 'Rosal cortado seco farfalla' },
    { Clave: '10402616', Descripcion: 'Rosal cortado seco high and peace' },
    { Clave: '10402617', Descripcion: 'Rosal cortado seco high and pure' },
    { Clave: '10402618', Descripcion: 'Rosal cortado seco inocencia o innocenti' },
    { Clave: '10402619', Descripcion: 'Rosal cortado seco ivory' },
    { Clave: '10402620', Descripcion: 'Rosal cortado seco mondial' },
    { Clave: '10402621', Descripcion: 'Rosal cortado seco mount everest' },
    { Clave: '10402622', Descripcion: 'Rosal cortado seco nova zembla' },
    { Clave: '10402623', Descripcion: 'Rosal cortado seco patience o auspator' },
    { Clave: '10402624', Descripcion: 'Rosal cortado seco polar star' },
    { Clave: '10402625', Descripcion: 'Rosal cortado seco polo' },
    { Clave: '10402626', Descripcion: 'Rosal cortado seco proud' },
    { Clave: '10402627', Descripcion: 'Rosal cortado seco snowy jewel' },
    { Clave: '10402628', Descripcion: 'Rosal cortado seco tibet' },
    { Clave: '10402629', Descripcion: 'Rosal cortado seco tineke' },
    { Clave: '10402630', Descripcion: 'Rosal cortado seco vitality' },
    { Clave: '10402631', Descripcion: 'Rosal cortado seco white cadillac' },
    { Clave: '10402632', Descripcion: 'Rosal cortado seco white dove' },
    { Clave: '10402700', Descripcion: 'Rosas cortadas secas amarillos' },
    { Clave: '10402701', Descripcion: 'Rosal cortado seco aalsmeer gold' },
    { Clave: '10402702', Descripcion: 'Rosal cortado seco alina' },
    { Clave: '10402703', Descripcion: 'Rosal cortado seco ambiance' },
    { Clave: '10402704', Descripcion: 'Rosal cortado seco aquarel' },
    { Clave: '10402705', Descripcion: 'Rosal cortado seco autumn' },
    { Clave: '10402706', Descripcion: 'Rosal cortado seco brasil' },
    { Clave: '10402707', Descripcion: 'Rosal cortado seco candle light' },
    { Clave: '10402708', Descripcion: 'Rosal cortado seco cantata o cantate' },
    { Clave: '10402709', Descripcion: 'Rosal cortado seco capriccio' },
    { Clave: '10402710', Descripcion: 'Rosal cortado seco caribbean' },
    { Clave: '10402711', Descripcion: 'Rosal cortado seco circus' },
    { Clave: '10402712', Descripcion: 'Rosal cortado seco citran' },
    { Clave: '10402713', Descripcion: 'Rosal cortado seco concorde' },
    { Clave: '10402714', Descripcion: 'Rosal cortado seco conga' },
    { Clave: '10402715', Descripcion: 'Rosal cortado seco deja vu' },
    { Clave: '10402716', Descripcion: 'Rosal cortado seco desire' },
    { Clave: '10402717', Descripcion: 'Rosal cortado seco donia sol' },
    { Clave: '10402718', Descripcion: 'Rosal cortado seco dueto' },
    { Clave: '10402719', Descripcion: 'Rosal cortado seco erin' },
    { Clave: '10402720', Descripcion: 'Rosal cortado seco exotic curiosa' },
    { Clave: '10402721', Descripcion: 'Rosal cortado seco feria' },
    { Clave: '10402722', Descripcion: 'Rosal cortado seco fire bird' },
    { Clave: '10402723', Descripcion: 'Rosal cortado seco florida' },
    { Clave: '10402724', Descripcion: 'Rosal cortado seco friendly' },
    { Clave: '10402725', Descripcion: 'Rosal cortado seco gallinda' },
    { Clave: '10402726', Descripcion: 'Rosal cortado seco geisha' },
    { Clave: '10402727', Descripcion: 'Rosal cortado seco gelbe' },
    { Clave: '10402728', Descripcion: 'Rosal cortado seco gelosia o yellow flame' },
    { Clave: '10402729', Descripcion: 'Rosal cortado seco gold rush' },
    { Clave: '10402730', Descripcion: 'Rosal cortado seco gold star' },
    { Clave: '10402731', Descripcion: 'Rosal cortado seco gold strike' },
    { Clave: '10402732', Descripcion: 'Rosal cortado seco golda' },
    { Clave: '10402733', Descripcion: 'Rosal cortado seco golden fashion' },
    { Clave: '10402734', Descripcion: 'Rosal cortado seco golden gate' },
    { Clave: '10402735', Descripcion: 'Rosal cortado seco gran dorado' },
    { Clave: '10402736', Descripcion: 'Rosal cortado seco helio' },
    { Clave: '10402737', Descripcion: 'Rosal cortado seco high and exotic' },
    { Clave: '10402738', Descripcion: 'Rosal cortado seco high and yellow' },
    { Clave: '10402739', Descripcion: 'Rosal cortado seco high and yellow magic' },
    { Clave: '10402740', Descripcion: 'Rosal cortado seco high society' },
    { Clave: '10402741', Descripcion: 'Rosal cortado seco hummer' },
    { Clave: '10402742', Descripcion: 'Rosal cortado seco idole o elle' },
    { Clave: '10402743', Descripcion: 'Rosal cortado seco inti' },
    { Clave: '10402744', Descripcion: 'Rosal cortado seco jet set' },
    { Clave: '10402745', Descripcion: 'Rosal cortado seco judy' },
    { Clave: '10402746', Descripcion: 'Rosal cortado seco jupiter' },
    { Clave: '10402747', Descripcion: 'Rosal cortado seco konfetti' },
    { Clave: '10402748', Descripcion: 'Rosal cortado seco kyara o kira' },
    { Clave: '10402749', Descripcion: 'Rosal cortado seco latin beauty' },
    { Clave: '10402750', Descripcion: 'Rosal cortado seco latin spirit' },
    { Clave: '10402751', Descripcion: 'Rosal cortado seco latina' },
    { Clave: '10402752', Descripcion: 'Rosal cortado seco lina' },
    { Clave: '10402753', Descripcion: 'Rosal cortado seco lindsey' },
    { Clave: '10402754', Descripcion: 'Rosal cortado seco male' },
    { Clave: '10402755', Descripcion: 'Rosal cortado seco marie clare' },
    { Clave: '10402756', Descripcion: 'Rosal cortado seco marisa' },
    { Clave: '10402757', Descripcion: 'Rosal cortado seco matchball' },
    { Clave: '10402758', Descripcion: 'Rosal cortado seco melon' },
    { Clave: '10402759', Descripcion: 'Rosal cortado seco mohana' },
    { Clave: '10402760', Descripcion: 'Rosal cortado seco okie dokie' },
    { Clave: '10402761', Descripcion: 'Rosal cortado seco pailine' },
    { Clave: '10402762', Descripcion: 'Rosal cortado seco parrot' },
    { Clave: '10402763', Descripcion: 'Rosal cortado seco rio d oro' },
    { Clave: '10402764', Descripcion: 'Rosal cortado seco salami' },
    { Clave: '10402765', Descripcion: 'Rosal cortado seco santa fe' },
    { Clave: '10402766', Descripcion: 'Rosal cortado seco skyline' },
    { Clave: '10402767', Descripcion: 'Rosal cortado seco sonrisa' },
    { Clave: '10402768', Descripcion: 'Rosal cortado seco star ambiance' },
    { Clave: '10402769', Descripcion: 'Rosal cortado seco starbust' },
    { Clave: '10402770', Descripcion: 'Rosal cortado seco sun king' },
    { Clave: '10402771', Descripcion: 'Rosal cortado seco sunmaster' },
    { Clave: '10402772', Descripcion: 'Rosal cortado seco sunny milva' },
    { Clave: '10402773', Descripcion: 'Rosal cortado seco sushi' },
    { Clave: '10402774', Descripcion: 'Rosal cortado seco tabasco' },
    { Clave: '10402775', Descripcion: 'Rosal cortado seco tara' },
    { Clave: '10402776', Descripcion: 'Rosal cortado seco tresor 2000' },
    { Clave: '10402777', Descripcion: 'Rosal cortado seco ooty' },
    { Clave: '10402778', Descripcion: 'Rosal cortado seco yellow coral' },
    { Clave: '10402779', Descripcion: 'Rosal cortado seco yellow finess' },
    { Clave: '10402780', Descripcion: 'Rosal cortado seco yellow submarine' },
    { Clave: '10402781', Descripcion: 'Rosal cortado seco yellow sunset' },
    { Clave: '10402782', Descripcion: 'Rosal cortado seco yelllow timeless' },
    { Clave: '10402800', Descripcion: 'Rosas cortadas secas spray' },
    { Clave: '10402801', Descripcion: 'Rosal cortado seco alegría spray' },
    { Clave: '10402802', Descripcion: 'Rosal cortado seco andrea follies' },
    { Clave: '10402803', Descripcion: 'Rosal cortado seco antara follies' },
    { Clave: '10402804', Descripcion: 'Rosal cortado seco arrow follies' },
    { Clave: '10402805', Descripcion: 'Rosal cortado seco babe spray' },
    { Clave: '10402806', Descripcion: 'Rosal cortado seco bellina collection' },
    { Clave: '10402807', Descripcion: 'Rosal cortado seco blue moon' },
    { Clave: '10402808', Descripcion: 'Rosal cortado seco chablis spray' },
    { Clave: '10402809', Descripcion: 'Rosal cortado seco cherry follies' },
    { Clave: '10402810', Descripcion: 'Rosal cortado seco chess spray' },
    { Clave: '10402811', Descripcion: 'Rosal cortado seco classic lydia spray' },
    { Clave: '10402812', Descripcion: 'Rosal cortado seco cream gracia spray' },
    { Clave: '10402813', Descripcion: 'Rosal cortado seco cream lydia srpay' },
    { Clave: '10402814', Descripcion: 'Rosal cortado seco cream sensation spray' },
    { Clave: '10402815', Descripcion: 'Rosal cortado seco cremita spray' },
    { Clave: '10402816', Descripcion: 'Rosal cortado seco diablo spray' },
    { Clave: '10402817', Descripcion: 'Rosal cortado seco electra spray' },
    { Clave: '10402818', Descripcion: 'Rosal cortado seco fire king spray' },
    { Clave: '10402819', Descripcion: 'Rosal cortado seco fleurspray' },
    { Clave: '10402820', Descripcion: 'Rosal cortado seco girlie follies spray' },
    { Clave: '10402821', Descripcion: 'Rosal cortado seco giselle follies spray' },
    { Clave: '10402822', Descripcion: 'Rosal cortado seco golden collection spray' },
    { Clave: '10402823', Descripcion: 'Rosal cortado seco golden mimi spray' },
    { Clave: '10402824', Descripcion: 'Rosal cortado seco gracia spray' },
    { Clave: '10402825', Descripcion: 'Rosal cortado seco hot majolica spray' },
    { Clave: '10402826', Descripcion: 'Rosal cortado seco hot pink follies spray' },
    { Clave: '10402827', Descripcion: 'Rosal cortado seco ilse spray' },
    { Clave: '10402828', Descripcion: 'Rosal cortado seco jelena spray' },
    { Clave: '10402829', Descripcion: 'Rosal cortado seco laminuette spray' },
    { Clave: '10402830', Descripcion: 'Rosal cortado seco lavender follies spray' },
    { Clave: '10402831', Descripcion: 'Rosal cortado seco limoncello spray' },
    { Clave: '10402832', Descripcion: 'Rosal cortado seco little silver spray' },
    { Clave: '10402833', Descripcion: 'Rosal cortado seco lovely lydia spray' },
    { Clave: '10402834', Descripcion: 'Rosal cortado seco lucy spray' },
    { Clave: '10402835', Descripcion: 'Rosal cortado seco lydia spray' },
    { Clave: '10402836', Descripcion: 'Rosal cortado seco macarena spray' },
    { Clave: '10402837', Descripcion: 'Rosal cortado seco magic sensation spray' },
    { Clave: '10402838', Descripcion: 'Rosal cortado seco majolica spray' },
    { Clave: '10402839', Descripcion: 'Rosal cortado seco mambo número 5 spray' },
    { Clave: '10402840', Descripcion: 'Rosal cortado seco mambo spray' },
    { Clave: '10402841', Descripcion: 'Rosal cortado seco marlene spray' },
    { Clave: '10402842', Descripcion: 'Rosal cortado seco mimi eden spray' },
    { Clave: '10402843', Descripcion: 'Rosal cortado seco minou spray' },
    { Clave: '10402844', Descripcion: 'Rosal cortado seco nikita spray' },
    { Clave: '10402845', Descripcion: 'Rosal cortado seco novel collection spray' },
    { Clave: '10402846', Descripcion: 'Rosal cortado seco orange success spray' },
    { Clave: '10402847', Descripcion: 'Rosal cortado seco pepita spray' },
    { Clave: '10402848', Descripcion: 'Rosal cortado seco pink flash spray' },
    { Clave: '10402849', Descripcion: 'Rosal cortado seco pink sensation spray' },
    { Clave: '10402850', Descripcion: 'Rosal cortado seco porcelina spray' },
    { Clave: '10402851', Descripcion: 'Rosal cortado seco princess spray' },
    { Clave: '10402852', Descripcion: 'Rosal cortado seco purple mikado spray' },
    { Clave: '10402853', Descripcion: 'Rosal cortado seco red angel spray' },
    { Clave: '10402854', Descripcion: 'Rosal cortado seco red collection spray' },
    { Clave: '10402855', Descripcion: 'Rosal cortado seco red hero spray' },
    { Clave: '10402856', Descripcion: 'Rosal cortado seco red mikado spray' },
    { Clave: '10402857', Descripcion: 'Rosal cortado seco red vision spray' },
    { Clave: '10402858', Descripcion: 'Rosal cortado seco ritmo spray' },
    { Clave: '10402859', Descripcion: 'Rosal cortado seco romance mikado o eva spray' },
    { Clave: '10402860', Descripcion: 'Rosal cortado seco romántica follies spray' },
    { Clave: '10402861', Descripcion: 'Rosal cortado seco rubicon spray' },
    { Clave: '10402862', Descripcion: 'Rosal cortado seco rumba spray' },
    { Clave: '10402863', Descripcion: 'Rosal cortado seco salsa spray' },
    { Clave: '10402864', Descripcion: 'Rosal cortado seco sangrita spray' },
    { Clave: '10402865', Descripcion: 'Rosal cortado seco santa bárbara spray' },
    { Clave: '10402866', Descripcion: 'Rosal cortado seco sashaba spray' },
    { Clave: '10402867', Descripcion: 'Rosal cortado seco scarlett spray' },
    { Clave: '10402868', Descripcion: 'Rosal cortado seco seline spray' },
    { Clave: '10402869', Descripcion: 'Rosal cortado seco sensation spray' },
    { Clave: '10402870', Descripcion: 'Rosal cortado seco silver collection spray' },
    { Clave: '10402871', Descripcion: 'Rosal cortado seco silver sensation spray' },
    { Clave: '10402872', Descripcion: 'Rosal cortado seco snowdance spray' },
    { Clave: '10402873', Descripcion: 'Rosal cortado seco snowflake spray' },
    { Clave: '10402874', Descripcion: 'Rosal cortado seco suncity spray' },
    { Clave: '10402875', Descripcion: 'Rosal cortado seco super nova spray' },
    { Clave: '10402876', Descripcion: 'Rosal cortado seco sweet sensation spray' },
    { Clave: '10402877', Descripcion: 'Rosal cortado seco taifun o typhoon spray' },
    { Clave: '10402878', Descripcion: 'Rosal cortado seco tamango spray' },
    { Clave: '10402879', Descripcion: 'Rosal cortado seco tanger follies spray' },
    { Clave: '10402880', Descripcion: 'Rosal cortado seco tiara spray' },
    { Clave: '10402881', Descripcion: 'Rosal cortado seco tiramisú spray' },
    { Clave: '10402882', Descripcion: 'Rosal cortado seco twinkle bride spray' },
    { Clave: '10402883', Descripcion: 'Rosal cortado seco viviane spray' },
    { Clave: '10402884', Descripcion: 'Rosal cortado seco white majolica spray' },
    { Clave: '10402885', Descripcion: 'Rosal cortado seco white mikado spray' },
    { Clave: '10402886', Descripcion: 'Rosal cortado seco xentina spray' },
    { Clave: '10402887', Descripcion: 'Rosal cortado seco yellow babe spray' },
    { Clave: '10402888', Descripcion: 'Rosal cortado seco yellow follies spray' },
    { Clave: '10411500', Descripcion: 'Anturios cortado secos' },
    { Clave: '10411501', Descripcion: 'Anturio cortado seco chocolate' },
    { Clave: '10411502', Descripcion: 'Anturio cortado seco rojo oscuro' },
    { Clave: '10411503', Descripcion: 'Anturio cortado seco verde' },
    { Clave: '10411504', Descripcion: 'Anturio cortado seco rosado fuerte' },
    { Clave: '10411505', Descripcion: 'Anturio cortado seco mickey mouse' },
    { Clave: '10411506', Descripcion: 'Anturio cortado seco obake verde y blanco' },
    { Clave: '10411507', Descripcion: 'Anturio cortado seco obake rojo y verde' },
    { Clave: '10411508', Descripcion: 'Anturio cortado seco anaranjado' },
    { Clave: '10411509', Descripcion: 'Anturio cortado seco durazno' },
    { Clave: '10411510', Descripcion: 'Anturio cortado seco picasso o pecoso' },
    { Clave: '10411511', Descripcion: 'Anturio cortado seco rojo' },
    { Clave: '10411512', Descripcion: 'Anturio cortado seco splash' },
    { Clave: '10411513', Descripcion: 'Anturio cortado seco tropic fire' },
    { Clave: '10411514', Descripcion: 'Anturio cortado seco verde tulipán' },
    { Clave: '10411515', Descripcion: 'Anturio cortado seco rosado tulipán' },
    { Clave: '10411516', Descripcion: 'Anturio cortado seco púrpura tulipán' },
    { Clave: '10411517', Descripcion: 'Anturio cortado seco rojo tulipán' },
    { Clave: '10411518', Descripcion: 'Anturio cortado seco blanco' },
    { Clave: '10411519', Descripcion: 'Anturio cortado seco blanco salvaje' },
    { Clave: '10411600', Descripcion: 'Allium cortado seco' },
    { Clave: '10411601', Descripcion: 'Allium cortado seco ambassador' },
    { Clave: '10411602', Descripcion: 'Allium cortado seco ampeloprasum' },
    { Clave: '10411603', Descripcion: 'Allium cortado seco bullit o drumstick' },
    { Clave: '10411604', Descripcion: 'Allium cortado seco christophii' },
    { Clave: '10411605', Descripcion: 'Allium cortado seco cowanii' },
    { Clave: '10411606', Descripcion: 'Allium cortado seco gigante' },
    { Clave: '10411607', Descripcion: 'Allium cortado seco gladiador' },
    { Clave: '10411608', Descripcion: 'Allium cortado seco globemaster' },
    { Clave: '10411609', Descripcion: 'Allium cortado seco bola de golf' },
    { Clave: '10411610', Descripcion: 'Allium cortado seco cabello' },
    { Clave: '10411611', Descripcion: 'Allium cortado seco rosado gigante' },
    { Clave: '10411612', Descripcion: 'Allium cortado seco sensación púrpura' },
    { Clave: '10411613', Descripcion: 'Allium cortado seco sicilum hanging' },
    { Clave: '10411614', Descripcion: 'Allium cortado seco spider schubertii' },
    { Clave: '10411615', Descripcion: 'Allium cortado seco spray moly' },
    { Clave: '10411616', Descripcion: 'Allium cortado seco spray roseum' },
    { Clave: '10411617', Descripcion: 'Allium cortado seco tuberosum' },
    { Clave: '10411618', Descripcion: 'Allium cortado seco unifolium o spray' },
    { Clave: '10411619', Descripcion: 'Allium cortado seco monte everest blanco' },
    { Clave: '10411700', Descripcion: 'Astromelia cortada seca' },
    { Clave: '10411701', Descripcion: 'Astromelia cortada seca agropoli' },
    { Clave: '10411702', Descripcion: 'Astromelia cortada seca bourgogne' },
    { Clave: '10411703', Descripcion: 'Astromelia cortada seca cairo' },
    { Clave: '10411704', Descripcion: 'Astromelia cortada seca charmes' },
    { Clave: '10411705', Descripcion: 'Astromelia cortada seca cherry bay' },
    { Clave: '10411706', Descripcion: 'Astromelia cortada seca cherry white' },
    { Clave: '10411707', Descripcion: 'Astromelia cortada seca dame blanche' },
    { Clave: '10411708', Descripcion: 'Astromelia cortada seca diamond' },
    { Clave: '10411709', Descripcion: 'Astromelia cortada seca gran canaria' },
    { Clave: '10411710', Descripcion: 'Astromelia cortada seca harlekijn' },
    { Clave: '10411711', Descripcion: 'Astromelia cortada seca indian summer' },
    { Clave: '10411712', Descripcion: 'Astromelia cortada seca jamaica' },
    { Clave: '10411713', Descripcion: 'Astromelia cortada seca macondo' },
    { Clave: '10411714', Descripcion: 'Astromelia cortada seca mistique' },
    { Clave: '10411715', Descripcion: 'Astromelia cortada seca my fair' },
    { Clave: '10411716', Descripcion: 'Astromelia cortada seca new cairo' },
    { Clave: '10411717', Descripcion: 'Astromelia cortada seca nice' },
    { Clave: '10411718', Descripcion: 'Astromelia cortada seca orange bowl' },
    { Clave: '10411719', Descripcion: 'Astromelia cortada seca orange queens' },
    { Clave: '10411720', Descripcion: 'Astromelia cortada seca orange sun' },
    { Clave: '10411721', Descripcion: 'Astromelia cortada seca paris' },
    { Clave: '10411722', Descripcion: 'Astromelia cortada seca picasso' },
    { Clave: '10411723', Descripcion: 'Astromelia cortada seca pink panther' },
    { Clave: '10411724', Descripcion: 'Astromelia cortada seca prima donna' },
    { Clave: '10411725', Descripcion: 'Astromelia cortada seca red silhouette' },
    { Clave: '10411726', Descripcion: 'Astromelia cortada seca sacha' },
    { Clave: '10411727', Descripcion: 'Astromelia cortada seca salmón' },
    { Clave: '10411728', Descripcion: 'Astromelia cortada seca santiago' },
    { Clave: '10411729', Descripcion: 'Astromelia cortada seca senna' },
    { Clave: '10411730', Descripcion: 'Astromelia cortada seca snowball' },
    { Clave: '10411731', Descripcion: 'Astromelia cortada seca sublime' },
    { Clave: '10411732', Descripcion: 'Astromelia cortada seca tropicana' },
    { Clave: '10411733', Descripcion: 'Astromelia cortada seca virginia' },
    { Clave: '10411734', Descripcion: 'Astromelia cortada seca blanca' },
    { Clave: '10411800', Descripcion: 'Amaranto cortado seco' },
    { Clave: '10411801', Descripcion: 'Amaranto cortado seco verde colgante' },
    { Clave: '10411802', Descripcion: 'Amaranto cortado seco rojo colgante' },
    { Clave: '10411803', Descripcion: 'Amaranto cortado seco bronce erguido' },
    { Clave: '10411804', Descripcion: 'Amaranto cortado seco verde erguido' },
    { Clave: '10411805', Descripcion: 'Amaranto cortado seco rojo erguido' },
    { Clave: '10411900', Descripcion: 'Amaryllis cortado seco' },
    { Clave: '10411901', Descripcion: 'Amaryllis cortado seco naranja' },
    { Clave: '10411902', Descripcion: 'Amaryllis cortado seco orange nagano' },
    { Clave: '10411903', Descripcion: 'Amaryllis cortado seco pigmeo mini' },
    { Clave: '10411904', Descripcion: 'Amaryllis cortado seco red lion' },
    { Clave: '10411905', Descripcion: 'Amaryllis cortado seco rilona' },
    { Clave: '10411906', Descripcion: 'Amaryllis cortado seco royal velvet' },
    { Clave: '10411907', Descripcion: 'Amaryllis cortado seco sonatini naranja' },
    { Clave: '10411908', Descripcion: 'Amaryllis cortado seco sonatini rojo' },
    { Clave: '10411909', Descripcion: 'Amaryllis cortado seco tango' },
    { Clave: '10411910', Descripcion: 'Amaryllis cortado seco tinto night' },
    { Clave: '10412000', Descripcion: 'Anémona cortada seca' },
    { Clave: '10412001', Descripcion: 'Anémona cortada seca aubergine' },
    { Clave: '10412002', Descripcion: 'Anémona cortada seca negra' },
    { Clave: '10412003', Descripcion: 'Anémona cortada seca azul' },
    { Clave: '10412004', Descripcion: 'Anémona cortada seca cereza' },
    { Clave: '10412005', Descripcion: 'Anémona cortada seca coronaria' },
    { Clave: '10412006', Descripcion: 'Anémona cortada seca rosado fuerte' },
    { Clave: '10412007', Descripcion: 'Anémona cortada seca rosado claro' },
    { Clave: '10412008', Descripcion: 'Anémona cortada seca rosado' },
    { Clave: '10412009', Descripcion: 'Anémona cortada seca púrpura' },
    { Clave: '10412010', Descripcion: 'Anémona cortada seca roja' },
    { Clave: '10412011', Descripcion: 'Anémona cortada seca blanca' },
    { Clave: '10412100', Descripcion: 'Asclepia cortada seca' },
    { Clave: '10412101', Descripcion: 'Asclepia cortada seca lavanda' },
    { Clave: '10412102', Descripcion: 'Asclepia cortada seca moby dick' },
    { Clave: '10412103', Descripcion: 'Asclepia cortada seca tuberosa' },
    { Clave: '10412104', Descripcion: 'Asclepia cortada seca blanca' },
    { Clave: '10412200', Descripcion: 'Aster cortada seca' },
    { Clave: '10412201', Descripcion: 'Aster cortada seca beauty' },
    { Clave: '10412202', Descripcion: 'Aster cortada seca japonés azul' },
    { Clave: '10412203', Descripcion: 'Aster cortada seca japonés verde' },
    { Clave: '10412204', Descripcion: 'Aster cortada seca japonés rosado fuerte' },
    { Clave: '10412205', Descripcion: 'Aster cortada seca japonés lavanda' },
    { Clave: '10412206', Descripcion: 'Aster cortada seca japonés rosado claro' },
    { Clave: '10412207', Descripcion: 'Aster cortada seca japonés durazno' },
    { Clave: '10412208', Descripcion: 'Aster cortada seca japonés rosado' },
    { Clave: '10412209', Descripcion: 'Aster cortada seca japonés púrpura' },
    { Clave: '10412210', Descripcion: 'Aster cortada seca japonés rojo' },
    { Clave: '10412211', Descripcion: 'Aster cortada seca japonés spider' },
    { Clave: '10412212', Descripcion: 'Aster cortada seca japonés  blanco' },
    { Clave: '10412213', Descripcion: 'Aster cortada seca novi belgii rosado fuerte' },
    { Clave: '10412214', Descripcion: 'Aster cortada seca novi belgii lavanda' },
    { Clave: '10412215', Descripcion: 'Aster cortada seca novi belgii rosado' },
    { Clave: '10412216', Descripcion: 'Aster cortada seca novi belgii púrpura' },
    { Clave: '10412217', Descripcion: 'Aster cortada seca novi belgii blanco' },
    { Clave: '10412218', Descripcion: 'Aster cortada seca solidago' },
    { Clave: '10412219', Descripcion: 'Aster cortada seca spider' },
    { Clave: '10412300', Descripcion: 'Berzelia cortada seca' },
    { Clave: '10412301', Descripcion: 'Berzelia lanuginosa cortada seca abrotnoides' },
    { Clave: '10412302', Descripcion: 'Berzelia lanuginosa cortada seca fireball' },
    { Clave: '10412303', Descripcion: 'Berzelia lanuginosa cortada seca galpinii' },
    { Clave: '10412304', Descripcion: 'Berzelia lanuginosa cortada seca galpini o baubles' },
    { Clave: '10412305', Descripcion: 'Berzelia lanuginosa cortada seca squarrosa' },
    { Clave: '10412400', Descripcion: 'Bouvaria cortada seca' },
    { Clave: '10412401', Descripcion: 'Bouvardia cortada seca doble rosado fuerte' },
    { Clave: '10412402', Descripcion: 'Bouvardia cortada seca rosado claro' },
    { Clave: '10412403', Descripcion: 'Bouvardia cortada seca doble rosado claro' },
    { Clave: '10412404', Descripcion: 'Bouvardia cortada seca roja' },
    { Clave: '10412405', Descripcion: 'Bouvardia cortada seca blanca' },
    { Clave: '10412500', Descripcion: 'Brodiaea cortada seca' },
    { Clave: '10412501', Descripcion: 'Brodiaea cortada seca congesta' },
    { Clave: '10412502', Descripcion: 'Brodiaea cortada seca congesta lavanda' },
    { Clave: '10412503', Descripcion: 'Brodiaea cortada seca jacinta' },
    { Clave: '10412504', Descripcion: 'Brodiaea cortada seca ida maija' },
    { Clave: '10412505', Descripcion: 'Brodiaea cortada seca starlight' },
    { Clave: '10412600', Descripcion: 'Calla cortada seca' },
    { Clave: '10412601', Descripcion: 'Calla cortada seca green goddess' },
    { Clave: '10412602', Descripcion: 'Calla cortada seca ramillete albertville' },
    { Clave: '10412603', Descripcion: 'Calla cortada seca ramillete aranal' },
    { Clave: '10412604', Descripcion: 'Calla cortada seca ramillete black eyed beauty' },
    { Clave: '10412605', Descripcion: 'Calla cortada seca black star' },
    { Clave: '10412606', Descripcion: 'Calla cortada seca brisbane' },
    { Clave: '10412607', Descripcion: 'Calla cortada seca crystal blush' },
    { Clave: '10412608', Descripcion: 'Calla cortada seca crystal pink' },
    { Clave: '10412609', Descripcion: 'Calla cortada seca crystal white' },
    { Clave: '10412610', Descripcion: 'Calla cortada seca dark captain romanc' },
    { Clave: '10412611', Descripcion: 'Calla cortada seca ramillete dark mozart' },
    { Clave: '10412612', Descripcion: 'Calla cortada seca ramillete dark naomi' },
    { Clave: '10412613', Descripcion: 'Calla cortada seca ramillete deformed calla' },
    { Clave: '10412614', Descripcion: 'Calla cortada seca ramillete dordogne' },
    { Clave: '10412615', Descripcion: 'Calla cortada seca ramillete etude' },
    { Clave: '10412616', Descripcion: 'Calla cortada seca ramillete farao' },
    { Clave: '10412617', Descripcion: 'Calla cortada seca ramillete fire glow' },
    { Clave: '10412618', Descripcion: 'Calla cortada seca ramillete florex gold' },
    { Clave: '10412619', Descripcion: 'Calla cortada seca ramillete garnet glow' },
    { Clave: '10412620', Descripcion: 'Calla cortada seca ramillete hot chocolate' },
    { Clave: '10412621', Descripcion: 'Calla cortada seca ramillete lavender improved' },
    { Clave: '10412622', Descripcion: 'Calla cortada seca ramillete light cromance' },
    { Clave: '10412623', Descripcion: 'Calla cortada seca ramillete little suzy' },
    { Clave: '10412624', Descripcion: 'Calla cortada seca ramillete majestic red' },
    { Clave: '10412625', Descripcion: 'Calla cortada seca ramillete mango' },
    { Clave: '10412626', Descripcion: 'Calla cortada seca ramillete merlot' },
    { Clave: '10412627', Descripcion: 'Calla cortada seca ramillete mozart' },
    { Clave: '10412628', Descripcion: 'Calla cortada seca ramillete naomi' },
    { Clave: '10412629', Descripcion: 'Calla cortada seca ramillete night cap' },
    { Clave: '10412630', Descripcion: 'Calla cortada seca ramillete odessa' },
    { Clave: '10412631', Descripcion: 'Calla cortada seca ramillete pacific pink' },
    { Clave: '10412632', Descripcion: 'Calla cortada seca ramillete passion fruit' },
    { Clave: '10412633', Descripcion: 'Calla cortada seca ramillete picasso' },
    { Clave: '10412634', Descripcion: 'Calla cortada seca ramillete pillow talk' },
    { Clave: '10412635', Descripcion: 'Calla cortada seca ramillete pink persuation' },
    { Clave: '10412636', Descripcion: 'Calla cortada seca ramillete pisa' },
    { Clave: '10412637', Descripcion: 'Calla cortada seca ramillete pot of' },
    { Clave: '10412638', Descripcion: 'Calla cortada seca ramillete red sox' },
    { Clave: '10412639', Descripcion: 'Calla cortada seca ramillete rosa' },
    { Clave: '10412640', Descripcion: 'Calla cortada seca ramillete ruby light rose' },
    { Clave: '10412641', Descripcion: 'Calla cortada seca ramillete samur' },
    { Clave: '10412642', Descripcion: 'Calla cortada seca ramillete sapporo' },
    { Clave: '10412643', Descripcion: 'Calla cortada seca ramillete schwarzwalder' },
    { Clave: '10412644', Descripcion: 'Calla cortada seca ramillete serrada' },
    { Clave: '10412645', Descripcion: 'Calla cortada seca ramillete solemio' },
    { Clave: '10412646', Descripcion: 'Calla cortada seca ramillete sunrise' },
    { Clave: '10412647', Descripcion: 'Calla cortada seca ramillete super mac' },
    { Clave: '10412648', Descripcion: 'Calla cortada seca ramillete swan lake' },
    { Clave: '10412649', Descripcion: 'Calla cortada seca ramillete vermeer' },
    { Clave: '10412650', Descripcion: 'Calla cortada seca ramillete white butterfly' },
    { Clave: '10412651', Descripcion: 'Calla cortada seca ramillete amarilla' },
    { Clave: '10412652', Descripcion: 'Calla cortada seca ramillete yellow mozart' },
    { Clave: '10412653', Descripcion: 'Calla cortada seca ramillete white large' },
    { Clave: '10412800', Descripcion: 'Celosia cortada seca' },
    { Clave: '10412801', Descripcion: 'Cresta de gallo (celosia) cortada seca verde' },
    { Clave: '10412802', Descripcion: 'Cresta de gallo (celosia) cortada seca anaranjado' },
    { Clave: '10412803', Descripcion: 'Cresta de gallo (celosia) cortada seca rosado' },
    { Clave: '10412804', Descripcion: 'Cresta de gallo (celosia) cortada seca púrpura' },
    { Clave: '10412805', Descripcion: 'Cresta de gallo (celosia) cortada seca rojo' },
    { Clave: '10412806', Descripcion: 'Cresta de gallo (celosia) cortada seca amarillo' },
    { Clave: '10412807', Descripcion: 'Cresta de gallo (celosia) cortada seca plume light' },
    { Clave: '10412808', Descripcion: 'Cresta de gallo (celosia) cortada seca plume orange' },
    { Clave: '10412809', Descripcion: 'Cresta de gallo (celosia) cortada seca plume purple' },
    { Clave: '10412810', Descripcion: 'Cresta de gallo (celosia) cortada seca plume red' },
    { Clave: '10412811', Descripcion: 'Cresta de gallo (celosia) cortada seca plume yellow' },
    { Clave: '10412812', Descripcion: 'Cresta de gallo (celosia) cortada seca wheat pink' },
    { Clave: '10412813', Descripcion: 'Cresta de gallo (celosia) cortada seca wheat yellow' },
    { Clave: '10412900', Descripcion: 'Narciso cortado seco' },
    { Clave: '10412901', Descripcion: 'Narciso cortado seco dick wilden' },
    { Clave: '10412902', Descripcion: 'Narciso cortado seco dutch master' },
    { Clave: '10412903', Descripcion: 'Narciso cortado seco ice follies' },
    { Clave: '10412904', Descripcion: 'Narciso cortado seco ice king' },
    { Clave: '10412905', Descripcion: 'Narciso cortado seco johan strauss' },
    { Clave: '10412906', Descripcion: 'Narciso cortado seco yellow carlton' },
    { Clave: '10413000', Descripcion: 'Dalia cortada seca' },
    { Clave: '10413001', Descripcion: 'Dalia cortada seca bicolor' },
    { Clave: '10413002', Descripcion: 'Dalia cortada seca rosado fuerte' },
    { Clave: '10413003', Descripcion: 'Dalia cortada seca rosado claro' },
    { Clave: '10413004', Descripcion: 'Dalia cortada seca rosado medio' },
    { Clave: '10413005', Descripcion: 'Dalia cortada seca anaranjado' },
    { Clave: '10413006', Descripcion: 'Dalia cortada seca durazno' },
    { Clave: '10413007', Descripcion: 'Dalia cortada seca púrpura' },
    { Clave: '10413008', Descripcion: 'Dalia cortada seca roja' },
    { Clave: '10413009', Descripcion: 'Dalia cortada seca blanca' },
    { Clave: '10413010', Descripcion: 'Dalia cortada seca amarilla' },
    { Clave: '10413100', Descripcion: 'Delphinium cortado seco' },
    { Clave: '10413101', Descripcion: 'Delphinium cortado seco bella azul oscuro' },
    { Clave: '10413102', Descripcion: 'Delphinium cortado seco bella azul claro' },
    { Clave: '10413103', Descripcion: 'Delphinium cortado seco bella blanco' },
    { Clave: '10413104', Descripcion: 'Delphinium cortado seco azul sombra' },
    { Clave: '10413105', Descripcion: 'Delphinium cortado seco híbrido azul oscuro' },
    { Clave: '10413106', Descripcion: 'Delphinium cortado seco híbrido azul claro' },
    { Clave: '10413107', Descripcion: 'Delphinium cortado seco híbrido malva' },
    { Clave: '10413108', Descripcion: 'Delphinium cortado seco híbrido rosado' },
    { Clave: '10413109', Descripcion: 'Delphinium cortado seco híbrido púrpura' },
    { Clave: '10413110', Descripcion: 'Delphinium cortado seco híbrido rojo' },
    { Clave: '10413111', Descripcion: 'Delphinium cortado seco híbrido blanco' },
    { Clave: '10413112', Descripcion: 'Delphinium cortado seco princesa carolina' },
    { Clave: '10413113', Descripcion: 'Delphinium cortado seco volkerfrieden' },
    { Clave: '10413200', Descripcion: 'Clavel (dianthus) cortado seco' },
    { Clave: '10413201', Descripcion: 'Clavel (dianthus) cortado seco chocolate' },
    { Clave: '10413202', Descripcion: 'Clavel (dianthus) cortado seco fucsia' },
    { Clave: '10413203', Descripcion: 'Clavel (dianthus) cortado seco bola verde' },
    { Clave: '10413204', Descripcion: 'Clavel (dianthus) cortado seco rosado fuerte' },
    { Clave: '10413205', Descripcion: 'Clavel (dianthus) cortado seco lavanda' },
    { Clave: '10413206', Descripcion: 'Clavel (dianthus) cortado seco frambuesa' },
    { Clave: '10413207', Descripcion: 'Clavel (dianthus) cortado seco rojo' },
    { Clave: '10413208', Descripcion: 'Clavel (dianthus) cortado seco rosado' },
    { Clave: '10413300', Descripcion: 'Eremruses cortadas secas' },
    { Clave: '10413301', Descripcion: 'Eremurus cortado seco deruyter híbrido' },
    { Clave: '10413302', Descripcion: 'Eremurus cortado seco himalaicus blanco' },
    { Clave: '10413303', Descripcion: 'Eremurus cortado seco anaranjado' },
    { Clave: '10413304', Descripcion: 'Eremurus cortado seco durazno' },
    { Clave: '10413305', Descripcion: 'Eremurus cortado seco amarillo' },
    { Clave: '10413400', Descripcion: 'Brezo (erica) cortada seca' },
    { Clave: '10413401', Descripcion: 'Brezo (erica) cortado seco campunalarus' },
    { Clave: '10413402', Descripcion: 'Brezo (erica) cortado seco conica' },
    { Clave: '10413403', Descripcion: 'Brezo (erica) cortado seco green ice' },
    { Clave: '10413404', Descripcion: 'Brezo (erica) cortado seco rosado' },
    { Clave: '10413405', Descripcion: 'Brezo (erica) cortado seco príncipe de gales' },
    { Clave: '10413500', Descripcion: 'Euphorbia cortada seca' },
    { Clave: '10413501', Descripcion: 'Euphorbia cortada seca characias' },
    { Clave: '10413502', Descripcion: 'Euphorbia cortada seca griffithii fireglow' },
    { Clave: '10413503', Descripcion: 'Euphorbia cortada seca martini' },
    { Clave: '10413504', Descripcion: 'Euphorbia cortada seca anaranjada' },
    { Clave: '10413505', Descripcion: 'Euphorbia cortada seca durazno' },
    { Clave: '10413506', Descripcion: 'Euphorbia cortada seca rosada' },
    { Clave: '10413507', Descripcion: 'Euphorbia cortada seca roja' },
    { Clave: '10413508', Descripcion: 'Euphorbia cortada seca blanca' },
    { Clave: '10413509', Descripcion: 'Euphorbia cortada seca amarilla' },
    { Clave: '10413510', Descripcion: 'Euphorbia cortada seca amarilla spurge' },
    { Clave: '10413600', Descripcion: 'Fresia cortada seca' },
    { Clave: '10413601', Descripcion: 'Fresia cortada seca crema' },
    { Clave: '10413602', Descripcion: 'Fresia cortada seca doble blanca' },
    { Clave: '10413603', Descripcion: 'Fresia cortada seca doble amarilla' },
    { Clave: '10413604', Descripcion: 'Fresia cortada seca rosado fuerte' },
    { Clave: '10413605', Descripcion: 'Fresia cortada seca lady brunet' },
    { Clave: '10413606', Descripcion: 'Fresia cortada seca lavanda' },
    { Clave: '10413607', Descripcion: 'Fresia cortada seca rosado medio' },
    { Clave: '10413608', Descripcion: 'Fresia cortada seca anaranjado' },
    { Clave: '10413609', Descripcion: 'Fresia cortada seca pimpinela' },
    { Clave: '10413610', Descripcion: 'Fresia cortada seca rosada' },
    { Clave: '10413611', Descripcion: 'Fresia cortada seca pùrpura' },
    { Clave: '10413612', Descripcion: 'Fresia cortada seca roja' },
    { Clave: '10413613', Descripcion: 'Fresia cortada seca blanca' },
    { Clave: '10413614', Descripcion: 'Fresia cortada seca amarilla' },
    { Clave: '10413700', Descripcion: 'Ajedrezada cortada seca' },
    { Clave: '10413701', Descripcion: 'Ajedrezada cortada seca acmopelata' },
    { Clave: '10413702', Descripcion: 'Ajedrezada cortada seca assyriaca' },
    { Clave: '10413703', Descripcion: 'Ajedrezada cortada seca assyriaca uva vulpis' },
    { Clave: '10413704', Descripcion: 'Ajedrezada cortada seca elysee' },
    { Clave: '10413705', Descripcion: 'Ajedrezada cortada seca imperialis anaranjada' },
    { Clave: '10413706', Descripcion: 'Ajedrezada cortada seca imperialis amarilla' },
    { Clave: '10413707', Descripcion: 'Ajedrezada cortada seca meleagris' },
    { Clave: '10413708', Descripcion: 'Ajedrezada cortada seca michailowski' },
    { Clave: '10413709', Descripcion: 'Ajedrezada cortada seca uva vulpis' },
    { Clave: '10413800', Descripcion: 'Hiniesta cortada seca' },
    { Clave: '10413801', Descripcion: 'Hiniesta cortada seca verde' },
    { Clave: '10413802', Descripcion: 'Hiniesta cortada seca rosado fuerte' },
    { Clave: '10413803', Descripcion: 'Hiniesta cortada seca lavanda' },
    { Clave: '10413804', Descripcion: 'Hiniesta cortada seca rosado claro' },
    { Clave: '10413805', Descripcion: 'Hiniesta cortada seca durazno' },
    { Clave: '10413806', Descripcion: 'Hiniesta cortada seca púrpura' },
    { Clave: '10413807', Descripcion: 'Hiniesta cortada seca blanco' },
    { Clave: '10413808', Descripcion: 'Hiniesta cortada seca amarillo' },
    { Clave: '10413900', Descripcion: 'Margarita cortada seca' },
    { Clave: '10413901', Descripcion: 'Margarita cortada seca crema de centro negro' },
    { Clave: '10413902', Descripcion: 'Margarita cortada seca crema' },
    { Clave: '10413903', Descripcion: 'Margarita cortada seca dorada' },
    { Clave: '10413904', Descripcion: 'Margarita cortada seca rosado fuerte' },
    { Clave: '10413905', Descripcion: 'Margarita cortada seca rosado claro' },
    { Clave: '10413906', Descripcion: 'Margarita cortada seca magenta' },
    { Clave: '10413907', Descripcion: 'Margarita cortada seca mini coral' },
    { Clave: '10413908', Descripcion: 'Margarita cortada seca mini fucsia' },
    { Clave: '10413909', Descripcion: 'Margarita cortada seca mini rosado fuerte' },
    { Clave: '10413910', Descripcion: 'Margarita cortada seca mini anaranjado claro de centro negro' },
    { Clave: '10413911', Descripcion: 'Margarita cortada seca mini anaranjado de centro negro' },
    { Clave: '10413912', Descripcion: 'Margarita cortada seca mini anaranjado' },
    { Clave: '10413913', Descripcion: 'Margarita cortada seca mini rojo de centro negro' },
    { Clave: '10413914', Descripcion: 'Margarita cortada seca mini blanco' },
    { Clave: '10413915', Descripcion: 'Margarita cortada seca mini amarillo claro de centro negro' },
    { Clave: '10413916', Descripcion: 'Margarita cortada seca anaranjado de centro negro' },
    { Clave: '10413917', Descripcion: 'Margarita cortada seca anaranjada' },
    { Clave: '10413918', Descripcion: 'Margarita cortada seca durazno de centro negro' },
    { Clave: '10413919', Descripcion: 'Margarita cortada seca durazno' },
    { Clave: '10413920', Descripcion: 'Margarita cortada seca rosada de centro negro' },
    { Clave: '10413921', Descripcion: 'Margarita cortada seca rosada' },
    { Clave: '10413922', Descripcion: 'Margarita cortada seca roja de centro negro' },
    { Clave: '10413923', Descripcion: 'Margarita cortada seca roja' },
    { Clave: '10413924', Descripcion: 'Margarita cortada seca spider durazno' },
    { Clave: '10413925', Descripcion: 'Margarita cortada seca spider roja' },
    { Clave: '10413926', Descripcion: 'Margarita cortada seca terracota' },
    { Clave: '10413927', Descripcion: 'Margarita cortada seca blanca de centro negro' },
    { Clave: '10413928', Descripcion: 'Margarita cortada seca blanca' },
    { Clave: '10413929', Descripcion: 'Margarita cortada seca amarilla' },
    { Clave: '10414000', Descripcion: 'Jengibre cortado seco' },
    { Clave: '10414001', Descripcion: 'Jengibre cortado seco  indonesio' },
    { Clave: '10414002', Descripcion: 'Jengibre cortado seco jungle king rosado' },
    { Clave: '10414003', Descripcion: 'Jengibre cortado seco jungle king rojo' },
    { Clave: '10414004', Descripcion: 'Jengibre cortado seco rosado' },
    { Clave: '10414005', Descripcion: 'Jengibre cortado seco rojo' },
    { Clave: '10414006', Descripcion: 'Jengibre cortado seco torch' },
    { Clave: '10414100', Descripcion: 'Gladiolo cortado seco' },
    { Clave: '10414101', Descripcion: 'Gladiolo cortado seco burgundy' },
    { Clave: '10414102', Descripcion: 'Gladiolo cortado seco fucsia' },
    { Clave: '10414103', Descripcion: 'Gladiolo cortado seco verde' },
    { Clave: '10414104', Descripcion: 'Gladiolo cortado seco rosado fuerte' },
    { Clave: '10414105', Descripcion: 'Gladiolo cortado seco rosado claro' },
    { Clave: '10414106', Descripcion: 'Gladiolo cortado seco anaranjado' },
    { Clave: '10414107', Descripcion: 'Gladiolo cortado seco durazno' },
    { Clave: '10414108', Descripcion: 'Gladiolo cortado seco rosado medio' },
    { Clave: '10414109', Descripcion: 'Gladiolo cortado seco púrpura' },
    { Clave: '10414110', Descripcion: 'Gladiolo cortado seco rojo bi ? color' },
    { Clave: '10414111', Descripcion: 'Gladiolo cortado seco rojo' },
    { Clave: '10414112', Descripcion: 'Gladiolo cortado seco salmón' },
    { Clave: '10414113', Descripcion: 'Gladiolo cortado seco blanco' },
    { Clave: '10414114', Descripcion: 'Gladiolo cortado seco amarillo' },
    { Clave: '10414200', Descripcion: 'Godetia cortada seca' },
    { Clave: '10414201', Descripcion: 'Godetia cortada seca bicolor' },
    { Clave: '10414202', Descripcion: 'Godetia cortada seca fucsia' },
    { Clave: '10414203', Descripcion: 'Godetia cortada seca rosado fuerte' },
    { Clave: '10414204', Descripcion: 'Godetia cortada seca anaranjada' },
    { Clave: '10414205', Descripcion: 'Godetia cortada seca roja' },
    { Clave: '10414206', Descripcion: 'Godetia cortada seca blanca' },
    { Clave: '10414300', Descripcion: 'Bromelia cortada seca' },
    { Clave: '10414301', Descripcion: 'Bromelia lingulata cortada seca anaranjada' },
    { Clave: '10414302', Descripcion: 'Bromelia lingulata cortada seca roja' },
    { Clave: '10414303', Descripcion: 'Bromelia lingulata cortada seca blanca' },
    { Clave: '10414304', Descripcion: 'Bromelia lingulata cortada seca amarilla' },
    { Clave: '10414305', Descripcion: 'Bromelia variegata cortada seca' },
    { Clave: '10414400', Descripcion: 'Gypsophilia cortada seca' },
    { Clave: '10414401', Descripcion: 'Gypsophilia cortada seca bambino' },
    { Clave: '10414402', Descripcion: 'Gypsophilia cortada seca million stars' },
    { Clave: '10414403', Descripcion: 'Gypsophilia cortada seca mirabella' },
    { Clave: '10414404', Descripcion: 'Gypsophilia cortada seca new love' },
    { Clave: '10414405', Descripcion: 'Gypsophilia cortada seca orión' },
    { Clave: '10414406', Descripcion: 'Gypsophilia cortada seca perfecta' },
    { Clave: '10414500', Descripcion: 'Brezo cortado seco' },
    { Clave: '10414501', Descripcion: 'Brezo cortado seco augustine' },
    { Clave: '10414502', Descripcion: 'Brezo cortado seco érica cuatro hermanas' },
    { Clave: '10414503', Descripcion: 'Brezo cortado seco francés' },
    { Clave: '10414504', Descripcion: 'Brezo cortado seco verde' },
    { Clave: '10414505', Descripcion: 'Brezo cortado seco sterling range blanco' },
    { Clave: '10414506', Descripcion: 'Brezo cortado seco rosado sunset' },
    { Clave: '10414507', Descripcion: 'Brezo cortado seco blanco' },
    { Clave: '10414600', Descripcion: 'Heliconia cortada seca' },
    { Clave: '10414601', Descripcion: 'Heliconia cortada seca bihai claw' },
    { Clave: '10414602', Descripcion: 'Heliconia cortada seca bihai flash' },
    { Clave: '10414603', Descripcion: 'Heliconia cortada seca bihai lobster claw' },
    { Clave: '10414604', Descripcion: 'Heliconia cortada seca caribea red' },
    { Clave: '10414605', Descripcion: 'Heliconia cortada seca caribea yellow' },
    { Clave: '10414606', Descripcion: 'Heliconia cortada seca christmas' },
    { Clave: '10414607', Descripcion: 'Heliconia cortada seca edge of night' },
    { Clave: '10414608', Descripcion: 'Heliconia cortada seca green bihai' },
    { Clave: '10414609', Descripcion: 'Heliconia cortada seca marginata lutea' },
    { Clave: '10414610', Descripcion: 'Heliconia cortada seca psitt fire opal' },
    { Clave: '10414611', Descripcion: 'Heliconia cortada seca psittacorum' },
    { Clave: '10414612', Descripcion: 'Heliconia cortada seca richmond red' },
    { Clave: '10414613', Descripcion: 'Heliconia cortada seca rostrata' },
    { Clave: '10414614', Descripcion: 'Heliconia cortada seca sexy pink' },
    { Clave: '10414615', Descripcion: 'Heliconia cortada seca sexy scarlett' },
    { Clave: '10414616', Descripcion: 'Heliconia cortada seca shogun' },
    { Clave: '10414617', Descripcion: 'Heliconia cortada seca small red' },
    { Clave: '10414618', Descripcion: 'Heliconia cortada seca southern cross' },
    { Clave: '10414619', Descripcion: 'Heliconia cortada seca wagneriana' },
    { Clave: '10414700', Descripcion: 'Jacinto cortado seco' },
    { Clave: '10414701', Descripcion: 'Jacinto cortado seco bean' },
    { Clave: '10414702', Descripcion: 'Jacinto cortado seco durazno' },
    { Clave: '10414703', Descripcion: 'Jacinto cortado seco azul' },
    { Clave: '10414704', Descripcion: 'Jacinto cortado seco fucsia' },
    { Clave: '10414705', Descripcion: 'Jacinto cortado seco rosado fuerte' },
    { Clave: '10414706', Descripcion: 'Jacinto cortado seco lavanda' },
    { Clave: '10414707', Descripcion: 'Jacinto cortado seco azul claro' },
    { Clave: '10414708', Descripcion: 'Jacinto cortado seco rosado medio' },
    { Clave: '10414709', Descripcion: 'Jacinto cortado seco rosado' },
    { Clave: '10414710', Descripcion: 'Jacinto cortado seco estrella púrpura' },
    { Clave: '10414711', Descripcion: 'Jacinto cortado seco blanco' },
    { Clave: '10414712', Descripcion: 'Jacinto cortado seco amarillo' },
    { Clave: '10414800', Descripcion: 'Hydrangea cortada seca' },
    { Clave: '10414801', Descripcion: 'Hydrangea cortada seca anabella' },
    { Clave: '10414802', Descripcion: 'Hydrangea cortada seca azul antiguo' },
    { Clave: '10414803', Descripcion: 'Hydrangea cortada seca azul antiguo  o verde o nueva zelandia' },
    { Clave: '10414804', Descripcion: 'Hydrangea cortada seca verde antiguo' },
    { Clave: '10414805', Descripcion: 'Hydrangea cortada seca rosado antiguo' },
    { Clave: '10414806', Descripcion: 'Hydrangea cortada seca púrpura antiguo o nueva zelandia' },
    { Clave: '10414807', Descripcion: 'Hydrangea cortada seca aubergene o nueva zelandia' },
    { Clave: '10414808', Descripcion: 'Hydrangea cortada seca azul oscuro' },
    { Clave: '10414809', Descripcion: 'Hydrangea cortada seca rosado oscuro' },
    { Clave: '10414810', Descripcion: 'Hydrangea cortada seca púrpura oscuro' },
    { Clave: '10414811', Descripcion: 'Hydrangea cortada seca berenjena' },
    { Clave: '10414812', Descripcion: 'Hydrangea cortada seca verde teñida' },
    { Clave: '10414813', Descripcion: 'Hydrangea cortada seca limón verde' },
    { Clave: '10414814', Descripcion: 'Hydrangea cortada seca rosado fuerte' },
    { Clave: '10414815', Descripcion: 'Hydrangea cortada seca jumbo blanca' },
    { Clave: '10414816', Descripcion: 'Hydrangea cortada seca lavanda o nueva zelandia' },
    { Clave: '10414817', Descripcion: 'Hydrangea cortada seca azul claro' },
    { Clave: '10414818', Descripcion: 'Hydrangea cortada seca rosado claro grande' },
    { Clave: '10414819', Descripcion: 'Hydrangea cortada seca verde lima grande' },
    { Clave: '10414820', Descripcion: 'Hydrangea cortada seca mini verde' },
    { Clave: '10414821', Descripcion: 'Hydrangea cortada seca oakleaf' },
    { Clave: '10414822', Descripcion: 'Hydrangea cortada seca oakleaf copo de nieve' },
    { Clave: '10414823', Descripcion: 'Hydrangea cortada seca rosado teñido' },
    { Clave: '10414824', Descripcion: 'Hydrangea cortada seca rosado' },
    { Clave: '10414825', Descripcion: 'Hydrangea cortada seca púrpura o nueva zelandia' },
    { Clave: '10414826', Descripcion: 'Hydrangea cortada seca rojo teñido' },
    { Clave: '10414827', Descripcion: 'Hydrangea cortada seca shocking blue' },
    { Clave: '10414828', Descripcion: 'Hydrangea cortada seca tardiva' },
    { Clave: '10414829', Descripcion: 'Hydrangea cortada seca blanca' },
    { Clave: '10414900', Descripcion: 'Iris cortada seca' },
    { Clave: '10414901', Descripcion: 'Iris cortada seca negra barbada' },
    { Clave: '10414902', Descripcion: 'Iris cortada seca azul barbada' },
    { Clave: '10414903', Descripcion: 'Iris cortada seca lavanda barbada' },
    { Clave: '10414904', Descripcion: 'Iris cortada seca barbada azul clara' },
    { Clave: '10414905', Descripcion: 'Iris cortada seca barbada púrpura' },
    { Clave: '10414906', Descripcion: 'Iris cortada seca barbada roja' },
    { Clave: '10414907', Descripcion: 'Iris cortada seca barbada blanca' },
    { Clave: '10414908', Descripcion: 'Iris cortada seca baerbada blanca y púrpura' },
    { Clave: '10414909', Descripcion: 'Iris cortada seca amarilla barbada' },
    { Clave: '10414910', Descripcion: 'Iris cortada seca blue elegance' },
    { Clave: '10414911', Descripcion: 'Iris cortada seca casablanca' },
    { Clave: '10414912', Descripcion: 'Iris cortada seca golden beau' },
    { Clave: '10414913', Descripcion: 'Iris cortada seca hildegard' },
    { Clave: '10414914', Descripcion: 'Iris cortada seca hong kong' },
    { Clave: '10414915', Descripcion: 'Iris cortada seca ideal' },
    { Clave: '10414916', Descripcion: 'Iris cortada seca professor blue' },
    { Clave: '10414917', Descripcion: 'Iris cortada seca purple' },
    { Clave: '10414918', Descripcion: 'Iris cortada seca spuria' },
    { Clave: '10414919', Descripcion: 'Iris cortada seca telstar' },
    { Clave: '10415000', Descripcion: 'Pata de canguro cortada seca' },
    { Clave: '10415001', Descripcion: 'Pata de canguro cortada seca bicolor' },
    { Clave: '10415002', Descripcion: 'Pata de canguro cortada seca negra' },
    { Clave: '10415003', Descripcion: 'Pata de canguro cortada seca verde' },
    { Clave: '10415004', Descripcion: 'Pata de canguro cortada seca anaranjada' },
    { Clave: '10415005', Descripcion: 'Pata de canguro cortada seca rosada' },
    { Clave: '10415006', Descripcion: 'Pata de canguro cortada seca roja' },
    { Clave: '10415007', Descripcion: 'Pata de canguro cortada seca amarilla' },
    { Clave: '10415100', Descripcion: 'Delfinio cortado seco' },
    { Clave: '10415101', Descripcion: 'Delfinio cortada seca azul nube' },
    { Clave: '10415102', Descripcion: 'Delfinio cortada seca rosado fuerte' },
    { Clave: '10415103', Descripcion: 'Delfinio cortada seca lavanda' },
    { Clave: '10415104', Descripcion: 'Delfinio cortada seca rosado claro' },
    { Clave: '10415105', Descripcion: 'Delfinio cortada seca púrpura' },
    { Clave: '10415106', Descripcion: 'Delfinio cortada seca blanca' },
    { Clave: '10415200', Descripcion: 'Lepto cortado seco' },
    { Clave: '10415201', Descripcion: 'Lepto cortado seco azul o floreciente' },
    { Clave: '10415202', Descripcion: 'Lepto cortado seco rosado fuerte' },
    { Clave: '10415203', Descripcion: 'Lepto cortado seco rosado claro' },
    { Clave: '10415204', Descripcion: 'Lepto cortado seco rosado' },
    { Clave: '10415205', Descripcion: 'Lepto cortado seco rojo' },
    { Clave: '10415206', Descripcion: 'Lepto cortado seco blanco' },
    { Clave: '10415300', Descripcion: 'Lila cortada seca' },
    { Clave: '10415301', Descripcion: 'Lila  cortada seca híbrida francesa lavanda' },
    { Clave: '10415302', Descripcion: 'Lila  cortada seca híbrida francesa púrpura' },
    { Clave: '10415303', Descripcion: 'Lila  cortada seca púrpura' },
    { Clave: '10415304', Descripcion: 'Lila  cortada seca vino' },
    { Clave: '10415305', Descripcion: 'Lila  cortada seca blanca' },
    { Clave: '10415306', Descripcion: 'Lila  cortada seca blanca salvaje' },
    { Clave: '10415400', Descripcion: 'Azucena cortada seca' },
    { Clave: '10415401', Descripcion: 'Azucena cortada seca alteza longiflorum e híbrido asiática' },
    { Clave: '10415402', Descripcion: 'Azucena cortada seca black out' },
    { Clave: '10415403', Descripcion: 'Azucena cortada seca rosado oscuro' },
    { Clave: '10415404', Descripcion: 'Azucena cortada seca leéctrica asiática' },
    { Clave: '10415405', Descripcion: 'Azucena cortada seca festival asiática' },
    { Clave: '10415406', Descripcion: 'Azucena cortada seca ginebra asiática' },
    { Clave: '10415407', Descripcion: 'Azucena cortada seca rosado claro asiática' },
    { Clave: '10415408', Descripcion: 'Azucena cortada seca colombina asiática' },
    { Clave: '10415409', Descripcion: 'Azucena cortada seca miss américa púrpura asiática' },
    { Clave: '10415410', Descripcion: 'Azucena cortada seca monte negro asiática' },
    { Clave: '10415411', Descripcion: 'Azucena cortada seca anaranjada asiática' },
    { Clave: '10415412', Descripcion: 'Azucena cortada seca durazno cannes asiática' },
    { Clave: '10415413', Descripcion: 'Azucena cortada seca risada asiática' },
    { Clave: '10415414', Descripcion: 'Azucena cortada seca sancerre asiátia' },
    { Clave: '10415415', Descripcion: 'Azucena cortada seca white dream asiática' },
    { Clave: '10415416', Descripcion: 'Azucena cortada seca amarilla asiática' },
    { Clave: '10415417', Descripcion: 'Azucena cortada seca diamante brillante longiflorum e híbrido asiática' },
    { Clave: '10415418', Descripcion: 'Azucena cortada seca brindisi longiflorum e híbrido asiática' },
    { Clave: '10415419', Descripcion: 'Azucena cortada seca carmine longiflorum e híbrido asiática' },
    { Clave: '10415420', Descripcion: 'Azucena cortada seca cinnabar longiflorum e híbrido asiática' },
    { Clave: '10415421', Descripcion: 'Azucena cortada seca club longiflorum e híbrido asiática' },
    { Clave: '10415422', Descripcion: 'Azucena cortada seca discovery longiflorum e híbrido asiática' },
    { Clave: '10415423', Descripcion: 'Azucena cortada seca de pascua' },
    { Clave: '10415424', Descripcion: 'Azucena cortada seca isis longiflorum e híbrido asiática' },
    { Clave: '10415425', Descripcion: 'Azucena cortada seca la hybrid justice longiflorum e híbrido asiática' },
    { Clave: '10415426', Descripcion: 'Azucena cortada seca lace longiflorum e híbrido asiática' },
    { Clave: '10415427', Descripcion: 'Azucena cortada seca lirio de los valles' },
    { Clave: '10415428', Descripcion: 'Azucena cortada seca love longiflorum e híbrido asiática' },
    { Clave: '10415429', Descripcion: 'Azucena cortada seca menorca longiflorum e híbrido asiática' },
    { Clave: '10415430', Descripcion: 'Azucena cortada seca oriental acapulco' },
    { Clave: '10415431', Descripcion: 'Azucena cortada seca oriental albion' },
    { Clave: '10415432', Descripcion: 'Azucena cortada seca oriental argentina' },
    { Clave: '10415433', Descripcion: 'Azucena cortada seca oriental auratum' },
    { Clave: '10415434', Descripcion: 'Azucena cortada seca oriental barbaresco' },
    { Clave: '10415435', Descripcion: 'Azucena cortada seca oriental bernini' },
    { Clave: '10415436', Descripcion: 'Azucena cortada seca oriental beseno' },
    { Clave: '10415437', Descripcion: 'Azucena cortada seca oriental broadway' },
    { Clave: '10415438', Descripcion: 'Azucena cortada seca oriental canada' },
    { Clave: '10415439', Descripcion: 'Azucena cortada seca oriental casablanca' },
    { Clave: '10415440', Descripcion: 'Azucena cortada seca oriental chili' },
    { Clave: '10415441', Descripcion: 'Azucena cortada seca oriental chrystal' },
    { Clave: '10415442', Descripcion: 'Azucena cortada seca oriental cobra' },
    { Clave: '10415443', Descripcion: 'Azucena cortada seca oriental conca d? or' },
    { Clave: '10415444', Descripcion: 'Azucena cortada seca oriental cote d? ivor' },
    { Clave: '10415445', Descripcion: 'Azucena cortada seca oriental dizzy' },
    { Clave: '10415446', Descripcion: 'Azucena cortada seca oriental fireball' },
    { Clave: '10415447', Descripcion: 'Azucena cortada seca oriental gluhwein' },
    { Clave: '10415448', Descripcion: 'Azucena cortada seca oriental goldband' },
    { Clave: '10415449', Descripcion: 'Azucena cortada seca oriental halifax' },
    { Clave: '10415450', Descripcion: 'Azucena cortada seca oriental kathryn' },
    { Clave: '10415451', Descripcion: 'Azucena cortada seca oriental kyoto' },
    { Clave: '10415452', Descripcion: 'Azucena cortada seca oriental la mancha' },
    { Clave: '10415453', Descripcion: 'Azucena cortada seca oriental medusa' },
    { Clave: '10415454', Descripcion: 'Azucena cortada seca oriental montezuma' },
    { Clave: '10415455', Descripcion: 'Azucena cortada seca oriental muscadet' },
    { Clave: '10415456', Descripcion: 'Azucena cortada seca oriental nippon' },
    { Clave: '10415457', Descripcion: 'Azucena cortada seca oriental opus one' },
    { Clave: '10415458', Descripcion: 'Azucena cortada seca oriental pompeii' },
    { Clave: '10415459', Descripcion: 'Azucena cortada seca oriental rialto' },
    { Clave: '10415460', Descripcion: 'Azucena cortada seca oriental robina' },
    { Clave: '10415461', Descripcion: 'Azucena cortada seca oriental rousilon' },
    { Clave: '10415462', Descripcion: 'Azucena cortada seca oriental siberia' },
    { Clave: '10415463', Descripcion: 'Azucena cortada seca oriental sorbonne' },
    { Clave: '10415464', Descripcion: 'Azucena cortada seca oriental starfighter' },
    { Clave: '10415465', Descripcion: 'Azucena cortada seca oriental stargazer' },
    { Clave: '10415466', Descripcion: 'Azucena cortada seca oriental sumatra' },
    { Clave: '10415467', Descripcion: 'Azucena cortada seca oriental time out' },
    { Clave: '10415468', Descripcion: 'Azucena cortada seca oriental tom pouche' },
    { Clave: '10415469', Descripcion: 'Azucena cortada seca oriental tropical' },
    { Clave: '10415470', Descripcion: 'Azucena cortada seca oriental white cup' },
    { Clave: '10415471', Descripcion: 'Azucena cortada seca oriental white merostar' },
    { Clave: '10415472', Descripcion: 'Azucena cortada seca oriental white montana' },
    { Clave: '10415473', Descripcion: 'Azucena cortada seca oriental white stargazer' },
    { Clave: '10415474', Descripcion: 'Azucena cortada seca oriental yellow band' },
    { Clave: '10415475', Descripcion: 'Azucena cortada seca oriental yellow dream' },
    { Clave: '10415476', Descripcion: 'Azucena cortada seca oriental yellow queen' },
    { Clave: '10415477', Descripcion: 'Azucena cortada seca oriental yellow star' },
    { Clave: '10415478', Descripcion: 'Azucena cortada seca oriental yeloween' },
    { Clave: '10415479', Descripcion: 'Azucena cortada seca ot red dutch' },
    { Clave: '10415480', Descripcion: 'Azucena cortada seca sonata nimph' },
    { Clave: '10415481', Descripcion: 'Azucena cortada seca sonata shocking' },
    { Clave: '10415482', Descripcion: 'Azucena cortada seca sonata triumphateer' },
    { Clave: '10415483', Descripcion: 'Azucena cortada seca sunset longiflorum e híbrido asiática' },
    { Clave: '10415484', Descripcion: 'Azucena cortada seca de agua' },
    { Clave: '10415500', Descripcion: 'Lavanda cortada seca' },
    { Clave: '10415501', Descripcion: 'Lavanda marina cortada seca misty peach' },
    { Clave: '10415502', Descripcion: 'Lavanda marina cortada seca misty rosada' },
    { Clave: '10415503', Descripcion: 'Lavanda marina cortada seca misty blanca' },
    { Clave: '10415504', Descripcion: 'Lavanda marina cortada seca misty amarilla' },
    { Clave: '10415505', Descripcion: 'Lavanda marina cortada seca safora' },
    { Clave: '10415506', Descripcion: 'Lavanda marina cortada seca sinensis' },
    { Clave: '10415600', Descripcion: 'Lisianthus cortado seco' },
    { Clave: '10415601', Descripcion: 'Lisianthus cortado seco crema' },
    { Clave: '10415602', Descripcion: 'Lisianthus cortado seco rosado fuerte' },
    { Clave: '10415603', Descripcion: 'Lisianthus cortado seco verde' },
    { Clave: '10415604', Descripcion: 'Lisianthus cortado seco lavanda' },
    { Clave: '10415605', Descripcion: 'Lisianthus cortado seco rosado claro' },
    { Clave: '10415606', Descripcion: 'Lisianthus cortado seco mini blanco' },
    { Clave: '10415607', Descripcion: 'Lisianthus cortado seco durazno' },
    { Clave: '10415608', Descripcion: 'Lisianthus cortado seco rosado con borde blanco' },
    { Clave: '10415609', Descripcion: 'Lisianthus cortado seco púrpura' },
    { Clave: '10415610', Descripcion: 'Lisianthus cortado seco púrpura con borde blanco' },
    { Clave: '10415611', Descripcion: 'Lisianthus cortado seco blanco con borde rosado' },
    { Clave: '10415612', Descripcion: 'Lisianthus cortado seco blanco' },
    { Clave: '10415613', Descripcion: 'Lisianthus cortado seco blanco con borde pùrpura' },
    { Clave: '10415700', Descripcion: 'Muscari cortada seca' },
    { Clave: '10415701', Descripcion: 'Muscari cortada seca armeniacum' },
    { Clave: '10415702', Descripcion: 'Muscari cortada seca bortyoides blanca' },
    { Clave: '10415703', Descripcion: 'Muscari cortada seca verde' },
    { Clave: '10415704', Descripcion: 'Muscari cortada seca latifolia' },
    { Clave: '10415705', Descripcion: 'Muscari cortada seca valerie finn' },
    { Clave: '10415800', Descripcion: 'Narciso cortado seco' },
    { Clave: '10415801', Descripcion: 'Narciso cortado seco alegría' },
    { Clave: '10415802', Descripcion: 'Narciso cortado seco amanecer dorado' },
    { Clave: '10415803', Descripcion: 'Narciso cortado seco abba paperwhite' },
    { Clave: '10415804', Descripcion: 'Narciso cortado seco paperwhite' },
    { Clave: '10415805', Descripcion: 'Narciso cortado seco ojo de faisán' },
    { Clave: '10415806', Descripcion: 'Narciso cortado seco soleil d? or' },
    { Clave: '10415807', Descripcion: 'Narciso cortado seco tete a tete' },
    { Clave: '10415808', Descripcion: 'Narciso cortado seco thalia' },
    { Clave: '10416000', Descripcion: 'Pimientos ornamentales cortado secos' },
    { Clave: '10416001', Descripcion: 'Pimiento chile cortado seco ornamental' },
    { Clave: '10416002', Descripcion: 'Pimiento mezclado cortado seco ornamental' },
    { Clave: '10416003', Descripcion: 'Pimiento anaranjado cortado seco ornamental' },
    { Clave: '10416004', Descripcion: 'Pimiento rojo cortado seco ornamental' },
    { Clave: '10416005', Descripcion: 'Pimiento amarillo cortado seco ornamental' },
    { Clave: '10416100', Descripcion: 'Estrella de Belén cortada seca' },
    { Clave: '10416101', Descripcion: 'Estrella de belén cortada seca arábica' },
    { Clave: '10416102', Descripcion: 'Estrella de belén cortada seca dubium anaranjada' },
    { Clave: '10416103', Descripcion: 'Estrella de belén cortada seca umbellada' },
    { Clave: '10416104', Descripcion: 'Estrella de belén cortada seca dubium blanca' },
    { Clave: '10416105', Descripcion: 'Estrella de belén cortada seca dubium amarilla' },
    { Clave: '10416200', Descripcion: 'Peonia cortada seca' },
    { Clave: '10416201', Descripcion: 'Peonia cortada seca alexander fleming' },
    { Clave: '10416202', Descripcion: 'Peonia cortada seca coral charm' },
    { Clave: '10416203', Descripcion: 'Peonia cortada seca suset' },
    { Clave: '10416204', Descripcion: 'Peonia cortada seca coral supreme' },
    { Clave: '10416205', Descripcion: 'Peonia cortada seca gardenia doble' },
    { Clave: '10416206', Descripcion: 'Peonia cortada seca doble jues eli oscura' },
    { Clave: '10416207', Descripcion: 'Peonia cortada seca soble duquesa blanca' },
    { Clave: '10416208', Descripcion: 'Peonia cortada seca felix crousse' },
    { Clave: '10416209', Descripcion: 'Peonia cortada seca festiva máxima' },
    { Clave: '10416210', Descripcion: 'Peonia cortada seca tesoro del jardín' },
    { Clave: '10416211', Descripcion: 'Peonia cortada seca kansas rosado oscuro' },
    { Clave: '10416212', Descripcion: 'Peonia cortada seca karl rosenfelt' },
    { Clave: '10416213', Descripcion: 'Peonia cortada seca paula fay' },
    { Clave: '10416214', Descripcion: 'Peonia cortada seca encanto rojo' },
    { Clave: '10416215', Descripcion: 'Peonia cortada seca pasion roja' },
    { Clave: '10416216', Descripcion: 'Peonia cortada seca sarah bernhardt roja' },
    { Clave: '10416217', Descripcion: 'Peonia cortada seca scarlet o? hara' },
    { Clave: '10416218', Descripcion: 'Peonia cortada seca shirley temple' },
    { Clave: '10416300', Descripcion: 'Banksia cortada seca' },
    { Clave: '10416301', Descripcion: 'Banksia cortada seca ashbyi' },
    { Clave: '10416302', Descripcion: 'Banksia cortada seca baxteri' },
    { Clave: '10416306', Descripcion: 'Banksia cortada seca coccinea' },
    { Clave: '10416311', Descripcion: 'Banksia cortada seca ericifolia' },
    { Clave: '10416315', Descripcion: 'Banksia cortada seca verde' },
    { Clave: '10416322', Descripcion: 'Banksia cortada seca menziesii' },
    { Clave: '10416325', Descripcion: 'Banksia cortada seca blanco natural' },
    { Clave: '10416326', Descripcion: 'Banksia cortada seca anaranjado' },
    { Clave: '10416332', Descripcion: 'Banksia cortada seca rosada' },
    { Clave: '10416400', Descripcion: 'Ranúculo cortado seco' },
    { Clave: '10416401', Descripcion: 'Ranúnculo cortado seco chocolate' },
    { Clave: '10416402', Descripcion: 'Ranúnculo cortado seco elegancia' },
    { Clave: '10416403', Descripcion: 'Ranúnculo cortado seco verde' },
    { Clave: '10416404', Descripcion: 'Ranúnculo cortado seco grimaldi' },
    { Clave: '10416405', Descripcion: 'Ranúnculo cortado seco rosado fuerte' },
    { Clave: '10416406', Descripcion: 'Ranúnculo cortado seco rosado claro' },
    { Clave: '10416407', Descripcion: 'Ranúnculo cortado seco anaranjado' },
    { Clave: '10416408', Descripcion: 'Ranúnculo cortado seco centro verde' },
    { Clave: '10416409', Descripcion: 'Ranúnculo cortado seco rosado' },
    { Clave: '10416410', Descripcion: 'Ranúnculo cortado seco rojo' },
    { Clave: '10416411', Descripcion: 'Ranúnculo cortado seco blanco' },
    { Clave: '10416412', Descripcion: 'Ranúnculo cortado seco amarillo' },
    { Clave: '10416413', Descripcion: 'Ranúnculo cortado seco salmón' },
    { Clave: '10416500', Descripcion: 'Escabiosa cortada seca' },
    { Clave: '10416501', Descripcion: 'Escabiosa cortada seca anual' },
    { Clave: '10416502', Descripcion: 'Escabiosa cortada seca negra' },
    { Clave: '10416503', Descripcion: 'Escabiosa cortada seca caucásica azul' },
    { Clave: '10416504', Descripcion: 'Escabiosa cortada seca caucásica rosada' },
    { Clave: '10416505', Descripcion: 'Escabiosa cortada seca caucásica vainas' },
    { Clave: '10416506', Descripcion: 'Escabiosa cortada seca caucásica blanca' },
    { Clave: '10416507', Descripcion: 'Escabiosa cortada seca fresa' },
    { Clave: '10416600', Descripcion: 'Retama de escobas cortada seca' },
    { Clave: '10416601', Descripcion: 'Retama de escobas cortada seca rosada' },
    { Clave: '10416602', Descripcion: 'Retama de escobas cortada seca púrpura' },
    { Clave: '10416603', Descripcion: 'Retama de escobas cortada seca blanca' },
    { Clave: '10416604', Descripcion: 'Retama de escobas cortada seca amarilla' },
    { Clave: '10416700', Descripcion: 'Boca de dragón cortada seca' },
    { Clave: '10416701', Descripcion: 'Boca de dragón cortada seca bicolor' },
    { Clave: '10416702', Descripcion: 'Boca de dragón cortada seca burgundy' },
    { Clave: '10416703', Descripcion: 'Boca de dragón cortada seca rosado fuerte' },
    { Clave: '10416704', Descripcion: 'Boca de dragón cortada seca lavanda' },
    { Clave: '10416705', Descripcion: 'Boca de dragón cortada seca anaranjado claro' },
    { Clave: '10416706', Descripcion: 'Boca de dragón cortada seca rosado claro' },
    { Clave: '10416707', Descripcion: 'Boca de dragón cortada seca anaranjado' },
    { Clave: '10416708', Descripcion: 'Boca de dragón cortada seca blanco' },
    { Clave: '10416709', Descripcion: 'Boca de dragón cortada seca amarillo' },
    { Clave: '10416800', Descripcion: 'Romero del pantano cortada seca' },
    { Clave: '10416801', Descripcion: 'Romero del pantano cortada seca azul' },
    { Clave: '10416802', Descripcion: 'Romero del pantano cortada seca lavanda' },
    { Clave: '10416803', Descripcion: 'Romero del pantano cortada seca durazno' },
    { Clave: '10416804', Descripcion: 'Romero del pantano cortada seca rosada' },
    { Clave: '10416805', Descripcion: 'Romero del pantano cortada seca púrpura' },
    { Clave: '10416806', Descripcion: 'Romero del pantano cortada seca espuma de mar' },
    { Clave: '10416807', Descripcion: 'Romero del pantano cortada seca blanca' },
    { Clave: '10416808', Descripcion: 'Romero del pantano cortada seca amarilla' },
    { Clave: '10416900', Descripcion: 'Matiola cortada seca' },
    { Clave: '10416901', Descripcion: 'Matiola cortada seca apricot' },
    { Clave: '10416902', Descripcion: 'Matiola cortada seca crema' },
    { Clave: '10416903', Descripcion: 'Matiola cortada seca fucsia' },
    { Clave: '10416904', Descripcion: 'Matiola cortada seca lavanda' },
    { Clave: '10416905', Descripcion: 'Matiola cortada seca lavanda claro' },
    { Clave: '10416906', Descripcion: 'Matiola cortada seca rosado pacífico' },
    { Clave: '10416907', Descripcion: 'Matiola cortada seca púrpura' },
    { Clave: '10416908', Descripcion: 'Matiola cortada seca rojo rubí' },
    { Clave: '10416909', Descripcion: 'Matiola cortada seca enamorada rosda' },
    { Clave: '10416910', Descripcion: 'Matiola cortada seca blanca' },
    { Clave: '10417000', Descripcion: 'Girasol cortada seca' },
    { Clave: '10417001', Descripcion: 'Girasol cortada seca tinte festivo' },
    { Clave: '10417002', Descripcion: 'Girasol cortada seca mahogany' },
    { Clave: '10417003', Descripcion: 'Girasol cortada seca rayo de sol' },
    { Clave: '10417004', Descripcion: 'Girasol cortada seca brillo del sol' },
    { Clave: '10417005', Descripcion: 'Girasol cortada seca salpicada de sol' },
    { Clave: '10417006', Descripcion: 'Girasol cortada seca oso de peluche' },
    { Clave: '10417100', Descripcion: 'Guisante de olor cortado seco' },
    { Clave: '10417101', Descripcion: 'Guisante de olor cortado seco verde teñido' },
    { Clave: '10417102', Descripcion: 'Guisante de olor cortado seco rosado fuerte' },
    { Clave: '10417103', Descripcion: 'Guisante de olor cortado seco lavanda' },
    { Clave: '10417104', Descripcion: 'Guisante de olor cortado seco rosado claro' },
    { Clave: '10417105', Descripcion: 'Guisante de olor cortado seco anaranjado' },
    { Clave: '10417106', Descripcion: 'Guisante de olor cortado seco durazno teñido' },
    { Clave: '10417107', Descripcion: 'Guisante de olor cortado seco púrpura' },
    { Clave: '10417108', Descripcion: 'Guisante de olor cortado seco blanco' },
    { Clave: '10417200', Descripcion: 'Cardo cortado seco' },
    { Clave: '10417201', Descripcion: 'Cardo cortado seco alpinum' },
    { Clave: '10417202', Descripcion: 'Cardo cortado seco echinops' },
    { Clave: '10417203', Descripcion: 'Cardo cortado seco eryngium árabe' },
    { Clave: '10417204', Descripcion: 'Cardo cortado seco eryngium azul' },
    { Clave: '10417205', Descripcion: 'Cardo cortado seco eryngium orión' },
    { Clave: '10417206', Descripcion: 'Cardo cortado seco eryngium frambuesa' },
    { Clave: '10417207', Descripcion: 'Cardo cortado seco eryngium supernova' },
    { Clave: '10417208', Descripcion: 'Cardo cortado seco eryngium campanita' },
    { Clave: '10417300', Descripcion: 'Tulipán cortado seco' },
    { Clave: '10417301', Descripcion: 'Tulipán cortado seco adrem' },
    { Clave: '10417302', Descripcion: 'Tulipán cortado seco apricot' },
    { Clave: '10417303', Descripcion: 'Tulipán cortado seco bicolor rojo y amarillo' },
    { Clave: '10417304', Descripcion: 'Tulipán cortado seco doble bicolor' },
    { Clave: '10417305', Descripcion: 'Tulipán cortado seco doble rosado' },
    { Clave: '10417306', Descripcion: 'Tulipán cortado seco doble rojo' },
    { Clave: '10417307', Descripcion: 'Tulipán cortado seco doble blanco' },
    { Clave: '10417308', Descripcion: 'Tulipán cortado seco doble amarillo' },
    { Clave: '10417309', Descripcion: 'Tulipán cortado seco francés avignon' },
    { Clave: '10417310', Descripcion: 'Tulipán cortado seco francés camarque' },
    { Clave: '10417311', Descripcion: 'Tulipán cortado seco francés dordogne' },
    { Clave: '10417312', Descripcion: 'Tulipán cortado seco francés fiat' },
    { Clave: '10417313', Descripcion: 'Tulipán cortado seco francés flamboyant' },
    { Clave: '10417314', Descripcion: 'Tulipán cortado seco francés flaming parrot' },
    { Clave: '10417315', Descripcion: 'Tulipán cortado seco francés florissa' },
    { Clave: '10417316', Descripcion: 'Tulipán cortado seco francés doble maureen' },
    { Clave: '10417317', Descripcion: 'Tulipán cortado seco francés maureen' },
    { Clave: '10417318', Descripcion: 'Tulipán cortado seco francés menton' },
    { Clave: '10417319', Descripcion: 'Tulipán cortado seco francés montpellier' },
    { Clave: '10417320', Descripcion: 'Tulipán cortado seco francés naranja unique' },
    { Clave: '10417321', Descripcion: 'Tulipán cortado seco francés peonia reconocido único' },
    { Clave: '10417322', Descripcion: 'Tulipán cortado seco francés loro rosado' },
    { Clave: '10417323', Descripcion: 'Tulipán cortado seco francés princesa unique' },
    { Clave: '10417324', Descripcion: 'Tulipán cortado seco francés reconocido' },
    { Clave: '10417325', Descripcion: 'Tulipán cortado seco francés schepppers' },
    { Clave: '10417326', Descripcion: 'Tulipán cortado seco francés gamuza' },
    { Clave: '10417327', Descripcion: 'Tulipán cortado seco francés toyota' },
    { Clave: '10417328', Descripcion: 'Tulipán cortado seco francés loro weber' },
    { Clave: '10417329', Descripcion: 'Tulipán cortado seco francés loro blanco' },
    { Clave: '10417330', Descripcion: 'Tulipán cortado seco lavanda de borde crespo' },
    { Clave: '10417331', Descripcion: 'Tulipán cortado seco rosado fuerte' },
    { Clave: '10417332', Descripcion: 'Tulipán cortado seco rosado fuerte hoja variegada' },
    { Clave: '10417333', Descripcion: 'Tulipán cortado seco lavanda' },
    { Clave: '10417334', Descripcion: 'Tulipán cortado seco rosado claro hoja variegada' },
    { Clave: '10417335', Descripcion: 'Tulipán cortado seco viuda alegre' },
    { Clave: '10417336', Descripcion: 'Tulipán cortado seco anaranjado' },
    { Clave: '10417337', Descripcion: 'Tulipán cortado seco loro negro' },
    { Clave: '10417338', Descripcion: 'Tulipán cortado seco loro estela rijnveld' },
    { Clave: '10417339', Descripcion: 'Tulipán cortado seco llameante' },
    { Clave: '10417340', Descripcion: 'Tulipán cortado seco loro verde' },
    { Clave: '10417341', Descripcion: 'Tulipán cortado seco loro lavanda' },
    { Clave: '10417342', Descripcion: 'Tulipán cortado seco loro anaranjado' },
    { Clave: '10417343', Descripcion: 'Tulipán cortado seco loro durazno' },
    { Clave: '10417344', Descripcion: 'Tulipán cortado seco loro rosado' },
    { Clave: '10417345', Descripcion: 'Tulipán cortado seco loro rojo' },
    { Clave: '10417346', Descripcion: 'Tulipán cortado seco loro rojo rococó' },
    { Clave: '10417347', Descripcion: 'Tulipán cortado seco loro weber' },
    { Clave: '10417348', Descripcion: 'Tulipán cortado seco loro blanco' },
    { Clave: '10417349', Descripcion: 'Tulipán cortado seco loro rosado' },
    { Clave: '10417350', Descripcion: 'Tulipán cortado seco rosado' },
    { Clave: '10417351', Descripcion: 'Tulipán cortado seco púrpura' },
    { Clave: '10417352', Descripcion: 'Tulipán cortado seco rojo' },
    { Clave: '10417353', Descripcion: 'Tulipán cortado seco especias' },
    { Clave: '10417354', Descripcion: 'Tulipán cortado seco blanco' },
    { Clave: '10417355', Descripcion: 'Tulipán cortado seco amarillo' },
    { Clave: '10417400', Descripcion: 'Flor de cera cortada seca' },
    { Clave: '10417401', Descripcion: 'Flor de cera cortada seca alba' },
    { Clave: '10417402', Descripcion: 'Flor de cera cortada seca bicolor' },
    { Clave: '10417403', Descripcion: 'Flor de cera cortada seca chichilla' },
    { Clave: '10417404', Descripcion: 'Flor de cera cortada seca reina danzante' },
    { Clave: '10417405', Descripcion: 'Flor de cera cortada seca dinamarca' },
    { Clave: '10417406', Descripcion: 'Flor de cera cortada seca denmar' },
    { Clave: '10417407', Descripcion: 'Flor de cera cortada seca híbrida pastel flor gema' },
    { Clave: '10417408', Descripcion: 'Flor de cera cortada seca híbrida rosada flor gem' },
    { Clave: '10417409', Descripcion: 'Flor de cera cortada seca híbrida blanca rubia' },
    { Clave: '10417410', Descripcion: 'Flor de cera cortada seca híbrida eric john' },
    { Clave: '10417411', Descripcion: 'Flor de cera cortada seca híbrida dama pintada' },
    { Clave: '10417412', Descripcion: 'Flor de cera cortada seca híbrida revelación' },
    { Clave: '10417413', Descripcion: 'Flor de cera cortada seca híbrida bola de nieve' },
    { Clave: '10417414', Descripcion: 'Flor de cera cortada seca juriens brook' },
    { Clave: '10417415', Descripcion: 'Flor de cera cortada seca lady stephanie rosada' },
    { Clave: '10417416', Descripcion: 'Flor de cera cortada seca madonna' },
    { Clave: '10417417', Descripcion: 'Flor de cera cortada seca mini blanca' },
    { Clave: '10417418', Descripcion: 'Flor de cera cortada seca anaranjada' },
    { Clave: '10417419', Descripcion: 'Flor de cera cortada seca perla' },
    { Clave: '10417420', Descripcion: 'Flor de cera cortada seca pixie moon' },
    { Clave: '10417421', Descripcion: 'Flor de cera cortada seca orgullo púrpura' },
    { Clave: '10417422', Descripcion: 'Flor de cera cortada seca roja' },
    { Clave: '10417423', Descripcion: 'Flor de cera cortada seca wanaroo' },
    { Clave: '10417424', Descripcion: 'Flor de cera cortada seca amarilla' },
    { Clave: '10417500', Descripcion: 'Hierba de aquiles cortada seca' },
    { Clave: '10417501', Descripcion: 'Hierba de aquiles cortada seca burgundy' },
    { Clave: '10417502', Descripcion: 'Hierba de aquiles cortada seca crema campesina' },
    { Clave: '10417503', Descripcion: 'Hierba de aquiles cortada seca rosado campesino' },
    { Clave: '10417504', Descripcion: 'Hierba de aquiles cortada seca moonshine' },
    { Clave: '10417505', Descripcion: 'Hierba de aquiles cortada seca anaranjado' },
    { Clave: '10417506', Descripcion: 'Hierba de aquiles cortada seca durazno' },
    { Clave: '10417507', Descripcion: 'Hierba de aquiles cortada seca rosada' },
    { Clave: '10417508', Descripcion: 'Hierba de aquiles cortada seca rojo teñido' },
    { Clave: '10417509', Descripcion: 'Hierba de aquiles cortada seca blanca' },
    { Clave: '10417510', Descripcion: 'Hierba de aquiles cortada seca amarilla' },
    { Clave: '10417600', Descripcion: 'Zinia cortada seca' },
    { Clave: '10417601', Descripcion: 'Zinia cortada seca rosado fuerte' },
    { Clave: '10417602', Descripcion: 'Zinia cortada seca mini' },
    { Clave: '10417603', Descripcion: 'Zinia cortada seca rosado' },
    { Clave: '10417604', Descripcion: 'Zinia cortada seca rojo' },
    { Clave: '10417605', Descripcion: 'Zinia cortada seca salmón' },
    { Clave: '10417606', Descripcion: 'Zinia cortada seca amarillo' },
    { Clave: '10417700', Descripcion: 'Forsythia cortada seca' },
    { Clave: '10417701', Descripcion: 'Forsythia cortada seca viridissima' },
    { Clave: '10417702', Descripcion: 'Forsythia cortada seca giraldiana' },
    { Clave: '10417703', Descripcion: 'Forsythia cortada seca mira' },
    { Clave: '10417704', Descripcion: 'Forsythia cortada seca suspensa' },
    { Clave: '10417705', Descripcion: 'Forsythia cortada seca intermedia' },
    { Clave: '10417706', Descripcion: 'Forsythia cortada seca variabilis' },
    { Clave: '10417707', Descripcion: 'Forsythia cortada seca ovate' },
    { Clave: '10417708', Descripcion: 'Forsythia cortada seca intermedia lynwood' },
    { Clave: '10417800', Descripcion: 'Geranio cortado seco' },
    { Clave: '10417801', Descripcion: 'Geranio cortado seco argenteum' },
    { Clave: '10417802', Descripcion: 'Geranio cortado seco cinereum' },
    { Clave: '10417803', Descripcion: 'Geranio cortado seco clarkei' },
    { Clave: '10417804', Descripcion: 'Geranio cortado seco dalmaticum' },
    { Clave: '10417805', Descripcion: 'Geranio cortado seco endressii' },
    { Clave: '10417806', Descripcion: 'Geranio cortado seco eriostemon' },
    { Clave: '10417807', Descripcion: 'Geranio cortado seco farreri' },
    { Clave: '10417808', Descripcion: 'Geranio cortado seco himalayense o grandiflora' },
    { Clave: '10417809', Descripcion: 'Geranio cortado seco ibericum' },
    { Clave: '10417810', Descripcion: 'Geranio cortado seco macrorhizum o raiz grande' },
    { Clave: '10417811', Descripcion: 'Geranio cortado seco maculatum' },
    { Clave: '10417812', Descripcion: 'Geranio cortado seco nodosum' },
    { Clave: '10417813', Descripcion: 'Geranio cortado seco phaeum' },
    { Clave: '10417814', Descripcion: 'Geranio cortado seco platypetalum' },
    { Clave: '10417815', Descripcion: 'Geranio cortado seco pratense' },
    { Clave: '10417816', Descripcion: 'Geranio cortado seco procurrens' },
    { Clave: '10417817', Descripcion: 'Geranio cortado seco psilostemon' },
    { Clave: '10417818', Descripcion: 'Geranio cortado seco pylzowianum' },
    { Clave: '10417819', Descripcion: 'Geranio cortado seco renardii' },
    { Clave: '10417820', Descripcion: 'Geranio cortado seco sanguineum o sangruento' },
    { Clave: '10417821', Descripcion: 'Geranio cortado seco sylvaticum' },
    { Clave: '10417822', Descripcion: 'Geranio cortado seco traversii' },
    { Clave: '10417823', Descripcion: 'Geranio cortado seco tuberosum' },
    { Clave: '10417824', Descripcion: 'Geranio cortado seco versicolor' },
    { Clave: '10417825', Descripcion: 'Geranio cortado seco wallachianum' },
    { Clave: '10417826', Descripcion: 'Geranio cortado seco wlassovianum' },
    { Clave: '10417827', Descripcion: 'Geranio cortado seco x magnificum o llamativo' },
    { Clave: '10417900', Descripcion: 'Amaryllis cortada seca' },
    { Clave: '10417901', Descripcion: 'Amaryllis cortada seca aglaiae' },
    { Clave: '10417902', Descripcion: 'Amaryllis cortada seca amaru' },
    { Clave: '10417903', Descripcion: 'Amaryllis cortada seca angustifolium' },
    { Clave: '10417904', Descripcion: 'Amaryllis cortada seca anzaldoi' },
    { Clave: '10417905', Descripcion: 'Amaryllis cortada seca araripinum' },
    { Clave: '10417906', Descripcion: 'Amaryllis cortada seca arboricola' },
    { Clave: '10417907', Descripcion: 'Amaryllis cortada seca argentinum' },
    { Clave: '10417908', Descripcion: 'Amaryllis cortada seca aulicum' },
    { Clave: '10417909', Descripcion: 'Amaryllis cortada seca aviflorum' },
    { Clave: '10417910', Descripcion: 'Amaryllis cortada seca barreirasum' },
    { Clave: '10417911', Descripcion: 'Amaryllis cortada seca blossfeldiae' },
    { Clave: '10417912', Descripcion: 'Amaryllis cortada seca blumenavium' },
    { Clave: '10417913', Descripcion: 'Amaryllis cortada seca brasilianum' },
    { Clave: '10417914', Descripcion: 'Amaryllis cortada seca breviflorum' },
    { Clave: '10417915', Descripcion: 'Amaryllis cortada seca bukasovii' },
    { Clave: '10417916', Descripcion: 'Amaryllis cortada seca calyptratum' },
    { Clave: '10417917', Descripcion: 'Amaryllis cortada seca caupolicanense' },
    { Clave: '10417918', Descripcion: 'Amaryllis cortada seca chionedyanthum' },
    { Clave: '10417919', Descripcion: 'Amaryllis cortada seca condemaita' },
    { Clave: '10417920', Descripcion: 'Amaryllis cortada seca corriense' },
    { Clave: '10417921', Descripcion: 'Amaryllis cortada seca cuzcoense' },
    { Clave: '10417922', Descripcion: 'Amaryllis cortada seca curitibanum' },
    { Clave: '10417923', Descripcion: 'Amaryllis cortada seca cybister' },
    { Clave: '10417924', Descripcion: 'Amaryllis cortada seca divijuliani' },
    { Clave: '10417925', Descripcion: 'Amaryllis cortada seca evansiae' },
    { Clave: '10417926', Descripcion: 'Amaryllis cortada seca ferreyrae' },
    { Clave: '10417927', Descripcion: 'Amaryllis cortada seca forgetii' },
    { Clave: '10417928', Descripcion: 'Amaryllis cortada seca fosteri' },
    { Clave: '10417929', Descripcion: 'Amaryllis cortada seca fuscum' },
    { Clave: '10417930', Descripcion: 'Amaryllis cortada seca glaucescens' },
    { Clave: '10417931', Descripcion: 'Amaryllis cortada seca goianum' },
    { Clave: '10417932', Descripcion: 'Amaryllis cortada seca guarapuavicum' },
    { Clave: '10417933', Descripcion: 'Amaryllis cortada seca harrisonii' },
    { Clave: '10417934', Descripcion: 'Amaryllis cortada seca hugoi' },
    { Clave: '10417935', Descripcion: 'Amaryllis cortada seca iguazuanum' },
    { Clave: '10417936', Descripcion: 'Amaryllis cortada seca illustre' },
    { Clave: '10417937', Descripcion: 'Amaryllis cortada seca intiflorum' },
    { Clave: '10417938', Descripcion: 'Amaryllis cortada seca kromeri' },
    { Clave: '10417939', Descripcion: 'Amaryllis cortada seca lapacense' },
    { Clave: '10417940', Descripcion: 'Amaryllis cortada seca leonardii' },
    { Clave: '10417941', Descripcion: 'Amaryllis cortada seca leopoldii' },
    { Clave: '10417942', Descripcion: 'Amaryllis cortada seca macbridei' },
    { Clave: '10417943', Descripcion: 'Amaryllis cortada seca machupijchense' },
    { Clave: '10417944', Descripcion: 'Amaryllis cortada seca mandonii' },
    { Clave: '10417945', Descripcion: 'Amaryllis cortada seca minasgerais' },
    { Clave: '10417946', Descripcion: 'Amaryllis cortada seca miniatum' },
    { Clave: '10417947', Descripcion: 'Amaryllis cortada seca mollevillquense' },
    { Clave: '10417948', Descripcion: 'Amaryllis cortada seca morelianum' },
    { Clave: '10417949', Descripcion: 'Amaryllis cortada seca nelsonii' },
    { Clave: '10417950', Descripcion: 'Amaryllis cortada seca oconoquense' },
    { Clave: '10417951', Descripcion: 'Amaryllis cortada seca papilio' },
    { Clave: '10417952', Descripcion: 'Amaryllis cortada seca paquichanum' },
    { Clave: '10417953', Descripcion: 'Amaryllis cortada seca paradisiacum' },
    { Clave: '10417954', Descripcion: 'Amaryllis cortada seca pardinum' },
    { Clave: '10417955', Descripcion: 'Amaryllis cortada seca parodii' },
    { Clave: '10417956', Descripcion: 'Amaryllis cortada seca petiolatum' },
    { Clave: '10417957', Descripcion: 'Amaryllis cortada seca psittacinum' },
    { Clave: '10417958', Descripcion: 'Amaryllis cortada seca puniceum' },
    { Clave: '10417959', Descripcion: 'Amaryllis cortada seca reginae' },
    { Clave: '10417960', Descripcion: 'Amaryllis cortada seca reticulatum' },
    { Clave: '10417961', Descripcion: 'Amaryllis cortada seca rubropictum' },
    { Clave: '10417962', Descripcion: 'Amaryllis cortada seca santacatarina' },
    { Clave: '10417963', Descripcion: 'Amaryllis cortada seca solandraeflorum' },
    { Clave: '10417964', Descripcion: 'Amaryllis cortada seca starkiorum' },
    { Clave: '10417965', Descripcion: 'Amaryllis cortada seca striatum' },
    { Clave: '10417966', Descripcion: 'Amaryllis cortada seca stylosum' },
    { Clave: '10417967', Descripcion: 'Amaryllis cortada seca teyucuarense' },
    { Clave: '10417968', Descripcion: 'Amaryllis cortada seca traubii' },
    { Clave: '10417969', Descripcion: 'Amaryllis cortada seca vargasii' },
    { Clave: '10417970', Descripcion: 'Amaryllis cortada seca variegatum' },
    { Clave: '10417971', Descripcion: 'Amaryllis cortada seca vittatum' },
    { Clave: '10417972', Descripcion: 'Amaryllis cortada seca yungacense' },
    { Clave: '10418000', Descripcion: 'Rubdeckia cortada seca' },
    { Clave: '10418001', Descripcion: 'Rubdeckia cortada seca alpicola' },
    { Clave: '10418002', Descripcion: 'Rubdeckia cortada seca amplexicaulis' },
    { Clave: '10418003', Descripcion: 'Rubdeckia cortada seca auriculata' },
    { Clave: '10418004', Descripcion: 'Rubdeckia cortada seca bicolor' },
    { Clave: '10418005', Descripcion: 'Rubdeckia cortada seca californica' },
    { Clave: '10418006', Descripcion: 'Rubdeckia cortada seca fulgida' },
    { Clave: '10418007', Descripcion: 'Rubdeckia cortada seca glaucescens' },
    { Clave: '10418008', Descripcion: 'Rubdeckia cortada seca graminifolia' },
    { Clave: '10418009', Descripcion: 'Rubdeckia cortada seca grandiflora' },
    { Clave: '10418010', Descripcion: 'Rubdeckia cortada seca heliopsidis' },
    { Clave: '10418011', Descripcion: 'Rubdeckia cortada seca hirta' },
    { Clave: '10418012', Descripcion: 'Rubdeckia cortada seca klamathensis' },
    { Clave: '10418013', Descripcion: 'Rubdeckia cortada seca laciniata' },
    { Clave: '10418014', Descripcion: 'Rubdeckia cortada seca máxima' },
    { Clave: '10418015', Descripcion: 'Rubdeckia cortada seca missouriensis' },
    { Clave: '10418016', Descripcion: 'Rubdeckia cortada seca mohrii' },
    { Clave: '10418017', Descripcion: 'Rubdeckia cortada seca mollis' },
    { Clave: '10418018', Descripcion: 'Rubdeckia cortada seca montana' },
    { Clave: '10418019', Descripcion: 'Rubdeckia cortada seca nítida' },
    { Clave: '10418020', Descripcion: 'Rubdeckia cortada seca occidentalis' },
    { Clave: '10418021', Descripcion: 'Rubdeckia cortada seca pinnata' },
    { Clave: '10418022', Descripcion: 'Rubdeckia cortada seca scabrifolia' },
    { Clave: '10418023', Descripcion: 'Rubdeckia cortada seca serotina' },
    { Clave: '10418024', Descripcion: 'Rubdeckia cortada seca speciosa' },
    { Clave: '10418025', Descripcion: 'Rubdeckia cortada seca subtomentosa' },
    { Clave: '10418026', Descripcion: 'Rubdeckia cortada seca texana' },
    { Clave: '10418027', Descripcion: 'Rubdeckia cortada seca triloba' },
    { Clave: '10418100', Descripcion: 'Protea cortada seca' },
    { Clave: '10418101', Descripcion: 'Protea cortada seca bouquet' },
    { Clave: '10418102', Descripcion: 'Protea cortada seca cepillo botella' },
    { Clave: '10418103', Descripcion: 'Protea cortada seca carnaval' },
    { Clave: '10418104', Descripcion: 'Protea cortada seca follaje cordata' },
    { Clave: '10418105', Descripcion: 'Protea cortada seca grandiceps' },
    { Clave: '10418106', Descripcion: 'Protea cortada seca visón verde' },
    { Clave: '10418107', Descripcion: 'Protea cortada seca hiedra' },
    { Clave: '10418108', Descripcion: 'Protea cortada seca rey' },
    { Clave: '10418109', Descripcion: 'Protea cortada seca nana cones' },
    { Clave: '10418110', Descripcion: 'Protea cortada seca alfiletero anaranjada' },
    { Clave: '10418111', Descripcion: 'Protea cortada seca alfiletero tango' },
    { Clave: '10418112', Descripcion: 'Protea cortada seca alfiletero amarillo' },
    { Clave: '10418113', Descripcion: 'Protea cortada seca hielo rosado' },
    { Clave: '10418114', Descripcion: 'Protea cortada seca visón rosado' },
    { Clave: '10418115', Descripcion: 'Protea cortada seca reina' },
    { Clave: '10418116', Descripcion: 'Protea cortada seca repens' },
    { Clave: '10418117', Descripcion: 'Protea cortada seca cuchara de rosas' },
    { Clave: '10418118', Descripcion: 'Protea cortada seca silvia' },
    { Clave: '10418119', Descripcion: 'Protea cortada seca sugarbush' },
    { Clave: '10418120', Descripcion: 'Protea cortada seca susara' },
    { Clave: '10418121', Descripcion: 'Protea cortada seca waratha' },
    { Clave: '10418122', Descripcion: 'Protea cortada seca visón blanco' },
    { Clave: '10418200', Descripcion: 'leucadendron cortado seco' },
    { Clave: '10418201', Descripcion: 'Leucadendron cortado seco argenteum' },
    { Clave: '10418202', Descripcion: 'Leucadendron cortado seco delicia de crema' },
    { Clave: '10418203', Descripcion: 'Leucadendron cortado seco cumosum' },
    { Clave: '10418204', Descripcion: 'Leucadendron cortado seco discolor' },
    { Clave: '10418205', Descripcion: 'Leucadendron cortado seco galpini' },
    { Clave: '10418206', Descripcion: 'Leucadendron cortado seco mina de oro' },
    { Clave: '10418207', Descripcion: 'Leucadendron cortado seco oro inca' },
    { Clave: '10418208', Descripcion: 'Leucadendron cortado seco bufón' },
    { Clave: '10418209', Descripcion: 'Leucadendron cortado seco laxum' },
    { Clave: '10418210', Descripcion: 'Leucadendron cortado seco mini' },
    { Clave: '10418211', Descripcion: 'Leucadendron cortado seco oro patea' },
    { Clave: '10418212', Descripcion: 'Leucadendron cortado seco petra' },
    { Clave: '10418213', Descripcion: 'Leucadendron cortado seco plumosum' },
    { Clave: '10418214', Descripcion: 'Leucadendron cortado seco roseta' },
    { Clave: '10418215', Descripcion: 'Leucadendron cortado seco atardecer safari' },
    { Clave: '10418216', Descripcion: 'Leucadendron cortado seco atardecer safari spr' },
    { Clave: '10418217', Descripcion: 'Leucadendron cortado seco speciosa' },
    { Clave: '10418218', Descripcion: 'Leucadendron cortado seco spray' },
    { Clave: '10418219', Descripcion: 'Leucadendron cortado seco maravilla wilson' },
    { Clave: '10418220', Descripcion: 'Leucadendron cortado seco yarden' },
    { Clave: '10418300', Descripcion: 'leucospermum cortado seco' },
    { Clave: '10418301', Descripcion: 'Leucospermum cortado seco album' },
    { Clave: '10418302', Descripcion: 'Leucospermum cortado seco attenuatum' },
    { Clave: '10418303', Descripcion: 'Leucospermum cortado seco calligerum' },
    { Clave: '10418304', Descripcion: 'Leucospermum cortado seco conocarpodendron' },
    { Clave: '10418305', Descripcion: 'Leucospermum cortado seco cordatum' },
    { Clave: '10418306', Descripcion: 'Leucospermum cortado seco cuneiforme' },
    { Clave: '10418307', Descripcion: 'Leucospermum cortado seco formosum' },
    { Clave: '10418308', Descripcion: 'Leucospermum cortado seco glabrum' },
    { Clave: '10418309', Descripcion: 'Leucospermum cortado seco grandiflorum' },
    { Clave: '10418310', Descripcion: 'Leucospermum cortado seco harmatum' },
    { Clave: '10418311', Descripcion: 'Leucospermum cortado seco heterophyllum' },
    { Clave: '10418312', Descripcion: 'Leucospermum cortado seco innovans' },
    { Clave: '10418313', Descripcion: 'Leucospermum cortado seco muirii' },
    { Clave: '10418314', Descripcion: 'Leucospermum cortado seco oleifolium' },
    { Clave: '10418315', Descripcion: 'Leucospermum cortado seco patersonii' },
    { Clave: '10418316', Descripcion: 'Leucospermum cortado seco pluridens' },
    { Clave: '10418317', Descripcion: 'Leucospermum cortado seco praemorsum' },
    { Clave: '10418318', Descripcion: 'Leucospermum cortado seco prostratum' },
    { Clave: '10418319', Descripcion: 'Leucospermum cortado seco rodolentum' },
    { Clave: '10418320', Descripcion: 'Leucospermum cortado seco saxatile' },
    { Clave: '10418321', Descripcion: 'Leucospermum cortado seco secundifolium' },
    { Clave: '10418322', Descripcion: 'Leucospermum cortado seco tomentosus' },
    { Clave: '10418323', Descripcion: 'Leucospermum cortado seco truncatulum' },
    { Clave: '10418324', Descripcion: 'Leucospermum cortado seco utriculosum' },
    { Clave: '10418325', Descripcion: 'Leucospermum cortado seco winterii' },
    { Clave: '10418326', Descripcion: 'Leucospermum cortado seco arenarium' },
    { Clave: '10418327', Descripcion: 'Leucospermum cortado seco bolusii' },
    { Clave: '10418328', Descripcion: 'Leucospermum cortado seco catherinae' },
    { Clave: '10418329', Descripcion: 'Leucospermum cortado seco conocarpum' },
    { Clave: '10418330', Descripcion: 'Leucospermum cortado seco cordifolium' },
    { Clave: '10418331', Descripcion: 'Leucospermum cortado seco erubescens' },
    { Clave: '10418332', Descripcion: 'Leucospermum cortado seco gerrardii' },
    { Clave: '10418333', Descripcion: 'Leucospermum cortado seco gracile' },
    { Clave: '10418334', Descripcion: 'Leucospermum cortado seco gueinzii' },
    { Clave: '10418335', Descripcion: 'Leucospermum cortado seco harpagonatum' },
    { Clave: '10418336', Descripcion: 'Leucospermum cortado seco hypophyllocarpodendron' },
    { Clave: '10418337', Descripcion: 'Leucospermum cortado seco lineare' },
    { Clave: '10418338', Descripcion: 'Leucospermum cortado seco mundii' },
    { Clave: '10418339', Descripcion: 'Leucospermum cortado seco parile' },
    { Clave: '10418340', Descripcion: 'Leucospermum cortado seco pendunculatum' },
    { Clave: '10418341', Descripcion: 'Leucospermum cortado seco praecox' },
    { Clave: '10418342', Descripcion: 'Leucospermum cortado seco profugum' },
    { Clave: '10418343', Descripcion: 'Leucospermum cortado seco reflexum' },
    { Clave: '10418344', Descripcion: 'Leucospermum cortado seco royenifolium' },
    { Clave: '10418345', Descripcion: 'Leucospermum cortado seco saxosum' },
    { Clave: '10418346', Descripcion: 'Leucospermum cortado seco spathulatum' },
    { Clave: '10418347', Descripcion: 'Leucospermum cortado seco tottum' },
    { Clave: '10418348', Descripcion: 'Leucospermum cortado seco truncatum' },
    { Clave: '10418349', Descripcion: 'Leucospermum cortado seco vestitum' },
    { Clave: '10418350', Descripcion: 'Leucospermum cortado seco wittebergense' },
    { Clave: '10421500', Descripcion: 'Agapanto cortado seco' },
    { Clave: '10421501', Descripcion: 'Agapanto cortado seco azul' },
    { Clave: '10421502', Descripcion: 'Agapanto cortado seco blanco' },
    { Clave: '10421600', Descripcion: 'Alchimilla cortada seca' },
    { Clave: '10421601', Descripcion: 'Alchimilla cortada seca capa de dama' },
    { Clave: '10421602', Descripcion: 'Alchimilla cortada seca robustica' },
    { Clave: '10421700', Descripcion: 'Astilbe cortado seco' },
    { Clave: '10421701', Descripcion: 'Astilbe cortado seco rosado fuerte' },
    { Clave: '10421702', Descripcion: 'Astilbe cortado seco rosado claro' },
    { Clave: '10421703', Descripcion: 'Astilbe cortado seco rojo' },
    { Clave: '10421704', Descripcion: 'Astilbe cortado seco blanco' },
    { Clave: '10421800', Descripcion: 'angélica cortada seca' },
    { Clave: '10421801', Descripcion: 'Angélica cortada seca gigas' },
    { Clave: '10421802', Descripcion: 'Angélica cortada seca sylvestris' },
    { Clave: '10421900', Descripcion: 'ajenjo cortado seco' },
    { Clave: '10421901', Descripcion: 'Ajenjo cortado seco verde' },
    { Clave: '10421902', Descripcion: 'Ajenjo cortado seco rey dorado' },
    { Clave: '10422000', Descripcion: 'Flor de alcachofa cortada seca' },
    { Clave: '10422001', Descripcion: 'Flor de alcachofa cortada seca chocolate' },
    { Clave: '10422002', Descripcion: 'Flor de alcachofa cortada seca verde' },
    { Clave: '10422003', Descripcion: 'Flor de alcachofa cortada seca púrpura o floreciente' },
    { Clave: '10422100', Descripcion: 'Astrantia cortada seca' },
    { Clave: '10422101', Descripcion: 'Astrantia cortada seca rosada' },
    { Clave: '10422102', Descripcion: 'Astrantia cortada seca blanca' },
    { Clave: '10422200', Descripcion: 'Flor de banano cortada seca' },
    { Clave: '10422201', Descripcion: 'Flor de banano cortada seca anaranjada' },
    { Clave: '10422202', Descripcion: 'Flor de banano cortada seca antorcha anaranjada' },
    { Clave: '10422203', Descripcion: 'Flor de banano cortada seca púrpura' },
    { Clave: '10422300', Descripcion: 'Baptisia cortada seca' },
    { Clave: '10422301', Descripcion: 'Baptisia cortada seca australis' },
    { Clave: '10422302', Descripcion: 'Baptisia cortada seca sphaerocarpa' },
    { Clave: '10422400', Descripcion: 'Boronia cortada seca' },
    { Clave: '10422401', Descripcion: 'Boronia cortada seca rosada' },
    { Clave: '10422402', Descripcion: 'Boronia cortada seca amarilla' },
    { Clave: '10422500', Descripcion: 'Bromelia cortada seca' },
    { Clave: '10422501', Descripcion: 'Bromelia cortada seca amarilla reg' },
    { Clave: '10422502', Descripcion: 'Bromelia cortada seca roja reg' },
    { Clave: '10422600', Descripcion: 'Brunia cortada seca' },
    { Clave: '10422601', Descripcion: 'Brunia albiflora cortada seca' },
    { Clave: '10422602', Descripcion: 'Brunia albiflora cortada seca verde' },
    { Clave: '10422603', Descripcion: 'Brunia cortada seca rocío de plata' },
    { Clave: '10422700', Descripcion: 'Catalea cortada seca' },
    { Clave: '10422701', Descripcion: 'Calatea cortada seca cigarro' },
    { Clave: '10422702', Descripcion: 'Calatea cortada seca hielo verde' },
    { Clave: '10422703', Descripcion: 'Calatea cortada seca serpiente cascabel' },
    { Clave: '10422800', Descripcion: 'Calcynia cortada seca' },
    { Clave: '10422801', Descripcion: 'Calcynia cortada seca rosada' },
    { Clave: '10422802', Descripcion: 'Calcynia cortada seca princesa' },
    { Clave: '10422803', Descripcion: 'Calcynia cortada seca blanca' },
    { Clave: '10422900', Descripcion: 'Caléndula cortada seca' },
    { Clave: '10422901', Descripcion: 'Caléndula cortada seca anaranjada' },
    { Clave: '10422902', Descripcion: 'Caléndula cortada seca amarilla' },
    { Clave: '10423000', Descripcion: 'Campanilla cortada seca' },
    { Clave: '10423001', Descripcion: 'Campanilla cortada seca azul' },
    { Clave: '10423002', Descripcion: 'Campanilla cortada seca rosada' },
    { Clave: '10423003', Descripcion: 'Campanilla cortada seca blanca' },
    { Clave: '10423100', Descripcion: 'Cestrum cortado seco' },
    { Clave: '10423101', Descripcion: 'Cestrum cortado seco rojo' },
    { Clave: '10423102', Descripcion: 'Cestrum cortado seco rojo zohar' },
    { Clave: '10423103', Descripcion: 'Cestrum cortado seco amarillo' },
    { Clave: '10423200', Descripcion: 'Chasmante cortada seca' },
    { Clave: '10423201', Descripcion: 'Chasmante floribunda cortada seca amarilla' },
    { Clave: '10423202', Descripcion: 'Chasmante floribunda cortada seca anaranjada' },
    { Clave: '10423300', Descripcion: 'Costus cortada seca' },
    { Clave: '10423301', Descripcion: 'Costus barbada cortada seca' },
    { Clave: '10423302', Descripcion: 'Costus cortada seca cabeza de indio' },
    { Clave: '10423400', Descripcion: 'Vara de San José cortada seca' },
    { Clave: '10423401', Descripcion: 'Vara de san josé lucifer cortada seca' },
    { Clave: '10423402', Descripcion: 'Vara de san josé cortada seca vainas' },
    { Clave: '10423403', Descripcion: 'Vara de san josé cortada seca amarilla' },
    { Clave: '10423500', Descripcion: 'Lirio del Fuego cortado seco' },
    { Clave: '10423501', Descripcion: 'Lirio del fuego cortado seco anaranjado brillante' },
    { Clave: '10423502', Descripcion: 'Lirio del fuego cortado seco crema' },
    { Clave: '10423503', Descripcion: 'Lirio del fuego cortado seco anaranjado' },
    { Clave: '10423504', Descripcion: 'Lirio del fuego cortado seco amarillo' },
    { Clave: '10423600', Descripcion: 'Flor del muelle cortada seca' },
    { Clave: '10423601', Descripcion: 'Flor del muelle cortada seca verde' },
    { Clave: '10423602', Descripcion: 'Flor del muelle cortada seca roja' },
    { Clave: '10423700', Descripcion: 'Planta erizo cortada seca' },
    { Clave: '10423701', Descripcion: 'Planta erizo cortada seca tintura negra' },
    { Clave: '10423702', Descripcion: 'Planta erizo cortada seca tintura anaranjada' },
    { Clave: '10423800', Descripcion: 'Manzanilla cortada seca' },
    { Clave: '10423801', Descripcion: 'Manzanilla cortada seca sencilla vegmo' },
    { Clave: '10423802', Descripcion: 'Manzanilla cortada seca doble blanca' },
    { Clave: '10423803', Descripcion: 'Manzanilla cortada seca bola de nieve' },
    { Clave: '10423804', Descripcion: 'Manzanilla cortada seca blanca' },
    { Clave: '10423900', Descripcion: 'Nomeolvides cortada seca' },
    { Clave: '10423901', Descripcion: 'Nomeolvides cortada seca azul' },
    { Clave: '10423902', Descripcion: 'Nomeolvides cortada seca blanca' },
    { Clave: '10424000', Descripcion: 'Gallardia cortada seca' },
    { Clave: '10424001', Descripcion: 'Gallardia cortada seca anaranjada' },
    { Clave: '10424002', Descripcion: 'Gallardia cortada seca amarilla' },
    { Clave: '10424100', Descripcion: 'Genciana cortada seca' },
    { Clave: '10424101', Descripcion: 'Genciana cortada seca azul' },
    { Clave: '10424102', Descripcion: 'Genciana cortada seca blanca' },
    { Clave: '10424200', Descripcion: 'Gladiolo cortado seco' },
    { Clave: '10424201', Descripcion: 'Gladiolo cortado seco glamini rosado' },
    { Clave: '10424202', Descripcion: 'Gladiolo cortado seco glamini rojo' },
    { Clave: '10424300', Descripcion: 'Gloriosa cortada seca' },
    { Clave: '10424301', Descripcion: 'Gloriosa cortada seca anaranjada' },
    { Clave: '10424302', Descripcion: 'Gloriosa cortada seca roja' },
    { Clave: '10424303', Descripcion: 'Gloriosa cortada seca amarilla' },
    { Clave: '10424400', Descripcion: 'Violeta silvestre cortada seca' },
    { Clave: '10424401', Descripcion: 'Violeta silvestre cortada seca anaranjada' },
    { Clave: '10424402', Descripcion: 'Violeta silvestre cortada seca roja' },
    { Clave: '10424500', Descripcion: 'Eléboro cortado seco' },
    { Clave: '10424501', Descripcion: 'Eléboro cortado seco verde' },
    { Clave: '10424502', Descripcion: 'Eléboro cortado seco rayo de luna' },
    { Clave: '10424600', Descripcion: 'Ixia cortada seca' },
    { Clave: '10424601', Descripcion: 'Ixia cortada seca rosada' },
    { Clave: '10424602', Descripcion: 'Ixia cortada seca blanca' },
    { Clave: '10424700', Descripcion: 'Liatrises cortadas secas' },
    { Clave: '10424701', Descripcion: 'Liatris cortada seca púrpura' },
    { Clave: '10424702', Descripcion: 'Liatris cortada seca spray' },
    { Clave: '10424703', Descripcion: 'Liatris cortada seca blanca' },
    { Clave: '10424800', Descripcion: 'Lysimachia cortada seca' },
    { Clave: '10424801', Descripcion: 'Lysimachia punctata cortada seca' },
    { Clave: '10424802', Descripcion: 'Lysimachia vulgaris cortada seca' },
    { Clave: '10424803', Descripcion: 'Lysimachia blanca cortada seca' },
    { Clave: '10424804', Descripcion: 'Lysimachia amarilla cortada seca' },
    { Clave: '10424900', Descripcion: 'Maraca cortada seca' },
    { Clave: '10424901', Descripcion: 'Maraca cortada seca marrón' },
    { Clave: '10424902', Descripcion: 'Maraca cortada seca shampoo ginger' },
    { Clave: '10425000', Descripcion: 'Caléndula cortada seca' },
    { Clave: '10425001', Descripcion: 'Caléndula cortada seca francesa' },
    { Clave: '10425002', Descripcion: 'Caléndula cortada seca verde' },
    { Clave: '10425003', Descripcion: 'Caléndula (marigold) cortada seca anaranjada' },
    { Clave: '10425004', Descripcion: 'Caléndula (marigold) cortada seca amarilla' },
    { Clave: '10425100', Descripcion: 'Mimosa cortada seca' },
    { Clave: '10425101', Descripcion: 'Mimosa cortada seca azul o púrpura' },
    { Clave: '10425102', Descripcion: 'Mimosa dedo cortada seca' },
    { Clave: '10425103', Descripcion: 'Mimosa cortada seca floribunda o italia' },
    { Clave: '10425104', Descripcion: 'Mimosa cortada seca mirandola' },
    { Clave: '10425200', Descripcion: 'Nerina cortada seca' },
    { Clave: '10425201', Descripcion: 'Nerina cortada seca rosada' },
    { Clave: '10425202', Descripcion: 'Nerina samiensis blanca' },
    { Clave: '10425300', Descripcion: 'Flor de nogal cortada seca' },
    { Clave: '10425301', Descripcion: 'Flor colgante de nogal blanco cortada seca' },
    { Clave: '10425302', Descripcion: 'Flor sin hojas de nogal blanco cortada seca' },
    { Clave: '10425303', Descripcion: 'Flor erecta de nogal blanco brasileño cortada seca' },
    { Clave: '10425400', Descripcion: 'Phlox cortada seca' },
    { Clave: '10425401', Descripcion: 'Phlox cortada seca rosado oscur' },
    { Clave: '10425402', Descripcion: 'Phlox cortada seca lavanda' },
    { Clave: '10425403', Descripcion: 'Phlox cortada seca rosado claro' },
    { Clave: '10425404', Descripcion: 'Phlox cortada seca blanca' },
    { Clave: '10425500', Descripcion: 'physostegia cortada seca' },
    { Clave: '10425501', Descripcion: 'Physostegia cortada seca rosada' },
    { Clave: '10425502', Descripcion: 'Physostegia cortada seca vainas' },
    { Clave: '10425503', Descripcion: 'Physostegia cortada seca blanca' },
    { Clave: '10425600', Descripcion: 'Saponaria cortada seca' },
    { Clave: '10425601', Descripcion: 'Saponaria cortada seca rosada' },
    { Clave: '10425602', Descripcion: 'Saponaria cortada seca blanca' },
    { Clave: '10425700', Descripcion: 'Sarracenias cortadas secas' },
    { Clave: '10425701', Descripcion: 'Sarracenia cortada seca flava rugelii' },
    { Clave: '10425800', Descripcion: 'Sicilia cortada seca' },
    { Clave: '10425801', Descripcion: 'Sicilia cortada seca campanulata azul' },
    { Clave: '10425802', Descripcion: 'Sicilia cortada seca campanulata rosada' },
    { Clave: '10425803', Descripcion: 'Sicilia cortada seca campanulata blanca' },
    { Clave: '10425804', Descripcion: 'Sicilia cortada seca peruana' },
    { Clave: '10425900', Descripcion: 'Sedum cortada seca' },
    { Clave: '10425901', Descripcion: 'Sedum cortada seca marrón' },
    { Clave: '10425902', Descripcion: 'Sedum cortada seca verde' },
    { Clave: '10425903', Descripcion: 'Sedum cortada seca rosada' },
    { Clave: '10425904', Descripcion: 'Sedum cortada seca roja' },
    { Clave: '10426000', Descripcion: 'Especies individuales o variedades de flores cortadas secas' },
    { Clave: '10426001', Descripcion: 'Agrsotema cortada seca' },
    { Clave: '10426002', Descripcion: 'Kniphophia o assegai poker cortada seca' },
    { Clave: '10426003', Descripcion: 'Bellis perennis cortada seca' },
    { Clave: '10426004', Descripcion: 'Campana de irlanda o molucella cortada seca' },
    { Clave: '10426005', Descripcion: 'Ave del paraíso cortada seca' },
    { Clave: '10426006', Descripcion: 'Novia sonrojada cortada seca' },
    { Clave: '10426007', Descripcion: 'Buddleia o arbusto mariposa cortada seca' },
    { Clave: '10426008', Descripcion: 'Bupleurum griffithii cortada seca' },
    { Clave: '10426009', Descripcion: 'California ginesta cortada seca' },
    { Clave: '10426010', Descripcion: 'Callicarpa púrpura cortada seca' },
    { Clave: '10426011', Descripcion: 'Campanilla blanca cortada seca' },
    { Clave: '10426012', Descripcion: 'Penacho dulce cortada seca' },
    { Clave: '10426013', Descripcion: 'Cariopteris cortada seca' },
    { Clave: '10426014', Descripcion: 'Centaurea o marco polo cortada seca' },
    { Clave: '10426015', Descripcion: 'Linterna china cortada seca' },
    { Clave: '10426016', Descripcion: 'Clematis recta purpurea cortada seca' },
    { Clave: '10426017', Descripcion: 'Cleome spinosa cortada seca' },
    { Clave: '10426018', Descripcion: 'Coreopsis cortada seca' },
    { Clave: '10426019', Descripcion: 'Farolitos de la virgen azul cortada seca' },
    { Clave: '10426020', Descripcion: 'Cosmos chocolate cortada seca' },
    { Clave: '10426021', Descripcion: 'Cotinus coggygria cortada seca' },
    { Clave: '10426022', Descripcion: 'Craspedia o billy bolas cortada seca' },
    { Clave: '10426023', Descripcion: 'Deutzia alta cortada seca' },
    { Clave: '10426024', Descripcion: 'Diosma cortada seca' },
    { Clave: '10426025', Descripcion: 'Echeveria suculenta chupahuevos cortada seca' },
    { Clave: '10426026', Descripcion: 'Echinacea purpurea cortada seca' },
    { Clave: '10426027', Descripcion: 'Edelweiss cortada seca' },
    { Clave: '10426028', Descripcion: 'Erythronium pagoda cortada seca' },
    { Clave: '10426029', Descripcion: 'Flor de eucalipto cortada seca' },
    { Clave: '10426030', Descripcion: 'Eucaris o lirio del amazonas cortado seco' },
    { Clave: '10426031', Descripcion: 'Eucomis o lirio de piña cortado seco' },
    { Clave: '10426032', Descripcion: 'Eupatorium maculatum cortado seco' },
    { Clave: '10426033', Descripcion: 'Filipendula cortada seca' },
    { Clave: '10426034', Descripcion: 'Digitalis cortado seco' },
    { Clave: '10426035', Descripcion: 'Gilia globo cortada seca' },
    { Clave: '10426036', Descripcion: 'Globularia de ojo azul cortada seca' },
    { Clave: '10426037', Descripcion: 'Washington hawthorne cortada seca' },
    { Clave: '10426038', Descripcion: 'Helenio cortada seca' },
    { Clave: '10426039', Descripcion: 'Helianto cortada seca' },
    { Clave: '10426040', Descripcion: 'Herperis matronalis cortada seca' },
    { Clave: '10426041', Descripcion: 'Houttuynia cordata chameleon cortada seca' },
    { Clave: '10426042', Descripcion: 'Jacinto con bulbo cortada seca' },
    { Clave: '10426043', Descripcion: 'Maiz indio cortado seco' },
    { Clave: '10426044', Descripcion: 'Jack in the pulpit cortado seco' },
    { Clave: '10426045', Descripcion: 'Arbol del cielo japonés cortado seco' },
    { Clave: '10426046', Descripcion: 'Enredadera de jazmin florecida cortada seca' },
    { Clave: '10426047', Descripcion: 'Jatropha curcas o pólvora cortada seca' },
    { Clave: '10426048', Descripcion: 'Knautia cortada seca' },
    { Clave: '10426049', Descripcion: 'Kochia sedifolia cortada seca' },
    { Clave: '10426050', Descripcion: 'Lachenalia romaud cortada seca' },
    { Clave: '10426051', Descripcion: 'Flor de oreja de oveja cortada seca' },
    { Clave: '10426052', Descripcion: 'Lavanda cortada seca' },
    { Clave: '10426053', Descripcion: 'Leucocoryne speciosa cortada seca' },
    { Clave: '10426054', Descripcion: 'Lythrum cortada seca' },
    { Clave: '10426055', Descripcion: 'Malva zebrina cortada seca' },
    { Clave: '10426056', Descripcion: 'Margarita blanca cortada seca' },
    { Clave: '10426057', Descripcion: 'Montbretia amarilla cortada seca' },
    { Clave: '10426058', Descripcion: 'Nebelia cortada seca' },
    { Clave: '10426059', Descripcion: 'Nicotiana cortada seca' },
    { Clave: '10426060', Descripcion: 'Nigella damascena o amor en la niebla cortada seca' },
    { Clave: '10426061', Descripcion: 'Nigella cortada seca' },
    { Clave: '10426062', Descripcion: 'Orquídea de monja cortada seca' },
    { Clave: '10426063', Descripcion: 'Orquídea verde paphiopedilum cortada seca' },
    { Clave: '10426064', Descripcion: 'Paranomus cortada seca' },
    { Clave: '10426065', Descripcion: 'Penstemon husker rojo cortado seco' },
    { Clave: '10426066', Descripcion: 'Manzana peruana cortada seca' },
    { Clave: '10426067', Descripcion: 'Phlomis sarnia cortada seca' },
    { Clave: '10426068', Descripcion: 'Didiscus o flor de encaje rosada cortada seca' },
    { Clave: '10426069', Descripcion: 'Platycodon o flor globo cortada seca' },
    { Clave: '10426070', Descripcion: 'Retzia capensis cortada seca' },
    { Clave: '10426071', Descripcion: 'Ricino común cortado seco' },
    { Clave: '10426072', Descripcion: 'Nieve en el monte cortada seca' },
    { Clave: '10426073', Descripcion: 'Solidago teñida cortada seca' },
    { Clave: '10426074', Descripcion: 'Scilla blanca cortada seca' },
    { Clave: '10426075', Descripcion: 'Stachys byzantina cortada seca' },
    { Clave: '10426076', Descripcion: 'Flor de paja cortada seca' },
    { Clave: '10426077', Descripcion: 'Oscularia suculenta cortada seca' },
    { Clave: '10426078', Descripcion: 'Flor de tillasandia cortada seca' },
    { Clave: '10426079', Descripcion: 'Triteleia cortada seca' },
    { Clave: '10426080', Descripcion: 'Tritoma naranja o chuzo caliente cortada seca roja' },
    { Clave: '10426081', Descripcion: 'Veronicastrum virginiana cortada seca' },
    { Clave: '10426082', Descripcion: 'Bromelia vriesea splendens cortada seca' },
    { Clave: '10426084', Descripcion: 'Hipericim o hierba de san juan cortada seca' },
    { Clave: '10426085', Descripcion: 'Spirea cortada seca' },
    { Clave: '10426086', Descripcion: 'Yerba de san bonifacio cortada seca' },
    { Clave: '10426100', Descripcion: 'Sello de salomón (polygonato) cortada seca' },
    { Clave: '10426101', Descripcion: 'Sello de salomón (polygonato) falso cortada seca' },
    { Clave: '10426102', Descripcion: 'Sello de salomón (polygonato) variegado cortada seca' },
    { Clave: '10426200', Descripcion: 'Tanaceto cortado seco' },
    { Clave: '10426201', Descripcion: 'Tanaceto cortado seco amazonas' },
    { Clave: '10426202', Descripcion: 'Tanaceto cortado seco victoria doble blanco' },
    { Clave: '10426203', Descripcion: 'Tanaceto cortado seco victoria sencillo blanco' },
    { Clave: '10426204', Descripcion: 'Tanaceto cortado seco vegmo amarillo' },
    { Clave: '10426300', Descripcion: 'Traquelio cortado seco' },
    { Clave: '10426301', Descripcion: 'Trachelium jade cortada seca' },
    { Clave: '10426302', Descripcion: 'Trachelium púrpura cortada seca' },
    { Clave: '10426303', Descripcion: 'Trachelium blanca cortada seca' },
    { Clave: '10426400', Descripcion: 'Tuberosas cortadas secas' },
    { Clave: '10426401', Descripcion: 'Tuberosa cortada seca doble' },
    { Clave: '10426402', Descripcion: 'Tuberosa cortada seca sencilla' },
    { Clave: '10426500', Descripcion: 'Tweedias cortadas secas' },
    { Clave: '10426501', Descripcion: 'Tweedia azul cortada seca' },
    { Clave: '10426502', Descripcion: 'Tweedia blanca cortada seca' },
    { Clave: '10426600', Descripcion: 'Verónicas cortadas secas' },
    { Clave: '10426601', Descripcion: 'Verónica cortada seca rosada' },
    { Clave: '10426602', Descripcion: 'Verónica cortada seca púrpura' },
    { Clave: '10426603', Descripcion: 'Verónica cortada seca blanca' },
    { Clave: '10426700', Descripcion: 'Watsonias cortadas secas' },
    { Clave: '10426701', Descripcion: 'Watsonia cortada seca anaranjada' },
    { Clave: '10426702', Descripcion: 'Watsonia cortada seca rosada' },
    { Clave: '10426703', Descripcion: 'Watsonia cortada seca roja' },
    { Clave: '10426704', Descripcion: 'Watsonia cortada seca blanca' },
    { Clave: '10431500', Descripcion: 'Crisantemo cortado seco pompón' },
    { Clave: '10431501', Descripcion: 'Crisantemo cortado seco pompón delirock' },
    { Clave: '10431502', Descripcion: 'Crisantemo cortado seco pompón discovery' },
    { Clave: '10431503', Descripcion: 'Crisantemo cortado seco pompón focus' },
    { Clave: '10431504', Descripcion: 'Crisantemo cortado seco pompón jeanne' },
    { Clave: '10431505', Descripcion: 'Crisantemo cortado seco pompón lady' },
    { Clave: '10431506', Descripcion: 'Crisantemo cortado seco pompón leidi' },
    { Clave: '10431507', Descripcion: 'Crisantemo cortado seco pompón lexy' },
    { Clave: '10431508', Descripcion: 'Crisantemo cortado seco pompón ole' },
    { Clave: '10431509', Descripcion: 'Crisantemo cortado seco pompón revise' },
    { Clave: '10431510', Descripcion: 'Crisantemo cortado seco pompón estadista' },
    { Clave: '10431511', Descripcion: 'Crisantemo cortado seco pompón dulce' },
    { Clave: '10431512', Descripcion: 'Crisantemo cortado seco pompón yoko ono' },
    { Clave: '10431513', Descripcion: 'Crisantemo cortado seco pompón zip' },
    { Clave: '10431600', Descripcion: 'Crisantemo cortado seco pompón margarita' },
    { Clave: '10431601', Descripcion: 'Crisantemo cortado seco pompón artista rosado' },
    { Clave: '10431602', Descripcion: 'Crisantemo cortado seco pompón artista amarillo' },
    { Clave: '10431603', Descripcion: 'Crisantemo cortado seco pompón atlantis rosado' },
    { Clave: '10431604', Descripcion: 'Crisantemo cortado seco pompón atlantis blanco' },
    { Clave: '10431605', Descripcion: 'Crisantemo cortado seco pompón atlantis amarillo' },
    { Clave: '10431606', Descripcion: 'Crisantemo cortado seco pompón bennie jolink' },
    { Clave: '10431607', Descripcion: 'Crisantemo cortado seco pompón bennie jolink amarillo' },
    { Clave: '10431608', Descripcion: 'Crisantemo cortado seco pompón managua bronce' },
    { Clave: '10431609', Descripcion: 'Crisantemo cortado seco pompón clave' },
    { Clave: '10431610', Descripcion: 'Crisantemo cortado seco pompón ficción coral' },
    { Clave: '10431611', Descripcion: 'Crisantemo cortado seco pompón cumbia' },
    { Clave: '10431612', Descripcion: 'Crisantemo cortado seco pompón cantata oscura' },
    { Clave: '10431613', Descripcion: 'Crisantemo cortado seco pompón lineker oscuro' },
    { Clave: '10431614', Descripcion: 'Crisantemo cortado seco pompón dipper' },
    { Clave: '10431615', Descripcion: 'Crisantemo cortado seco pompón rosado elite' },
    { Clave: '10431616', Descripcion: 'Crisantemo cortado seco pompón blanco elite' },
    { Clave: '10431617', Descripcion: 'Crisantemo cortado seco pompón amarillo elite' },
    { Clave: '10431618', Descripcion: 'Crisantemo cortado seco pompón factor' },
    { Clave: '10431619', Descripcion: 'Crisantemo cortado seco pompón ficción' },
    { Clave: '10431620', Descripcion: 'Crisantemo cortado seco pompón fuerza' },
    { Clave: '10431621', Descripcion: 'Crisantemo cortado seco pompón reagan mejorado' },
    { Clave: '10431622', Descripcion: 'Crisantemo cortado seco pompón life' },
    { Clave: '10431623', Descripcion: 'Crisantemo cortado seco pompón managua anaranjado' },
    { Clave: '10431624', Descripcion: 'Crisantemo cortado seco pompón novedsd bronce cocarde' },
    { Clave: '10431625', Descripcion: 'Crisantemo cortado seco pompón reagan anaranjado' },
    { Clave: '10431626', Descripcion: 'Crisantemo cortado seco pompón orinoco' },
    { Clave: '10431627', Descripcion: 'Crisantemo cortado seco pompón petra' },
    { Clave: '10431628', Descripcion: 'Crisantemo cortado seco pompón balsas rosado' },
    { Clave: '10431629', Descripcion: 'Crisantemo cortado seco pompón mona lisa rosado' },
    { Clave: '10431630', Descripcion: 'Crisantemo cortado seco pompón reagan rosado' },
    { Clave: '10431631', Descripcion: 'Crisantemo cortado seco pompón marfil reagan' },
    { Clave: '10431632', Descripcion: 'Crisantemo cortado seco pompón reagan rosado' },
    { Clave: '10431633', Descripcion: 'Crisantemo cortado seco pompón rebasco' },
    { Clave: '10431634', Descripcion: 'Crisantemo cortado seco pompón redock' },
    { Clave: '10431635', Descripcion: 'Crisantemo cortado seco pompón salmón lineker' },
    { Clave: '10431636', Descripcion: 'Crisantemo cortado seco pompón sheba' },
    { Clave: '10431637', Descripcion: 'Crisantemo cortado seco pompón sirius' },
    { Clave: '10431638', Descripcion: 'Crisantemo cortado seco pompón reagan espléndido' },
    { Clave: '10431639', Descripcion: 'Crisantemo cortado seco pompón reagan soleado' },
    { Clave: '10431640', Descripcion: 'Crisantemo cortado seco pompón tina' },
    { Clave: '10431641', Descripcion: 'Crisantemo cortado seco pompón vero' },
    { Clave: '10431642', Descripcion: 'Crisantemo cortado seco pompón volare' },
    { Clave: '10431643', Descripcion: 'Crisantemo cortado seco pompón vida blanca' },
    { Clave: '10431644', Descripcion: 'Crisantemo cortado seco pompón regan blanco' },
    { Clave: '10431645', Descripcion: 'Crisantemo cortado seco pompón rino blanco' },
    { Clave: '10431646', Descripcion: 'Crisantemo cortado seco pompón vero amarillo' },
    { Clave: '10431647', Descripcion: 'Crisantemo cortado seco pompón zenith' },
    { Clave: '10431700', Descripcion: 'Crisantemo cortado seco espollado cremon' },
    { Clave: '10431701', Descripcion: 'Crisantemo cortado seco espollado cremon annecy oscura' },
    { Clave: '10431702', Descripcion: 'Crisantemo cortado seco espollado cremon' },
    { Clave: '10431703', Descripcion: 'Crisantemo cortado seco espollado cremon atlantis rosado' },
    { Clave: '10431704', Descripcion: 'Crisantemo cortado seco espollado cremon eleonora bronce' },
    { Clave: '10431705', Descripcion: 'Crisantemo cortado seco espollado cremon eleonora lila' },
    { Clave: '10431706', Descripcion: 'Crisantemo cortado seco espollado cremon eleonora rosado' },
    { Clave: '10431707', Descripcion: 'Crisantemo cortado seco espollado cremon eleonora nieve' },
    { Clave: '10431708', Descripcion: 'Crisantemo cortado seco espollado cremon eleonora amarillo' },
    { Clave: '10431709', Descripcion: 'Crisantemo cortado seco espollado cremon idea' },
    { Clave: '10431710', Descripcion: 'Crisantemo cortado seco espollado cremon ivanna púrpura' },
    { Clave: '10431711', Descripcion: 'Crisantemo cortado seco espollado cremon minka rosado' },
    { Clave: '10431712', Descripcion: 'Crisantemo cortado seco espollado cremon listo' },
    { Clave: '10431713', Descripcion: 'Crisantemo cortado seco espollado cremon sinatra' },
    { Clave: '10431714', Descripcion: 'Crisantemo cortado seco rover rojo' },
    { Clave: '10431800', Descripcion: 'Crisantemo cortado seco espollado football' },
    { Clave: '10431801', Descripcion: 'Crisantemo cortado seco espollado blaze' },
    { Clave: '10431802', Descripcion: 'Crisantemo cortado seco espollado beso de football' },
    { Clave: '10431803', Descripcion: 'Crisantemo cortado seco espollado football lavanda / rosado' },
    { Clave: '10431804', Descripcion: 'Crisantemo cortado seco espollado football resouci' },
    { Clave: '10431805', Descripcion: 'Crisantemo cortado seco espollado footbal blanco' },
    { Clave: '10431806', Descripcion: 'Crisantemo cortado seco espollado football amarillo' },
    { Clave: '10431807', Descripcion: 'Crisantemo cortado seco espollado promenade' },
    { Clave: '10431808', Descripcion: 'Crisantemo cortado seco espollado rebonnet' },
    { Clave: '10431809', Descripcion: 'Crisantemo cortado seco espollado reflex' },
    { Clave: '10431810', Descripcion: 'Crisantemo cortado seco espollado residence' },
    { Clave: '10431811', Descripcion: 'Crisantemo cortado seco espollado resomee perla' },
    { Clave: '10431812', Descripcion: 'Crisantemo cortado seco espollado resouci' },
    { Clave: '10431900', Descripcion: 'Crisantemo spider cortado seco' },
    { Clave: '10431901', Descripcion: 'Crisantemo spider cortado seco anastasia bronce' },
    { Clave: '10431902', Descripcion: 'Crisantemo spider cortado seco anastasia bronce oscuro' },
    { Clave: '10431903', Descripcion: 'Crisantemo spider cortado seco anastasia verde' },
    { Clave: '10431904', Descripcion: 'Crisantemo spider cortado seco anastasia lila' },
    { Clave: '10431905', Descripcion: 'Crisantemo spider cortado seco anastasia rosado' },
    { Clave: '10431906', Descripcion: 'Crisantemo spider cortado seco anastasia púrpura' },
    { Clave: '10431907', Descripcion: 'Crisantemo spider cortado seco anastasia soleado' },
    { Clave: '10431908', Descripcion: 'Crisantemo spider cortado seco anastasia blanco' },
    { Clave: '10431909', Descripcion: 'Crisantemo spider cortado seco bradford' },
    { Clave: '10431910', Descripcion: 'Crisantemo spider cortado seco delistar blanco' },
    { Clave: '10431911', Descripcion: 'Crisantemo spider cortado seco delistar amarillo' },
    { Clave: '10431912', Descripcion: 'Crisantemo spider cortado seco minka' },
    { Clave: '10431913', Descripcion: 'Crisantemo spider cortado seco natasha soleado' },
    { Clave: '10431914', Descripcion: 'Crisantemo spider cortado seco pirouette' },
    { Clave: '10431915', Descripcion: 'Crisantemo spider cortado seco reflejo' },
    { Clave: '10431916', Descripcion: 'Crisantemo spider cortado seco regata' },
    { Clave: '10431917', Descripcion: 'Crisantemo spider cortado seco render' },
    { Clave: '10431918', Descripcion: 'Crisantemo spider cortado seco repertorio' },
    { Clave: '10431919', Descripcion: 'Crisantemo spider cortado seco resolute' },
    { Clave: '10431920', Descripcion: 'Crisantemo spider cortado seco resomac' },
    { Clave: '10431921', Descripcion: 'Crisantemo spider cortado seco trébol' },
    { Clave: '10431922', Descripcion: 'Crisantemo spider cortado seco talante bronce' },
    { Clave: '10431923', Descripcion: 'Crisantemo spider cortado seco super blanco' },
    { Clave: '10431924', Descripcion: 'Crisantemo spider cortado seco super amarillo' },
    { Clave: '10431925', Descripcion: 'Crisantemo spider cortado seco tierno' },
    { Clave: '10431926', Descripcion: 'Crisantemo spider cortado seco zembia' },
    { Clave: '10432000', Descripcion: 'Crisantemo cortado seco  novelty' },
    { Clave: '10432001', Descripcion: 'Crisantemo cortado seco pompón anecy rosado' },
    { Clave: '10432002', Descripcion: 'Crisantemo cortado seco pompón ardilo royal' },
    { Clave: '10432003', Descripcion: 'Crisantemo cortado seco pompón athos' },
    { Clave: '10432004', Descripcion: 'Crisantemo cortado seco pompón biarritz' },
    { Clave: '10432005', Descripcion: 'Crisantemo cortado seco pompón bradford anaranjado' },
    { Clave: '10432006', Descripcion: 'Crisantemo cortado seco pompón bradford' },
    { Clave: '10432007', Descripcion: 'Crisantemo cortado seco pompón vela' },
    { Clave: '10432008', Descripcion: 'Crisantemo cortado seco pompón candor' },
    { Clave: '10432009', Descripcion: 'Crisantemo cortado seco pompón gallardía' },
    { Clave: '10432010', Descripcion: 'Crisantemo cortado seco pompón décima' },
    { Clave: '10432012', Descripcion: 'Crisantemo cortado seco pompón delisun' },
    { Clave: '10432013', Descripcion: 'Crisantemo cortado seco pompón dion' },
    { Clave: '10432014', Descripcion: 'Crisantemo cortado seco pompón dorena' },
    { Clave: '10432015', Descripcion: 'Crisantemo cortado seco pompón dublín' },
    { Clave: '10432016', Descripcion: 'Crisantemo cortado seco pompón everglades' },
    { Clave: '10432017', Descripcion: 'Crisantemo cortado seco pompón buenmozo' },
    { Clave: '10432018', Descripcion: 'Crisantemo cortado seco pompón hasting' },
    { Clave: '10432019', Descripcion: 'Crisantemo cortado seco pompón high five' },
    { Clave: '10432020', Descripcion: 'Crisantemo cortado seco pompón mundial mejorado' },
    { Clave: '10432021', Descripcion: 'Crisantemo cortado seco pompón juanes' },
    { Clave: '10432022', Descripcion: 'Crisantemo cortado seco pompón kiato verde' },
    { Clave: '10432023', Descripcion: 'Crisantemo cortado seco pompón kiato' },
    { Clave: '10432024', Descripcion: 'Crisantemo cortado seco pompón kiwi' },
    { Clave: '10432025', Descripcion: 'Crisantemo cortado seco pompón madeira' },
    { Clave: '10432026', Descripcion: 'Crisantemo cortado seco pompón magneto' },
    { Clave: '10432027', Descripcion: 'Crisantemo cortado seco pompón marimo' },
    { Clave: '10432028', Descripcion: 'Crisantemo cortado seco pompón matrix' },
    { Clave: '10432029', Descripcion: 'Crisantemo cortado seco pompón miletta' },
    { Clave: '10432030', Descripcion: 'Crisantemo cortado seco pompón monalisa' },
    { Clave: '10432031', Descripcion: 'Crisantemo cortado seco pompón omaha' },
    { Clave: '10432032', Descripcion: 'Crisantemo cortado seco pompón orinoco púrpura' },
    { Clave: '10432033', Descripcion: 'Crisantemo cortado seco pompón orinoco amarillo' },
    { Clave: '10432034', Descripcion: 'Crisantemo cortado seco pompón verde pacífico' },
    { Clave: '10432035', Descripcion: 'Crisantemo cortado seco pompón blanco puma' },
    { Clave: '10432036', Descripcion: 'Crisantemo cortado seco pompón amarillo puma' },
    { Clave: '10432037', Descripcion: 'Crisantemo cortado seco pompón mundial púrpura' },
    { Clave: '10432038', Descripcion: 'Crisantemo cortado seco pompón regata' },
    { Clave: '10432039', Descripcion: 'Crisantemo cortado seco pompón remco' },
    { Clave: '10432040', Descripcion: 'Crisantemo cortado seco pompón royal mundial' },
    { Clave: '10432041', Descripcion: 'Crisantemo cortado seco pompón sabrina' },
    { Clave: '10432042', Descripcion: 'Crisantemo cortado seco pompón shakira blanco' },
    { Clave: '10432043', Descripcion: 'Crisantemo cortado seco pompón sharp' },
    { Clave: '10432044', Descripcion: 'Crisantemo cortado seco pompón shock' },
    { Clave: '10432045', Descripcion: 'Crisantemo cortado seco pompón sizzle verde' },
    { Clave: '10432046', Descripcion: 'Crisantemo cortado seco pompón sizzle rosado' },
    { Clave: '10432047', Descripcion: 'Crisantemo cortado seco pompón sizzle' },
    { Clave: '10432048', Descripcion: 'Crisantemo cortado seco pompón sizzle púrpura' },
    { Clave: '10432049', Descripcion: 'Crisantemo cortado seco pompón sizzle salmón' },
    { Clave: '10432050', Descripcion: 'Crisantemo cortado seco pompón sizzle amarillo' },
    { Clave: '10432051', Descripcion: 'Crisantemo cortado seco pompón bandera española' },
    { Clave: '10432052', Descripcion: 'Crisantemo cortado seco pompón starbust o copo de nieve' },
    { Clave: '10432053', Descripcion: 'Crisantemo cortado seco pompón cisne' },
    { Clave: '10432054', Descripcion: 'Crisantemo cortado seco pompón tedcha anaranjado' },
    { Clave: '10432055', Descripcion: 'Crisantemo cortado seco pompón tierno' },
    { Clave: '10432056', Descripcion: 'Crisantemo cortado seco pompón oropel' },
    { Clave: '10432057', Descripcion: 'Crisantemo cortado seco pompón toque' },
    { Clave: '10432058', Descripcion: 'Crisantemo cortado seco pompón troyes' },
    { Clave: '10432059', Descripcion: 'Crisantemo cortado seco pompón valesca' },
    { Clave: '10432060', Descripcion: 'Crisantemo cortado seco pompón anaranjado vikingo' },
    { Clave: '10432061', Descripcion: 'Crisantemo cortado seco pompón vikingo' },
    { Clave: '10432062', Descripcion: 'Crisantemo cortado seco pompón reloj' },
    { Clave: '10432063', Descripcion: 'Crisantemo cortado seco pompón aguja blanco' },
    { Clave: '10432064', Descripcion: 'Crisantemo cortado seco pompón noche blanca' },
    { Clave: '10432065', Descripcion: 'Crisantemo cortado seco pompón artista amarillo' },
    { Clave: '10432066', Descripcion: 'Crisantemo cortado seco pompón ficción amarillo' },
    { Clave: '10432067', Descripcion: 'Crisantemo cortado seco pompón sharp amarillo' },
    { Clave: '10432100', Descripcion: 'Crisantemo cortado seco santini' },
    { Clave: '10432101', Descripcion: 'Crisantemo cortado seco pompón alma' },
    { Clave: '10432102', Descripcion: 'Crisantemo cortado seco pompón barón' },
    { Clave: '10432103', Descripcion: 'Crisantemo cortado seco pompón bernardo' },
    { Clave: '10432104', Descripcion: 'Crisantemo cortado seco pompón bistro' },
    { Clave: '10432105', Descripcion: 'Crisantemo cortado seco pompón bodega' },
    { Clave: '10432106', Descripcion: 'Crisantemo cortado seco pompón brisa' },
    { Clave: '10432107', Descripcion: 'Crisantemo cortado seco pompón centella bronce' },
    { Clave: '10432108', Descripcion: 'Crisantemo cortado seco pompón costa blanca' },
    { Clave: '10432109', Descripcion: 'Crisantemo cortado seco pompón creta' },
    { Clave: '10432110', Descripcion: 'Crisantemo cortado seco pompón deliflame' },
    { Clave: '10432111', Descripcion: 'Crisantemo cortado seco pompón dalila' },
    { Clave: '10432112', Descripcion: 'Crisantemo cortado seco pompón dígito' },
    { Clave: '10432113', Descripcion: 'Crisantemo cortado seco pompón evilio' },
    { Clave: '10432114', Descripcion: 'Crisantemo cortado seco pompón furense' },
    { Clave: '10432115', Descripcion: 'Crisantemo cortado seco pompón guía' },
    { Clave: '10432116', Descripcion: 'Crisantemo cortado seco pompón kerry' },
    { Clave: '10432117', Descripcion: 'Crisantemo cortado seco pompón kess' },
    { Clave: '10432118', Descripcion: 'Crisantemo cortado seco pompón lima' },
    { Clave: '10432119', Descripcion: 'Crisantemo cortado seco pompón lupo' },
    { Clave: '10432120', Descripcion: 'Crisantemo cortado seco pompón lineker anaranjado' },
    { Clave: '10432121', Descripcion: 'Crisantemo cortado seco pompón panuco rojo' },
    { Clave: '10432122', Descripcion: 'Crisantemo cortado seco pompón costa rosado' },
    { Clave: '10432123', Descripcion: 'Crisantemo cortado seco pompón rafael' },
    { Clave: '10432124', Descripcion: 'Crisantemo cortado seco pompón refine' },
    { Clave: '10432125', Descripcion: 'Crisantemo cortado seco pompón regaliz' },
    { Clave: '10432126', Descripcion: 'Crisantemo cortado seco pompón renella' },
    { Clave: '10432127', Descripcion: 'Crisantemo cortado seco pompón retorno' },
    { Clave: '10432128', Descripcion: 'Crisantemo cortado seco pompón río' },
    { Clave: '10432129', Descripcion: 'Crisantemo cortado seco pompón sabas' },
    { Clave: '10432130', Descripcion: 'Crisantemo cortado seco pompón target' },
    { Clave: '10432131', Descripcion: 'Crisantemo cortado seco pompón texto' },
    { Clave: '10432132', Descripcion: 'Crisantemo cortado seco pompón caballo amarillo' },
    { Clave: '10441500', Descripcion: 'Clavel cortado seco de floración sencilla' },
    { Clave: '10441501', Descripcion: 'Clavel cortado seco de floración sencilla burgundy bicolor' },
    { Clave: '10441502', Descripcion: 'Clavel cortado seco de floración sencilla burgundy' },
    { Clave: '10441503', Descripcion: 'Clavel cortado seco de floración sencilla cinderella' },
    { Clave: '10441504', Descripcion: 'Clavel cortado seco de floración sencilla crema bicolor' },
    { Clave: '10441505', Descripcion: 'Clavel cortado seco de floración sencilla crema' },
    { Clave: '10441506', Descripcion: 'Clavel cortado seco de floración sencilla verde o prado' },
    { Clave: '10441507', Descripcion: 'Clavel cortado seco de floración sencilla rosado fuerte' },
    { Clave: '10441508', Descripcion: 'Clavel cortado seco de floración sencilla verde claro' },
    { Clave: '10441509', Descripcion: 'Clavel cortado seco de floración sencilla rosado claro' },
    { Clave: '10441510', Descripcion: 'Clavel cortado seco de floración sencilla anaranjado bicolor' },
    { Clave: '10441511', Descripcion: 'Clavel cortado seco de floración sencilla anaranjado' },
    { Clave: '10441512', Descripcion: 'Clavel cortado seco de floración sencilla durazno' },
    { Clave: '10441513', Descripcion: 'Clavel cortado seco de floración sencilla menta bicolor' },
    { Clave: '10441514', Descripcion: 'Clavel cortado seco de floración sencilla rosado bicolor' },
    { Clave: '10441515', Descripcion: 'Clavel cortado seco de floración sencilla rosado' },
    { Clave: '10441516', Descripcion: 'Clavel cortado seco de floración sencilla púrpura bicolor' },
    { Clave: '10441517', Descripcion: 'Clavel cortado seco de floración sencilla rojo bicolor' },
    { Clave: '10441518', Descripcion: 'Clavel cortado seco de floración sencilla rojo' },
    { Clave: '10441519', Descripcion: 'Clavel cortado seco de floración sencilla blanco' },
    { Clave: '10441520', Descripcion: 'Clavel cortado seco de floración sencilla amarillo' },
    { Clave: '10441600', Descripcion: 'Clavel cortado seco mini o spray' },
    { Clave: '10441601', Descripcion: 'Clavel cortado seco mini o spray burgundy' },
    { Clave: '10441602', Descripcion: 'Clavel cortado seco mini o spray' },
    { Clave: '10441603', Descripcion: 'Clavel cortado seco mini o spray rosado fuerte' },
    { Clave: '10441604', Descripcion: 'Clavel cortado seco mini o spray lavanda' },
    { Clave: '10441605', Descripcion: 'Clavel cortado seco mini o spray rosado claro' },
    { Clave: '10441606', Descripcion: 'Clavel cortado seco mini o spray anaranjado' },
    { Clave: '10441607', Descripcion: 'Clavel cortado seco mini o spray durazno' },
    { Clave: '10441608', Descripcion: 'Clavel cortado seco mini o spray menta' },
    { Clave: '10441609', Descripcion: 'Clavel cortado seco mini o spray rosado' },
    { Clave: '10441610', Descripcion: 'Clavel cortado seco mini o spray púrpura bicolor' },
    { Clave: '10441611', Descripcion: 'Clavel cortado seco mini o spray púrpura' },
    { Clave: '10441612', Descripcion: 'Clavel cortado seco mini o spray rojo' },
    { Clave: '10441613', Descripcion: 'Clavel cortado seco mini o spray blanco' },
    { Clave: '10441614', Descripcion: 'Clavel cortado seco mini o spray amarillo' },
    { Clave: '10451500', Descripcion: 'Orquidea cypripedium  o pantuflas de dama cortada seca' },
    { Clave: '10451501', Descripcion: 'Orquidea cypripedium cortada seca verde' },
    { Clave: '10451502', Descripcion: 'Orquidea cypripedium cortada seca francia' },
    { Clave: '10451503', Descripcion: 'Orquidea cypripedium cortada seca púrpura rey arturo' },
    { Clave: '10451504', Descripcion: 'Orquidea paphiopedilum cortada seca verde' },
    { Clave: '10451505', Descripcion: 'Orquidea aranthera maggie vie cortada seca' },
    { Clave: '10451600', Descripcion: 'Orquídea mocara cortada seca' },
    { Clave: '10451601', Descripcion: 'Orquídea mocara cortada seca omyai' },
    { Clave: '10451602', Descripcion: 'Orquídea mocara cortada seca roja' },
    { Clave: '10451603', Descripcion: 'Orquídea mocara cortada seca calipso' },
    { Clave: '10451604', Descripcion: 'Orquídea mocara cortada seca nora' },
    { Clave: '10451605', Descripcion: 'Orquídea mocara cortada seca panee' },
    { Clave: '10451700', Descripcion: 'Orquídea catleya cortada seca' },
    { Clave: '10451701', Descripcion: 'Orquídea cattleya cortada seca blanca' },
    { Clave: '10451702', Descripcion: 'Orquídea cattleya cortada seca r b lavanda' },
    { Clave: '10451800', Descripcion: 'Orquídea disa cortada seca' },
    { Clave: '10451801', Descripcion: 'Orquídea disa cortada seca roja' },
    { Clave: '10451802', Descripcion: 'Orquídea disa cortada seca anaranjada' },
    { Clave: '10451803', Descripcion: 'Orquídea disa cortada seca rosada' },
    { Clave: '10451804', Descripcion: 'Orquídea disa cortada seca anaranjada y amarilla bicolor' },
    { Clave: '10451805', Descripcion: 'Orquídea disa cortada seca durazno y amarilla bicolor' },
    { Clave: '10451806', Descripcion: 'Orquídea disa cortada seca amarilla y roja bicolor' },
    { Clave: '10451900', Descripcion: 'Orquídea arachnis cortada seca' },
    { Clave: '10451901', Descripcion: 'Orquídea arachnis cortada seca james storie roja' },
    { Clave: '10451902', Descripcion: 'Orquídea arachnis cortada seca maggie oei roja' },
    { Clave: '10451903', Descripcion: 'Orquídea arachnis cortada seca maggie oei amarilla' },
    { Clave: '10451904', Descripcion: 'Orquídea arachnis cortada seca maroon maggie' },
    { Clave: '10451905', Descripcion: 'Orquídea arachnis cortada seca merry maggie' },
    { Clave: '10452000', Descripcion: 'Orquídea phalaenopsis cortada seca' },
    { Clave: '10452001', Descripcion: 'Orquídea phalaenopsis cortada seca amabilis' },
    { Clave: '10452002', Descripcion: 'Orquídea phalaenopsis cortada seca amboinensis' },
    { Clave: '10452003', Descripcion: 'Orquídea phalaenopsis cortada seca afrodita' },
    { Clave: '10452004', Descripcion: 'Orquídea phalaenopsis cortada seca appendiculata' },
    { Clave: '10452005', Descripcion: 'Orquídea phalaenopsis cortada seca bastianii' },
    { Clave: '10452006', Descripcion: 'Orquídea phalaenopsis cortada seca bellina' },
    { Clave: '10452007', Descripcion: 'Orquídea phalaenopsis cortada seca borneensis' },
    { Clave: '10452008', Descripcion: 'Orquídea phalaenopsis cortada seca braceana' },
    { Clave: '10452009', Descripcion: 'Orquídea phalaenopsis cortada seca buyssoniana' },
    { Clave: '10452010', Descripcion: 'Orquídea phalaenopsis cortada seca celebensis' },
    { Clave: '10452011', Descripcion: 'Orquídea phalaenopsis cortada seca chibae' },
    { Clave: '10452012', Descripcion: 'Orquídea phalaenopsis cortada seca cochlearis' },
    { Clave: '10452013', Descripcion: 'Orquídea phalaenopsis cortada seca corningiana' },
    { Clave: '10452014', Descripcion: 'Orquídea phalaenopsis cortada seca cornu-cervi' },
    { Clave: '10452015', Descripcion: 'Orquídea phalaenopsis cortada seca deliciosa' },
    { Clave: '10452016', Descripcion: 'Orquídea phalaenopsis cortada seca doweryënsis' },
    { Clave: '10452017', Descripcion: 'Orquídea phalaenopsis cortada seca equestris' },
    { Clave: '10452018', Descripcion: 'Orquídea phalaenopsis cortada seca fasciata' },
    { Clave: '10452019', Descripcion: 'Orquídea phalaenopsis cortada seca fimbriata' },
    { Clave: '10452020', Descripcion: 'Orquídea phalaenopsis cortada seca floresensis' },
    { Clave: '10452021', Descripcion: 'Orquídea phalaenopsis cortada seca fuscata' },
    { Clave: '10452022', Descripcion: 'Orquídea phalaenopsis cortada seca gibbosa' },
    { Clave: '10452023', Descripcion: 'Orquídea phalaenopsis cortada seca hainanensis' },
    { Clave: '10452024', Descripcion: 'Orquídea phalaenopsis cortada seca hieroglyphica' },
    { Clave: '10452025', Descripcion: 'Orquídea phalaenopsis cortada seca honghenensis' },
    { Clave: '10452026', Descripcion: 'Orquídea phalaenopsis cortada seca inscriptiosinensis' },
    { Clave: '10452027', Descripcion: 'Orquídea phalaenopsis cortada seca javanica' },
    { Clave: '10452028', Descripcion: 'Orquídea phalaenopsis cortada seca kunstleri' },
    { Clave: '10452029', Descripcion: 'Orquídea phalaenopsis cortada seca lamelligera' },
    { Clave: '10452030', Descripcion: 'Orquídea phalaenopsis cortada seca lindenii' },
    { Clave: '10452031', Descripcion: 'Orquídea phalaenopsis cortada seca lobbii' },
    { Clave: '10452032', Descripcion: 'Orquídea phalaenopsis cortada seca lowii' },
    { Clave: '10452033', Descripcion: 'Orquídea phalaenopsis cortada seca lueddemanniana' },
    { Clave: '10452034', Descripcion: 'Orquídea phalaenopsis cortada seca mambo' },
    { Clave: '10452035', Descripcion: 'Orquídea phalaenopsis cortada seca luteola' },
    { Clave: '10452036', Descripcion: 'Orquídea phalaenopsis cortada seca maculata' },
    { Clave: '10452037', Descripcion: 'Orquídea phalaenopsis cortada seca malipoensis' },
    { Clave: '10452038', Descripcion: 'Orquídea phalaenopsis cortada seca mannii' },
    { Clave: '10452039', Descripcion: 'Orquídea phalaenopsis cortada seca mariae' },
    { Clave: '10452040', Descripcion: 'Orquídea phalaenopsis cortada seca micholitzii' },
    { Clave: '10452041', Descripcion: 'Orquídea phalaenopsis cortada seca modesta' },
    { Clave: '10452042', Descripcion: 'Orquídea phalaenopsis cortada seca mysorensis' },
    { Clave: '10452043', Descripcion: 'Orquídea phalaenopsis cortada seca pallens' },
    { Clave: '10452044', Descripcion: 'Orquídea phalaenopsis cortada seca pantherina' },
    { Clave: '10452045', Descripcion: 'Orquídea phalaenopsis cortada seca parishii' },
    { Clave: '10452046', Descripcion: 'Orquídea phalaenopsis cortada seca petelotii' },
    { Clave: '10452047', Descripcion: 'Orquídea phalaenopsis cortada seca philippinensis' },
    { Clave: '10452048', Descripcion: 'Orquídea phalaenopsis cortada seca pulcherrima' },
    { Clave: '10452049', Descripcion: 'Orquídea phalaenopsis cortada seca pulchra' },
    { Clave: '10452050', Descripcion: 'Orquídea phalaenopsis cortada seca regnieriana' },
    { Clave: '10452051', Descripcion: 'Orquídea phalaenopsis cortada seca reichenbachiana' },
    { Clave: '10452052', Descripcion: 'Orquídea phalaenopsis cortada seca nivacolor' },
    { Clave: '10452053', Descripcion: 'Orquídea phalaenopsis cortada seca sanderiana' },
    { Clave: '10452054', Descripcion: 'Orquídea phalaenopsis cortada seca schilleriana' },
    { Clave: '10452055', Descripcion: 'Orquídea phalaenopsis cortada seca speciosa' },
    { Clave: '10452056', Descripcion: 'Orquídea phalaenopsis cortada seca stobartiana' },
    { Clave: '10452057', Descripcion: 'Orquídea phalaenopsis cortada seca stuartiana' },
    { Clave: '10452058', Descripcion: 'Orquídea phalaenopsis cortada seca sumatrana' },
    { Clave: '10452059', Descripcion: 'Orquídea phalaenopsis cortada seca taenialis' },
    { Clave: '10452060', Descripcion: 'Orquídea phalaenopsis cortada seca tetraspis' },
    { Clave: '10452061', Descripcion: 'Orquídea phalaenopsis cortada seca venosa' },
    { Clave: '10452062', Descripcion: 'Orquídea phalaenopsis cortada seca violácea' },
    { Clave: '10452063', Descripcion: 'Orquídea phalaenopsis cortada seca viridis' },
    { Clave: '10452064', Descripcion: 'Orquídea phalaenopsis cortada seca wilsonii' },
    { Clave: '10452065', Descripcion: 'Orquídea phalaenopsis cortada seca zebrina' },
    { Clave: '10452067', Descripcion: 'Orquídea phalaenopsis cortada seca labio lavanda' },
    { Clave: '10452100', Descripcion: 'Orquídea dendrobium cortada seca' },
    { Clave: '10452101', Descripcion: 'Orquídea dendrobium cortada seca bom' },
    { Clave: '10452102', Descripcion: 'Orquídea dendrobium cortada seca burana' },
    { Clave: '10452103', Descripcion: 'Orquídea dendrobium cortada seca chita' },
    { Clave: '10452104', Descripcion: 'Orquídea dendrobium cortada seca fátima' },
    { Clave: '10452105', Descripcion: 'Orquídea dendrobium cortada seca intuwong' },
    { Clave: '10452106', Descripcion: 'Orquídea dendrobium cortada seca jumbo blanca' },
    { Clave: '10452107', Descripcion: 'Orquídea dendrobium cortada seca kating dang' },
    { Clave: '10452108', Descripcion: 'Orquídea dendrobium cortada seca libertad' },
    { Clave: '10452109', Descripcion: 'Orquídea dendrobium cortada seca hawaii' },
    { Clave: '10452110', Descripcion: 'Orquídea dendrobium cortada seca sakura sweet rosada' },
    { Clave: '10452111', Descripcion: 'Orquídea dendrobium cortada seca sensacional púrpura' },
    { Clave: '10452112', Descripcion: 'Orquídea dendrobium cortada seca blanca' },
    { Clave: '10452200', Descripcion: 'Orquídea cymbidium cortada seca' },
    { Clave: '10452201', Descripcion: 'Orquídea cymbidium cortada seca crema' },
    { Clave: '10452202', Descripcion: 'Orquídea cymbidium cortada seca verde' },
    { Clave: '10452203', Descripcion: 'Orquídea cymbidium cortada seca mini verde' },
    { Clave: '10452204', Descripcion: 'Orquídea cymbidium cortada seca mini rosada' },
    { Clave: '10452205', Descripcion: 'Orquídea cymbidium cortada seca mini roja' },
    { Clave: '10452206', Descripcion: 'Orquídea cymbidium cortada seca mini blanca' },
    { Clave: '10452207', Descripcion: 'Orquídea cymbidium cortada seca mini amarilla' },
    { Clave: '10452208', Descripcion: 'Orquídea cymbidium cortada seca chocolate' },
    { Clave: '10452209', Descripcion: 'Orquídea cymbidium cortada seca rosado oscuro' },
    { Clave: '10452210', Descripcion: 'Orquídea cymbidium cortada seca anaranjada' },
    { Clave: '10452211', Descripcion: 'Orquídea cymbidium cortada seca rosada' },
    { Clave: '10452212', Descripcion: 'Orquídea cymbidium cortada seca blanca' },
    { Clave: '10452213', Descripcion: 'Orquídea cymbidium cortada seca amarilla' },
    { Clave: '10452300', Descripcion: 'Orquídea oncidium cortada seca' },
    { Clave: '10452301', Descripcion: 'Orquídea oncidium cortada seca ducha dorada' },
    { Clave: '10452302', Descripcion: 'Orquídea oncidium cortada seca rhamsey' },
    { Clave: '10452400', Descripcion: 'Orquídea vanda cortada seca' },
    { Clave: '10452401', Descripcion: 'Orquídea vanda alizarin' },
    { Clave: '10452402', Descripcion: 'Orquídea vanda rosada fuerte' },
    { Clave: '10452403', Descripcion: 'Orquídea vanda lavanda' },
    { Clave: '10452404', Descripcion: 'Orquídea vanda púrpura' },
    { Clave: '10452405', Descripcion: 'Orquídea vanda hazme cosquillas rosada' },
    { Clave: '10452406', Descripcion: 'Orquídea vanda amarilla' },
    { Clave: '10501500', Descripcion: 'Aspidistras cortadas frescas' },
    { Clave: '10501501', Descripcion: 'Aspidistra cortada fresca verde' },
    { Clave: '10501502', Descripcion: 'Aspidistra cortada fresca vía láctea' },
    { Clave: '10501503', Descripcion: 'Aspidistra cortada fresca variegada' },
    { Clave: '10501600', Descripcion: 'Chili verde cortado fresco' },
    { Clave: '10501601', Descripcion: 'Chili verde cortado fresco avellana' },
    { Clave: '10501602', Descripcion: 'Chili verde cortado fresco romerillo' },
    { Clave: '10501603', Descripcion: 'Chili verde cortado fresco pacarilla' },
    { Clave: '10501604', Descripcion: 'Chili verde cortado fresco musgo' },
    { Clave: '10501700', Descripcion: 'Haya cortada fresca' },
    { Clave: '10501701', Descripcion: 'Haya cortada fresca bronce' },
    { Clave: '10501702', Descripcion: 'Haya cortada fresca verde' },
    { Clave: '10501703', Descripcion: 'Haya cortada fresca rojo' },
    { Clave: '10501800', Descripcion: 'Hojas de eucalipto cortadas prescas' },
    { Clave: '10501801', Descripcion: 'Eucalipto cortado fresco bebé azul' },
    { Clave: '10501802', Descripcion: 'Eucalipto cortado fresco alto bonsai' },
    { Clave: '10501803', Descripcion: 'Eucalipto cortado fresco pluma' },
    { Clave: '10501804', Descripcion: 'Eucalipto cortado fresco gunnii' },
    { Clave: '10501805', Descripcion: 'Eucalipto cortado fresco parvifolia' },
    { Clave: '10501806', Descripcion: 'Eucalipto cortado fresco preservado' },
    { Clave: '10501807', Descripcion: 'Eucalipto cortado fresco sin semilla' },
    { Clave: '10501808', Descripcion: 'Eucalipto cortado fresco sin semilla y sin hojas' },
    { Clave: '10501809', Descripcion: 'Eucalipto cortado fresco sin semillas llorón' },
    { Clave: '10501810', Descripcion: 'Eucalipto cortado fresco dólar de plata' },
    { Clave: '10501811', Descripcion: 'Eucalipto cortado fresco alto espiral' },
    { Clave: '10501812', Descripcion: 'Eucalipto cortado fresco azul verdadero' },
    { Clave: '10501813', Descripcion: 'Eucalipto cortado fresco sauce sin semillas' },
    { Clave: '10501900', Descripcion: 'Helechos cortados frescos' },
    { Clave: '10501901', Descripcion: 'Helecho cortado fresco coontie' },
    { Clave: '10501902', Descripcion: 'Helecho cortado fresco pluma' },
    { Clave: '10501903', Descripcion: 'Helecho cortado fresco plano' },
    { Clave: '10501904', Descripcion: 'Helecho cortado fresco pelo de mujer' },
    { Clave: '10501905', Descripcion: 'Helecho cortado fresco ming' },
    { Clave: '10501906', Descripcion: 'Helecho cortado fresco espada florida alto' },
    { Clave: '10501907', Descripcion: 'Helecho cortado fresco árbol cortado' },
    { Clave: '10501908', Descripcion: 'Helecho cortado fresco sombrilla' },
    { Clave: '10502000', Descripcion: 'Hojas o follajes cortados frescos' },
    { Clave: '10502001', Descripcion: 'Follaje fresco cortado de melaluca australiana' },
    { Clave: '10502002', Descripcion: 'Follaje fresco cortado de lepto azul' },
    { Clave: '10502003', Descripcion: 'Follaje fresco cortado de corylus avellana' },
    { Clave: '10502004', Descripcion: 'Follaje fresco cortado de hosta verde' },
    { Clave: '10502005', Descripcion: 'Follaje fresco cortado de hosta variegada' },
    { Clave: '10502006', Descripcion: 'Follaje fresco cortado de kunzia' },
    { Clave: '10502007', Descripcion: 'Follaje fresco cortado de jade' },
    { Clave: '10502008', Descripcion: 'Follaje fresco cortado de magnolia' },
    { Clave: '10502009', Descripcion: 'Follaje fresco cortado de meyeril o foxtail' },
    { Clave: '10502010', Descripcion: 'Follaje fresco cortado de olivo' },
    { Clave: '10502011', Descripcion: 'Follaje fresco cortado de pistacho' },
    { Clave: '10502012', Descripcion: 'Follaje fresco cortado de ciruelo' },
    { Clave: '10502013', Descripcion: 'Follaje fresco cortado de robellini' },
    { Clave: '10502014', Descripcion: 'Follaje fresco cortado de viburnum' },
    { Clave: '10502015', Descripcion: 'Follaje fresco cortado de xanadu' },
    { Clave: '10502016', Descripcion: 'Follaje fresco cortado de sanderiana' },
    { Clave: '10502017', Descripcion: 'Follaje fresco cortado de gardenia' },
    { Clave: '10502100', Descripcion: 'Pastos costados frescos' },
    { Clave: '10502101', Descripcion: 'Pasto fresco cortado lirio florida' },
    { Clave: '10502102', Descripcion: 'Pasto fresco cortado variegado' },
    { Clave: '10502103', Descripcion: 'Pasto fresco cortado oso' },
    { Clave: '10502104', Descripcion: 'Pasto fresco cortado bambú' },
    { Clave: '10502105', Descripcion: 'Pasto fresco cortado caña' },
    { Clave: '10502106', Descripcion: 'Pasto fresco cortado descorchador' },
    { Clave: '10502107', Descripcion: 'Pasto fresco cortado pluma' },
    { Clave: '10502108', Descripcion: 'Pasto fresco cortado flexi' },
    { Clave: '10502109', Descripcion: 'Pasto fresco cortado fuente' },
    { Clave: '10502110', Descripcion: 'Pasto fresco cortado carrizo' },
    { Clave: '10502111', Descripcion: 'Pasto fresco cortado millet' },
    { Clave: '10502112', Descripcion: 'Pasto fresco cortado millet negro' },
    { Clave: '10502113', Descripcion: 'Pasto fresco cortado milo' },
    { Clave: '10502114', Descripcion: 'Pasto fresco cortado avena variegada' },
    { Clave: '10502115', Descripcion: 'Pasto fresco cortado phalaris canariensis' },
    { Clave: '10502116', Descripcion: 'Pasto fresco cortado ciruela púrpiura' },
    { Clave: '10502117', Descripcion: 'Pasto fresco cortado cascabel' },
    { Clave: '10502118', Descripcion: 'Pasto fresco cortado cinta' },
    { Clave: '10502119', Descripcion: 'Pasto fresco cortado avena marina' },
    { Clave: '10502120', Descripcion: 'Pasto fresco cortado steelgrass' },
    { Clave: '10502121', Descripcion: 'Pasto fresco cortado cebra variegado' },
    { Clave: '10502122', Descripcion: 'Pasto fresco cortado panicum o jungla' },
    { Clave: '10502123', Descripcion: 'Pasto fresco cortado pampas' },
    { Clave: '10502124', Descripcion: 'Pasto fresco cortado pimienta' },
    { Clave: '10502125', Descripcion: 'Pasto fresco cortado cebra' },
    { Clave: '10502200', Descripcion: 'mora huckleberry fresca cortada' },
    { Clave: '10502201', Descripcion: 'Mora huckleberry fresca cortada verde' },
    { Clave: '10502202', Descripcion: 'Mora huckleberry fresca cortada roja' },
    { Clave: '10502300', Descripcion: 'Hiedra cortada fresca' },
    { Clave: '10502301', Descripcion: 'Hiedra fresca cortada verde' },
    { Clave: '10502302', Descripcion: 'Hiedra fresca cortada varigada' },
    { Clave: '10502303', Descripcion: 'Hiedra fresca cortada erecta' },
    { Clave: '10502304', Descripcion: 'Hiedra fresca cortada verde de árbol' },
    { Clave: '10502305', Descripcion: 'Hiedra fresca cortada varirgada de árbol' },
    { Clave: '10502400', Descripcion: 'Integrifolia cortada fresca' },
    { Clave: '10502401', Descripcion: 'Integrifolia fresca cortada roja' },
    { Clave: '10502402', Descripcion: 'Integrifolia fresca cortada natural' },
    { Clave: '10502500', Descripcion: 'Mirto cortado fresco' },
    { Clave: '10502501', Descripcion: 'Mirto fresco cortado enano' },
    { Clave: '10502502', Descripcion: 'Mirto fresco cortado variegado' },
    { Clave: '10502600', Descripcion: 'Hojas frescas cortadas de roble' },
    { Clave: '10502601', Descripcion: 'Hojas frescas cortadas de roble rojo de holanda' },
    { Clave: '10502700', Descripcion: 'Oregonia fresca cortada' },
    { Clave: '10502701', Descripcion: 'Oregonia fresca cortada verde' },
    { Clave: '10502702', Descripcion: 'Oregonia fresca cortada plateada' },
    { Clave: '10502703', Descripcion: 'Oregonia fresca cortada variegada' },
    { Clave: '10502800', Descripcion: 'pittosporum fresco cortado' },
    { Clave: '10502801', Descripcion: 'Pittosporum fresco cortado verde' },
    { Clave: '10502802', Descripcion: 'Pittosporum fresco cortado nevatus' },
    { Clave: '10502803', Descripcion: 'Pittosporum fresco cortado nueva zelandia' },
    { Clave: '10502804', Descripcion: 'Pittosporum fresco cortado nigra' },
    { Clave: '10502805', Descripcion: 'Pittosporum fresco cortado variegado' },
    { Clave: '10502900', Descripcion: 'Hojas frescas cortadas de especies sencillas' },
    { Clave: '10502901', Descripcion: 'Acanto o cordone fresca cortada' },
    { Clave: '10502902', Descripcion: 'Pluma de acacia púrpura fresca cortada' },
    { Clave: '10502903', Descripcion: 'Adina rubella fresca cortada' },
    { Clave: '10502904', Descripcion: 'Agonis flexuosa fresca cortada' },
    { Clave: '10502905', Descripcion: 'Azara dentata fresca cortada' },
    { Clave: '10502906', Descripcion: 'Arbusto barker fresca cortada' },
    { Clave: '10502907', Descripcion: 'Corona de laurel fresca cortada' },
    { Clave: '10502908', Descripcion: 'Llama azul fresca cortada' },
    { Clave: '10502909', Descripcion: 'Buxus africana fresca cortada' },
    { Clave: '10502910', Descripcion: 'Laurel fresca cortada' },
    { Clave: '10502911', Descripcion: 'Hoja de camelia fresca cortada' },
    { Clave: '10502912', Descripcion: 'Cedro dorado de nueva zelandia fresca cortada' },
    { Clave: '10502913', Descripcion: 'Ceratopetalum de nueva zelandia fresca cortada' },
    { Clave: '10502914', Descripcion: 'Choisya ternata fresca cortada' },
    { Clave: '10502915', Descripcion: 'Cocculus fresca cortada' },
    { Clave: '10502916', Descripcion: 'Croto fresca cortada' },
    { Clave: '10502917', Descripcion: 'Datura fresca cortada' },
    { Clave: '10502918', Descripcion: 'Dusty miller fresca cortada' },
    { Clave: '10502919', Descripcion: 'Elegia capensis fresca cortada' },
    { Clave: '10502920', Descripcion: 'Hoja de euonymous fresca cortada' },
    { Clave: '10502921', Descripcion: 'Hoja de lino fresca cortada' },
    { Clave: '10502922', Descripcion: 'Hoja de galax fresca cortada' },
    { Clave: '10502923', Descripcion: 'Hoja de oro fresca cortada' },
    { Clave: '10502924', Descripcion: 'Hoja de gravillea fresca cortada' },
    { Clave: '10502925', Descripcion: 'Hoja de pasto cola de caballo o culebra fresca cortada' },
    { Clave: '10502926', Descripcion: 'Acacia hoja de navaja fresca cortada' },
    { Clave: '10502927', Descripcion: 'Hoja de laurel fresca cortada' },
    { Clave: '10502928', Descripcion: 'Hoja de cuero fresca cortada' },
    { Clave: '10502929', Descripcion: 'Hoja de ligularia fresca cortada' },
    { Clave: '10502930', Descripcion: 'Hoja de ligularia  argentea fresca cortada' },
    { Clave: '10502931', Descripcion: 'Lophomyrtus fresca cortada' },
    { Clave: '10502932', Descripcion: 'Lycopodium  o pino princresa fresca cortada' },
    { Clave: '10502933', Descripcion: 'Mahonia aquifolium fresca cortada' },
    { Clave: '10502934', Descripcion: 'Hoja de nagi fresca cortada' },
    { Clave: '10502935', Descripcion: 'Palma de abanico palmetto fresca cortada' },
    { Clave: '10502936', Descripcion: 'Papyrus umbrella florida fresca cortada' },
    { Clave: '10502937', Descripcion: 'Pennycress fresca cortada' },
    { Clave: '10502938', Descripcion: 'Photinia robin rojo fresca cortada' },
    { Clave: '10502939', Descripcion: 'Diablo de nueve cortezas o physocarpus fresca cortada' },
    { Clave: '10502940', Descripcion: 'Pimelea nivea fresca cortada' },
    { Clave: '10502941', Descripcion: 'Hoja de pistacho fresca cortada' },
    { Clave: '10502942', Descripcion: 'Plumosa fresca cortada' },
    { Clave: '10502943', Descripcion: 'Podocarpus fresca cortada' },
    { Clave: '10502944', Descripcion: 'Pokeweed fresca cortada' },
    { Clave: '10502945', Descripcion: 'Photinia  de punta roja fresca cortada' },
    { Clave: '10502946', Descripcion: 'Rhamnus o buckthorn variegada fresca cortada' },
    { Clave: '10502947', Descripcion: 'Rododendro en flor fresca cortada' },
    { Clave: '10502948', Descripcion: 'Hoja de rosio fresca cortada' },
    { Clave: '10502949', Descripcion: 'Ruscus florida largo fresca cortada' },
    { Clave: '10502950', Descripcion: 'Ruscus italiana fresca cortada' },
    { Clave: '10502951', Descripcion: 'Salal u hoja de limón fresca cortada' },
    { Clave: '10502952', Descripcion: 'Ruscifolia sarcococca fresca cortada' },
    { Clave: '10502953', Descripcion: 'Cheflera fresca cortada' },
    { Clave: '10502954', Descripcion: 'Avena de mar uniola latifolia fresca cortada' },
    { Clave: '10502955', Descripcion: 'Tomillo plateado fresca cortada' },
    { Clave: '10502956', Descripcion: 'Skimmia fresca cortada' },
    { Clave: '10502957', Descripcion: 'Springeri fresca cortada' },
    { Clave: '10502958', Descripcion: 'Tulepods fresca cortada' },
    { Clave: '10502959', Descripcion: 'Weigelia fresca cortada' },
    { Clave: '11101500', Descripcion: 'Minerales' },
    { Clave: '11101501', Descripcion: 'Mica' },
    { Clave: '11101502', Descripcion: 'Lija o esmeril' },
    { Clave: '11101503', Descripcion: 'Cuarzo' },
    { Clave: '11101504', Descripcion: 'Pirita' },
    { Clave: '11101505', Descripcion: 'Azufre o sulfuro' },
    { Clave: '11101506', Descripcion: 'Tiza' },
    { Clave: '11101507', Descripcion: 'Grafito' },
    { Clave: '11101508', Descripcion: 'Dolomita' },
    { Clave: '11101509', Descripcion: 'Magnesita' },
    { Clave: '11101510', Descripcion: 'Asbesto (amianto)' },
    { Clave: '11101511', Descripcion: 'Calcio' },
    { Clave: '11101512', Descripcion: 'Borato' },
    { Clave: '11101513', Descripcion: 'Criolita' },
    { Clave: '11101514', Descripcion: 'Feldespato' },
    { Clave: '11101515', Descripcion: 'Leucita' },
    { Clave: '11101516', Descripcion: 'Nefelita' },
    { Clave: '11101517', Descripcion: 'Esteatita' },
    { Clave: '11101518', Descripcion: 'Talco' },
    { Clave: '11101519', Descripcion: 'Diamante en bruto' },
    { Clave: '11101520', Descripcion: 'Granates' },
    { Clave: '11101521', Descripcion: 'Carburo de silicio' },
    { Clave: '11101522', Descripcion: 'Carbón activado' },
    { Clave: '11101523', Descripcion: 'Mulita' },
    { Clave: '11101524', Descripcion: 'Fluorita' },
    { Clave: '11101525', Descripcion: 'Kieserita' },
    { Clave: '11101526', Descripcion: 'Carnalita' },
    { Clave: '11101527', Descripcion: 'Sílice de diatomeas' },
    { Clave: '11101528', Descripcion: 'Óxido de aluminio activado' },
    { Clave: '11101529', Descripcion: 'Zeolita' },
    { Clave: '11101530', Descripcion: 'Calcita' },
    { Clave: '11101531', Descripcion: 'Serpentina' },
    { Clave: '11101532', Descripcion: 'Peridoto' },
    { Clave: '11101533', Descripcion: 'Pirofilita' },
    { Clave: '11101534', Descripcion: 'Diamante pulido' },
    { Clave: '11101600', Descripcion: 'Minerales metálicos' },
    { Clave: '11101601', Descripcion: 'Mineral de hierro' },
    { Clave: '11101602', Descripcion: 'Mineral de titanio' },
    { Clave: '11101603', Descripcion: 'Mineral de uranio' },
    { Clave: '11101604', Descripcion: 'Mineral de cobre' },
    { Clave: '11101605', Descripcion: 'Mineral de aluminio' },
    { Clave: '11101606', Descripcion: 'Mineral de níquel' },
    { Clave: '11101607', Descripcion: 'Mineral de plata' },
    { Clave: '11101608', Descripcion: 'Mineral de plomo' },
    { Clave: '11101609', Descripcion: 'Mineral de zinc' },
    { Clave: '11101610', Descripcion: 'Mineral de estaño' },
    { Clave: '11101611', Descripcion: 'Mineral de manganeso' },
    { Clave: '11101612', Descripcion: 'Mineral de cromo' },
    { Clave: '11101613', Descripcion: 'Mineral de tungsteno o wolframio' },
    { Clave: '11101614', Descripcion: 'Mineral de molibdeno' },
    { Clave: '11101615', Descripcion: 'Mineral de cobalto' },
    { Clave: '11101616', Descripcion: 'Mineral de oro' },
    { Clave: '11101617', Descripcion: 'Mineral de tántalo' },
    { Clave: '11101618', Descripcion: 'Mineral de platino' },
    { Clave: '11101619', Descripcion: 'Mineral de vermiculita' },
    { Clave: '11101620', Descripcion: 'Mineral de torio' },
    { Clave: '11101621', Descripcion: 'Mineral de cianita' },
    { Clave: '11101622', Descripcion: 'Mineral de antimonio' },
    { Clave: '11101623', Descripcion: 'Mineral de zirconio' },
    { Clave: '11101624', Descripcion: 'Mineral de magnetita' },
    { Clave: '11101625', Descripcion: 'Mineral de bauxita' },
    { Clave: '11101700', Descripcion: 'Metales de Base' },
    { Clave: '11101701', Descripcion: 'Escoria o ceniza' },
    { Clave: '11101702', Descripcion: 'Grafito natural' },
    { Clave: '11101704', Descripcion: 'Acero' },
    { Clave: '11101705', Descripcion: 'Aluminio' },
    { Clave: '11101706', Descripcion: 'Níquel' },
    { Clave: '11101708', Descripcion: 'Bismuto' },
    { Clave: '11101709', Descripcion: 'Antimonio' },
    { Clave: '11101710', Descripcion: 'Cadmio' },
    { Clave: '11101711', Descripcion: 'Aleación no ferrosa' },
    { Clave: '11101712', Descripcion: 'Aleación ferrosa' },
    { Clave: '11101713', Descripcion: 'Hierro' },
    { Clave: '11101714', Descripcion: 'Plomo' },
    { Clave: '11101716', Descripcion: 'Estaño' },
    { Clave: '11101717', Descripcion: 'Indio' },
    { Clave: '11101718', Descripcion: 'Paladio' },
    { Clave: '11101719', Descripcion: 'Zinc' },
    { Clave: '11101800', Descripcion: 'Metales preciosos' },
    { Clave: '11101801', Descripcion: 'Oro' },
    { Clave: '11101802', Descripcion: 'Plata' },
    { Clave: '11101803', Descripcion: 'Platino' },
    { Clave: '11101900', Descripcion: 'Productos minerales intermedios, procesados y refinados' },
    { Clave: '11101901', Descripcion: 'Concentrado de mineral de hierro' },
    { Clave: '11101902', Descripcion: 'Concentrado de níquel' },
    { Clave: '11101903', Descripcion: 'Briqueta o compacto de níquel' },
    { Clave: '11101904', Descripcion: 'Mate de níquel' },
    { Clave: '11101905', Descripcion: 'Aluminio liquido' },
    { Clave: '11101906', Descripcion: 'Concentrado de cobre' },
    { Clave: '11101907', Descripcion: 'Cátodo de cobre' },
    { Clave: '11101908', Descripcion: 'Sínter o perdigones de manganeso' },
    { Clave: '11111500', Descripcion: 'Barro y Tierra' },
    { Clave: '11111501', Descripcion: 'Tierra' },
    { Clave: '11111502', Descripcion: 'Material de relleno' },
    { Clave: '11111503', Descripcion: 'Capa vegetal' },
    { Clave: '11111504', Descripcion: 'Tierra de cristal' },
    { Clave: '11111505', Descripcion: 'Suelo artificial' },
    { Clave: '11111600', Descripcion: 'Piedra' },
    { Clave: '11111601', Descripcion: 'Yeso' },
    { Clave: '11111602', Descripcion: 'Travertino' },
    { Clave: '11111603', Descripcion: 'Alabastro' },
    { Clave: '11111604', Descripcion: 'Granito' },
    { Clave: '11111605', Descripcion: 'Mármol' },
    { Clave: '11111606', Descripcion: 'Pizarra' },
    { Clave: '11111607', Descripcion: 'Arenisca' },
    { Clave: '11111608', Descripcion: 'Caliza' },
    { Clave: '11111609', Descripcion: 'Basalto' },
    { Clave: '11111610', Descripcion: 'Piedra pómez' },
    { Clave: '11111611', Descripcion: 'Gravilla' },
    { Clave: '11111612', Descripcion: 'Caliza en polvo' },
    { Clave: '11111613', Descripcion: 'Wollastonita' },
    { Clave: '11111700', Descripcion: 'Arena' },
    { Clave: '11111701', Descripcion: 'Arena de sílice' },
    { Clave: '11111800', Descripcion: 'Arcillas' },
    { Clave: '11111801', Descripcion: 'Terracota' },
    { Clave: '11111802', Descripcion: 'Arcilla refractaria' },
    { Clave: '11111803', Descripcion: 'Caolín u otras arcillas caolínicas' },
    { Clave: '11111804', Descripcion: 'Bentonita' },
    { Clave: '11111805', Descripcion: 'Andalucita' },
    { Clave: '11111806', Descripcion: 'Mullita' },
    { Clave: '11111807', Descripcion: 'Chamota' },
    { Clave: '11111808', Descripcion: 'Arcilla común' },
    { Clave: '11111809', Descripcion: 'Arcilla de bola' },
    { Clave: '11111810', Descripcion: 'Tierra de fuller' },
    { Clave: '11111811', Descripcion: 'Haydita' },
    { Clave: '11111812', Descripcion: 'Vermiculita' },
    { Clave: '11121500', Descripcion: 'Savia' },
    { Clave: '11121502', Descripcion: 'Resina' },
    { Clave: '11121503', Descripcion: 'Laca' },
    { Clave: '11121600', Descripcion: 'Madera' },
    { Clave: '11121603', Descripcion: 'Troncos' },
    { Clave: '11121604', Descripcion: 'Madera blanda' },
    { Clave: '11121605', Descripcion: 'Ratán' },
    { Clave: '11121606', Descripcion: 'Corcho' },
    { Clave: '11121607', Descripcion: 'Pulpa de madera' },
    { Clave: '11121608', Descripcion: 'Bambú' },
    { Clave: '11121610', Descripcion: 'Maderas duras' },
    { Clave: '11121612', Descripcion: 'Médula de madera' },
    { Clave: '11121614', Descripcion: 'Madera de teca' },
    { Clave: '11121615', Descripcion: 'Madera de álamo' },
    { Clave: '11121616', Descripcion: 'Madera de pino' },
    { Clave: '11121617', Descripcion: 'Madera de roble' },
    { Clave: '11121618', Descripcion: 'Madera de arce' },
    { Clave: '11121619', Descripcion: 'Madera de cerezo' },
    { Clave: '11121620', Descripcion: 'Madera de tornillo' },
    { Clave: '11121700', Descripcion: 'Productos secundarios de la explotación forestal' },
    { Clave: '11121701', Descripcion: 'Aserrín' },
    { Clave: '11121702', Descripcion: 'Astillas de madera' },
    { Clave: '11121703', Descripcion: 'Musgo de turba' },
    { Clave: '11121705', Descripcion: 'Corteza' },
    { Clave: '11121706', Descripcion: 'Composta de madera' },
    { Clave: '11121707', Descripcion: 'Viruta de madera' },
    { Clave: '11121708', Descripcion: 'Harina de madera' },
    { Clave: '11121709', Descripcion: 'Bálsamos' },
    { Clave: '11121710', Descripcion: 'Brea de madera' },
    { Clave: '11121800', Descripcion: 'Fibras de plantas' },
    { Clave: '11121801', Descripcion: 'Cáñamo' },
    { Clave: '11121802', Descripcion: 'Algodón' },
    { Clave: '11121803', Descripcion: 'Lino' },
    { Clave: '11121804', Descripcion: 'Yute' },
    { Clave: '11121805', Descripcion: 'Sisal' },
    { Clave: '11121806', Descripcion: 'Fibra de coco' },
    { Clave: '11121807', Descripcion: 'Fibras de abacá' },
    { Clave: '11121808', Descripcion: 'Ramio' },
    { Clave: '11121809', Descripcion: 'Paja' },
    { Clave: '11121810', Descripcion: 'Mijo común o ixtle o piasava' },
    { Clave: '11121900', Descripcion: 'Productos de perfumería' },
    { Clave: '11121901', Descripcion: 'Raíz de regaliz' },
    { Clave: '11122000', Descripcion: 'Productos de madera diseñados' },
    { Clave: '11122001', Descripcion: 'Triplex' },
    { Clave: '11122002', Descripcion: 'Tablex' },
    { Clave: '11122003', Descripcion: 'Corrugado' },
    { Clave: '11122004', Descripcion: 'Enchapes de madera' },
    { Clave: '11122005', Descripcion: 'Madera laminada engomada' },
    { Clave: '11122006', Descripcion: 'Madera tratada' },
    { Clave: '11131500', Descripcion: 'Cuero, pieles o materiales textiles de animales' },
    { Clave: '11131501', Descripcion: 'Plumas' },
    { Clave: '11131502', Descripcion: 'Pieles' },
    { Clave: '11131503', Descripcion: 'Pelo animal' },
    { Clave: '11131504', Descripcion: 'Cueros' },
    { Clave: '11131505', Descripcion: 'Mohair sin procesar' },
    { Clave: '11131506', Descripcion: 'Lana sin procesar' },
    { Clave: '11131507', Descripcion: 'Gamuza' },
    { Clave: '11131508', Descripcion: 'Cuero de grano completo' },
    { Clave: '11131600', Descripcion: 'Otros productos de animales' },
    { Clave: '11131601', Descripcion: 'Marfil' },
    { Clave: '11131602', Descripcion: 'Semen' },
    { Clave: '11131603', Descripcion: 'Excreciones' },
    { Clave: '11131604', Descripcion: 'Huevas de pescado' },
    { Clave: '11131605', Descripcion: 'Huesos' },
    { Clave: '11131606', Descripcion: 'Cuernos de animales' },
    { Clave: '11131607', Descripcion: 'Embriones' },
    { Clave: '11131608', Descripcion: 'Artículos de concha' },
    { Clave: '11141600', Descripcion: 'Desechos no metálicos y chatarra' },
    { Clave: '11141601', Descripcion: 'Desecho o desperdicio textil' },
    { Clave: '11141602', Descripcion: 'Desecho o desperdicio plástico' },
    { Clave: '11141603', Descripcion: 'Desechos de aceite' },
    { Clave: '11141604', Descripcion: 'Desechos de papel' },
    { Clave: '11141605', Descripcion: 'Desecho o desperdicios de vidrio' },
    { Clave: '11141606', Descripcion: 'Desecho o desperdicios de madera' },
    { Clave: '11141607', Descripcion: 'Desecho o desperdicios de caucho' },
    { Clave: '11141608', Descripcion: 'Desecho o desperdicios peligrosos' },
    { Clave: '11141609', Descripcion: 'Composición de cuero' },
    { Clave: '11141610', Descripcion: 'Desecho o desperdicios de cuero' },
    { Clave: '11141700', Descripcion: 'Desperdicios o restos de alimentos y tabaco' },
    { Clave: '11141701', Descripcion: 'Desecho o desperdicios de alimentos' },
    { Clave: '11141702', Descripcion: 'Desecho o desperdicios de tabaco' },
    { Clave: '11151500', Descripcion: 'Fibras' },
    { Clave: '11151501', Descripcion: 'Fibras de acetato' },
    { Clave: '11151502', Descripcion: 'Fibras de nylon' },
    { Clave: '11151503', Descripcion: 'Fibras de poliéster' },
    { Clave: '11151504', Descripcion: 'Fibras de acrílico' },
    { Clave: '11151505', Descripcion: 'Fibras de viscosas' },
    { Clave: '11151506', Descripcion: 'Fibras de rayón' },
    { Clave: '11151507', Descripcion: 'Fibras de algodón' },
    { Clave: '11151508', Descripcion: 'Fibras de lana' },
    { Clave: '11151509', Descripcion: 'Fibras de seda' },
    { Clave: '11151510', Descripcion: 'Fibras vegetales' },
    { Clave: '11151511', Descripcion: 'Fibras de polipropileno' },
    { Clave: '11151512', Descripcion: 'Fibras de vidrio' },
    { Clave: '11151513', Descripcion: 'Fibras cerámicas' },
    { Clave: '11151514', Descripcion: 'Fibras de polímero de aramida' },
    { Clave: '11151515', Descripcion: 'Fibras de asbesto' },
    { Clave: '11151516', Descripcion: 'Fibras de poliuretano o spándex' },
    { Clave: '11151517', Descripcion: 'Fibras de alcohol polivinilo' },
    { Clave: '11151518', Descripcion: 'Fibras de polietileno' },
    { Clave: '11151519', Descripcion: 'Fibras de lana de roca o lana mineral' },
    { Clave: '11151600', Descripcion: 'Hebras' },
    { Clave: '11151601', Descripcion: 'Hebra de algodón' },
    { Clave: '11151602', Descripcion: 'Hebra de seda' },
    { Clave: '11151603', Descripcion: 'Hebra de poliéster' },
    { Clave: '11151604', Descripcion: 'Hebra de poliamida' },
    { Clave: '11151605', Descripcion: 'Hebra de bismaleimida' },
    { Clave: '11151606', Descripcion: 'Hebra de fibra de vidrio' },
    { Clave: '11151607', Descripcion: 'Hebra de grafito' },
    { Clave: '11151608', Descripcion: 'Hebra de nylon' },
    { Clave: '11151609', Descripcion: 'Hebra de resina impregnada' },
    { Clave: '11151610', Descripcion: 'Hebra de caucho o látex' },
    { Clave: '11151611', Descripcion: 'Hebra de spandex' },
    { Clave: '11151612', Descripcion: 'Hebra de asbesto' },
    { Clave: '11151700', Descripcion: 'Hilos' },
    { Clave: '11151701', Descripcion: 'Hilado de lana' },
    { Clave: '11151702', Descripcion: 'Hilado de algodón' },
    { Clave: '11151703', Descripcion: 'Hilado de poliéster' },
    { Clave: '11151704', Descripcion: 'Hilado de acrílico' },
    { Clave: '11151705', Descripcion: 'Hilado de seda' },
    { Clave: '11151706', Descripcion: 'Hilado de ramie' },
    { Clave: '11151708', Descripcion: 'Hilado de pelo animal' },
    { Clave: '11151709', Descripcion: 'Hilado de sintético' },
    { Clave: '11151710', Descripcion: 'Hilado de yute' },
    { Clave: '11151711', Descripcion: 'Hilado de fibra de coco' },
    { Clave: '11151712', Descripcion: 'Hilado de papel' },
    { Clave: '11151713', Descripcion: 'Hilado de cáñamo' },
    { Clave: '11151714', Descripcion: 'Hilado de vidrio' },
    { Clave: '11151715', Descripcion: 'Hilado de lino' },
    { Clave: '11151716', Descripcion: 'Hilado mezclado' },
    { Clave: '11161500', Descripcion: 'Tejidos de seda' },
    { Clave: '11161501', Descripcion: 'Textiles de seda de tejido simple' },
    { Clave: '11161502', Descripcion: 'Textiles de seda de tejido  jacquard' },
    { Clave: '11161503', Descripcion: 'Textiles de lana' },
    { Clave: '11161504', Descripcion: 'Textiles de tejido de pana' },
    { Clave: '11161600', Descripcion: 'Tejidos de lana' },
    { Clave: '11161601', Descripcion: 'Tejidos de lana en tafetán' },
    { Clave: '11161602', Descripcion: 'Tejidos de lana urdidos a la jacquard' },
    { Clave: '11161603', Descripcion: 'Tejidos de lana de punto' },
    { Clave: '11161604', Descripcion: 'Tejidos de lana de ligamento de sarga' },
    { Clave: '11161700', Descripcion: 'Tejidos de algodón' },
    { Clave: '11161701', Descripcion: 'Textiles de algodón de tejido simple' },
    { Clave: '11161702', Descripcion: 'Textiles de algodón de tejido de pana' },
    { Clave: '11161703', Descripcion: 'Tela de algodón oxford' },
    { Clave: '11161704', Descripcion: 'Textiles de algodón tejido' },
    { Clave: '11161705', Descripcion: 'Textiles de terciopelo de algodón' },
    { Clave: '11161800', Descripcion: 'Tejidos sintéticos' },
    { Clave: '11161801', Descripcion: 'Textil sintético de tejido sintético' },
    { Clave: '11161802', Descripcion: 'Textil sintético de tejido jacquard' },
    { Clave: '11161803', Descripcion: 'Textil sintético de tejido dobby' },
    { Clave: '11161804', Descripcion: 'Textil sintético tejido' },
    { Clave: '11161805', Descripcion: 'Textil sintético de terciopelo' },
    { Clave: '11161806', Descripcion: 'Textil sintético de tejido de pana' },
    { Clave: '11161807', Descripcion: 'Textil sintético de felpa' },
    { Clave: '11162000', Descripcion: 'Tejidos de materiales vegetales no de algodón' },
    { Clave: '11162001', Descripcion: 'Textil de fibra vegetal tejida distinta de algodón simple' },
    { Clave: '11162002', Descripcion: 'Textil de fibra vegetal tejida distinta de algodón' },
    { Clave: '11162003', Descripcion: 'Tela de arpillera o cáñamo o yute' },
    { Clave: '11162100', Descripcion: 'Tejidos o telas especiales' },
    { Clave: '11162101', Descripcion: 'Tela o textil de queso' },
    { Clave: '11162102', Descripcion: 'Tela o textil de bismaleimida' },
    { Clave: '11162104', Descripcion: 'Tela o textil de grafito' },
    { Clave: '11162105', Descripcion: 'Tela o textil de vidrio' },
    { Clave: '11162107', Descripcion: 'Tela de resina impregnada' },
    { Clave: '11162108', Descripcion: 'Tela malla de alambre' },
    { Clave: '11162109', Descripcion: 'Encaje' },
    { Clave: '11162110', Descripcion: 'Malla red' },
    { Clave: '11162111', Descripcion: 'Malla' },
    { Clave: '11162112', Descripcion: 'Telas revestidas' },
    { Clave: '11162113', Descripcion: 'Telas para tapicería' },
    { Clave: '11162114', Descripcion: 'Telas o cintas de velcro' },
    { Clave: '11162115', Descripcion: 'Trenza elástica' },
    { Clave: '11162116', Descripcion: 'Tela de fique o estopa' },
    { Clave: '11162117', Descripcion: 'Tela de caucho' },
    { Clave: '11162118', Descripcion: 'Tela de hilado de papel' },
    { Clave: '11162119', Descripcion: 'Tela de calcado' },
    { Clave: '11162120', Descripcion: 'Tela de cernido' },
    { Clave: '11162121', Descripcion: 'Artículos de pasamanería y ornamentales' },
    { Clave: '11162122', Descripcion: 'Tela de sujeción' },
    { Clave: '11162123', Descripcion: 'Tela de cinta' },
    { Clave: '11162124', Descripcion: 'Tela de fieltro' },
    { Clave: '11162125', Descripcion: 'Tela de correas' },
    { Clave: '11162126', Descripcion: 'Tela acolchada' },
    { Clave: '11162127', Descripcion: 'Tela de camuflaje' },
    { Clave: '11162128', Descripcion: 'Tela de paracaídas' },
    { Clave: '11162129', Descripcion: 'Tela marquisette' },
    { Clave: '11162130', Descripcion: 'Tela dosel' },
    { Clave: '11162131', Descripcion: 'Tela para ribeteado' },
    { Clave: '11162132', Descripcion: 'Tela de damasco' },
    { Clave: '11162133', Descripcion: 'Tela de satín' },
    { Clave: '11162200', Descripcion: 'Telas no tejidas' },
    { Clave: '11162201', Descripcion: 'Telas no tejidas ligadas térmicamente' },
    { Clave: '11162202', Descripcion: 'Telas no tejidas hidroentrelazadas' },
    { Clave: '11162300', Descripcion: 'Cueros' },
    { Clave: '11162301', Descripcion: 'Cuero de gamuza' },
    { Clave: '11162302', Descripcion: 'Cuero de cabra' },
    { Clave: '11162303', Descripcion: 'Cuero de oveja' },
    { Clave: '11162304', Descripcion: 'Cuero acharolado' },
    { Clave: '11162305', Descripcion: 'Cuero de vaca' },
    { Clave: '11162306', Descripcion: 'Cuero de cerdo' },
    { Clave: '11162307', Descripcion: 'Cuero sintético o de imitación' },
    { Clave: '11162308', Descripcion: 'Cuero de búfalo' },
    { Clave: '11162309', Descripcion: 'Cuero de reptil' },
    { Clave: '11162310', Descripcion: 'Cuero de caballo' },
    { Clave: '11162311', Descripcion: 'Cuero de becerro' },
    { Clave: '11162400', Descripcion: 'Guata' },
    { Clave: '11162401', Descripcion: 'Guata de algodón' },
    { Clave: '11162402', Descripcion: 'Guata sintética' },
    { Clave: '11171500', Descripcion: 'Aceros básicos' },
    { Clave: '11171501', Descripcion: 'Acero e24-2 o a37-2' },
    { Clave: '11171600', Descripcion: 'Aleaciones de acero inoxidable' },
    { Clave: '11171601', Descripcion: 'Aleación de acero inoxidable 304' },
    { Clave: '11171602', Descripcion: 'Aleación de acero inoxidable 304i' },
    { Clave: '11171603', Descripcion: 'Aleación de acero inoxidable 316' },
    { Clave: '11171604', Descripcion: 'Aleación de ferroníquel' },
    { Clave: '11171700', Descripcion: 'Aceros rápidos' },
    { Clave: '11171701', Descripcion: 'Acero de alta velocidad z90wdcv6542 o m2' },
    { Clave: '11171702', Descripcion: 'Acero de alta velocidad z90wdkcv65542 or m35' },
    { Clave: '11171800', Descripcion: 'Superaleaciones de níquel' },
    { Clave: '11171801', Descripcion: 'Súper aleación inconel 600' },
    { Clave: '11171900', Descripcion: 'Superaleaciones de titanio' },
    { Clave: '11171901', Descripcion: 'Súper aleación ta6v' },
    { Clave: '11172000', Descripcion: 'Aleaciones de aluminio' },
    { Clave: '11172001', Descripcion: 'Aleación de aluminio 7178' },
    { Clave: '11172002', Descripcion: 'Aluminio refundido' },
    { Clave: '11172003', Descripcion: 'Aleación de hierro y aluminio' },
    { Clave: '11172100', Descripcion: 'Superaleaciones de cobalto' },
    { Clave: '11172101', Descripcion: 'Aleación pigmalión ú 846' },
    { Clave: '11172200', Descripcion: 'Aleaciones con base de magnesio' },
    { Clave: '11172201', Descripcion: 'Aleación de aluminio y manganeso' },
    { Clave: '11172300', Descripcion: 'Aleaciones con base de manganeso' },
    { Clave: '11172301', Descripcion: 'Aleación de ferro manganeso' },
    { Clave: '11172302', Descripcion: 'Aleación de ferro manganeso carbono medio' },
    { Clave: '11172303', Descripcion: 'Aleación de silicona manganeso' },
    { Clave: '11172304', Descripcion: 'Aleación de ferro manganeso carbono bajo' },
    { Clave: '11181500', Descripcion: 'Óxidos metálicos no férreos' },
    { Clave: '11181501', Descripcion: 'Óxido de molibdeno' },
    { Clave: '11181502', Descripcion: 'Óxido de titanio' },
    { Clave: '11181503', Descripcion: 'Óxido de indio' },
    { Clave: '11181504', Descripcion: 'Óxido de estaño' },
    { Clave: '11181505', Descripcion: 'Arcilla de sellado' },
    { Clave: '11181506', Descripcion: 'Óxido de vanadio' },
    { Clave: '11181507', Descripcion: 'Óxido de níquel' },
    { Clave: '11181508', Descripcion: 'Óxido de manganeso' },
    { Clave: '11191500', Descripcion: 'Cuerpos sólidos de metal' },
    { Clave: '11191501', Descripcion: 'Sólidos de níquel' },
    { Clave: '11191502', Descripcion: 'Sólidos de acero básico' },
    { Clave: '11191503', Descripcion: 'Sólidos de aleación ferrosa' },
    { Clave: '11191504', Descripcion: 'Sólidos de aleación no ferrosa' },
    { Clave: '11191505', Descripcion: 'Sólidos de súper aleación' },
    { Clave: '11191600', Descripcion: 'Chatarra' },
    { Clave: '11191601', Descripcion: 'Chatarra de níquel' },
    { Clave: '11191602', Descripcion: 'Chatarra de acero básico' },
    { Clave: '11191603', Descripcion: 'Chatarra de aleación ferrosa' },
    { Clave: '11191604', Descripcion: 'Chatarra de aleación no ferrosa' },
    { Clave: '11191605', Descripcion: 'Chatarra de súper aleación' },
    { Clave: '11191606', Descripcion: 'Destrozos automovilísticos para chatarra o desecho' },
    { Clave: '11191607', Descripcion: 'Chatarra de cobre' },
    { Clave: '11191608', Descripcion: 'Chatarra de plomo' },
    { Clave: '11191609', Descripcion: 'Chatarra de zinc' },
    { Clave: '11191610', Descripcion: 'Chatarra de aluminio' },
    { Clave: '11191611', Descripcion: 'Chatarra de estaño' },
    { Clave: '11191700', Descripcion: 'Virutas' },
    { Clave: '11191701', Descripcion: 'Torneados (virutas) de níquel' },
    { Clave: '11191702', Descripcion: 'Torneados (virutas) de bronce' },
    { Clave: '11191800', Descripcion: 'Subproductos del metal' },
    { Clave: '11191801', Descripcion: 'Escoria de manganeso' },
    { Clave: '11191802', Descripcion: 'Sulfuro de cobre' },
    { Clave: '11191803', Descripcion: 'Sulfuro de níquel' },
    { Clave: '12131500', Descripcion: 'Explosivos' },
    { Clave: '12131501', Descripcion: 'Dinamita' },
    { Clave: '12131502', Descripcion: 'Cartuchos explosivos' },
    { Clave: '12131503', Descripcion: 'Explosivos propelentes' },
    { Clave: '12131504', Descripcion: 'Cargas explosivas' },
    { Clave: '12131505', Descripcion: 'Explosivos plásticos' },
    { Clave: '12131506', Descripcion: 'Explosivos aluminizados' },
    { Clave: '12131507', Descripcion: 'Explosivos de nitrato de amonio' },
    { Clave: '12131508', Descripcion: 'Explosivos de polvo de nitroglicerina' },
    { Clave: '12131509', Descripcion: 'Nitrato de amonio y fuel oil - anfo' },
    { Clave: '12131600', Descripcion: 'Pirotecnia' },
    { Clave: '12131601', Descripcion: 'Fuegos artificiales' },
    { Clave: '12131602', Descripcion: 'Señales de niebla' },
    { Clave: '12131603', Descripcion: 'Cohetes de lluvia' },
    { Clave: '12131604', Descripcion: 'Antorchas' },
    { Clave: '12131605', Descripcion: 'Materiales pirotécnicos para el teatro o la televisión' },
    { Clave: '12131700', Descripcion: 'Encendedores' },
    { Clave: '12131701', Descripcion: 'Estopines' },
    { Clave: '12131702', Descripcion: 'Detonadores' },
    { Clave: '12131703', Descripcion: 'Mechas explosivas' },
    { Clave: '12131704', Descripcion: 'Iniciadores explosivos' },
    { Clave: '12131705', Descripcion: 'Fulminantes explosivos' },
    { Clave: '12131706', Descripcion: 'Fósforos' },
    { Clave: '12131707', Descripcion: 'Encendedores' },
    { Clave: '12131708', Descripcion: 'Caja detonadora' },
    { Clave: '12131709', Descripcion: 'Apisonadores de explosivos' },
    { Clave: '12131800', Descripcion: 'Propulsores' },
    { Clave: '12131801', Descripcion: 'Propelente en polvo' },
    { Clave: '12131802', Descripcion: 'Propelente sólido' },
    { Clave: '12131803', Descripcion: 'Propelente de pistola' },
    { Clave: '12131804', Descripcion: 'Propelente de alta energía' },
    { Clave: '12131805', Descripcion: 'Propelente en gel' },
    { Clave: '12131806', Descripcion: 'Propelente híbridos' },
    { Clave: '12141500', Descripcion: 'Metales de tierra' },
    { Clave: '12141501', Descripcion: 'Berilio be' },
    { Clave: '12141502', Descripcion: 'Magnesio mg' },
    { Clave: '12141503', Descripcion: 'Calcio ca' },
    { Clave: '12141504', Descripcion: 'Estroncio sr' },
    { Clave: '12141505', Descripcion: 'Bario ba' },
    { Clave: '12141506', Descripcion: 'Radio ra' },
    { Clave: '12141600', Descripcion: 'Metales de tierra rara' },
    { Clave: '12141601', Descripcion: 'Cerio ce' },
    { Clave: '12141602', Descripcion: 'Disprosio dy' },
    { Clave: '12141603', Descripcion: 'Erbio er' },
    { Clave: '12141604', Descripcion: 'Europio eu' },
    { Clave: '12141605', Descripcion: 'Gadolinio gd' },
    { Clave: '12141606', Descripcion: 'Holmio ho' },
    { Clave: '12141607', Descripcion: 'Lantano la' },
    { Clave: '12141608', Descripcion: 'Lutecio lu' },
    { Clave: '12141609', Descripcion: 'Neodimio nd' },
    { Clave: '12141610', Descripcion: 'Praseodimio pr' },
    { Clave: '12141611', Descripcion: 'Promecio pm' },
    { Clave: '12141612', Descripcion: 'Samario sm' },
    { Clave: '12141613', Descripcion: 'Escandio sc' },
    { Clave: '12141614', Descripcion: 'Terbio tb' },
    { Clave: '12141615', Descripcion: 'Tulio tm' },
    { Clave: '12141616', Descripcion: 'Iterbio yb' },
    { Clave: '12141617', Descripcion: 'Itrio y' },
    { Clave: '12141700', Descripcion: 'Metales de transición' },
    { Clave: '12141701', Descripcion: 'Actinio ac' },
    { Clave: '12141702', Descripcion: 'Aluminio al' },
    { Clave: '12141703', Descripcion: 'Americio am' },
    { Clave: '12141704', Descripcion: 'Antimonio sb' },
    { Clave: '12141705', Descripcion: 'Berkelio bk' },
    { Clave: '12141706', Descripcion: 'Bismuto bi' },
    { Clave: '12141707', Descripcion: 'Cadmio ca' },
    { Clave: '12141708', Descripcion: 'Californio cf' },
    { Clave: '12141709', Descripcion: 'Cromo cr' },
    { Clave: '12141710', Descripcion: 'Cobalto co' },
    { Clave: '12141711', Descripcion: 'Cobre cu' },
    { Clave: '12141712', Descripcion: 'Curio cm' },
    { Clave: '12141713', Descripcion: 'Einstenio es' },
    { Clave: '12141714', Descripcion: 'Fermio fm' },
    { Clave: '12141715', Descripcion: 'Galio ga' },
    { Clave: '12141716', Descripcion: 'Germanio ge' },
    { Clave: '12141717', Descripcion: 'Oro au' },
    { Clave: '12141718', Descripcion: 'Hafnio hf' },
    { Clave: '12141719', Descripcion: 'Indio in' },
    { Clave: '12141720', Descripcion: 'Iridio ir' },
    { Clave: '12141721', Descripcion: 'Hierro fe' },
    { Clave: '12141722', Descripcion: 'Lawrencio lr' },
    { Clave: '12141723', Descripcion: 'Plomo pb' },
    { Clave: '12141724', Descripcion: 'Manganeso mn' },
    { Clave: '12141725', Descripcion: 'Mendelevio md' },
    { Clave: '12141726', Descripcion: 'Mercurio hg' },
    { Clave: '12141727', Descripcion: 'Molibdeno mo' },
    { Clave: '12141728', Descripcion: 'Neptunio np' },
    { Clave: '12141729', Descripcion: 'Níquel ni' },
    { Clave: '12141730', Descripcion: 'Niobio nb' },
    { Clave: '12141731', Descripcion: 'Nobelio no' },
    { Clave: '12141732', Descripcion: 'Osmio os' },
    { Clave: '12141733', Descripcion: 'Paladio pd' },
    { Clave: '12141734', Descripcion: 'Platino pyt' },
    { Clave: '12141735', Descripcion: 'Plutonio pu' },
    { Clave: '12141736', Descripcion: 'Protactinio pa' },
    { Clave: '12141737', Descripcion: 'Renio re' },
    { Clave: '12141738', Descripcion: 'Rodio rh' },
    { Clave: '12141739', Descripcion: 'Rutenio ru' },
    { Clave: '12141740', Descripcion: 'Plata ag' },
    { Clave: '12141741', Descripcion: 'Tántalo ta' },
    { Clave: '12141742', Descripcion: 'Tecnecio te' },
    { Clave: '12141743', Descripcion: 'Talio tl' },
    { Clave: '12141744', Descripcion: 'Torio th' },
    { Clave: '12141745', Descripcion: 'Estaño sn' },
    { Clave: '12141746', Descripcion: 'Titanio ti' },
    { Clave: '12141747', Descripcion: 'Tungsteno w' },
    { Clave: '12141748', Descripcion: 'Uranio u' },
    { Clave: '12141749', Descripcion: 'Vanadio v' },
    { Clave: '12141750', Descripcion: 'Zinc zn' },
    { Clave: '12141751', Descripcion: 'Zirconio zr' },
    { Clave: '12141752', Descripcion: 'Bohrio bh' },
    { Clave: '12141753', Descripcion: 'Dubnio db' },
    { Clave: '12141754', Descripcion: 'Hassio hs' },
    { Clave: '12141755', Descripcion: 'Rutherfordio rf' },
    { Clave: '12141756', Descripcion: 'Seaborgio sg' },
    { Clave: '12141757', Descripcion: 'Ununnilium uum' },
    { Clave: '12141758', Descripcion: 'Unununium uuu' },
    { Clave: '12141759', Descripcion: 'Ununbium uub' },
    { Clave: '12141760', Descripcion: 'Polonio po' },
    { Clave: '12141800', Descripcion: 'Metales álcalis' },
    { Clave: '12141801', Descripcion: 'Cesio cs' },
    { Clave: '12141802', Descripcion: 'Francio fm' },
    { Clave: '12141803', Descripcion: 'Litio li' },
    { Clave: '12141804', Descripcion: 'Potasio k' },
    { Clave: '12141805', Descripcion: 'Rubidio rb' },
    { Clave: '12141806', Descripcion: 'Sodio na' },
    { Clave: '12141900', Descripcion: 'No-metales y gases elementales y puros' },
    { Clave: '12141901', Descripcion: 'Cloro cl' },
    { Clave: '12141902', Descripcion: 'Hidrógeno h' },
    { Clave: '12141903', Descripcion: 'Nitrógeno n' },
    { Clave: '12141904', Descripcion: 'Oxígeno o' },
    { Clave: '12141905', Descripcion: 'Flúor f' },
    { Clave: '12141906', Descripcion: 'Arsénico as' },
    { Clave: '12141907', Descripcion: 'Boro b' },
    { Clave: '12141908', Descripcion: 'Carbono c' },
    { Clave: '12141909', Descripcion: 'Fósforo p' },
    { Clave: '12141910', Descripcion: 'Selenio se' },
    { Clave: '12141911', Descripcion: 'Silicona si' },
    { Clave: '12141912', Descripcion: 'Azufre s' },
    { Clave: '12141913', Descripcion: 'Telurio te' },
    { Clave: '12141914', Descripcion: 'Astato at' },
    { Clave: '12141915', Descripcion: 'Bromo br' },
    { Clave: '12141916', Descripcion: 'Yodo i' },
    { Clave: '12142000', Descripcion: 'Gases nobles' },
    { Clave: '12142001', Descripcion: 'Gas xenón xe' },
    { Clave: '12142002', Descripcion: 'Gas radón rn' },
    { Clave: '12142003', Descripcion: 'Gas criptón kr' },
    { Clave: '12142004', Descripcion: 'Gas argón ar' },
    { Clave: '12142005', Descripcion: 'Gas helio he' },
    { Clave: '12142006', Descripcion: 'Gas neón ne' },
    { Clave: '12142100', Descripcion: 'Gases naturales y gases mixtos' },
    { Clave: '12142101', Descripcion: 'Gases compuestos de hidrógeno' },
    { Clave: '12142102', Descripcion: 'Gases compuestos clorados' },
    { Clave: '12142103', Descripcion: 'Amoniaco' },
    { Clave: '12142104', Descripcion: 'Gas dióxido de carbono' },
    { Clave: '12142105', Descripcion: 'Aire industrial' },
    { Clave: '12142106', Descripcion: 'Mezclas de gas inerte' },
    { Clave: '12142107', Descripcion: 'Sulfato de hidrógeno' },
    { Clave: '12142108', Descripcion: 'Monóxido de carbono' },
    { Clave: '12142109', Descripcion: 'Hielo seco' },
    { Clave: '12142110', Descripcion: 'Amoniaco líquido' },
    { Clave: '12142200', Descripcion: 'Isótopos' },
    { Clave: '12142201', Descripcion: 'Disolvente deuterado' },
    { Clave: '12142202', Descripcion: 'Agua pesada' },
    { Clave: '12142203', Descripcion: 'Fuentes alfa' },
    { Clave: '12142204', Descripcion: 'Fuentes beta' },
    { Clave: '12142205', Descripcion: 'Fuentes de cobalto' },
    { Clave: '12142206', Descripcion: 'Fuentes gamma' },
    { Clave: '12142207', Descripcion: 'Fuentes radio ? isótopo' },
    { Clave: '12142208', Descripcion: 'Fuentes de calibración' },
    { Clave: '12161500', Descripcion: 'Indicadores y reactivos' },
    { Clave: '12161501', Descripcion: 'Etiquetas de afinidad' },
    { Clave: '12161502', Descripcion: 'Agentes de entrecruzamiento' },
    { Clave: '12161503', Descripcion: 'Kits de reactivos' },
    { Clave: '12161504', Descripcion: 'Reactivos sulfhidrilo' },
    { Clave: '12161505', Descripcion: 'Agentes de intercalación' },
    { Clave: '12161506', Descripcion: 'Agentes de desvío' },
    { Clave: '12161507', Descripcion: 'Reactivo cupferrón' },
    { Clave: '12161600', Descripcion: 'Catalizadores' },
    { Clave: '12161601', Descripcion: 'Catalizadores ácidos' },
    { Clave: '12161602', Descripcion: 'Catalizadores de combustión' },
    { Clave: '12161603', Descripcion: 'Catalizadores a la medida' },
    { Clave: '12161604', Descripcion: 'Catalizadores de ruptura' },
    { Clave: '12161605', Descripcion: 'Catalizadores fe tratamiento' },
    { Clave: '12161606', Descripcion: 'Catalizadores reformadores' },
    { Clave: '12161700', Descripcion: 'Amortiguadores' },
    { Clave: '12161701', Descripcion: 'Mezclas de anfólito' },
    { Clave: '12161702', Descripcion: 'Soluciones reguladoras de bicarbonato' },
    { Clave: '12161703', Descripcion: 'Otras soluciones reguladoras' },
    { Clave: '12161704', Descripcion: 'Soluciones reguladoras ácidas' },
    { Clave: '12161705', Descripcion: 'Soluciones reguladoras básicas' },
    { Clave: '12161706', Descripcion: 'Soluciones reguladoras neutrales' },
    { Clave: '12161800', Descripcion: 'Coloides' },
    { Clave: '12161801', Descripcion: 'Geles' },
    { Clave: '12161802', Descripcion: 'Suspensiones' },
    { Clave: '12161803', Descripcion: 'Aerosoles' },
    { Clave: '12161804', Descripcion: 'Emulsiones' },
    { Clave: '12161805', Descripcion: 'Agentes gelificantes naturales' },
    { Clave: '12161806', Descripcion: 'Agentes gelificantes sintéticos' },
    { Clave: '12161807', Descripcion: 'Estabilizadores de gel' },
    { Clave: '12161808', Descripcion: 'Agentes de suspensión' },
    { Clave: '12161809', Descripcion: 'Gel de silicona' },
    { Clave: '12161900', Descripcion: 'Surfactantes' },
    { Clave: '12161901', Descripcion: 'Agentes anti ? espuma' },
    { Clave: '12161902', Descripcion: 'Surfactantes detergentes' },
    { Clave: '12161903', Descripcion: 'Agentes de espuma' },
    { Clave: '12161904', Descripcion: 'Agentes de dispersión' },
    { Clave: '12161905', Descripcion: 'Enjuagues' },
    { Clave: '12161906', Descripcion: 'Agentes de humectación' },
    { Clave: '12161907', Descripcion: 'Aditivos de inundación de agua' },
    { Clave: '12161908', Descripcion: 'Alkil sulfatos' },
    { Clave: '12161909', Descripcion: 'Betaínas' },
    { Clave: '12161910', Descripcion: 'Sulfatos de éter' },
    { Clave: '12161911', Descripcion: 'Cuaternarios' },
    { Clave: '12161912', Descripcion: 'Sultaína' },
    { Clave: '12161913', Descripcion: 'Coadyuvante de rociado' },
    { Clave: '12162000', Descripcion: 'Plastificantes' },
    { Clave: '12162002', Descripcion: 'Poliméricos' },
    { Clave: '12162003', Descripcion: 'Aceites agrícolas' },
    { Clave: '12162004', Descripcion: 'Sulfonamidas' },
    { Clave: '12162005', Descripcion: 'Glutaratos' },
    { Clave: '12162006', Descripcion: 'Plastificante ester aromático' },
    { Clave: '12162100', Descripcion: 'Retardadores de llama' },
    { Clave: '12162101', Descripcion: 'Retardantes brominados' },
    { Clave: '12162200', Descripcion: 'Anti-oxidantes' },
    { Clave: '12162201', Descripcion: 'Ácido ascórbico' },
    { Clave: '12162202', Descripcion: 'Beta caroteno' },
    { Clave: '12162203', Descripcion: 'Butilhidroxianisol' },
    { Clave: '12162204', Descripcion: 'Butilado hidroxitolueno' },
    { Clave: '12162205', Descripcion: 'Citrato de calcio' },
    { Clave: '12162206', Descripcion: 'Cantaxantina' },
    { Clave: '12162207', Descripcion: 'Melatonina' },
    { Clave: '12162208', Descripcion: 'Ácido nordihidroguaiarético' },
    { Clave: '12162209', Descripcion: 'Galato de propilo' },
    { Clave: '12162210', Descripcion: 'Silibinina' },
    { Clave: '12162211', Descripcion: 'Dióxido de azufre' },
    { Clave: '12162212', Descripcion: 'Ubicquinona o coenzima q10' },
    { Clave: '12162300', Descripcion: 'Agentes de curación' },
    { Clave: '12162301', Descripcion: 'Agentes de curado transmitidos por el agua' },
    { Clave: '12162302', Descripcion: 'Aceleradores de cemento' },
    { Clave: '12162303', Descripcion: 'Retardantes de cemento' },
    { Clave: '12162304', Descripcion: 'Acelerador adhesivo' },
    { Clave: '12162305', Descripcion: 'Aditivo de concreto' },
    { Clave: '12162400', Descripcion: 'Rompedores de polímero' },
    { Clave: '12162401', Descripcion: 'Rompedores de polímero ácido' },
    { Clave: '12162402', Descripcion: 'Rompedores de polímero orgánico' },
    { Clave: '12162500', Descripcion: 'Rompedores de emulsión' },
    { Clave: '12162501', Descripcion: 'Rompedores de emulsión agua en aceite' },
    { Clave: '12162502', Descripcion: 'Rompedores de emulsión aceite en agua' },
    { Clave: '12162503', Descripcion: 'Ayudas de flotación' },
    { Clave: '12162600', Descripcion: 'Estabilizadores de arcilla' },
    { Clave: '12162601', Descripcion: 'Estabilizadores de arcilla inorgánica' },
    { Clave: '12162602', Descripcion: 'Estabilizadores de arcilla orgánica' },
    { Clave: '12162700', Descripcion: 'Aditivos de pérdida de fluido' },
    { Clave: '12162701', Descripcion: 'Aditivos de pérdida de fluido de polímeros modificados' },
    { Clave: '12162702', Descripcion: 'Aditivos de pérdida de fluido de polímeros naturales' },
    { Clave: '12162800', Descripcion: 'Reductores de fricción' },
    { Clave: '12162801', Descripcion: 'Reductores de fricción aniónico' },
    { Clave: '12162802', Descripcion: 'Reductores de fricción catódica' },
    { Clave: '12162900', Descripcion: 'Agentes de control de parafina asfaltenos' },
    { Clave: '12162901', Descripcion: 'Agentes de control de asfaltenos de parafina tipo solvente' },
    { Clave: '12162902', Descripcion: 'Agentes de control de asfaltenos de parafina modificados cristal' },
    { Clave: '12162903', Descripcion: 'Agentes de control de asfaltenos de parafina tipo dispersante' },
    { Clave: '12163000', Descripcion: 'Mezclas para quitar barro' },
    { Clave: '12163001', Descripcion: 'Agentes de limpieza de lodo' },
    { Clave: '12163100', Descripcion: 'Anti lodo (sludger)' },
    { Clave: '12163101', Descripcion: 'Aditivos anti ? lodos' },
    { Clave: '12163102', Descripcion: 'Desfloculante' },
    { Clave: '12163200', Descripcion: 'Agentes de migración anti gas' },
    { Clave: '12163201', Descripcion: 'Aditivos de migración anti gas' },
    { Clave: '12163300', Descripcion: 'Agentes de expansión' },
    { Clave: '12163301', Descripcion: 'Agentes de expansión de cemento' },
    { Clave: '12163400', Descripcion: 'Extensores' },
    { Clave: '12163401', Descripcion: 'Extensores de cemento' },
    { Clave: '12163500', Descripcion: 'Sellador de pozo petrolífero' },
    { Clave: '12163501', Descripcion: 'Sellantes de cemento' },
    { Clave: '12163600', Descripcion: 'Inhibidores de corrosión' },
    { Clave: '12163601', Descripcion: 'Inhibidores de corrosión en la producción de petróleo' },
    { Clave: '12163602', Descripcion: 'Inhibidores de corrosión en la producción de gas' },
    { Clave: '12163700', Descripcion: 'Reguladores de hidrato de gas' },
    { Clave: '12163701', Descripcion: 'Controladores de hidrato de gas' },
    { Clave: '12163800', Descripcion: 'Limpiadores químicos' },
    { Clave: '12163801', Descripcion: 'Eliminadores de sulfuro de hidrógeno' },
    { Clave: '12163802', Descripcion: 'Eliminadores de oxígeno' },
    { Clave: '12163900', Descripcion: 'Reguladores de sarro' },
    { Clave: '12163901', Descripcion: 'Inhibidores de incrustaciones' },
    { Clave: '12163902', Descripcion: 'Removedores o convertidores de incrustaciones' },
    { Clave: '12164000', Descripcion: 'Bactericidas' },
    { Clave: '12164001', Descripcion: 'Microbicidas registrados' },
    { Clave: '12164100', Descripcion: 'In situ' },
    { Clave: '12164101', Descripcion: 'Aditivos in situ' },
    { Clave: '12164102', Descripcion: 'Aditivos ácidos' },
    { Clave: '12164200', Descripcion: 'Retardadores' },
    { Clave: '12164201', Descripcion: 'Inhibidores anti ? corrosión' },
    { Clave: '12164300', Descripcion: 'Controladores de hierro' },
    { Clave: '12164301', Descripcion: 'Aditivos de control de hierro' },
    { Clave: '12164302', Descripcion: 'Aditivos secuestradores de hierro' },
    { Clave: '12164303', Descripcion: 'Inhibidor de la corrosión de hierro' },
    { Clave: '12164400', Descripcion: 'No emulsificadoras' },
    { Clave: '12164401', Descripcion: 'Aditivos no emulsificantes' },
    { Clave: '12164500', Descripcion: 'Aditivos de alimentos y fármacos' },
    { Clave: '12164501', Descripcion: 'Preservativos de alimentos' },
    { Clave: '12164502', Descripcion: 'sabores o extractos' },
    { Clave: '12164503', Descripcion: 'Aditivos de fragancia' },
    { Clave: '12164504', Descripcion: 'Endulzantes' },
    { Clave: '12164505', Descripcion: 'Excipiente' },
    { Clave: '12164506', Descripcion: 'Aglutinante de tabletas' },
    { Clave: '12164507', Descripcion: 'Recubrimiento de tabletas' },
    { Clave: '12164508', Descripcion: 'Desintegrante' },
    { Clave: '12164509', Descripcion: 'Agente antiapelmazante' },
    { Clave: '12164600', Descripcion: 'Modificadores de papel, plástico y tela' },
    { Clave: '12164601', Descripcion: 'Agente de dimensionamiento' },
    { Clave: '12164602', Descripcion: 'Agente de espesamiento' },
    { Clave: '12164603', Descripcion: 'Abrillantador óptico' },
    { Clave: '12164604', Descripcion: 'Agente antiestática' },
    { Clave: '12164605', Descripcion: 'Micro ? floculante' },
    { Clave: '12164606', Descripcion: 'Estabilizador de luz y ultravioleta' },
    { Clave: '12164700', Descripcion: 'Antisépticos industriales' },
    { Clave: '12164701', Descripcion: 'Aditivo antiséptico' },
    { Clave: '12164800', Descripcion: 'Agentes reductores de agua' },
    { Clave: '12164801', Descripcion: 'Agente reductor de agua en cemento' },
    { Clave: '12164802', Descripcion: 'Agente detenedor o hinchador de agua' },
    { Clave: '12164803', Descripcion: 'Repelente estructural de agua' },
    { Clave: '12164900', Descripcion: 'Agentes de impermeabilización' },
    { Clave: '12164901', Descripcion: 'Recubrimiento impermeable de uretano' },
    { Clave: '12164902', Descripcion: 'Recubrimiento impermeable de asfalto' },
    { Clave: '12164903', Descripcion: 'Recubrimiento impermeable epoxi' },
    { Clave: '12164904', Descripcion: 'Recubrimiento impermeable inorgánico' },
    { Clave: '12164905', Descripcion: 'Agente de impermeabilización' },
    { Clave: '12165000', Descripcion: 'Agentes anti encogimiento' },
    { Clave: '12165001', Descripcion: 'Concreto anti ? contracción del cemento' },
    { Clave: '12165100', Descripcion: 'Químicos para mejorar la recuperación de petróleo' },
    { Clave: '12165101', Descripcion: 'Gel de polímero' },
    { Clave: '12165102', Descripcion: 'Surfactante dispersante' },
    { Clave: '12165103', Descripcion: 'Surfactante polímero álcali asp' },
    { Clave: '12165104', Descripcion: 'Polímero surfactante sp' },
    { Clave: '12171500', Descripcion: 'Tintes' },
    { Clave: '12171501', Descripcion: 'Colorantes fluorescentes' },
    { Clave: '12171502', Descripcion: 'Colorantes ftaleínicos' },
    { Clave: '12171503', Descripcion: 'Colorantes rosanilina' },
    { Clave: '12171504', Descripcion: 'Colorantes fdc seguros para alimentos o cosméticos' },
    { Clave: '12171505', Descripcion: 'Lacado' },
    { Clave: '12171506', Descripcion: 'Colorantes naturales' },
    { Clave: '12171507', Descripcion: 'Colorantes de azufre' },
    { Clave: '12171508', Descripcion: 'Colorantes de cuba o tina' },
    { Clave: '12171509', Descripcion: 'Colorantes reactivos' },
    { Clave: '12171510', Descripcion: 'Colorantes solventes' },
    { Clave: '12171511', Descripcion: 'Colorantes ácidos' },
    { Clave: '12171600', Descripcion: 'Pigmentos' },
    { Clave: '12171602', Descripcion: 'Óxidos metálicos inorgánicos' },
    { Clave: '12171603', Descripcion: 'Negro carbono' },
    { Clave: '12171604', Descripcion: 'Dióxido de titanio' },
    { Clave: '12171605', Descripcion: 'Pigmentos orgánicos' },
    { Clave: '12171606', Descripcion: 'Zeaxantina' },
    { Clave: '12171607', Descripcion: 'Verde parís' },
    { Clave: '12171608', Descripcion: 'Caput mortuum (púrpura de cardenal)' },
    { Clave: '12171609', Descripcion: 'Verde cadmio' },
    { Clave: '12171610', Descripcion: 'Anaranjado cadmio' },
    { Clave: '12171611', Descripcion: 'Amarillo cadmio' },
    { Clave: '12171612', Descripcion: 'Rojo cadmio' },
    { Clave: '12171613', Descripcion: 'Rojo ocre' },
    { Clave: '12171614', Descripcion: 'Amarillo ocre' },
    { Clave: '12171615', Descripcion: 'Sanguina' },
    { Clave: '12171616', Descripcion: 'Azul de prusia' },
    { Clave: '12171617', Descripcion: 'Rojo veneciano' },
    { Clave: '12171618', Descripcion: 'Verde cromo' },
    { Clave: '12171619', Descripcion: 'Auerolina (amarillo de cobalto)' },
    { Clave: '12171620', Descripcion: 'Amarillo cromo' },
    { Clave: '12171621', Descripcion: 'Pigmento fluorescente' },
    { Clave: '12171700', Descripcion: 'Compuestos de colores y dispersiones' },
    { Clave: '12171701', Descripcion: 'Concentrado de color de polímero' },
    { Clave: '12171702', Descripcion: 'Dispersiones de pigmento' },
    { Clave: '12171703', Descripcion: 'Tintas' },
    { Clave: '12181500', Descripcion: 'Ceras' },
    { Clave: '12181501', Descripcion: 'Ceras sintéticas' },
    { Clave: '12181502', Descripcion: 'Ceras naturales' },
    { Clave: '12181503', Descripcion: 'Parafinas' },
    { Clave: '12181504', Descripcion: 'Petrolatos' },
    { Clave: '12181600', Descripcion: 'Aceites' },
    { Clave: '12181601', Descripcion: 'Aceites sintéticos' },
    { Clave: '12181602', Descripcion: 'Aceites naturales' },
    { Clave: '12191500', Descripcion: 'Solventes hidrocarbonatos' },
    { Clave: '12191501', Descripcion: 'Disolventes aromáticos' },
    { Clave: '12191502', Descripcion: 'Disolventes alifáticos' },
    { Clave: '12191503', Descripcion: 'Fenoles o sus sustitutos y derivados' },
    { Clave: '12191504', Descripcion: 'Alcanos cíclicos' },
    { Clave: '12191600', Descripcion: 'Solventes oxigenados' },
    { Clave: '12191601', Descripcion: 'Solventes de alcohol' },
    { Clave: '12191602', Descripcion: 'Solventes activos' },
    { Clave: '12352000', Descripcion: 'Compuestos aromáticos y alifáticos' },
    { Clave: '12352001', Descripcion: 'Alcanos' },
    { Clave: '12352002', Descripcion: 'Alquenos' },
    { Clave: '12352003', Descripcion: 'Alquinos' },
    { Clave: '12352005', Descripcion: 'Compuestos aromáticos o heterocíclicos' },
    { Clave: '12352100', Descripcion: 'Derivados orgánicos y compuestos sustituidos' },
    { Clave: '12352101', Descripcion: 'Compuestos halogenados orgánicos' },
    { Clave: '12352102', Descripcion: 'Compuestos orgánicos nitro o nitroso' },
    { Clave: '12352103', Descripcion: 'Compuestos órgano ? metálicos' },
    { Clave: '12352104', Descripcion: 'Alcoholes o sus sustitutos' },
    { Clave: '12352105', Descripcion: 'Alcoholes tio (mercaptanos)' },
    { Clave: '12352106', Descripcion: 'Ácidos orgánicos o sus sustitutos' },
    { Clave: '12352107', Descripcion: 'Sales orgánicas o sus sustitutos' },
    { Clave: '12352108', Descripcion: 'Esteres o sus sustitutos' },
    { Clave: '12352111', Descripcion: 'Amidas o imidas' },
    { Clave: '12352112', Descripcion: 'Esteres o sus sustitutos' },
    { Clave: '12352113', Descripcion: 'Tioéteres' },
    { Clave: '12352114', Descripcion: 'Aldehídos o sus sustitutos' },
    { Clave: '12352115', Descripcion: 'Cetonas o quinonas o sus sustitutos' },
    { Clave: '12352116', Descripcion: 'Amines o imines o sus sustitutos' },
    { Clave: '12352117', Descripcion: 'Cianuros o isocianuros' },
    { Clave: '12352118', Descripcion: 'Cianatos o isocianatos o tiocianatos o isotiocianatos' },
    { Clave: '12352119', Descripcion: 'Óxidos orgánicos' },
    { Clave: '12352120', Descripcion: 'Peróxidos orgánicos' },
    { Clave: '12352121', Descripcion: 'Hidróxidos orgánicos' },
    { Clave: '12352123', Descripcion: 'Ureidos o purinas o sus derivados' },
    { Clave: '12352124', Descripcion: 'Azo compuestos o sus sustitutos' },
    { Clave: '12352125', Descripcion: 'Azidas o azinas' },
    { Clave: '12352126', Descripcion: 'Oximas' },
    { Clave: '12352127', Descripcion: 'Hidracinas o hidracidas o sus sustitutos' },
    { Clave: '12352128', Descripcion: 'Fosfinas' },
    { Clave: '12352129', Descripcion: 'Amidinas o imidinas' },
    { Clave: '12352130', Descripcion: 'Intermedios de acrilato o metacrilato' },
    { Clave: '12352131', Descripcion: 'Pirrolidona' },
    { Clave: '12352132', Descripcion: 'Triclosán' },
    { Clave: '12352133', Descripcion: 'Condicionador de congelación' },
    { Clave: '12352135', Descripcion: 'Glicol propileno' },
    { Clave: '12352136', Descripcion: 'Glicol trietileno' },
    { Clave: '12352137', Descripcion: 'Glicol etileno' },
    { Clave: '12352200', Descripcion: 'Productos bioquímicos' },
    { Clave: '12352201', Descripcion: 'Carbohidratos o sus derivados' },
    { Clave: '12352202', Descripcion: 'Proteínas' },
    { Clave: '12352203', Descripcion: 'Anticuerpos' },
    { Clave: '12352204', Descripcion: 'Enzimas' },
    { Clave: '12352205', Descripcion: 'Nutrientes' },
    { Clave: '12352206', Descripcion: 'Tejidos' },
    { Clave: '12352207', Descripcion: 'Cultivos y fluidos' },
    { Clave: '12352208', Descripcion: 'Ácido nucleico' },
    { Clave: '12352209', Descripcion: 'Aminoácidos o sus derivados' },
    { Clave: '12352210', Descripcion: 'Alcaloides' },
    { Clave: '12352211', Descripcion: 'Grasas o lípidos' },
    { Clave: '12352212', Descripcion: 'Terpenoides' },
    { Clave: '12352300', Descripcion: 'Compuestos inorgánicos' },
    { Clave: '12352301', Descripcion: 'Ácidos inorgánicos' },
    { Clave: '12352302', Descripcion: 'Sales metálicas inorgánicas' },
    { Clave: '12352303', Descripcion: 'Óxidos inorgánicos' },
    { Clave: '12352304', Descripcion: 'Peróxidos inorgánicos' },
    { Clave: '12352305', Descripcion: 'Hidróxidos inorgánicos' },
    { Clave: '12352306', Descripcion: 'Hídridos inorgánicos' },
    { Clave: '12352307', Descripcion: 'Halidos ácidos o sus sustitutos' },
    { Clave: '12352308', Descripcion: 'Silicatos' },
    { Clave: '12352309', Descripcion: 'Sílice' },
    { Clave: '12352310', Descripcion: 'Siliconas' },
    { Clave: '12352311', Descripcion: 'Alúmina y otros compuestos de aluminio' },
    { Clave: '12352312', Descripcion: 'Permanganato de potasio' },
    { Clave: '12352313', Descripcion: 'Ácido trifluoroacético tfa' },
    { Clave: '12352314', Descripcion: 'Sulfato de amonio' },
    { Clave: '12352315', Descripcion: 'Caucho de silicona líquida' },
    { Clave: '12352316', Descripcion: 'Hidróxido de sodio' },
    { Clave: '12352317', Descripcion: 'Fluoruro de aluminio' },
    { Clave: '12352318', Descripcion: 'Fluoruro de calcio' },
    { Clave: '12352319', Descripcion: 'Hidróxido de calcio' },
    { Clave: '12352320', Descripcion: 'Hidróxido de potasio' },
    { Clave: '12352321', Descripcion: 'Glicol acetileno' },
    { Clave: '12352400', Descripcion: 'Mezclas' },
    { Clave: '12352401', Descripcion: 'Mezclas químicas orgánicas' },
    { Clave: '12352402', Descripcion: 'Mezclas químicas inorgánicas' },
    { Clave: '12352500', Descripcion: 'Fijadores' },
    { Clave: '12352501', Descripcion: 'Formaldehidos' },
    { Clave: '12352502', Descripcion: 'Glutarales' },
    { Clave: '12352503', Descripcion: 'Taninos' },
    { Clave: '12352600', Descripcion: 'Compuestos fluorados' },
    { Clave: '13101500', Descripcion: 'Caucho natural' },
    { Clave: '13101501', Descripcion: 'Caucho látex' },
    { Clave: '13101502', Descripcion: 'Caucho crepe' },
    { Clave: '13101503', Descripcion: 'Caucho hoja ahumada' },
    { Clave: '13101504', Descripcion: 'Caucho espuma natural' },
    { Clave: '13101505', Descripcion: 'Caucho bloque o borona' },
    { Clave: '13101600', Descripcion: 'Caucho procesado y sintético' },
    { Clave: '13101601', Descripcion: 'Caucho vulcanizado' },
    { Clave: '13101602', Descripcion: 'Caucho clorado' },
    { Clave: '13101603', Descripcion: 'Caucho clorhidrato' },
    { Clave: '13101604', Descripcion: 'Caucho ciclizado' },
    { Clave: '13101605', Descripcion: 'Caucho isomerizado' },
    { Clave: '13101606', Descripcion: 'Caucho termoplástico' },
    { Clave: '13101607', Descripcion: 'Compuesto de caucho' },
    { Clave: '13101608', Descripcion: 'Caucho reclamado' },
    { Clave: '13101700', Descripcion: 'Elastómeros' },
    { Clave: '13101701', Descripcion: 'Butadieno acilonitrilo nbr' },
    { Clave: '13101702', Descripcion: 'Nitrilo altamente saturado nhbr' },
    { Clave: '13101703', Descripcion: 'Fluorocarbono fcm' },
    { Clave: '13101704', Descripcion: 'Propileno etileno epe' },
    { Clave: '13101705', Descripcion: 'Butadieno estireno' },
    { Clave: '13101706', Descripcion: 'Clorpreno cr' },
    { Clave: '13101707', Descripcion: 'Isopreno isobutileno iir / xiir' },
    { Clave: '13101708', Descripcion: 'Silicona vmq y pmq y pvmq' },
    { Clave: '13101709', Descripcion: 'Fluorosilicona fvmq' },
    { Clave: '13101710', Descripcion: 'Poliacrilato acm' },
    { Clave: '13101711', Descripcion: 'Etileno acrílico aem' },
    { Clave: '13101712', Descripcion: 'Polietileno clorosulfonatado csm' },
    { Clave: '13101713', Descripcion: 'Cloropolietileno cm' },
    { Clave: '13101714', Descripcion: 'Epicloridrina' },
    { Clave: '13101715', Descripcion: 'Polisopreno natural nr' },
    { Clave: '13101716', Descripcion: 'Polisopreno sintético ir' },
    { Clave: '13101717', Descripcion: 'Poliéster uretano au' },
    { Clave: '13101718', Descripcion: 'Poliéster uretano eu' },
    { Clave: '13101719', Descripcion: 'Polibutadieno br' },
    { Clave: '13101720', Descripcion: 'Poli éter bloque amida peba' },
    { Clave: '13101721', Descripcion: 'Estireno bloque copolímero tes' },
    { Clave: '13101722', Descripcion: 'Copoliester' },
    { Clave: '13101723', Descripcion: 'Termoplástico' },
    { Clave: '13101724', Descripcion: 'Poliolefínico' },
    { Clave: '13101725', Descripcion: 'Etileno propileno dieno epdm' },
    { Clave: '13101900', Descripcion: 'Plásticos termoformados' },
    { Clave: '13101902', Descripcion: 'Fenólico pf' },
    { Clave: '13101903', Descripcion: 'Poliéster no saturado up' },
    { Clave: '13101904', Descripcion: 'Urea uf' },
    { Clave: '13101905', Descripcion: 'Melamina mf' },
    { Clave: '13101906', Descripcion: 'Poliuretano termoestable pur' },
    { Clave: '13102000', Descripcion: 'Plásticos termoplásticos' },
    { Clave: '13102001', Descripcion: 'Acrilonitrilo butadieno estireno abs' },
    { Clave: '13102002', Descripcion: 'Aleaciones de acrilonitrilo butadieno estireno abs' },
    { Clave: '13102003', Descripcion: 'Polímero acetal' },
    { Clave: '13102005', Descripcion: 'Acrilonitrilo estireno acrílico asa' },
    { Clave: '13102006', Descripcion: 'Aleaciones de acrilonitrilo estireno acrílico asa' },
    { Clave: '13102008', Descripcion: 'Fluoropolímeros ptfe' },
    { Clave: '13102010', Descripcion: 'Polímero cristal líquido lcp' },
    { Clave: '13102011', Descripcion: 'Nylons poliamida pa' },
    { Clave: '13102012', Descripcion: '(no hay sugerencias) pbt' },
    { Clave: '13102013', Descripcion: 'Policarbonato pc' },
    { Clave: '13102014', Descripcion: 'Polietereterquetona peek' },
    { Clave: '13102016', Descripcion: 'Polietersulfona pes' },
    { Clave: '13102017', Descripcion: 'Polietileno de baja densidad hdpe' },
    { Clave: '13102018', Descripcion: 'Polietileno de alta densidad ldpe' },
    { Clave: '13102019', Descripcion: 'Polietileno de media densidad mdpe' },
    { Clave: '13102020', Descripcion: 'Polietileno tereftalato pet' },
    { Clave: '13102021', Descripcion: 'Polimida pi' },
    { Clave: '13102022', Descripcion: 'Polipropileno pp' },
    { Clave: '13102024', Descripcion: 'Óxido de polifenileno ppo' },
    { Clave: '13102025', Descripcion: 'Sulfuro de polifenileno pps' },
    { Clave: '13102026', Descripcion: 'Poliestireno (icopor) ps' },
    { Clave: '13102027', Descripcion: 'Poliestireno de alto impacto hips' },
    { Clave: '13102028', Descripcion: 'Polisulfona psu' },
    { Clave: '13102029', Descripcion: 'Poliuretano termoplástico rígido rptu' },
    { Clave: '13102030', Descripcion: 'Cloruro de polivinilo pvc' },
    { Clave: '13102031', Descripcion: 'Éter de polifenilene ppe' },
    { Clave: '13102032', Descripcion: 'Poliolefina termoplástica tpo' },
    { Clave: '13111000', Descripcion: 'Resinas' },
    { Clave: '13111001', Descripcion: 'Epoxi' },
    { Clave: '13111002', Descripcion: 'Resina fenólica' },
    { Clave: '13111003', Descripcion: 'Resina polester no saturada' },
    { Clave: '13111004', Descripcion: 'Resina estireno butadieno acrilonitrilo' },
    { Clave: '13111005', Descripcion: 'Resina acrílica estireno acrilonitrilo' },
    { Clave: '13111006', Descripcion: 'Resina aleación acrílica estireno acrilonitrilo' },
    { Clave: '13111007', Descripcion: 'Resina fliuoropolímero' },
    { Clave: '13111008', Descripcion: 'Resina acetato vinilo etileno' },
    { Clave: '13111009', Descripcion: 'Resina polímero cristal líquido' },
    { Clave: '13111010', Descripcion: 'Nylon' },
    { Clave: '13111011', Descripcion: 'Tetralftalato polibutileno' },
    { Clave: '13111012', Descripcion: 'Resina de policarbonato' },
    { Clave: '13111013', Descripcion: 'Resina polietereterquetona' },
    { Clave: '13111014', Descripcion: 'Resina polieterimida' },
    { Clave: '13111015', Descripcion: 'Resina polietersulfona' },
    { Clave: '13111016', Descripcion: 'Polietileno' },
    { Clave: '13111017', Descripcion: 'Resina terftalato polietileno' },
    { Clave: '13111018', Descripcion: 'Resina polimida' },
    { Clave: '13111019', Descripcion: 'Resina polipropileno' },
    { Clave: '13111020', Descripcion: 'Resina poliftalamida' },
    { Clave: '13111021', Descripcion: 'Óxido de polietileno' },
    { Clave: '13111022', Descripcion: 'Resina sulfuro de polifenilene' },
    { Clave: '13111023', Descripcion: 'Resina de poliestireno' },
    { Clave: '13111024', Descripcion: 'Resina de polisulfona' },
    { Clave: '13111025', Descripcion: 'Resina de cloruro de polivinilo' },
    { Clave: '13111026', Descripcion: 'Resina de acrilonitrilo de estireno' },
    { Clave: '13111027', Descripcion: 'Formaldehído de urea' },
    { Clave: '13111028', Descripcion: 'Alquido' },
    { Clave: '13111029', Descripcion: 'Formaldehído de melamina' },
    { Clave: '13111030', Descripcion: 'Poliacetal' },
    { Clave: '13111031', Descripcion: 'Poliamida' },
    { Clave: '13111032', Descripcion: 'Grupo alilo' },
    { Clave: '13111033', Descripcion: 'Ácido acrílico de etileno' },
    { Clave: '13111034', Descripcion: 'Compuesto cloruro de polivinilo' },
    { Clave: '13111035', Descripcion: 'Solución de vinilo' },
    { Clave: '13111036', Descripcion: 'Fenoxi' },
    { Clave: '13111037', Descripcion: 'Resina compuesta' },
    { Clave: '13111038', Descripcion: 'Pirolidina polivinilo' },
    { Clave: '13111039', Descripcion: 'Tereftalato de polietileno o glicol modificado' },
    { Clave: '13111040', Descripcion: 'Fijador de hidrocarburo' },
    { Clave: '13111041', Descripcion: 'Mezclas de policarbonato' },
    { Clave: '13111042', Descripcion: 'Alcohol polivinilo' },
    { Clave: '13111043', Descripcion: 'Butiral polivinilo' },
    { Clave: '13111044', Descripcion: 'Compuesto de moldeo de poliéster' },
    { Clave: '13111045', Descripcion: 'Acetato de polivinilo' },
    { Clave: '13111046', Descripcion: 'Éter de polivinilo' },
    { Clave: '13111047', Descripcion: 'Polivinilo formal' },
    { Clave: '13111048', Descripcion: 'Acrílico de estireno' },
    { Clave: '13111049', Descripcion: 'Polímeros de propileno de etileno' },
    { Clave: '13111050', Descripcion: 'Óxido de polipropileno' },
    { Clave: '13111051', Descripcion: 'Éter de polipropileno' },
    { Clave: '13111052', Descripcion: 'Sulfona de polipropileno' },
    { Clave: '13111053', Descripcion: 'Polimetilacrilato' },
    { Clave: '13111054', Descripcion: 'Anhídrido maléico de estireno' },
    { Clave: '13111055', Descripcion: 'Poliestireno sindiotáctico' },
    { Clave: '13111056', Descripcion: 'Cloruro de polivinilo clorado' },
    { Clave: '13111057', Descripcion: 'Poliéster termoplástico' },
    { Clave: '13111058', Descripcion: 'Resinas de indeno' },
    { Clave: '13111059', Descripcion: 'Resinas plásticas' },
    { Clave: '13111060', Descripcion: 'Resinas de petróleo' },
    { Clave: '13111061', Descripcion: 'Resinas de poliuretano' },
    { Clave: '13111062', Descripcion: 'Resinas de polieter' },
    { Clave: '13111063', Descripcion: 'Resinas recicladas' },
    { Clave: '13111064', Descripcion: 'Resinas acrílicas' },
    { Clave: '13111065', Descripcion: 'Resinas celulosas' },
    { Clave: '13111066', Descripcion: 'Resinas de polipropileno' },
    { Clave: '13111067', Descripcion: 'Alcohol etileno vinilo' },
    { Clave: '13111068', Descripcion: 'Polietileno lineal de baja densidad' },
    { Clave: '13111069', Descripcion: 'Resina de poliacrilonitrilo' },
    { Clave: '13111070', Descripcion: 'Resina de poliamida ? imida' },
    { Clave: '13111071', Descripcion: 'Resina de poliarileterquetona' },
    { Clave: '13111072', Descripcion: 'Resina de polibenzimidasola' },
    { Clave: '13111073', Descripcion: 'Resina de polimetilpenteno' },
    { Clave: '13111074', Descripcion: 'Fluoruro de polivinildeno' },
    { Clave: '13111075', Descripcion: 'Aleación estireno uradieno acrilonitrilo policarbonato pc abs' },
    { Clave: '13111076', Descripcion: 'Polietileno entrecruzado pex' },
    { Clave: '13111077', Descripcion: 'Poliamida 6 ? 12' },
    { Clave: '13111078', Descripcion: 'Poliamida 6 ? 6' },
    { Clave: '13111079', Descripcion: 'Poliamida 4 ? 6' },
    { Clave: '13111080', Descripcion: 'Nylon poliamida de alta temperatura htn' },
    { Clave: '13111081', Descripcion: 'Poliamida 12' },
    { Clave: '13111082', Descripcion: 'Poliamida 6' },
    { Clave: '13111100', Descripcion: 'Colofonia' },
    { Clave: '13111101', Descripcion: 'Rosina de madera' },
    { Clave: '13111102', Descripcion: 'Rosina de goma' },
    { Clave: '13111103', Descripcion: 'Rosina aceite de pino' },
    { Clave: '13111200', Descripcion: 'Películas' },
    { Clave: '13111201', Descripcion: 'Películas de polietileno' },
    { Clave: '13111202', Descripcion: 'Películas de poliuretano' },
    { Clave: '13111203', Descripcion: 'Películas de acetato' },
    { Clave: '13111204', Descripcion: 'Películas de acrílico' },
    { Clave: '13111205', Descripcion: 'Películas coextruídas' },
    { Clave: '13111206', Descripcion: 'Películas de fluoropolímero' },
    { Clave: '13111207', Descripcion: 'Películas metalizadas' },
    { Clave: '13111208', Descripcion: 'Películas de nylon' },
    { Clave: '13111209', Descripcion: 'Películas de policarbonato' },
    { Clave: '13111210', Descripcion: 'Películas de poliéster' },
    { Clave: '13111211', Descripcion: 'Películas de polipropileno' },
    { Clave: '13111212', Descripcion: 'Polipropileno orientado axialmente' },
    { Clave: '13111213', Descripcion: 'Películas de poliamida' },
    { Clave: '13111214', Descripcion: 'Películas de poliestireno' },
    { Clave: '13111215', Descripcion: 'Películas de cloruro de polivinilo flexible' },
    { Clave: '13111216', Descripcion: 'Películas de cloruro de polivinilo rígido' },
    { Clave: '13111217', Descripcion: 'Películas de alcohol vinilo etileno' },
    { Clave: '13111218', Descripcion: 'Películas de cloruro de polivinilo' },
    { Clave: '13111219', Descripcion: 'Películas de alcohol polivinilo' },
    { Clave: '13111220', Descripcion: 'Películas de recubiertas de silicona' },
    { Clave: '13111300', Descripcion: 'Espumas' },
    { Clave: '13111301', Descripcion: 'Espumas de poliolefina' },
    { Clave: '13111302', Descripcion: 'Espumas de poli éter' },
    { Clave: '13111303', Descripcion: 'Espumas de silicona' },
    { Clave: '13111304', Descripcion: 'Espumas de terpolímero propileno etileno' },
    { Clave: '13111305', Descripcion: 'Espumas de neopreno' },
    { Clave: '13111306', Descripcion: 'Espumas de cloruro de polivinilo' },
    { Clave: '13111307', Descripcion: 'Espumas de caucho' },
    { Clave: '13111308', Descripcion: 'Espumas de poliestireno' },
    { Clave: '14101500', Descripcion: 'Materias primas' },
    { Clave: '14101501', Descripcion: 'Pulpa de papel' },
    { Clave: '14111500', Descripcion: 'Papel de imprenta y papel de escribir' },
    { Clave: '14111501', Descripcion: 'Papel cebolla' },
    { Clave: '14111502', Descripcion: 'Papel vitela' },
    { Clave: '14111503', Descripcion: 'Papel pergamino' },
    { Clave: '14111504', Descripcion: 'Papel en formas continuas' },
    { Clave: '14111505', Descripcion: 'Papel mimeógrafo' },
    { Clave: '14111506', Descripcion: 'Papel para impresión de computadores' },
    { Clave: '14111507', Descripcion: 'Papel para impresora o fotocopiadora' },
    { Clave: '14111508', Descripcion: 'Papel para fax' },
    { Clave: '14111509', Descripcion: 'Papel membreteado' },
    { Clave: '14111510', Descripcion: 'Papel para plotter' },
    { Clave: '14111511', Descripcion: 'Papel de escritura' },
    { Clave: '14111512', Descripcion: 'Papel para gráficos' },
    { Clave: '14111513', Descripcion: 'Papel de libro' },
    { Clave: '14111514', Descripcion: 'Blocs o cuadernos de papel' },
    { Clave: '14111515', Descripcion: 'Papel para sumadora o máquina registradora' },
    { Clave: '14111516', Descripcion: 'Repuestos de papel para cuaderno' },
    { Clave: '14111518', Descripcion: 'Tarjetas de índice' },
    { Clave: '14111519', Descripcion: 'Papeles cartulina' },
    { Clave: '14111520', Descripcion: 'Papel secante' },
    { Clave: '14111523', Descripcion: 'Papel calcante' },
    { Clave: '14111524', Descripcion: 'Papel folio' },
    { Clave: '14111525', Descripcion: 'Papel multipropósito' },
    { Clave: '14111526', Descripcion: 'Papel libretas o libros de mensajes telefónicos' },
    { Clave: '14111527', Descripcion: 'Papel autocopiante' },
    { Clave: '14111528', Descripcion: 'Papel magnético' },
    { Clave: '14111529', Descripcion: 'Rollos de télex' },
    { Clave: '14111530', Descripcion: 'Papel de notas autoadhesivas' },
    { Clave: '14111531', Descripcion: 'Papel libros o cuadernos para bitácoras' },
    { Clave: '14111532', Descripcion: 'Papel kits de papeles surtidos' },
    { Clave: '14111533', Descripcion: 'Papel cuadernillos o formularios de exámenes' },
    { Clave: '14111534', Descripcion: 'Papeles para notación musical o manuscritos' },
    { Clave: '14111535', Descripcion: 'Papeles para telégrafo' },
    { Clave: '14111536', Descripcion: 'Tarjetas de préstamo de bibliotecas' },
    { Clave: '14111537', Descripcion: 'Etiquetas de papel' },
    { Clave: '14111538', Descripcion: 'Papel digital' },
    { Clave: '14111539', Descripcion: 'Papel de monitoreo o calcado o registro médico' },
    { Clave: '14111540', Descripcion: 'Papel de estampilla' },
    { Clave: '14111541', Descripcion: 'Papel lector de marcas ópticas' },
    { Clave: '14111542', Descripcion: 'Papel coreano para papelería' },
    { Clave: '14111543', Descripcion: 'Piedra de tinta' },
    { Clave: '14111600', Descripcion: 'Papel fantasía' },
    { Clave: '14111601', Descripcion: 'Papel o bolsas o cajas de regalo' },
    { Clave: '14111604', Descripcion: 'Tarjetas de presentación' },
    { Clave: '14111605', Descripcion: 'Tarjetas postales, de saludo o de notas' },
    { Clave: '14111606', Descripcion: 'Papel para artes o artesanías' },
    { Clave: '14111607', Descripcion: 'Tableros para afiches' },
    { Clave: '14111608', Descripcion: 'Certificados de regalo' },
    { Clave: '14111609', Descripcion: 'Papel de cubierta' },
    { Clave: '14111610', Descripcion: 'Papel de construcción' },
    { Clave: '14111611', Descripcion: 'Tarjetas de invitación o de anuncio' },
    { Clave: '14111613', Descripcion: 'Papel de pancartas' },
    { Clave: '14111614', Descripcion: 'Papel o tejidos de álbum' },
    { Clave: '14111615', Descripcion: 'Papeles de afiche' },
    { Clave: '14111616', Descripcion: 'Papeles para forrar' },
    { Clave: '14111617', Descripcion: 'Papel leathack (verjurado)' },
    { Clave: '14111618', Descripcion: 'Papel kent' },
    { Clave: '14111700', Descripcion: 'Productos de papel para uso personal' },
    { Clave: '14111701', Descripcion: 'Pañuelos faciales' },
    { Clave: '14111702', Descripcion: 'Cubiertos de asientos de sanitario' },
    { Clave: '14111703', Descripcion: 'Toallas de papel' },
    { Clave: '14111704', Descripcion: 'Papel higiénico' },
    { Clave: '14111705', Descripcion: 'Servilletas de papel' },
    { Clave: '14111706', Descripcion: 'Manteles de papel' },
    { Clave: '14111800', Descripcion: 'Papeles de uso comercial' },
    { Clave: '14111801', Descripcion: 'Boletas o rollos de boletería' },
    { Clave: '14111802', Descripcion: 'Recibos o libros de recibos' },
    { Clave: '14111803', Descripcion: 'Comprobantes' },
    { Clave: '14111804', Descripcion: 'Facturas o libros de facturas' },
    { Clave: '14111805', Descripcion: 'Cheques o chequeras' },
    { Clave: '14111806', Descripcion: 'Formularios o cuestionarios de negocios' },
    { Clave: '14111807', Descripcion: 'Libros comerciales para múltiples usos' },
    { Clave: '14111808', Descripcion: 'Formatos contables o libros de contabilidad' },
    { Clave: '14111809', Descripcion: 'Formas o libros de conocimientos de embarque' },
    { Clave: '14111810', Descripcion: 'Formatos o libros de personal' },
    { Clave: '14111811', Descripcion: 'Formatos o libros de ventas' },
    { Clave: '14111812', Descripcion: 'Formatos o libros de inventarios' },
    { Clave: '14111813', Descripcion: 'Formatos o libros de correspondencia' },
    { Clave: '14111814', Descripcion: 'Formatos o libros de impuestos' },
    { Clave: '14111815', Descripcion: 'Tarjetas de identificación' },
    { Clave: '14111816', Descripcion: 'Tarjetas de huellas digitales de solicitante' },
    { Clave: '14111817', Descripcion: 'Formato de verificación de depósito' },
    { Clave: '14111818', Descripcion: 'Papel térmico' },
    { Clave: '14111819', Descripcion: 'Formularios p libros de reservas' },
    { Clave: '14111820', Descripcion: 'Formas o cupones de juegos de azar' },
    { Clave: '14111821', Descripcion: 'Formatos o libros de órdenes' },
    { Clave: '14111822', Descripcion: 'Formatos o libros de entregas' },
    { Clave: '14111823', Descripcion: 'Formatos o libros de control' },
    { Clave: '14111824', Descripcion: 'Libreta de prescripciones farmacéuticas' },
    { Clave: '14111825', Descripcion: 'Menú' },
    { Clave: '14111826', Descripcion: 'Certificados de nacimiento' },
    { Clave: '14111827', Descripcion: 'Certificados de defunción' },
    { Clave: '14111828', Descripcion: 'Papelería comercial membreteada' },
    { Clave: '14121500', Descripcion: 'Cartón y papel para embalaje' },
    { Clave: '14121501', Descripcion: 'Cartón blanqueado' },
    { Clave: '14121502', Descripcion: 'Cartón no blanqueado' },
    { Clave: '14121503', Descripcion: 'Cartón' },
    { Clave: '14121504', Descripcion: 'Papel de empaque' },
    { Clave: '14121505', Descripcion: 'Material de fibra' },
    { Clave: '14121506', Descripcion: 'Fibra corrugada' },
    { Clave: '14121507', Descripcion: 'Papel inhibidor de la corrosión volátil o vci' },
    { Clave: '14121600', Descripcion: 'Papel de seda' },
    { Clave: '14121601', Descripcion: 'Papel crepé sin blanquear' },
    { Clave: '14121602', Descripcion: 'Papel crepé semi blanqueado' },
    { Clave: '14121603', Descripcion: 'Pañuelos de papel resistentes a la humedad' },
    { Clave: '14121604', Descripcion: 'Pañuelos de papel libres de ácido' },
    { Clave: '14121605', Descripcion: 'Pañuelos de papel kraft' },
    { Clave: '14121700', Descripcion: 'Papeles laminados' },
    { Clave: '14121701', Descripcion: 'Papeles adheridos con película' },
    { Clave: '14121702', Descripcion: 'Papeles cilindro o papel pesado multi ? capas' },
    { Clave: '14121703', Descripcion: 'Hojas de papel aluminio laminado' },
    { Clave: '14121800', Descripcion: 'Papeles recubiertos' },
    { Clave: '14121801', Descripcion: 'Papeles recubiertos de arcilla' },
    { Clave: '14121802', Descripcion: 'Papeles recubiertos de polietileno' },
    { Clave: '14121803', Descripcion: 'Papeles recubiertos de poliéster' },
    { Clave: '14121804', Descripcion: 'Papeles recubiertos de silicona' },
    { Clave: '14121805', Descripcion: 'Papeles recubiertos tratados con látex' },
    { Clave: '14121806', Descripcion: 'Papel de parafinado' },
    { Clave: '14121807', Descripcion: 'Papel mantequilla' },
    { Clave: '14121808', Descripcion: 'Papel para congelador' },
    { Clave: '14121809', Descripcion: 'Papel de enmascarar' },
    { Clave: '14121810', Descripcion: 'Papeles carbón' },
    { Clave: '14121811', Descripcion: 'Papeles de copiado sensibilizados' },
    { Clave: '14121812', Descripcion: 'Papel de fotografía' },
    { Clave: '14121900', Descripcion: 'Papel prensa y de offset' },
    { Clave: '14121901', Descripcion: 'Papel periódico estándar' },
    { Clave: '14121902', Descripcion: 'Papel periódico de colores' },
    { Clave: '14121903', Descripcion: 'Papel periódico de alto brillo' },
    { Clave: '14121904', Descripcion: 'Papel offset' },
    { Clave: '14121905', Descripcion: 'Papeles tímpano' },
    { Clave: '14122100', Descripcion: 'Papeles de base sin recubrir' },
    { Clave: '14122101', Descripcion: 'Papel kraft súper calandrado' },
    { Clave: '14122102', Descripcion: 'Papel kraft terminado o satinado en máquina' },
    { Clave: '14122103', Descripcion: 'Papel no recubierto no tratado' },
    { Clave: '14122104', Descripcion: 'Papel crepé no tratado' },
    { Clave: '14122105', Descripcion: 'Papel crepé tratado con látex' },
    { Clave: '14122106', Descripcion: 'Papel no recubierto tratado con látex' },
    { Clave: '14122107', Descripcion: 'Papel base corrugado' },
    { Clave: '14122200', Descripcion: 'Papeles especializados de uso industrial' },
    { Clave: '14122201', Descripcion: 'Papel para germinación de semillas' },
    { Clave: '14122202', Descripcion: 'Papel para bolsas de te' },
    { Clave: '15101500', Descripcion: 'Petróleo y Destilados' },
    { Clave: '15101502', Descripcion: 'Kerosene' },
    { Clave: '15101503', Descripcion: 'Nafta' },
    { Clave: '15101504', Descripcion: 'Combustible de aviación' },
    { Clave: '15101505', Descripcion: 'Combustible diesel' },
    { Clave: '15101506', Descripcion: 'Gasolina' },
    { Clave: '15101507', Descripcion: 'Benceno' },
    { Clave: '15101508', Descripcion: 'Petróleo crudo' },
    { Clave: '15101509', Descripcion: 'Combustible marítimo' },
    { Clave: '15101510', Descripcion: 'Condensado' },
    { Clave: '15101511', Descripcion: 'Etanol' },
    { Clave: '15101512', Descripcion: 'Coque de petróleo o petcoke' },
    { Clave: '15101513', Descripcion: 'Combustible diesel para uso fuera de carretera' },
    { Clave: '15101600', Descripcion: 'Combustibles sólidos o gelificados' },
    { Clave: '15101601', Descripcion: 'Carbón sub ? bituminosos o débil' },
    { Clave: '15101602', Descripcion: 'Lignito' },
    { Clave: '15101603', Descripcion: 'Turba' },
    { Clave: '15101604', Descripcion: 'Coque' },
    { Clave: '15101605', Descripcion: 'Carbón de leña o vegetal' },
    { Clave: '15101606', Descripcion: 'Combustibles de alcohol gelatinoso' },
    { Clave: '15101607', Descripcion: 'Hexaminas' },
    { Clave: '15101608', Descripcion: 'Trioxanos' },
    { Clave: '15101609', Descripcion: 'Briqueta' },
    { Clave: '15101610', Descripcion: 'Carbón de cascara de coco' },
    { Clave: '15101611', Descripcion: 'Antracita o carbón duro' },
    { Clave: '15101612', Descripcion: 'Carbón metalúrgico' },
    { Clave: '15101613', Descripcion: 'Carbón sin procesar' },
    { Clave: '15101614', Descripcion: 'Carbón energético' },
    { Clave: '15101700', Descripcion: 'Aceites combustibles' },
    { Clave: '15101701', Descripcion: 'Fuel oil de calefacción # 2' },
    { Clave: '15101702', Descripcion: 'Fuel oils pesados residuales # 4 ó # 6' },
    { Clave: '15101800', Descripcion: 'Combustibles o biocombustibles líquidos basados en plantas' },
    { Clave: '15101801', Descripcion: 'Biodiesel' },
    { Clave: '15111500', Descripcion: 'Combustibles gaseosos' },
    { Clave: '15111501', Descripcion: 'Propano' },
    { Clave: '15111502', Descripcion: 'Metano' },
    { Clave: '15111503', Descripcion: 'Propileno' },
    { Clave: '15111504', Descripcion: 'Etileno' },
    { Clave: '15111505', Descripcion: 'Butano' },
    { Clave: '15111506', Descripcion: 'Acetileno' },
    { Clave: '15111507', Descripcion: 'Gas de agua o gas productor' },
    { Clave: '15111508', Descripcion: 'Gas de carbón' },
    { Clave: '15111509', Descripcion: 'Gas metilacetileno propadieno mapp' },
    { Clave: '15111510', Descripcion: 'Gas licuado de petróleo' },
    { Clave: '15111511', Descripcion: 'Gas natural licuado gnl' },
    { Clave: '15111700', Descripcion: 'Aditivos para carburante' },
    { Clave: '15111701', Descripcion: 'Espesantes de combustible' },
    { Clave: '15111702', Descripcion: 'Inhibidores de hielo para sistemas de combustibles' },
    { Clave: '15121500', Descripcion: 'Preparados lubricantes' },
    { Clave: '15121501', Descripcion: 'Aceite motor' },
    { Clave: '15121502', Descripcion: 'Aceite de corte' },
    { Clave: '15121503', Descripcion: 'Aceite de engranajes' },
    { Clave: '15121504', Descripcion: 'Aceite hidráulico' },
    { Clave: '15121505', Descripcion: 'Aceite de transformador o aislador' },
    { Clave: '15121508', Descripcion: 'Aceite de transmisión' },
    { Clave: '15121509', Descripcion: 'Aceite de frenos' },
    { Clave: '15121510', Descripcion: 'Anti ? excoriación' },
    { Clave: '15121511', Descripcion: 'Pastas de ensamble' },
    { Clave: '15121512', Descripcion: 'Anti adhesivos' },
    { Clave: '15121513', Descripcion: 'Lubricantes de grafito' },
    { Clave: '15121514', Descripcion: 'Lubricantes espray' },
    { Clave: '15121515', Descripcion: 'Compuestos anti ? adherentes o anti ? manchas' },
    { Clave: '15121516', Descripcion: 'Eliminador de fugas' },
    { Clave: '15121517', Descripcion: 'Jabones lubricantes' },
    { Clave: '15121518', Descripcion: 'Fluidos de amortiguación' },
    { Clave: '15121519', Descripcion: 'Aceites de lubricación de relojes' },
    { Clave: '15121520', Descripcion: 'Lubricantes de propósito general' },
    { Clave: '15121521', Descripcion: 'Aceites para lubricación de bombas' },
    { Clave: '15121522', Descripcion: 'Aceites para lubricación de armas' },
    { Clave: '15121523', Descripcion: 'Fluidos para preparación de lentes' },
    { Clave: '15121524', Descripcion: 'Aceites de templado' },
    { Clave: '15121525', Descripcion: 'Aceite atemperante' },
    { Clave: '15121526', Descripcion: 'Lubricantes para equipo de procesamiento de alimentos' },
    { Clave: '15121527', Descripcion: 'Aceite de turbina' },
    { Clave: '15121528', Descripcion: 'Fluido hidráulico resistente al fuego' },
    { Clave: '15121529', Descripcion: 'Aceite para máquinas de refrigeración' },
    { Clave: '15121530', Descripcion: 'Aceite o fluido de transferencia de calor' },
    { Clave: '15121800', Descripcion: 'Anticorrosivos' },
    { Clave: '15121801', Descripcion: 'Repelente de humedad' },
    { Clave: '15121802', Descripcion: 'Lubricante anti ? corrosión' },
    { Clave: '15121803', Descripcion: 'Removedor de óxido' },
    { Clave: '15121804', Descripcion: 'Preparación contra óxido' },
    { Clave: '15121805', Descripcion: 'Pastas anti ? soldado' },
    { Clave: '15121806', Descripcion: 'Aceites penetrantes' },
    { Clave: '15121807', Descripcion: 'Anticongelante' },
    { Clave: '15121900', Descripcion: 'Grasas' },
    { Clave: '15121901', Descripcion: 'Grasa de silicona' },
    { Clave: '15121902', Descripcion: 'Grasa' },
    { Clave: '15121903', Descripcion: 'Grasa fluoropolímero' },
    { Clave: '15121904', Descripcion: 'Grasa de lana' },
    { Clave: '15121905', Descripcion: 'Grasa térmica' },
    { Clave: '15131500', Descripcion: 'Combustible nuclear' },
    { Clave: '15131502', Descripcion: 'Uranio empobrecido' },
    { Clave: '15131503', Descripcion: 'Uranio enriquecido' },
    { Clave: '15131504', Descripcion: 'Iridio' },
    { Clave: '15131505', Descripcion: 'Plutonio enriquecido' },
    { Clave: '15131506', Descripcion: 'Plutonio empobrecido' },
    { Clave: '15131600', Descripcion: 'Instalación de combustible de fisión' },
    { Clave: '15131601', Descripcion: 'Barra de combustible nuclear' },
    { Clave: '20101500', Descripcion: 'Equipo de corte' },
    { Clave: '20101501', Descripcion: 'Equipo de minería continua' },
    { Clave: '20101502', Descripcion: 'Cizallas de pared alta' },
    { Clave: '20101503', Descripcion: 'Cortadores de carbón' },
    { Clave: '20101504', Descripcion: 'Cortadores de roca' },
    { Clave: '20101505', Descripcion: 'Cadena de corte para minería' },
    { Clave: '20101506', Descripcion: 'Barra de corte' },
    { Clave: '20101600', Descripcion: 'Cribas y equipos de alimentación' },
    { Clave: '20101601', Descripcion: 'Pantallas' },
    { Clave: '20101602', Descripcion: 'Alimentadores' },
    { Clave: '20101603', Descripcion: 'Pantalla de hoyo de desagüe' },
    { Clave: '20101617', Descripcion: 'Motorreductor' },
    { Clave: '20101618', Descripcion: 'Canaleta alimentadora' },
    { Clave: '20101619', Descripcion: 'Banda alimentadora para pesar' },
    { Clave: '20101620', Descripcion: 'Vibro ? alimentador electromagnético' },
    { Clave: '20101621', Descripcion: 'Vibro ? alimentador electromecánico' },
    { Clave: '20101700', Descripcion: 'Trituradoras, quebrantadoras y amoladores' },
    { Clave: '20101701', Descripcion: 'Trituradores de roca' },
    { Clave: '20101702', Descripcion: 'Trituradores de rollo' },
    { Clave: '20101703', Descripcion: 'Trituradores de cono' },
    { Clave: '20101704', Descripcion: 'Trituradores giratorios' },
    { Clave: '20101705', Descripcion: 'Trituradores de impacto' },
    { Clave: '20101706', Descripcion: 'Trituradores de mandíbula' },
    { Clave: '20101707', Descripcion: 'Plantas de trituración' },
    { Clave: '20101708', Descripcion: 'Molino de varilla' },
    { Clave: '20101709', Descripcion: 'Molino de bola' },
    { Clave: '20101710', Descripcion: 'Maquinaria de pulverización' },
    { Clave: '20101711', Descripcion: 'Rompedores de roca' },
    { Clave: '20101712', Descripcion: 'Moledores de roca' },
    { Clave: '20101713', Descripcion: 'Moledoras de ciclón o vórtex' },
    { Clave: '20101714', Descripcion: 'Placas de mandíbula' },
    { Clave: '20101715', Descripcion: 'Broca trituradora' },
    { Clave: '20101716', Descripcion: 'Martillo triturador' },
    { Clave: '20101800', Descripcion: 'Sistemas mecanizados de soporte en tierra' },
    { Clave: '20101801', Descripcion: 'Apernador de cable' },
    { Clave: '20101802', Descripcion: 'Apernador de tijera' },
    { Clave: '20101803', Descripcion: 'Apernador de boom' },
    { Clave: '20101804', Descripcion: 'Equipo para aplicar concreto lanzado o shotcrete' },
    { Clave: '20101805', Descripcion: 'Repuestos o accesorios de sistema mecanizado de soporte en tierra' },
    { Clave: '20101810', Descripcion: 'Conmutadores' },
    { Clave: '20101900', Descripcion: 'Sistemas secundarios de ruptura de roca' },
    { Clave: '20101901', Descripcion: 'Sistema blockholer o taladro y cargue' },
    { Clave: '20101902', Descripcion: 'Sistemas de impacto repetitivo' },
    { Clave: '20101903', Descripcion: 'Repuestos o accesorios de sistema secundario de ruptura de roca' },
    { Clave: '20102000', Descripcion: 'Sistemas de exploración y desarrollo' },
    { Clave: '20102001', Descripcion: 'Taladros de barreno profundo en el hoyo ith o abajo del hoyo dth' },
    { Clave: '20102002', Descripcion: 'Taladros de barreo profundo de martillo superior' },
    { Clave: '20102003', Descripcion: 'Jumbos neumáticos para perforación de pozos' },
    { Clave: '20102004', Descripcion: 'Jumbos hidráulicos para perforación de pozos' },
    { Clave: '20102005', Descripcion: 'Jumbos neumáticos de desarrollo horizontal' },
    { Clave: '20102006', Descripcion: 'Jumbos hidráulicos de desarrollo horizontal' },
    { Clave: '20102007', Descripcion: 'Taladros de núcleo' },
    { Clave: '20102008', Descripcion: 'Repuestos o accesorios de sistema de exploración o desarrollo' },
    { Clave: '20102100', Descripcion: 'Barrenas de rocas' },
    { Clave: '20102101', Descripcion: 'Taladros de roca neumáticos' },
    { Clave: '20102102', Descripcion: 'Taladros de roca hidráulicos' },
    { Clave: '20102103', Descripcion: 'Taladros de roca manuales' },
    { Clave: '20102104', Descripcion: 'Repuestos y accesorios de taladros de roca' },
    { Clave: '20102105', Descripcion: 'Broca o barrena de acero' },
    { Clave: '20102106', Descripcion: 'Broca o barrena de vacío' },
    { Clave: '20102200', Descripcion: 'Maquinaria de cargue de explosivos' },
    { Clave: '20102201', Descripcion: 'Maquinaria para cargar nitrato de amonio y fuel oil - anfo' },
    { Clave: '20102202', Descripcion: 'Maquinaria para cargar emulsión' },
    { Clave: '20102203', Descripcion: 'Repuestos y accesorios para maquinaria de cargue de explosivos' },
    { Clave: '20102300', Descripcion: 'Vehículos de servicio de minería subterránea' },
    { Clave: '20102301', Descripcion: 'Transporte de personal' },
    { Clave: '20102302', Descripcion: 'Vehículos grúa' },
    { Clave: '20102303', Descripcion: 'Cama bajas para el transporte de material' },
    { Clave: '20102304', Descripcion: 'Transportadores de carga a granel' },
    { Clave: '20102305', Descripcion: 'Vehículos utilitarios' },
    { Clave: '20102306', Descripcion: 'Vehículos de plataforma elevable o elevadores de tijera' },
    { Clave: '20102307', Descripcion: 'Repuestos o accesorios de vehículo de servicio minero subterráneo' },
    { Clave: '20111500', Descripcion: 'Equipo de exploración y perforación' },
    { Clave: '20111504', Descripcion: 'Equipos para perforar pozos de agua' },
    { Clave: '20111505', Descripcion: 'Equipo para la exploración de uranio' },
    { Clave: '20111600', Descripcion: 'Maquinaria de perforación y explotación' },
    { Clave: '20111601', Descripcion: 'Maquinaria de sondeo o de perforación' },
    { Clave: '20111602', Descripcion: 'Maquinaria de ensamble en fondo de pozo' },
    { Clave: '20111603', Descripcion: 'Martillos perforadores' },
    { Clave: '20111604', Descripcion: 'Taladro sobre orugas' },
    { Clave: '20111606', Descripcion: 'Vibradores neumáticos' },
    { Clave: '20111607', Descripcion: 'Maquinaria para hacer túneles' },
    { Clave: '20111608', Descripcion: 'Martillos de poder' },
    { Clave: '20111609', Descripcion: 'Taladro de platina' },
    { Clave: '20111610', Descripcion: 'Maquinaria de inspección de alcantarillas' },
    { Clave: '20111611', Descripcion: 'Taladros giratorios' },
    { Clave: '20111612', Descripcion: 'Taladros de perforación de pozos' },
    { Clave: '20111613', Descripcion: 'Taladros de barreno profundo' },
    { Clave: '20111614', Descripcion: 'Brocas industriales' },
    { Clave: '20111615', Descripcion: 'Perforadora de avance' },
    { Clave: '20111616', Descripcion: 'Torre de perforación' },
    { Clave: '20111617', Descripcion: 'Carros de perforación' },
    { Clave: '20111618', Descripcion: 'Caña de pecar de fondo de pozo' },
    { Clave: '20111619', Descripcion: 'Cono de perforación de pozos' },
    { Clave: '20111620', Descripcion: 'Agarre de martillo' },
    { Clave: '20111621', Descripcion: 'Oscilador de revestimiento' },
    { Clave: '20111622', Descripcion: 'Taladro de circulación reversa' },
    { Clave: '20111623', Descripcion: 'Almeja de diafragma de muro' },
    { Clave: '20111700', Descripcion: 'Accesorios de perforación y explotación' },
    { Clave: '20111701', Descripcion: 'Instrumentos audiovisuales para inspección de pozos' },
    { Clave: '20111702', Descripcion: 'Tapones o anclajes de tubos' },
    { Clave: '20111703', Descripcion: 'Recubrimientos de perforación' },
    { Clave: '20111704', Descripcion: 'Pantallas de perforación' },
    { Clave: '20111705', Descripcion: 'Puntos de pozo' },
    { Clave: '20111706', Descripcion: 'Cuñas de perforación' },
    { Clave: '20111707', Descripcion: 'Adaptadores de herramientas de perforación' },
    { Clave: '20111708', Descripcion: 'Columnas de perforación' },
    { Clave: '20111709', Descripcion: 'Herramientas o kits de accesorios para perforar pozos' },
    { Clave: '20111710', Descripcion: 'Kit de reparación de tapones y tuberías de perforación' },
    { Clave: '20111711', Descripcion: 'Partes y accesorios de tuberías de perforación' },
    { Clave: '20111712', Descripcion: 'Tubo de lavado' },
    { Clave: '20111713', Descripcion: 'Mástil de perforación' },
    { Clave: '20111714', Descripcion: 'Conector de tubería flexible' },
    { Clave: '20111715', Descripcion: 'Recubrimiento de tajo del sitio del pozo' },
    { Clave: '20121000', Descripcion: 'Equipo acidificante' },
    { Clave: '20121001', Descripcion: 'Unidades de mezcla acidificante' },
    { Clave: '20121002', Descripcion: 'Sensores de densidad acidificante' },
    { Clave: '20121003', Descripcion: 'Unidades de bombeo acidificante' },
    { Clave: '20121004', Descripcion: 'Unidades acidificantes' },
    { Clave: '20121005', Descripcion: 'Tubería de aire acidificante' },
    { Clave: '20121006', Descripcion: 'Inyectores de bola acidificantes' },
    { Clave: '20121007', Descripcion: 'Equipo de ácido líquido a granel' },
    { Clave: '20121008', Descripcion: 'Cajas de gota acidificante' },
    { Clave: '20121009', Descripcion: 'Medidores de acidificación' },
    { Clave: '20121010', Descripcion: 'Cajas de unión de acidificación' },
    { Clave: '20121011', Descripcion: 'Sensores de presión de acidificación' },
    { Clave: '20121012', Descripcion: 'Tubería de proceso acidificante' },
    { Clave: '20121013', Descripcion: 'Uniones estrechas de acidificación' },
    { Clave: '20121014', Descripcion: 'Pivotes de acidificación' },
    { Clave: '20121015', Descripcion: 'Hierros de tratamiento de acidificación' },
    { Clave: '20121016', Descripcion: 'Protectores de árboles de acidificación' },
    { Clave: '20121100', Descripcion: 'Equipo para cementar' },
    { Clave: '20121101', Descripcion: 'Unidades de mezclado' },
    { Clave: '20121102', Descripcion: 'Tapones de puente' },
    { Clave: '20121103', Descripcion: 'Equipo de cemento líquido a granel' },
    { Clave: '20121104', Descripcion: 'Equipo de material de cemento a granel' },
    { Clave: '20121105', Descripcion: 'Sensores de densidad del cemento' },
    { Clave: '20121106', Descripcion: 'Unidades de flotación de cemento a granel' },
    { Clave: '20121107', Descripcion: 'Herramientas de flotación de cemento' },
    { Clave: '20121108', Descripcion: 'Tapones limpiadores de equipo de flotación de cemento' },
    { Clave: '20121109', Descripcion: 'Unidades de bombeo de cemento' },
    { Clave: '20121110', Descripcion: 'Retenedores de cemento' },
    { Clave: '20121111', Descripcion: 'Centralizadores' },
    { Clave: '20121112', Descripcion: 'Acoples de pestillo expreso' },
    { Clave: '20121113', Descripcion: 'Collares de flotación' },
    { Clave: '20121114', Descripcion: 'Zapatas de flotación' },
    { Clave: '20121115', Descripcion: 'Herramientas para cementación de campos petroleros' },
    { Clave: '20121116', Descripcion: 'Tapones recuperables de cementación' },
    { Clave: '20121118', Descripcion: 'Cabezales de cemento bajo el mar' },
    { Clave: '20121119', Descripcion: 'Cabezales de cemento en superficie' },
    { Clave: '20121120', Descripcion: 'Centralizador de arco' },
    { Clave: '20121121', Descripcion: 'Centralizador de cuchilla' },
    { Clave: '20121122', Descripcion: 'Centralizador sub de arco' },
    { Clave: '20121123', Descripcion: 'Kit de herramientas de cementación' },
    { Clave: '20121124', Descripcion: 'Kit de equipo de flotación de cemento' },
    { Clave: '20121125', Descripcion: 'Kit de retención de cemento' },
    { Clave: '20121126', Descripcion: 'Partes y accesorios del centralizador' },
    { Clave: '20121127', Descripcion: 'Estabilizador' },
    { Clave: '20121128', Descripcion: 'Herramienta de torque y arrastre de reducción' },
    { Clave: '20121129', Descripcion: 'Partes y accesorios de la herramienta de torque y arrastre de reducción' },
    { Clave: '20121130', Descripcion: 'Partes y accesorios del tapón de puente' },
    { Clave: '20121200', Descripcion: 'Equipo de fracturar' },
    { Clave: '20121201', Descripcion: 'Equipo de fractura líquida a granel' },
    { Clave: '20121202', Descripcion: 'Equipo de fractura a granel usando unidades de soporte' },
    { Clave: '20121203', Descripcion: 'Unidades de control de fracturación' },
    { Clave: '20121204', Descripcion: 'Sensores de densidad de fracturación' },
    { Clave: '20121205', Descripcion: 'Unidades múltiples de fracturación' },
    { Clave: '20121206', Descripcion: 'Equipo de transporte de fracturación usando unidades de soporte' },
    { Clave: '20121207', Descripcion: 'Unidades de bombeo de fracturación' },
    { Clave: '20121208', Descripcion: 'Unidades de mezclado de lechada fracturación' },
    { Clave: '20121209', Descripcion: 'Unidades de mezcla de gel' },
    { Clave: '20121210', Descripcion: 'Misiles de fracturación' },
    { Clave: '20121211', Descripcion: 'Monitores de integridad de bombeo' },
    { Clave: '20121212', Descripcion: 'Tapones de servicio de fracturación' },
    { Clave: '20121213', Descripcion: 'Unidades de estimulación de bombeo' },
    { Clave: '20121300', Descripcion: 'Equipo de control de arena' },
    { Clave: '20121301', Descripcion: 'Tapones obturadores' },
    { Clave: '20121302', Descripcion: 'Flotadores' },
    { Clave: '20121303', Descripcion: 'Sistemas frac pack' },
    { Clave: '20121304', Descripcion: 'Sistemas paquete de gravilla' },
    { Clave: '20121305', Descripcion: 'Zapatas guía' },
    { Clave: '20121306', Descripcion: 'Boquillas de acometida' },
    { Clave: '20121307', Descripcion: 'Acoples de conformación' },
    { Clave: '20121308', Descripcion: 'Rebosamientos de tuberías de producción' },
    { Clave: '20121309', Descripcion: 'Obturadores de control de arena' },
    { Clave: '20121310', Descripcion: 'Equipo de granel líquido para control de arena' },
    { Clave: '20121311', Descripcion: 'Equipo que usa unidades de soporte para control de arena a granel' },
    { Clave: '20121312', Descripcion: 'Sensores de densidad de control de arena' },
    { Clave: '20121313', Descripcion: 'Unidades múltiples de control de arena' },
    { Clave: '20121314', Descripcion: 'Equipo de transporte de arena usando unidades de soporte' },
    { Clave: '20121315', Descripcion: 'Unidades control de bombeo de arena' },
    { Clave: '20121316', Descripcion: 'Pantallas de control de arena' },
    { Clave: '20121317', Descripcion: 'Unidades de mezclado de lechada control de arena' },
    { Clave: '20121318', Descripcion: 'Detectores de arena' },
    { Clave: '20121319', Descripcion: 'Localizadores de ensamble de sello' },
    { Clave: '20121320', Descripcion: 'Uniones de cizalla' },
    { Clave: '20121321', Descripcion: 'Herramientas de levantamiento de manga' },
    { Clave: '20121322', Descripcion: 'Mangas deslizantes' },
    { Clave: '20121323', Descripcion: 'Cuerdas de velocidad' },
    { Clave: '20121324', Descripcion: 'Anillo de control de arena' },
    { Clave: '20121325', Descripcion: 'Patrón de tubo muescado' },
    { Clave: '20121326', Descripcion: 'Partes y accesorios de pantalla de control de arena' },
    { Clave: '20121400', Descripcion: 'Herramientas y equipo de terminación' },
    { Clave: '20121401', Descripcion: 'Subs de atrapado de bola' },
    { Clave: '20121402', Descripcion: 'Uniones de voladura' },
    { Clave: '20121403', Descripcion: 'Boquillas de voladura' },
    { Clave: '20121404', Descripcion: 'Tapón calibrador de cierre' },
    { Clave: '20121405', Descripcion: 'Dispositivos de circulación de producción' },
    { Clave: '20121406', Descripcion: 'Equipo de prueba de culminación' },
    { Clave: '20121407', Descripcion: 'Protectores de línea de control' },
    { Clave: '20121408', Descripcion: 'Herramientas de deflexión' },
    { Clave: '20121409', Descripcion: 'Uniones de expansión de culminación' },
    { Clave: '20121410', Descripcion: 'Acoples de flujo' },
    { Clave: '20121411', Descripcion: 'Equipo de elevación de gas' },
    { Clave: '20121412', Descripcion: 'Herramientas de estabilización de colgador' },
    { Clave: '20121413', Descripcion: 'Bombas hidráulicas de culminación' },
    { Clave: '20121414', Descripcion: 'Herramientas hidráulicas de curado' },
    { Clave: '20121415', Descripcion: 'Sistemas de inyección' },
    { Clave: '20121416', Descripcion: 'Boquillas de estabilización' },
    { Clave: '20121417', Descripcion: 'Colgadores de línea' },
    { Clave: '20121418', Descripcion: 'Herramientas de halar tapones' },
    { Clave: '20121419', Descripcion: 'Herramientas de correr tapones' },
    { Clave: '20121420', Descripcion: 'Tapones de producción' },
    { Clave: '20121421', Descripcion: 'Equipos de bombeo a través de la línea de flujo' },
    { Clave: '20121422', Descripcion: 'Uniones de seguridad de culminación' },
    { Clave: '20121423', Descripcion: 'Ensambles de sello de culminación' },
    { Clave: '20121424', Descripcion: 'Sello hoyo u hoyo pulido' },
    { Clave: '20121425', Descripcion: 'Mandriles de bolsillos laterales' },
    { Clave: '20121427', Descripcion: 'Válvulas de seguridad sub ? superficie' },
    { Clave: '20121428', Descripcion: 'Uniones de desplazamiento' },
    { Clave: '20121429', Descripcion: 'Anclajes de tubería' },
    { Clave: '20121430', Descripcion: 'Ensamblajes de flujo paralelo' },
    { Clave: '20121431', Descripcion: 'Tapón inflable' },
    { Clave: '20121432', Descripcion: 'Partes y accesorios de válvula de control de pozo' },
    { Clave: '20121433', Descripcion: 'Kit de reparación de culminación de hoyo revestido' },
    { Clave: '20121434', Descripcion: 'Kit de reparación de manga deslizante' },
    { Clave: '20121435', Descripcion: 'Kit adaptador de fraguado' },
    { Clave: '20121436', Descripcion: 'Partes y accesorios para el sistema de muelle de pistón' },
    { Clave: '20121437', Descripcion: 'Partes y accesorios para las válvulas de seguridad de sub ? superficie' },
    { Clave: '20121438', Descripcion: 'Partes y accesorios para válvula de muelle de  gas' },
    { Clave: '20121439', Descripcion: 'Mandril tapón de producción' },
    { Clave: '20121440', Descripcion: 'Sub tapón de revestimiento del anillo' },
    { Clave: '20121441', Descripcion: 'Herramienta de fraguado del revestimiento' },
    { Clave: '20121442', Descripcion: 'Bloque de arrastre' },
    { Clave: '20121443', Descripcion: 'Kit de herramientas de fraguado de tapón de revestimiento' },
    { Clave: '20121444', Descripcion: 'Kit de reparación del sistema de revestimiento' },
    { Clave: '20121445', Descripcion: 'Accesorios y partes' },
    { Clave: '20121446', Descripcion: 'Tapón superior revestimiento' },
    { Clave: '20121447', Descripcion: 'Collar fraguado del revestimiento' },
    { Clave: '20121448', Descripcion: 'Junk bonnet' },
    { Clave: '20121449', Descripcion: 'Equipo de muelle de pistón' },
    { Clave: '20121450', Descripcion: 'Mandril muelle de gas' },
    { Clave: '20121451', Descripcion: 'Válvula muelle de gas' },
    { Clave: '20121500', Descripcion: 'Herramientas convencionales de perforación' },
    { Clave: '20121501', Descripcion: 'Preventor de reventones' },
    { Clave: '20121502', Descripcion: 'Controles del preventor de reventones' },
    { Clave: '20121503', Descripcion: 'Raspadores de revestimiento' },
    { Clave: '20121504', Descripcion: 'Collar de perforación' },
    { Clave: '20121505', Descripcion: 'Equipo de núcleos' },
    { Clave: '20121506', Descripcion: 'Protectores de rosca de tubería de perforación' },
    { Clave: '20121507', Descripcion: 'Uniones de herramientas de tubería de perforación' },
    { Clave: '20121508', Descripcion: 'Tubo de perforación' },
    { Clave: '20121509', Descripcion: 'Anillos de calibre' },
    { Clave: '20121510', Descripcion: 'Abridores de hoyos' },
    { Clave: '20121511', Descripcion: 'Escariadores de hoyos' },
    { Clave: '20121513', Descripcion: 'Amortiguadores de fondo de pozo' },
    { Clave: '20121514', Descripcion: 'Subs de perforación' },
    { Clave: '20121515', Descripcion: 'Estabilizadores de fondo de pozo' },
    { Clave: '20121516', Descripcion: 'Propulsores' },
    { Clave: '20121517', Descripcion: 'Partes y accesorios de escariadores de hoyos de perforación' },
    { Clave: '20121518', Descripcion: 'Cabeza de control giratorio' },
    { Clave: '20121519', Descripcion: 'Partes y accesorios de la cabeza de control giratorio' },
    { Clave: '20121520', Descripcion: 'Partes y accesorios del preventor de reventones' },
    { Clave: '20121521', Descripcion: 'Partes y accesorios de los amortiguadores de fondo de pozo' },
    { Clave: '20121522', Descripcion: 'Partes y accesorios de los raspadores de revestimiento' },
    { Clave: '20121523', Descripcion: 'Tarro de perforación' },
    { Clave: '20121524', Descripcion: 'Partes y accesorios del tarro de perforación' },
    { Clave: '20121600', Descripcion: 'Brocas de barrena' },
    { Clave: '20121601', Descripcion: 'Brocas de cortadora fija' },
    { Clave: '20121602', Descripcion: 'Brocas de diamante natural' },
    { Clave: '20121603', Descripcion: 'Brocas de boquilla' },
    { Clave: '20121604', Descripcion: 'Brocas pdc' },
    { Clave: '20121605', Descripcion: 'Brocas cónicas de rodillo de botón de inserción' },
    { Clave: '20121606', Descripcion: 'Brocas de rodillo de diente de acero' },
    { Clave: '20121607', Descripcion: 'Brocas de núcleo' },
    { Clave: '20121608', Descripcion: 'Brocas de bloque' },
    { Clave: '20121609', Descripcion: 'Brocas de minería continuas' },
    { Clave: '20121610', Descripcion: 'Brocas de alimentador' },
    { Clave: '20121611', Descripcion: 'Broca de tamaño grande' },
    { Clave: '20121612', Descripcion: 'Broca de techo' },
    { Clave: '20121613', Descripcion: 'Accesorios de broca' },
    { Clave: '20121700', Descripcion: 'Herramientas de pesca' },
    { Clave: '20121701', Descripcion: 'Bumper subs' },
    { Clave: '20121702', Descripcion: 'Parches de revestimiento' },
    { Clave: '20121703', Descripcion: 'Impulsores de tarro' },
    { Clave: '20121704', Descripcion: 'Subs de chatarra' },
    { Clave: '20121705', Descripcion: 'Molinos o zapatas de quemado' },
    { Clave: '20121706', Descripcion: 'Rebosamientos' },
    { Clave: '20121707', Descripcion: 'Lanzas de pesca en campo petrolero' },
    { Clave: '20121708', Descripcion: 'Herramientas de pesca no especificada' },
    { Clave: '20121709', Descripcion: 'Tubo y extensión de lavado de pesca' },
    { Clave: '20121710', Descripcion: 'Extensión de rebosamiento' },
    { Clave: '20121711', Descripcion: 'Sub de pesca' },
    { Clave: '20121712', Descripcion: 'Partes y accesorios de parche de revestimiento' },
    { Clave: '20121713', Descripcion: 'Buje de operación de tubo de lavado' },
    { Clave: '20121714', Descripcion: 'Bloque de impresión de pesca' },
    { Clave: '20121715', Descripcion: 'Kit de pesca de sistema de cable de acero' },
    { Clave: '20121716', Descripcion: 'Partes y accesorios de la canasta de chatarra' },
    { Clave: '20121717', Descripcion: 'Pescante de agarre' },
    { Clave: '20121718', Descripcion: 'Control de pescante' },
    { Clave: '20121719', Descripcion: 'Tapón de pescante' },
    { Clave: '20121720', Descripcion: 'Partes y accesorios de pescante' },
    { Clave: '20121721', Descripcion: 'Imán de pesca' },
    { Clave: '20121722', Descripcion: 'Grifo de ajuste' },
    { Clave: '20121723', Descripcion: 'Agarre de lanza de pescar' },
    { Clave: '20121724', Descripcion: 'Partes y accesorios de lanza de pescar' },
    { Clave: '20121725', Descripcion: 'Cortador de revestimiento' },
    { Clave: '20121726', Descripcion: 'Grifo de caja' },
    { Clave: '20121727', Descripcion: 'Canasta de desperdicios' },
    { Clave: '20121728', Descripcion: 'Tarro de pesca' },
    { Clave: '20121800', Descripcion: 'Equipo de perforación direccional' },
    { Clave: '20121801', Descripcion: 'Herramientas de geo - dirección' },
    { Clave: '20121802', Descripcion: 'Motores de lodo' },
    { Clave: '20121803', Descripcion: 'Herramientas dirigibles giratorias' },
    { Clave: '20121804', Descripcion: 'Sistemas de control de superficie de perforación direccional' },
    { Clave: '20121805', Descripcion: 'Herramientas de perforación direccional de hoyo derecho' },
    { Clave: '20121806', Descripcion: 'Registro mientras herramientas perforan lwd' },
    { Clave: '20121807', Descripcion: 'Partes y accesorios de registro mientras herramientas perforan lwd' },
    { Clave: '20121808', Descripcion: 'Estabilizador de perforación direccional' },
    { Clave: '20121809', Descripcion: 'Partes y accesorios de herramientas dirigibles giratorias' },
    { Clave: '20121810', Descripcion: 'Sub de taladro direccional' },
    { Clave: '20121811', Descripcion: 'Hélice de perforación direccional' },
    { Clave: '20121812', Descripcion: 'Collar de perforación direccional' },
    { Clave: '20121813', Descripcion: 'Partes y accesorios de motores de lodo' },
    { Clave: '20121900', Descripcion: 'Equipo de medir y registro del pozo' },
    { Clave: '20121901', Descripcion: 'Herramientas acústicas' },
    { Clave: '20121902', Descripcion: 'Instrumentos de control de perforación o lodo' },
    { Clave: '20121903', Descripcion: 'Herramientas de medición de desempeño de perforación' },
    { Clave: '20121904', Descripcion: 'Equipo de medición de flujo' },
    { Clave: '20121905', Descripcion: 'Herramientas de resonancia magnética nuclear' },
    { Clave: '20121906', Descripcion: 'Herramientas nucleares' },
    { Clave: '20121907', Descripcion: 'Equipos de registro de producción' },
    { Clave: '20121908', Descripcion: 'Herramientas de resistividad' },
    { Clave: '20121909', Descripcion: 'Sistemas de levantamiento' },
    { Clave: '20121910', Descripcion: 'Sistemas de telemetría' },
    { Clave: '20121911', Descripcion: 'Herramientas ultrasónicas' },
    { Clave: '20121912', Descripcion: 'Equipo de presión de registro de fondo de pozo' },
    { Clave: '20121913', Descripcion: 'Equipo de prueba de registro de fondo de pozo' },
    { Clave: '20121914', Descripcion: 'Unidades de registro de pozo' },
    { Clave: '20121915', Descripcion: 'Registro de densidad de granel' },
    { Clave: '20121916', Descripcion: 'Cable de fondo de pozo de sensor óptico' },
    { Clave: '20121917', Descripcion: 'Mandril y accesorios de sensor óptico' },
    { Clave: '20121918', Descripcion: 'Cable de superficie de sensor óptico' },
    { Clave: '20121919', Descripcion: 'Herramientas y accesorios de inspección de revestimiento' },
    { Clave: '20121920', Descripcion: 'Herramientas y accesorios de indicador de punto libre' },
    { Clave: '20121921', Descripcion: 'Medidor de radiación' },
    { Clave: '20121922', Descripcion: 'Partes y accesorios de herramienta de rayos gamma' },
    { Clave: '20121923', Descripcion: 'Herramientas y partes de imaginería de pozo' },
    { Clave: '20122000', Descripcion: 'Equipo de prueba y Accesorios' },
    { Clave: '20122001', Descripcion: 'Barra de desplazamiento' },
    { Clave: '20122002', Descripcion: 'Mangas de desplazamiento' },
    { Clave: '20122003', Descripcion: 'Conejo de desplazamiento' },
    { Clave: '20122004', Descripcion: 'Accesorios de prueba' },
    { Clave: '20122005', Descripcion: 'Boquillas de prueba' },
    { Clave: '20122006', Descripcion: 'Tapones de prueba' },
    { Clave: '20122100', Descripcion: 'Equipo de Perforación' },
    { Clave: '20122101', Descripcion: 'Pistolas de cápsulas' },
    { Clave: '20122102', Descripcion: 'Pistolas de revestimiento' },
    { Clave: '20122103', Descripcion: 'Cabezas de despliegue' },
    { Clave: '20122104', Descripcion: 'Explosivos de perforación' },
    { Clave: '20122105', Descripcion: 'Cabezas de disparo' },
    { Clave: '20122106', Descripcion: 'Adaptadores de pistola' },
    { Clave: '20122107', Descripcion: 'Pistola de disparo de alta densidad' },
    { Clave: '20122108', Descripcion: 'Tapones bull de perforación' },
    { Clave: '20122109', Descripcion: 'Herramientas de poner tapones' },
    { Clave: '20122110', Descripcion: 'Equipo de posicionamiento de perforación' },
    { Clave: '20122111', Descripcion: 'Pistolas festoneadas' },
    { Clave: '20122112', Descripcion: 'Subs en tándem' },
    { Clave: '20122113', Descripcion: 'Accesorios de pistola de perforación de tubos completa' },
    { Clave: '20122114', Descripcion: 'Pistola de perforación de tubos completa' },
    { Clave: '20122115', Descripcion: 'Subs ventilación bajo balance' },
    { Clave: '20122200', Descripcion: 'Equipo de prueba del pozo' },
    { Clave: '20122201', Descripcion: 'Booms de bengalas' },
    { Clave: '20122202', Descripcion: 'Quemadores de bengalas' },
    { Clave: '20122203', Descripcion: 'Herramientas de prueba de hoyo' },
    { Clave: '20122204', Descripcion: 'Colector del choke' },
    { Clave: '20122205', Descripcion: 'Colector de desviación' },
    { Clave: '20122206', Descripcion: 'Canastas de flowhead' },
    { Clave: '20122207', Descripcion: 'Pivote de flowhead' },
    { Clave: '20122208', Descripcion: 'Flowheads' },
    { Clave: '20122209', Descripcion: 'Herramientas de formación de cierre' },
    { Clave: '20122210', Descripcion: 'Bengalas de gas' },
    { Clave: '20122211', Descripcion: 'Analizadores de gas de lodos' },
    { Clave: '20122212', Descripcion: 'Muestreadores de petróleo' },
    { Clave: '20122213', Descripcion: 'Separadores de pruebas de pozo' },
    { Clave: '20122214', Descripcion: 'Tubería de superficie de pruebas de pozo' },
    { Clave: '20122215', Descripcion: 'Tanques de compensación' },
    { Clave: '20122216', Descripcion: 'Herramientas de prueba de fondo de pozo' },
    { Clave: '20122300', Descripcion: 'Equipo de Slickline - Cable de Recuperación' },
    { Clave: '20122301', Descripcion: 'Cabezas de adaptador de cable de recuperación' },
    { Clave: '20122302', Descripcion: 'Retardo de envío del cable de recuperación' },
    { Clave: '20122303', Descripcion: 'Guías de campana de cable de recuperación' },
    { Clave: '20122304', Descripcion: 'Cajas ciegas de cable de recuperación' },
    { Clave: '20122305', Descripcion: 'Equipo de presión de fondo de hoyo de cable de recuperación' },
    { Clave: '20122306', Descripcion: 'Herramientas de calibración de cable de recuperación' },
    { Clave: '20122307', Descripcion: 'Volcado de cemento de rescate de equipos cable de recuperación' },
    { Clave: '20122308', Descripcion: 'Cortadores químicos de cable de recuperación' },
    { Clave: '20122309', Descripcion: 'Centralizadores de cadena de herramientas de almeja de cable de recuperación' },
    { Clave: '20122310', Descripcion: 'Centralizadores de cable de acero de almeja de cable de recuperación' },
    { Clave: '20122311', Descripcion: 'Localizadores de collar de cable de recuperación' },
    { Clave: '20122312', Descripcion: 'Colectores de cable de recuperación' },
    { Clave: '20122313', Descripcion: 'Herramientas de colisión de cable de recuperación' },
    { Clave: '20122314', Descripcion: 'Cruces de cable de recuperación' },
    { Clave: '20122315', Descripcion: 'Equipo de medición de profundidad de cable de recuperación' },
    { Clave: '20122316', Descripcion: 'Frasco dewar de cable de recuperación' },
    { Clave: '20122317', Descripcion: 'Herramientas del dipmeter de cable de recuperación' },
    { Clave: '20122318', Descripcion: 'Herramientas direccionales de cable de recuperación' },
    { Clave: '20122319', Descripcion: 'Herramientas de vaya con el diablo de cable de recuperación' },
    { Clave: '20122320', Descripcion: 'Abridores de hoyos de cable de recuperación' },
    { Clave: '20122321', Descripcion: 'Cortadores jet de cable de recuperación' },
    { Clave: '20122322', Descripcion: 'Disparos de desperdicios de cable de recuperación' },
    { Clave: '20122323', Descripcion: 'Herramientas de kickover de cable de recuperación' },
    { Clave: '20122324', Descripcion: 'Uniones de nudillos de cable de recuperación' },
    { Clave: '20122325', Descripcion: 'Bloques de impresión de cable de recuperación' },
    { Clave: '20122326', Descripcion: 'Mandriles de ubicación de cable de recuperación' },
    { Clave: '20122327', Descripcion: 'Mandriles de bloqueo de cable de recuperación' },
    { Clave: '20122328', Descripcion: 'Lubricadores de cable de recuperación' },
    { Clave: '20122329', Descripcion: 'Achicadores mecánicos de cable de recuperación' },
    { Clave: '20122330', Descripcion: 'Plugback mecánico de cable de recuperación' },
    { Clave: '20122331', Descripcion: 'Otras herramientas de cable de recuperación' },
    { Clave: '20122332', Descripcion: 'Raspadores de parafina de cable de recuperación' },
    { Clave: '20122333', Descripcion: 'Enchufe de línea de cable de recuperación' },
    { Clave: '20122334', Descripcion: 'Tenazas para halar o correr el cable de recuperación' },
    { Clave: '20122335', Descripcion: 'Herramientas para cortar el cable de recuperación' },
    { Clave: '20122336', Descripcion: 'Roldanas o bloques de piso de la cable de recuperación' },
    { Clave: '20122338', Descripcion: 'Accesorios de herramientas para halar el cable de recuperación' },
    { Clave: '20122339', Descripcion: 'Herramientas para halar el cable de recuperación' },
    { Clave: '20122340', Descripcion: 'Herramientas para correr el cable de recuperación' },
    { Clave: '20122341', Descripcion: 'Unidades de cable de recuperación' },
    { Clave: '20122342', Descripcion: 'Alambre del cable de recuperación' },
    { Clave: '20122343', Descripcion: 'Herramientas sónicas del cable de recuperación' },
    { Clave: '20122344', Descripcion: 'Barras espaciadoras del cable de recuperación' },
    { Clave: '20122345', Descripcion: 'Terminales de prensado del cable de recuperación' },
    { Clave: '20122346', Descripcion: 'Dispositivos de tensión del cable de recuperación' },
    { Clave: '20122347', Descripcion: 'Tapones de tubos de cable de recuperación' },
    { Clave: '20122348', Descripcion: 'Herramientas ultrasónicas cable de recuperación' },
    { Clave: '20122349', Descripcion: 'Agarres del cable de recuperación' },
    { Clave: '20122350', Descripcion: 'Tarros del cable de recuperación' },
    { Clave: '20122351', Descripcion: 'Raspadores del cable de recuperación' },
    { Clave: '20122352', Descripcion: 'Lanza del cable de recuperación' },
    { Clave: '20122353', Descripcion: 'Tallos del cable de recuperación' },
    { Clave: '20122354', Descripcion: 'Válvulas del cable de recuperación' },
    { Clave: '20122356', Descripcion: 'Preventores del cable de recuperación' },
    { Clave: '20122357', Descripcion: 'Aceleradores de tarro del cable de recuperación' },
    { Clave: '20122358', Descripcion: 'Dardo de prueba' },
    { Clave: '20122359', Descripcion: 'Accesorios y partes de herramientas de corrida del cable de recuperación' },
    { Clave: '20122360', Descripcion: 'Punzón de la línea de cable' },
    { Clave: '20122361', Descripcion: 'Válvula de pie' },
    { Clave: '20122362', Descripcion: 'Guía de campana de la línea de cable' },
    { Clave: '20122363', Descripcion: 'Hallador de cable' },
    { Clave: '20122364', Descripcion: 'Cadena de herramientas de línea de cable' },
    { Clave: '20122365', Descripcion: 'Partes y accesorios del centralizador de cable de recuperación' },
    { Clave: '20122366', Descripcion: 'Descentralizador magnético' },
    { Clave: '20122367', Descripcion: 'Herramienta de alado de la línea de cable' },
    { Clave: '20122368', Descripcion: 'Mandril de la línea de cable' },
    { Clave: '20122369', Descripcion: 'Herramienta de establecer línea de cable' },
    { Clave: '20122370', Descripcion: 'Cruce de línea de cable' },
    { Clave: '20122371', Descripcion: 'Centralizador de cable de recuperación' },
    { Clave: '20122372', Descripcion: 'Acople de pivote de línea de cable' },
    { Clave: '20122373', Descripcion: 'Partes y accesorios de herramienta de kickover del cable de recuperación' },
    { Clave: '20122400', Descripcion: 'Equipo de sistemas de producción' },
    { Clave: '20122401', Descripcion: 'Dispositivo golpeador de cable' },
    { Clave: '20122402', Descripcion: 'Evaporadores de producción de campo de petróleo' },
    { Clave: '20122403', Descripcion: 'Probadores hipot' },
    { Clave: '20122404', Descripcion: 'Maquinas de vueltas de campo de petróleo' },
    { Clave: '20122405', Descripcion: 'Elevadores finales de motor' },
    { Clave: '20122406', Descripcion: 'Probadores dieléctricos de aceite' },
    { Clave: '20122407', Descripcion: 'Unidades de llenado al vacío de aceite' },
    { Clave: '20122408', Descripcion: 'Enderezadores de eje de producción de campo petrolero' },
    { Clave: '20122409', Descripcion: 'Bobina de producción de campo de petróleo' },
    { Clave: '20122410', Descripcion: 'Analizadores de vibración' },
    { Clave: '20122500', Descripcion: 'Equipo para tubería flexible' },
    { Clave: '20122501', Descripcion: 'Herramientas de arenadora' },
    { Clave: '20122502', Descripcion: 'Unidades de camión grúa de tubería flexible' },
    { Clave: '20122503', Descripcion: 'Unidades de tubería flexible' },
    { Clave: '20122504', Descripcion: 'Paquetes de manguera de tubería flexible' },
    { Clave: '20122505', Descripcion: 'Sistemas inflables de tubería flexible' },
    { Clave: '20122506', Descripcion: 'Cabezales de inyector de tubería flexible' },
    { Clave: '20122507', Descripcion: 'Equipo de elevación de tubería flexible' },
    { Clave: '20122508', Descripcion: 'Casas de operadores' },
    { Clave: '20122509', Descripcion: 'Fuentes de poder de tubería flexible' },
    { Clave: '20122510', Descripcion: 'Rollos de tubería flexible' },
    { Clave: '20122511', Descripcion: 'Carretes de embobinar tubería flexible' },
    { Clave: '20122512', Descripcion: 'Guías de tubería' },
    { Clave: '20122513', Descripcion: 'Enganches de boca de pozo' },
    { Clave: '20122514', Descripcion: 'Estructuras de soporte de boca de pozo' },
    { Clave: '20122515', Descripcion: 'Tubería flexible de campo petrolero' },
    { Clave: '20122516', Descripcion: 'Cadena de herramientas de tubería flexible' },
    { Clave: '20122518', Descripcion: 'Centralizador de tubería flexible' },
    { Clave: '20122600', Descripcion: 'Equipo sísmico' },
    { Clave: '20122601', Descripcion: 'Sensores análogos sísmicos' },
    { Clave: '20122602', Descripcion: 'Arreglos sísmicos' },
    { Clave: '20122603', Descripcion: 'Aves de cable de serpentina sísmica' },
    { Clave: '20122604', Descripcion: 'Tanqueros de taladro sísmico' },
    { Clave: '20122605', Descripcion: 'Geófonos sísmicos' },
    { Clave: '20122606', Descripcion: 'Sistemas de gravedad sísmicos' },
    { Clave: '20122607', Descripcion: 'Sistemas de malacate de cañón sísmico' },
    { Clave: '20122608', Descripcion: 'Hidrofonos sísmicos' },
    { Clave: '20122609', Descripcion: 'Fuentes de impulso sísmicos' },
    { Clave: '20122610', Descripcion: 'Cables serpentinas marinos sísmicos' },
    { Clave: '20122611', Descripcion: 'Cables del fondo del océano sísmico' },
    { Clave: '20122612', Descripcion: 'Sistemas magnéticos sísmicos' },
    { Clave: '20122613', Descripcion: 'Equipo de posicionar sísmico' },
    { Clave: '20122614', Descripcion: 'Arietes sísmicos' },
    { Clave: '20122615', Descripcion: 'Receptores sísmicos' },
    { Clave: '20122616', Descripcion: 'Sistemas de refracción sísmicos' },
    { Clave: '20122617', Descripcion: 'Controladores de fuente sísmicos' },
    { Clave: '20122618', Descripcion: 'Dispositivos de bobinar sísmicos' },
    { Clave: '20122619', Descripcion: 'Bloques de remolque sísmicos' },
    { Clave: '20122620', Descripcion: 'Puntos de remolque sísmicos' },
    { Clave: '20122621', Descripcion: 'Vibradores sísmicos' },
    { Clave: '20122622', Descripcion: 'Sistemas de grabar sísmicos' },
    { Clave: '20122623', Descripcion: 'Sistemas de procesamiento de datos sísmicos' },
    { Clave: '20122700', Descripcion: 'Mercancía tubular para campo petrolero' },
    { Clave: '20122701', Descripcion: 'Revestimiento para campo petrolero' },
    { Clave: '20122702', Descripcion: 'Acoples para campo petrolero' },
    { Clave: '20122703', Descripcion: 'Tubería corta para campo petrolero' },
    { Clave: '20122704', Descripcion: 'Tubería para campo petrolero' },
    { Clave: '20122705', Descripcion: 'Recubrimiento de tubería para campo petrolero' },
    { Clave: '20122706', Descripcion: 'Revestimiento de conductor' },
    { Clave: '20122707', Descripcion: 'Equipo para operar el revestimiento para conductor' },
    { Clave: '20122708', Descripcion: 'Cruces de la tubería de perforación' },
    { Clave: '20122709', Descripcion: 'Protectores de rosca para campo petrolero' },
    { Clave: '20122710', Descripcion: 'Dispositivo para parar el revestimiento' },
    { Clave: '20122800', Descripcion: 'Equipo y plataformas de reacondicionamiento y perforación' },
    { Clave: '20122801', Descripcion: 'Agitadores de lodos' },
    { Clave: '20122802', Descripcion: 'Depósitos de lodos' },
    { Clave: '20122803', Descripcion: 'Equipo de aire para perforaciones' },
    { Clave: '20122804', Descripcion: 'Taladros en barcazas' },
    { Clave: '20122806', Descripcion: 'Desviadores de fluido' },
    { Clave: '20122807', Descripcion: 'Aparejos de maniobras' },
    { Clave: '20122808', Descripcion: 'Equipo para lecho de perforación' },
    { Clave: '20122809', Descripcion: 'Pivotes de perforación' },
    { Clave: '20122810', Descripcion: 'Barco ? taladro de perforación' },
    { Clave: '20122811', Descripcion: 'Elevadores de taladro de perforación' },
    { Clave: '20122812', Descripcion: 'Estribos del taladro de perforación' },
    { Clave: '20122813', Descripcion: 'Unidades de reacondicionamiento hidráulico' },
    { Clave: '20122814', Descripcion: 'Sistemas de gatos para mover el taladro de perforación' },
    { Clave: '20122815', Descripcion: 'Taladro de perforación marina movido con gatos' },
    { Clave: '20122816', Descripcion: 'Bujes kelly' },
    { Clave: '20122817', Descripcion: 'Válvulas kelly' },
    { Clave: '20122818', Descripcion: 'Escobillas kelly' },
    { Clave: '20122819', Descripcion: 'Kellys' },
    { Clave: '20122820', Descripcion: 'Taladro de perforación en tierra' },
    { Clave: '20122821', Descripcion: 'Equipo de limpieza de lodo' },
    { Clave: '20122822', Descripcion: 'Múltiples para lodo' },
    { Clave: '20122823', Descripcion: 'Mezcladoras de lodo' },
    { Clave: '20122824', Descripcion: 'Equipo para manejo de tubería' },
    { Clave: '20122825', Descripcion: 'Plataforma de taladro de perforación' },
    { Clave: '20122826', Descripcion: 'Pivote de energía o transmisión superior' },
    { Clave: '20122827', Descripcion: 'Patines para taladro' },
    { Clave: '20122828', Descripcion: 'Elevadores del taladro de perforación' },
    { Clave: '20122829', Descripcion: 'Mesas giratorias de taladro de perforación' },
    { Clave: '20122830', Descripcion: 'Plataformas auto ? elevadoras para el reacondicionamiento' },
    { Clave: '20122831', Descripcion: 'Taladros de perforación semi ? sumergibles' },
    { Clave: '20122832', Descripcion: 'Deslizadores del lecho de perforación' },
    { Clave: '20122833', Descripcion: 'Tenazas de reposición' },
    { Clave: '20122834', Descripcion: 'Llave doble automática' },
    { Clave: '20122835', Descripcion: 'Equipo viajero' },
    { Clave: '20122836', Descripcion: 'Barcos de reacondicionamiento' },
    { Clave: '20122837', Descripcion: 'Taladros de reacondicionamiento' },
    { Clave: '20122838', Descripcion: 'Separadores de lodo (lutita)' },
    { Clave: '20122839', Descripcion: 'Desgasificadores de lodo' },
    { Clave: '20122840', Descripcion: 'Bloques de corona' },
    { Clave: '20122841', Descripcion: 'Bloques móviles' },
    { Clave: '20122842', Descripcion: 'Desarenadores de lodo' },
    { Clave: '20122843', Descripcion: 'Separadores de granos finos de lodo' },
    { Clave: '20122844', Descripcion: 'Tenazas de energía' },
    { Clave: '20122845', Descripcion: 'Partes y accesorios para el manejo de tubería' },
    { Clave: '20122846', Descripcion: 'Trampolín' },
    { Clave: '20122847', Descripcion: 'Sub y enchufe de elevador' },
    { Clave: '20122848', Descripcion: 'Dispositivo de arreglo horizontal o unidad de trepidación' },
    { Clave: '20122849', Descripcion: 'Araña de revestimiento de pozo' },
    { Clave: '20122851', Descripcion: 'Unidad de potencia hidráulica' },
    { Clave: '20122900', Descripcion: 'Equipo de registrar datos de superficie' },
    { Clave: '20122901', Descripcion: 'Conductos de registrar datos de superficie' },
    { Clave: '20122902', Descripcion: 'Sensores de registrar datos de superficie' },
    { Clave: '20122903', Descripcion: 'Unidades de registrar datos de superficie' },
    { Clave: '20123000', Descripcion: 'Equipo multilateral' },
    { Clave: '20123001', Descripcion: 'Revestimiento multilateral' },
    { Clave: '20123002', Descripcion: 'Uniones multilaterales' },
    { Clave: '20123003', Descripcion: 'Tapones multilaterales' },
    { Clave: '20123004', Descripcion: 'Partes y accesorios de tapones multilaterales' },
    { Clave: '20123100', Descripcion: 'Herramienta para salida de revestimientos' },
    { Clave: '20123101', Descripcion: 'Herramienta de ajuste de salida de revestimientos' },
    { Clave: '20123102', Descripcion: 'Whipstock de salida de revestimientos' },
    { Clave: '20123200', Descripcion: 'Equipo de expansión en profundidad' },
    { Clave: '20123201', Descripcion: 'Herramienta ranurada de ajuste expandible' },
    { Clave: '20123202', Descripcion: 'Kit de reparación expandible' },
    { Clave: '20123203', Descripcion: 'Revestimiento expandible' },
    { Clave: '20123300', Descripcion: 'Herramientas para revestimientos mientras se perfora' },
    { Clave: '20123301', Descripcion: 'Zapata de taladro' },
    { Clave: '20123302', Descripcion: 'Partes y accesorios de zapata de taladro' },
    { Clave: '20123303', Descripcion: 'Lanza de taladro' },
    { Clave: '20123304', Descripcion: 'Partes y accesorios de lanza de taladro' },
    { Clave: '20131000', Descripcion: 'Lodo de perforar y materiales' },
    { Clave: '20131001', Descripcion: 'Agentes de control de filtración' },
    { Clave: '20131002', Descripcion: 'Espaciadores de fluido' },
    { Clave: '20131003', Descripcion: 'Agentes de circulación perdida' },
    { Clave: '20131004', Descripcion: 'Lodos con base de petróleo' },
    { Clave: '20131005', Descripcion: 'Aumentadores de la rata de penetración' },
    { Clave: '20131006', Descripcion: 'Fluidos centradores' },
    { Clave: '20131007', Descripcion: 'Lodos con base sintética' },
    { Clave: '20131008', Descripcion: 'Agentes para adelgazar el lodo' },
    { Clave: '20131009', Descripcion: 'Lodos con base en agua' },
    { Clave: '20131010', Descripcion: 'Agentes para engruesar el lodo' },
    { Clave: '20131100', Descripcion: 'Apuntalantes de fracturamiento de pozo' },
    { Clave: '20131101', Descripcion: 'Apuntaladores cerámicos' },
    { Clave: '20131102', Descripcion: 'Arenas de fracturación' },
    { Clave: '20131103', Descripcion: 'Apuntaladores cerámicos cubiertos de resina' },
    { Clave: '20131104', Descripcion: 'Arenas de fracturación cubiertas de resina' },
    { Clave: '20131105', Descripcion: 'Bauxitas sinterizadas cubierta de resina' },
    { Clave: '20131106', Descripcion: 'Bauxitas sinterizadas' },
    { Clave: '20131200', Descripcion: 'Fluidos de completar' },
    { Clave: '20131201', Descripcion: 'Salmueras divalentes' },
    { Clave: '20131202', Descripcion: 'Salmueras monovalentes' },
    { Clave: '20131300', Descripcion: 'Cemento de pozo petrolero' },
    { Clave: '20131301', Descripcion: 'Cemento a granel de pozo petrolero' },
    { Clave: '20131302', Descripcion: 'Pozo petrolero cemento tipo i clase a' },
    { Clave: '20131303', Descripcion: 'Pozo petrolero cemento tipo ii clase b' },
    { Clave: '20131304', Descripcion: 'Pozo petrolero cemento clase c' },
    { Clave: '20131305', Descripcion: 'Pozo petrolero cemento clase g' },
    { Clave: '20131306', Descripcion: 'Pozo petrolero cemento clase h' },
    { Clave: '20131307', Descripcion: 'Cemento liviano de pozo petrolero' },
    { Clave: '20131308', Descripcion: 'Pozo petrolero cemento estándar fino tipo iii' },
    { Clave: '20141000', Descripcion: 'Equipo de cabeza de pozo' },
    { Clave: '20141001', Descripcion: 'Actuadores de boca de pozo' },
    { Clave: '20141002', Descripcion: 'Bombas de balancín de boca de pozo' },
    { Clave: '20141003', Descripcion: 'Líneas de flujo de boca de pozo' },
    { Clave: '20141004', Descripcion: 'Válvulas de compuerta de boca de pozo' },
    { Clave: '20141005', Descripcion: 'Reguladores de producción de boca de pozo' },
    { Clave: '20141006', Descripcion: 'Accesorios de boca de pozo o flujo debajo de la superficie de boca de pozo' },
    { Clave: '20141007', Descripcion: 'Accesorios de boca de pozo o flujo de superficie de boca de pozo' },
    { Clave: '20141008', Descripcion: 'Válvulas de seguridad de la superficie de boca de pozo' },
    { Clave: '20141011', Descripcion: 'Adaptador de cabeza de tubería' },
    { Clave: '20141012', Descripcion: 'Revestimiento del cabezal de la carcasa' },
    { Clave: '20141013', Descripcion: 'Carrete del cabezal de la tubería' },
    { Clave: '20141014', Descripcion: 'Carrete del cabezal del revestimiento' },
    { Clave: '20141015', Descripcion: 'Conexiones en t o en cruz de la boca de pozo' },
    { Clave: '20141016', Descripcion: 'Base de llegada de la boca de pozo' },
    { Clave: '20141017', Descripcion: 'Cuerpo de transporte boca de pozo' },
    { Clave: '20141018', Descripcion: 'Colgador de boca de pozo' },
    { Clave: '20141100', Descripcion: 'Sistemas de inyección químicos' },
    { Clave: '20141101', Descripcion: 'Sistemas de inyección de parafina' },
    { Clave: '20141200', Descripcion: 'Equipo de desarenar' },
    { Clave: '20141201', Descripcion: 'Equipo de desarenar producción' },
    { Clave: '20141300', Descripcion: 'Anclas y bombas de chorro de barrenas hacia abajo' },
    { Clave: '20141301', Descripcion: 'Bombas de chorro de fondo de pozo' },
    { Clave: '20141302', Descripcion: 'Partes y accesorios de bombas de chorro de fondo de pozo' },
    { Clave: '20141303', Descripcion: 'Tubería de perforación' },
    { Clave: '20141400', Descripcion: 'Accesorios de producción de barrenas hacia abajo' },
    { Clave: '20141401', Descripcion: 'Topes de tubería' },
    { Clave: '20141500', Descripcion: 'Bombas de barrenas hacia abajo' },
    { Clave: '20141501', Descripcion: 'Bombas eléctricas de fondo de pozo' },
    { Clave: '20141502', Descripcion: 'Bomba de fondo de pozo de cavidad progresiva' },
    { Clave: '20141600', Descripcion: 'Bombas de exportación' },
    { Clave: '20141601', Descripcion: 'Bombas neumáticas de exportación' },
    { Clave: '20141700', Descripcion: 'Plataformas de almacenaje y producción a poca distancia de la costa' },
    { Clave: '20141701', Descripcion: 'Plataformas fijas de producción costa afuera' },
    { Clave: '20141702', Descripcion: 'Plataformas flotantes de producción costa afuera' },
    { Clave: '20141703', Descripcion: 'Plataformas flotantes de almacenamiento costa afuera' },
    { Clave: '20141704', Descripcion: 'Plataformas flotantes de brazo de tensión de producción costa afuera' },
    { Clave: '20141705', Descripcion: 'Plataformas flotantes de brazo de tensión de almacenamiento costa afuera' },
    { Clave: '20141800', Descripcion: 'Contadores para medir el flujo de producción del pozo' },
    { Clave: '20141801', Descripcion: 'Contadores de la turbina de gas de producción del pozo' },
    { Clave: '20141900', Descripcion: 'Equipo de tratamiento de gas' },
    { Clave: '20141901', Descripcion: 'Equipo de tratamiento de gas para producción del pozo' },
    { Clave: '20142000', Descripcion: 'Regeneradores de glicol' },
    { Clave: '20142001', Descripcion: 'Regeneradores de glicol de pozo petrolero' },
    { Clave: '20142100', Descripcion: 'Tratadores de calentadores' },
    { Clave: '20142101', Descripcion: 'Tratadores de calentadores de aceite para pozo' },
    { Clave: '20142200', Descripcion: 'Calentadores de línea' },
    { Clave: '20142201', Descripcion: 'Calentadores de línea eléctrica' },
    { Clave: '20142300', Descripcion: 'Deslizaderos de inyección de producción' },
    { Clave: '20142301', Descripcion: 'Patines de inyección neumática de metanol' },
    { Clave: '20142400', Descripcion: 'Equipo para el lecho del océano' },
    { Clave: '20142401', Descripcion: 'Equipo para producción submarina de boca de pozo' },
    { Clave: '20142402', Descripcion: 'Tuberías submarinas umbilicales o flexibles' },
    { Clave: '20142403', Descripcion: 'Sistema múltiple de distribución de producción submarina' },
    { Clave: '20142404', Descripcion: 'Sistema de bombeo y separación anular vertical' },
    { Clave: '20142405', Descripcion: 'Cruz de producción y componente submarino' },
    { Clave: '20142406', Descripcion: 'Sistema de control submarino' },
    { Clave: '20142407', Descripcion: 'Ensamble de terminación umbilical submarina' },
    { Clave: '20142500', Descripcion: 'Equipo para tratar agua producida' },
    { Clave: '20142501', Descripcion: 'Centrífugas de petróleo del agua del campo petrolífero' },
    { Clave: '20142600', Descripcion: 'Instrumentación del sistema de control de producción' },
    { Clave: '20142601', Descripcion: 'Sistemas inalámbricos de control de producción' },
    { Clave: '20142700', Descripcion: 'Unidades de bombear' },
    { Clave: '20142701', Descripcion: 'Gatos de la varilla de bombeo' },
    { Clave: '20142702', Descripcion: 'Bombas de barra' },
    { Clave: '20142703', Descripcion: 'Bombas de barra mecánica' },
    { Clave: '20142704', Descripcion: 'Ensamble de marco de bombeo y extensión' },
    { Clave: '20142705', Descripcion: 'Ensamble de biela' },
    { Clave: '20142706', Descripcion: 'Ensamble de ecualizador pitman' },
    { Clave: '20142707', Descripcion: 'Ensamble de cabezote' },
    { Clave: '20142708', Descripcion: 'Ensamble de puesto samson' },
    { Clave: '20142709', Descripcion: 'Ensamble de viga de caminar' },
    { Clave: '20142710', Descripcion: 'Ensamble de barra de colgador' },
    { Clave: '20142800', Descripcion: 'Separadores de producción' },
    { Clave: '20142801', Descripcion: 'Separadores de agua petróleo' },
    { Clave: '20142900', Descripcion: 'Tanques y recipientes almacenadores' },
    { Clave: '20142901', Descripcion: 'Tanques almacenadores de petróleo' },
    { Clave: '20142902', Descripcion: 'Tanque de fibra de vidrio' },
    { Clave: '20142903', Descripcion: 'Tanque de acero' },
    { Clave: '20142904', Descripcion: 'Tanque plástico' },
    { Clave: '20142905', Descripcion: 'Tanque receptor de aire' },
    { Clave: '20143000', Descripcion: 'Vástagos de succión' },
    { Clave: '20143001', Descripcion: 'Vástagos de succión de aleación de acero' },
    { Clave: '20143002', Descripcion: 'Varillas cortas' },
    { Clave: '20143003', Descripcion: 'Vástago continuo de succión' },
    { Clave: '20143004', Descripcion: 'Pin de terminación roscada de vástago continuo de succión' },
    { Clave: '20143005', Descripcion: 'Acople deslizante de vástago continuo de succión' },
    { Clave: '20143300', Descripcion: 'Equipo para servicio de oleoductos' },
    { Clave: '20143301', Descripcion: 'Dispositivo limpiador de oleoductos' },
    { Clave: '20143302', Descripcion: 'Equipo para inspeccionar el interior del oleoducto' },
    { Clave: '20143303', Descripcion: 'Cortador robótico del interior del oleoducto' },
    { Clave: '21101500', Descripcion: 'Maquinaria agrícola para preparación del suelo' },
    { Clave: '21101501', Descripcion: 'Arados' },
    { Clave: '21101502', Descripcion: 'Pulverizadores' },
    { Clave: '21101503', Descripcion: 'Cultivadoras' },
    { Clave: '21101504', Descripcion: 'Desmalezadoras' },
    { Clave: '21101505', Descripcion: 'Máquinas desyerbadoras' },
    { Clave: '21101506', Descripcion: 'Máquinas aplanadoras o niveladoras' },
    { Clave: '21101507', Descripcion: 'Rodillos agrícolas' },
    { Clave: '21101508', Descripcion: 'Rodillos para prados o terrenos deportivos' },
    { Clave: '21101509', Descripcion: 'Máquina para drenaje de zanjas' },
    { Clave: '21101511', Descripcion: 'Chorros de riego' },
    { Clave: '21101512', Descripcion: 'Gastos generales de riego' },
    { Clave: '21101513', Descripcion: 'Discos' },
    { Clave: '21101514', Descripcion: 'Arados de subsuelo' },
    { Clave: '21101516', Descripcion: 'Sembradoras' },
    { Clave: '21101517', Descripcion: 'Motocultivador giratorio o de potencia' },
    { Clave: '21101518', Descripcion: 'Esterilizador de suelo' },
    { Clave: '21101519', Descripcion: 'Inyector de suelo' },
    { Clave: '21101520', Descripcion: 'Lanzallamas' },
    { Clave: '21101521', Descripcion: 'Mezclador de fertilizante' },
    { Clave: '21101522', Descripcion: 'Arado de bordes o arado' },
    { Clave: '21101523', Descripcion: 'Sistema automatizado de manejo de maquinaria agrícola' },
    { Clave: '21101600', Descripcion: 'Maquinaria agrícola para siembra y plantado' },
    { Clave: '21101601', Descripcion: 'Plantadoras' },
    { Clave: '21101602', Descripcion: 'Transplantadoras' },
    { Clave: '21101603', Descripcion: 'Sembradoras de grano' },
    { Clave: '21101604', Descripcion: 'Sembradoras de semillas' },
    { Clave: '21101605', Descripcion: 'Equipo para tratamiento de semillas' },
    { Clave: '21101606', Descripcion: 'Excavadoras de agujeros' },
    { Clave: '21101607', Descripcion: 'Remolque de sembradora' },
    { Clave: '21101608', Descripcion: 'Gabinete o cámara para crecimiento de plantas' },
    { Clave: '21101609', Descripcion: 'Cama de semillas (semillero)' },
    { Clave: '21101610', Descripcion: 'Máquina de cubrimiento del suelo' },
    { Clave: '21101611', Descripcion: 'Controlador de tasa de flujo de fertilizante' },
    { Clave: '21101612', Descripcion: 'Germinador de semillas' },
    { Clave: '21101700', Descripcion: 'Maquinaria agrícola para cosechar' },
    { Clave: '21101701', Descripcion: 'Cortadoras de pasto' },
    { Clave: '21101702', Descripcion: 'Máquina segadora de heno' },
    { Clave: '21101703', Descripcion: 'Cosechadoras' },
    { Clave: '21101704', Descripcion: 'Cosechadoras ?trilladoras? o mixtas' },
    { Clave: '21101705', Descripcion: 'Trilladoras' },
    { Clave: '21101706', Descripcion: 'Separadores de cultivos' },
    { Clave: '21101707', Descripcion: 'Piezas de cosechadora o accesorios' },
    { Clave: '21101708', Descripcion: 'Piezas de segadora o accesorios' },
    { Clave: '21101709', Descripcion: 'Embaladora' },
    { Clave: '21101710', Descripcion: 'Secadora de cosecha' },
    { Clave: '21101711', Descripcion: 'Desgranadora' },
    { Clave: '21101712', Descripcion: 'Máquina automática cortadora y empaquetadora de flores' },
    { Clave: '21101800', Descripcion: 'Aparatos dispersores o aspersores para agricultura' },
    { Clave: '21101801', Descripcion: 'Rociadores' },
    { Clave: '21101802', Descripcion: 'Guarda polvos' },
    { Clave: '21101803', Descripcion: 'Aspersores de agua' },
    { Clave: '21101804', Descripcion: 'Dispersores o distribuidores de fertilizante' },
    { Clave: '21101805', Descripcion: 'Generadores de niebla o neblina' },
    { Clave: '21101806', Descripcion: 'Compostadores' },
    { Clave: '21101807', Descripcion: 'Equipo y suministros para polinización' },
    { Clave: '21101808', Descripcion: 'Equipo para protección contra las heladas' },
    { Clave: '21101809', Descripcion: 'Sistema para la fumigación del grano' },
    { Clave: '21101900', Descripcion: 'Equipo para aves de corral y ganado' },
    { Clave: '21101901', Descripcion: 'Ordeñadoras' },
    { Clave: '21101902', Descripcion: 'Equipo para cría de ganado' },
    { Clave: '21101903', Descripcion: 'Incubadoras o polleras para aves de corral' },
    { Clave: '21101904', Descripcion: 'Mezcladoras de forraje' },
    { Clave: '21101905', Descripcion: 'Equipo para identificación de ganado' },
    { Clave: '21101906', Descripcion: 'Equipo de inspección o recolección de huevos' },
    { Clave: '21101907', Descripcion: 'Máquinas para abrevar animales' },
    { Clave: '21101908', Descripcion: 'Tanques refrigeradores de leche' },
    { Clave: '21101909', Descripcion: 'Equipo para el esquilar o peluquear de animales' },
    { Clave: '21101910', Descripcion: 'Peine de lana' },
    { Clave: '21101911', Descripcion: 'Compostador para gallinaza' },
    { Clave: '21101912', Descripcion: 'Espacio para inmovilizar ganado' },
    { Clave: '21101913', Descripcion: 'Cortador de picos' },
    { Clave: '21101914', Descripcion: 'Cerca eléctrica para ganado' },
    { Clave: '21101915', Descripcion: 'Medidor de grasa en la espalda de los cerdos' },
    { Clave: '21102000', Descripcion: 'Maquinaria agrícola para limpieza, selección o clasificación' },
    { Clave: '21102001', Descripcion: 'Máquinas limpiadoras de semillas, grano o legumbres secas' },
    { Clave: '21102002', Descripcion: 'Máquinas seleccionadoras de semillas, grano o legumbres secas' },
    { Clave: '21102003', Descripcion: 'Clasificadoras de semillas, grano o legumbres secas' },
    { Clave: '21102004', Descripcion: 'Equipo para limpieza y descascarillado de arroz' },
    { Clave: '21102005', Descripcion: 'Molino para trituración' },
    { Clave: '21102006', Descripcion: 'Molinos de martillo' },
    { Clave: '21102007', Descripcion: 'Clasificadora de frutas' },
    { Clave: '21102008', Descripcion: 'Equipo para limpiar o descascarar cebada' },
    { Clave: '21102100', Descripcion: 'Maquinaria y equipo para transformación agrícola' },
    { Clave: '21102101', Descripcion: 'Máquinas agrícolas de briqueta' },
    { Clave: '21102102', Descripcion: 'Expulsor de aceite de semilla' },
    { Clave: '21102200', Descripcion: 'Maquinaria y equipo para silvicultura' },
    { Clave: '21102201', Descripcion: 'Descortezadoras' },
    { Clave: '21102202', Descripcion: 'Equipo de explotación forestal' },
    { Clave: '21102203', Descripcion: 'Equipo de reforestación' },
    { Clave: '21102204', Descripcion: 'Sierras para silvicultura' },
    { Clave: '21102205', Descripcion: 'Deslizadores forestales' },
    { Clave: '21102206', Descripcion: 'Barrenos para aumento forestal' },
    { Clave: '21102207', Descripcion: 'Hipsómetro para silvicultura' },
    { Clave: '21102300', Descripcion: 'Equipo para invernadero' },
  ];
}
