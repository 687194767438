// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Auth
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
import { LandingComponent } from './views/pages/landing/landing.component';
import { AutofacturaComponent } from './views/pages/autofactura/autofactura.component';
import { FacturacionComponent } from './views/pages/facturacion/facturacion.component';
import { PreciosComponent } from './views/pages/precios/precios.component';
import { ApiComponent } from './views/pages/soluciones/api/api.component';
import { ContadoresComponent } from './views/pages/soluciones/contadores/contadores.component';
import { WhiteLabelComponent } from './views/pages/soluciones/white-label/white-label.component';
import { VisoorComponent } from './views/pages/visoor/visoor.component';
import { DudasComponent } from './views/pages/dudas/dudas.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'autofactura',
    component: AutofacturaComponent
  },
  {
    path: 'facturacion',
    component: FacturacionComponent
  },
  {
    path: 'precios',
    component: PreciosComponent
  },
  {
    path: 'api',
    component: ApiComponent
  },
  {
    path: 'contadores',
    component: ContadoresComponent
  },
  {
    path: 'white-label',
    component: WhiteLabelComponent
  },
  {
    path: 'porque-visoor',
    component: VisoorComponent
  },
  {
    path: 'dudas',
    component: DudasComponent
  },
  {
    path: 'home',
    redirectTo: ''
  },
  {
    path: '**',
    redirectTo: ''
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '404',
    redirectTo: '',
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true})
  ],
  exports: [RouterModule],

})
export class AppRoutingModule {
}
