export class InvoiceCatClaveUnidadTable {
  // tslint:disable-next-line: variable-name
  public static invoice_cat_clave_prod_serv: any[] = [
    { Clave: '18', Descripcion: 'Tambor de cincuenta y cinco galones (EUA)' },
    { Clave: '19', Descripcion: 'Camión cisterna' },
    { Clave: '26', Descripcion: 'Tonelada real' },
    { Clave: '29', Descripcion: 'Libra por mil pies cuadrados' },
    { Clave: '30', Descripcion: 'Día de potencia de caballos por tonelada métrica de aire seco' },
    { Clave: '31', Descripcion: 'Pescar' },
    { Clave: '32', Descripcion: 'Kilogramo por tonelada métrica seca del aire' },
    { Clave: '36', Descripcion: 'Pie cúbico por minuto por pie cuadrado' },
    { Clave: '44', Descripcion: 'Bolsa a granel de quinientos kilos' },
    { Clave: '45', Descripcion: 'Bolsa a granel de trescientos kilos' },
    { Clave: '46', Descripcion: 'Bolsa a granel de cincuenta libras' },
    { Clave: '47', Descripcion: 'Bolso de cincuenta libras' },
    { Clave: '48', Descripcion: 'Carga masiva' },
    { Clave: '53', Descripcion: 'Kilogramo teórico' },
    { Clave: '54', Descripcion: 'Tonelada teórica (UK)' },
    { Clave: '62', Descripcion: 'Por ciento por 1000 horas' },
    { Clave: '63', Descripcion: 'Tasa de fracaso en el tiempo' },
    { Clave: '64', Descripcion: 'Libra por pulgada cuadrada, calíbre' },
    { Clave: '66', Descripcion: 'Oersted' },
    { Clave: '69', Descripcion: 'Escala específica de prueba' },
    { Clave: '71', Descripcion: 'Voltios amperios por libra' },
    { Clave: '72', Descripcion: 'Vatio por libra' },
    { Clave: '73', Descripcion: 'Amperios por centímetro' },
    { Clave: '76', Descripcion: 'Gauss' },
    { Clave: '77', Descripcion: 'Kilogauss' },
    { Clave: '84', Descripcion: 'Kilopound-force por pulgada cuadrada' },
    { Clave: '90', Descripcion: 'Saybold segundo universal' },
    { Clave: '92', Descripcion: 'Calorías por centímetro cúbico' },
    { Clave: '93', Descripcion: 'Calorías por gramo' },
    { Clave: '94', Descripcion: 'Unidad de curl' },
    { Clave: '95', Descripcion: 'Veinte mil galones' },
    { Clave: '96', Descripcion: 'Diez mil galones (US)' },
    { Clave: '97', Descripcion: 'Diez kilos de tambor' },
    { Clave: '98', Descripcion: 'Quince kilos de tambor' },
    { Clave: '05', Descripcion: 'Ascensor' },
    { Clave: '06', Descripcion: 'Pequeño aerosol' },
    { Clave: '08', Descripcion: 'Montón de calor' },
    { Clave: '10', Descripcion: 'Grupos' },
    { Clave: '11', Descripcion: 'Equipos' },
    { Clave: '13', Descripcion: 'Raciones' },
    { Clave: '14', Descripcion: 'Shot' },
    { Clave: '15', Descripcion: 'Palo, medida militar.' },
    { Clave: '16', Descripcion: 'Tambor de 115 kilogramos' },
    { Clave: '17', Descripcion: 'Tambor de cien libras' },
    { Clave: '1A', Descripcion: 'Milla de carros' },
    { Clave: '1B', Descripcion: 'Recuento de automóviles' },
    { Clave: '1C', Descripcion: 'Conteo de locomotoras' },
    { Clave: '1D', Descripcion: 'Caboose count' },
    { Clave: '1E', Descripcion: 'Coche vacío' },
    { Clave: '1F', Descripcion: 'Milla de tren' },
    { Clave: '1G', Descripcion: 'Galón del uso del combustible (los EUA)' },
    { Clave: '1H', Descripcion: 'Milla de caboose' },
    { Clave: '1I', Descripcion: 'Tipo de interés fijo' },
    { Clave: '1J', Descripcion: 'Tonelada milla' },
    { Clave: '1K', Descripcion: 'Milla locomotora' },
    { Clave: '1L', Descripcion: 'Recuento total de automóviles' },
    { Clave: '1M', Descripcion: 'Milla total del coche' },
    { Clave: '1X', Descripcion: 'Cuarto de milla' },
    { Clave: '20', Descripcion: 'Contenedores de veinte pies' },
    { Clave: '21', Descripcion: 'Contenedor de cuarenta pies' },
    { Clave: '22', Descripcion: 'Decilitro por gramo' },
    { Clave: '23', Descripcion: 'Gramo por centímetro cúbico' },
    { Clave: '24', Descripcion: 'Libra teórica' },
    { Clave: '25', Descripcion: 'Gramo por centímetro cuadrado' },
    { Clave: '27', Descripcion: 'Tonelada teórica' },
    { Clave: '28', Descripcion: 'Kilogramo por metro cuadrado' },
    { Clave: '2A', Descripcion: 'Radián por segundo' },
    { Clave: '2B', Descripcion: 'Radián por segundo cuadrado' },
    { Clave: '2C', Descripcion: 'Roentgen' },
    { Clave: '2G', Descripcion: 'Voltios CA' },
    { Clave: '2H', Descripcion: 'Voltios CD' },
    { Clave: '2I', Descripcion: 'Unidad térmica británica (tabla internacional) por hora' },
    { Clave: '2J', Descripcion: 'Centímetro cúbico por segundo' },
    { Clave: '2K', Descripcion: 'Pie cúbico por hora' },
    { Clave: '2L', Descripcion: 'Pie cúbico por minuto' },
    { Clave: '2M', Descripcion: 'Centímetro por segundo' },
    { Clave: '2N', Descripcion: 'Decibel' },
    { Clave: '2P', Descripcion: 'Kilobyte' },
    { Clave: '2Q', Descripcion: 'Kilobecquerel' },
    { Clave: '2R', Descripcion: 'Kilocurie' },
    { Clave: '2U', Descripcion: 'Megagramo' },
    { Clave: '2V', Descripcion: 'Megagrama por hora' },
    { Clave: '2X', Descripcion: 'Metro por minuto' },
    { Clave: '2Y', Descripcion: 'Milliroentgen' },
    { Clave: '2Z', Descripcion: 'Milivoltio' },
    { Clave: '33', Descripcion: 'Kilopascal por grtr' },
    { Clave: '34', Descripcion: 'Kilopascal por milimetro' },
    { Clave: '35', Descripcion: 'Milimetro por un segundo centimetro cuadrado' },
    { Clave: '37', Descripcion: 'Onza por pie cuadrado' },
    { Clave: '38', Descripcion: 'Onzas por pie cuadrado por 0,01 pulgadas' },
    { Clave: '3B', Descripcion: 'Megajoule' },
    { Clave: '3C', Descripcion: 'Manmonth' },
    { Clave: '3E', Descripcion: 'Libra por libra de producto' },
    { Clave: '3G', Descripcion: 'Libra por pieza de producto' },
    { Clave: '3H', Descripcion: 'Kilogramo por kilogramo de producto' },
    { Clave: '3I', Descripcion: 'Kilogramo por pedazo de producto' },
    { Clave: '40', Descripcion: 'Mililitro por segundo' },
    { Clave: '41', Descripcion: 'Mililitro por minuto' },
    { Clave: '4B', Descripcion: 'Gorra' },
    { Clave: '4C', Descripcion: 'Centistokes' },
    { Clave: '4E', Descripcion: 'Veinte pack' },
    { Clave: '4G', Descripcion: 'Microlitro' },
    { Clave: '4H', Descripcion: 'Micra' },
    { Clave: '4K', Descripcion: 'Miliamperio' },
    { Clave: '4L', Descripcion: 'Megabyte' },
    { Clave: '4M', Descripcion: 'Miligramo por hora' },
    { Clave: '4N', Descripcion: 'Megabequerel' },
    { Clave: '4O', Descripcion: 'Microfaradio' },
    { Clave: '4P', Descripcion: 'Newton por metro' },
    { Clave: '4Q', Descripcion: 'Onza pulgada' },
    { Clave: '4R', Descripcion: 'Onza pie' },
    { Clave: '4T', Descripcion: 'Picofaradio' },
    { Clave: '4U', Descripcion: 'Libra por hora' },
    { Clave: '4W', Descripcion: 'Tonelada (EUA) por hora' },
    { Clave: '4X', Descripcion: 'Kilolitro por hora' },
    { Clave: '56', Descripcion: 'Sitas' },
    { Clave: '57', Descripcion: 'Malla' },
    { Clave: '58', Descripcion: 'kilogramo neto' },
    { Clave: '59', Descripcion: 'Parte por millón' },
    { Clave: '5A', Descripcion: 'Barril por minuto' },
    { Clave: '5B', Descripcion: 'Batch' },
    { Clave: '5C', Descripcion: 'Galón (US) por mil' },
    { Clave: '5E', Descripcion: 'Mmscf/day' },
    { Clave: '5F', Descripcion: 'Libra por mil' },
    { Clave: '5G', Descripcion: 'bomba' },
    { Clave: '5H', Descripcion: 'Escenario' },
    { Clave: '5I', Descripcion: 'Pies cúbicos estándar' },
    { Clave: '5J', Descripcion: 'Caballos de potencia hidráulica' },
    { Clave: '5K', Descripcion: 'Contar por minuto' },
    { Clave: '5P', Descripcion: 'Nivel sísmico' },
    { Clave: '5Q', Descripcion: 'Línea sísmica' },
    { Clave: '60', Descripcion: 'Tanto por ciento en peso' },
    { Clave: '61', Descripcion: 'Parte por mil millones (EUA)' },
    { Clave: '74', Descripcion: 'Milipascal' },
    { Clave: '77', Descripcion: 'Mili-pulgada' },
    { Clave: '80', Descripcion: 'Libra por pulgada cuadrado absoluta' },
    { Clave: '81', Descripcion: 'Henry' },
    { Clave: '85', Descripcion: 'Pie libra-fuerza' },
    { Clave: '87', Descripcion: 'Libra por pie cúbico' },
    { Clave: '89', Descripcion: 'Poise' },
    { Clave: '91', Descripcion: 'Stokes' },
    { Clave: 'A1', Descripcion: '15 ° C calorías' },
    { Clave: 'A10', Descripcion: 'Amperio por metro cuadrado por joule segundo' },
    { Clave: 'A11', Descripcion: 'Ángstrom' },
    { Clave: 'A12', Descripcion: 'Unidad astronómica' },
    { Clave: 'A13', Descripcion: 'Attojoule' },
    { Clave: 'A14', Descripcion: 'Barn' },
    { Clave: 'A15', Descripcion: 'Barn por electrovoltio' },
    { Clave: 'A16', Descripcion: 'Barn por electrovoltio ' },
    { Clave: 'A17', Descripcion: 'Barn por esteroradian' },
    { Clave: 'A18', Descripcion: 'Becquerel por kilogramo' },
    { Clave: 'A19', Descripcion: 'Becquerel por metro cúbico' },
    { Clave: 'A2', Descripcion: 'Amperio por centímetro' },
    { Clave: 'A20', Descripcion: 'Unidad térmica británica (tabla internacional) por segundo pie cuadrado grado rankine.' },
    { Clave: 'A21', Descripcion: 'Unidad térmica británica (tabla internacional) por libra grado rankine' },
    { Clave: 'A22', Descripcion: 'Unidad térmica británica (tabla internacional) por segundo pie grado rankine' },
    { Clave: 'A23', Descripcion: 'Unidad térmica británica (tabla internacional) por hora pie cuadrado grado rankine.' },
    { Clave: 'A24', Descripcion: 'Candela por metro cuadrado' },
    { Clave: 'A25', Descripcion: 'Caballo de vapor' },
    { Clave: 'A26', Descripcion: 'Culombio metro' },
    { Clave: 'A27', Descripcion: 'Culombio metro cuadrado por voltio' },
    { Clave: 'A28', Descripcion: 'Culombio por centímetro cúbico' },
    { Clave: 'A29', Descripcion: 'Culombio por metro cúbico' },
    { Clave: 'A3', Descripcion: 'Amperio por milímetro' },
    { Clave: 'A30', Descripcion: 'Culombio por milimetro cúbico' },
    { Clave: 'A31', Descripcion: 'Culombio por kilogramo-segundo' },
    { Clave: 'A32', Descripcion: 'Culombio por Mol' },
    { Clave: 'A33', Descripcion: 'Culombio por centímetro cuadrado' },
    { Clave: 'A34', Descripcion: 'Culombio por metro cuadrado' },
    { Clave: 'A35', Descripcion: 'Culombio por milímetro cuadrado' },
    { Clave: 'A36', Descripcion: 'Centimetro cúbico por Mol' },
    { Clave: 'A37', Descripcion: 'Decimetro cuadrado por Mol' },
    { Clave: 'A38', Descripcion: 'Cubic pooulo p' },
    { Clave: 'A39', Descripcion: 'Metro cúbico por kilogramo' },
    { Clave: 'A4', Descripcion: 'Amperio por centímetro cuadrado' },
    { Clave: 'A40', Descripcion: 'Metro cubbico por Mol' },
    { Clave: 'A41', Descripcion: 'Amperio por metro cuadrado' },
    { Clave: 'A42', Descripcion: 'Curie por kilogramo' },
    { Clave: 'A43', Descripcion: 'Tonelaje de peso muerto' },
    { Clave: 'A44', Descripcion: 'Decalitro' },
    { Clave: 'A45', Descripcion: 'Decámetro' },
    { Clave: 'A47', Descripcion: 'Decitex' },
    { Clave: 'A48', Descripcion: 'Grado rankine' },
    { Clave: 'A49', Descripcion: 'Negador' },
    { Clave: 'A5', Descripcion: 'Amperio metro cuadrado' },
    { Clave: 'A50', Descripcion: 'Dyne segundo por centímetro cúbico' },
    { Clave: 'A51', Descripcion: 'Dyne segundo por centímetro' },
    { Clave: 'A52', Descripcion: 'Dyne segundo por centímetro a la quinta potencia' },
    { Clave: 'A53', Descripcion: 'Electrónvoltio' },
    { Clave: 'A54', Descripcion: 'Electrovoltio por metro' },
    { Clave: 'A55', Descripcion: 'Electrovoltio por metro cuadrado' },
    { Clave: 'A56', Descripcion: 'Electrovoltio metro cuadrado por kilogramo' },
    { Clave: 'A57', Descripcion: 'Ergio' },
    { Clave: 'A58', Descripcion: 'Erg por centímetro' },
    { Clave: 'A59', Descripcion: 'La cobertura de nubes 8-parte' },
    { Clave: 'A6', Descripcion: 'Amperio por metro cuadrado Kelvin cuadrado' },
    { Clave: 'A60', Descripcion: 'Erg por centímetro cúbico' },
    { Clave: 'A61', Descripcion: 'Erg por gramo' },
    { Clave: 'A62', Descripcion: 'Erg por segundo gramo' },
    { Clave: 'A63', Descripcion: 'Erg por segundo' },
    { Clave: 'A64', Descripcion: 'Erg por segundo centímetro cuadrado' },
    { Clave: 'A65', Descripcion: 'Erg por centímetro cuadrado segundo' },
    { Clave: 'A66', Descripcion: 'Erg centímetro cuadrado' },
    { Clave: 'A67', Descripcion: 'Erg centímetro cuadrado por gramo' },
    { Clave: 'A68', Descripcion: 'Exajoule' },
    { Clave: 'A69', Descripcion: 'Faradio por metro' },
    { Clave: 'A7', Descripcion: 'Amperio por milímetro cuadrado' },
    { Clave: 'A70', Descripcion: 'Femtojoule' },
    { Clave: 'A71', Descripcion: 'Femtómetro' },
    { Clave: 'A73', Descripcion: 'Pie por segundo al cuadrado' },
    { Clave: 'A74', Descripcion: 'Pie libra-fuerza por segundo' },
    { Clave: 'A75', Descripcion: 'Tonelada de carga' },
    { Clave: 'A76', Descripcion: 'Galón' },
    { Clave: 'A77', Descripcion: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de desplazamiento' },
    { Clave: 'A78', Descripcion: 'Gaussiano CGS (Centímetro-Gram-Segundo sistema) unidad de corriente eléctrica' },
    { Clave: 'A79', Descripcion: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de carga eléctrica' },
    { Clave: 'A8', Descripcion: 'Amperio segundo' },
    { Clave: 'A80', Descripcion: 'Gaussiano CGS (Centímetro-Gram-Segundo sistema) unidad de la fuerza del campo eléctrico' },
    { Clave: 'A81', Descripcion: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de polarización eléctrica' },
    { Clave: 'A82', Descripcion: 'Gaussiano CGS (Centímetro-Gram-Segundo sistema) unidad de potencial eléctrico' },
    { Clave: 'A83', Descripcion: 'Gaussian CGS (Centímetro-Gram-Segundo sistema) unidad de magnetización' },
    { Clave: 'A84', Descripcion: 'GigaCulombio por metro cúbico' },
    { Clave: 'A85', Descripcion: 'Gigaelectrónvoltio' },
    { Clave: 'A86', Descripcion: 'Gigahertz' },
    { Clave: 'A87', Descripcion: 'GigaOhm' },
    { Clave: 'A88', Descripcion: 'GigaOhm metro' },
    { Clave: 'A89', Descripcion: 'Gigapascal' },
    { Clave: 'A9', Descripcion: 'Tarífa' },
    { Clave: 'A90', Descripcion: 'Gigawatt' },
    { Clave: 'A91', Descripcion: 'Grado centesimal' },
    { Clave: 'A93', Descripcion: 'Gramo por metro cúbico' },
    { Clave: 'A94', Descripcion: 'Gramo por Mol' },
    { Clave: 'A95', Descripcion: 'Gray' },
    { Clave: 'A96', Descripcion: 'Gray por segundo' },
    { Clave: 'A97', Descripcion: 'Hectopascal' },
    { Clave: 'A98', Descripcion: 'Henry por metro' },
    { Clave: 'A99', Descripcion: 'Bit' },
    { Clave: 'AA', Descripcion: 'Balón' },
    { Clave: 'AB', Descripcion: 'Paquete a granel' },
    { Clave: 'ACR', Descripcion: 'Acre' },
    { Clave: 'ACT', Descripcion: 'Actividad' },
    { Clave: 'AD', Descripcion: 'Byte' },
    { Clave: 'AE', Descripcion: 'Amperio por metro' },
    { Clave: 'AH', Descripcion: 'Minuto adicional' },
    { Clave: 'AI', Descripcion: 'Minuto y medio por llamada' },
    { Clave: 'AJ', Descripcion: 'policía' },
    { Clave: 'AK', Descripcion: 'Braza' },
    { Clave: 'AL', Descripcion: 'Línea de acceso' },
    { Clave: 'AMH', Descripcion: 'Amperio hora' },
    { Clave: 'AMP', Descripcion: 'Amperio' },
    { Clave: 'ANN', Descripcion: 'Año' },
    { Clave: 'AP', Descripcion: 'Libra de aluminio solamente' },
    { Clave: 'APZ', Descripcion: 'Onza troy u onza farmacéutica ' },
    { Clave: 'AQ', Descripcion: 'Unidad del factir anti-hemofilico.' },
    { Clave: 'AR', Descripcion: 'supositorio' },
    { Clave: 'ARE', Descripcion: 'Are' },
    { Clave: 'AS', Descripcion: 'Variedad' },
    { Clave: 'ASM', Descripcion: 'Grado alcohólico en masa' },
    { Clave: 'ASU', Descripcion: 'Grado alcohólico volumétrico' },
    { Clave: 'ATM', Descripcion: 'Atmósfera estándar' },
    { Clave: 'ATT', Descripcion: 'Atmósfera técnica' },
    { Clave: 'AW', Descripcion: 'Relleno de polvo en viales' },
    { Clave: 'AWG', Descripcion: 'Calibre de alambre americano' },
    { Clave: 'AY', Descripcion: 'Montaje' },
    { Clave: 'AZ', Descripcion: 'Unidad térmica británica (tabla internacional) por libra' },
    { Clave: 'B0', Descripcion: 'Btu por pie cúbico' },
    { Clave: 'B1', Descripcion: 'Barril (EUA) por día' },
    { Clave: 'B10', Descripcion: 'Bits por segundo' },
    { Clave: 'B11', Descripcion: 'Joule por kilogramo kelvin' },
    { Clave: 'B12', Descripcion: 'Joule por metro' },
    { Clave: 'B13', Descripcion: 'Joule por metro cuadrado' },
    { Clave: 'B14', Descripcion: 'Joule por metro a la cuarta potencia' },
    { Clave: 'B15', Descripcion: 'Juole por Mol' },
    { Clave: 'B16', Descripcion: 'Juoule por Mol kelvin' },
    { Clave: 'B17', Descripcion: 'Crédito' },
    { Clave: 'B18', Descripcion: 'Segundos joule' },
    { Clave: 'B19', Descripcion: 'Dígito' },
    { Clave: 'B2', Descripcion: 'litera' },
    { Clave: 'B20', Descripcion: 'Joule metro cuadrado por kologramo' },
    { Clave: 'B21', Descripcion: 'Kelvin por watt' },
    { Clave: 'B22', Descripcion: 'Kiloamperio' },
    { Clave: 'B23', Descripcion: 'Kiloamperio por metro cuadrado' },
    { Clave: 'B24', Descripcion: 'Kiloamperio por metro' },
    { Clave: 'B25', Descripcion: 'Kilobecquerel por metrocúbico' },
    { Clave: 'B26', Descripcion: 'KiloCulombio' },
    { Clave: 'B27', Descripcion: 'KiloCulombio por metro cúbico' },
    { Clave: 'B28', Descripcion: 'KiloCulombio por metro cuadrado' },
    { Clave: 'B29', Descripcion: 'Kiloelectrónvoltio' },
    { Clave: 'B3', Descripcion: 'Libra de bateo' },
    { Clave: 'B30', Descripcion: 'Gibibit' },
    { Clave: 'B31', Descripcion: 'Kilogramo metro por segundo' },
    { Clave: 'B32', Descripcion: 'Kilogramo metro cuadrado' },
    { Clave: 'B33', Descripcion: 'Kilogramo metro cuadrado por segundo' },
    { Clave: 'B34', Descripcion: 'Kilogramo por decímetro cúbico' },
    { Clave: 'B35', Descripcion: 'Kilogramo por litro' },
    { Clave: 'B36', Descripcion: 'Calorías (termoquímicas) por gramo' },
    { Clave: 'B37', Descripcion: 'Kilogramo de fuerza' },
    { Clave: 'B38', Descripcion: 'Kilogramo-metro de la fuerza' },
    { Clave: 'B39', Descripcion: 'Kilogramo-fuerza del metro por segundo' },
    { Clave: 'B4', Descripcion: 'Barril, unidad imperial' },
    { Clave: 'B40', Descripcion: 'Kilogramo de fuerza por metro cuadrado' },
    { Clave: 'B41', Descripcion: 'Kilojoule por kelvin' },
    { Clave: 'B42', Descripcion: 'Kilojoule por kilogramo' },
    { Clave: 'B43', Descripcion: 'Kilojoule por kilogramo kelvin' },
    { Clave: 'B44', Descripcion: 'Kilojoule por Mol ' },
    { Clave: 'B45', Descripcion: 'KiloMol' },
    { Clave: 'B46', Descripcion: 'KiloMol por metro cúbico' },
    { Clave: 'B47', Descripcion: 'Kilonewton' },
    { Clave: 'B48', Descripcion: 'Kilonewton metro' },
    { Clave: 'B49', Descripcion: 'KiloOhm' },
    { Clave: 'B5', Descripcion: 'palanquilla' },
    { Clave: 'B50', Descripcion: 'KiloOhm metro' },
    { Clave: 'B51', Descripcion: 'KiloLibra' },
    { Clave: 'B52', Descripcion: 'Kilosegundo' },
    { Clave: 'B53', Descripcion: 'Kilosiemens' },
    { Clave: 'B54', Descripcion: 'Kilosiemens por metro' },
    { Clave: 'B55', Descripcion: 'Kilovoltio por metro' },
    { Clave: 'B56', Descripcion: 'Kiloweber por metro' },
    { Clave: 'B57', Descripcion: 'Año luz' },
    { Clave: 'B58', Descripcion: 'Litro por Mol' },
    { Clave: 'B59', Descripcion: 'Lumen hora' },
    { Clave: 'B6', Descripcion: 'Bollo' },
    { Clave: 'B60', Descripcion: 'Lumen por metro cuadrado' },
    { Clave: 'B61', Descripcion: 'Luminosidad por watt' },
    { Clave: 'B62', Descripcion: 'Lumen segundo' },
    { Clave: 'B63', Descripcion: 'Hora de luz' },
    { Clave: 'B64', Descripcion: 'Segundo de luz' },
    { Clave: 'B65', Descripcion: 'Maxwell' },
    { Clave: 'B66', Descripcion: 'Megaamperio por metro cuadrado' },
    { Clave: 'B67', Descripcion: 'Megabecquerel por kilogramo' },
    { Clave: 'B68', Descripcion: 'Gigabit' },
    { Clave: 'B69', Descripcion: 'MegaCulombio por metro cúbico' },
    { Clave: 'B7', Descripcion: 'Ciclo' },
    { Clave: 'B70', Descripcion: 'MegaCulombio por metro cuadrado' },
    { Clave: 'B71', Descripcion: 'Megaelectrónvoltio' },
    { Clave: 'B72', Descripcion: 'Megagramo por metro cúbico' },
    { Clave: 'B73', Descripcion: 'Meganewton' },
    { Clave: 'B74', Descripcion: 'Meganewton metro' },
    { Clave: 'B75', Descripcion: 'MegaOhm' },
    { Clave: 'B76', Descripcion: 'MegaOhm metro' },
    { Clave: 'B77', Descripcion: 'Megasiemens por metro' },
    { Clave: 'B78', Descripcion: 'Megavoltio ' },
    { Clave: 'B79', Descripcion: 'Megavoltio por metro' },
    { Clave: 'B8', Descripcion: 'Joule por metro cúbico' },
    { Clave: 'B80', Descripcion: 'Gigabit por segundo' },
    { Clave: 'B81', Descripcion: 'Reciprocidad del metro cuadrado, reciprocidad del segundo' },
    { Clave: 'B82', Descripcion: 'Pulgadas por pie lineal' },
    { Clave: 'B83', Descripcion: 'Metro a la cuarta potencia' },
    { Clave: 'B84', Descripcion: 'Microamperio' },
    { Clave: 'B85', Descripcion: 'Microbar' },
    { Clave: 'B86', Descripcion: 'MicroCulombio' },
    { Clave: 'B87', Descripcion: 'MicroCulombio por metro cúbico' },
    { Clave: 'B88', Descripcion: 'MicroCulombio por metro cuadrado' },
    { Clave: 'B89', Descripcion: 'Microfaradio por metro' },
    { Clave: 'B9', Descripcion: 'Batt' },
    { Clave: 'B90', Descripcion: 'Microhenry' },
    { Clave: 'B91', Descripcion: 'Microhenry por metro' },
    { Clave: 'B92', Descripcion: 'Micronewton' },
    { Clave: 'B93', Descripcion: 'Micronewton metro' },
    { Clave: 'B94', Descripcion: 'Micro Ohm' },
    { Clave: 'B95', Descripcion: 'MicroOhm metro' },
    { Clave: 'B96', Descripcion: 'Micropascal' },
    { Clave: 'B97', Descripcion: 'Microrradián' },
    { Clave: 'B98', Descripcion: 'Microsegundo' },
    { Clave: 'B99', Descripcion: 'Microsiemens' },
    { Clave: 'BAR', Descripcion: 'Bar [unidad de presión]' },
    { Clave: 'BB', Descripcion: 'Caja base' },
    { Clave: 'BFT', Descripcion: 'Tablero de pies' },
    { Clave: 'BH', Descripcion: 'Cepillo' },
    { Clave: 'BHP', Descripcion: 'Potencia al freno' },
    { Clave: 'BIL', Descripcion: 'Billon' },
    { Clave: 'BLD', Descripcion: 'Barril seco (EUA)' },
    { Clave: 'BLL', Descripcion: 'Barril (EUA)' },
    { Clave: 'BP', Descripcion: 'Tabledo de cien pies' },
    { Clave: 'BPM', Descripcion: 'Latidos por minuto' },
    { Clave: 'BQL', Descripcion: 'Becquerel' },
    { Clave: 'BTU', Descripcion: 'Unidad térmica británica (tabla internacional)' },
    { Clave: 'BUA', Descripcion: 'Bushel (EUA)' },
    { Clave: 'BUI', Descripcion: 'Bushel (UK)' },
    { Clave: 'BW', Descripcion: 'Peso base' },
    { Clave: 'BZ', Descripcion: 'Millones de BTUs' },
    { Clave: 'C0', Descripcion: 'Llamada' },
    { Clave: 'C1', Descripcion: 'Libra de producto compuesto (peso total)' },
    { Clave: 'C10', Descripcion: 'Milifaradio' },
    { Clave: 'C11', Descripcion: 'Miligalón' },
    { Clave: 'C12', Descripcion: 'Miligramo por metro' },
    { Clave: 'C13', Descripcion: 'MilliGray' },
    { Clave: 'C14', Descripcion: 'Milihenry' },
    { Clave: 'C15', Descripcion: 'Milijoule' },
    { Clave: 'C16', Descripcion: 'Milímetro por segundo' },
    { Clave: 'C17', Descripcion: 'Milímetro cuadrado por segundo' },
    { Clave: 'C18', Descripcion: 'MiliMol' },
    { Clave: 'C19', Descripcion: 'Mol por kilogramo' },
    { Clave: 'C2', Descripcion: 'Carset' },
    { Clave: 'C20', Descripcion: 'Milinewton' },
    { Clave: 'C21', Descripcion: 'Kibibit' },
    { Clave: 'C22', Descripcion: 'Milinewton por metro' },
    { Clave: 'C23', Descripcion: 'MiliOhm metro' },
    { Clave: 'C24', Descripcion: 'Milipascal segundo' },
    { Clave: 'C25', Descripcion: 'Milirradián' },
    { Clave: 'C26', Descripcion: 'Milisegundo' },
    { Clave: 'C27', Descripcion: 'Milisiemens' },
    { Clave: 'C28', Descripcion: 'MilliSievert' },
    { Clave: 'C29', Descripcion: 'Militesla' },
    { Clave: 'C3', Descripcion: 'Microvoltios por metro' },
    { Clave: 'C30', Descripcion: 'Milivoltio por metro' },
    { Clave: 'C31', Descripcion: 'Miliwatt' },
    { Clave: 'C32', Descripcion: 'Miliwatt por metro cuadrado' },
    { Clave: 'C33', Descripcion: 'Miliweber' },
    { Clave: 'C34', Descripcion: 'Mol' },
    { Clave: 'C35', Descripcion: 'Mol por decimetro cúbico' },
    { Clave: 'C36', Descripcion: 'Mol por metro cúbico' },
    { Clave: 'C37', Descripcion: 'Kilobits' },
    { Clave: 'C38', Descripcion: 'Mol por litro' },
    { Clave: 'C39', Descripcion: 'Nanoamperio' },
    { Clave: 'C4', Descripcion: 'partido de carga' },
    { Clave: 'C40', Descripcion: 'NanoCulombio' },
    { Clave: 'C41', Descripcion: 'Nanofaradio' },
    { Clave: 'C42', Descripcion: 'Nanofaradio por metro' },
    { Clave: 'C43', Descripcion: 'Nanohenry' },
    { Clave: 'C44', Descripcion: 'Nanohenry por metro' },
    { Clave: 'C45', Descripcion: 'Nanómetro' },
    { Clave: 'C46', Descripcion: 'NanoOhm metro' },
    { Clave: 'C47', Descripcion: 'Nanosegundo' },
    { Clave: 'C48', Descripcion: 'Nanotesla' },
    { Clave: 'C49', Descripcion: 'Nanowatt' },
    { Clave: 'C5', Descripcion: 'Costo' },
    { Clave: 'C50', Descripcion: 'Neperio' },
    { Clave: 'C51', Descripcion: 'Neperio por segundo' },
    { Clave: 'C52', Descripcion: 'Picómetro' },
    { Clave: 'C53', Descripcion: 'Newton metro segundo' },
    { Clave: 'C54', Descripcion: 'Newton metro cuadrado por kilogramo cuadrado' },
    { Clave: 'C55', Descripcion: 'Newton por metro cuadrado' },
    { Clave: 'C56', Descripcion: 'Newton por milimetro cuadrado' },
    { Clave: 'C57', Descripcion: 'Newton segundo' },
    { Clave: 'C58', Descripcion: 'Segundos newton por metro' },
    { Clave: 'C59', Descripcion: 'Octava' },
    { Clave: 'C6', Descripcion: 'celda' },
    { Clave: 'C60', Descripcion: 'Ohm centímetro' },
    { Clave: 'C61', Descripcion: 'Ohm metro' },
    { Clave: 'C62', Descripcion: 'Uno' },
    { Clave: 'C63', Descripcion: 'Pársec' },
    { Clave: 'C64', Descripcion: 'Pascal por kelvin' },
    { Clave: 'C65', Descripcion: 'Pascal segundo' },
    { Clave: 'C66', Descripcion: 'Segundos pascal por metro cúbico' },
    { Clave: 'C67', Descripcion: 'Segundos pascal por metro ' },
    { Clave: 'C68', Descripcion: 'Petajoule' },
    { Clave: 'C69', Descripcion: 'Phon' },
    { Clave: 'C7', Descripcion: 'Centipoise' },
    { Clave: 'C70', Descripcion: 'Picoamperio' },
    { Clave: 'C71', Descripcion: 'PicoCulombio' },
    { Clave: 'C72', Descripcion: 'Picofaradio por metro' },
    { Clave: 'C73', Descripcion: 'Picohenry' },
    { Clave: 'C74', Descripcion: 'Kilobits por segundo' },
    { Clave: 'C75', Descripcion: 'Picowatt' },
    { Clave: 'C76', Descripcion: 'Picowatt  por metro cuadrado' },
    { Clave: 'C77', Descripcion: 'Calibre de libra' },
    { Clave: 'C78', Descripcion: 'Libra fuerza' },
    { Clave: 'C79', Descripcion: 'Kilovoltios horas amperios' },
    { Clave: 'C8', Descripcion: 'MilliCulombio por kilogramo' },
    { Clave: 'C80', Descripcion: 'Rad' },
    { Clave: 'C81', Descripcion: 'Radián' },
    { Clave: 'C82', Descripcion: 'Radianmetro cuadrado por Mol' },
    { Clave: 'C83', Descripcion: 'Rradian metro cuadrado por kilogramo' },
    { Clave: 'C84', Descripcion: 'Radían por metro' },
    { Clave: 'C85', Descripcion: 'Reciprocidad Ámstrong' },
    { Clave: 'C86', Descripcion: 'Reciprocidad del metro cúbico' },
    { Clave: 'C87', Descripcion: 'Reciprocidad metro cúbico por segundo' },
    { Clave: 'C88', Descripcion: 'Recíproco joule por metro cúbico' },
    { Clave: 'C89', Descripcion: 'Henry recíproco' },
    { Clave: 'C9', Descripcion: 'Grupo bobinas' },
    { Clave: 'C90', Descripcion: 'Recíproco Henry' },
    { Clave: 'C91', Descripcion: 'Recíprocidad de kelvin ó kelvin a la potencia menos 1' },
    { Clave: 'C92', Descripcion: 'Reciprocidad Metro' },
    { Clave: 'C93', Descripcion: 'Reciprocidad Metro cuadrado' },
    { Clave: 'C94', Descripcion: 'Reciprocidad Minuto' },
    { Clave: 'C95', Descripcion: 'Reciprocidad Mol' },
    { Clave: 'C96', Descripcion: 'Reciprocidad Pascal o pascal a la potencia menos 1 ' },
    { Clave: 'C97', Descripcion: 'Reciprocidad Segundo' },
    { Clave: 'C98', Descripcion: 'Segundo recíproco por metro cúbico' },
    { Clave: 'C99', Descripcion: 'Reciprocidad Segundo por metro cuadrado' },
    { Clave: 'CCT', Descripcion: 'Capacidad de carga en toneladas métricas' },
    { Clave: 'CDL', Descripcion: 'Candela' },
    { Clave: 'CE', Descripcion: 'Cada mes' },
    { Clave: 'CEL', Descripcion: 'Grados celsius' },
    { Clave: 'CEN', Descripcion: 'Centenar' },
    { Clave: 'CG', Descripcion: 'Tarjeta' },
    { Clave: 'CGM', Descripcion: 'Centígramo' },
    { Clave: 'CK', Descripcion: 'Conector' },
    { Clave: 'CKG', Descripcion: 'Culombio por kilogramo' },
    { Clave: 'CLF', Descripcion: 'Cientos de hojas' },
    { Clave: 'CLT', Descripcion: 'Centilitro' },
    { Clave: 'CMK', Descripcion: 'Centímetro cuadrado' },
    { Clave: 'CMQ', Descripcion: 'Centímetro cúbico' },
    { Clave: 'CMT', Descripcion: 'Centímetro' },
    { Clave: 'CNP', Descripcion: 'Cientos de paquetes' },
    { Clave: 'CNT', Descripcion: 'Cental (UK)' },
    { Clave: 'COU', Descripcion: 'Culombio' },
    { Clave: 'CTG', Descripcion: 'Contenido en gramos' },
    { Clave: 'CTM', Descripcion: 'Quilatage métrico' },
    { Clave: 'CTN', Descripcion: 'Tonelada de contenido (métrica)' },
    { Clave: 'CUR', Descripcion: 'Curie' },
    { Clave: 'CWA', Descripcion: 'Hundred pound' },
    { Clave: 'CWI', Descripcion: 'Hundredweight' },
    { Clave: 'CZ', Descripcion: 'Combo' },
    { Clave: 'D03', Descripcion: 'Kilovatio hora por hora' },
    { Clave: 'D04', Descripcion: 'Lot [unidad de peso]' },
    { Clave: 'D1', Descripcion: 'Segundo recíproco por estereorradián' },
    { Clave: 'D10', Descripcion: 'Siemens por metro' },
    { Clave: 'D11', Descripcion: 'Mebibit' },
    { Clave: 'D12', Descripcion: 'Siemens metro cuadrado por Mol' },
    { Clave: 'D13', Descripcion: 'Sievert' },
    { Clave: 'D14', Descripcion: 'Yarda mil lineal' },
    { Clave: 'D15', Descripcion: 'Sone' },
    { Clave: 'D16', Descripcion: 'Centimetro cuadrado por erg' },
    { Clave: 'D17', Descripcion: 'Centimetro cuadrado por esteroradian erg' },
    { Clave: 'D18', Descripcion: 'Metro kelvin' },
    { Clave: 'D19', Descripcion: 'Metro cuadrado kelvin por watt.' },
    { Clave: 'D2', Descripcion: 'Segundo recíproco por estereorradián metro cuadrado' },
    { Clave: 'D20', Descripcion: 'Metro cuadrado por joule' },
    { Clave: 'D21', Descripcion: 'Metro cuadrado por kilogramo' },
    { Clave: 'D22', Descripcion: 'Metro cuadrado por Mol' },
    { Clave: 'D23', Descripcion: 'Gramo pluma (proteína)' },
    { Clave: 'D24', Descripcion: 'Metro cuadrado por esteroradian' },
    { Clave: 'D25', Descripcion: 'Metro cuadrado por esteroradian joule' },
    { Clave: 'D26', Descripcion: 'Metro cuadrado por voltiosegundo' },
    { Clave: 'D27', Descripcion: 'Estereorradían' },
    { Clave: 'D28', Descripcion: 'sifón' },
    { Clave: 'D29', Descripcion: 'Terahertz' },
    { Clave: 'D30', Descripcion: 'Terajoule' },
    { Clave: 'D31', Descripcion: 'Terawatt' },
    { Clave: 'D32', Descripcion: 'Terawatt hora ' },
    { Clave: 'D33', Descripcion: 'Tesla' },
    { Clave: 'D34', Descripcion: 'Tex' },
    { Clave: 'D35', Descripcion: 'Calorías (termoquímicas)' },
    { Clave: 'D36', Descripcion: 'Megabit' },
    { Clave: 'D37', Descripcion: 'Calorías (termoquímicas) por gramo de kelvin' },
    { Clave: 'D38', Descripcion: 'Calorías (termoquímicas) por segundo centímetro kelvin' },
    { Clave: 'D39', Descripcion: 'Calorías (termoquímicas) por segundo centímetro cuadrado kelvin' },
    { Clave: 'D40', Descripcion: 'Mil litros' },
    { Clave: 'D41', Descripcion: 'Tonelada por metro cúbico' },
    { Clave: 'D42', Descripcion: 'Año tropical' },
    { Clave: 'D43', Descripcion: 'Unidad de masa atómica unificada' },
    { Clave: 'D44', Descripcion: 'Var' },
    { Clave: 'D45', Descripcion: 'Voltio cuadrado por kelvin cuadrado' },
    { Clave: 'D46', Descripcion: 'Voltioio-amperio' },
    { Clave: 'D47', Descripcion: 'Voltio por centímetro' },
    { Clave: 'D48', Descripcion: 'Voltio por Kelvin ' },
    { Clave: 'D49', Descripcion: 'Millivoltio por Kelvin ' },
    { Clave: 'D5', Descripcion: 'Kilogramo por centímetro cuadrado' },
    { Clave: 'D50', Descripcion: 'Voltio por metro' },
    { Clave: 'D51', Descripcion: 'Voltio por milímetro' },
    { Clave: 'D52', Descripcion: 'Watt por kelvin' },
    { Clave: 'D53', Descripcion: 'Watt por metro kelvin' },
    { Clave: 'D54', Descripcion: 'Watt por metro cuadrado' },
    { Clave: 'D55', Descripcion: 'Watt por metro cuadrado kelvin' },
    { Clave: 'D56', Descripcion: 'Watt por metro cuadrado kelvin a la cuarta potencia' },
    { Clave: 'D57', Descripcion: 'Watt por estereorradián' },
    { Clave: 'D58', Descripcion: 'Watt por estereorradián metro cuadrado' },
    { Clave: 'D59', Descripcion: 'Weber por metro' },
    { Clave: 'D6', Descripcion: 'Roentgen por segundo' },
    { Clave: 'D60', Descripcion: 'Weber por milímetro' },
    { Clave: 'D61', Descripcion: 'Minuto [unidad de ángulo]' },
    { Clave: 'D62', Descripcion: 'Segundo [unidad de ángulo]' },
    { Clave: 'D63', Descripcion: 'Libro' },
    { Clave: 'D64', Descripcion: 'bloquear' },
    { Clave: 'D65', Descripcion: 'Redondo' },
    { Clave: 'D66', Descripcion: 'casete' },
    { Clave: 'D67', Descripcion: 'Dólar por hora' },
    { Clave: 'D68', Descripcion: 'Número de palabras' },
    { Clave: 'D69', Descripcion: 'Pulgada a la cuarta potencia' },
    { Clave: 'D7', Descripcion: 'Sandwich' },
    { Clave: 'D70', Descripcion: 'Calorías (tabla internacional)' },
    { Clave: 'D71', Descripcion: 'Calorías (tabla internacional) por segundo centímetro kelvin' },
    { Clave: 'D72', Descripcion: 'Calorías (tabla internacional) por segundo centímetro cuadrado kelvin' },
    { Clave: 'D73', Descripcion: 'Joule metro cuadrado' },
    { Clave: 'D74', Descripcion: 'Kilogramo por Mol' },
    { Clave: 'D75', Descripcion: 'Calorías (tabla internacional) por gramo' },
    { Clave: 'D76', Descripcion: 'Calorías (tabla internacional) por gramo de kelvin' },
    { Clave: 'D77', Descripcion: 'MegaCulombio' },
    { Clave: 'D78', Descripcion: 'Megajoule por segundo' },
    { Clave: 'D79', Descripcion: 'Viga' },
    { Clave: 'D8', Descripcion: 'Draize score' },
    { Clave: 'D80', Descripcion: 'Microwatt' },
    { Clave: 'D81', Descripcion: 'Microtesla' },
    { Clave: 'D82', Descripcion: 'Microvoltio' },
    { Clave: 'D83', Descripcion: 'Milinewton metro' },
    { Clave: 'D85', Descripcion: 'Microwatt por metro cuadrado' },
    { Clave: 'D86', Descripcion: 'MiliCulombio' },
    { Clave: 'D87', Descripcion: 'MiliMol por kilogramo' },
    { Clave: 'D88', Descripcion: 'MiliCulombio por metro cúbico' },
    { Clave: 'D89', Descripcion: 'MiliCulombio por metro cuadrado' },
    { Clave: 'D9', Descripcion: 'Dyne por centímetro cuadrado' },
    { Clave: 'D90', Descripcion: 'Metro cúbico (neta)' },
    { Clave: 'D91', Descripcion: 'Rem' },
    { Clave: 'D92', Descripcion: 'banda' },
    { Clave: 'D93', Descripcion: 'Segundo por metro cúbico' },
    { Clave: 'D94', Descripcion: 'Segundo por metro cúbico Radian' },
    { Clave: 'D95', Descripcion: 'Joule por gramo' },
    { Clave: 'D96', Descripcion: 'Libra bruta' },
    { Clave: 'D98', Descripcion: 'Libra masiva' },
    { Clave: 'D99', Descripcion: 'manga' },
    { Clave: 'DAA', Descripcion: 'Decar' },
    { Clave: 'DAD', Descripcion: 'Decena de días' },
    { Clave: 'DAY', Descripcion: 'Día' },
    { Clave: 'DB', Descripcion: 'Libra seca' },
    { Clave: 'DC', Descripcion: 'Disco (disco)' },
    { Clave: 'DD', Descripcion: 'Grado [unidad de ángulo]' },
    { Clave: 'DE', Descripcion: 'Acuerdo' },
    { Clave: 'DEC', Descripcion: 'Década' },
    { Clave: 'DG', Descripcion: 'Decigramo' },
    { Clave: 'DI', Descripcion: 'dispensador' },
    { Clave: 'DJ', Descripcion: 'Decagramo' },
    { Clave: 'DLT', Descripcion: 'Decilitro' },
    { Clave: 'DMA', Descripcion: 'Decámetro cúbico' },
    { Clave: 'DMK', Descripcion: 'Decímetro cuadrado ' },
    { Clave: 'DMO', Descripcion: 'Kiloliter norma' },
    { Clave: 'DMQ', Descripcion: 'Decímetro cúbico' },
    { Clave: 'DMT', Descripcion: 'Decímetro' },
    { Clave: 'DN', Descripcion: 'Decinewton metro' },
    { Clave: 'DPC', Descripcion: 'Docenas de piezas' },
    { Clave: 'DPR', Descripcion: 'Docenas de pares' },
    { Clave: 'DPT', Descripcion: 'Peso de desplazamiento' },
    { Clave: 'DQ', Descripcion: 'registro de datos' },
    { Clave: 'DRA', Descripcion: 'Dram (EUA)' },
    { Clave: 'DRI', Descripcion: 'Dram (UK)' },
    { Clave: 'DRL', Descripcion: 'Docena de rodillos' },
    { Clave: 'DRM', Descripcion: 'Drachm (UK)' },
    { Clave: 'DS', Descripcion: 'monitor' },
    { Clave: 'DT', Descripcion: 'Tonelada seca' },
    { Clave: 'DTN', Descripcion: 'Decitonelada métrica' },
    { Clave: 'DU', Descripcion: 'dina' },
    { Clave: 'DWT', Descripcion: 'Pennyweight' },
    { Clave: 'Dx', Descripcion: 'Dyne por centímetro' },
    { Clave: 'DY', Descripcion: 'Libro de directorio' },
    { Clave: 'DZN', Descripcion: 'Docena' },
    { Clave: 'DZP', Descripcion: 'Docena de paquete' },
    { Clave: 'E01', Descripcion: 'Newton por centímetro cuadrado' },
    { Clave: 'E07', Descripcion: 'Megawatt hora por hora' },
    { Clave: 'E08', Descripcion: 'Megavatios por hertz' },
    { Clave: 'E09', Descripcion: 'Miliamperio hora' },
    { Clave: 'E10', Descripcion: 'Día de grado' },
    { Clave: 'E11', Descripcion: 'Gigacalorie' },
    { Clave: 'E12', Descripcion: 'Mille' },
    { Clave: 'E14', Descripcion: 'Kilocaloría (tabla internacional)' },
    { Clave: 'E15', Descripcion: 'Kilocaloría (termoquímica) por hora' },
    { Clave: 'E16', Descripcion: 'Millón de btu (ti) por hora' },
    { Clave: 'E17', Descripcion: 'Pie cúbico por segundo' },
    { Clave: 'E18', Descripcion: 'Tonelada por hora' },
    { Clave: 'E19', Descripcion: 'Ping' },
    { Clave: 'E20', Descripcion: 'Megabit por segundo' },
    { Clave: 'E21', Descripcion: 'Shares' },
    { Clave: 'E22', Descripcion: 'Tue' },
    { Clave: 'E23', Descripcion: 'Neumático' },
    { Clave: 'E25', Descripcion: 'Unidad activa' },
    { Clave: 'E27', Descripcion: 'Dosis' },
    { Clave: 'E28', Descripcion: 'Tonelada seca de aire' },
    { Clave: 'E3', Descripcion: 'remolque' },
    { Clave: 'E30', Descripcion: 'Hebra' },
    { Clave: 'E31', Descripcion: 'Metro cuadrado por litro' },
    { Clave: 'E32', Descripcion: 'Litros por hora' },
    { Clave: 'E33', Descripcion: 'Por mil pies' },
    { Clave: 'E34', Descripcion: 'Gigabyte' },
    { Clave: 'E35', Descripcion: 'Terabyte' },
    { Clave: 'E36', Descripcion: 'Petabyte' },
    { Clave: 'E37', Descripcion: 'Pixel' },
    { Clave: 'E38', Descripcion: 'Megapíxeles' },
    { Clave: 'E39', Descripcion: 'Puntos por pulgada' },
    { Clave: 'E4', Descripcion: 'Kilo bruto' },
    { Clave: 'E40', Descripcion: 'Parte por cien mil' },
    { Clave: 'E41', Descripcion: 'Kilogramo-fuerza por milímetro cuadrado' },
    { Clave: 'E42', Descripcion: 'Kilogramo-fuerza por centímetro cuadrado' },
    { Clave: 'E43', Descripcion: 'Joule por centímetro cuadrado' },
    { Clave: 'E44', Descripcion: 'Metros kilogramo-fuerza por centímetro cuadrado' },
    { Clave: 'E45', Descripcion: 'MiliOhm' },
    { Clave: 'E46', Descripcion: 'Kilovatio hora por metro cúbico' },
    { Clave: 'E47', Descripcion: 'Kilovatio hora por kelvin' },
    { Clave: 'E48', Descripcion: 'Unidad de servicio' },
    { Clave: 'E49', Descripcion: 'Día de trabajo' },
    { Clave: 'E5', Descripcion: 'Tonelada métrica larga' },
    { Clave: 'E50', Descripcion: 'Unidad de cuenta' },
    { Clave: 'E51', Descripcion: 'Trabajo' },
    { Clave: 'E52', Descripcion: 'Run foot' },
    { Clave: 'E53', Descripcion: 'Prueba' },
    { Clave: 'E54', Descripcion: 'Viaje' },
    { Clave: 'E55', Descripcion: 'Utilizar' },
    { Clave: 'E56', Descripcion: 'Bien' },
    { Clave: 'E57', Descripcion: 'Zona' },
    { Clave: 'E58', Descripcion: 'Exabit por segundo' },
    { Clave: 'E59', Descripcion: 'Exbibyte' },
    { Clave: 'E60', Descripcion: 'Pebibyte' },
    { Clave: 'E61', Descripcion: 'Tebibyte' },
    { Clave: 'E62', Descripcion: 'Gibibyte' },
    { Clave: 'E63', Descripcion: 'Mebibyte' },
    { Clave: 'E64', Descripcion: 'Kibibyte' },
    { Clave: 'E65', Descripcion: 'Exbibit por metro' },
    { Clave: 'E66', Descripcion: 'Exbibit por metro cuadrado' },
    { Clave: 'E67', Descripcion: 'Exbibit por metro cúbico' },
    { Clave: 'E68', Descripcion: 'Gigabyte por segundo' },
    { Clave: 'E69', Descripcion: 'Gibibit por metro' },
    { Clave: 'E70', Descripcion: 'Gibibit por metro cuadrado' },
    { Clave: 'E71', Descripcion: 'Gibibit por metro cúbico' },
    { Clave: 'E72', Descripcion: 'Kibibit por metro' },
    { Clave: 'E73', Descripcion: 'Kibibit por metro cuadrado' },
    { Clave: 'E74', Descripcion: 'Kikibit por metro cúbico.' },
    { Clave: 'E75', Descripcion: 'Mebbit por metro.' },
    { Clave: 'E76', Descripcion: 'Mebbitt por metro cuadrado.' },
    { Clave: 'E77', Descripcion: 'Mebbit por metro cúbico. ' },
    { Clave: 'E78', Descripcion: 'Petabit' },
    { Clave: 'E79', Descripcion: 'Pebibit por segundo.' },
    { Clave: 'E80', Descripcion: 'Pebibit por metro.' },
    { Clave: 'E81', Descripcion: 'Pebibit por metro cuadrado.' },
    { Clave: 'E82', Descripcion: 'Pebibit por metro cúbico.' },
    { Clave: 'E83', Descripcion: 'Tebibit.' },
    { Clave: 'E84', Descripcion: 'Tebibit por segundo' },
    { Clave: 'E85', Descripcion: 'Tebibit por metro.' },
    { Clave: 'E86', Descripcion: 'Tebibit por metro cúbico.' },
    { Clave: 'E87', Descripcion: 'Tebibit por metro cuadrado' },
    { Clave: 'E88', Descripcion: 'Bit por metro' },
    { Clave: 'E89', Descripcion: 'Bit por metro cuadrado' },
    { Clave: 'E90', Descripcion: 'Centímetro recíproco' },
    { Clave: 'E91', Descripcion: 'Día recíproco' },
    { Clave: 'E92', Descripcion: 'Decímetro cúbico por hora' },
    { Clave: 'E93', Descripcion: 'Kilogramo por hora' },
    { Clave: 'E94', Descripcion: 'KiloMol por segundo' },
    { Clave: 'E95', Descripcion: 'Mol por segundo' },
    { Clave: 'E96', Descripcion: 'Grado por segundo' },
    { Clave: 'E97', Descripcion: 'Mililitro por gadro celsius metro' },
    { Clave: 'E98', Descripcion: 'Grado celsius por kelvin' },
    { Clave: 'E99', Descripcion: 'Hectopascal por bar' },
    { Clave: 'EA', Descripcion: 'Elemento' },
    { Clave: 'EB', Descripcion: 'Casilla de correo electrónico' },
    { Clave: 'EP', Descripcion: 'Paquete de once' },
    { Clave: 'EQ', Descripcion: 'Galón equivalente' },
    { Clave: 'F01', Descripcion: 'Bit por metro cúbico' },
    { Clave: 'F02', Descripcion: 'Kelvin por kelvin' },
    { Clave: 'F03', Descripcion: 'Kilopascal por bar' },
    { Clave: 'F04', Descripcion: 'Milibar por bar' },
    { Clave: 'F05', Descripcion: 'Megapascal por bar' },
    { Clave: 'F06', Descripcion: 'Poise por bar' },
    { Clave: 'F07', Descripcion: 'Pascal por bar' },
    { Clave: 'F08', Descripcion: 'Miliamperio por pulgada' },
    { Clave: 'F1', Descripcion: 'Mil pies cúbicos por día' },
    { Clave: 'F10', Descripcion: 'Kelvin por hora' },
    { Clave: 'F11', Descripcion: 'Kelvin por minuto' },
    { Clave: 'F12', Descripcion: 'Kelvin por segundo' },
    { Clave: 'F13', Descripcion: 'Slug' },
    { Clave: 'F14', Descripcion: 'Gramo por kelvin' },
    { Clave: 'F15', Descripcion: 'Kilogramo por kelvin' },
    { Clave: 'F16', Descripcion: 'Miligramo por kelvin' },
    { Clave: 'F17', Descripcion: 'Libra fuerza por pie' },
    { Clave: 'F18', Descripcion: 'Kilogramo centímetro cuadrado' },
    { Clave: 'F19', Descripcion: 'Kilogramo milimetro cuadrado' },
    { Clave: 'F20', Descripcion: 'Libra pulgada cuadrada' },
    { Clave: 'F21', Descripcion: 'Libra fuerza pulgada' },
    { Clave: 'F22', Descripcion: 'Libra fuerza por pie entre amperio' },
    { Clave: 'F23', Descripcion: 'Gramo por decímetro cúbico' },
    { Clave: 'F24', Descripcion: 'Kilogramo por kiloMol' },
    { Clave: 'F25', Descripcion: 'Gramo por hertz' },
    { Clave: 'F26', Descripcion: 'Gramo por día' },
    { Clave: 'F27', Descripcion: 'Gramo por hora' },
    { Clave: 'F28', Descripcion: 'Gramo por minuto' },
    { Clave: 'F29', Descripcion: 'Gramo por segundo' },
    { Clave: 'F30', Descripcion: 'Kilogramo por día' },
    { Clave: 'F31', Descripcion: 'Kilogramo por minuto' },
    { Clave: 'F32', Descripcion: 'Miligramo por dia' },
    { Clave: 'F33', Descripcion: 'Miligramo por minuto' },
    { Clave: 'F34', Descripcion: 'Miligramo por segundo' },
    { Clave: 'F35', Descripcion: 'Gramo por día kelvin' },
    { Clave: 'F36', Descripcion: 'Gramo por hora kelvin' },
    { Clave: 'F37', Descripcion: 'Gramo por minuto kelvin' },
    { Clave: 'F38', Descripcion: 'Gramo por segundo kelvin' },
    { Clave: 'F39', Descripcion: 'Kilogramo por día kelvin' },
    { Clave: 'F40', Descripcion: 'Kilogramo por hora kelvin' },
    { Clave: 'F41', Descripcion: 'Kilogramo por minuto kelvin' },
    { Clave: 'F42', Descripcion: 'Kilogramo por segundo kelvin' },
    { Clave: 'F43', Descripcion: 'Miligramo por día kelvin' },
    { Clave: 'F44', Descripcion: 'Miligramo por hora kelvin' },
    { Clave: 'F45', Descripcion: 'Miligramo por minuto kelvin' },
    { Clave: 'F46', Descripcion: 'Miligramo por segundo kelvin' },
    { Clave: 'F47', Descripcion: 'Newton por milímetro' },
    { Clave: 'F48', Descripcion: 'Libra fuerza por pulgada' },
    { Clave: 'F49', Descripcion: 'Rod (Unidad de distancia)' },
    { Clave: 'F50', Descripcion: 'Micrómetro por kelvin' },
    { Clave: 'F51', Descripcion: 'Centímetro por kelvin' },
    { Clave: 'F52', Descripcion: 'Metro por kelvin' },
    { Clave: 'F53', Descripcion: 'Mililitro por kelvin' },
    { Clave: 'F54', Descripcion: 'MiliOhm por metro' },
    { Clave: 'F55', Descripcion: 'Ohm por milla (milla estatal)' },
    { Clave: 'F56', Descripcion: 'Ohm por kilómetro' },
    { Clave: 'F57', Descripcion: 'Miliamperio por libra-fuerza por pulgada cuadrada' },
    { Clave: 'F58', Descripcion: 'Bar recíproco' },
    { Clave: 'F59', Descripcion: 'Miliamperio por bar' },
    { Clave: 'F60', Descripcion: 'Grado celsius por bar' },
    { Clave: 'F61', Descripcion: 'Kelvin por bar' },
    { Clave: 'F62', Descripcion: 'Gramo por día bar' },
    { Clave: 'F63', Descripcion: 'Gramo por hora bar' },
    { Clave: 'F64', Descripcion: 'Gramo por minuto bar' },
    { Clave: 'F65', Descripcion: 'Gramo por segundo bar' },
    { Clave: 'F66', Descripcion: 'Kililogramo por día bar' },
    { Clave: 'F67', Descripcion: 'Kilogramo por hora bar' },
    { Clave: 'F68', Descripcion: 'Kilogramo por minuto bar' },
    { Clave: 'F69', Descripcion: 'Kilogramo por segundo bar' },
    { Clave: 'F70', Descripcion: 'Miligramo por día bar' },
    { Clave: 'F71', Descripcion: 'Miligramo por hora bar' },
    { Clave: 'F72', Descripcion: 'Miligramo por minuto bar' },
    { Clave: 'F73', Descripcion: 'Miligramo por segundo bar' },
    { Clave: 'F74', Descripcion: 'Gramo por bar' },
    { Clave: 'F75', Descripcion: 'Miligramo por bar' },
    { Clave: 'F76', Descripcion: 'Miliamperio por milímetro' },
    { Clave: 'F77', Descripcion: 'Pascal segundo por kelvin' },
    { Clave: 'F78', Descripcion: 'Pulgada de agua' },
    { Clave: 'F79', Descripcion: 'Pulgada de mercurio' },
    { Clave: 'F80', Descripcion: 'Caballos de fuerza de agua' },
    { Clave: 'F81', Descripcion: 'Bar por kelvin' },
    { Clave: 'F82', Descripcion: 'Hectopascal por kelvin' },
    { Clave: 'F83', Descripcion: 'Kilopascal por kelvin' },
    { Clave: 'F84', Descripcion: 'Milibar por kelvin' },
    { Clave: 'F85', Descripcion: 'Megapascal por kelvin' },
    { Clave: 'F86', Descripcion: 'Poise por kelvin' },
    { Clave: 'F87', Descripcion: 'Voltio por litro minuto' },
    { Clave: 'F88', Descripcion: 'Newton centímetro' },
    { Clave: 'F89', Descripcion: 'Newton metro por grados' },
    { Clave: 'F9', Descripcion: 'Fibra por centímetro cúbico de aire' },
    { Clave: 'F90', Descripcion: 'Newton metro por amperio' },
    { Clave: 'F91', Descripcion: 'Bar litro por segundo' },
    { Clave: 'F92', Descripcion: 'Bar metro cúbico por segundo' },
    { Clave: 'F93', Descripcion: 'Hectopascal litro por segundo' },
    { Clave: 'F94', Descripcion: 'Hectopascal metro cúbico por segundo' },
    { Clave: 'F95', Descripcion: 'Milibar litro por segundo' },
    { Clave: 'F96', Descripcion: 'Milibar metro cúbico por segundo' },
    { Clave: 'F97', Descripcion: 'Megapascal litro por segundo' },
    { Clave: 'F98', Descripcion: 'Megapascal metro cúbico por segundo' },
    { Clave: 'F99', Descripcion: 'Pascal litro por segundo' },
    { Clave: 'FAH', Descripcion: 'Grado fahrenheit' },
    { Clave: 'FAR', Descripcion: 'Farad' },
    { Clave: 'FB', Descripcion: 'campo' },
    { Clave: 'FBM', Descripcion: 'Medidor de fibra' },
    { Clave: 'FC', Descripcion: 'Mil pies cúbicos' },
    { Clave: 'FD', Descripcion: 'Millones de partículas por pie cúbico' },
    { Clave: 'FE', Descripcion: 'Pie de pista' },
    { Clave: 'FF', Descripcion: 'Cien metros cúbicos' },
    { Clave: 'FG', Descripcion: 'Parche transdérmico' },
    { Clave: 'FH', Descripcion: 'MicroMol' },
    { Clave: 'FIT', Descripcion: 'Fallas en el tiempo' },
    { Clave: 'FL', Descripcion: 'Flake ton' },
    { Clave: 'FM', Descripcion: 'Millones de pies cúbicos' },
    { Clave: 'FOT', Descripcion: 'Pie' },
    { Clave: 'FP', Descripcion: 'Libra por pie cuadrado' },
    { Clave: 'FR', Descripcion: 'Pie por minuto' },
    { Clave: 'FS', Descripcion: 'Pie por segundo' },
    { Clave: 'FTK', Descripcion: 'Pie cuadrado' },
    { Clave: 'FTQ', Descripcion: 'Pie cúbico' },
    { Clave: 'G01', Descripcion: 'Pascal metro cúbico por segundo' },
    { Clave: 'G04', Descripcion: 'Centímetro por bar' },
    { Clave: 'G05', Descripcion: 'Metro por bar ' },
    { Clave: 'G06', Descripcion: 'Milímetro por bar ' },
    { Clave: 'G08', Descripcion: 'Pulgada cuadrada por segundo' },
    { Clave: 'G09', Descripcion: 'Metro cuadrado por segundo kelvin' },
    { Clave: 'G10', Descripcion: 'Stokes por kelvin' },
    { Clave: 'G11', Descripcion: 'Gramo por centímetro cúbico bar' },
    { Clave: 'G12', Descripcion: 'Gramo por decímetro cúbico bar' },
    { Clave: 'G13', Descripcion: 'Gramo por litro bar' },
    { Clave: 'G14', Descripcion: 'Gramo por metro cúbico bar' },
    { Clave: 'G15', Descripcion: 'Gramo por mililitro bar' },
    { Clave: 'G16', Descripcion: 'Kilogramo por centímetro cúbico bar' },
    { Clave: 'G17', Descripcion: 'Kilogramo por litro bar' },
    { Clave: 'G18', Descripcion: 'Kilogramo por metro cúbico bar' },
    { Clave: 'G19', Descripcion: 'Newton metro por kilogramo' },
    { Clave: 'G2', Descripcion: 'Galón (EUA) por minuto' },
    { Clave: 'G20', Descripcion: 'Libra-fuerza pie por libra' },
    { Clave: 'G21', Descripcion: 'Taza (unidad de volumen)' },
    { Clave: 'G23', Descripcion: 'Peck' },
    { Clave: 'G24', Descripcion: 'Cucharada (estados unidos)' },
    { Clave: 'G25', Descripcion: 'Cucharilla (estados unidos)' },
    { Clave: 'G26', Descripcion: 'Estere' },
    { Clave: 'G27', Descripcion: 'Centímetro cúbico por kelvin' },
    { Clave: 'G28', Descripcion: 'Litro por kelvin' },
    { Clave: 'G29', Descripcion: 'Metro cúbico por kelvin' },
    { Clave: 'G3', Descripcion: 'Galón (RU) por minuto' },
    { Clave: 'G30', Descripcion: 'Mililitro por klevin' },
    { Clave: 'G31', Descripcion: 'Kilogramo por centímetro cúbico' },
    { Clave: 'G32', Descripcion: 'Onza (avoirdupois) por yarda cúbica' },
    { Clave: 'G33', Descripcion: 'Gramo por centímetro cúbico kelvin' },
    { Clave: 'G34', Descripcion: 'Gramo por decímetro cúbico kelvin' },
    { Clave: 'G35', Descripcion: 'Gramo por litro kelvin' },
    { Clave: 'G36', Descripcion: 'Gramo por metro cúbico kelvin' },
    { Clave: 'G37', Descripcion: 'Gramo por mililitro kelvin' },
    { Clave: 'G38', Descripcion: 'Kilogramo por centímetro cúbico kelvin' },
    { Clave: 'G39', Descripcion: 'Kilogramo por litro kelvin' },
    { Clave: 'G40', Descripcion: 'Kilogramo por metro cúbico kelvin' },
    { Clave: 'G41', Descripcion: 'Metro cuadrado por segundo bar' },
    { Clave: 'G42', Descripcion: 'Microsiemens por centímetro' },
    { Clave: 'G43', Descripcion: 'Microsiemens por metro' },
    { Clave: 'G44', Descripcion: 'Nanosiemens por centímetro' },
    { Clave: 'G45', Descripcion: 'Nanosiemens por metro' },
    { Clave: 'G46', Descripcion: 'Stokes por bar' },
    { Clave: 'G47', Descripcion: 'Centímetro cúbico por día' },
    { Clave: 'G48', Descripcion: 'Centímetro cúbico por hora' },
    { Clave: 'G49', Descripcion: 'Centímetro cúbico por minuto' },
    { Clave: 'G50', Descripcion: 'Galón por hora' },
    { Clave: 'G51', Descripcion: 'Litro por segundo' },
    { Clave: 'G52', Descripcion: 'Metro cúbico por día' },
    { Clave: 'G53', Descripcion: 'Metro cúbico por minuto' },
    { Clave: 'G54', Descripcion: 'Mililitro por día' },
    { Clave: 'G55', Descripcion: 'Mililitro por hora' },
    { Clave: 'G56', Descripcion: 'Pulgada cúbica por hora' },
    { Clave: 'G57', Descripcion: 'Pulgada cúbica por minuto' },
    { Clave: 'G58', Descripcion: 'Pulgada cúbica por segundo' },
    { Clave: 'G59', Descripcion: 'Miliamperio por litro minuto' },
    { Clave: 'G60', Descripcion: 'Voltio por bar' },
    { Clave: 'G61', Descripcion: 'Centímetro cúbico por día kelvin' },
    { Clave: 'G62', Descripcion: 'Centímetro cúbico por hora kelvin' },
    { Clave: 'G63', Descripcion: 'Centímetro cúbico por minuto kelvin' },
    { Clave: 'G64', Descripcion: 'Centímetro cúbico por segundo kelvin' },
    { Clave: 'G65', Descripcion: 'Litro por día kelvin' },
    { Clave: 'G66', Descripcion: 'Litro por hora kelvin' },
    { Clave: 'G67', Descripcion: 'Litro por minuto kelvin' },
    { Clave: 'G68', Descripcion: 'Litro por segundo kelvin' },
    { Clave: 'G69', Descripcion: 'Metro cúbico por día kelvin' },
    { Clave: 'G7', Descripcion: 'Hoja de microficha' },
    { Clave: 'G70', Descripcion: 'Metro cúbico por hora kelvin' },
    { Clave: 'G71', Descripcion: 'Metro cúbico por minuto kelvin' },
    { Clave: 'G72', Descripcion: 'Metro cúbico por segundo kelvin' },
    { Clave: 'G73', Descripcion: 'Mililitro por día kelvin' },
    { Clave: 'G74', Descripcion: 'Mililitro por hora kelvin' },
    { Clave: 'G75', Descripcion: 'Mililitro por minuto kelvin' },
    { Clave: 'G76', Descripcion: 'Mililitro por segundo kelvin' },
    { Clave: 'G77', Descripcion: 'Milímetro a la cuarta potencia ' },
    { Clave: 'G78', Descripcion: 'Centímetro cúbico por día bar' },
    { Clave: 'G79', Descripcion: 'Centímetro cúbico por hora bar' },
    { Clave: 'G80', Descripcion: 'Centímetro cúbico por minuto bar' },
    { Clave: 'G81', Descripcion: 'Centímetro cúbico por segundo bar' },
    { Clave: 'G82', Descripcion: 'Litro por día bar' },
    { Clave: 'G83', Descripcion: 'Litro por hora bar' },
    { Clave: 'G84', Descripcion: 'Litro por minuto bar' },
    { Clave: 'G85', Descripcion: 'Litro por segundo bar' },
    { Clave: 'G86', Descripcion: 'Metro cúbico por día bar' },
    { Clave: 'G87', Descripcion: 'Metro cúbico por hora bar' },
    { Clave: 'G88', Descripcion: 'Metro cúbico por minuto bar' },
    { Clave: 'G89', Descripcion: 'Metro cúbico por segundo bar' },
    { Clave: 'G90', Descripcion: 'Mililitro por día bar' },
    { Clave: 'G91', Descripcion: 'Mililitro por hora bar' },
    { Clave: 'G92', Descripcion: 'Mililitro por minuto bar' },
    { Clave: 'G93', Descripcion: 'Mililitro por segundo bar' },
    { Clave: 'G94', Descripcion: 'Centímetro cúbico por bar' },
    { Clave: 'G95', Descripcion: 'Litro por bar' },
    { Clave: 'G96', Descripcion: 'Metro cúbico por bar' },
    { Clave: 'G97', Descripcion: 'Mililitro por bar ' },
    { Clave: 'G98', Descripcion: 'Micro henry por kiloOhm' },
    { Clave: 'G99', Descripcion: 'Micro henry por Ohm' },
    { Clave: 'GB', Descripcion: 'Galón (EUA) por día' },
    { Clave: 'GBQ', Descripcion: 'Gigabecquerel' },
    { Clave: 'GC', Descripcion: 'Gramo por 100 gramos' },
    { Clave: 'Gd', Descripcion: 'Barril bruto' },
    { Clave: 'GDW', Descripcion: 'Gramo, peso seco' },
    { Clave: 'GE', Descripcion: 'Libra por galón (EUA)' },
    { Clave: 'GF', Descripcion: 'Gramo por metro (gramo por 100 centímetros)' },
    { Clave: 'GFI', Descripcion: 'Gramo de isótopo fisible' },
    { Clave: 'GGR', Descripcion: 'Grandioso' },
    { Clave: 'GH', Descripcion: 'Medio galón (US)' },
    { Clave: 'GIA', Descripcion: 'Gill (us)' },
    { Clave: 'GIC', Descripcion: 'Gramo, incluido el contenedor' },
    { Clave: 'GII', Descripcion: 'Gill (uk)' },
    { Clave: 'GIP', Descripcion: 'Grama, incluido el embalaje interior' },
    { Clave: 'GJ', Descripcion: 'Gramo por mililitro' },
    { Clave: 'GK', Descripcion: 'Gramo por kilogramo' },
    { Clave: 'GL', Descripcion: 'Gramo por litro' },
    { Clave: 'GLD', Descripcion: 'Galón seco (EUA)' },
    { Clave: 'GLI', Descripcion: 'Galón (UK)' },
    { Clave: 'GLL', Descripcion: 'Galón (EUA)' },
    { Clave: 'GM', Descripcion: 'Gramo por metro cuadrado' },
    { Clave: 'GN', Descripcion: 'Galón bruto' },
    { Clave: 'GO', Descripcion: 'Miligramo por metro cuadrado' },
    { Clave: 'GP', Descripcion: 'Miligramo por metro cúbico' },
    { Clave: 'GQ', Descripcion: 'Microgramo por metro cúbico' },
    { Clave: 'GRM', Descripcion: 'Gramo' },
    { Clave: 'GRN', Descripcion: 'Grano' },
    { Clave: 'GRO', Descripcion: 'Gross' },
    { Clave: 'GT', Descripcion: 'Tonelada bruta' },
    { Clave: 'GV', Descripcion: 'Gigajoule' },
    { Clave: 'GW', Descripcion: 'Galón por mil pies cúbicos' },
    { Clave: 'GWH', Descripcion: 'Gigawatt hora' },
    { Clave: 'GY', Descripcion: 'Patio grueso' },
    { Clave: 'GZ', Descripcion: 'Sistema de calibración' },
    { Clave: 'H03', Descripcion: 'Henry por kiloOhm' },
    { Clave: 'H04', Descripcion: 'Henry por Ohm' },
    { Clave: 'H05', Descripcion: 'Milihenry por kiloOhm' },
    { Clave: 'H06', Descripcion: 'Milihenry por Ohm' },
    { Clave: 'H07', Descripcion: 'Pascal segundo por bar' },
    { Clave: 'H08', Descripcion: 'Microbequerel' },
    { Clave: 'H09', Descripcion: 'Año recíproco' },
    { Clave: 'H1', Descripcion: 'Media página - electrónica' },
    { Clave: 'H10', Descripcion: 'Hora recíproca' },
    { Clave: 'H11', Descripcion: 'Mes recíproco' },
    { Clave: 'H12', Descripcion: 'Grado celsius por hora' },
    { Clave: 'H13', Descripcion: 'Grado celsius por minuto' },
    { Clave: 'H14', Descripcion: 'Grado celsius por segundo' },
    { Clave: 'H15', Descripcion: 'Centímetro cudrado por gramo' },
    { Clave: 'H16', Descripcion: 'Decámetro cuadrado' },
    { Clave: 'H18', Descripcion: 'Hectómetro cuadrado' },
    { Clave: 'H19', Descripcion: 'Hectómetro cúbico' },
    { Clave: 'H2', Descripcion: 'Medio litro' },
    { Clave: 'H20', Descripcion: 'Kilómetro cúbico' },
    { Clave: 'H21', Descripcion: 'Blanco' },
    { Clave: 'H22', Descripcion: 'Voltio pulgada cuadrada por libra fuerza' },
    { Clave: 'H23', Descripcion: 'Voltio por pulgada' },
    { Clave: 'H24', Descripcion: 'Voltio por microsegundo' },
    { Clave: 'H25', Descripcion: 'Por ciento por kelvin' },
    { Clave: 'H26', Descripcion: 'Ohm por metro' },
    { Clave: 'H27', Descripcion: 'Grado por metro' },
    { Clave: 'H28', Descripcion: 'Microfaradio por kilómetro' },
    { Clave: 'H29', Descripcion: 'Microgramo por litro' },
    { Clave: 'H30', Descripcion: 'Micrómetro cuadrado' },
    { Clave: 'H31', Descripcion: 'Amperio por kilogramo' },
    { Clave: 'H32', Descripcion: 'Amperio cuadrado segundo' },
    { Clave: 'H33', Descripcion: 'Faradio por kilómetro' },
    { Clave: 'H34', Descripcion: 'Hertz metro' },
    { Clave: 'H35', Descripcion: 'Kelvin metro por watt' },
    { Clave: 'H36', Descripcion: 'MegaOhm por kilómetro' },
    { Clave: 'H37', Descripcion: 'MegaOhm por metro' },
    { Clave: 'H38', Descripcion: 'Megaamperio' },
    { Clave: 'H39', Descripcion: 'Megahertz kilómetro' },
    { Clave: 'H40', Descripcion: 'Newton por amperio' },
    { Clave: 'H41', Descripcion: 'Newton metro watts elevado a la potencia menos 0.5' },
    { Clave: 'H42', Descripcion: 'Pascal por metro' },
    { Clave: 'H43', Descripcion: 'Siemens por centímetro' },
    { Clave: 'H44', Descripcion: 'TeraOhm' },
    { Clave: 'H45', Descripcion: 'Voltio segundo por metro' },
    { Clave: 'H46', Descripcion: 'Voltio por segundo' },
    { Clave: 'H47', Descripcion: 'Watt por metro cúbico' },
    { Clave: 'H48', Descripcion: 'Attofarad' },
    { Clave: 'H49', Descripcion: 'Centímetro por hora' },
    { Clave: 'H50', Descripcion: 'Reciprocidad del centimetro cúbico' },
    { Clave: 'H51', Descripcion: 'Decibel per kilometro' },
    { Clave: 'H52', Descripcion: 'Decibel per metro' },
    { Clave: 'H53', Descripcion: 'Kilogramo por bar' },
    { Clave: 'H54', Descripcion: 'Kilogramo por decímetro cúbico kelvin ' },
    { Clave: 'H55', Descripcion: 'Kilogramo por decímetro cúbico bar' },
    { Clave: 'H56', Descripcion: 'Kilogramo por metro cuadrado segundo' },
    { Clave: 'H57', Descripcion: 'Pulgada por dos pi por radián' },
    { Clave: 'H58', Descripcion: 'Metro por voltio segundo' },
    { Clave: 'H59', Descripcion: 'Metro cuadrado por newton' },
    { Clave: 'H60', Descripcion: 'Metro cúbico por metro cúbico' },
    { Clave: 'H61', Descripcion: 'Milisiemens por centímetro' },
    { Clave: 'H62', Descripcion: 'Milivoltio por minuto' },
    { Clave: 'H63', Descripcion: 'Miligramo por centímetro cuadrado' },
    { Clave: 'H64', Descripcion: 'Miligramo por gramo' },
    { Clave: 'H65', Descripcion: 'Mililitro por metro cúbico' },
    { Clave: 'H66', Descripcion: 'Milímetro por año ' },
    { Clave: 'H67', Descripcion: 'Milímetro por hora' },
    { Clave: 'H68', Descripcion: 'MiliMol por gram' },
    { Clave: 'H69', Descripcion: 'Picopascal por kilometro' },
    { Clave: 'H70', Descripcion: 'Picosegundo' },
    { Clave: 'H71', Descripcion: 'Por ciento al mes' },
    { Clave: 'H72', Descripcion: 'Por ciento por hectobar' },
    { Clave: 'H73', Descripcion: 'Por ciento por decakelvin' },
    { Clave: 'H74', Descripcion: 'Watt por metro' },
    { Clave: 'H75', Descripcion: 'Decapascal' },
    { Clave: 'H76', Descripcion: 'Gramo por milímetro' },
    { Clave: 'H77', Descripcion: 'Anchura del módulo' },
    { Clave: 'H78', Descripcion: 'Centímetro convencional de agua' },
    { Clave: 'H79', Descripcion: 'Escala francesa ' },
    { Clave: 'H80', Descripcion: 'Unidad de bastidor' },
    { Clave: 'H81', Descripcion: 'Milímetro por minuto' },
    { Clave: 'H82', Descripcion: 'Punto grande' },
    { Clave: 'H83', Descripcion: 'Litro por kilogramo' },
    { Clave: 'H84', Descripcion: 'Gramos milímetro' },
    { Clave: 'H85', Descripcion: 'Semana recíproca' },
    { Clave: 'H87', Descripcion: 'Pieza' },
    { Clave: 'H88', Descripcion: 'MegaOhm kilómetro' },
    { Clave: 'H89', Descripcion: 'Por ciento por Ohmio' },
    { Clave: 'H90', Descripcion: 'Porcentaje por grado' },
    { Clave: 'H91', Descripcion: 'Por ciento por cada diez mil' },
    { Clave: 'H92', Descripcion: 'Ciento por cien mil' },
    { Clave: 'H93', Descripcion: 'Porcentaje por cien' },
    { Clave: 'H94', Descripcion: 'Por ciento por mil' },
    { Clave: 'H95', Descripcion: 'Por ciento por voltio' },
    { Clave: 'H96', Descripcion: 'Tanto por ciento por bar' },
    { Clave: 'H98', Descripcion: 'Por ciento por pulgada' },
    { Clave: 'H99', Descripcion: 'Por ciento por metro' },
    { Clave: 'HA', Descripcion: 'Madeja' },
    { Clave: 'HAR', Descripcion: 'hectárea' },
    { Clave: 'HBA', Descripcion: 'Hectobar ' },
    { Clave: 'HBX', Descripcion: 'Ciento de cajas' },
    { Clave: 'HC', Descripcion: 'Conteo en cientos' },
    { Clave: 'HD', Descripcion: 'Media docena' },
    { Clave: 'HDW', Descripcion: 'Cien kilogramos, peso seco' },
    { Clave: 'HE', Descripcion: 'Centésima de un quilate' },
    { Clave: 'HEA', Descripcion: 'Cabeza' },
    { Clave: 'HF', Descripcion: 'Cien pies' },
    { Clave: 'HGM', Descripcion: 'Héctogramo' },
    { Clave: 'HH', Descripcion: 'Cien pies cúbicos' },
    { Clave: 'HI', Descripcion: 'Cien hojas' },
    { Clave: 'HIU', Descripcion: 'Unidad internacional de cien' },
    { Clave: 'HJ', Descripcion: 'Potencia métrica' },
    { Clave: 'HK', Descripcion: 'Cien kilogramos' },
    { Clave: 'HKM', Descripcion: 'Cien kilogramos, masa neta' },
    { Clave: 'HL', Descripcion: 'Cien pies (lineal)' },
    { Clave: 'HLT', Descripcion: 'Hectolitro' },
    { Clave: 'HM', Descripcion: 'Milla por hora (milla estatal)' },
    { Clave: 'HMQ', Descripcion: 'Millones de metros cúbicos' },
    { Clave: 'HMT', Descripcion: 'Hectómetro' },
    { Clave: 'HN', Descripcion: 'Milímetro convencional de mercurio' },
    { Clave: 'HO', Descripcion: 'Cien onzas troy' },
    { Clave: 'HP', Descripcion: 'Milímetro convencional de agua' },
    { Clave: 'HPA', Descripcion: 'Hectolitro de alcohol puro' },
    { Clave: 'HS', Descripcion: 'Cien pies cuadrados' },
    { Clave: 'HT', Descripcion: 'media hora' },
    { Clave: 'HTZ', Descripcion: 'Hertz' },
    { Clave: 'HUR', Descripcion: 'Hora' },
    { Clave: 'HY', Descripcion: 'Cien yardas' },
    { Clave: 'IA', Descripcion: 'Pulgada libra' },
    { Clave: 'IC', Descripcion: 'Contar por pulgada' },
    { Clave: 'IE', Descripcion: 'Personas' },
    { Clave: 'IF', Descripcion: 'Pulgadas de agua' },
    { Clave: 'II', Descripcion: 'Columna pulgada' },
    { Clave: 'IM', Descripcion: 'Impresión' },
    { Clave: 'INH', Descripcion: 'Pulgada' },
    { Clave: 'INK', Descripcion: 'Pulgada cuadrada' },
    { Clave: 'INQ', Descripcion: 'Pulgada cúbica' },
    { Clave: 'IP', Descripcion: 'póliza de seguros' },
    { Clave: 'ISD', Descripcion: 'Grado internacional de azúcar' },
    { Clave: 'IT', Descripcion: 'Recuento por centímetro' },
    { Clave: 'IU', Descripcion: 'Pulgada por segundo' },
    { Clave: 'IV', Descripcion: 'Pulgada por segundo al cuadrado' },
    { Clave: 'J10', Descripcion: 'Por ciento por milímetro' },
    { Clave: 'J12', Descripcion: 'Por mille por psi' },
    { Clave: 'J13', Descripcion: 'Grado api' },
    { Clave: 'J14', Descripcion: 'Grado baume (escala de origen)' },
    { Clave: 'J15', Descripcion: 'Grado baume (us pesado)' },
    { Clave: 'J16', Descripcion: 'Grado baume (luz de los EUA)' },
    { Clave: 'J17', Descripcion: 'Grado balling' },
    { Clave: 'J18', Descripcion: 'Grado brix' },
    { Clave: 'J19', Descripcion: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (termoquímico).' },
    { Clave: 'J2', Descripcion: 'Joule por kilogramo' },
    { Clave: 'J20', Descripcion: 'Grado fahrenheit por kelvin' },
    { Clave: 'J21', Descripcion: 'Grado fahrenheit por bar' },
    { Clave: 'J22', Descripcion: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (tabla internacional).' },
    { Clave: 'J23', Descripcion: 'Grado fahrenheit por hora' },
    { Clave: 'J24', Descripcion: 'Grado fahrenheit por minuto' },
    { Clave: 'J25', Descripcion: 'Grado fahrenheit por segundo' },
    { Clave: 'J26', Descripcion: 'Reciprocidad grado fahrenheit' },
    { Clave: 'J27', Descripcion: 'Grado oechsle' },
    { Clave: 'J28', Descripcion: 'Grado rankine por hora' },
    { Clave: 'J29', Descripcion: 'Grado rankine por minuto' },
    { Clave: 'J30', Descripcion: 'Grado rankine por segundo' },
    { Clave: 'J31', Descripcion: 'Grado twaddel.' },
    { Clave: 'J32', Descripcion: 'Micropoise' },
    { Clave: 'J33', Descripcion: 'Microgramo por kilogramo' },
    { Clave: 'J34', Descripcion: 'Microgramo por metro cúbico kelvin' },
    { Clave: 'J35', Descripcion: 'Microgramo por metro cúbico bar' },
    { Clave: 'J36', Descripcion: 'Microlitro por litro' },
    { Clave: 'J38', Descripcion: 'Baud' },
    { Clave: 'J39', Descripcion: 'Unidad térmica británica (significado)' },
    { Clave: 'J40', Descripcion: 'Unidad térmica británica (tabla internacional) pie por hora pie cuadrado grado fahrenheit.' },
    { Clave: 'J41', Descripcion: 'Unidad térmica británica (tabla internacional) pulgada por hora pie cuadrado grado fahrenheit.' },
    { Clave: 'J42', Descripcion: 'Unidad térmica británica (tabla internacional) pulgada por segundo pie cuadrado grado fahrenheit.' },
    { Clave: 'J43', Descripcion: 'Unidad térmica británica (tabla internacional) por libra grado fahrenheit' },
    { Clave: 'J44', Descripcion: 'Unidad térmica británica (tabla internacional) por minuto' },
    { Clave: 'J45', Descripcion: 'Unidad térmica británica (tabla internacional) por segundo' },
    { Clave: 'J46', Descripcion: 'Unidad térmica británica (termoquímico) pie por hora pie cuadrado grado fahrenheit.' },
    { Clave: 'J47', Descripcion: 'Unidad térmica británica (termoquímica) por hora' },
    { Clave: 'J48', Descripcion: 'Unidad térmica británica (termoquímico) pulgada por hora pie cuadrado grado fahrenheit.' },
    { Clave: 'J49', Descripcion: 'Unidad térmica británica (termoquímico) pulgada por segundo pie cuadrado grado fahrenheit.' },
    { Clave: 'J50', Descripcion: 'Unidad térmica británica (termoquímico) por libra grado fahrenheit' },
    { Clave: 'J51', Descripcion: 'Unidad térmica británica (termoquímica) por minuto' },
    { Clave: 'J52', Descripcion: 'Unidad térmica británica (termoquímica) por segundo' },
    { Clave: 'J53', Descripcion: 'Culombio metro cuadrado por kilogramo' },
    { Clave: 'J54', Descripcion: 'Megabaud' },
    { Clave: 'J55', Descripcion: 'Watt segundo' },
    { Clave: 'J56', Descripcion: 'Bar por bar' },
    { Clave: 'J57', Descripcion: 'Barril (uk petróleo)' },
    { Clave: 'J58', Descripcion: 'Barril (petróleo UK) por minuto' },
    { Clave: 'J59', Descripcion: 'Barril (petróleo UK) por día' },
    { Clave: 'J60', Descripcion: 'Barril (petróleo UK) por hora' },
    { Clave: 'J61', Descripcion: 'Barril (petróleo UK) por segundo' },
    { Clave: 'J62', Descripcion: 'Barril (petróleo estados unidos) por hora' },
    { Clave: 'J63', Descripcion: 'Barril (petróleo estados unidos) por segundo' },
    { Clave: 'J64', Descripcion: 'Bushel (UK) por día ' },
    { Clave: 'J65', Descripcion: 'Bushel (UK) por hora' },
    { Clave: 'J66', Descripcion: 'Bushel (UK) por minuto' },
    { Clave: 'J67', Descripcion: 'Bushel (UK) por segundo' },
    { Clave: 'J68', Descripcion: 'Bushel (seco,estados unidos) por día' },
    { Clave: 'J69', Descripcion: 'Bushel (seco,estados unidos) por hora' },
    { Clave: 'J70', Descripcion: 'Bushel (seco,estados unidos) por minuto' },
    { Clave: 'J71', Descripcion: 'Bushel (seco,estados unidos) por segundo' },
    { Clave: 'J72', Descripcion: 'Centinewton metro' },
    { Clave: 'J73', Descripcion: 'Centipoise por kelvin' },
    { Clave: 'J74', Descripcion: 'Centipoise por bar' },
    { Clave: 'J75', Descripcion: 'Caloría' },
    { Clave: 'J76', Descripcion: 'Caloría (tabla internacional) por gramo gadro celsius' },
    { Clave: 'J78', Descripcion: 'Caloría (termoquímica) por centímetro segundo grado celsius' },
    { Clave: 'J79', Descripcion: 'Caloría (termoquímico) por gramo gadro celsius' },
    { Clave: 'J81', Descripcion: 'Caloría (termoquímica) por minuto' },
    { Clave: 'J82', Descripcion: 'Caloría (termoquímica) por segundo' },
    { Clave: 'J83', Descripcion: 'Clo' },
    { Clave: 'J84', Descripcion: 'Centímetro por segundo kelvin' },
    { Clave: 'J85', Descripcion: 'Centímetro por segundo bar' },
    { Clave: 'J87', Descripcion: 'Centímetro cúbico por metro cúbico' },
    { Clave: 'J89', Descripcion: 'Centímetro de mercurio' },
    { Clave: 'J90', Descripcion: 'Decímetro cúbico por día' },
    { Clave: 'J91', Descripcion: 'Decímetro cúbico por metro cúbico' },
    { Clave: 'J92', Descripcion: 'Decímetro cúbico por minuto' },
    { Clave: 'J93', Descripcion: 'Decímetro cúbico por segundo' },
    { Clave: 'J94', Descripcion: 'Dyne centímetro' },
    { Clave: 'J95', Descripcion: 'Onza (fluido, UK) por día' },
    { Clave: 'J96', Descripcion: 'Onza (fluido, UK) por hora' },
    { Clave: 'J97', Descripcion: 'Onza (fluido, UK) por minuto' },
    { Clave: 'J98', Descripcion: 'Onza (fluido, UK) por segundo' },
    { Clave: 'J99', Descripcion: 'Onza (fluido, estados unidos) por día' },
    { Clave: 'JB', Descripcion: 'Jumbo' },
    { Clave: 'JE', Descripcion: 'Joule por kelvin' },
    { Clave: 'JK', Descripcion: 'Megajoule por kilogramo' },
    { Clave: 'JM', Descripcion: 'Megajoule por metro cúbico' },
    { Clave: 'JNT', Descripcion: 'Junta de tubería' },
    { Clave: 'Jo', Descripcion: 'Articulación' },
    { Clave: 'JOU', Descripcion: 'Joule' },
    { Clave: 'JPS', Descripcion: 'Cien metros' },
    { Clave: 'JWL', Descripcion: 'Número de joyas' },
    { Clave: 'K1', Descripcion: 'Demanda de kilowatt' },
    { Clave: 'K10', Descripcion: 'Onza (fluido, estados unidos) por hora' },
    { Clave: 'K11', Descripcion: 'Onza (fluido, estados unidos) por minuto' },
    { Clave: 'K12', Descripcion: 'Onza (fluido, estados unidos) por segundo' },
    { Clave: 'K13', Descripcion: 'Pie por grado fahrenheit' },
    { Clave: 'K14', Descripcion: 'Pie por hora' },
    { Clave: 'K15', Descripcion: 'Pie libra-fuerza por hora' },
    { Clave: 'K16', Descripcion: 'Pie libra-fuerza por minuto' },
    { Clave: 'K17', Descripcion: 'Pie por psi (libra por pulgada cuadrada)' },
    { Clave: 'K18', Descripcion: 'Pie por segundo grado fahrenheit' },
    { Clave: 'K19', Descripcion: 'Pie por segundo psi (libra por pulgada cuadrada)' },
    { Clave: 'K2', Descripcion: 'Kilovoltios amperios demanda reactiva' },
    { Clave: 'K20', Descripcion: 'Reciprocidad del pie cuadrado' },
    { Clave: 'K21', Descripcion: 'Pie cúbico por grado fahrenheit' },
    { Clave: 'K22', Descripcion: 'Pie cúbico por día' },
    { Clave: 'K23', Descripcion: 'Pie cúbico por psi (libra por pulgada cuadrada)' },
    { Clave: 'K24', Descripcion: 'Pie de agua' },
    { Clave: 'K25', Descripcion: 'Pie de mercurio' },
    { Clave: 'K26', Descripcion: 'Galón (UK) por día' },
    { Clave: 'K27', Descripcion: 'Galón (UK) por hora' },
    { Clave: 'K28', Descripcion: 'Galón (UK) por segundo' },
    { Clave: 'K3', Descripcion: 'Kilovoltio amperio hora reactivo' },
    { Clave: 'K30', Descripcion: 'Galón (líquido, EUA) por segundo' },
    { Clave: 'K31', Descripcion: 'Gramo-fuerza por centímetro cuadrado' },
    { Clave: 'K32', Descripcion: 'Gill (UK) por día' },
    { Clave: 'K33', Descripcion: 'Gill (UK) por hora' },
    { Clave: 'K34', Descripcion: 'Gill (UK) por minuto' },
    { Clave: 'K35', Descripcion: 'Gill (UK) por segundo' },
    { Clave: 'K36', Descripcion: 'Gill (estados unidos) por día' },
    { Clave: 'K37', Descripcion: 'Gill (estados unidos) por hora' },
    { Clave: 'K38', Descripcion: 'Gill (estados unidos) por minuto' },
    { Clave: 'K39', Descripcion: 'Gill (estados unidos) por segundo' },
    { Clave: 'K40', Descripcion: 'Aceleración estándar de la caída libre' },
    { Clave: 'K41', Descripcion: 'Grano por galón (EUA)' },
    { Clave: 'K42', Descripcion: 'Caballo de fuerza de caldera ' },
    { Clave: 'K43', Descripcion: 'Caballo de fuerza (eléctrico)' },
    { Clave: 'K45', Descripcion: 'Pulgada por grado fahrenheit' },
    { Clave: 'K46', Descripcion: 'Pulgada por psi (libra por pulgada cuadrada)' },
    { Clave: 'K47', Descripcion: 'Pulgada por segundo grado fahrenheit' },
    { Clave: 'K48', Descripcion: 'Pulgada por segundo psi (libra por pulgada cuadrada)' },
    { Clave: 'K49', Descripcion: 'Reciprocidad de la pulgada cuadrada' },
    { Clave: 'K5', Descripcion: 'Kilovoltios amperios (reactivos)' },
    { Clave: 'K50', Descripcion: 'Kilobaud' },
    { Clave: 'K51', Descripcion: 'Kilocaloría (significado)' },
    { Clave: 'K52', Descripcion: 'Kilocaloría (tabla internacional) por hora metro grado celsius' },
    { Clave: 'K53', Descripcion: 'Kilocaloría (termoquímico)' },
    { Clave: 'K54', Descripcion: 'Kilocaloría (termoquímica) por minuto' },
    { Clave: 'K55', Descripcion: 'Kilocaloría (termoquímica) por segundo' },
    { Clave: 'K58', Descripcion: 'KiloMol por hora' },
    { Clave: 'K59', Descripcion: 'KiloMol por metro cúbico kelvin' },
    { Clave: 'K6', Descripcion: 'Kilolitro' },
    { Clave: 'K60', Descripcion: 'KiloMol por metro cúbico bar' },
    { Clave: 'K61', Descripcion: 'KiloMol por minuto' },
    { Clave: 'K62', Descripcion: 'Litro por litro' },
    { Clave: 'K63', Descripcion: 'Reciprocidad del litro' },
    { Clave: 'K64', Descripcion: 'Libra (avoirdupois) por grado fahrenheit' },
    { Clave: 'K65', Descripcion: 'Libra (avoirdupois) pie cuadrado' },
    { Clave: 'K66', Descripcion: 'Libra (avoirdupois) por día' },
    { Clave: 'K67', Descripcion: 'Libra por pie hora' },
    { Clave: 'K68', Descripcion: 'Libra por pie segundo' },
    { Clave: 'K69', Descripcion: 'Libra (avoirdupois) por pie cúbico grado fahrenheit' },
    { Clave: 'K70', Descripcion: 'Libra (avoirdupois) por pie cúbico psi' },
    { Clave: 'K71', Descripcion: 'Libra (avoirdupois) por galón (UK)' },
    { Clave: 'K73', Descripcion: 'Libra (avoirdupois) por hora grados fahrenheit' },
    { Clave: 'K74', Descripcion: 'Libra (avoirdupois) por hora libra-fuerza por pulgada cuadrada' },
    { Clave: 'K75', Descripcion: 'Libra (avoirdupois) por pulgada cúbica grado fahrenheit' },
    { Clave: 'K76', Descripcion: 'Libra (avoirdupois) por pulgada cúbica psi' },
    { Clave: 'K77', Descripcion: 'Libra (avoirdupois) por psi' },
    { Clave: 'K78', Descripcion: 'Libra (avoirdupois) por minuto' },
    { Clave: 'K79', Descripcion: 'Libra (avoirdupois) por minuto grados fahrenheit' },
    { Clave: 'K80', Descripcion: 'Libra (avoirdupois) por minuto libra-fuerza por pulgada cuadrada' },
    { Clave: 'K81', Descripcion: 'Libra (avoirdupois) por segundo' },
    { Clave: 'K82', Descripcion: 'Libra (avoirdupois)por segundo grados fahrenheit' },
    { Clave: 'K83', Descripcion: 'Libra (avoirdupois) por segundo libra-fuerza por pulgada cuadrada' },
    { Clave: 'K84', Descripcion: 'Libra por yarda cúbica ' },
    { Clave: 'K85', Descripcion: 'Libra-fuerza por pie cuadrado' },
    { Clave: 'K86', Descripcion: 'Libra-fuerza por pulgada cuadrada grados fahrenheit' },
    { Clave: 'K87', Descripcion: 'Libra-fuerza por pulgada cuadrada pulgada cúbica por segundo' },
    { Clave: 'K88', Descripcion: 'Libra-fuerza por pulgada cuadrada litro por segundo' },
    { Clave: 'K89', Descripcion: 'Libra-fuerza por pulgada cuadrada metro cúbico por segundo' },
    { Clave: 'K90', Descripcion: 'Libra-fuerza por pulgada cuadrada yarda cúbica por segundo' },
    { Clave: 'K91', Descripcion: 'Libra-fuerza segundo por pie cuadrado' },
    { Clave: 'K92', Descripcion: 'Libra-fuerza segundo por pulgada cuadrada' },
    { Clave: 'K93', Descripcion: 'Reciprocidad psi' },
    { Clave: 'K94', Descripcion: 'Cuarto (líquido, UK) por día' },
    { Clave: 'K95', Descripcion: 'Cuarto (líquido, UK) por hora' },
    { Clave: 'K96', Descripcion: 'Cuarto (líquido, UK) por minuto' },
    { Clave: 'K97', Descripcion: 'Cuarto (líquido, UK) por segundo' },
    { Clave: 'K98', Descripcion: 'Cuarto (líquido, estados unidos) por día' },
    { Clave: 'K99', Descripcion: 'Cuarto (líquido, estados unidos) por hora' },
    { Clave: 'KA', Descripcion: 'Pastel' },
    { Clave: 'KAT', Descripcion: 'Katal' },
    { Clave: 'KB', Descripcion: 'Kilocaracter' },
    { Clave: 'KBA', Descripcion: 'Kilobar' },
    { Clave: 'KCC', Descripcion: 'Kilogramo de cloruro de colina' },
    { Clave: 'KD', Descripcion: 'Kilogram decimal' },
    { Clave: 'KDW', Descripcion: 'Kilogramo de peso neto drenado' },
    { Clave: 'KEL', Descripcion: 'Kelvin' },
    { Clave: 'KF', Descripcion: 'Kilopacket' },
    { Clave: 'KGM', Descripcion: 'Kilogramo' },
    { Clave: 'KGS', Descripcion: 'Kilogramo por segundo' },
    { Clave: 'KHY', Descripcion: 'Kilogramo de peróxido de hidrógeno' },
    { Clave: 'KHZ', Descripcion: 'Kilohertz' },
    { Clave: 'KI', Descripcion: 'Kilogramo por milímetro de ancho' },
    { Clave: 'KIC', Descripcion: 'Kilogramo, incluyendo el contenedor' },
    { Clave: 'KIP', Descripcion: 'Kilogramo, incluyendo el empaquetado interno' },
    { Clave: 'KJ', Descripcion: 'Kilosegmento' },
    { Clave: 'KJO', Descripcion: 'Kilojoule' },
    { Clave: 'KL', Descripcion: 'Kilogramo por metro' },
    { Clave: 'KLK', Descripcion: 'Porcentaje de material seco láctico' },
    { Clave: 'KLX', Descripcion: 'Kilolux' },
    { Clave: 'KMA', Descripcion: 'Kilogramo de metilamina' },
    { Clave: 'KMH', Descripcion: 'Kilómetro por hora' },
    { Clave: 'KMK', Descripcion: 'Kilómetro cuadrado' },
    { Clave: 'KMQ', Descripcion: 'Kilogramo por metro cúbico' },
    { Clave: 'KMT', Descripcion: 'Kilómetro' },
    { Clave: 'KNI', Descripcion: 'Kilogramo de nitrógeno' },
    { Clave: 'KNM', Descripcion: 'Kolonewton por metro cuadrado' },
    { Clave: 'KNS', Descripcion: 'Kilogram sustancia nombrada' },
    { Clave: 'KNT', Descripcion: 'Nudo' },
    { Clave: 'KO', Descripcion: 'Miliequivalentes de potasa cáustica por gramo de producto' },
    { Clave: 'KPA', Descripcion: 'Kilopascal' },
    { Clave: 'KPH', Descripcion: 'Kilogramo de hidróxido de potasio (potasa cáustica)' },
    { Clave: 'KPO', Descripcion: 'Kilogramo de óxido de potasio' },
    { Clave: 'KPP', Descripcion: 'Kilogramo de pentóxido de fósforo (anhídrido fosfórico)' },
    { Clave: 'KR', Descripcion: 'Kiloroentgen' },
    { Clave: 'KS', Descripcion: 'Mil libras por pulgada cuadrada' },
    { Clave: 'KSD', Descripcion: 'Kilogramo de sustancia 90% seco' },
    { Clave: 'KSH', Descripcion: 'Kilogramo de hidróxido de sodio (sodio cáustica)' },
    { Clave: 'KT', Descripcion: 'Kit' },
    { Clave: 'KTN', Descripcion: 'Kilotonelada Métrica' },
    { Clave: 'KUR', Descripcion: 'Kilogramo de uranio' },
    { Clave: 'KVA', Descripcion: 'Kilovoltio - amperio' },
    { Clave: 'KVR', Descripcion: 'Kilovar' },
    { Clave: 'KVT', Descripcion: 'Kilovoltio' },
    { Clave: 'KW', Descripcion: 'Kilogramo por milímetro' },
    { Clave: 'KWH', Descripcion: 'Kilowatt hora' },
    { Clave: 'KWN', Descripcion: 'Kilowatt hora por metro cúbico normalizado' },
    { Clave: 'KWO', Descripcion: 'Kilogramo de trióxido de tungsteno' },
    { Clave: 'KWS', Descripcion: 'Kilowatt hora por metro cúbico estándar' },
    { Clave: 'KWT', Descripcion: 'Kilowatt' },
    { Clave: 'KX', Descripcion: 'Mililitro por kilogramo' },
    { Clave: 'L10', Descripcion: 'Cuarto (líquido, estados unidos) por minuto' },
    { Clave: 'L11', Descripcion: 'Cuarto (líquido, estados unidos) por segundo' },
    { Clave: 'L12', Descripcion: 'Metro por segundo kelvin' },
    { Clave: 'L13', Descripcion: 'Metro por segundo bar' },
    { Clave: 'L14', Descripcion: 'Metro cuadrado hora grado celsius por kilocaloría (tabla internacional)' },
    { Clave: 'L15', Descripcion: 'Milipascal segundo por kelvin' },
    { Clave: 'L16', Descripcion: 'Milipascal segundo por bar' },
    { Clave: 'L17', Descripcion: 'Miligramo por metro cúbico kelvin' },
    { Clave: 'L18', Descripcion: 'Miligramo por metro cúbico bar' },
    { Clave: 'L19', Descripcion: 'Mililitro por litro' },
    { Clave: 'L2', Descripcion: 'Litro por minuto' },
    { Clave: 'L20', Descripcion: 'Reciprocidad del milimetro cúbico' },
    { Clave: 'L21', Descripcion: 'Milimetro cúbico por metro cúbico' },
    { Clave: 'L23', Descripcion: 'Mol por hora ' },
    { Clave: 'L24', Descripcion: 'Mol por kilogramo kelvin' },
    { Clave: 'L25', Descripcion: 'Mol por kilogramo bar' },
    { Clave: 'L26', Descripcion: 'Mol por litreo kelvin' },
    { Clave: 'L27', Descripcion: 'Mol por litro bar' },
    { Clave: 'L28', Descripcion: 'Mol por metro cúbico kelvin' },
    { Clave: 'L29', Descripcion: 'Mol por metro cúbico bar' },
    { Clave: 'L30', Descripcion: 'Mol por minuto' },
    { Clave: 'L31', Descripcion: 'Milliroentgen aequivalent men' },
    { Clave: 'L32', Descripcion: 'Nanogramo por kilogramo' },
    { Clave: 'L33', Descripcion: 'Onza (avoirdupois) por día' },
    { Clave: 'L34', Descripcion: 'Onza (avoirdupois) por hora' },
    { Clave: 'L35', Descripcion: 'Onza (avoirdupois) por minuto' },
    { Clave: 'L36', Descripcion: 'Onza (avoirdupois) por segundo' },
    { Clave: 'L37', Descripcion: 'Onza (avoirdupois) por galón (UK)' },
    { Clave: 'L38', Descripcion: 'Onza (avoirdupois) por galón (EUA)' },
    { Clave: 'L39', Descripcion: 'Onza (avoirdupois) por pulgada cúbica' },
    { Clave: 'L40', Descripcion: 'Onza fuerza' },
    { Clave: 'L41', Descripcion: 'Onza (avoirdupois) fuerza pulgada' },
    { Clave: 'L42', Descripcion: 'Picosiemens por metro' },
    { Clave: 'L43', Descripcion: 'Peck (UK)' },
    { Clave: 'L44', Descripcion: 'Peck (UK) por día' },
    { Clave: 'L45', Descripcion: 'Peck (UK) por hora' },
    { Clave: 'L46', Descripcion: 'Peck (UK) por minuto' },
    { Clave: 'L47', Descripcion: 'Peck (UK) por segundo' },
    { Clave: 'L48', Descripcion: 'Peck (seco, estados unidos) por día' },
    { Clave: 'L49', Descripcion: 'Peck (seco, estados unidos) por hora' },
    { Clave: 'L50', Descripcion: 'Peck (seco, estados unidos) por minuto' },
    { Clave: 'L51', Descripcion: 'Peck (seco, estados unidos) por segundo' },
    { Clave: 'L52', Descripcion: 'Libra.fuerza por pulgada cuadrada por libra fuerza por pulgada cuadrada' },
    { Clave: 'L53', Descripcion: 'Pinta (UK) por día' },
    { Clave: 'L54', Descripcion: 'Pinta (UK) por hora' },
    { Clave: 'L55', Descripcion: 'Pinta (UK) por minuto' },
    { Clave: 'L56', Descripcion: 'Pinta (UK) por segundo' },
    { Clave: 'L57', Descripcion: 'Pinta (líquido, estados unidos) por día' },
    { Clave: 'L58', Descripcion: 'Pinta (líquido, estados unidos) por hora' },
    { Clave: 'L59', Descripcion: 'Pinta (líquido, estados unidos) por minuto' },
    { Clave: 'L60', Descripcion: 'Pinta (líquido, estados unidos) por segundo' },
    { Clave: 'L61', Descripcion: 'Pinta (US seco)' },
    { Clave: 'L62', Descripcion: 'Cuarto de galón (seco de los EUA)' },
    { Clave: 'L63', Descripcion: 'Slug por día' },
    { Clave: 'L64', Descripcion: 'Slug por pie segundo' },
    { Clave: 'L65', Descripcion: 'Slug por pie cúbico' },
    { Clave: 'L66', Descripcion: 'Slug por hora' },
    { Clave: 'L67', Descripcion: 'Slug por minuto' },
    { Clave: 'L68', Descripcion: 'Slug por segundo' },
    { Clave: 'L69', Descripcion: 'Tonelada por kelvin' },
    { Clave: 'L70', Descripcion: 'Tonelada por bar' },
    { Clave: 'L71', Descripcion: 'Tonelada por día' },
    { Clave: 'L72', Descripcion: 'Tonelada por día kelvin' },
    { Clave: 'L73', Descripcion: 'Tonelada por día bar' },
    { Clave: 'L74', Descripcion: 'Tonelada por hora kelvin' },
    { Clave: 'L75', Descripcion: 'Tonelada por hora bar' },
    { Clave: 'L76', Descripcion: 'Tonelada por metro cúbico kelvin' },
    { Clave: 'L77', Descripcion: 'Tonelada por metro cúbico bar' },
    { Clave: 'L78', Descripcion: 'Tonelada por minuto' },
    { Clave: 'L79', Descripcion: 'Tonelada por minuto kelvin' },
    { Clave: 'L80', Descripcion: 'Tonelada por minuto bar' },
    { Clave: 'L81', Descripcion: 'Tonelada por segundo' },
    { Clave: 'L82', Descripcion: 'Tonelada por segundo kelvin' },
    { Clave: 'L83', Descripcion: 'Tonelada por segundo bar' },
    { Clave: 'L84', Descripcion: 'Tonelada (flota UK)' },
    { Clave: 'L85', Descripcion: 'Tonelada larga por día' },
    { Clave: 'L86', Descripcion: 'Tonelada (flota estados unidos)' },
    { Clave: 'L87', Descripcion: 'Tonelada corta por grado fahrenheit' },
    { Clave: 'L88', Descripcion: 'Tonelada corta por día' },
    { Clave: 'L89', Descripcion: 'Tonelada corta por hora grados fahrenheit' },
    { Clave: 'L90', Descripcion: 'Tonelada corta por hora libra-fuerza por pulgada cuadrada' },
    { Clave: 'L91', Descripcion: 'Tonelada corta por psi' },
    { Clave: 'L92', Descripcion: 'Tonelada larga (UK) por yarda cúbica' },
    { Clave: 'L93', Descripcion: 'Tonelada corta (estados unidos) por yarda cúbica' },
    { Clave: 'L94', Descripcion: 'Tonelada fuerza' },
    { Clave: 'L95', Descripcion: 'Año común' },
    { Clave: 'L96', Descripcion: 'Año sideral' },
    { Clave: 'L98', Descripcion: 'Yarda por grado fahrenheit' },
    { Clave: 'L99', Descripcion: 'Yarda por psi (libra por pulgada cuadrada)' },
    { Clave: 'LA', Descripcion: 'Libra por pulgada cúbica ' },
    { Clave: 'LAC', Descripcion: 'Porcentaje de exceso de lactosa' },
    { Clave: 'LBR', Descripcion: 'Libra' },
    { Clave: 'LBT', Descripcion: 'Troy pound' },
    { Clave: 'LC', Descripcion: 'Centímetro lineal' },
    { Clave: 'LD', Descripcion: 'Litro por día' },
    { Clave: 'LE', Descripcion: 'Lite' },
    { Clave: 'LEF', Descripcion: 'Hoja' },
    { Clave: 'LF', Descripcion: 'Pie lineal' },
    { Clave: 'LH', Descripcion: 'Hora de trabajo' },
    { Clave: 'LI', Descripcion: 'Pulgada lineal' },
    { Clave: 'LJ', Descripcion: 'Spray grande' },
    { Clave: 'LK', Descripcion: 'Enlazar' },
    { Clave: 'LM', Descripcion: 'Metro lineal' },
    { Clave: 'LN', Descripcion: 'Longitud' },
    { Clave: 'LO', Descripcion: 'Lote [unidad de adquisición]' },
    { Clave: 'LP', Descripcion: 'Libra líquida' },
    { Clave: 'LPA', Descripcion: 'Litro de alcohol puro' },
    { Clave: 'LR', Descripcion: 'Capa ' },
    { Clave: 'LS', Descripcion: 'Suma global' },
    { Clave: 'LTN', Descripcion: 'Tonelada (UK) o tonelada larga (estados unidos)' },
    { Clave: 'LTR', Descripcion: 'Litro' },
    { Clave: 'LUB', Descripcion: 'Tonelada métrica, aceite lubricante' },
    { Clave: 'LUM', Descripcion: 'Lumen' },
    { Clave: 'LUX', Descripcion: 'Lux' },
    { Clave: 'LX', Descripcion: 'Yarda lineal por libra' },
    { Clave: 'LY', Descripcion: 'Yarda lineal' },
    { Clave: 'M0', Descripcion: 'cinta magnética' },
    { Clave: 'M1', Descripcion: 'Miligramo por litro' },
    { Clave: 'M10', Descripcion: 'Reciprocidad de la yarda cuadrada' },
    { Clave: 'M11', Descripcion: 'Yarda cúbica por grado fahrenheit' },
    { Clave: 'M12', Descripcion: 'Yarda cúbica por día' },
    { Clave: 'M13', Descripcion: 'Yarda cúbica por hora ' },
    { Clave: 'M14', Descripcion: 'Yarda cúbica por psi (libra por pulgada cuadrada)' },
    { Clave: 'M15', Descripcion: 'Yarda cúbica por minuto' },
    { Clave: 'M16', Descripcion: 'Yarda cúbica por segundo' },
    { Clave: 'M17', Descripcion: 'Kilohertz metro' },
    { Clave: 'M18', Descripcion: 'Gigahertz metro' },
    { Clave: 'M19', Descripcion: 'Beaufort' },
    { Clave: 'M20', Descripcion: 'Recíproco de megakelvin ó megakelvin a la potencia menos 1' },
    { Clave: 'M21', Descripcion: 'Kilovoltio-amperio hora recíprocidad' },
    { Clave: 'M22', Descripcion: 'Milímetro por centímetro cuadrado minuto' },
    { Clave: 'M23', Descripcion: 'Newton por centímetro' },
    { Clave: 'M24', Descripcion: 'Ohm kilómetro' },
    { Clave: 'M25', Descripcion: 'Porcentaje por grado celsius' },
    { Clave: 'M26', Descripcion: 'GigaOhm por metro' },
    { Clave: 'M27', Descripcion: 'Megahertz metro' },
    { Clave: 'M29', Descripcion: 'Kilogramo por kilogramo' },
    { Clave: 'M30', Descripcion: 'voltio-amperio segundo recíprocidad' },
    { Clave: 'M31', Descripcion: 'Kilogramo por kilómetro' },
    { Clave: 'M32', Descripcion: 'Segundos pascal por litro' },
    { Clave: 'M33', Descripcion: 'MiliMol por litro' },
    { Clave: 'M34', Descripcion: 'Newton metro por metro cuadrado' },
    { Clave: 'M35', Descripcion: 'Milivoltio - amperio' },
    { Clave: 'M36', Descripcion: 'Mes de 30 días' },
    { Clave: 'M37', Descripcion: 'Actual 360' },
    { Clave: 'M38', Descripcion: 'Kilómetro por segundo cuadrado' },
    { Clave: 'M39', Descripcion: 'Centímetro por segundo cuadrado' },
    { Clave: 'M4', Descripcion: 'Valor monetario' },
    { Clave: 'M40', Descripcion: 'Yarda por segundo cuadrado' },
    { Clave: 'M41', Descripcion: 'Milímetro por segundo cuadrado' },
    { Clave: 'M42', Descripcion: 'Milla (milla estatal)  por segundo cuadrado' },
    { Clave: 'M43', Descripcion: 'Mil (unidad de Medida Militar)' },
    { Clave: 'M44', Descripcion: 'Revolución' },
    { Clave: 'M45', Descripcion: 'Grado por segundo cuadrado' },
    { Clave: 'M46', Descripcion: 'Revolución por minuto' },
    { Clave: 'M47', Descripcion: 'Circular Mil' },
    { Clave: 'M48', Descripcion: 'Milla cuadrada (basado en u.s survey foot)' },
    { Clave: 'M49', Descripcion: 'Cadena ' },
    { Clave: 'M5', Descripcion: 'Microcurie' },
    { Clave: 'M50', Descripcion: 'Estadio' },
    { Clave: 'M51', Descripcion: 'Pie (Topografía UEA)' },
    { Clave: 'M52', Descripcion: 'Milla' },
    { Clave: 'M53', Descripcion: 'Metro por pascal' },
    { Clave: 'M55', Descripcion: 'Metro por radián' },
    { Clave: 'M56', Descripcion: 'Shake ' },
    { Clave: 'M57', Descripcion: 'Milla por minuto' },
    { Clave: 'M58', Descripcion: 'Milla por segundo' },
    { Clave: 'M59', Descripcion: 'Metro por segundo pascal' },
    { Clave: 'M60', Descripcion: 'Metro por hora' },
    { Clave: 'M61', Descripcion: 'Pulgada por año' },
    { Clave: 'M62', Descripcion: 'Kilómetro por segundo' },
    { Clave: 'M63', Descripcion: 'Pulgada por minuto' },
    { Clave: 'M64', Descripcion: 'Yarda por segundo' },
    { Clave: 'M65', Descripcion: 'Yarda por minuto' },
    { Clave: 'M66', Descripcion: 'Yarda por hora' },
    { Clave: 'M67', Descripcion: 'Acre-pie' },
    { Clave: 'M68', Descripcion: 'Cordón' },
    { Clave: 'M69', Descripcion: 'Milla cúbica (reinounido)' },
    { Clave: 'M7', Descripcion: 'Micro-pulgada' },
    { Clave: 'M70', Descripcion: 'Unidad tradicional de capacidad de carga' },
    { Clave: 'M71', Descripcion: 'Metro cúbico por pascal (joules)' },
    { Clave: 'M72', Descripcion: 'Bel' },
    { Clave: 'M73', Descripcion: 'Kilogramo por metro cúbico pascal' },
    { Clave: 'M74', Descripcion: 'Kilogramo por pascal' },
    { Clave: 'M75', Descripcion: 'Kilolibra fuerza' },
    { Clave: 'M76', Descripcion: 'Poundal' },
    { Clave: 'M77', Descripcion: 'Kilogramo metro por segundo cuadrado' },
    { Clave: 'M78', Descripcion: 'Pond' },
    { Clave: 'M79', Descripcion: 'Pie cuadrado por hora' },
    { Clave: 'M80', Descripcion: 'Stokes por pascal' },
    { Clave: 'M81', Descripcion: 'Centímetro cuadrado por segundo' },
    { Clave: 'M82', Descripcion: 'Metro cuadrado por segundo pascal' },
    { Clave: 'M83', Descripcion: 'Denier' },
    { Clave: 'M84', Descripcion: 'Libra por yarda ' },
    { Clave: 'M85', Descripcion: 'Tonelada, ensayo' },
    { Clave: 'M86', Descripcion: 'Libra Alemana' },
    { Clave: 'M87', Descripcion: 'Kilogramo por segundo pascal' },
    { Clave: 'M88', Descripcion: 'Tonelada por mes' },
    { Clave: 'M89', Descripcion: 'Tonelada por año' },
    { Clave: 'M9', Descripcion: 'Millones de btu por 1000 pies cúbicos' },
    { Clave: 'M90', Descripcion: 'Kilolibra por hora' },
    { Clave: 'M91', Descripcion: 'Libra por libra' },
    { Clave: 'M92', Descripcion: 'Libra fuerza pie' },
    { Clave: 'M93', Descripcion: 'Newton metro por radián' },
    { Clave: 'M94', Descripcion: 'Kilogramo metro' },
    { Clave: 'M95', Descripcion: 'Poundal pie' },
    { Clave: 'M96', Descripcion: 'Poundal pulgada' },
    { Clave: 'M97', Descripcion: 'Dina metro' },
    { Clave: 'M98', Descripcion: 'Kilogramo centímetro por segundo' },
    { Clave: 'M99', Descripcion: 'Gramo centímetro por segundo' },
    { Clave: 'MA', Descripcion: 'Máquina por unidad' },
    { Clave: 'MAH', Descripcion: 'Megavoltio amperio reactivo hora' },
    { Clave: 'MAL', Descripcion: 'Megalitro' },
    { Clave: 'MAM', Descripcion: 'Megametro' },
    { Clave: 'MAR', Descripcion: 'Megavar' },
    { Clave: 'MAW', Descripcion: 'Megawatt' },
    { Clave: 'MBE', Descripcion: 'Mil equivalente de ladrillo estándar' },
    { Clave: 'MBF', Descripcion: 'Mil pies de tabla' },
    { Clave: 'MBR', Descripcion: 'Milibar' },
    { Clave: 'MC', Descripcion: 'Microgramo' },
    { Clave: 'MCU', Descripcion: 'Milicurie' },
    { Clave: 'MD', Descripcion: 'Tonelada métrica seca al aire' },
    { Clave: 'MF', Descripcion: 'Miligramo por pie cuadrado por lado' },
    { Clave: 'MGM', Descripcion: 'Miligramo' },
    { Clave: 'MHZ', Descripcion: 'Megahertz' },
    { Clave: 'MIK', Descripcion: 'Milla cuadrada (milla estatal)' },
    { Clave: 'MIL', Descripcion: 'Mil' },
    { Clave: 'MIN', Descripcion: 'Minuto [unidad de tiempo]' },
    { Clave: 'MIO', Descripcion: 'Millon' },
    { Clave: 'MIU', Descripcion: 'Unidad internacional de millon' },
    { Clave: 'MK', Descripcion: 'Miligramo por pulgada cuadrada' },
    { Clave: 'MLD', Descripcion: 'Mil millones' },
    { Clave: 'MLT', Descripcion: 'Mililitro' },
    { Clave: 'MMK', Descripcion: 'Milímetro cuadrado' },
    { Clave: 'MMQ', Descripcion: 'Milímetro cúbico' },
    { Clave: 'MMT', Descripcion: 'Milímetro' },
    { Clave: 'MND', Descripcion: 'Kilogramo, peso seco' },
    { Clave: 'MON', Descripcion: 'Mes ' },
    { Clave: 'MPA', Descripcion: 'Megapascal' },
    { Clave: 'MQ', Descripcion: 'Mil metros' },
    { Clave: 'MQH', Descripcion: 'Metro cúbico por hora' },
    { Clave: 'MQS', Descripcion: 'Metro cúbico por segundo' },
    { Clave: 'MSK', Descripcion: 'Metro por segundo cuadrado' },
    { Clave: 'MTK', Descripcion: 'Metro cuadrado' },
    { Clave: 'MTQ', Descripcion: 'Metro cúbico' },
    { Clave: 'MTR', Descripcion: 'Metro' },
    { Clave: 'MTS', Descripcion: 'Metro por segundo' },
    { Clave: 'MV', Descripcion: 'Número de mults' },
    { Clave: 'MVA', Descripcion: 'Megavoltio - amperio' },
    { Clave: 'MWH', Descripcion: 'Megawatt hora' },
    { Clave: 'N1', Descripcion: 'Pluma calórica' },
    { Clave: 'N10', Descripcion: 'Libra pie por segundo' },
    { Clave: 'N11', Descripcion: 'Libra pulgada por segundo' },
    { Clave: 'N12', Descripcion: 'Pferdestaerke' },
    { Clave: 'N13', Descripcion: 'Centímetro de mercurio (0°)' },
    { Clave: 'N14', Descripcion: 'Centímetro de agua (4°)' },
    { Clave: 'N15', Descripcion: 'Pie de agua (39.2 °f)' },
    { Clave: 'N16', Descripcion: 'Pulgada de mercurio (32 °f)' },
    { Clave: 'N17', Descripcion: 'Pulgada de mercurio (60 °f)' },
    { Clave: 'N18', Descripcion: 'Pulgada de agua (39.2 °f)' },
    { Clave: 'N19', Descripcion: 'Pulgada de agua (60 °f)' },
    { Clave: 'N2', Descripcion: 'número de líneas' },
    { Clave: 'N20', Descripcion: 'Kip por pulgada cuadrada' },
    { Clave: 'N21', Descripcion: 'Poundal por pie cuadrado' },
    { Clave: 'N22', Descripcion: 'Onza (avoirdupois) por pulgada cuadrada' },
    { Clave: 'N23', Descripcion: 'Metro convencional de agua' },
    { Clave: 'N24', Descripcion: 'Gramo por milímetro cuadrado' },
    { Clave: 'N25', Descripcion: 'Libra por yarda cuadrada' },
    { Clave: 'N26', Descripcion: 'Poundal por pulgada cuadrada' },
    { Clave: 'N27', Descripcion: 'Pie a la cuarta potencia ' },
    { Clave: 'N28', Descripcion: 'Decímetro cúbico por kilogramo' },
    { Clave: 'N29', Descripcion: 'Pie cúbico por libra ' },
    { Clave: 'N30', Descripcion: 'Impresión de punto' },
    { Clave: 'N30', Descripcion: 'Pulgada cúbica por libra' },
    { Clave: 'N31', Descripcion: 'Kilonewton por metro' },
    { Clave: 'N32', Descripcion: 'Poundal por pulgada' },
    { Clave: 'N33', Descripcion: 'Libra-fuerza por yarda' },
    { Clave: 'N34', Descripcion: 'Poundal segundo por pie cuadrado' },
    { Clave: 'N35', Descripcion: 'Poise por pascal' },
    { Clave: 'N36', Descripcion: 'Newton segundo por metro cuadrado' },
    { Clave: 'N37', Descripcion: 'Kilogramo por metro segundo' },
    { Clave: 'N38', Descripcion: 'Kilogramo por metro minuto' },
    { Clave: 'N39', Descripcion: 'Kilogramo por metro día' },
    { Clave: 'N40', Descripcion: 'Kilogramo por metro hora' },
    { Clave: 'N41', Descripcion: 'Gramo por centímetro segundo' },
    { Clave: 'N42', Descripcion: 'Poundal segundo por pulgada cuadrada' },
    { Clave: 'N43', Descripcion: 'Libra por pie minuto' },
    { Clave: 'N44', Descripcion: 'Libra por pie día' },
    { Clave: 'N45', Descripcion: 'Metro cúbico por segundo pascal' },
    { Clave: 'N46', Descripcion: 'Pie poundal' },
    { Clave: 'N47', Descripcion: 'Pulgada poundal' },
    { Clave: 'N48', Descripcion: 'Watt por centímetro cuadrado' },
    { Clave: 'N49', Descripcion: 'Watt por pulgada cuadrada' },
    { Clave: 'N50', Descripcion: 'Unidad térmica británica (tabla internacional) por pie cuadrado hora.' },
    { Clave: 'N51', Descripcion: 'Unidad térmica británica (termoquímica) por pie cuadrado hora.' },
    { Clave: 'N52', Descripcion: 'Unidad térmica británica (termoquímico) por pie cuadrado minuto.' },
    { Clave: 'N53', Descripcion: 'Unidad térmica británica (tabla internacional) por pie cuadrado segundo.' },
    { Clave: 'N54', Descripcion: 'Unidad térmica británica (termoquímica) por pie cuadrado segundo.' },
    { Clave: 'N55', Descripcion: 'Unidad térmica británica (tabla internacional) por pulgada cuadrada segundo.' },
    { Clave: 'N56', Descripcion: 'Caloría (termoquímica) por centímetro cuadrado minuto' },
    { Clave: 'N57', Descripcion: 'Caloría (termoquímica) por centímetro cuadrado segundo' },
    { Clave: 'N58', Descripcion: 'Unidad térmica británica (tabla internacional) por pie cúbico' },
    { Clave: 'N59', Descripcion: 'Unidad térmica británica (termoquímica) por pie cúbico' },
    { Clave: 'N60', Descripcion: 'Unidad térmica británica (tabla internacional) por grado fahrenheit' },
    { Clave: 'N61', Descripcion: 'Unidad térmica británica (termoquímico) por grado fahrenheit' },
    { Clave: 'N62', Descripcion: 'Unidad térmica británica (tabla internacional) por grado rankine' },
    { Clave: 'N63', Descripcion: 'Unidad térmica británica (termoquímico) por grado rankine' },
    { Clave: 'N64', Descripcion: 'Unidad térmica británica (termoquímico) por libra grado rankine' },
    { Clave: 'N65', Descripcion: 'Kilocaloría (tabla internacional) por gramo kelvin' },
    { Clave: 'N66', Descripcion: 'Unidad térmica británica (39 °f)' },
    { Clave: 'N67', Descripcion: 'Unidad térmica británica (59 °f)' },
    { Clave: 'N68', Descripcion: 'Unidad térmica británica (60 °f)' },
    { Clave: 'N69', Descripcion: 'Caloría (20 °c)' },
    { Clave: 'N70', Descripcion: 'Quad' },
    { Clave: 'N71', Descripcion: 'Termia (energía comercial)' },
    { Clave: 'N72', Descripcion: 'Termia (UEA)' },
    { Clave: 'N73', Descripcion: 'Unidad térmica británica (termoquímica) por libra' },
    { Clave: 'N74', Descripcion: 'Unidad térmica británica (tabla internacional) por hora pie cuadrado grado fahrenheit.' },
    { Clave: 'N75', Descripcion: 'Unidad térmica británica (termoquímico) por hora pie cuadrado grado farenheit.' },
    { Clave: 'N76', Descripcion: 'Unidad térmica británica (tabla internacional) por segundo pie cuadrado grado fahrenheit.' },
    { Clave: 'N77', Descripcion: 'Unidad térmica británica (termoquímico) por segundo pie cuadrado grado fahrenheit.' },
    { Clave: 'N78', Descripcion: 'Kilowatt por metro cuadrado kelvin' },
    { Clave: 'N79', Descripcion: 'Kelvin por pascal' },
    { Clave: 'N80', Descripcion: 'Watt por metro grado celsius' },
    { Clave: 'N81', Descripcion: 'Kilowatt por metro kelvin' },
    { Clave: 'N82', Descripcion: 'Kilowatt por metro grado celsius' },
    { Clave: 'N83', Descripcion: 'Metro por grado celsius metro' },
    { Clave: 'N84', Descripcion: 'Grado fahrenheit hora por unidad térmica británica (tabla internacional)' },
    { Clave: 'N85', Descripcion: 'Grado fahrenheit hora por unidad térmica británica (termoquímico)' },
    { Clave: 'N86', Descripcion: 'Grado fahrenheit segundo por unidad térmica británica (tabla internacional)' },
    { Clave: 'N87', Descripcion: 'Grago fahrenheit segundo por unidad térmica británica (termoquímico)' },
    { Clave: 'N88', Descripcion: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (tabla internacional) pulgada' },
    { Clave: 'N89', Descripcion: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (termoquímico) pulgada.' },
    { Clave: 'N90', Descripcion: 'Kilofaradio' },
    { Clave: 'N91', Descripcion: 'Joule recíproco' },
    { Clave: 'N92', Descripcion: 'Picosiemens' },
    { Clave: 'N93', Descripcion: 'Amperio por pascal' },
    { Clave: 'N94', Descripcion: 'Franklin' },
    { Clave: 'N95', Descripcion: 'Amperio minuto' },
    { Clave: 'N96', Descripcion: 'Biot' },
    { Clave: 'N97', Descripcion: 'Gilbert' },
    { Clave: 'N98', Descripcion: 'Voltio por pascal' },
    { Clave: 'N99', Descripcion: 'Picovoltio' },
    { Clave: 'NA', Descripcion: 'Miligramo por kilogramo' },
    { Clave: 'NAR', Descripcion: 'Número de artículos' },
    { Clave: 'NB', Descripcion: 'barcaza' },
    { Clave: 'NBB', Descripcion: 'Número de bobinas' },
    { Clave: 'NC', Descripcion: 'Carro' },
    { Clave: 'NCL', Descripcion: 'Número de celulas' },
    { Clave: 'ND', Descripcion: 'Barril neto' },
    { Clave: 'NE', Descripcion: 'Litro neto' },
    { Clave: 'NEW', Descripcion: 'Newton' },
    { Clave: 'NF', Descripcion: 'Mensaje' },
    { Clave: 'NG', Descripcion: 'Galón neto (us)' },
    { Clave: 'NH', Descripcion: 'Hora del mensaje' },
    { Clave: 'NI', Descripcion: 'Galón imperial neto' },
    { Clave: 'NIL', Descripcion: 'Nil' },
    { Clave: 'NIU', Descripcion: 'Número de unidades internacionales' },
    { Clave: 'NJ', Descripcion: 'Número de pantallas' },
    { Clave: 'NL', Descripcion: 'Carga' },
    { Clave: 'NM3', Descripcion: 'Metro cúbico normalizado' },
    { Clave: 'NMI', Descripcion: 'Milla náutica' },
    { Clave: 'NMP', Descripcion: 'Número de paquetes' },
    { Clave: 'NN', Descripcion: 'tren' },
    { Clave: 'NPR', Descripcion: 'Número de parejas' },
    { Clave: 'NPT', Descripcion: 'Número de partes' },
    { Clave: 'NQ', Descripcion: 'Mho' },
    { Clave: 'NR', Descripcion: 'Micromho' },
    { Clave: 'NT', Descripcion: 'Tonelada neta' },
    { Clave: 'NTT', Descripcion: 'Tonelada de registro neto' },
    { Clave: 'NV', Descripcion: 'vehículo' },
    { Clave: 'NV', Descripcion: 'Libra por tonelada métrica al aire seco' },
    { Clave: 'NX', Descripcion: 'Parte por mil' },
    { Clave: 'OA', Descripcion: 'Panel' },
    { Clave: 'ODE', Descripcion: 'Equvalente de agotamiento del ozona' },
    { Clave: 'Ohm', Descripcion: 'Ohm' },
    { Clave: 'ON', Descripcion: 'Onza por yarda cuadrada' },
    { Clave: 'ONZ', Descripcion: 'Onza (avoirdupois)' },
    { Clave: 'OP', Descripcion: 'Dos paquetes' },
    { Clave: 'OPM', Descripcion: 'Oscilaciones por minuto' },
    { Clave: 'OT', Descripcion: 'Hora extra' },
    { Clave: 'OZ', Descripcion: 'Onza AV' },
    { Clave: 'OZA', Descripcion: 'Onza líquida (estados unidos)' },
    { Clave: 'OZI', Descripcion: 'Onza líquida (UK)' },
    { Clave: 'P0', Descripcion: 'Página electrónica' },
    { Clave: 'P1', Descripcion: 'Tanto por ciento' },
    { Clave: 'P10', Descripcion: 'Culombio por metro' },
    { Clave: 'P11', Descripcion: 'Kiloweber' },
    { Clave: 'P12', Descripcion: 'Gamma' },
    { Clave: 'P13', Descripcion: 'Kilotesla' },
    { Clave: 'P14', Descripcion: 'Joule por segundo' },
    { Clave: 'P15', Descripcion: 'Joule por minuto' },
    { Clave: 'P16', Descripcion: 'Joule por hora' },
    { Clave: 'P17', Descripcion: 'Joule por día' },
    { Clave: 'P18', Descripcion: 'Kilojoule por segundo' },
    { Clave: 'P19', Descripcion: 'Kilojoule por minuto' },
    { Clave: 'P2', Descripcion: 'Libra por pie' },
    { Clave: 'P20', Descripcion: 'Kilojoule por hora' },
    { Clave: 'P21', Descripcion: 'Kilojoule por dia' },
    { Clave: 'P22', Descripcion: 'NanoOhm' },
    { Clave: 'P23', Descripcion: 'Ohm circular-mil por pie' },
    { Clave: 'P24', Descripcion: 'Kilohenry' },
    { Clave: 'P25', Descripcion: 'Lumen por pie cuadrado' },
    { Clave: 'P26', Descripcion: 'Foto' },
    { Clave: 'P27', Descripcion: 'Vela (medida)' },
    { Clave: 'P28', Descripcion: 'Candela por pulgada cuadrada' },
    { Clave: 'P29', Descripcion: 'Footlambert' },
    { Clave: 'P3', Descripcion: 'Tres paquetes' },
    { Clave: 'P30', Descripcion: 'Lambert' },
    { Clave: 'P31', Descripcion: 'Stilb ' },
    { Clave: 'P32', Descripcion: 'Candela por pie cuadrado' },
    { Clave: 'P33', Descripcion: 'Kilocandela' },
    { Clave: 'P34', Descripcion: 'Milicandela' },
    { Clave: 'P35', Descripcion: 'Hefner-kerze' },
    { Clave: 'P36', Descripcion: 'Candela internacional' },
    { Clave: 'P37', Descripcion: 'Unidad térmica británica (tabla internacional) por pie cuadrado' },
    { Clave: 'P38', Descripcion: 'Unidad térmica británica (termoquímica) por pie cuadrado' },
    { Clave: 'P39', Descripcion: 'Caloría (termoquímica) por centímetro cuadrado' },
    { Clave: 'P4', Descripcion: 'paquete de cuatro' },
    { Clave: 'P40', Descripcion: 'Langley' },
    { Clave: 'P41', Descripcion: 'Década (logaritmica)' },
    { Clave: 'P42', Descripcion: 'Pascal por segundo cuadrado' },
    { Clave: 'P43', Descripcion: 'Bel por metro' },
    { Clave: 'P44', Descripcion: 'Libra Mol' },
    { Clave: 'P45', Descripcion: 'Libra de Mol por segundo' },
    { Clave: 'P46', Descripcion: 'Libra Mol por minuto' },
    { Clave: 'P47', Descripcion: 'KiloMol por kilogramo' },
    { Clave: 'P48', Descripcion: 'Libra de Mol por libra' },
    { Clave: 'P49', Descripcion: 'Newton metro cuadrado por amperio' },
    { Clave: 'P5', Descripcion: 'Paquete de cinco' },
    { Clave: 'P50', Descripcion: 'Metro weber' },
    { Clave: 'P51', Descripcion: 'Mol por kilogramo pascal ' },
    { Clave: 'P52', Descripcion: 'Mol por metro cúbico pascal' },
    { Clave: 'P53', Descripcion: 'Unit por segundo' },
    { Clave: 'P54', Descripcion: 'MiliGray por segundo' },
    { Clave: 'P55', Descripcion: 'MicroGray por segundo' },
    { Clave: 'P56', Descripcion: 'NanoGray por segundo' },
    { Clave: 'P57', Descripcion: 'Gray por minuto' },
    { Clave: 'P58', Descripcion: 'MiliGray por minuto' },
    { Clave: 'P59', Descripcion: 'Microgray por minuto' },
    { Clave: 'P6', Descripcion: 'paquete de seis' },
    { Clave: 'P60', Descripcion: 'Nanogray por minuto' },
    { Clave: 'P61', Descripcion: 'Gray por hora' },
    { Clave: 'P62', Descripcion: 'MiliGray por hora' },
    { Clave: 'P63', Descripcion: 'Micro gray por hora' },
    { Clave: 'P64', Descripcion: 'Nanogray por hora' },
    { Clave: 'P65', Descripcion: 'Sievert por segundo' },
    { Clave: 'P66', Descripcion: 'MilliSievert por segundo' },
    { Clave: 'P67', Descripcion: 'MicroSievert por segundo' },
    { Clave: 'P68', Descripcion: 'NanoSievert por segundo' },
    { Clave: 'P69', Descripcion: 'Rem por segundo' },
    { Clave: 'P7', Descripcion: 'Paquete de siete' },
    { Clave: 'P70', Descripcion: 'Sievert por hora' },
    { Clave: 'P71', Descripcion: 'MilliSievert por hora' },
    { Clave: 'P72', Descripcion: 'Micro Sievert por hora' },
    { Clave: 'P73', Descripcion: 'NanoSievert por hora' },
    { Clave: 'P74', Descripcion: 'Sievert por minuto' },
    { Clave: 'P75', Descripcion: 'MilliSievert por minuto' },
    { Clave: 'P76', Descripcion: 'MicroSievert por minuto' },
    { Clave: 'P77', Descripcion: 'NanoSievert pominut ' },
    { Clave: 'P78', Descripcion: 'Reciprocidad por segundo' },
    { Clave: 'P79', Descripcion: 'Pascal metro cuadrado por kilogramo' },
    { Clave: 'P8', Descripcion: 'Paquete de ocho' },
    { Clave: 'P80', Descripcion: 'Milipascal por metro.' },
    { Clave: 'P81', Descripcion: 'Kilopascal por metro.' },
    { Clave: 'P82', Descripcion: 'Hectopascal por metro.' },
    { Clave: 'P83', Descripcion: 'Admosfera estandar por metro.' },
    { Clave: 'P84', Descripcion: 'Admosfera tecnica por metro.' },
    { Clave: 'P85', Descripcion: 'Torr por metro.' },
    { Clave: 'P86', Descripcion: 'Psi por pulgada' },
    { Clave: 'P87', Descripcion: 'Metro cúbico por segundo de metro cuadrado' },
    { Clave: 'P88', Descripcion: 'Rhe' },
    { Clave: 'P89', Descripcion: 'Libra por metro cúbico de pulgada' },
    { Clave: 'P9', Descripcion: 'Nueve paquetes' },
    { Clave: 'P90', Descripcion: 'Libra-fuerza por pulgada cuadrada' },
    { Clave: 'P91', Descripcion: 'Permanente (0°c)' },
    { Clave: 'P92', Descripcion: 'Permanente (23°c)' },
    { Clave: 'P93', Descripcion: 'Byte por segundo' },
    { Clave: 'P94', Descripcion: 'Kilobyte por segundo' },
    { Clave: 'P95', Descripcion: 'Megabite por segundo' },
    { Clave: 'P96', Descripcion: 'Recíproco de la unidad si deriva voltio' },
    { Clave: 'P97', Descripcion: 'Reciprocidad de Radian' },
    { Clave: 'P98', Descripcion: 'Pascal a la suma de potencia de los números estequimetricos' },
    { Clave: 'P99', Descripcion: 'Mols por metro cúbico a la suma de potencia de los números estequimetricos' },
    { Clave: 'PAL', Descripcion: 'Pascal' },
    { Clave: 'PB', Descripcion: 'Par de la pulgada' },
    { Clave: 'PD', Descripcion: 'Almohadilla' },
    { Clave: 'PE', Descripcion: 'Libra equivalente' },
    { Clave: 'PFL', Descripcion: 'Litro de prueba ' },
    { Clave: 'PGL', Descripcion: 'Galón de prueba' },
    { Clave: 'PI', Descripcion: 'Tono' },
    { Clave: 'PLA', Descripcion: 'Grado de platón ' },
    { Clave: 'PM', Descripcion: 'Porcentaje de libra' },
    { Clave: 'PO', Descripcion: 'Libra por pulgada' },
    { Clave: 'PQ', Descripcion: 'Página por pulgada' },
    { Clave: 'PR', Descripcion: 'Par' },
    { Clave: 'PS', Descripcion: 'Libra fuerza por pulgada cuadrada' },
    { Clave: 'PT', Descripcion: 'Pinta (US)' },
    { Clave: 'PTD', Descripcion: 'Pinta seca (estados unidos)' },
    { Clave: 'PTI', Descripcion: 'Pint (uk)' },
    { Clave: 'PTL', Descripcion: 'Pinta líquida (estados unidos)' },
    { Clave: 'PTN', Descripcion: 'Parte' },
    { Clave: 'PV', Descripcion: 'Media pinta (US)' },
    { Clave: 'PW', Descripcion: 'Libra por pulgada de ancho' },
    { Clave: 'PY', Descripcion: 'Pico seco (EUA)' },
    { Clave: 'PZ', Descripcion: 'Peck dry (UK)' },
    { Clave: 'Q10', Descripcion: 'Joule por tesla' },
    { Clave: 'Q11', Descripcion: 'Erlang' },
    { Clave: 'Q12', Descripcion: 'Octeto' },
    { Clave: 'Q13', Descripcion: 'Octeto por segundo' },
    { Clave: 'Q14', Descripcion: 'Shannon' },
    { Clave: 'Q15', Descripcion: 'Hartley' },
    { Clave: 'Q16', Descripcion: 'Unidad natural de información ' },
    { Clave: 'Q17', Descripcion: 'Shannon por segundo' },
    { Clave: 'Q18', Descripcion: 'Hartley por segundo ' },
    { Clave: 'Q19', Descripcion: 'Unidad natural de información por segundo ' },
    { Clave: 'Q20', Descripcion: 'Segundo por kilogramo' },
    { Clave: 'Q21', Descripcion: 'Watt metro cuadrado' },
    { Clave: 'Q22', Descripcion: 'Segundo por metro cúbicos de radianes ' },
    { Clave: 'Q23', Descripcion: 'Weber a la potencia menos uno' },
    { Clave: 'Q24', Descripcion: 'Reciprocidad de Pulgada' },
    { Clave: 'Q25', Descripcion: 'Dioptría' },
    { Clave: 'Q26', Descripcion: 'Uno por uno' },
    { Clave: 'Q27', Descripcion: 'Newtons metros por metro' },
    { Clave: 'Q28', Descripcion: 'Kilograma por metro cuadrado pascal segundo' },
    { Clave: 'Q29', Descripcion: 'Microgramo por hectogramo' },
    { Clave: 'Q3', Descripcion: 'Comida' },
    { Clave: 'Q30', Descripcion: 'Ph (potencial de hidrogeno)' },
    { Clave: 'Q31', Descripcion: 'Kilojoule por gramo' },
    { Clave: 'Q32', Descripcion: 'Femtolitro' },
    { Clave: 'Q33', Descripcion: 'Picolitro' },
    { Clave: 'Q34', Descripcion: 'Nanolitro' },
    { Clave: 'Q35', Descripcion: 'Megawatts por minuto' },
    { Clave: 'Q36', Descripcion: 'Metro cuadrado por metro cúbico' },
    { Clave: 'Q37', Descripcion: 'Metro cúbico estándar por día' },
    { Clave: 'Q38', Descripcion: 'Metro cúbico estándar por hora' },
    { Clave: 'Q39', Descripcion: 'Metro cúbico normalizado por día' },
    { Clave: 'Q40', Descripcion: 'Metro cúbico normalizado por hora' },
    { Clave: 'Q41', Descripcion: 'Joule por metro cúbico normalizado' },
    { Clave: 'Q42', Descripcion: 'Joule por metro cúbico estándar' },
    { Clave: 'QA', Descripcion: 'Página-fascimil' },
    { Clave: 'QAN', Descripcion: 'Cuarto (de un año)' },
    { Clave: 'QB', Descripcion: 'Página, copia impresa' },
    { Clave: 'QD', Descripcion: 'Cuarta docena' },
    { Clave: 'QH', Descripcion: 'Un cuarto de hora' },
    { Clave: 'QK', Descripcion: 'Cuarto de kilogramo' },
    { Clave: 'QR', Descripcion: 'Mano de papel' },
    { Clave: 'QT', Descripcion: 'Cuarto (EUA)' },
    { Clave: 'QTD', Descripcion: 'Cuarto seco (estados unidos)' },
    { Clave: 'QTI', Descripcion: 'Cuarto (UK)' },
    { Clave: 'QTL', Descripcion: 'Cuarto de líquido (estadis unidos)' },
    { Clave: 'QTR', Descripcion: 'Cuarto' },
    { Clave: 'R1', Descripcion: 'Pica' },
    { Clave: 'R9', Descripcion: 'Mil metros cúbicos ' },
    { Clave: 'RH', Descripcion: 'Hora de funcionamiento' },
    { Clave: 'RK', Descripcion: 'Medida métrica de rollo' },
    { Clave: 'RM', Descripcion: 'Resma' },
    { Clave: 'RN', Descripcion: 'Medida métrica de Hojas (resma)' },
    { Clave: 'ROM', Descripcion: 'Habitación' },
    { Clave: 'RP', Descripcion: 'Libra por resma' },
    { Clave: 'RPM', Descripcion: 'Revoluciones por minuto' },
    { Clave: 'RPS', Descripcion: 'Revoluciones por segundo' },
    { Clave: 'RS', Descripcion: 'Reiniciar' },
    { Clave: 'RT', Descripcion: 'Milla de toneladas de ingresos' },
    { Clave: 'RU', Descripcion: 'correr' },
    { Clave: 'S3', Descripcion: 'Pie cuadrado por segundo' },
    { Clave: 'S4', Descripcion: 'Metro cuadrado por segundo' },
    { Clave: 'S5', Descripcion: 'Sesenta cuartos de pulgada' },
    { Clave: 'S6', Descripcion: 'Sesión' },
    { Clave: 'S7', Descripcion: 'unidad de almacenamiento' },
    { Clave: 'S8', Descripcion: 'Unidad de publicidad estándar' },
    { Clave: 'SAN', Descripcion: 'Medio año' },
    { Clave: 'SCO', Descripcion: 'Puntuación' },
    { Clave: 'SCR', Descripcion: 'Escrúpulo' },
    { Clave: 'SD', Descripcion: 'Libra sólida' },
    { Clave: 'SE', Descripcion: 'Sección' },
    { Clave: 'SEC', Descripcion: 'Segundo [unidad de tiempo]' },
    { Clave: 'SET', Descripcion: 'Conjunto' },
    { Clave: 'SG', Descripcion: 'Segmento ' },
    { Clave: 'SHT', Descripcion: 'Tonelada de envíos' },
    { Clave: 'SIE', Descripcion: 'Siemens' },
    { Clave: 'SK', Descripcion: 'Camión cisterna con división' },
    { Clave: 'SM3', Descripcion: 'Metro cúbico estándar' },
    { Clave: 'SMI', Descripcion: 'Milla (milla estatal)' },
    { Clave: 'SN', Descripcion: 'Barra cuadrada' },
    { Clave: 'SQ', Descripcion: 'Cuadrado' },
    { Clave: 'SQR', Descripcion: 'Cuadrado y techado' },
    { Clave: 'SR', Descripcion: 'Tira' },
    { Clave: 'SS', Descripcion: 'Medida métrica de hoja' },
    { Clave: 'SST', Descripcion: 'Estandar corto (7200 partidos)' },
    { Clave: 'STC', Descripcion: 'Palo' },
    { Clave: 'STI', Descripcion: 'Estone (UK)' },
    { Clave: 'STK', Descripcion: 'Palo, cigarrillo' },
    { Clave: 'STL', Descripcion: 'Litro estándar' },
    { Clave: 'STN', Descripcion: 'Tonelada (estados unidos) o tonelada corta (UK y estados unidos)' },
    { Clave: 'STW', Descripcion: 'Paja' },
    { Clave: 'SW', Descripcion: 'Número de madejas' },
    { Clave: 'SX', Descripcion: 'Envío' },
    { Clave: 'SYR', Descripcion: 'Jeringuilla' },
    { Clave: 'T0', Descripcion: 'Linea de telecomunicaciones en servicio' },
    { Clave: 'T1', Descripcion: 'Mil libras' },
    { Clave: 'T3', Descripcion: 'Mil pedazos' },
    { Clave: 'T4', Descripcion: 'Bolsa de mil' },
    { Clave: 'T5', Descripcion: 'Mil envolturas' },
    { Clave: 'T6', Descripcion: 'Mil galones (US)' },
    { Clave: 'T7', Descripcion: 'Impresión de mil' },
    { Clave: 'T8', Descripcion: 'Mil pulgadas lineales' },
    { Clave: 'TA', Descripcion: 'Décimo de pie cúbico' },
    { Clave: 'TAB', Descripcion: 'Tonelada de registro bruto' },
    { Clave: 'TAH', Descripcion: 'Kiloamperio-hora (milamperio-hora)' },
    { Clave: 'TAN', Descripcion: 'Número de acido total' },
    { Clave: 'TC', Descripcion: 'Camión' },
    { Clave: 'TD', Descripcion: 'Termico' },
    { Clave: 'TE', Descripcion: 'Totalizador' },
    { Clave: 'TF', Descripcion: 'Diez yardas cuadradas' },
    { Clave: 'TI', Descripcion: 'Mil pulgadas cuadradas' },
    { Clave: 'TIC', Descripcion: 'Tonelada métrica, incluido el contenedor' },
    { Clave: 'TIP', Descripcion: 'Tonelada métrica, incluido el embalaje interior' },
    { Clave: 'TJ', Descripcion: 'Mil centímetros cuadrados' },
    { Clave: 'TKM', Descripcion: 'Tonelada kilometro' },
    { Clave: 'TL', Descripcion: 'Mil pies (lineal)' },
    { Clave: 'TMS', Descripcion: 'Kilogramo de carne importada, menos despojos' },
    { Clave: 'TNE', Descripcion: 'Tonelada (tonelada métrica)' },
    { Clave: 'TP', Descripcion: 'Paquete de diez' },
    { Clave: 'TPI', Descripcion: 'Dientes por pulgada' },
    { Clave: 'TPR', Descripcion: 'Decenas de pares' },
    { Clave: 'TQ', Descripcion: 'Mil pies' },
    { Clave: 'TQD', Descripcion: 'Mil metros cúbicos por día ' },
    { Clave: 'TR', Descripcion: 'Diez pies cuadrados' },
    { Clave: 'TRL', Descripcion: 'Trillones (eur)' },
    { Clave: 'Ts', Descripcion: 'Mil pies cuadrados' },
    { Clave: 'TSD', Descripcion: 'Tonelada de sustancia 90% seca' },
    { Clave: 'TSH', Descripcion: 'Tonelada de vapor por hora' },
    { Clave: 'TST', Descripcion: 'Decena de conjuntos' },
    { Clave: 'TT', Descripcion: 'Mil metros lineales' },
    { Clave: 'TTS', Descripcion: 'Decenas de millar de pegatinas' },
    { Clave: 'Tu', Descripcion: 'Contenedor externo' },
    { Clave: 'TV', Descripcion: 'Mil kilogramos' },
    { Clave: 'TW', Descripcion: 'Mil hojas' },
    { Clave: 'U1', Descripcion: 'Tratamiento ' },
    { Clave: 'U2', Descripcion: 'Número de tabletas' },
    { Clave: 'UA', Descripcion: 'Torr' },
    { Clave: 'UB', Descripcion: 'Linea de telecomunicaciones en servicio promedio ' },
    { Clave: 'UC', Descripcion: 'Puerto de telecomunicaciones ' },
    { Clave: 'UD', Descripcion: 'Décimo minuto' },
    { Clave: 'UE', Descripcion: 'Hora de décimo' },
    { Clave: 'UF', Descripcion: 'Uso por línea de telecomunicaciones promedio' },
    { Clave: 'UH', Descripcion: 'Diez mil yardas' },
    { Clave: 'UM', Descripcion: 'Millón de unidades' },
    { Clave: 'UN', Descripcion: 'Newton metro' },
    { Clave: 'VA', Descripcion: 'Voltio-amperio por kilogramo' },
    { Clave: 'VLT', Descripcion: 'Voltio' },
    { Clave: 'VP', Descripcion: 'Volumen porcentual ' },
    { Clave: 'VS', Descripcion: 'Visita' },
    { Clave: 'W2', Descripcion: 'Kilo húmedo ' },
    { Clave: 'W4', Descripcion: 'Dos semanas' },
    { Clave: 'WA', Descripcion: 'Watt por kilogramo ' },
    { Clave: 'WB', Descripcion: 'Libra húmeda' },
    { Clave: 'WCD', Descripcion: 'Cable' },
    { Clave: 'WE', Descripcion: 'Tonelada húmeda ' },
    { Clave: 'WEB', Descripcion: 'Weber' },
    { Clave: 'WEE', Descripcion: 'Semana' },
    { Clave: 'WG', Descripcion: 'Galón de vino' },
    { Clave: 'WH', Descripcion: 'Rueda' },
    { Clave: 'WHR', Descripcion: 'Watt hora' },
    { Clave: 'WI', Descripcion: 'Peso por pulgada cuadrada' },
    { Clave: 'WM', Descripcion: 'Mes de trabajo ' },
    { Clave: 'WR', Descripcion: 'Envolver' },
    { Clave: 'WSD', Descripcion: 'Estándar' },
    { Clave: 'WTT', Descripcion: 'Watt' },
    { Clave: 'WW', Descripcion: 'Mililitro de agua' },
    { Clave: 'X1', Descripcion: 'Cadena de gunter' },
    { Clave: 'X1A', Descripcion: 'Tambor de acero' },
    { Clave: 'X1B', Descripcion: 'Tambor de aluminio' },
    { Clave: 'X1D', Descripcion: 'Tambor contrachapado' },
    { Clave: 'X1F', Descripcion: 'Contenedor flexible' },
    { Clave: 'X1G', Descripcion: 'Tambor de fibra' },
    { Clave: 'X1w', Descripcion: 'Tambor de madera' },
    { Clave: 'X2C', Descripcion: 'Barril de madera' },
    { Clave: 'X3A', Descripcion: 'Bidón de acero' },
    { Clave: 'X3H', Descripcion: 'Bidón de plástico' },
    { Clave: 'X43', Descripcion: 'Bolsa de gran tamaño' },
    { Clave: 'X44', Descripcion: 'Bolsa de plastico' },
    { Clave: 'X4A', Descripcion: 'Caja de acero' },
    { Clave: 'X4B', Descripcion: 'Caja de aluminio' },
    { Clave: 'X4C', Descripcion: 'Caja de  madera natural' },
    { Clave: 'X4D', Descripcion: 'Caja de contrachapado' },
    { Clave: 'X4F', Descripcion: 'Caja de madera reconstituida' },
    { Clave: 'X4G', Descripcion: 'Caja de cartón' },
    { Clave: 'X4H', Descripcion: 'Caja de plástico' },
    { Clave: 'X5H', Descripcion: 'Bolsa de plástico tejido' },
    { Clave: 'X5L', Descripcion: 'Bolsa textil' },
    { Clave: 'X5M', Descripcion: 'Bolsa de papel' },
    { Clave: 'X6H', Descripcion: 'Recipiente de plástico, Contenedor compuesto.' },
    { Clave: 'X6P', Descripcion: 'Recipiente de vidrio, Contenedor compuesto.' },
    { Clave: 'X7A', Descripcion: 'Estuche para carro' },
    { Clave: 'X7B', Descripcion: 'Estuche de madera' },
    { Clave: 'X8A', Descripcion: 'Pallet de madera' },
    { Clave: 'X8B', Descripcion: 'Cajón de madera' },
    { Clave: 'X8C', Descripcion: 'Madera flejada' },
    { Clave: 'XAA', Descripcion: 'Contenedor intermedio  para gráneles de plástico rígido' },
    { Clave: 'XAB', Descripcion: 'Contenedor de fibra' },
    { Clave: 'XAC', Descripcion: 'Contenedor de papel' },
    { Clave: 'XAD', Descripcion: 'Contenedor de madera' },
    { Clave: 'XAE', Descripcion: 'Aerosol' },
    { Clave: 'XAF', Descripcion: 'Pallet modular con collares,  80cms * 60cms' },
    { Clave: 'XAG', Descripcion: 'Pallet o empaquetado' },
    { Clave: 'XAH', Descripcion: 'Pallet, 100cms X 110cms' },
    { Clave: 'XAI', Descripcion: 'Contenedor tipo concha' },
    { Clave: 'XAJ', Descripcion: 'Cono' },
    { Clave: 'XAL', Descripcion: 'Esféra' },
    { Clave: 'XAM', Descripcion: 'Ampolleta no protegida' },
    { Clave: 'XAP', Descripcion: 'Ampolleta protegida' },
    { Clave: 'XAT', Descripcion: 'Atomizador' },
    { Clave: 'XAV', Descripcion: 'Cápsula' },
    { Clave: 'XB4', Descripcion: 'Cinturón' },
    { Clave: 'XBA', Descripcion: 'Barril' },
    { Clave: 'XBB', Descripcion: 'Bobina' },
    { Clave: 'XBC', Descripcion: 'Cajón para botellas / Estante para botellas' },
    { Clave: 'XBD', Descripcion: 'Tablero' },
    { Clave: 'XBE', Descripcion: 'Flejado' },
    { Clave: 'XBF', Descripcion: 'Globo no protegido' },
    { Clave: 'XBG', Descripcion: 'Bolso' },
    { Clave: 'XBH', Descripcion: 'Manojo' },
    { Clave: 'XBI', Descripcion: 'Compartimiento' },
    { Clave: 'XBJ', Descripcion: 'Cubeta' },
    { Clave: 'XBK', Descripcion: 'Cesta' },
    { Clave: 'XBL', Descripcion: 'Paca comprimida' },
    { Clave: 'XBM', Descripcion: 'Cuenco' },
    { Clave: 'XBN', Descripcion: 'Paca no comprimida' },
    { Clave: 'XBO', Descripcion: 'Botella no-protegida y cilíndrica' },
    { Clave: 'XBP', Descripcion: 'Globo protegido' },
    { Clave: 'XBQ', Descripcion: 'Botella cilíndrica protegida' },
    { Clave: 'XBR', Descripcion: 'Barra' },
    { Clave: 'XBS', Descripcion: 'Botella, no-protegida en forma de bulbo' },
    { Clave: 'XBT', Descripcion: 'Rollo de tela' },
    { Clave: 'XBU', Descripcion: 'Butt' },
    { Clave: 'XBV', Descripcion: 'Botella de bulbo protegido' },
    { Clave: 'XBW', Descripcion: 'Caja para líquidos' },
    { Clave: 'XBX', Descripcion: 'Caja' },
    { Clave: 'XBY', Descripcion: 'Tablero, con fleje/ agrupados/ armados' },
    { Clave: 'XBZ', Descripcion: 'Barras, con fleje/ agrupados/ armados' },
    { Clave: 'XCA', Descripcion: 'Lata rectangular' },
    { Clave: 'XCB', Descripcion: 'Cajón para cerveza' },
    { Clave: 'XCC', Descripcion: 'Mantequera' },
    { Clave: 'XCD', Descripcion: 'Lata con mango y boquilla' },
    { Clave: 'XCE', Descripcion: 'Cesto tejido' },
    { Clave: 'XCF', Descripcion: 'Cofre' },
    { Clave: 'XCG', Descripcion: 'Contenedor tipo Jaula' },
    { Clave: 'XCH', Descripcion: 'Cajonera' },
    { Clave: 'XCI', Descripcion: 'Frasco' },
    { Clave: 'XCJ', Descripcion: 'Ataúd' },
    { Clave: 'XCK', Descripcion: 'Barrica' },
    { Clave: 'XCL', Descripcion: 'Espiral' },
    { Clave: 'XCM', Descripcion: 'Paquete de tarjetas' },
    { Clave: 'XCN', Descripcion: 'Contenedor, no especificado como equipo de transporte' },
    { Clave: 'XCO', Descripcion: 'Garrafón no protegido' },
    { Clave: 'XCP', Descripcion: 'Garrafón protegido' },
    { Clave: 'XCQ', Descripcion: 'Cartucho' },
    { Clave: 'XCR', Descripcion: 'Cajón' },
    { Clave: 'XCS', Descripcion: 'Estuche' },
    { Clave: 'XCT', Descripcion: 'Cartón' },
    { Clave: 'XCU', Descripcion: 'Vaso' },
    { Clave: 'XCV', Descripcion: 'Cubierta' },
    { Clave: 'XCW', Descripcion: 'Jaula estilo rodillo' },
    { Clave: 'XCX', Descripcion: 'Lata cilíndrica' },
    { Clave: 'XCY', Descripcion: 'Cilindro' },
    { Clave: 'XCZ', Descripcion: 'Lona' },
    { Clave: 'XDA', Descripcion: 'Cajón multicapa de plástico' },
    { Clave: 'XDB', Descripcion: 'Cajón de varias capas de madera' },
    { Clave: 'XDC', Descripcion: 'Cajón multicapa de cartón' },
    { Clave: 'XDG', Descripcion: 'Jaula, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP))' },
    { Clave: 'XDH', Descripcion: 'Caja, Según la clasificación de la compañía (CHEP), Eurobox' },
    { Clave: 'XDI', Descripcion: 'Tambor de hierro' },
    { Clave: 'XDJ', Descripcion: 'damajuana o garrafa, no protegido' },
    { Clave: 'XDK', Descripcion: 'Cajón a granel, cartón' },
    { Clave: 'XDL', Descripcion: 'Cajas de plástico' },
    { Clave: 'XDM', Descripcion: 'Cajones a granel de madera' },
    { Clave: 'XDN', Descripcion: 'Dispensador' },
    { Clave: 'XDP', Descripcion: 'damajuana o garrafa, protegido' },
    { Clave: 'XDR', Descripcion: 'Tambor' },
    { Clave: 'XDS', Descripcion: 'Bandeja de una capa sin cubierta y de plástico' },
    { Clave: 'XDT', Descripcion: 'Bandeja de una capa sin cubierta y de madera' },
    { Clave: 'XDU', Descripcion: 'Bandeja de una capa sin cubierta y  poliestireno' },
    { Clave: 'XDV', Descripcion: 'Bandeja de una capa sin cubierta y de cartón' },
    { Clave: 'XDW', Descripcion: 'Bandeja de dos capas sin tapa y con bandeja de plástico' },
    { Clave: 'XDX', Descripcion: 'Bandeja de dos capas sin cubierta y de madera' },
    { Clave: 'XDY', Descripcion: 'Bandeja de dos capas sin cubierta y de cartón' },
    { Clave: 'XEC', Descripcion: 'Bolsa de plástico' },
    { Clave: 'XED', Descripcion: 'Estuche, con pallet de base' },
    { Clave: 'XEE', Descripcion: 'Estuche, con pallet base de madera' },
    { Clave: 'XEF', Descripcion: 'Estuche, con pallet base de cartón' },
    { Clave: 'XEG', Descripcion: 'Estuche, con pallet base de plástico' },
    { Clave: 'XEH', Descripcion: 'Estuche, con pallet base de metal' },
    { Clave: 'XEI', Descripcion: 'Estuche isotérmico' },
    { Clave: 'XEN', Descripcion: 'Sobre' },
    { Clave: 'XFB', Descripcion: 'Bolsa flexible' },
    { Clave: 'XFC', Descripcion: 'Cajón para fruta' },
    { Clave: 'XFD', Descripcion: 'Cajón enmarcado' },
    { Clave: 'XFE', Descripcion: 'Tanque flexible' },
    { Clave: 'XFI', Descripcion: 'Firkin' },
    { Clave: 'XFL', Descripcion: 'Matraz' },
    { Clave: 'XFO', Descripcion: 'Cajón para zapatos' },
    { Clave: 'XFP', Descripcion: 'Caja auxiliar para película fotográfica' },
    { Clave: 'XFR', Descripcion: 'Marco' },
    { Clave: 'XFT', Descripcion: 'Contenedor para alimentos' },
    { Clave: 'XFW', Descripcion: 'Carro de cama plana' },
    { Clave: 'XFX', Descripcion: 'Bolsa flexible tipo contenedor' },
    { Clave: 'XGB', Descripcion: 'Botella para gas' },
    { Clave: 'XGI', Descripcion: 'Viga' },
    { Clave: 'XGL', Descripcion: 'Contenedor tipo galón' },
    { Clave: 'XGR', Descripcion: 'Recipiente de vidrio' },
    { Clave: 'XGU', Descripcion: 'Bandeja contenedor para apilar horizontalmente objetos planos' },
    { Clave: 'XGY', Descripcion: 'Costal de Yute' },
    { Clave: 'XGZ', Descripcion: 'Vigas con correas o agrupadas' },
    { Clave: 'XHA', Descripcion: 'Cesta con mango y de plástico' },
    { Clave: 'XHB', Descripcion: 'Cesta con mango y de madera' },
    { Clave: 'XHC', Descripcion: 'Cesta  con asa y de  cartón' },
    { Clave: 'XHG', Descripcion: 'Hogshead' },
    { Clave: 'XHN', Descripcion: 'Gancho' },
    { Clave: 'XHR', Descripcion: 'Cesto' },
    { Clave: 'XIA', Descripcion: 'Paquete con pantalla y de madera' },
    { Clave: 'XIB', Descripcion: 'Paquete  con pantalla y de cartón' },
    { Clave: 'XIC', Descripcion: 'Paquete con pantalla y de plástico' },
    { Clave: 'XID', Descripcion: 'Paquete con pantalla y de metal' },
    { Clave: 'XIE', Descripcion: 'Paquete de mostrador.' },
    { Clave: 'XIF', Descripcion: 'Envase para alimentos' },
    { Clave: 'XIG', Descripcion: 'Paquete envuelto en papel' },
    { Clave: 'XIH', Descripcion: 'Tambor de plástico' },
    { Clave: 'XIK', Descripcion: 'Paquete de cartón con los agujeros para botellas' },
    { Clave: 'XIL', Descripcion: 'Bandeja rígida con tapa y apilable (CEN TS 14482: 2002)' },
    { Clave: 'XIN', Descripcion: 'Lingote' },
    { Clave: 'XIZ', Descripcion: 'Lingotes  con correas/ agrupados' },
    { Clave: 'XJB', Descripcion: 'Bolsa jumbo' },
    { Clave: 'XJC', Descripcion: 'Bidón rectangular' },
    { Clave: 'XJG', Descripcion: 'Jarra' },
    { Clave: 'XJR', Descripcion: 'Tarro' },
    { Clave: 'XJT', Descripcion: 'Bolsa de yute' },
    { Clave: 'XJY', Descripcion: 'Bidón, cilíndrico' },
    { Clave: 'XKG', Descripcion: 'Barrilete' },
    { Clave: 'XKI', Descripcion: 'Kit (Conjunto de piezas)' },
    { Clave: 'XLE', Descripcion: 'Valijas' },
    { Clave: 'XLG', Descripcion: 'Bitacora' },
    { Clave: 'XLT', Descripcion: 'Lote' },
    { Clave: 'XLU', Descripcion: 'Caja de arrastre' },
    { Clave: 'XLV', Descripcion: 'Contenedor pequeño' },
    { Clave: 'XLZ', Descripcion: 'Registros  con fleje/ agrupados/ armados' },
    { Clave: 'XMA', Descripcion: 'Cajón metálico' },
    { Clave: 'XMB', Descripcion: 'Múltiplo de bolsas' },
    { Clave: 'XMC', Descripcion: 'Cajón para leche' },
    { Clave: 'XME', Descripcion: 'Contenedor de metal' },
    { Clave: 'XMR', Descripcion: 'Recipiente de metal' },
    { Clave: 'XMS', Descripcion: 'Saco milti-pared' },
    { Clave: 'XMT', Descripcion: 'Tapete' },
    { Clave: 'XMW', Descripcion: 'Contenedor envuelto en plástico' },
    { Clave: 'XMX', Descripcion: 'Caja pequeña de cerillos' },
    { Clave: 'XNA', Descripcion: 'No disponible' },
    { Clave: 'XNE', Descripcion: 'Sin empaque o no empaquetado' },
    { Clave: 'XNF', Descripcion: 'Sin empaque o no empaquetado, unidad simple' },
    { Clave: 'XNG', Descripcion: 'Sin empaque o no empaquetado, unidades múltiples' },
    { Clave: 'XNS', Descripcion: 'Caja nido' },
    { Clave: 'XNT', Descripcion: 'Red' },
    { Clave: 'XNU', Descripcion: 'Red de plástico con tubo' },
    { Clave: 'XNV', Descripcion: 'Red textil con tubo' },
    { Clave: 'XOA', Descripcion: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 40 cm x 6' },
    { Clave: 'XOB', Descripcion: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 80 cm x 1' },
    { Clave: 'XOC', Descripcion: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 100 cm x ' },
    { Clave: 'XOD', Descripcion: 'Pallet, AS 4068-1993' },
    { Clave: 'XOE', Descripcion: 'Pallet, ISO T11' },
    { Clave: 'XOF', Descripcion: 'Plataforma, peso o dimensión no especificada' },
    { Clave: 'XOK', Descripcion: 'Bloque' },
    { Clave: 'XOT', Descripcion: 'Octabin' },
    { Clave: 'XP2', Descripcion: 'Charola' },
    { Clave: 'XPA', Descripcion: 'Cajetilla' },
    { Clave: 'XPB', Descripcion: 'Pallet, Caja combinada y abierta con caja y pallet.' },
    { Clave: 'XPC', Descripcion: 'Paquete postal' },
    { Clave: 'XPD', Descripcion: 'Pallet modular con collares (80cms * 100cms)' },
    { Clave: 'XPE', Descripcion: 'Pallet modular con collares (80cms * 120cms)' },
    { Clave: 'XPF', Descripcion: 'Corral' },
    { Clave: 'XPG', Descripcion: 'Placa' },
    { Clave: 'XPH', Descripcion: 'Cantaro' },
    { Clave: 'XPI', Descripcion: 'Pleca' },
    { Clave: 'XPJ', Descripcion: 'Canastilla' },
    { Clave: 'XPK', Descripcion: 'Paquete' },
    { Clave: 'XPL', Descripcion: 'Balde' },
    { Clave: 'XPN', Descripcion: 'Tablón' },
    { Clave: 'XPO', Descripcion: 'Bolsa pequeña' },
    { Clave: 'XPR', Descripcion: 'Contenedor de plástico' },
    { Clave: 'XPT', Descripcion: 'Maceta' },
    { Clave: 'XPU', Descripcion: 'Cacerola' },
    { Clave: 'XPV', Descripcion: 'Tubos, con fleje/ agrupados/ armados' },
    { Clave: 'XPX', Descripcion: 'Pallet' },
    { Clave: 'XPY', Descripcion: 'Placas con fleje/ agrupados/ armados' },
    { Clave: 'XPZ', Descripcion: 'Tablones con fleje/ agrupados/ armados' },
    { Clave: 'XQA', Descripcion: 'Tambor de acero con cabeza no desmontable' },
    { Clave: 'XQB', Descripcion: 'Tambor de  acero con cabeza extraíble' },
    { Clave: 'XQC', Descripcion: 'Tambor de aluminio con cabeza no extraíble' },
    { Clave: 'XQD', Descripcion: 'Tambor de aluminio con cabeza extraíble' },
    { Clave: 'XQF', Descripcion: 'Tambor, plástico con cabeza no desmontable' },
    { Clave: 'XQG', Descripcion: 'Tambor, plástico, cabeza extraíble' },
    { Clave: 'XQH', Descripcion: 'Barril de madera con tapón' },
    { Clave: 'XQJ', Descripcion: 'Barril de madera con cabeza desprendible' },
    { Clave: 'XQK', Descripcion: 'Bidón de acero con cabeza no desmontable' },
    { Clave: 'XQL', Descripcion: 'Bidón de acero con cabeza desmontable' },
    { Clave: 'XQM', Descripcion: 'Bidón de plástico con cabeza no desmontable' },
    { Clave: 'XQN', Descripcion: 'Bidón de plástico con cabeza extraíble' },
    { Clave: 'XQP', Descripcion: 'Caja de madera natural estándar' },
    { Clave: 'XQQ', Descripcion: 'Caja de madera natural con muros a prueba de filtraciones' },
    { Clave: 'XQR', Descripcion: 'Caja de plástico expandida' },
    { Clave: 'XQS', Descripcion: 'Caja de plástico sólida' },
    { Clave: 'XRD', Descripcion: 'Rod' },
    { Clave: 'XRG', Descripcion: 'Anillo' },
    { Clave: 'XRJ', Descripcion: 'Estante, Perchero para ropa' },
    { Clave: 'XRK', Descripcion: 'Estante' },
    { Clave: 'XRL', Descripcion: 'Carrete' },
    { Clave: 'XRO', Descripcion: 'Rollo' },
    { Clave: 'XRT', Descripcion: 'Red Roja' },
    { Clave: 'XRZ', Descripcion: 'Varillas  con fleje/ agrupados/ armados' },
    { Clave: 'XSA', Descripcion: 'Saco' },
    { Clave: 'XSB', Descripcion: 'Losa' },
    { Clave: 'XSC', Descripcion: 'Cajón poco profundo' },
    { Clave: 'XSD', Descripcion: 'Huso' },
    { Clave: 'XSE', Descripcion: 'Baúl' },
    { Clave: 'XSH', Descripcion: 'Bolsa pequeña hermética' },
    { Clave: 'XSI', Descripcion: 'Patín' },
    { Clave: 'XSK', Descripcion: 'Carcasa esqueleto' },
    { Clave: 'XSL', Descripcion: 'Hoja de deslizamiento' },
    { Clave: 'XSM', Descripcion: 'Hoja de metal' },
    { Clave: 'XSO', Descripcion: 'Carrete pequeño' },
    { Clave: 'XSP', Descripcion: 'Hoja de empaque de plástico' },
    { Clave: 'XSS', Descripcion: 'Cajón de acero' },
    { Clave: 'XSU', Descripcion: 'Maleta' },
    { Clave: 'XSV', Descripcion: 'Sobre de acero' },
    { Clave: 'XSW', Descripcion: 'Envoltorio' },
    { Clave: 'XSY', Descripcion: 'Manga' },
    { Clave: 'XSZ', Descripcion: 'Hojas  con fleje/ agrupados/ armados' },
    { Clave: 'XT1', Descripcion: 'Tableta' },
    { Clave: 'XTB', Descripcion: 'Tina' },
    { Clave: 'XTC', Descripcion: 'Caja para té' },
    { Clave: 'XTD', Descripcion: 'Tubo plegable' },
    { Clave: 'XTG', Descripcion: 'Contenedor de tanque genérico' },
    { Clave: 'XTI', Descripcion: 'Tierce' },
    { Clave: 'XTK', Descripcion: 'Tanque rectangular' },
    { Clave: 'XTL', Descripcion: 'Tina con tapa' },
    { Clave: 'XTN', Descripcion: 'Hojalata' },
    { Clave: 'XTO', Descripcion: 'Tonel' },
    { Clave: 'XTR', Descripcion: 'Maletero' },
    { Clave: 'XTS', Descripcion: 'Estructura' },
    { Clave: 'XTT', Descripcion: 'Bolsa de mano' },
    { Clave: 'XTU', Descripcion: 'Tubo' },
    { Clave: 'XTV', Descripcion: 'Tubo con boquilla' },
    { Clave: 'XTW', Descripcion: 'Pallet tricapa' },
    { Clave: 'XTY', Descripcion: 'Tanque cilíndrico' },
    { Clave: 'XTZ', Descripcion: 'Tubos  con fleje/ agrupados/ armados' },
    { Clave: 'XUC', Descripcion: 'Sin empaque' },
    { Clave: 'XUN', Descripcion: 'Unidad' },
    { Clave: 'XVA', Descripcion: 'Tanque' },
    { Clave: 'XVG', Descripcion: 'Tanque de gas (a 1,031 mbar y 15° C)' },
    { Clave: 'XVI', Descripcion: 'Frasco pequeño' },
    { Clave: 'XVK', Descripcion: 'Paquete transportable' },
    { Clave: 'XVL', Descripcion: 'Contenedor para líquidos a granel' },
    { Clave: 'XVN', Descripcion: 'Vehículo' },
    { Clave: 'XVO', Descripcion: 'Contenedor para sólido de partículas grandes a granel ("nódulos")' },
    { Clave: 'XVP', Descripcion: 'Envasado al vacío' },
    { Clave: 'XVQ', Descripcion: 'Tanque para Gas licuado (a temperatura / presión anormal)' },
    { Clave: 'XVR', Descripcion: 'Contenedor para sólidos de partículas granulares a granel (Granos)' },
    { Clave: 'XVS', Descripcion: 'Contenedor de chatarra a granel' },
    { Clave: 'XVY', Descripcion: 'Contenedor para sólido de partículas finas a granel ("polvos")' },
    { Clave: 'XWA', Descripcion: 'Contenedor de granel intermedio' },
    { Clave: 'XWB', Descripcion: 'Botella de mimbre' },
    { Clave: 'XWC', Descripcion: 'Contenedor intermedio para gráneles y de acero' },
    { Clave: 'XWD', Descripcion: 'Contenedor intermedio para gráneles y de aluminio' },
    { Clave: 'XWF', Descripcion: 'Contenedor intermedio para gráneles y de metal' },
    { Clave: 'XWG', Descripcion: 'Contenedor intermedio para gráneles y de acero presurizado menor a 10 kpa' },
    { Clave: 'XWH', Descripcion: 'Contenedor intermedio para gráneles y de aluminio, presurizado menor a 10 kpa' },
    { Clave: 'XWJ', Descripcion: 'Contenedor intermedio para gráneles y de metal con una presión de 10 kpa' },
    { Clave: 'XWK', Descripcion: 'Contenedor intermedio para gráneles y de acero para líquido' },
    { Clave: 'XWL', Descripcion: 'Contenedor intermedio para gráneles y de aluminio para líquido' },
    { Clave: 'XWM', Descripcion: 'Contenedor intermedio para gráneles y de metal para líquido' },
    { Clave: 'XWN', Descripcion: 'Contenedor intermedio para gráneles con tejido plástico sin capa con revestimiento' },
    { Clave: 'XWP', Descripcion: 'Contenedor intermedio para gráneles con tejido plástico y recubierto' },
    { Clave: 'XWQ', Descripcion: 'Contenedor intermedio para gráneles con tejido plástico con revestimiento' },
    { Clave: 'XWR', Descripcion: 'Contenedor intermedio para gráneles con tejido plástico, revestido y con forro' },
    { Clave: 'XWS', Descripcion: 'Contenedor intermedio para gráneles con película de plástico' },
    { Clave: 'XWT', Descripcion: 'Contenedor intermedio para gráneles textil sin capa / forro' },
    { Clave: 'XWU', Descripcion: 'Contenedor intermedio para gráneles de madera natural con forro interior' },
    { Clave: 'XWV', Descripcion: 'Contenedor intermedio para gráneles textil recubierto' },
    { Clave: 'XWW', Descripcion: 'Contenedor intermedio para gráneles textil con revestimiento' },
    { Clave: 'XWX', Descripcion: 'Contenedor intermedio para gráneles textil recubierto y con forro' },
    { Clave: 'XWY', Descripcion: 'Contenedor intermedio para gráneles contrachapado con revestimiento interior' },
    { Clave: 'XWZ', Descripcion: 'Contenedor intermedio para gráneles de madera reconstituida con revestimiento interior' },
    { Clave: 'XXA', Descripcion: 'Bolsa de tejido plástico, sin abrigo interior ni forro' },
    { Clave: 'XXB', Descripcion: 'Bolsa de tejido plástico a prueba de filtraciones' },
    { Clave: 'XXC', Descripcion: 'Bolsa de tejido plástico resistente al agua' },
    { Clave: 'XXD', Descripcion: 'Bolsa con película de plástico' },
    { Clave: 'XXF', Descripcion: 'Bolsa textil sin capa ni forro interior' },
    { Clave: 'XXG', Descripcion: 'Bolsa textil a prueba de filtraciones' },
    { Clave: 'XXH', Descripcion: 'Bolsa textil resistente al agua' },
    { Clave: 'XXJ', Descripcion: 'Bolsa de papel multi-pared' },
    { Clave: 'XXK', Descripcion: 'Bolsa de papel multi-pared, resistente al agua' },
    { Clave: 'XYA', Descripcion: 'Empaque compuesto, recipiente de plástico en tambor de acero' },
    { Clave: 'XYB', Descripcion: 'Empaque compuesto, recipiente de plástico en cajas de acero' },
    { Clave: 'XYC', Descripcion: 'Empaque compuesto, recipiente de plástico en tambor de aluminio' },
    { Clave: 'XYD', Descripcion: 'Empaque compuesto, recipiente de plástico en cajón de aluminio' },
    { Clave: 'XYF', Descripcion: 'Empaque compuesto, recipiente de plástico en caja de madera' },
    { Clave: 'XYG', Descripcion: 'Empaque compuesto, recipiente de plástico en tambor de madera contrachapada' },
    { Clave: 'XYH', Descripcion: 'Empaque compuesto, recipiente de plástico en caja de madera contrachapada' },
    { Clave: 'XYJ', Descripcion: 'Empaque compuesto, recipiente de plástico en tambor de fibra' },
    { Clave: 'XYK', Descripcion: 'Empaque compuesto, recipiente de plástico en caja de cartón' },
    { Clave: 'XYL', Descripcion: 'Empaque compuesto, recipiente de plástico en el tambor de plástico' },
    { Clave: 'XYM', Descripcion: 'Empaque compuesto, recipiente de plástico en caja de plástico sólido' },
    { Clave: 'XYN', Descripcion: 'Empaque compuesto, receptáculo de vidrio en tambor de acero' },
    { Clave: 'XYP', Descripcion: 'Empaque compuesto, receptáculo de vidrio en caja de cajas de acero' },
    { Clave: 'XYQ', Descripcion: 'Empaque compuesto, recipiente de vidrio en tambor de aluminio' },
    { Clave: 'XYR', Descripcion: 'Empaque compuesto, receptáculo de vidrio en caja de aluminio' },
    { Clave: 'XYS', Descripcion: 'Empaque compuesto, recipiente de vidrio en caja de madera' },
    { Clave: 'XYT', Descripcion: 'Empaque compuesto, recipiente de vidrio en tambor de madera contrachapada' },
    { Clave: 'Xyv', Descripcion: 'Empaque compuesto, recipiente de vidrio en el cesto de mimbre' },
    { Clave: 'XYW', Descripcion: 'Empaque compuesto, recipiente de vidrio en tambor de fibra' },
    { Clave: 'XYX', Descripcion: 'Empaque compuesto, recipiente de vidrio en caja de cartón' },
    { Clave: 'XYY', Descripcion: 'Empaque compuesto, recipiente de vidrio en paquete de plástico expandible' },
    { Clave: 'XYZ', Descripcion: 'Empaque compuesto, recipiente de vidrio en paquete de plástico sólido' },
    { Clave: 'XZA', Descripcion: 'Contenedor de granel intermedio, papel, multi-pared' },
    { Clave: 'XZB', Descripcion: 'Bolsa grande' },
    { Clave: 'XZC', Descripcion: 'Contenedor intermedio para gráneles de papel, multi-pared y resistente al agua' },
    { Clave: 'XZD', Descripcion: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para sólidos' },
    { Clave: 'XZF', Descripcion: 'Contenedor intermedio para gráneles de plástico rígido, autoportante para sólidos' },
    { Clave: 'XZG', Descripcion: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural, presurizado' },
    { Clave: 'XZH', Descripcion: 'Contenedor intermedio para gráneles de plástico rígido, autoportante y presurizado' },
    { Clave: 'XZJ', Descripcion: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para líquidos' },
    { Clave: 'XZK', Descripcion: 'Contenedor intermedio para gráneles de plástico rígido, autoportante, líquidos' },
    { Clave: 'XZL', Descripcion: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, sólidos' },
    { Clave: 'XZM', Descripcion: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible, sólidos' },
    { Clave: 'XZN', Descripcion: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, presurizado' },
    { Clave: 'XZP', Descripcion: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible, presurizado' },
    { Clave: 'XZQ', Descripcion: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, líquidos' },
    { Clave: 'XZR', Descripcion: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible para líquidos' },
    { Clave: 'XZS', Descripcion: 'Contenedor intermedio para gráneles, compuesto' },
    { Clave: 'XZT', Descripcion: 'Contenedor intermedio para gráneles con tablero de fibras' },
    { Clave: 'XZU', Descripcion: 'Contenedor intermedio para gráneles flexible' },
    { Clave: 'XZV', Descripcion: 'Contenedor intermedio para gráneles de metal, distinto del acero' },
    { Clave: 'XZW', Descripcion: 'Contenedor intermedio para gráneles, de madera natural' },
    { Clave: 'XZX', Descripcion: 'Contenedor intermedio para gráneles, de contrachapado' },
    { Clave: 'XZY', Descripcion: 'Contenedor intermedio para gráneles, de madera reconstituida' },
    { Clave: 'YDK', Descripcion: 'Yarda cuadrada' },
    { Clave: 'YDQ', Descripcion: 'Yarda cúbica' },
    { Clave: 'YL', Descripcion: 'Cien yardas lineales' },
    { Clave: 'YRD', Descripcion: 'Yarda' },
    { Clave: 'YT', Descripcion: 'Diez yardas' },
    { Clave: 'Z1', Descripcion: 'Furgoneta' },
    { Clave: 'Z11', Descripcion: 'Contenedor colgante ' },
    { Clave: 'Z5', Descripcion: 'Arrastre' },
    { Clave: 'Z6', Descripcion: 'Punto de conferencia' },
    { Clave: 'Z8', Descripcion: 'Página de noticias' },
    { Clave: 'ZP', Descripcion: 'Páginas' },
    { Clave: 'ZZ', Descripcion: 'Mutuamente definido' }
  ];
}
