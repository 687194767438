import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@auth/services/authentication.service';
import { MustMatch } from '../../../../app/core/_base/layout/validators/must-match-validators';
import { Subject } from 'rxjs';
import { RegisterModel } from '@models/register.model';
import { first, takeUntil } from 'rxjs/operators';
import { LayoutUtilsService, MessageType } from 'app/core/_base/crud/utils/layout-utils.service';

@Component({
  selector: 'kt-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent implements OnInit {

  public formContacto: FormGroup;
  error$ = new Subject<boolean>();
  send$ = new Subject<boolean>();
  loading$ = new Subject<boolean>();
  public send = false;
  showConfirmPassword = false;
  hidePass = true;
  hideConfirmPass = true;
  termsChecked = false;
  submited = false;
  ngUnsubscribe = new Subject();
  public validateNumero: boolean = true;
  public validateMayus: boolean = true;
  public validateMinus: boolean = true;
  public validateCharac: boolean = true;
  

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RegisterDialogComponent>,
    private authService: AuthenticationService,
    private layoutUtilsService: LayoutUtilsService,
    @Inject(MAT_DIALOG_DATA) public origin: string
    ) { }

  ngOnInit(): void {

    this.formContacto = this.fb.group({

      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      Rfc: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(13), Validators.pattern(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8}/)]],
      confirmPassword: ['', [Validators.required]],
      
    }, {
      
      validator: MustMatch('password', 'confirmPassword')
    
    });
    
  }

  contact() {

    this.formContacto.markAllAsTouched();
    if (this.formContacto.invalid || !this.termsChecked) {
      
      this.submited = true;
      this.error$.next(true);
      setTimeout(() => {
        this.error$.next(false);
      }, 10000);

    } else {

      this.loading$.next(true);
      let user: RegisterModel = new RegisterModel();
      user.name = this.formContacto.get('nombre').value;
      user.email = this.formContacto.get('correo').value;
      user.rfc = this.formContacto.get('Rfc').value;
      user.password = this.formContacto.get('password').value;
      user.origin = this.origin;
      user.collectedAt = new Date();
      
      this.authService.addUser(user).pipe(
        takeUntil(this.ngUnsubscribe),
        ).subscribe((res) => {

        if(res.code !== 404){
          
          this.send = true;
          this.error$.next(false);
          this.send$.next(true);
          this.loading$.next(false);

          const message = 'Registro Exitoso ahora puedes iniciar seción';
          this.layoutUtilsService.showActionNotification(message, MessageType.Create, 4000);

          setTimeout(() => {
            window.location.href = 'https://app.visoor.mx/entrar';
          }, 5000);
        
        }else{

          this.loading$.next(false);          
          const message = res.Desc;
          this.layoutUtilsService.showActionNotification(message, MessageType.Create, 8000);
        
        }
      });
    }
  }

  validatePassword(event) {

    const password: string = event.target.value;

    if (!/(?=.*\d)/.test(password)) {
      document.getElementById('helperNum').classList.remove('form__helpers--success');
      document.getElementById('helperNum').classList.add('form__helpers--error');
      this.showConfirmPassword = false;
    } else {
      document.getElementById('helperNum').classList.remove('form__helpers--error');
      document.getElementById('helperNum').classList.add('form__helpers--success');
    }

    if (!/(?=.*[a-z])/.test(password)) {
      this.showConfirmPassword = false;
      document.getElementById('helperMinus').classList.remove('form__helpers--success');
      document.getElementById('helperMinus').classList.add('form__helpers--error');
    } else {
      document.getElementById('helperMinus').classList.remove('form__helpers--error');
      document.getElementById('helperMinus').classList.add('form__helpers--success');
    }

    if (!/(?=.*[A-Z])/.test(password)) {
      this.showConfirmPassword = false;
      document.getElementById('helperMayus').classList.remove('form__helpers--success');
      document.getElementById('helperMayus').classList.add('form__helpers--error');
    } else {
      document.getElementById('helperMayus').classList.remove('form__helpers--error');
      document.getElementById('helperMayus').classList.add('form__helpers--success');
    }

    if (!/(?=.*[$@$!%*?&])/.test(password)) {
      this.showConfirmPassword = false;
      document.getElementById('helperSpecial').classList.remove('form__helpers--success');
      document.getElementById('helperSpecial').classList.add('form__helpers--error');
    } else {
      document.getElementById('helperSpecial').classList.remove('form__helpers--error');
      document.getElementById('helperSpecial').classList.add('form__helpers--success');
    }

    if (!this.formContacto.get('password').invalid) {
      this.showConfirmPassword = true;
    }

  }

  toggleCheckbox(e) {
    this.termsChecked = e.checked;
  }

}
