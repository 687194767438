
<div class="header">

    <div>
      <img (click)="refreshHome()" class="header-img cursor" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
    </div>
    <div class="header-menu">
      
        <div>
            <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
            <mat-menu #soluciones="matMenu">
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
                <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
            </mat-menu>
        </div>
  
        <div>            
            <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
        </div>
        
        <div>
          <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
        </div>
      
    </div>
    <div class="header-boton">
      <div class="onPress">
        <button (click)="redVisoorRegister()" mat-flat-button class="header-boton-left font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            ¡Prueba Visoor por 7 días!
          </span>
        </button>
      </div>
      <div class="onPress">
        <button (click)="redVisoorLogin()" mat-flat-button class="header-boton-right font-poppins">
          <span class="kt-margin-r-5 kt-margin-l-5">
            Entrar
          </span>
        </button>
      </div>
    </div>
  
</div>

<div class="header--movil">
  <img style="width: 80px;" (click)="refreshHome()" src="../../../../assets/media/visoor/images/Recurso1.png" alt="">
  <div>
    <a class="sections-nav-link button-registrar" (click)="redVisoorRegister()">¡Prueba Visoor por 7 días!</a>
    <a class="sections-nav-link button-entrar" (click)="redVisoorLogin()">Entrar</a>
  </div>
  <mat-icon class="ps-3" (click)="sidenav.toggle()">reorder</mat-icon>
</div>
<mat-sidenav-container>
  <mat-sidenav #sidenav style="position: fixed;">
    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="sidenav.toggle()">Cerrar</button>
    </div>
    <div class="menu-opciones">
      <button class="header-boton" mat-button [matMenuTriggerFor]="soluciones">Soluciones</button>
      <mat-menu #soluciones="matMenu">
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('contadores')">Contadores</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('api')">API's</button>
          <button class="header-matMenu" mat-menu-item (click)="redirectPage('white-label')">White label</button>
      </mat-menu>
    </div>

    <div class="menu-opciones">            
        <button class="header-boton" mat-button (click)="redirectPage('facturacion')">Facturacion 4.0</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('visoor')">¿Por qué Visoor?</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('autofactura')">Autofactura</button>
    </div>

    <div class="menu-opciones">
      <button class="header-boton" mat-button (click)="redirectPage('precios')">Precios</button>
    </div>
  </mat-sidenav>
</mat-sidenav-container>


<div class="contenedor contenedor_principal fondo1">
    <div class="columna_left">

        <div class="color-body-base center titles">
            <span class="title-light"> Delega la Facturación a tus Clientes <br> con </span>
            <span class="title-bold"> Autofactura de Visoor </span>
        </div>

        <h4 class="margen"> Reduce el margen de error y deja a tu cliente el llenado de los datos esenciales de su factura.</h4>

        <div class="boton_principal">
            <div>
                <button (click)="contactVentas()" mat-flat-button class="boton font-poppins">
                    <span class="kt-margin-r-5 kt-margin-l-5">
                        Hablar con Ventas
                    </span>
                </button>
            </div>
        </div>
        
        <div class="imagen imagen_margin">
            <img src="../../../../assets/media/visoor/images/autofactura/auto_recurso2.png">
        </div>
    </div>
    <div class="columna_right">
        <div class="imagen margen">
            <img src="../../../../assets/media/visoor/images/autofactura/auto_recurso1.png">
        </div>

        <h1 class="margen-titulo margen center">¿Cómo funciona?</h1>
        
        <div class="opciones margen">
            <mat-icon>send</mat-icon>
            <h4> Añade la venta a facturar. </h4>
        </div>
        <div class="opciones margen">
            <mat-icon>send</mat-icon>
            <h4> Proporciona el folio y link personalizado a tu cliente. </h4>
        </div>
        <div class="opciones margen">
            <mat-icon>send</mat-icon>
            <h4> Pide a tu cliente llenar sus datos fiscales. </h4>
        </div>
        <div class="opciones margen">
            <mat-icon>send</mat-icon>
            <h4> La factura se timbrará automáticamente y se enviará a los correos del cliente y el negocio. </h4>
        </div>
        <div class="opciones margen">
            <mat-icon>send</mat-icon>
            <h4> Genera tu Factura Global con Ventas no relacionadas </h4>
        </div>
        
        
    </div>

</div>

<div class="contenedorSegundo fondo2">
  <div class="segunda_Parte">
    
    <div class="color-body-base center titles">
      <span class="title-light"> Empresas que ya utilizan <br> </span>
      <span class="title-bold"> Autofactura de Visoor</span>
    </div>
    <div class="empresas">
      <div>
        <img src="./../../../../assets/media/visoor/images/autofactura/MORODER.png">
      </div>
      <div>
        <img src="./../../../../assets/media/visoor/images/autofactura/CASA_MIGUEL.png">
      </div>
      <div>
        <img src="./../../../../assets/media/visoor/images/autofactura/CROQUERIA.png">
      </div>
    </div>
    <div class="boton_principal">
      <div class="boton_segundaParte">
          <button (click)="contactVentas()" mat-flat-button class="boton font-poppins">
              <span class="kt-margin-r-5 kt-margin-l-5">
                  Hablar con Ventas
              </span>
          </button>
      </div>
  </div>

  </div>
    
</div>
